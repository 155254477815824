import { Group, Person, Place, WorkOutline } from "@mui/icons-material";
import moment from "moment";
import { useState } from "react";
import ASSETS from "src/asset";
import { GENDERS } from "src/helpers/constants";

export default function HandleSelectUser({
  allData,
  pickedUser,
  setPickedUser,
  setOpenSecondSelectUser,
  setSecondParticipant,
}) {
  const [filterGender, setFilterGender] = useState("");

  const calculateAge = (birthdate) => {
    const birthDate = new Date(birthdate);
    const ageDiffMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDiffMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  return (
    <div className="bg-white w-full">
      <div className="space-y-3 ">
        <div className="flex px-3 sm:px-5 justify-between items-center">
          <h2 className="text-sm font-semibold">All Users</h2>
          {/* <select
            value={filterGender}
            onChange={(e) => setFilterGender(e.target.value)}
            className="w-32 text-xs sm:text-sm px-4 py-2 rounded-lg"
          >
            <option value={""}>All Gender</option>
            {GENDERS?.map?.((gene, index) => (
              <option key={index} value={gene}>
                {gene}
              </option>
            ))}
          </select> */}
        </div>
        <div className="w-full px-3 sm:px-5 divide-y divide-gray-300  h-72 overflow-y-auto ">
          {allData?.length > 0 ? (
            allData?.map?.((user: any, index: any) => {
              const location = `${
                user?.location_state
                  ? `${user?.location_state?.trim?.()}, `
                  : ""
              }${
                user?.location_country ? user?.location_country?.trim?.() : ""
              }`;
              const userAge = user?.age?.includes?.("-")
                ? calculateAge(user?.age)
                  ? calculateAge(user?.age)
                  : ""
                : moment().diff(moment(user?.age, "DD/MM/YYYY"), "years");
              return (
                <div key={index} className=" py-1.5">
                  <div className="flex w-full justify-between items-center">
                    <div className="flex gap-2 items-center">
                      <div className="w-6 sm:w-8 h-6 sm:h-8 p-0.5 border border-gray-400 flex-shrink-0 rounded-full">
                        <img
                          src={user?.profile_image_url ?? ASSETS?.Avi}
                          className="rounded-full  object-cover w-full h-full"
                          alt="person"
                        />
                      </div>
                      <div className="space-y-1">
                        <div>
                          <h2 className="text-xs sm:text-sm capitalize font-semibold">
                            {user?.first_name?.toLowerCase?.() +
                              " " +
                              user?.last_name?.toLowerCase?.() +
                              `${userAge ? ", " + userAge : ""}`}
                          </h2>
                          <p className="text-[9px] sm:text-xs w-full flex gap-2 items-center">
                            <WorkOutline fontSize="inherit" />
                            {user?.occupation?.length > 30
                              ? `${user?.occupation?.slice?.(0, 30)}...`
                              : user?.occupation}
                          </p>
                        </div>
                        <div className="flex gap-5 md:gap-8 items-center">
                          <span className="flex gap-1 items-center">
                            <p className="text-[9px] sm:text-xs">
                              <Place fontSize="inherit" />
                            </p>
                            <p className="text-[9px] sm:text-xs font-semibold">
                              {location?.length > 20
                                ? `${location?.slice(0, 20)}...`
                                : location}
                            </p>
                          </span>
                          <span className="flex gap-1 items-center">
                            <p className="text-[9px] sm:text-xs">
                              <Person fontSize="inherit" />
                            </p>
                            <p className="text-[9px] sm:text-xs font-semibold">
                              {user?.gender?.toLowerCase?.()}
                            </p>
                          </span>
                          <span className="flex gap-1 items-center">
                            <p className="text-[9px] sm:text-xs">
                              <Group fontSize="inherit" />
                            </p>
                            <p className="text-[9px] sm:text-xs font-semibold">
                              {user?.status?.toLowerCase?.()}
                            </p>
                          </span>
                        </div>
                      </div>
                    </div>
                    <input
                      type="checkbox"
                      onChange={() => {
                        if (pickedUser?.id === user?.id) {
                          return setPickedUser(null);
                        }

                        return setPickedUser(user);
                      }}
                      checked={pickedUser?.id === user?.id}
                      className="w-4 flex-shrink-0 h-4"
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="h-40 flex flex-col justify-center items-center">
              <h2 className="text-sm text-center font-semibold">
                No suggestion available!
              </h2>
              <p className="text-xs text-center">
                Kindly suggest some users to the first selected user to add a
                blind date
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="flex p-3 sm:p-5 w-full justify-end gap-5 bg-gray-50 items-center">
        <button
          onClick={() => {
            setPickedUser(null);
            setOpenSecondSelectUser(false);
          }}
          className="px-4 sm:px-6 py-2 rounded-lg text-center text-xs sm:text-base font-semibold bg-white border border-gray-400 text-gray-700"
        >
          Cancel
        </button>
        <button
          disabled={!pickedUser}
          onClick={() => {
            setSecondParticipant(pickedUser);
            setPickedUser(null);
            setOpenSecondSelectUser(false);
          }}
          className="px-4 sm:px-6 py-2 rounded-lg text-center text-xs sm:text-base font-semibold bg-[#081A51] disabled:bg-gray-300 disabled:text-gray-700 text-white"
        >
          {"Select"}
        </button>
      </div>
    </div>
  );
}

import { BeatLoader } from "react-spinners";

export default function PageLoader({ noMargin = false, height = "h-80" }) {
  return (
    <div
      className={`flex ${height} items-center w-full justify-center ${
        noMargin ? "" : "mt-10 sm:mt-20"
      }`}
    >
      <BeatLoader color="#081A51" />
    </div>
  );
}

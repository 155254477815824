import { useState } from "react";

export default function UseLocalStorage(key: any, initialValue: any) {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });
  const setValue = (value: any) => {
    try {
      setStoredValue(value);
      if (typeof window !== "undefined") {
        localStorage?.setItem(
          key,
          JSON.stringify(value instanceof Function ? value?.() : value)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

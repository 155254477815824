import { Menu, Transition } from "@headlessui/react";
import {
  CheckCircleOutline,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";

export default function LinePagination({ data, setCurrentItems, children }) {
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    if (data?.length > 0) {
      setCurrentItems([...data.slice(itemOffset, endOffset)]);
      setPageCount(Math.ceil(data.length / itemsPerPage));
    } else {
      setCurrentItems([]);
      setPageCount(0);
    }
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {children}
      <div className="flex w-full flex-shrink-0 flex-col sm:flex-row items-center gap-2 justify-between  p-3">
        <div className="flex items-center gap-2">
          <p className="sm:text-xs text-[10px] font-medium">Showing</p>

          <Menu as="div" className="relative w-max">
            {({ open }) => (
              <>
                <Menu.Button as="div" className="w-full">
                  <div className="flex w-max bg-[#21407d64] gap-1 cursor-pointer items-center sm:gap-3 rounded-lg p-2 sm:text-xs text-[10px]">
                    {itemsPerPage}

                    <KeyboardArrowDown fontSize="inherit" />
                  </div>
                </Menu.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="focus:outline-none absolute left-0 bottom-full z-50 mt-1 w-32 origin-top-left rounded-lg border bg-white p-2 shadow-md ring-1 ring-gray-1">
                    <div className="space-y-1">
                      <Menu.Item>
                        <div
                          onClick={() => {
                            setItemsPerPage(10);
                          }}
                          className="flex cursor-pointer items-center gap-5 p-2 text-xs font-medium hover:text-blue-600"
                        >
                          10
                          {itemsPerPage === 10 && (
                            <CheckCircleOutline fontSize="small" />
                          )}
                        </div>
                      </Menu.Item>
                      <Menu.Item>
                        <div
                          onClick={() => {
                            setItemsPerPage(20);
                          }}
                          className="flex cursor-pointer items-center gap-5 p-2 text-xs font-medium hover:text-blue-600"
                        >
                          20
                          {itemsPerPage === 20 && (
                            <CheckCircleOutline fontSize="small" />
                          )}
                        </div>
                      </Menu.Item>
                      <Menu.Item>
                        <div
                          onClick={() => {
                            setItemsPerPage(30);
                          }}
                          className="flex cursor-pointer items-center gap-5 p-2 text-xs font-medium hover:text-blue-600"
                        >
                          30
                          {itemsPerPage === 30 && (
                            <CheckCircleOutline fontSize="small" />
                          )}
                        </div>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
          <p className="sm:text-xs text-[10px] font-medium">
            {" "}
            out of {data?.length}
          </p>
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel={<KeyboardArrowRight />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={<KeyboardArrowLeft />}
          renderOnZeroPageCount={null}
          containerClassName={"pagination"}
          activeClassName={"active"}
          marginPagesDisplayed={0}
        />
      </div>
    </>
  );
}

import React, { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { FaCheck } from 'react-icons/fa6'
import { useNavigate } from "react-router-dom";
interface SubscribePopUpProps {
  setPopUp: (value: boolean) => void;
}

const SubscribePopUp:React.FC<SubscribePopUpProps> = ({ setPopUp }) => {
  const [selected, setSelected] = useState('monthly')
   const navigate = useNavigate();
  return (
    <main className='font-sans h-full '>
      <div className='bg-gradient-to-b from-[#081A51] to-[#123BB7] text-white rounded-lg shadow-xl p-6 max-w-xl mx-auto'>
        <div className='flex justify-between items-center mb-4 border-b pb-4'>
          <h2 className='text-base md:text-lg xl:text-xl font-semibold font-sans'>
            Access Denied
          </h2>
          <button onClick={()=>setPopUp(false)} className='text-2xl text-white cursor-pointer'>
            <AiOutlineClose />
          </button>
        </div>
        <p className='mb-6 text-xs sm:text-sm lg:text-base'>
          You cannot access this feature as you are not a premium member.
          Subscribe today to start enjoying this feature and lots more. Select a
          plan below to get started.
        </p>
        {/* <div className='grid grid-cols-2 lg:grid-cols-4 gap-2 mb-6'>
          <button
            onClick={() => setSelected('monthly')}
            className={`${
              selected === 'monthly'
                ? ' bg-white shadow-md border-white hover:bg-opacity-80 text-primary-bg py-2 font-semibold rounded-lg'
                : 'border border-white hover:bg-[#3e5196] hover:bg-opacity-75  text-white py-2 rounded-lg'
            }`}
          >
            Monthly
          </button>
          <button
            onClick={() => setSelected('quarterly')}
            className={`${
              selected === 'quarterly'
                ? ' bg-white shadow-md border-white hover:bg-opacity-80 text-primary-bg py-2 font-semibold rounded-lg'
                : 'border border-white hover:bg-[#3e5196] hover:bg-opacity-75  text-white py-2 rounded-lg'
            }`}
          >
            Quarterly
          </button>
          <button
            onClick={() => setSelected('bi-annually')}
            className={`${
              selected === 'bi-annually'
                ? ' bg-white shadow-md border-white hover:bg-opacity-80 text-primary-bg py-2 font-semibold rounded-lg'
                : 'border border-white hover:bg-[#3e5196] hover:bg-opacity-75  text-white py-2 rounded-lg'
            }`}
          >
            Bi-annually
          </button>
          <button
            onClick={() => setSelected('yearly')}
            className={`${
              selected === 'yearly'
                ? ' bg-white shadow-md border-white hover:bg-opacity-80 text-primary-bg py-2 font-semibold rounded-lg'
                : 'border border-white hover:bg-[#3e5196] hover:bg-opacity-75  text-white py-2 rounded-lg'
            }`}
          >
            Yearly
          </button>
        </div> */}
        <div>
          <div className='relative flex gap-4 pb-10'>
            <div className='absolute w-1 z-10 left-[0.65rem] lg:left-[0.9rem]  bg-white h-full  '></div>
            <div className='w-6 lg:w-8 relative z-20 h-6 lg:h-8  flex-shrink-0 flex justify-center items-center bg-white  '>
              <h1 className='text-black'>
                <FaCheck />
              </h1>
            </div>
            <div className='text-xs sm:text-sm md:text-base'>
              Get new suggestions
            </div>
          </div>
          <div className='relative flex gap-4 pb-10'>
            <div className='absolute w-1 z-10 left-[0.65rem] lg:left-[0.9rem]  bg-white h-full '></div>
            <div className='w-6 lg:w-8 relative z-20 h-6 lg:h-8 flex-shrink-0 flex justify-center items-center bg-white  '>
              <h1 className='text-black'>
                <FaCheck />
              </h1>
            </div>
            <div className='text-xs sm:text-sm md:text-base'>
              Get personalized matches from handlers
            </div>
          </div>
          <div className='relative flex gap-4 pb-10'>
            <div className='absolute w-1 z-10 left-[0.65rem] lg:left-[0.9rem]  bg-white h-full '></div>
            <div className='w-6 lg:w-8 relative z-20 h-6 lg:h-8 flex-shrink-0 flex justify-center items-center bg-white  '>
              <h1 className='text-black'>
                <FaCheck />
              </h1>
            </div>
            <div className='text-xs sm:text-sm md:text-base '>
              Enjoy full handler support
            </div>
          </div>
          <div className='relative flex gap-4 pb-10'>
            <div className='absolute w-1 z-10 left-[0.65rem] lg:left-[0.9rem]  bg-white h-full '></div>
            <div className='w-6 lg:w-8 relative z-20 h-6 lg:h-8  flex-shrink-0 flex justify-center items-center bg-white  '>
              <h1 className='text-black'>
                <FaCheck />
              </h1>
            </div>
            <div className='text-xs sm:text-sm md:text-base'>
              {' '}
              Access to system scheduled Blind Dates.
            </div>
          </div>
          <div className='relative flex gap-4 pb-10'>
            <div className='w-6 lg:w-8 relative z-20 h-6 lg:h-8  flex-shrink-0 flex justify-center items-center bg-white  '>
              <h1 className='text-black'>
                <FaCheck />
              </h1>
            </div>
            <div className='text-xs sm:text-sm md:text-base'>
              And so much more ...
            </div>
          </div>
        </div>

        <button onClick={()=> navigate("/dashboard/choose-a-plan")} className='bg-[#1b3b82] hover:bg-[#3e5196] text-white font-bold py-2 lg:py-3 rounded-lg w-full border border-white text-sm lg:text-base shadow-md'>
          Get Started Now
        </button>
      </div>
    </main>
  )
}

export default SubscribePopUp

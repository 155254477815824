import { createTheme } from "@mui/material";
import { alpha } from "@mui/system";
import type {} from "@mui/x-data-grid/themeAugmentation";

const theme = {
  TEXT_PRIMARY: "#0F1222",
  PRIMARY: "#236192",
  PRIMARY_ALTERNATE: "#947BFF", // dark primary
  WHITE: "#FFF",
  WHITE_25: "rgba(255, 255, 255, 0.25)",
  PRIMARY_BACKGROUND: "#f3f4f9",
  PRIMARY_DARK: "#002A4D",
  DARK_GREY: "#C3CDE1",
  BLACK_0: "#000000",
  BLACK_040: "rgba(0, 0, 0, 0.04)",
  BLACK_30: "rgba(0, 0, 0, 0.3)",
  PRIMARY_80: "rgba(35, 97, 146, 0.08)", // 80 represent alpha channel (opacity). Please follow the same convention.
  PRIMARY_50: "rgba(100, 91, 232, 0.5)",
  GREY: "#B0B7C3",
  GREY_SUB: "#DEE3EE",
  GREY_TITLE: "#535875",
  GREY_TABLE: "#D3D5E4",
  GREY_VARIANT: "#E5E5E5",
  GREY_ICON: "#C4C4C4",
  GREY_50: "rgba(83, 88, 117, 0.5)",
  BLACK: "#444",
  RED: "#C4302B",
  RED_MISSED_CALLS: "#FF153C",
  SUCCESS_MAIN: "#1BE9B6",
  SUCCESS_OUTLINED: "rgba(27, 233, 182, 0.08)",
  SUCCESS_DARK: "#006B51",
  LOADING_BLUE: "#F4F4F9",
  ERROR_OUTLINED: "rgba(255, 21, 60, 0.1)",
  ERROR_MAIN: "#FF153C",
  WARNING_MAIN: "#BC9900",
  WARNING_OUTLINED: "rgba(249, 210, 42, 0.08)",
  TEXT_SECONDARY: "#979797",
  TEXT_SECONDARY_02: "#B0B7C3",
  YELLOW: "#F9D22A",
  ELLIPSE_BG: "#F2F2F2",
  GREEN: "#3EA800",
  MDOAL_BACKDROP: "rgba(172, 172, 172, 0.87)",

  GRAY_OTHER: "#F9FAFC",
  GREY_400: "#BDBDBD",

  TAG_BACKGROUND_RED: "#ffdbe1",
  TAG_FORGROUND_RED: "#DC0025",

  SEANCE: "#9C27B0",
  AMARANTH: "#D81B60",
  ORANGE_PEEL: "#FF9800",
  JELLY_BEAN: "#236192",
};

export default theme;

export const overrideTheme = createTheme({
  typography: {
    fontFamily: "'Roboto', sans-serif",
  },
  palette: {
    primary: {
      main: theme.PRIMARY,
    },
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          "& .MuiSwitch-switchBase.Mui-checked": {
            color: theme.WHITE,
            "&:hover": {
              backgroundColor: alpha(theme.SUCCESS_MAIN, 0.6),
            },
          },
          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: theme.SUCCESS_MAIN,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          fontSize: "13px",
          lineHeight: "18px",
          fontWeight: 400,
          letterSpacing: "0.16px",
          height: "24px",
          marginRight: "4px",
          "&.MuiChip-colorPrimary": {
            color: theme.PRIMARY_DARK,
            backgroundColor: theme.PRIMARY_80,
          },
          "&.MuiChip-colorSuccess": {
            color: theme.SUCCESS_DARK,
            backgroundColor: theme.SUCCESS_OUTLINED,
          },
          "&.MuiChip-colorError": {
            color: theme.ERROR_MAIN,
            backgroundColor: theme.ERROR_OUTLINED,
          },
          "&.MuiChip-colorWarning": {
            color: theme.WARNING_MAIN,
            backgroundColor: theme.WARNING_OUTLINED,
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
            outline: "none",
          },

          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },

          "&.MuiDataGrid-root .MuiTablePagination-displayedRows": {
            fontSize: "10px",
            lineHeight: "12px",
            color: theme.TEXT_SECONDARY,
          },
          "&.MuiDataGrid-root .MuiTablePagination-actions": {
            width: "32px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          },
          "&.MuiDataGrid-root .MuiTablePagination-actions .MuiButtonBase-root":
            {
              width: "1rem",
              height: "1rem",
            },

          "&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
            fontSize: "12px",
            lineHeight: "18px",
            fontWeight: "normal",
            display: "flex",
            alignItems: "center",
            color: theme.GREY_TITLE,
            opacity: 0.5,
          },

          "&.MuiDataGrid-root .MuiDataGrid-columnSeparator": {
            display: "none",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "unset",
          },
        },
      },
    },
  },
});

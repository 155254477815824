//@ts-nocheck
import Slider from "react-slick";
import { useRef, Fragment } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import ReactCardCarousel from "react-card-carousel";
import SingleTherapist from "./SingleTherapist";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import SingleTherapistMobile from "./SingleTherapistMobile";

export default function AllTherapists({
  availableTherapists = [],
  selectedTherapist,
  setSelectedTherapist,
  handleCheckClick,
  setIndexPage,
  setOpenConfirm,
}) {
  const ReactCardCarouselRef = useRef(null);
  const settings = {
    customPaging: function (i) {
      return (
        <p
          className="w-3 h-3 rounded-full"
          style={{ color: "#fff", backgroundColor: "#fff" }}
        ></p>
      );
    },
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    waitForAnimate: false,
    cssEase: "linear",
    swipeToSlide: true,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  function afterCardChange() {
    if (availableTherapists?.length && ReactCardCarouselRef?.current) {
      setSelectedTherapist(
        availableTherapists[ReactCardCarouselRef.current.getCurrentIndex()]
      );
    }
  }

  return (
    <>
      <div className="card w-full hidden sm:inline-block space-y-5 sm:space-y-8 sm:bg-black-3 sm:pb-8">
        {availableTherapists?.length ? (
          <div className="w-full">
            <div className="relative flex h-[500px] w-full items-center justify-center">
              <ReactCardCarousel
                key={availableTherapists.map((p) => p?.name).join("_")}
                ref={ReactCardCarouselRef}
                spread="wide"
                // disable_box_shadow
                afterChange={afterCardChange}
              >
                {availableTherapists.map((therapist, index) => {
                  const isSelectedTherapist =
                    therapist?.id === selectedTherapist?.id;
                  return (
                    <SingleTherapist
                      key={therapist?.name}
                      therapist={therapist}
                      isSelectedTherapist={isSelectedTherapist}
                      index={index}
                      handleCheckClick={handleCheckClick}
                    />
                  );
                })}
              </ReactCardCarousel>

              {availableTherapists.length > 1 ? (
                <>
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="absolute -left-8 z-5 sm:left-2"
                  >
                    <button
                      className=" h-10 w-10 bg-white flex-shrink-0 rounded-full p-0 sm:p-0"
                      onClick={() => {
                        if (ReactCardCarouselRef?.current) {
                          ReactCardCarouselRef.current.prev();
                        }
                      }}
                    >
                      <ArrowCircleLeft color="#fff" />
                    </button>
                  </div>

                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="absolute -right-8 z-5 sm:right-2"
                  >
                    <button
                      className="h-10 w-10 bg-white flex-shrink-0 rounded-full p-0 sm:p-0"
                      onClick={() => {
                        if (ReactCardCarouselRef?.current) {
                          ReactCardCarouselRef.current.next();
                        }
                      }}
                    >
                      <ArrowCircleRight color="#fff" />
                    </button>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div
        onClick={(e) => e.stopPropagation()}
        className="slider-container slida sm:hidden pb-10"
      >
        <Slider {...settings}>
          {availableTherapists.map((therapist, index) => {
            return (
              <SingleTherapistMobile
                key={therapist?.name}
                therapist={therapist}
                index={index}
                handleCheckClick={() => {
                  setSelectedTherapist(therapist);
                  setIndexPage(1);
                  setOpenConfirm(false);
                }}
              />
            );
          })}
        </Slider>
      </div>
    </>
  );
}

import { useMutation, useQuery } from "@apollo/client";
import { Person, StarRate } from "@mui/icons-material";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardControlKeyIcon from "@mui/icons-material/KeyboardControlKey";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import { useMediaQuery } from "@mui/material";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import Write from "src/assets/write.svg";
import InputField from "src/components/Input";
import AdminModal from "src/components/Message/AdminModal";
import UserMessage from "src/components/Message/UserMessage";
import Star from "src/components/Rating";
import ReportIsuueComponent from "src/components/ReportIsuueComponent";
import SingleButton from "src/components/SingleButton";
import { useUser } from "src/components/auth/hooks/UserProvider";
import { SideBarContext } from "src/context/SidebarProvider";
// import { SideBarItemObj } from "src/data/sidebar_item";
import {
  RATE_HANDLER,
  REPORT_ISSUE,
  SEND_HANDLER_MESSAGE,
} from "src/hooks/data/mutations";
import { GET_USER_HANDLER_CONVERSATION } from "src/hooks/data/queries";
import Noaccess from "./NoAccessPage";
import ASSETS from "src/asset";

const ChatHandler: React.FC = () => {
  const { user, userRefetch } = useUser();
  const [sendHandlerMessage] = useMutation(SEND_HANDLER_MESSAGE);
  const [
    rateHandler,
    // { data: rateData, loading: rateloading, error: raterror },
  ] = useMutation(RATE_HANDLER);
  const [
    reportIssue,
    // { data: issuedata, loading: reportloading, error: reporterror },
  ] = useMutation(REPORT_ISSUE);
  const { data: conversation_data, refetch } = useQuery(
    GET_USER_HANDLER_CONVERSATION,
    {
      variables: {
        userId: Number(user?.id),
      },
    }
  );
  //console.log({ data: conversation_data });

  const [message, setMessage] = useState({
    id: 1,
    header: "",
    message: "",
    isHandler: false,
  });

  const [report, setReport] = useState({
    subject: "",
    message: "",
  });

  const [handlerResponse, setHandlerResponse] = useState(false);
  // const [selectedFileState, setSelectedFileState] = useState<any>([
  //   { file: "" },
  // ]);
  const [showModal, setShowModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const { showMessage, toggleMessageView } = useContext<any>(SideBarContext);
  // const [showNotifications, setShowNotifications] = useState(false);
  const starRatingOptions = [1, 2, 3, 4, 5];
  const [messageDropDown, setMessageDropDown] = useState(
    conversation_data?.handlerConversationsByUserId?.handler_messages?.length >
      0
      ? false
      : true
  );
  const [rating, setRating] = useState(
    user?.userHandlerRating?.[0]?.rating ?? 0
  );
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  //   const user = singleUser?.name;

  const disabled = Object.values(message).some((text) => text === "");

  // const handleFileChange = (event: any, i) => {
  //   const file = event.target.files[0].name;

  //   const updatedFileState = selectedFileState.map((sel, idx) => {
  //     if (idx === i) {
  //       return {
  //         ...sel,
  //         file: file,
  //       };
  //     }
  //     return sel;
  //   });
  //   setSelectedFileState([...updatedFileState]);
  // };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setMessage((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  // const handleReportChange = (
  //   e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   setReport((prevState) => ({
  //     ...prevState,
  //     [e.target.name]: e.target.value,
  //   }));
  // };
  // const handleShowNotification = () => {
  //   setShowNotifications(!showNotifications);
  // };

  // const handleAddMore = () => {
  //   const newSelected = { file: "" };
  //   setSelectedFileState((prevData) => [...prevData, newSelected]);
  // };

  const handleSentMessage = () => {
    // let media_ids = [];
    // if (selectedFileState.length > 0) {
    //   //save to s3 and get media_ids
    //   // save in media_ids
    // }
    sendHandlerMessage({
      variables: {
        message: {
          message_sender: "USER",
          subject: message.header,
          body: message.message,
          handler_conversation_id: Number(
            conversation_data?.handlerConversationsByUserId?.id
          ),
          //   media_ids,
        },
      },
      onCompleted: (data) => {
        refetch();
        setMessage({
          id: 1,
          header: "",
          message: "",
          isHandler: false,
        });
        setMessageDropDown(!messageDropDown);
        // setMessageView([...messageView, message]);
        // setHandlerResponse(true);
        // toggleMessageView(!showMessage);
        // setMessage({ id: 0, header: "", message: "", isHandler: false });
        // setSelectedFileState([{ file: "" }]);
      },
      onError: (error) => {
        console.log({ error });
      },
    });
  };

  const OpenMessage = () => {
    toggleMessageView(showMessage);
    setHandlerResponse(!handlerResponse);
  };

  const closeModal = () => {
    setShowModal(false);
    setShowReportModal(false);
  };

  const handleStarClick = (index) => {
    rateHandler({
      variables: {
        userId: Number(user?.id),
        rating: index,
        handlerId: Number(user?.handler?.id),
      },
      onCompleted: (data) => {
        refetch();
        userRefetch();
        closeModal();
        toast.success("Rating submitted successfully");
      },
      onError: (error) => {
        console.log({ error });
      },
    });
  };

  const reportIssueHandler = () => {
    reportIssue({
      variables: {
        message: report.message,
        userId: Number(user?.id),
        subject: report.subject,
      },
      onCompleted: (data) => {
        closeModal();
        setReport({
          subject: "",
          message: "",
        });
        toast.success("Report submitted successfully");
      },
      onError: (error) => {
        console.log({ error });
        toast.error(error?.message ?? "Error submitting report");
      },
    });
  };

  return (
    // <>
    //   {user.subscription.name === "basic" ? (
    //     <Noaccess
    //       header="No Access"
    //       message=" You have no access to your Handler. Beef up your dating game today, by becoming a premium subscriber"
    //     />
    //   ) : (
    <div className="w-full  ">
      <div className="py-3  ">
        <div className="space-y-5">
          <div className=" w-full px-1 shadow-md bg-white flex justify-center  items-center divide-x divide-gray-300 gap-3 lg:gap-10 py-4 rounded-lg">
            <div className="flex gap-4 justify-center items-center">
              <div className="w-12 h-12 p-1 lg:w-28 lg:h-28 bg-[#081a51d3] rounded-full shadow-md border border-gray-300 text-white flex justify-center items-center">
                {
                  <img
                    src={user?.handler?.profile_image_url ?? ASSETS?.Avi}
                    alt="img"
                    className="w-full h-full rounded-full object-cover text-xs"
                  />
                }
              </div>
              <div className=" flex flex-col justify-center ">
                <p className="text-[#081A51] capitalize font-bold lg:text-xl text-xs">
                  {user?.handler?.user_name}
                </p>
                <p className="text-[#081A51] lg:text-sm text-[9px]">handler</p>
              </div>
            </div>
            <div className="flex gap-3 lg:gap-10 pl-3 items-center">
              {user?.userHandlerRating?.[0]?.rating ? (
                <div className="">
                  <div className="flex gap-1 items-center">
                    {starRatingOptions?.map?.((star, index) => (
                      <span
                        key={index}
                        className={`text-xs sm:text-xl ${
                          star > user?.userHandlerRating?.[0]?.rating
                            ? "text-[#9ca3af]"
                            : "text-[#facc15]"
                        }`}
                      >
                        <StarRate fontSize="inherit" color="inherit" />
                      </span>
                    ))}
                  </div>
                  <p className="text-[8px] sm:text-xs text-center ">
                    You rated ({user?.userHandlerRating?.[0]?.rating}/5)
                  </p>
                </div>
              ) : (
                <SingleButton
                  icon={
                    <StarBorderOutlinedIcon
                      style={{
                        color: rating === 0 ? "black" : "yellow",
                        fontSize: isSmallScreen ? "14px" : "20px",
                      }}
                    />
                  }
                  text="Rate Handler"
                  className="lg:p-4 p-1 flex justify-center items-center border border-[#081A51] rounded-lg cursor-pointer lg:mr-4 mr-1"
                  inlineStyle="text-blue-900 lg:text-xs text-[9px]"
                  onBtnClick={() => {
                    if (rating === 0) {
                      setShowModal(true);
                    }
                  }}
                  disabled={rating !== 0}
                />
              )}
              <SingleButton
                icon={
                  <DriveFileRenameOutlineOutlinedIcon
                    style={{
                      color: "white",
                      marginRight: "3px",
                      fontSize: isSmallScreen ? "14px" : "20px",
                    }}
                  />
                }
                text="Report Issue"
                className="lg:p-4 p-2.5 flex justify-center items-center border bg-red-500 rounded-lg cursor-pointer "
                inlineStyle="text-white lg:text-xs text-[9px]"
                onBtnClick={() => {
                  setMessageDropDown(false);
                  setShowReportModal(true);
                }}
              />
            </div>
          </div>
          {!messageDropDown && (
            <div
              onClick={OpenMessage}
              className="w-full h-14 shadow-md rounded-lg bg-white px-8 flex justify-between items-center my-4 "
            >
              <div onClick={() => setMessageDropDown(true)} className="w-28 flex cursor-pointer">
                <img
                  src={Write}
                  className="h-[20px] w-[20px] cursor-pointer"
                  alt="img"
                />
                <span  className="font-semibold ml-4 text-sm">Reply</span>
              </div>
              <span onClick={() => setMessageDropDown(true)}>
                <KeyboardArrowDownOutlinedIcon style={{ cursor: "pointer" }} />{" "}
              </span>
            </div>
          )}
          {messageDropDown && (
            <div className="w-full shadow-md rounded-lg bg-white ">
              <div className="flex justify-end w-full p-5 border-gray-300 border-b">
                <span onClick={() => setMessageDropDown(false)}>
                  <KeyboardControlKeyIcon style={{ cursor: "pointer" }} />{" "}
                </span>
              </div>
              <div className=" w-full p-5 pb-10  space-y-5 lg:w-4/5 mx-auto">
                <InputField
                  label={"Heading"}
                  placeholder="Input Heading here"
                  labelStyle="text-sm sm:text-base font-semibold"
                  type="text"
                  name="header"
                  onChange={handleChange}
                  value={message.header}
                  labelView
                  inBox="rounded-sm h-10 max-lg:h-8 flex mt-2 pl-3 max-lg:pl-1 border border-slate-300 items-center"
                  outBox="w-full"
                  inputClassName="bg-white text-xs sm:text-base max-lg:w-full max-lg:h-7 appearance-none lg:w-5/6 w-full h-8 border-0 focus:outline-none focus:border-transparent focus:ring-0 "
                />
                <div className="w-full flex flex-col ">
                  <label className="text-[#11142D] text-sm sm:text-base font-semibold">
                    Message
                  </label>
                  <textarea
                    placeholder="Input Message here "
                    name="message"
                    className="w-full h-24 border-slate-300 rounded-md mt-2 focus:outline-none focus:border-slate-300 focus:ring-0 text-sm"
                    rows={10}
                    cols={50}
                    onChange={handleChange}
                    value={message.message}
                  />
                </div>
                {/* <div className="w-full mt-16 max-lg:mt-8 flex justify-between">
                    <div className="flex flex-col">
                      {selectedFileState?.map((sel, i) => (
                        <>
                          <div className="flex">
                            <label
                              htmlFor="fileInput"
                              className="cursor-pointer h-10 w-40 max-lg:w-12 max-lg:text-[9px] max-lg:h-7 max-lg:border-slate-300  border border-[#102A42] flex items-center justify-center mt-2"
                            >
                              Select file
                            </label>
                            <input
                              type="file"
                              id="fileInput"
                              className="absolute opacity-0 cursor-pointer"
                              accept=".jpg, .gif, .jpeg, .png, .pdf, zip, .doc, .docx"
                              onChange={(e) => {
                                handleFileChange(e, i);
                              }}
                            />
                            <p
                              className="text-slate-700 h-10 w-[400px] truncate
                                  max-lg:ml-1 max-lg:w-32 max-lg:text-[9px] max-lg:h-7 max-lg:border-slate-300 border border-[#102A42] flex items-center justify-center mt-2 text-sm whitespace-nowrap"
                            >
                              {" "}
                              {sel.file}{" "}
                            </p>
                          </div>
                          <p className="text-xs ml-2 mt-2 max-lg:text-[9px] max-lg:ml-0">
                            Allowed File Extensions: .jpg, .gif, .jpeg, .png,
                            .pdf, zip, .doc, .docx (Max file size: 128MB)
                          </p>
                        </>
                      ))}
                    </div>
                  </div> */}
                {/* <SingleButton
                    icon={
                      <AddOutlinedIcon
                        style={{
                          color: "black",
                          marginRight: "3px",
                          fontSize: isSmallScreen ? "9px" : "25px",
                        }}
                      />
                    }
                    text="Add more"
                    className="h-10 w-[170px] absolute flex mt-2 justify-center items-center border-2 border-[#F1F2FA] cursor-pointer shadow-2xl max-lg:h-7 right-1 bottom-[135px] max-lg:w-16 max-lg:bottom-[132px]"
                    inlineStyle="text-slate-700 max-lg:text-[9px]"
                    onBtnClick={handleAddMore}
                  /> */}
                <div className="w-full">
                  <SingleButton
                    icon={
                      <SendOutlinedIcon
                        style={{
                          marginLeft: "10px",
                          color: "white",
                          transform: "rotate(315deg)",
                          fontSize: isSmallScreen ? "15px" : "25px",
                        }}
                      />
                    }
                    text="Send"
                    className={`h-10 w-full sm:w-[170px] flex flex-row-reverse max-lg:w-24 max-lg:h-7 justify-center items-center ${
                      disabled ? "bg-[#c5ddf4]" : "bg-[#017EFA]"
                    } cursor-pointer mt-2 shadow-2xl`}
                    inlineStyle="text-slate-100 max-lg:text-[9px]"
                    onBtnClick={handleSentMessage}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
          )}
          <UserMessage
            messageView={
              conversation_data?.handlerConversationsByUserId?.handler_messages
            }
            handler={user?.handler}
            user_profile_url={user?.profile_image_url}
          />
        </div>

        <AdminModal
          visible={showModal}
          close={closeModal}
          headerText="Rate your handler’s Service"
          containerStyle="lg:w-[60%] h-[400px] max-lg:h-[350px] mt-20 bg-white relative w-[95%] rounded-md flex flex-col"
        >
          <div className="py-2 sm:pt-6 space-y-5 w-full flex flex-col items-center">
            <p className="w-full sm:w-[80%] px-2 leading-7 text-center text-sm max-lg:text-xs max-lg:leading-7 ">
              We hope you've had a great experience with your handler! Your
              feedback matters immensely to us, and we'd love to hear your
              thoughts. Please take a moment to rate your recent experience with
              your handler. Your valuable insights will help us improve and
              provide even better service in the future.
            </p>
            <div className="h-14 w-full gap-1 flex items-center justify-center ">
              <p className="font-semibold text-sm text-slate-800 max-lg:text-xs">
                Very bad
                <span className="px-2 max-lg:text-xs">
                  {starRatingOptions.map((index) => (
                    <Star
                      key={index}
                      filled={index < rating}
                      onClick={() => handleStarClick(index)}
                    />
                  ))}
                </span>{" "}
                Excellent
              </p>
            </div>
          </div>
        </AdminModal>
        <AdminModal
          visible={showReportModal}
          close={closeModal}
          headerText={"Report an Issue"}
          containerStyle=" relative  rounded-md flex flex-col  justify-center item-center px-6 md:px-10 max-w-2xl lg:max-w-3xl"
        >
          <ReportIsuueComponent reportIssueHandler={reportIssueHandler} />
        </AdminModal>
      </div>
    </div>
    //   )}
    // </>
  );
};
export default ChatHandler;

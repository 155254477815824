import { PaystackConsumer } from "react-paystack";
import { env } from "../../helpers/runtime";

const PaystackPortal = ({
  email,
  onSuccess,
  onClose,
  last_step_complete,
  plan_cost,
  plan = "",
  currency_code,
  reference,
  currency,
  amount,
  phone_number,
  name,
  title,
  plan_duration,
  className = "",
  mainCurrency,
}) => {
  const publicKey: string = env("paystack_key") || "";
  // const amount: number = Number(plan_cost.split("$")?.[1]) * 100;
  // const currency: string = plan_cost.split("$")?.[0];

  type Currency = "NGN" | "USD";

  const componentProps = {
    reference: reference,
    email,
    amount: Number(plan_cost) * 100,
    publicKey,
    text: `Pay ${currency_code} ${plan_cost} for ${plan} plan`,
    currency: mainCurrency as Currency,
    onSuccess: () => {
      onSuccess();
    },
    onError: () => {},
    onClose,
  };

  return (
    <>
      <PaystackConsumer {...componentProps}>
        {({ initializePayment }) => (
          <>
            <button
              className={
                className ||
                `py-2.5 block w-full font-semibold text-white ${
                  Boolean(!last_step_complete) ? "bg-disabled-1" : "bg-brand-1"
                } rounded-3xl`
              }
              disabled={Boolean(!last_step_complete)}
              onClick={() => initializePayment()}
              type="button"
            >
              {title
                ? title
                : `Pay ${currency_code} ${plan_cost} for ${plan} plan`}
            </button>
            {/* <PaystackHookExample />
          <PaystackButton {...componentProps} />{" "} */}
          </>
        )}
      </PaystackConsumer>
    </>
  );
};

export default PaystackPortal;

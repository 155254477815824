import { Menu, Transition } from "@headlessui/react";
import {
  ArrowDropDown,
  ArrowRightAlt,
  Check,
  KeyboardBackspace,
} from "@mui/icons-material";
import { Fragment, useEffect, useState } from "react";
import {
  AGERANGE,
  ASKS,
  CURIOUSNESS,
  GENDERS,
  INCOMES,
  INFO,
  KIDS,
  OCCUPATION_CATEGORY,
} from "src/helpers/constants";
import { Country, State, City } from "country-state-city";
import { toast } from "react-toastify";
import VerificationSuccess from "./VerificationSuccess";
import Loader from "./Loader";
import { useLocation, useNavigate } from "react-router-dom";
import UseLocalStorage from "./UseLocalStorage";
import { useMutation } from "@apollo/client";
import { VALIDATE_USER } from "../data/mutation";
import useSignUp from "../hooks/useSignUp";
import { BeatLoader } from "react-spinners";

export default function VerificationIndex() {
  const { verifyPhoneNumber, is_number_availabe_data } = useSignUp();
  const allCountries = Country.getAllCountries();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [validateUser] = useMutation(VALIDATE_USER);
  const [isChecking, setIsChecking] = useState(false);
  const userEmail = searchParams.get("email");

  const [userDetails, setUserDetails] = UseLocalStorage("pre_data", {
    personal: {
      name: "Personal Information",
      completed: false,
      full_name: "",
      phone_code: "234",
      phone_number: "",
      gender: "",
      country: "",
      state: "",
      city: "",

      date_of_birth: "",
      track_array: [
        "full_name",
        "phone_number",
        "gender",
        "location",
        "date_of_birth",
      ],
    },
    professional: {
      name: "Professional Information",
      completed: false,
      occupation: "",
      occupation_category: "",
      income: "",

      track_array: ["occupation", "income"],
    },
    dating: {
      name: "Dating Preference",
      completed: false,
      preferred_gender: "",
      age_range: "",

      track_array: ["preferred_gender", "age_range"],
    },
    general: {
      name: "General Knowledge",
      completed: false,
      time_wasting: "",
      kids_available: "",
      commited_relationship: "",
      hear_about_us: "",
      agreed: false,
      track_array: [
        "time_wasting",
        "kids_available",
        "commited_relationship",
        "hear_about_us",
      ],
    },
  });

  const [selectedGroup, setSelectedGroup] = UseLocalStorage(
    "group",
    "personal"
  );
  const [subGroup, setSubGroup] = UseLocalStorage("sub", "full_name");
  const [inputError, setInputError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleCheckUser = (payload: any) => {
    validateUser({
      variables: {
        payload: payload,
      },
      onCompleted: (data) => {
        setIsLoading(false);
        if (data?.validatePreUser?.message === true) {
          setIsSuccess(true);
          setTimeout(() => {
            setIsSuccess(false);
            localStorage.clear();
            navigate(`/verificationsuccess`, {
              state: { payload: data?.validatePreUser?.payload },
            });
          }, 6000);
          return;
        }
        localStorage.clear();
        navigate("/verificationerror");
      },
      onError: (error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Error occured. Try again later");
      },
    });
  };

  const handleLastClick = () => {
    setIsLoading(true);
    setTimeout(() => {
      const payload = {
        committed_relationship:
          userDetails?.general?.commited_relationship?.toLowerCase?.(),
        dob: userDetails?.personal?.date_of_birth,
        first_name:
          userDetails?.personal?.full_name?.trim?.()?.split?.(" ")?.length > 2
            ? userDetails?.personal?.full_name
                ?.trim?.()
                ?.split?.(" ")
                ?.filter?.(
                  (_, i) =>
                    i !==
                    userDetails?.personal?.full_name?.trim?.()?.split?.(" ")
                      ?.length -
                      1
                )
                ?.join?.(" ")
            : userDetails?.personal?.full_name?.trim?.()?.split?.(" ")?.[0],
        email: userEmail,
        gender: userDetails?.personal?.gender,
        have_children: userDetails?.general?.kids_available?.includes?.("above")
          ? 6
          : +userDetails?.general?.kids_available,
        hear_about_us: userDetails?.general?.hear_about_us,
        last_name: userDetails?.personal?.full_name
          ?.trim?.()
          ?.split?.(" ")
          ?.filter?.(
            (_, i) =>
              i ===
              userDetails?.personal?.full_name?.trim?.()?.split?.(" ")?.length -
                1
          )?.[0],
        location_city: userDetails?.personal?.city,
        location_country: userDetails?.personal?.country,
        location_state: userDetails?.personal?.state,
        occupation: `${userDetails?.professional?.occupation_category}, ${userDetails?.professional?.occupation}`,
        phone_number: `+${
          userDetails?.personal?.phone_code?.includes?.("+")
            ? userDetails?.personal?.phone_code?.split?.("+")?.[1]
            : userDetails?.personal?.phone_code
        }${userDetails?.personal?.phone_number}`,
        preferred_gender: userDetails?.dating?.preferred_gender,
        yearly_income: userDetails?.professional?.income?.includes?.("Less")
          ? 1000
          : userDetails?.professional?.income === "None"
          ? 0
          : userDetails?.professional?.income?.includes?.("more")
          ? 100000
          : +userDetails?.professional?.income
              ?.split?.(" - ")?.[1]
              ?.trim?.()
              ?.slice?.(1),
        wasting_time: userDetails?.general?.time_wasting?.toLowerCase?.(),
        preferred_age: userDetails?.dating?.age_range,
      };
      handleCheckUser(payload);
    }, 10000);
  };

  const getToday = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    return `${year}-${month}-${day}`;
  };

  function isOver18YearsOld(dateString: any) {
    const givenDate = new Date(dateString);
    const today = new Date();
    const pastDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    return givenDate <= pastDate;
  }

  const handlePhoneCheck = (p_number: any) => {
    setIsChecking(true);
    verifyPhoneNumber(p_number)
      .then((value) => {
        setIsChecking(false);
        if (value?.data.verifyPhone === false || !value?.data?.verifyPhone) {
          return setInputError("Phone already exists");
        }
        const currentIndex =
          userDetails?.[selectedGroup]?.track_array?.indexOf?.(subGroup);
        const nextGroup =
          userDetails?.[selectedGroup]?.track_array?.[currentIndex + 1];
        if (nextGroup) {
          return setSubGroup(nextGroup);
        }
      })
      .catch((error) => {
        setIsChecking(false);
        toast.error("Error proceeding! Try again later");
        console.log({ error });
      });
  };

  useEffect(() => {
    if (!userEmail) {
      navigate("/pre-verify");
    }
  }, [location]);
  return (
    <div className="w-full h-screen md:overflow-hidden overflow-y-auto">
      {isLoading && <Loader />}
      {isSuccess && <VerificationSuccess />}
      <div className="w-full h-full flex flex-col md:flex-row">
        <div className=" w-full lg:w-4/12 flex-shrink-0 md:w-2/5 bg-white md:bg-[#081A51] flex justify-between items-center flex-col md:p-10  p-5 md:text-white md:h-full">
          <div className="space-y-5">
            <div className="border-b text-center md:text-left border-white pb-5">
              <h2 className="playfair text-base sm:text-xl font-semibold">
                Let’s get to know you
              </h2>
              <p className="text-xs sm:text-sm ">
                This process shouldn’t take more than 5 minutes{" "}
              </p>
            </div>
            <div className="pt-5 hidden md:inline-block">
              {Object?.keys?.(userDetails)?.map?.((item, index) => (
                <div key={index} className="relative flex gap-5 pb-10">
                  {index !== Object?.keys?.(userDetails)?.length - 1 && (
                    <div className="absolute w-0.5 z-10 left-3 bg-trensparent border-l border-dashed border-white h-full "></div>
                  )}
                  <div className="w-6 relative text-black z-20 text-sm h-6 flex-shrink-0 flex justify-center items-center rounded-full bg-white  ">
                    {userDetails?.[item]?.completed ? (
                      <Check fontSize="inherit" />
                    ) : (
                      <div className="w-1/2 bg-[#667085] h-1/2 font-semibold rounded-full flex justify-center items-center text-xs "></div>
                    )}
                  </div>
                  <h2 className="text-sm playfair font-semibold">
                    {userDetails?.[item]?.name}
                  </h2>
                </div>
              ))}
            </div>
          </div>
          <div className="hidden md:flex justify-center space-x-5 divide-x divide-white items-center">
            <p
              onClick={() => {
                navigate("/");
              }}
              className="text-xs w-max cursor-pointer hover:opacity-70 font-medium"
            >
              Home
            </p>
            <div className="pl-4">
              <p
                onClick={() => {
                  navigate("/login");
                }}
                className="text-xs w-max cursor-pointer hover:opacity-70 font-medium"
              >
                Login
              </p>
            </div>
            <div className="pl-4">
              <a
                target="_blank"
                href="/terms-and-condition"
                className="text-xs w-max cursor-pointer appearance-none hover:opacity-70 font-medium"
              >
                Terms and Condition
              </a>
            </div>
          </div>
        </div>
        <div className="flex-grow p-5 md:p-0">
          <div className="pb-5">
            <div className="flex md:hidden relative justify-between">
              <div
                style={{ top: "50%" }}
                className="absolute w-full z-20 top-4 left-0 bg-trensparent border-t border-dashed border-black h-1.5 "
              ></div>
              {Object?.keys?.(userDetails)?.map?.((item, index) => (
                <div key={index} className="relative z-30 bg-white flex gap-1 ">
                  <div className="w-4 sm:w-6 sm:h-6 relative text-black z-20 text-sm h-4 flex-shrink-0 flex justify-center items-center border border-gray-300 rounded-full bg-white  ">
                    {userDetails?.[item]?.completed ? (
                      <Check fontSize="inherit" />
                    ) : (
                      <div className="w-1/2 bg-[#667085] h-1/2 font-semibold rounded-full flex justify-center items-center text-xs "></div>
                    )}
                  </div>
                  <h2 className="text-[11px]  sm:text-sm playfair font-semibold">
                    {item}
                  </h2>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full md:w-5/6 space-y-5 mx-auto py-3 md:py-10">
            <div className="flex justify-between px-4 py-2 text-white md:text-inherit md:p-0 items-center bg-[#081A51] md:bg-white md:pb-5 border-b border-[#081A51]">
              <h2 className="text-sm sm:text-lg md:text-2xl font-semibold ">
                {userDetails?.[selectedGroup]?.name}
              </h2>
              <p className="text-sm sm:text-lg md:text-2xl font-semibold">
                {userDetails?.[selectedGroup]?.track_array?.indexOf?.(
                  subGroup
                ) + 1}
                /{userDetails?.[selectedGroup]?.track_array?.length}
              </p>
            </div>
            <div className="pt-10 space-y-8">
              {subGroup === "full_name" ? (
                <>
                  <h2 className="text-sm sm:text-lg md:text-xl font-semibold">
                    What’s your full name ?
                  </h2>
                  <div className="w-full relative">
                    <input
                      type="text"
                      value={userDetails?.[selectedGroup]?.[subGroup]}
                      onChange={(e) => {
                        setInputError("");
                        setUserDetails({
                          ...userDetails,
                          [selectedGroup]: {
                            ...userDetails?.[selectedGroup],
                            [subGroup]: e.target.value,
                          },
                        });
                      }}
                      placeholder="Kindly enter your full name"
                      className={`text-sm w-full sm:w-4/5 lg:w-1/2 py-2.5 outline-none ring-0 border ${
                        inputError
                          ? "border-red-400"
                          : "focus:border-blue-600 border-gray-400 "
                      } rounded-lg`}
                    />
                    {inputError && (
                      <p className="text-red-600 absolute top-full mt-1 text-xs font-medium">
                        {inputError}
                      </p>
                    )}
                  </div>
                </>
              ) : subGroup === "phone_number" ? (
                <>
                  <div>
                    <h2 className="text-sm sm:text-lg md:text-xl font-semibold">
                      What’s your phone number?
                    </h2>
                    {/* <p className="text-xs">
                      OTP verification required on phone number
                    </p> */}
                  </div>
                  <div className="w-full relative">
                    <div
                      className={`border relative w-full sm:w-4/5 lg:w-3/5 pl-3  flex gap-2 items-center ${
                        inputError ? "border-red-400" : " border-gray-400 "
                      } rounded-lg`}
                    >
                      <Menu as="div" className="w-max">
                        {({ open }) => (
                          <>
                            <Menu.Button as="div" className="w-max">
                              <p className="flex cursor-pointer border-r border-gray-300 w-max flex-shrink-0 gap-2 text-xs items-center">
                                +
                                {userDetails?.personal?.phone_code?.includes?.(
                                  "+"
                                )
                                  ? userDetails?.personal?.phone_code?.split?.(
                                      "+"
                                    )?.[1]
                                  : userDetails?.personal?.phone_code}
                                <ArrowDropDown fontSize="inherit" />
                              </p>
                            </Menu.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                static
                                className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg h-72 rounded-lg w-full top-full left-0"
                              >
                                <div className="flex items-center text-text-1 justify-between">
                                  <p className="text-10 ">
                                    Select country phone
                                  </p>
                                  <i className="fa-solid text-10 fa-chevron-up"></i>
                                </div>
                                {allCountries.map((actualCountry) => (
                                  <Menu.Item key={actualCountry?.name}>
                                    <div
                                      onClick={() => {
                                        setUserDetails({
                                          ...userDetails,
                                          [selectedGroup]: {
                                            ...userDetails?.personal,
                                            phone_code:
                                              actualCountry?.phonecode,
                                          },
                                        });
                                      }}
                                      className={`w-full rounded-lg cursor-pointer px-3 py-2 ${
                                        userDetails?.["personal"]
                                          ?.phone_code ===
                                        actualCountry?.phonecode
                                          ? "bg-drop-1 text-white"
                                          : "hover:bg-gray-300"
                                      }  text-xs`}
                                    >
                                      {`+${
                                        actualCountry?.phonecode?.includes?.(
                                          "+"
                                        )
                                          ? actualCountry?.phonecode?.split?.(
                                              "+"
                                            )?.[1]
                                          : actualCountry?.phonecode
                                      } - ${actualCountry?.name}`}
                                    </div>
                                  </Menu.Item>
                                ))}
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>

                      <input
                        type="number"
                        value={userDetails?.[selectedGroup]?.[subGroup]}
                        onChange={(e) => {
                          setInputError("");
                          setUserDetails({
                            ...userDetails,
                            [selectedGroup]: {
                              ...userDetails?.[selectedGroup],
                              [subGroup]: e.target.value,
                            },
                          });
                        }}
                        placeholder="Please input your phone number here"
                        className={`text-sm flex-grow border-transparent focus:ring-0 focus:border-0 focus:border-none rounded-r-lg py-2.5 outline-none ring-0 border-0 border-none`}
                      />
                    </div>
                    {inputError && (
                      <p className="text-red-600 absolute top-full mt-1 text-xs font-medium">
                        {inputError}
                      </p>
                    )}
                  </div>
                </>
              ) : subGroup === "gender" ? (
                <>
                  <h2 className="text-sm sm:text-lg md:text-xl font-semibold">
                    What’s your gender?
                  </h2>
                  <div className="w-full relative">
                    <Menu
                      as="div"
                      className="w-full sm:w-4/5 lg:w-1/2 relative"
                    >
                      {({ open }) => (
                        <>
                          <Menu.Button as="div" className="">
                            <div className="w-full">
                              <div
                                className={`text-sm py-2.5 px-5 w-full outline-none ring-0 border ${
                                  inputError
                                    ? "border-red-400"
                                    : "focus:border-blue-600 border-gray-400 "
                                } rounded-lg flex justify-between items-center cursor-pointer`}
                              >
                                <p className="text-sm">
                                  {userDetails?.[selectedGroup]?.[subGroup] ===
                                  ""
                                    ? "Select gender"
                                    : userDetails?.[selectedGroup]?.[subGroup]}
                                </p>
                                <i className="fa-solid text-xs fa-chevron-down"></i>
                              </div>
                            </div>
                          </Menu.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                            >
                              <div className="flex items-center text-text-1 justify-between">
                                <p className="text-10 ">Select gender</p>
                                <i className="fa-solid text-10 fa-chevron-up"></i>
                              </div>
                              {GENDERS.map((gender) => (
                                <Menu.Item key={gender}>
                                  <div
                                    onClick={() => {
                                      setInputError("");
                                      setUserDetails({
                                        ...userDetails,
                                        [selectedGroup]: {
                                          ...userDetails?.[selectedGroup],
                                          [subGroup]: gender,
                                        },
                                      });
                                    }}
                                    className={`w-full rounded-lg cursor-pointer px-3 py-2 ${
                                      userDetails?.[selectedGroup]?.[
                                        subGroup
                                      ] === gender
                                        ? "bg-drop-1 text-white"
                                        : "hover:bg-gray-300"
                                    }  text-xs`}
                                  >
                                    {gender}
                                  </div>
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                    {inputError && (
                      <p className="text-red-600 absolute top-full mt-1 text-xs font-medium">
                        {inputError}
                      </p>
                    )}
                  </div>
                </>
              ) : subGroup === "location" ? (
                <>
                  <h2 className="text-sm sm:text-lg md:text-xl font-semibold">
                    What’s your location?
                  </h2>
                  <div className="w-full relative">
                    <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                      <Menu as="div" className="w-full relative">
                        {({ open }) => (
                          <>
                            <Menu.Button as="div" className="">
                              <div className="w-full">
                                <div
                                  className={`text-sm py-2.5 px-5 w-full outline-none ring-0 border ${
                                    inputError &&
                                    userDetails?.[selectedGroup]?.[
                                      "country"
                                    ] === ""
                                      ? "border-red-400"
                                      : "focus:border-blue-600 border-gray-400 "
                                  } rounded-lg flex justify-between items-center cursor-pointer`}
                                >
                                  <p className="text-sm block truncate">
                                    {userDetails?.[selectedGroup]?.[
                                      "country"
                                    ] === ""
                                      ? "Select your country"
                                      : userDetails?.[selectedGroup]?.[
                                          "country"
                                        ]}
                                  </p>
                                  <i className="fa-solid text-xs fa-chevron-down"></i>
                                </div>
                              </div>
                            </Menu.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                static
                                className="absolute h-60 z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                              >
                                {/* <div className="flex items-center text-text-1 justify-between">
                                  <p className="text-10 ">Select gender</p>
                                  <i className="fa-solid text-10 fa-chevron-up"></i>
                                </div> */}
                                {allCountries
                                  ?.map((item) => item?.name)
                                  ?.map((cout) => (
                                    <Menu.Item key={cout}>
                                      <div
                                        onClick={() => {
                                          setInputError("");
                                          setUserDetails({
                                            ...userDetails,
                                            [selectedGroup]: {
                                              ...userDetails?.[selectedGroup],
                                              ["country"]: cout,
                                              ["state"]: "",
                                              ["city"]: "",
                                            },
                                          });
                                        }}
                                        className={`w-full rounded-lg cursor-pointer px-3 py-2 ${
                                          userDetails?.[selectedGroup]?.[
                                            "country"
                                          ] === cout
                                            ? "bg-drop-1 text-white"
                                            : "hover:bg-gray-300"
                                        }  text-xs`}
                                      >
                                        {cout}
                                      </div>
                                    </Menu.Item>
                                  ))}
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                      {userDetails?.[selectedGroup]?.["country"] ? (
                        State.getStatesOfCountry(
                          allCountries?.filter?.(
                            (i) =>
                              i?.name ===
                              userDetails?.[selectedGroup]?.["country"]
                          )?.[0]?.isoCode
                        )?.map((item) => item?.name)?.length > 0 ? (
                          <Menu as="div" className="w-full relative">
                            {({ open }) => (
                              <>
                                <Menu.Button as="div" className="">
                                  <div className="w-full">
                                    <div
                                      className={`text-sm py-2.5 px-5 w-full outline-none ring-0 border ${
                                        inputError &&
                                        userDetails?.[selectedGroup]?.[
                                          "state"
                                        ] === ""
                                          ? "border-red-400"
                                          : "focus:border-blue-600 border-gray-400 "
                                      } rounded-lg flex justify-between items-center cursor-pointer`}
                                    >
                                      <p className="text-sm block truncate">
                                        {userDetails?.[selectedGroup]?.[
                                          "state"
                                        ] === ""
                                          ? "Select your state"
                                          : userDetails?.[selectedGroup]?.[
                                              "state"
                                            ]}
                                      </p>
                                      <i className="fa-solid text-xs fa-chevron-down"></i>
                                    </div>
                                  </div>
                                </Menu.Button>
                                <Transition
                                  show={open}
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items
                                    static
                                    className="absolute h-60 z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                                  >
                                    {/* <div className="flex items-center text-text-1 justify-between">
                                    <p className="text-10 ">Select gender</p>
                                    <i className="fa-solid text-10 fa-chevron-up"></i>
                                  </div> */}
                                    {State.getStatesOfCountry(
                                      allCountries?.filter?.(
                                        (i) =>
                                          i?.name ===
                                          userDetails?.[selectedGroup]?.[
                                            "country"
                                          ]
                                      )?.[0]?.isoCode
                                    )
                                      ?.map((item) => item?.name)
                                      .map((cout) => (
                                        <Menu.Item key={cout}>
                                          <div
                                            onClick={() => {
                                              setInputError("");
                                              setUserDetails({
                                                ...userDetails,
                                                [selectedGroup]: {
                                                  ...userDetails?.[
                                                    selectedGroup
                                                  ],
                                                  ["state"]: cout,
                                                  ["city"]: "",
                                                },
                                              });
                                            }}
                                            className={`w-full rounded-lg cursor-pointer px-3 py-2 ${
                                              userDetails?.[selectedGroup]?.[
                                                "state"
                                              ] === cout
                                                ? "bg-drop-1 text-white"
                                                : "hover:bg-gray-300"
                                            }  text-xs`}
                                          >
                                            {cout}
                                          </div>
                                        </Menu.Item>
                                      ))}
                                  </Menu.Items>
                                </Transition>
                              </>
                            )}
                          </Menu>
                        ) : (
                          <input
                            placeholder="Enter State"
                            value={userDetails?.[selectedGroup]?.["state"]}
                            onChange={(e) => {
                              setInputError("");
                              setUserDetails({
                                ...userDetails,
                                [selectedGroup]: {
                                  ...userDetails?.[selectedGroup],
                                  ["state"]: e.target.value,
                                },
                              });
                            }}
                            className={`text-sm py-2.5 px-5 w-full outline-none ring-0 border ${
                              inputError &&
                              userDetails?.[selectedGroup]?.["state"] === ""
                                ? "border-red-400"
                                : "focus:border-blue-600 border-gray-400 "
                            } rounded-lg flex justify-between items-center cursor-pointer`}
                          />
                        )
                      ) : (
                        <div
                          className={`text-sm py-2.5 px-5 w-full outline-none ring-0 border ${
                            inputError &&
                            userDetails?.[selectedGroup]?.["state"] === ""
                              ? "border-red-400"
                              : "focus:border-blue-600 border-gray-400 "
                          } rounded-lg flex justify-between items-center cursor-pointer`}
                        >
                          <p className="text-sm">
                            {userDetails?.[selectedGroup]?.["state"] === ""
                              ? "Select your state"
                              : userDetails?.[selectedGroup]?.["state"]}
                          </p>
                          <i className="fa-solid text-xs fa-chevron-down"></i>
                        </div>
                      )}
                      {userDetails?.[selectedGroup]?.["state"] ? (
                        City.getCitiesOfState(
                          allCountries?.filter?.(
                            (i) =>
                              i?.name ===
                              userDetails?.[selectedGroup]?.["country"]
                          )?.[0]?.isoCode,
                          State.getStatesOfCountry(
                            allCountries?.filter?.(
                              (i) =>
                                i?.name ===
                                userDetails?.[selectedGroup]?.["country"]
                            )?.[0]?.isoCode
                          )?.filter(
                            (i) =>
                              i?.name ===
                              userDetails?.[selectedGroup]?.["state"]
                          )?.[0]?.isoCode
                        )?.map((item) => item?.name)?.length > 0 ? (
                          <Menu as="div" className="w-full relative">
                            {({ open }) => (
                              <>
                                <Menu.Button as="div" className="">
                                  <div className="w-full">
                                    <div
                                      className={`text-sm py-2.5 px-5 w-full outline-none ring-0 border ${
                                        inputError &&
                                        userDetails?.[selectedGroup]?.[
                                          "city"
                                        ] === ""
                                          ? "border-red-400"
                                          : "focus:border-blue-600 border-gray-400 "
                                      } rounded-lg flex justify-between items-center cursor-pointer`}
                                    >
                                      <p className="text-sm">
                                        {userDetails?.[selectedGroup]?.[
                                          "city"
                                        ] === ""
                                          ? "Select your city"
                                          : userDetails?.[selectedGroup]?.[
                                              "city"
                                            ]}
                                      </p>
                                      <i className="fa-solid text-xs fa-chevron-down"></i>
                                    </div>
                                  </div>
                                </Menu.Button>
                                <Transition
                                  show={open}
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items
                                    static
                                    className="absolute h-60 z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                                  >
                                    {/* <div className="flex items-center text-text-1 justify-between">
                                    <p className="text-10 ">Select gender</p>
                                    <i className="fa-solid text-10 fa-chevron-up"></i>
                                  </div> */}
                                    {City.getCitiesOfState(
                                      allCountries?.filter?.(
                                        (i) =>
                                          i?.name ===
                                          userDetails?.[selectedGroup]?.[
                                            "country"
                                          ]
                                      )?.[0]?.isoCode,
                                      State.getStatesOfCountry(
                                        allCountries?.filter?.(
                                          (i) =>
                                            i?.name ===
                                            userDetails?.[selectedGroup]?.[
                                              "country"
                                            ]
                                        )?.[0]?.isoCode
                                      )?.filter(
                                        (i) =>
                                          i?.name ===
                                          userDetails?.[selectedGroup]?.[
                                            "state"
                                          ]
                                      )?.[0]?.isoCode
                                    )
                                      ?.map((item) => item?.name)

                                      ?.map((cout) => (
                                        <Menu.Item key={cout}>
                                          <div
                                            onClick={() => {
                                              setInputError("");
                                              setUserDetails({
                                                ...userDetails,
                                                [selectedGroup]: {
                                                  ...userDetails?.[
                                                    selectedGroup
                                                  ],
                                                  ["city"]: cout,
                                                },
                                              });
                                            }}
                                            className={`w-full rounded-lg cursor-pointer px-3 py-2 ${
                                              userDetails?.[selectedGroup]?.[
                                                "city"
                                              ] === cout
                                                ? "bg-drop-1 text-white"
                                                : "hover:bg-gray-300"
                                            }  text-xs`}
                                          >
                                            {cout}
                                          </div>
                                        </Menu.Item>
                                      ))}
                                  </Menu.Items>
                                </Transition>
                              </>
                            )}
                          </Menu>
                        ) : (
                          <input
                            placeholder="Enter City"
                            value={userDetails?.[selectedGroup]?.["city"]}
                            onChange={(e) => {
                              setInputError("");
                              setUserDetails({
                                ...userDetails,
                                [selectedGroup]: {
                                  ...userDetails?.[selectedGroup],
                                  ["city"]: e.target.value,
                                },
                              });
                            }}
                            className={`text-sm py-2.5 px-5 w-full outline-none ring-0 border ${
                              inputError &&
                              userDetails?.[selectedGroup]?.["city"] === ""
                                ? "border-red-400"
                                : "focus:border-blue-600 border-gray-400 "
                            } rounded-lg flex justify-between items-center cursor-pointer`}
                          />
                        )
                      ) : (
                        <div
                          className={`text-sm py-2.5 px-5 w-full outline-none ring-0 border ${
                            inputError &&
                            userDetails?.[selectedGroup]?.["city"] === ""
                              ? "border-red-400"
                              : "focus:border-blue-600 border-gray-400 "
                          } rounded-lg flex justify-between items-center cursor-pointer`}
                        >
                          <p className="text-sm">
                            {userDetails?.[selectedGroup]?.["city"] === ""
                              ? "Select your city"
                              : userDetails?.[selectedGroup]?.["city"]}
                          </p>
                          <i className="fa-solid text-xs fa-chevron-down"></i>
                        </div>
                      )}
                    </div>
                    {inputError && (
                      <p className="text-red-600 absolute top-full mt-1 text-xs font-medium">
                        {inputError}
                      </p>
                    )}
                  </div>
                </>
              ) : subGroup === "date_of_birth" ? (
                <>
                  <h2 className="text-sm sm:text-lg md:text-xl font-semibold">
                    What’s your birthday?
                  </h2>
                  <div className="w-full relative">
                    <input
                      type="date"
                      max={getToday()}
                      value={userDetails?.[selectedGroup]?.[subGroup]}
                      onChange={(e) => {
                        setInputError("");
                        setUserDetails({
                          ...userDetails,
                          [selectedGroup]: {
                            ...userDetails?.[selectedGroup],
                            [subGroup]: e.target.value,
                          },
                        });
                      }}
                      placeholder="Kindly enter your full name"
                      className={`text-sm w-full sm:w-4/5 lg:w-1/2  py-2.5 outline-none ring-0 border ${
                        inputError
                          ? "border-red-400"
                          : "focus:border-blue-600 border-gray-400 "
                      } rounded-lg`}
                    />
                    {inputError && (
                      <p className="text-red-600 absolute top-full mt-1 text-xs font-medium">
                        {inputError}
                      </p>
                    )}
                  </div>
                </>
              ) : subGroup === "occupation" ? (
                <>
                  <div>
                    <h2 className="text-sm sm:text-lg md:text-xl font-semibold">
                      What do you do for a living?
                    </h2>
                    <p className="text-sm">
                      We ask for your occupation to match you with individuals
                      with similar and/or compatible interests and lifestyle
                    </p>
                  </div>
                  <div className="grid gap-5 grid-cols-1 relative sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
                    <div className="w-full relative">
                      <Menu as="div" className="w-full relative">
                        {({ open }) => (
                          <>
                            <Menu.Button as="div" className="">
                              <div className="w-full">
                                <div
                                  className={`text-sm py-2.5 px-5 w-full outline-none ring-0 border ${
                                    inputError &&
                                    userDetails?.[selectedGroup]?.[
                                      "occupation_category"
                                    ] === ""
                                      ? "border-red-400"
                                      : "focus:border-blue-600 border-gray-400 "
                                  } rounded-lg flex justify-between items-center cursor-pointer`}
                                >
                                  <p className="text-sm">
                                    {userDetails?.[selectedGroup]?.[
                                      "occupation_category"
                                    ] === ""
                                      ? "Select your Occupation Category"
                                      : userDetails?.[selectedGroup]?.[
                                          "occupation_category"
                                        ]}
                                  </p>
                                  <i className="fa-solid text-xs fa-chevron-down"></i>
                                </div>
                              </div>
                            </Menu.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                static
                                className="absolute h-60 z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                              >
                                {OCCUPATION_CATEGORY?.map((cout) => (
                                  <Menu.Item key={cout}>
                                    <div
                                      onClick={() => {
                                        setInputError("");
                                        setUserDetails({
                                          ...userDetails,
                                          [selectedGroup]: {
                                            ...userDetails?.[selectedGroup],
                                            ["occupation_category"]: cout,
                                            ["occupation"]: "",
                                          },
                                        });
                                      }}
                                      className={`w-full rounded-lg cursor-pointer px-3 py-2 ${
                                        userDetails?.[selectedGroup]?.[
                                          "occupation_category"
                                        ] === cout
                                          ? "bg-drop-1 text-white"
                                          : "hover:bg-gray-300"
                                      }  text-xs`}
                                    >
                                      {cout}
                                    </div>
                                  </Menu.Item>
                                ))}
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                    </div>
                    <div className="w-full relative">
                      <input
                        type="text"
                        value={userDetails?.[selectedGroup]?.[subGroup]}
                        onChange={(e) => {
                          setInputError("");
                          setUserDetails({
                            ...userDetails,
                            [selectedGroup]: {
                              ...userDetails?.[selectedGroup],
                              [subGroup]: e.target.value,
                            },
                          });
                        }}
                        placeholder="Enter specific occupation"
                        className={`text-sm w-full py-2.5 outline-none ring-0 border ${
                          inputError
                            ? "border-red-400"
                            : "focus:border-blue-600 border-gray-400 "
                        } rounded-lg`}
                      />
                    </div>
                    {inputError && (
                      <p className="text-red-600 absolute top-full mt-1 text-xs font-medium">
                        {inputError}
                      </p>
                    )}
                  </div>
                </>
              ) : subGroup === "income" ? (
                <>
                  <h2 className="text-sm sm:text-lg md:text-xl font-semibold">
                    What is your average yearly income in Dollars ?
                  </h2>
                  <p className="text-sm">
                    We ask for your annual income range to ensure we onboards
                    members with compatible lifestyles.
                  </p>
                  <div className="w-full relative">
                    <Menu
                      as="div"
                      className="w-full sm:w-4/5 lg:w-1/2  relative"
                    >
                      {({ open }) => (
                        <>
                          <Menu.Button as="div" className="">
                            <div className="w-full">
                              <div
                                className={`text-sm py-2.5 px-5 w-full outline-none ring-0 border ${
                                  inputError
                                    ? "border-red-400"
                                    : "focus:border-blue-600 border-gray-400 "
                                } rounded-lg flex justify-between items-center cursor-pointer`}
                              >
                                <p className="text-sm">
                                  {userDetails?.[selectedGroup]?.[subGroup] ===
                                  ""
                                    ? "Select annual amount"
                                    : userDetails?.[selectedGroup]?.[subGroup]}
                                </p>
                                <i className="fa-solid text-xs fa-chevron-down"></i>
                              </div>
                            </div>
                          </Menu.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="absolute h-60 z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                            >
                              <div className="flex items-center text-text-1 justify-between">
                                <p className="text-10 ">Select annual income</p>
                                <i className="fa-solid text-10 fa-chevron-up"></i>
                              </div>
                              {INCOMES.map((amount) => (
                                <Menu.Item key={amount}>
                                  <div
                                    onClick={() => {
                                      setInputError("");
                                      setUserDetails({
                                        ...userDetails,
                                        [selectedGroup]: {
                                          ...userDetails?.[selectedGroup],
                                          [subGroup]: amount,
                                        },
                                      });
                                    }}
                                    className={`w-full rounded-lg cursor-pointer px-3 py-2 ${
                                      userDetails?.[selectedGroup]?.[
                                        subGroup
                                      ] === amount
                                        ? "bg-drop-1 text-white"
                                        : "hover:bg-gray-300"
                                    }  text-xs`}
                                  >
                                    {amount}
                                  </div>
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                    {inputError && (
                      <p className="text-red-600 absolute top-full mt-1 text-xs font-medium">
                        {inputError}
                      </p>
                    )}
                  </div>
                </>
              ) : subGroup === "preferred_gender" ? (
                <>
                  <h2 className="text-sm sm:text-lg md:text-xl font-semibold">
                    What gender are you looking to date ?
                  </h2>

                  <div className="w-full relative">
                    <Menu
                      as="div"
                      className="w-full sm:w-4/5 lg:w-1/2  relative"
                    >
                      {({ open }) => (
                        <>
                          <Menu.Button as="div" className="">
                            <div className="w-full">
                              <div
                                className={`text-sm py-2.5 px-5 w-full outline-none ring-0 border ${
                                  inputError
                                    ? "border-red-400"
                                    : "focus:border-blue-600 border-gray-400 "
                                } rounded-lg flex justify-between items-center cursor-pointer`}
                              >
                                <p className="text-sm">
                                  {userDetails?.[selectedGroup]?.[subGroup] ===
                                  ""
                                    ? "Select gender"
                                    : userDetails?.[selectedGroup]?.[subGroup]}
                                </p>
                                <i className="fa-solid text-xs fa-chevron-down"></i>
                              </div>
                            </div>
                          </Menu.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                            >
                              <div className="flex items-center text-text-1 justify-between">
                                <p className="text-10 ">Select gender</p>
                                <i className="fa-solid text-10 fa-chevron-up"></i>
                              </div>
                              {GENDERS.map((gender) => (
                                <Menu.Item key={gender}>
                                  <div
                                    onClick={() => {
                                      setInputError("");
                                      setUserDetails({
                                        ...userDetails,
                                        [selectedGroup]: {
                                          ...userDetails?.[selectedGroup],
                                          [subGroup]: gender,
                                        },
                                      });
                                    }}
                                    className={`w-full rounded-lg cursor-pointer px-3 py-2 ${
                                      userDetails?.[selectedGroup]?.[
                                        subGroup
                                      ] === gender
                                        ? "bg-drop-1 text-white"
                                        : "hover:bg-gray-300"
                                    }  text-xs`}
                                  >
                                    {gender}
                                  </div>
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                    {inputError && (
                      <p className="text-red-600 absolute top-full mt-1 text-xs font-medium">
                        {inputError}
                      </p>
                    )}
                  </div>
                </>
              ) : subGroup === "age_range" ? (
                <>
                  <h2 className="text-sm sm:text-lg md:text-xl font-semibold">
                    What age range(s) are you open to date ?
                  </h2>

                  <div className="w-full relative">
                    <Menu
                      as="div"
                      className="w-full sm:w-4/5 lg:w-1/2  relative"
                    >
                      {({ open }) => (
                        <>
                          <Menu.Button as="div" className="">
                            <div className="w-full">
                              <div
                                className={`text-sm py-2.5 px-5 w-full outline-none ring-0 border ${
                                  inputError
                                    ? "border-red-400"
                                    : "focus:border-blue-600 border-gray-400 "
                                } rounded-lg flex justify-between items-center cursor-pointer`}
                              >
                                <p className="text-sm">
                                  {userDetails?.[selectedGroup]?.[subGroup] ===
                                  ""
                                    ? "Select age range"
                                    : userDetails?.[selectedGroup]?.[subGroup]}
                                </p>
                                <i className="fa-solid text-xs fa-chevron-down"></i>
                              </div>
                            </div>
                          </Menu.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                            >
                              <div className="flex items-center text-text-1 justify-between">
                                <p className="text-10 ">Select age range</p>
                                <i className="fa-solid text-10 fa-chevron-up"></i>
                              </div>
                              {AGERANGE.map((age) => (
                                <Menu.Item key={age}>
                                  <div
                                    onClick={() => {
                                      setInputError("");
                                      setUserDetails({
                                        ...userDetails,
                                        [selectedGroup]: {
                                          ...userDetails?.[selectedGroup],
                                          [subGroup]: age,
                                        },
                                      });
                                    }}
                                    className={`w-full rounded-lg cursor-pointer px-3 py-2 ${
                                      userDetails?.[selectedGroup]?.[
                                        subGroup
                                      ] === age
                                        ? "bg-drop-1 text-white"
                                        : "hover:bg-gray-300"
                                    }  text-xs`}
                                  >
                                    {age}
                                  </div>
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                    {inputError && (
                      <p className="text-red-600 absolute top-full mt-1 text-xs font-medium">
                        {inputError}
                      </p>
                    )}
                  </div>
                </>
              ) : subGroup === "time_wasting" ? (
                <>
                  <h2 className="text-sm sm:text-lg md:text-xl font-semibold">
                    Do you agree with the following statement ?
                  </h2>
                  <p className="text-sm">
                    I feel like I am wasting my time trying to find a partner on
                    online dating platforms.
                  </p>
                  <div className="w-full relative">
                    <Menu
                      as="div"
                      className="w-full sm:w-4/5 lg:w-1/2  relative"
                    >
                      {({ open }) => (
                        <>
                          <Menu.Button as="div" className="">
                            <div className="w-full">
                              <div
                                className={`text-sm py-2.5 px-5 w-full outline-none ring-0 border ${
                                  inputError
                                    ? "border-red-400"
                                    : "focus:border-blue-600 border-gray-400 "
                                } rounded-lg flex justify-between items-center cursor-pointer`}
                              >
                                <p className="text-sm">
                                  {userDetails?.[selectedGroup]?.[subGroup] ===
                                  ""
                                    ? "Select answer"
                                    : userDetails?.[selectedGroup]?.[subGroup]}
                                </p>
                                <i className="fa-solid text-xs fa-chevron-down"></i>
                              </div>
                            </div>
                          </Menu.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                            >
                              <div className="flex items-center text-text-1 justify-between">
                                <p className="text-10 ">Select answer</p>
                                <i className="fa-solid text-10 fa-chevron-up"></i>
                              </div>
                              {ASKS.map((decision) => (
                                <Menu.Item key={decision}>
                                  <div
                                    onClick={() => {
                                      setInputError("");
                                      setUserDetails({
                                        ...userDetails,
                                        [selectedGroup]: {
                                          ...userDetails?.[selectedGroup],
                                          [subGroup]: decision,
                                        },
                                      });
                                    }}
                                    className={`w-full rounded-lg cursor-pointer px-3 py-2 ${
                                      userDetails?.[selectedGroup]?.[
                                        subGroup
                                      ] === decision
                                        ? "bg-drop-1 text-white"
                                        : "hover:bg-gray-300"
                                    }  text-xs`}
                                  >
                                    {decision}
                                  </div>
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                    {inputError && (
                      <p className="text-red-600 absolute top-full mt-1 text-xs font-medium">
                        {inputError}
                      </p>
                    )}
                  </div>
                </>
              ) : subGroup === "kids_available" ? (
                <>
                  <h2 className="text-sm sm:text-lg md:text-xl font-semibold">
                    How many kids do you have?
                  </h2>

                  <div className="w-full relative">
                    <Menu
                      as="div"
                      className="w-full sm:w-4/5 lg:w-1/2  relative"
                    >
                      {({ open }) => (
                        <>
                          <Menu.Button as="div" className="">
                            <div className="w-full">
                              <div
                                className={`text-sm py-2.5 px-5 w-full outline-none ring-0 border ${
                                  inputError
                                    ? "border-red-400"
                                    : "focus:border-blue-600 border-gray-400 "
                                } rounded-lg flex justify-between items-center cursor-pointer`}
                              >
                                <p className="text-sm">
                                  {userDetails?.[selectedGroup]?.[subGroup] ===
                                  ""
                                    ? "Select answer"
                                    : userDetails?.[selectedGroup]?.[subGroup]}
                                </p>
                                <i className="fa-solid text-xs fa-chevron-down"></i>
                              </div>
                            </div>
                          </Menu.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                            >
                              <div className="flex items-center text-text-1 justify-between">
                                <p className="text-10 ">Select answer</p>
                                <i className="fa-solid text-10 fa-chevron-up"></i>
                              </div>
                              {KIDS.map((kid) => (
                                <Menu.Item key={kid}>
                                  <div
                                    onClick={() => {
                                      setInputError("");
                                      setUserDetails({
                                        ...userDetails,
                                        [selectedGroup]: {
                                          ...userDetails?.[selectedGroup],
                                          [subGroup]: kid,
                                        },
                                      });
                                    }}
                                    className={`w-full rounded-lg cursor-pointer px-3 py-2 ${
                                      userDetails?.[selectedGroup]?.[
                                        subGroup
                                      ] === kid
                                        ? "bg-drop-1 text-white"
                                        : "hover:bg-gray-300"
                                    }  text-xs`}
                                  >
                                    {kid}
                                  </div>
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                    {inputError && (
                      <p className="text-red-600 absolute top-full mt-1 text-xs font-medium">
                        {inputError}
                      </p>
                    )}
                  </div>
                </>
              ) : subGroup === "commited_relationship" ? (
                <>
                  <h2 className="text-sm sm:text-lg md:text-xl font-semibold">
                    How soon would you like to be in a committed relationship?
                  </h2>

                  <div className="w-full relative">
                    <Menu
                      as="div"
                      className="w-full sm:w-4/5 lg:w-1/2  relative"
                    >
                      {({ open }) => (
                        <>
                          <Menu.Button as="div" className="">
                            <div className="w-full">
                              <div
                                className={`text-sm py-2.5 px-5 w-full outline-none ring-0 border ${
                                  inputError
                                    ? "border-red-400"
                                    : "focus:border-blue-600 border-gray-400 "
                                } rounded-lg flex justify-between items-center cursor-pointer`}
                              >
                                <p className="text-sm">
                                  {userDetails?.[selectedGroup]?.[subGroup] ===
                                  ""
                                    ? "Select answer"
                                    : userDetails?.[selectedGroup]?.[subGroup]}
                                </p>
                                <i className="fa-solid text-xs fa-chevron-down"></i>
                              </div>
                            </div>
                          </Menu.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                            >
                              <div className="flex items-center text-text-1 justify-between">
                                <p className="text-10 ">Select answer</p>
                                <i className="fa-solid text-10 fa-chevron-up"></i>
                              </div>
                              {CURIOUSNESS.map((curious) => (
                                <Menu.Item key={curious}>
                                  <div
                                    onClick={() => {
                                      setInputError("");
                                      setUserDetails({
                                        ...userDetails,
                                        [selectedGroup]: {
                                          ...userDetails?.[selectedGroup],
                                          [subGroup]: curious,
                                        },
                                      });
                                    }}
                                    className={`w-full rounded-lg cursor-pointer px-3 py-2 ${
                                      userDetails?.[selectedGroup]?.[
                                        subGroup
                                      ] === curious
                                        ? "bg-drop-1 text-white"
                                        : "hover:bg-gray-300"
                                    }  text-xs`}
                                  >
                                    {curious}
                                  </div>
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                    {inputError && (
                      <p className="text-red-600 absolute top-full mt-1 text-xs font-medium">
                        {inputError}
                      </p>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <h2 className="text-sm sm:text-lg md:text-xl font-semibold">
                    How did you hear about us ?
                  </h2>

                  <div className="w-full relative">
                    <Menu
                      as="div"
                      className="w-full sm:w-4/5 lg:w-1/2  relative"
                    >
                      {({ open }) => (
                        <>
                          <Menu.Button as="div" className="">
                            <div className="w-full">
                              <div
                                className={`text-sm py-2.5 px-5 w-full outline-none ring-0 border ${
                                  inputError
                                    ? "border-red-400"
                                    : "focus:border-blue-600 border-gray-400 "
                                } rounded-lg flex justify-between items-center cursor-pointer`}
                              >
                                <p className="text-sm">
                                  {userDetails?.[selectedGroup]?.[subGroup] ===
                                  ""
                                    ? "Select answer"
                                    : userDetails?.[selectedGroup]?.[subGroup]}
                                </p>
                                <i className="fa-solid text-xs fa-chevron-down"></i>
                              </div>
                            </div>
                          </Menu.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="absolute h-60 z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                            >
                              <div className="flex items-center text-text-1 justify-between">
                                <p className="text-10 ">Select answer</p>
                                <i className="fa-solid text-10 fa-chevron-up"></i>
                              </div>
                              {INFO.map((place) => (
                                <Menu.Item key={place}>
                                  <div
                                    onClick={() => {
                                      setInputError("");
                                      setUserDetails({
                                        ...userDetails,
                                        [selectedGroup]: {
                                          ...userDetails?.[selectedGroup],
                                          [subGroup]: place,
                                        },
                                      });
                                    }}
                                    className={`w-full rounded-lg cursor-pointer px-3 py-2 ${
                                      userDetails?.[selectedGroup]?.[
                                        subGroup
                                      ] === place
                                        ? "bg-drop-1 text-white"
                                        : "hover:bg-gray-300"
                                    }  text-xs`}
                                  >
                                    {place}
                                  </div>
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                    {inputError && (
                      <p className="text-red-600 absolute top-full mt-1 text-xs font-medium">
                        {inputError}
                      </p>
                    )}
                  </div>

                  <div className="flex gap-5 items-center">
                    <input
                      type="checkbox"
                      checked={userDetails?.[selectedGroup]?.["agreed"]}
                      className="w-5 h-5"
                      onClick={() => {
                        setUserDetails({
                          ...userDetails,
                          [selectedGroup]: {
                            ...userDetails?.[selectedGroup],
                            ["agreed"]:
                              !userDetails?.[selectedGroup]?.["agreed"],
                          },
                        });
                      }}
                    />
                    <p className="text-xs font-medium">
                      I ({userDetails?.personal?.full_name}) hereby affirm and
                      consent that all the information provided by me is
                      accurate, complete, and truthful to the best of my
                      knowledge. I understand that providing false, misleading,
                      or incomplete information is a violation of this consent
                      and may result in legal and/or administrative actions
                    </p>
                  </div>
                </>
              )}
              <div className="pt-5 flex flex-col-reverse sm:flex-row gap-5 items-center">
                {subGroup !== "full_name" && (
                  <button
                    onClick={() => {
                      setInputError("");
                      const currentIndex =
                        userDetails?.[selectedGroup]?.track_array?.indexOf?.(
                          subGroup
                        );
                      const previousGroup =
                        userDetails?.[selectedGroup]?.track_array?.[
                          currentIndex - 1
                        ];
                      if (previousGroup) {
                        return setSubGroup(previousGroup);
                      }

                      const currentGroupIndex =
                        Object?.keys(userDetails)?.indexOf(selectedGroup);
                      const nextMainGroup =
                        Object?.keys(userDetails)?.[currentGroupIndex - 1];

                      if (nextMainGroup) {
                        const nextMainGroupSub =
                          userDetails?.[nextMainGroup]?.track_array?.[
                            userDetails?.[nextMainGroup]?.track_array?.length -
                              1
                          ];
                        setSelectedGroup(nextMainGroup);
                        setSubGroup(nextMainGroupSub);
                        return;
                      }
                    }}
                    className="px-4 w-full sm:w-max sm:px-6 py-2 border border-[#081a51] text-[#081a51] jarkata_sans text-sm sm:text-base rounded sm:rounded-lg flex gap-2 items-center justify-center font-medium"
                  >
                    <KeyboardBackspace fontSize="inherit" />
                    Previous
                  </button>
                )}
                <button
                  onClick={() => {
                    if (
                      subGroup !== "location" &&
                      subGroup !== "occupation" &&
                      userDetails?.[selectedGroup]?.[subGroup]?.trim?.() === ""
                    ) {
                      return setInputError("Kindly fill all fields");
                    }
                    if (subGroup === "location") {
                      if (
                        userDetails?.[selectedGroup]?.["country"] === "" ||
                        userDetails?.[selectedGroup]?.["state"] === "" ||
                        userDetails?.[selectedGroup]?.["city"] === ""
                      ) {
                        return setInputError("Kindly fill all location fields");
                      }
                    }
                    if (subGroup === "occupation") {
                      if (
                        userDetails?.[selectedGroup]?.["occupation"] === "" ||
                        userDetails?.[selectedGroup]?.[
                          "occupation_category"
                        ] === ""
                      ) {
                        return setInputError(
                          "Kindly fill all occupation fields"
                        );
                      }
                    }
                    if (subGroup === "phone_number") {
                      const enteredValue =
                        +userDetails?.[selectedGroup]?.phone_number;
                      if (enteredValue <= 0) {
                        return setInputError(
                          "Kindly enter a valid phone number"
                        );
                      }

                      const pCode =
                        userDetails?.personal?.phone_code?.includes?.("+")
                          ? userDetails?.personal?.phone_code?.split?.("+")?.[1]
                          : userDetails?.personal?.phone_code;

                      return handlePhoneCheck(`+${pCode}${enteredValue}`);
                    }
                    if (subGroup === "date_of_birth") {
                      if (
                        !isOver18YearsOld(
                          userDetails?.[selectedGroup]?.date_of_birth
                        )
                      ) {
                        return setInputError("You must be 18 years and above");
                      }
                    }
                    const currentIndex =
                      userDetails?.[selectedGroup]?.track_array?.indexOf?.(
                        subGroup
                      );
                    const nextSubGroup =
                      userDetails?.[selectedGroup]?.track_array?.[
                        currentIndex + 1
                      ];
                    if (nextSubGroup) {
                      return setSubGroup(nextSubGroup);
                    }
                    setUserDetails({
                      ...userDetails,
                      [selectedGroup]: {
                        ...userDetails[selectedGroup],
                        completed: true,
                      },
                    });
                    const currentGroupIndex =
                      Object?.keys(userDetails)?.indexOf(selectedGroup);
                    const nextMainGroup =
                      Object?.keys(userDetails)?.[currentGroupIndex + 1];

                    if (nextMainGroup) {
                      const nextMainGroupSub =
                        userDetails?.[nextMainGroup]?.track_array?.[0];
                      setSelectedGroup(nextMainGroup);
                      setSubGroup(nextMainGroupSub);
                      toast.success(
                        `${userDetails?.[selectedGroup]?.name} completed`
                      );
                      return;
                    }

                    if (userDetails?.["general"]?.["agreed"] === false) {
                      return toast.error(
                        "Kindly agree to the consent statement"
                      );
                    }

                    handleLastClick();
                  }}
                  className="px-4 w-full sm:w-max  sm:px-6 py-2 bg-[#081a51] jarkata_sans text-sm sm:text-base rounded sm:rounded-lg text-white flex gap-2 items-center justify-center font-medium"
                >
                  {isChecking ? (
                    <BeatLoader size={14} color="#fff" />
                  ) : subGroup === "hear_about_us" ? (
                    "Complete and Verify"
                  ) : (
                    <>
                      Proceed
                      <ArrowRightAlt fontSize="inherit" />
                    </>
                  )}
                </button>
              </div>
              <div className="flex pt-20 md:hidden justify-center space-x-5 divide-x divide-gray-300 items-center">
                <p
                  onClick={() => {
                    navigate("/");
                  }}
                  className="text-xs w-max cursor-pointer hover:opacity-70 font-medium"
                >
                  Home
                </p>
                <div className="pl-4">
                  <p
                    onClick={() => {
                      navigate("/login");
                    }}
                    className="text-xs w-max cursor-pointer hover:opacity-70 font-medium"
                  >
                    Login
                  </p>
                </div>
                <div className="pl-4">
                  <a
                    target="_blank"
                    href="/terms-and-condition"
                    className="text-xs w-max cursor-pointer appearance-none hover:opacity-70 font-medium"
                  >
                    Terms and Condition
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { Add, DeleteOutline, Image, Person } from "@mui/icons-material";
import EmptyCard from "../Card/emptyCard";
import NoChat from "src/assets/no_chat.png";
import { useQuery } from "@apollo/client";
import { HANDLER_GET_USER } from "src/hooks/data/queries";
import { useState } from "react";
import ViewImageOverlay from "src/overlays/ViewImagOverlay";
import moment from "moment";
import ASSETS from "src/asset";

export default function ViewProfile({
  user,
  isSetSelected = false,
  userSelected = false,
  handleClick = () => {},
  isHandler = false,
}) {
  const [selectedUser, setSelectedUser] = useState(user);
  const { data } = useQuery(HANDLER_GET_USER, {
    variables: {
      userId: Number(user?.id),
    },
    onCompleted(data) {
      setSelectedUser(data.user);
    },
  });
  const userName = selectedUser?.first_name + " " + selectedUser?.last_name;
  const [selectedImage, setSelectedImage] = useState(null);
  const [openImage, setOpenImage] = useState(false);

  return (
    <div className="space-y-10">
      <ViewImageOverlay
        setIsOpen={setOpenImage}
        size="small"
        isOpen={openImage}
        title={""}
      >
        <div className=" bg-white flex justify-center items-center space-y-3 w-full">
          <div className="bg-gray-200 min-h-[500px] w-full flex justify-center items-center">
            <img
              src={selectedImage ?? ASSETS?.Avi}
              alt="profile"
              style={{
                height: selectedImage ? "500px" : "300px",
                width: "100%",
                backgroundColor: "#eee",
              }}
              className={" object-center md:object-contain"}
            />
          </div>
        </div>
      </ViewImageOverlay>
      <div className="md:pb-16">
        <div
          style={{
            backgroundImage: `url(${ASSETS?.Marvin})`,
          }}
          className="w-full h-40 sm:h-60 lg:h-[297px] bg-[#0000009e] bg-blend-soft-light rounded-lg px-4 py-2 flex justify-between bg-cover bg-center bg-no-repeat relative"
        >
          {
            <div
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              className="absolute text-6xl text-gray-500 lg:text-9xl z-50 w-max "
            >
              <Image fontSize="inherit" color="inherit" />
            </div>
          }
          <div className=" absolute bottom-0 translate-y-5 gap-2 md:gap-5 lg:translate-y-20  flex ">
            <div
              onClick={() => {
                setSelectedImage(user?.profile_image_url ?? null);
                setOpenImage(true);
              }}
              className="lg:w-44 border text-lg lg:text-7xl flex justify-center items-center border-gray-300 bg-white h-12 w-12 sm:w-20 sm:h-20 lg:h-44 max-lg:w-16 max-lg:h-16 rounded-full bg-cover bg-center bg-no-repeat"
            >
              <img
                src={user?.profile_image_url ?? ASSETS?.Avi}
                alt="profile"
                className="h-full w-full object-cover rounded-full"
              />
            </div>
            <span className="text-white sm:mt-5 lg:mt-10  max-lg:text-slate-700 md:text-lg font-bold  capitalize whitespace-nowrap text-xs  ">
              {userName}
            </span>
          </div>
          {isSetSelected && (
            <button
              onClick={() => handleClick()}
              className={`flex ${
                userSelected ? "text-red-600" : "text-green-600"
              } absolute right-3 sm:right-10 font-medium bottom-3 sm:bottom-5 border border-gray-300 bg-white gap-1 items-center text-[10px] sm:text-xs py-2 px-2 sm:px-4 rounded-lg`}
            >
              {userSelected ? (
                <>
                  <DeleteOutline fontSize="inherit" />
                  Deselect User
                </>
              ) : (
                <>
                  <Add fontSize="inherit" />
                  Select User
                </>
              )}
            </button>
          )}
        </div>
      </div>
      <div className=" bg-white divide-y divide-gray-300 shadow-md rounded-lg">
        <h2 className="font-bold p-4 capitalize text-base flex gap-3 items-center flex-wrap">
          About {userName}
          {!isHandler ? null : selectedUser?.subscription?.name === "basic" ? (
            <p className="text-[10px] px-4 py-0.5 w-max text-center rounded-full bg-gray-100 border border-gray-800 text-gray-800">
              {selectedUser?.subscription?.name}
            </p>
          ) : selectedUser?.plan?.toLowerCase?.() === "monthly" ? (
            <p className="text-[10px] px-4 py-0.5 w-max text-center rounded-full bg-purple-100 border border-purple-800 text-purple-800">
              {selectedUser?.plan}
            </p>
          ) : (
            <p className="text-[10px] px-4 py-0.5 w-max text-center rounded-full bg-blue-100 border border-blue-800 text-blue-800">
              {selectedUser?.plan}
            </p>
          )}
        </h2>
        <div className=" p-5 w-full">
          {selectedUser?.about_me ? (
            <p className="text-base font-medium ">{selectedUser?.about_me}</p>
          ) : (
            <p className="text-base italic font-medium ">
              No bio/about available
            </p>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
        <EmptyCard header="Personal Information">
          <div className="p-4 space-y-2 pb-0">
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Fullname:{" "}
              <span className="text-sm font-normal capitalize">
                {selectedUser?.first_name ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Username:{" "}
              <span className="text-sm font-normal capitalize">
                {selectedUser?.alais ?? "N/A"}
              </span>
            </p>

            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Age:{" "}
              <span className="text-sm font-normal capitalize">
                {selectedUser?.age?.includes?.("/")
                  ? //@ts-ignore
                    moment().diff(
                      //@ts-ignore
                      moment(selectedUser?.age, "DD/MM/YYYY"),
                      "years"
                    ) ?? "N/A"
                  : //@ts-ignore
                    moment().diff(
                      //@ts-ignore
                      moment(selectedUser?.age),
                      "years"
                    ) ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Gender:{" "}
              <span className="text-sm font-normal capitalize">
                {selectedUser?.gender ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Genotype:{" "}
              <span className="text-sm font-normal capitalize">
                {selectedUser?.genotype ?? "N/A"}
              </span>
            </p>
            {/* <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Phone Number:{" "}
              <span className="text-sm font-normal capitalize">
                {selectedUser?.phone ?? "N/A"}
              </span>
            </p> */}
            {/* <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Email:{" "}
              <span className="text-sm font-normal capitalize">
                {selectedUser?.email ?? "N/A"}
              </span>
            </p> */}
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Etnicity:{" "}
              <span className="text-sm font-normal capitalize">
                {selectedUser?.ethnicity ?? "N/A"}
              </span>
            </p>
          </div>
        </EmptyCard>
        <EmptyCard header="Other Details">
          <div className="p-4 space-y-2 pb-0">
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Religion:{" "}
              <span className="text-sm font-normal capitalize">
                {selectedUser?.religion ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Occupation:{" "}
              <span className="text-sm font-normal capitalize">
                {selectedUser?.occupation ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Location:{" "}
              <span className="text-sm font-normal capitalize">
                {selectedUser?.location_country ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Place of birth:{" "}
              <span className="text-sm font-normal capitalize">
                {selectedUser?.origin_country ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Highest Academic Qualification:{" "}
              <span className="text-sm font-normal capitalize">
                {selectedUser?.qualification ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Relationship Status:{" "}
              <span className="text-sm font-normal capitalize">
                {selectedUser?.status ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Hobbies:{" "}
              <span className="text-sm font-normal capitalize">
                {selectedUser?.hobbies ?? "N/A"}
              </span>
            </p>
          </div>
        </EmptyCard>
        <EmptyCard header="Preferences">
          <div className="p-4 space-y-2 pb-0">
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Preferred Gender:{" "}
              <span className="text-sm font-normal capitalize">
                {selectedUser?.preferred_gender ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Preferred age range:{" "}
              <span className="text-sm font-normal capitalize">
                {selectedUser?.preferred_age ?? "N/A"}
              </span>
            </p>

            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Preferred Genotype:{" "}
              <span className="text-sm font-normal capitalize">
                {selectedUser?.preferred_genotype ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Preferred Religion:{" "}
              <span className="text-sm font-normal capitalize">
                {selectedUser?.preferred_religion ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Preferred Relationship:{" "}
              <span className="text-sm font-normal capitalize">
                {selectedUser?.preferred_status ?? "N/A"}
              </span>
            </p>
          </div>
        </EmptyCard>
      </div>
      <div className="w-full p-4 rounded-lg shadow-md bg-white">
        <p className="font-bold capitalize text-base">
          {userName + "'s"} Photos
        </p>
        {selectedUser?.media?.length > 0 ? (
          <div className="w-full py-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
            {selectedUser?.media.map((photo: any, i: number) => (
              <img
                key={i}
                onClick={() => {
                  setSelectedImage(photo?.url);
                  setOpenImage(true);
                }}
                className={`h-40 border border-gray-600 object-cover rounded-lg w-full`}
                src={photo?.url}
                alt="match"
              />
            ))}
          </div>
        ) : (
          <div className=" items-center justify-center w-full h-60 bg-white flex">
            <div className=" flex gap-5 flex-col justify-center items-center">
              <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                <img
                  src={NoChat}
                  alt="empty"
                  className="w-5 md:w-10 h-5 md:h-10 object-contain"
                />
              </div>
              <h2 className="text-lg text-center font-bold text-[#081A51] ">
                No Photos available
              </h2>
              <p className="text-sm w-4/5 mx-auto text-center">
                This user doesn't have any photo in their media
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

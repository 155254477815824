import { useQuery } from "@apollo/client";
import { ContentPaste } from "@mui/icons-material";
import moment from "moment";
import { BeatLoader } from "react-spinners";
import { GET_USER_THERAPIST_BOOKING } from "src/hooks/data/queries";

export default function ClientMeetingNotes({ client }) {
  const { data, loading } = useQuery(GET_USER_THERAPIST_BOOKING, {
    variables: {
      page: 1,
      pageSize: 10,
      userId: Number(client?.id),
    },
    fetchPolicy: "network-only",
  });

  const userBookings = data?.getUserBooking?.data;

  const actualNotes =
    userBookings?.length > 0
      ? userBookings?.filter?.((i) => i?.therapist_review)
      : [];

  return (
    <div className=" divide-y divide-gray-300">
      {loading ? (
        <div className="flex h-60 justify-center items-center">
          <BeatLoader color="#FE6D68" size={20} />
        </div>
      ) : actualNotes?.length > 0 ? (
        actualNotes?.map?.((item, index) => (
          <div key={index} className="space-y-3 py-5">
            <div className="flex justify-between items-center">
              <h2 className="text-sm font-semibold">
                Meeting with {item?.therapist?.name}
              </h2>
              <p className="text-xs">
                {moment(item?.availability?.startTime)?.format?.(
                  "Do MMMM YYYY, h:mm a"
                )}
              </p>
            </div>
            <p className="text-sm">{item?.therapist_review}</p>
          </div>
        ))
      ) : (
        <div className="flex h-60 gap-2 justify-center flex-col items-center">
          <div className="w-20 h-20 text-4xl bg-gray-50 border border-[#FE6D68] text-[#FE6D68] rounded-lg flex justify-center items-center">
            <ContentPaste fontSize="inherit" color="inherit" />
          </div>
          No meeting available yet!
        </div>
      )}
    </div>
  );
}

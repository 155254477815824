import React from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { useMediaQuery } from "@mui/material";

const Star = ({ filled, onClick }) => {
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  return filled ? (
    <StarIcon
      style={{
        cursor: "pointer",
        color: "gold",
        fontSize: isSmallScreen ? "24px" : "38px",
      }}
      onClick={onClick}
    />
  ) : (
    <StarBorderIcon
      style={{ cursor: "pointer", fontSize: isSmallScreen ? "24px" : "38px" }}
      onClick={onClick}
    />
  );
};

export default Star;

import { styled } from '@mui/system'
import Theme from 'src/assets/colors/theme'
import Button, { ButtonProps } from '@mui/material/Button'

export interface ButtonCustomProps extends ButtonProps {
  textColor?: string
  backgroundColor?: string
  borderColor?: string
 
}

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'textColor' &&
    prop !== 'backgroundColor' &&
    prop !== 'borderColor',
})<ButtonCustomProps>(
  ({ size, textColor, borderColor, variant, backgroundColor, disabled }) => ({
    boxShadow: 'none',
    textTransform: 'none',
    height: size === 'small' ? '30px' : size === 'medium' ? '36px' : '40px',
    fontWeight: '500',
    fontSize: size === 'small' ? '13px' : size === 'medium' ? '14px' : '15px',
    lineHeight: size === 'small' ? '24px' : '22px',
    letterSpacing: size === 'medium' ? '0.4px' : '0.46px',
    marginTop: size === "medium" ? '44px': size === 'small' ? '4px' : size === 'large' ? "60px" : "",
    width: size === "large" ? "100%" : "",
    cursor: disabled ? "not-allowed" : "pointer",
    color: textColor
      ? textColor
      : variant === 'outlined'
      ? Theme.PRIMARY
      : undefined,
    backgroundColor: backgroundColor
      ? backgroundColor
      : variant === 'contained'
      ? disabled
        ? Theme.DARK_GREY 
        : Theme.PRIMARY
      : undefined,
    border: borderColor
      ? `1px solid ${borderColor}`
      : variant === 'outlined'
      ? `1px solid ${Theme.PRIMARY_50}`
      : undefined,
    '&:hover': {
      background: backgroundColor
        ? backgroundColor
        : variant === 'contained'
        ? `linear-gradient(0deg, ${Theme.BLACK_30}, ${Theme.BLACK_30}), ${Theme.PRIMARY}`
        : variant === 'outlined'
        ? Theme.PRIMARY_80
        : undefined,
      opacity: backgroundColor ? 10 : undefined,
      border: borderColor
        ? `1px solid ${borderColor}`
        : variant === 'outlined'
        ? `1px solid ${Theme.PRIMARY_50}`
        : undefined,
    },
  }),
)

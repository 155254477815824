import React from "react";
import MainConfetti from "./Confetti";
import ASSETS from "src/asset";

export default function VerificationSuccess() {
  return (
    <div className="fixed left-0 bg-[#000107d2] flex justify-center items-center w-full z-50 h-full top-0">
      <div
        data-aos="zoom-in"
        data-aos-easing="linear"
        data-aos-duration="1500"
        data-aos-delay="1000"
        className="w-20 h-20 flex rounded-full bg-white justify-center items-center"
      >
        <img
          src={ASSETS?.Badge}
          alt="badge"
          className="w-14 h-14 animate-pulse"
        />
      </div>
      <MainConfetti />
    </div>
  );
}

//@ts-nocheck
// import { useRef, useEffect } from 'react';
import { Fragment, useContext, useEffect, useState, useRef } from "react";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { Menu, Transition } from "@headlessui/react";
import {
  ArrowBack,
  Block,
  Flag,
  KeyboardBackspace,
  MoreVert,
  Search,
  Send,
  ThumbDownAlt,
} from "@mui/icons-material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import moment from "moment";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import HeartMinusM from "src/assets/heart_minus_m.png";
import Unmatchuser from "src/assets/unmatchuser.png";
import ASSETS from "src/asset";
import { useUser } from "src/components/auth/hooks/UserProvider";
import ViewProfile from "src/components/NewHandlerDashboard/ViewProfile";
import PageLoader from "src/components/PageLoader";
import { SideBarContext } from "src/context/SidebarProvider";
import { REPORT_ISSUE, SEND_MESSAGE } from "src/hooks/data/mutations";
import { GET_USER_CONVERSATIONS } from "src/hooks/data/queries";
import { CONVERSATION_CHANGED } from "src/hooks/data/subscriptions";
import { useUpdateUserLoginStatus } from "src/hooks/data/mutations";
import ResponsiveOverlay from "src/overlays/ResponsiveOverlay";
import Noaccess from "./NoAccessPage";
import { UNMATCH_USER } from "src/components/auth/data/mutation";

export default function UserMessages() {
  const { setIsNav } = useContext(SideBarContext);
  const { user } = useUser();

  const [
    reportIssue,
    // { data: issuedata, loading: reportloading, error: reporterror },
  ] = useMutation(REPORT_ISSUE);
  const { loading, data, subscribeToMore, refetch } = useQuery(
    GET_USER_CONVERSATIONS,
    {
      variables: {
        userId: Number(user?.id),
      },
      fetchPolicy: "cache-and-network",
    }
  );
  const [sendMessageHandler] = useMutation(SEND_MESSAGE);
  const [unmatchUser] = useMutation(UNMATCH_USER);
  const [previewUser, setPreviewUser] = useState(false);

  const subscribeToNewMessages = () => {
    subscribeToMore({
      document: CONVERSATION_CHANGED,
      variables: { userId: Number(user?.id) },
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData?.data?.conversationChanged) {
          const updated_conversation = prev?.conversationsByUserId.map(
            (conversation) => {
              if (
                conversation?.id ===
                subscriptionData?.data?.conversationChanged?.id
              ) {
                const newConversation = {
                  ...conversation,
                  messages: [
                    ...conversation?.messages,
                    // {
                    //   ...subscriptionData?.data?.conversationChanged
                    //     .messages[0],
                    // },
                  ],
                };
                setSelectedConversation(newConversation);
                return newConversation;
              }
              return conversation;
            }
          );

          return { conversationsByUserId: updated_conversation };
        } else {
          return prev;
        }
      },
      onError(error) {
        console.log("subscribeToNewMessages", { error });
      },
    });
    console.log("subscribed to messages");
  };

  const conversations = data?.conversationsByUserId ?? [];

  const [selectedConversation, setSelectedConversation] = useState<any>(null);
  const [openUnmatch, setOpenUnmatch] = useState(false);
  const [openBlock, setOpenBlock] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const flowRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isReportLoading, setisReportLoading] = useState(false);
  const [isUnmatchLoading, setisUnmatchLoading] = useState(false);
  const containerRef = useRef(null);

  const reportReasons = [
    "Prostitution",
    "Underage",
    "Fake Profile",
    "Inappropriate Pictures",
    "Impersonation",
    "Other reasons",
  ];
  const [message, setMessage] = useState("");
  function getCurrentTime(datestring) {
    const date = new Date(datestring);

    let hours = date.getHours();
    let minutes = date.getMinutes();

    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    //@ts-ignore
    minutes = minutes < 10 ? "0" + minutes : minutes;

    const time = hours + ":" + minutes + " " + ampm;

    return time;
  }
  const getTargetUser = (users: any[]) => {
    const fe = users?.filter(
      (message_user) => Number(message_user?.id) !== Number(user?.id)
    )[0];
    return fe;
  };
  const getLastMessage = (messages) => {
    const length = messages.length;
    if (length > 0) {
      const lastMessage = messages[length - 1];
      return lastMessage?.body ?? "";
    }

    return "no message yet";
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation(); // Prevent default behavior of the Enter key
      const start = e.currentTarget.selectionStart;
      const end = e.currentTarget.selectionEnd;
      setMessage(message.substring(0, start) + "\n" + message.substring(end));
      e.currentTarget.selectionStart = start + 1;
      e.currentTarget.selectionEnd = start + 1;
    }
  };

  // console.log(selectedConversation, "qwertyuio tyuiop")

  useEffect(() => {
    const textArea = textareaRef.current;
    if (textareaRef.current) {
      textArea.style.height = "auto";
      textArea.style.height = `${Math.min(textArea.scrollHeight, 120)}px`;
    }
  }, [message]);

  // useEffect(() => {
  //   const div = flowRef.current;
  //   //@ts-ignore
  //   div.scrollTop = div?.scrollHeight;
  // }, [selectedConversation]);
  useEffect(() => {
    if (flowRef.current) {
      //@ts-ignore
      flowRef.current.scrollTop = flowRef.current.scrollHeight;
    }
  }, [selectedConversation]);
  useEffect(() => {
    subscribeToNewMessages();
  }, []);

  const sendMessage = (pay) => {
    if (message?.trim() === "") return;
    setSelectedConversation((prev) => {
      const updatedSelectedConversation = {
        ...prev,
        messages: [
          ...prev.messages,
          {
            body: message,
            conversation: { id: String(pay?.id) },
            from: { id: String(user?.id) },
            is_inbound: false,
            to: {
              id: Number(
                pay?.users?.filter(
                  (person: any) => person?.id !== user?.id
                )?.[0]?.id
              ),
            },
            created_at: new Date(),
          },
        ],
      };
      return updatedSelectedConversation;
    });
    setMessage("");
    setIsLoading(true);

    sendMessageHandler({
      variables: {
        message: {
          body: message,
          conversation_id: Number(pay?.id),
          from_user_id: Number(user?.id),
          is_inbound: false,
          message_type: "USER",
          to_user_id: Number(
            pay?.users?.filter((person: any) => person?.id !== user?.id)?.[0]
              ?.id
          ),
        },
      },
      onCompleted: (data) => {
        setIsLoading(false);
        setSelectedConversation((prev) => {
          const msgs = prev.messages.map((m) => {
            if (
              m.body === data.createOutgoingMessage.body &&
              m.from?.id === data.createOutgoingMessage?.from?.id
            ) {
              return data.createOutgoingMessage;
            }
            return m;
          });
          const updatedSelectedConversation = {
            ...prev,
            messages: msgs,
          };
          return updatedSelectedConversation;
        });
      },
      onError: (error) => {
        setIsLoading(false);
        console.log("sendMessage", { error });
      },
    });
  };

  const reportUser = () => {
    const userToReport = selectedConversation?.users?.filter(
      (person) => person?.id !== user?.id
    )[0];
    setisReportLoading(true);
    reportIssue({
      variables: {
        message: otherReason,
        userId: Number(userToReport?.id),
        subject: selectedReason,
      },
      onCompleted: (data) => {
        // closeModal();
        toast.success("Report submitted successfully");
        setOpenReport(false);
        setSelectedReason("");
        setOtherReason("");
        setisReportLoading(false);
        console.log({ data });
      },
      onError: (error) => {
        toast.error("An error occured");
        setisReportLoading(false);
        console.log({ error });
      },
    });
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop =
        container.scrollHeight / 2 - container.clientHeight / 2;
    }
  }, []);

  const unmatchHandler = (action: String) => {
    const userToUnmatch = selectedConversation?.users?.filter(
      (person) => person?.id !== user?.id
    )[0];

    setisUnmatchLoading(true);
    unmatchUser({
      variables: {
        email: user?.email,
        payload: {
          conversation_id: Number(selectedConversation?.id),
          match_id: Number(userToUnmatch?.id),
        },
      },
      onCompleted: (data) => {
        setisUnmatchLoading(false);
        toast.success(`User ${action} successfully`);
        setOpenUnmatch(false);
        setOpenBlock(false);
        refetch();
        setSelectedConversation(null);
      },
      onError: (error) => {
        console.log(error);
        setisUnmatchLoading(false);
        toast.error("An error occured");
      },
    });
  };
  useEffect(() => {
    if (selectedConversation) {
      setIsNav(false);
    } else {
      setIsNav(true);
    }
    return () => {
      setIsNav(true);
    };
  }, [selectedConversation, setIsNav]);

  const lastSelectedUserMessage = selectedConversation?.messages?.filter?.(
    (message: any) => message.from?.id !== user?.id
  );
  const lastMessageTimeframe =
    lastSelectedUserMessage?.[lastSelectedUserMessage?.length - 1];

  return (
    // <>
    //   {user.subscription.name === "basic" ? (
    //     <Noaccess
    //       header="No Access"
    //       message="You have no access to chat with your potential matches. Beef up your dating game today, by becoming a premium subscriber"
    //     />
    //   ) : (
    <>
      {loading ? (
        <div className="absolute top-0 overflow-hidden items-center justify-center left-0 w-full h-full bg-white flex">
          <PageLoader noMargin />
        </div>
      ) : conversations?.length > 0 ? (
        previewUser ? (
          <div className="space-y-5">
            <h2
              onClick={() => {
                setPreviewUser(false);
              }}
              className="text-sm w-max flex gap-2 items-center cursor-pointer hover:text-blue-600 font-bold text-[#1C1F37]"
            >
              <KeyboardBackspace fontSize="small" />
              Go Back to Chat
            </h2>
            <ViewProfile
              user={{
                id: getTargetUser(selectedConversation?.users)?.id,
                first_name: getTargetUser(selectedConversation?.users)
                  ?.first_name,
                last_name: getTargetUser(selectedConversation?.users)
                  ?.last_name,
                profile_image_url: getTargetUser(selectedConversation?.users)
                  ?.profile_image_url,
              }}
            />
          </div>
        ) : (
          <div className="absolute top-0 lg:pr-4 lg:pt-5 left-0 w-full h-full bg-white flex">
            <ResponsiveOverlay
              isOpen={openBlock}
              setIsOpen={setOpenBlock}
              title={""}
              size="small"
            >
              <div className="h-full w-full">
                <div className="w-full gap-5 mx-auto p-5 bg-white flex flex-col justify-center items-center">
                  <div className="w-20 h-20 flex-shrink-0 rounded-full flex justify-center items-center bg-gray-200">
                    <img
                      src={Unmatchuser}
                      className="w-10 h-10"
                      alt="unmatch"
                    />
                  </div>
                  <h2 className="text-2xl text-center canela text-[#344054] font-bold">
                    Block{" "}
                    {getTargetUser(selectedConversation?.users)?.first_name +
                      " " +
                      getTargetUser(selectedConversation?.users)?.last_name}
                  </h2>
                  <p className="text-sm text-center">
                    You are about to block{" "}
                    <span className="uppercase font-bold">
                      {getTargetUser(selectedConversation?.users)?.first_name +
                        " " +
                        getTargetUser(selectedConversation?.users)
                          ?.last_name}{" "}
                    </span>
                    . This action would clear your chat history and
                    automatically block all future connections. Would you like
                    to proceed?
                  </p>
                </div>
                <div className="flex p-5 w-full justify-end gap-5 bg-gray-50 items-center">
                  <button
                    onClick={() => setOpenBlock(false)}
                    className="px-6 py-2 rounded-lg text-center text-base font-semibold bg-white border border-gray-400 text-gray-700"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={isUnmatchLoading}
                    onClick={() => {
                      unmatchHandler("blocked");
                    }}
                    className="px-6 py-2 rounded-lg text-center text-base font-semibold bg-[#081A51] text-white"
                  >
                    {isUnmatchLoading ? (
                      <BeatLoader size={10} color="#fff" />
                    ) : (
                      "Block"
                    )}
                  </button>
                </div>
              </div>
            </ResponsiveOverlay>
            <ResponsiveOverlay
              isOpen={openReport}
              setIsOpen={setOpenReport}
              title={""}
            >
              <div className="h-full w-full pb-10">
                <div className="w-full md:w-1/2 gap-5 mx-auto p-5 bg-white flex flex-col justify-center items-center">
                  <div className="flex gap-5 items-center">
                    <img
                      src={Unmatchuser}
                      className="w-10 h-10"
                      alt="unmatch"
                    />
                  </div>
                  <h2 className="text-2xl text-center canela text-[#344054] font-bold">
                    Please tell us what went wrong
                  </h2>
                  <p className="text-sm text-center">
                    Please select an option that best suits your response. Your
                    Feedback is confidential and will help us create a better
                    experience for you
                  </p>
                  <div className="py-5 w-full">
                    {reportReasons?.map?.((reason, index) => (
                      <div key={index} className="py-1.5 w-full">
                        <div
                          onClick={() => setSelectedReason(reason)}
                          className="flex gap-5 w-full items-center"
                        >
                          {selectedReason === reason ? (
                            <RadioButtonCheckedIcon className="text-[#081A51]" />
                          ) : (
                            <RadioButtonUncheckedIcon />
                          )}
                          <p className="text-sm font-medium">{reason}</p>
                        </div>
                      </div>
                    ))}
                    <div className="pt-5">
                      {selectedReason === "Other reasons" && (
                        <input
                          type="text"
                          name="firstname"
                          id="firstname"
                          value={otherReason}
                          placeholder="Enter reason here"
                          onChange={(e) => setOtherReason(e.target.value)}
                          className="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 placeholder-gray-400 shadow-sm focus:border-blue-1 focus:ring-blue-1 sm:text-lg"
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col gap-5 w-full justify-between items-center">
                    <button
                      disabled={
                        isReportLoading ||
                        !selectedReason ||
                        (selectedReason === "Other reasons" &&
                          !otherReason?.trim?.())
                      }
                      className="px-6 py-2 disabled:bg-gray-300 disabled:text-gray-700 w-full rounded-lg text-center text-base font-semibold bg-[#081A51] text-white"
                      onClick={reportUser}
                    >
                      {isReportLoading ? (
                        <BeatLoader size={10} color="#081A51" />
                      ) : (
                        "Submit"
                      )}
                    </button>
                    <button
                      onClick={() => setOpenReport(false)}
                      className="px-6 py-2 w-full rounded-lg text-center text-base font-semibold bg-white border border-gray-400 text-gray-700"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </ResponsiveOverlay>
            <ResponsiveOverlay
              isOpen={openUnmatch}
              setIsOpen={setOpenUnmatch}
              title={""}
              size="small"
            >
              <div className="h-full w-full">
                <div className="w-full gap-5 mx-auto p-5 bg-white flex flex-col justify-center items-center">
                  <div className="w-20 h-20 flex-shrink-0 rounded-full flex justify-center items-center bg-gray-200">
                    <img
                      src={Unmatchuser}
                      className="w-10 h-10"
                      alt="unmatch"
                    />
                  </div>
                  <h2 className="text-2xl text-center canela text-[#344054] font-bold">
                    Please Confirm Unmatch
                  </h2>
                  <p className="text-sm text-center">
                    You are about to unmatch yourself with{" "}
                    <span className="uppercase font-bold">
                      {getTargetUser(selectedConversation?.users)?.first_name +
                        " " +
                        getTargetUser(selectedConversation?.users)?.last_name}
                    </span>
                    , please note that going on with this process will
                    automatically remove{" "}
                    {getTargetUser(selectedConversation?.users)?.first_name}{" "}
                    from your list of matches.
                  </p>
                </div>
                <div className="flex p-5 w-full justify-end gap-5 bg-gray-50 items-center">
                  <button
                    onClick={() => setOpenUnmatch(false)}
                    className="px-6 py-2 rounded-lg text-center text-base font-semibold bg-white border border-gray-400 text-gray-700"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={isUnmatchLoading}
                    onClick={() => {
                      unmatchHandler("unmatched");
                    }}
                    className="px-6 py-2 rounded-lg text-center text-base font-semibold bg-[#081A51] text-white"
                  >
                    {isUnmatchLoading ? (
                      <BeatLoader size={10} color="#fff" />
                    ) : (
                      "Unmatch"
                    )}
                  </button>
                </div>
              </div>
            </ResponsiveOverlay>
            <div className="w-80 hidden flex-shrink-0 lg:inline-block overflow-y-auto space-y-4 p-4 py-0 h-full">
              <div className="flex gap-5 items-center">
                <div className="flex-grow relative">
                  <Search
                    fontSize="small"
                    style={{
                      position: "absolute",
                      left: 8,
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  />
                  <input
                    placeholder="Search"
                    className="w-full px-4 pl-10 py-2 bg-[#F5F5F5] rounded-md "
                  />
                </div>
              </div>
              <div className="w-full pb-32">
                {conversations
                  ?.slice?.()
                  ?.sort((chatA, chatB) => {
                    if (
                      chatA?.messages?.length === 0 &&
                      chatB?.messages?.length === 0
                    ) {
                      return 0;
                    } else if (chatA?.messages?.length === 0) {
                      return 1;
                    } else if (chatB?.messages?.length === 0) {
                      return -1;
                    } else {
                      const lastMessageA =
                        chatA?.messages?.[chatA?.messages?.length - 1];
                      const lastMessageB =
                        chatB?.messages?.[chatB?.messages?.length - 1];
                      return (
                        //@ts-ignore
                        new Date(lastMessageB?.created_at) -
                        //@ts-ignore
                        new Date(lastMessageA?.created_at)
                      );
                    }
                  })
                  ?.map?.((conversation) => {
                    const lastMessage = getLastMessage(conversation?.messages);
                    // const  =
                    return (
                      <div key={conversation?.id} className="py-2">
                        <div
                          onClick={() => {
                            setSelectedConversation(conversation);
                            setMessage("");
                          }}
                          className={`flex w-full cursor-pointer p-2 rounded-md ${
                            getTargetUser(selectedConversation?.users)?.id ===
                            getTargetUser(conversation?.users)?.id
                              ? "bg-gray-200"
                              : "hover:bg-gray-100"
                          }   justify-between items-center`}
                        >
                          <div className="flex gap-5 flex-shrink-0 items-center">
                            <div className="w-10 h-10 relative flex-shrink-0  rounded-full shadow-md border-[#081A51] border">
                              <img
                                alt="handler"
                                src={
                                  getTargetUser(conversation?.users)
                                    ?.profile_image_url ?? ASSETS?.Avi
                                }
                                className="w-full relative z-10 h-full shadow-md object-cover rounded-full"
                              />
                              <div
                                className={` w-3 rounded-full h-3 z-40 absolute bottom-0 right-0 text-[9px] ${
                                  getTargetUser(conversation?.users)?.is_online
                                    ? "bg-green-400"
                                    : "bg-gray-400"
                                } `}
                              ></div>
                            </div>
                            <div className="space-y-1 ">
                              <h2 className="text-sm capitalize font-bold">
                                {getTargetUser(conversation?.users)?.first_name}
                              </h2>
                              <p className="text-xs">
                                {lastMessage?.length > 25
                                  ? `${lastMessage?.slice?.(0, 25)}...`
                                  : lastMessage}
                              </p>
                            </div>
                          </div>
                          <p className="text-xs">
                            {getCurrentTime(
                              conversation?.messages?.length > 0
                                ? conversation?.messages[
                                    conversation?.messages?.length - 1
                                  ]?.created_at
                                : conversation?.created_at
                            )}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div
              className={`w-full overflow-hidden relative transition-all ease-in duration-300 p-0 pt-0 lg:p-0  lg:pb-20 lg:border-l ${
                selectedConversation ? "lg:border-r shadow-md" : ""
              } pb-20 flex flex-col relative justify-between border-gray-400 h-full`}
            >
              {selectedConversation ? (
                <>
                  <div className="flex border   border-r-0 bg-gray-100  border-l-0 p-2 sm:p-4 border-gray-400 justify-between items-center">
                    <div className="flex gap-2 items-center">
                      <span
                        onClick={() => setSelectedConversation(null)}
                        className="lg:hidden"
                      >
                        <ArrowBack
                          fontSize="small"
                          className="lg:hidden"
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                      <div
                        onClick={() => setPreviewUser(true)}
                        className="flex gap-5 items-center "
                      >
                        <div className=" w-6 sm:w-10 h-6 flex justify-center items-center shadow-md border-[#081A51] sm:h-10 rounded-full border">
                          <img
                            alt="handler"
                            // @ts-ignore
                            src={
                              getTargetUser(selectedConversation?.users)
                                ?.profile_image_url ?? ASSETS?.Avi
                            }
                            className="w-full object-cover h-full rounded-full"
                          />
                        </div>
                        <div className="">
                          <h2 className="text-xs cursor-pointer capitalize sm:text-sm font-bold">
                            {getTargetUser(selectedConversation?.users)
                              ?.first_name ?? ""}
                          </h2>
                          {getTargetUser(selectedConversation?.users)
                            ?.is_online ? (
                            <p
                              className="text-10 sm:text-xs text-green-400"
                              // style={{ color: "green" }}
                            >
                              online
                            </p>
                          ) : !lastMessageTimeframe?.created_at &&
                            !getTargetUser(selectedConversation?.users)
                              ?.last_online ? null : (
                            <p className="text-10 sm:text-xs">
                              last seen{" "}
                              {getTargetUser(selectedConversation?.users)
                                ?.last_online
                                ? moment(
                                    getTargetUser(selectedConversation?.users)
                                      ?.last_online
                                  ).calendar({
                                    sameDay: "[today at] LT",
                                    lastDay: "[yesterday at] LT",
                                    lastWeek: function (now) {
                                      const diffDays = moment().diff(
                                        this,
                                        "days"
                                      );
                                      return `${diffDays} [days ago]`;
                                    },
                                    sameElse: "MMM D, hh:mm a",
                                  })
                                : moment(
                                    lastMessageTimeframe?.created_at
                                  ).calendar({
                                    sameDay: "[today at] LT",
                                    lastDay: "[yesterday at] LT",
                                    lastWeek: function (now) {
                                      const diffDays = moment().diff(
                                        this,
                                        "days"
                                      );
                                      return `${diffDays} [days ago]`;
                                    },
                                    sameElse: "MMM D, hh:mm a",
                                  })}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <Menu as="div" className="relative w-max">
                      {({ open }) => (
                        <>
                          <Menu.Button as="div" className="w-full">
                            <div className="w-10 h-10 cursor-pointer rounded-full hover:bg-gray-200 flex justify-center items-center">
                              <MoreVert />
                            </div>
                          </Menu.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="focus:outline-none absolute right-0 z-50 mt-1 w-40 origin-top-right overflow-hidden rounded-lg border bg-white py-2 shadow-md ring-1 ring-gray-1">
                              <div className=" px-1 overflow-hidden">
                                <Menu.Item>
                                  <div className={`py-1`}>
                                    <div
                                      onClick={() => setOpenUnmatch(true)}
                                      className="flex py-1 px-2 rounded-lg gap-2 hover:bg-gray-200 cursor-pointer items-center"
                                    >
                                      <ThumbDownAlt
                                        style={{ color: "#005ED2" }}
                                      />
                                      <p className="text-sm font-medium">
                                        Unmatch
                                      </p>
                                    </div>
                                  </div>
                                </Menu.Item>
                                <Menu.Item>
                                  <div className={`py-1`}>
                                    <div
                                      onClick={() => setOpenReport(true)}
                                      className="flex py-1 px-2 rounded-lg gap-2  hover:bg-gray-200  cursor-pointer items-center"
                                    >
                                      <Flag style={{ color: "#005ED2" }} />
                                      <p className="text-sm font-medium">
                                        Report
                                      </p>
                                    </div>
                                  </div>
                                </Menu.Item>
                                <Menu.Item>
                                  <div className={`py-1`}>
                                    <div
                                      onClick={() => setOpenBlock(true)}
                                      className="flex py-1 px-2 rounded-lg cursor-pointer  hover:bg-gray-200  gap-2 items-center"
                                    >
                                      <Block style={{ color: "#005ED2" }} />
                                      <p className="text-sm font-medium">
                                        Block
                                      </p>
                                    </div>
                                  </div>
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>

                  <div
                    ref={flowRef}
                    className="flex-grow p-4 h-full overflow-y-auto"
                  >
                    {[
                      ...new Set(
                        selectedConversation?.messages?.map?.(
                          (i) =>
                            new Date(i?.created_at)
                              ?.toISOString?.()
                              ?.split?.("T")?.[0]
                        )
                      ),
                    ]
                      ?.sort?.((a: any, b: any) => new Date(a) - new Date(b))
                      ?.map?.((item, index) => (
                        <div key={index}>
                          <div className="pb-5 flex justify-center">
                            <p className="text-[10px] rounded w-max bg-gray-200 px-2.5 py-1 text-gray-500">
                              {moment(item).calendar({
                                sameDay: "[Today]",
                                lastDay: "[Yesterday]",
                                lastWeek: function (now) {
                                  const diffDays = moment().diff(this, "days");
                                  return `${diffDays} [days ago]`;
                                },
                                sameElse: "MMMM D, YYYY",
                              })}
                            </p>
                          </div>
                          {selectedConversation?.messages
                            ?.filter?.(
                              (a) =>
                                new Date(a?.created_at)
                                  ?.toISOString?.()
                                  ?.split?.("T")?.[0] === item
                            )
                            ?.sort?.(
                              (a: any, b: any) =>
                                new Date(a?.created_at) -
                                new Date(b?.created_at)
                            )
                            ?.map((message: any, index: number) =>
                              message.from?.id !== user?.id ? (
                                <UserMessage message={message} key={index} />
                              ) : (
                                <TargetMessage message={message} key={index} />
                              )
                            )}
                        </div>
                      ))}
                  </div>
                  <div className="border p-4 max-h-40 gap-2 flex items-end ">
                    {/* <SentimentSatisfiedAlt style={{ cursor: "pointer" }} /> */}
                    <textarea
                      ref={textareaRef}
                      className="hide_input_bar block w-full h-full px-3 py-2 text-gray-700 bg-white border-gray-300 rounded-lg focus:outline-none focus:border-none resize-none overflow-y-auto"
                      placeholder="Type your message here"
                      onChange={(event) => setMessage(event.target.value)}
                      value={message}
                      onKeyDown={handleKeyDown}
                      rows={1}
                    />
                    <div
                      onClick={() => {
                        if (isLoading) {
                          return;
                        }
                        sendMessage(selectedConversation);
                      }}
                      className="w-10 h-10 flex-shrink-0 border border-gray-200 rounded-full flex justify-center items-center bg-gray-50"
                    >
                      <Send style={{ color: "#005ED2", cursor: "pointer" }} />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    ref={containerRef}
                    className="lg:hidden h-full overflow-y-auto"
                  >
                    <div className="bg-gray-200 p-5">
                      <h2 className="sm:text-base text-sm font-medium">
                        All Conversations
                      </h2>
                    </div>
                    <div className="divide-y p-3 pb-32 w-full divide-gray-300">
                      {conversations
                        ?.slice?.()
                        ?.sort((chatA, chatB) => {
                          if (
                            chatA?.messages?.length === 0 &&
                            chatB?.messages?.length === 0
                          ) {
                            return 0;
                          } else if (chatA?.messages?.length === 0) {
                            return 1;
                          } else if (chatB?.messages?.length === 0) {
                            return -1;
                          } else {
                            const lastMessageA =
                              chatA?.messages?.[chatA?.messages?.length - 1];
                            const lastMessageB =
                              chatB?.messages?.[chatB?.messages?.length - 1];
                            return (
                              //@ts-ignore
                              new Date(lastMessageB?.created_at) -
                              //@ts-ignore
                              new Date(lastMessageA?.created_at)
                            );
                          }
                        })
                        ?.map?.((conversation: any) => {
                          const lastMessage = getLastMessage(
                            conversation?.messages
                          );
                          return (
                            <div key={conversation?.id} className="py-2 w-full">
                              <div
                                onClick={() => {
                                  setSelectedConversation(conversation);
                                  setMessage("");
                                }}
                                className={`flex cursor-pointer p-2 rounded-lg ${
                                  getTargetUser(selectedConversation?.users)
                                    ?.id ===
                                  getTargetUser(conversation?.users)?.id
                                    ? "bg-gray-200"
                                    : "hover:bg-gray-100"
                                }   justify-between items-center`}
                              >
                                <div className="flex w-full gap-5 items-center">
                                  <div className="w-10 h-10 flex-shrink-0 shadow-md border-[#081A51]  rounded-full border">
                                    <img
                                      alt="handler"
                                      src={
                                        getTargetUser(conversation?.users)
                                          ?.profile_image_url ?? ASSETS?.Avi
                                      }
                                      className="w-full h-full object-cover rounded-full"
                                    />
                                  </div>
                                  <div className="space-y-1">
                                    <h2 className="text-sm capitalize font-bold">
                                      {
                                        getTargetUser(conversation?.users)
                                          ?.first_name
                                      }
                                    </h2>
                                    <p className="text-xs ">
                                      {lastMessage?.length > 35
                                        ? `${lastMessage?.slice?.(0, 35)}...`
                                        : lastMessage}
                                    </p>
                                  </div>
                                </div>
                                <p className="text-xs flex-shrink-0 ">
                                  {getCurrentTime(
                                    conversation?.messages?.length > 0
                                      ? conversation?.messages[
                                          conversation?.messages?.length - 1
                                        ]?.created_at
                                      : conversation?.created_at
                                  )}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="pb-20 hidden lg:flex h-full  gap-5 flex-col justify-center items-center">
                    <div className="w-20 h-20 rounded-full bg-gray-200 flex justify-center items-center">
                      <img
                        src={HeartMinusM}
                        alt="empty"
                        className="w-12 h-10"
                      />
                    </div>
                    <h2 className="text-lg text-center font-bold text-[#081A51] ">
                      Select a user to view messages
                    </h2>
                    <p className="text-sm w-4/5 mx-auto text-center">
                      Kindly click on a user to select, view messages and start
                      chatting
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        )
      ) : (
        <div className="absolute top-0 items-center justify-center left-0 w-full h-full bg-white flex">
          <div className="pb-20 h-full flex gap-5 flex-col justify-center items-center">
            <div className="w-20 h-20 rounded-full bg-gray-200 flex justify-center items-center">
              <img src={HeartMinusM} alt="empty" className="w-12 h-10" />
            </div>
            <h2 className="text-lg text-center font-bold text-[#081A51] ">
              No chats found
            </h2>
            <p className="text-sm w-4/5 mx-auto text-center">
              We couldn’t find any chat history records linked to your account.
              This could be because you have not engaged in any conversation
              with any of your recent matches. Start a conversation today to
              begin bonding with your matches
            </p>
          </div>
        </div>
      )}
    </>
    //   )}
    // </>
  );
}
const TargetMessage = ({ message }) => {
  return (
    <div className="pb-5 flex justify-end items-end flex-col">
      <div className="bg-[#B8E3F5] p-3 w-4/5 sm:w-1/2 rounded-lg">
        <p
          style={{ overflowWrap: "break-word", wordWrap: "break-word" }}
          className="text-xs sm:text-sm font-medium"
        >
          {message.body}
        </p>
      </div>
      <p className="text-10 sm:text-xs">
        {" "}
        {moment(new Date(message?.created_at))?.format?.("hh:mm a")}
      </p>
    </div>
  );
};

const UserMessage = ({ message }) => {
  return (
    <div className="pb-5">
      <div className="bg-[#F2F4F7] p-3 w-4/5 sm:w-1/2 rounded-lg">
        <p
          style={{ overflowWrap: "break-word", wordWrap: "break-word" }}
          className="text-xs sm:text-sm font-medium"
        >
          {message.body}
        </p>
      </div>
      <p className="text-10 sm:text-xs">
        {moment(new Date(message?.created_at))?.format?.("hh:mm a")}
      </p>
    </div>
  );
};

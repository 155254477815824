//@ts-nocheck

import { useMutation } from "@apollo/client";
import { Menu, Transition } from "@headlessui/react";
import { CircularProgress } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Slider from "@mui/material/Slider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { FlutterWaveResponse } from "flutterwave-react-v3/dist/types";
import moment from "moment";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  COUNTRY_LIST,
  GENDERS,
  GENOTYPE,
  HIGHEST_QUALIFICATION,
  RELATIONSHIP_STATUS,
  RELIGIONS,
 
} from "src/helpers/constants";
import useUploadFile from "src/hooks/useUploadMedia";
import { SkeletonWrapper } from "src/pages/styles";
import PageLoadingOverlay from "../PageLoadingOverlay/PageLoadingOverlay";
import ToolTip from "../ToolTip";
import { useUpload } from "../common/useUpload";
import Modal from "../modals/Modals";
import PaystackPortal from "../paystack/PaystackPortal";
import { PAYMENT_SUCCESS } from "./data/mutation";
import { useUser } from "./hooks/UserProvider";
import useSignUp from "./hooks/useSignUp";
import { useCurrency } from "src/context/AmountProvider";

export default function SignUpForm({
  authPage,
  setAuthPage,
  plan,
  plan_cost,
  currency_code,
  plan_duration,
  userPreData = {},
}) {
  const navigate = useNavigate();
  const [paymentSuccess, { error }] = useMutation(PAYMENT_SUCCESS);
  const { multipleFilesUrlObject } = useUploadFile();
  const { setUser } = useUser();
  const { country } = useCurrency();
  const {
    signUp,
    verifyEmailAddress,
    verifyPhoneNumber,
    is_email_availabe_data,
    is_number_availabe_data,
  } = useSignUp();
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [alais, setAlais] = useState("");
  const [occupation, setOccupation] = useState("");
  const [location_state, setLocationState] = useState("");
  const [location_city, setLocationCity] = useState("");
  const [origin_state, setOriginState] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [hobbies, setHobbies] = useState("");
  const [about_me, setAboutMe] = useState("");
  const [gender, setGender] = useState("");
  const [religion, setReligion] = useState("");
  const [genotype, setGenotype] = useState("");
  const [age, setAge] = useState<any>(moment().subtract(30, "years"));
  const [location_country, setLocationCountry] = useState("");
  const [origin_country, setOriginCountry] = useState("");
  const [qualification, setQualification] = useState("");
  const [preferred_gender, setPreferred_gender] = useState("");
  const [preferred_country, setPreferredLocation] = useState<any[]>([]);
  const [status, setStatus] = useState("");
  const [preferred_age, setPreferred_age] = useState<number[]>([23, 60]);
  const [preferred_religion, setPreferedReligion] = useState<any[]>([]);
  const [preferred_genotype, setpreferred_genotype] = useState<any[]>([]);
  const [preferred_status, setPreferred_status] = useState<any[]>([]);
  const [save_modal, setSaveModal] = useState<boolean>(false);
  const [user_agreed, setUser_agreed] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [user_reference, setUserReference] = useState<string>("");
  // const [payment_info, setPaymentInfo] = useState<FlutterWaveResponse | null>(
  //   null
  // );
  const [paid, setHasPaid] = useState(false);

  const checkPassword = password !== confirmPassword;

  const setPaid = () => {
    setHasPaid(true);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const isNumberAvailable = useCallback(() => {
    if (is_number_availabe_data?.verifyPhone === false) {
      return (
        <label
          className="text-xs mb-3 pl-5 block font-medium text-brand-red-1"
          style={{
            marginTop: "10px",
          }}
        >
          *That phone number is taken. Try another.
        </label>
      );
    } else if (is_number_availabe_data?.verifyPhone === true) {
      return <></>;
    } else return <></>;
  }, [is_number_availabe_data?.verifyPhone]);

  const isEmailAvailable = useCallback(() => {
    if (is_email_availabe_data?.verifyEmail === false) {
      return (
        <label
          className="text-xs mb-3 pl-5 block font-medium text-brand-red-1"
          style={{
            marginTop: "10px",
          }}
        >
          *That email is taken. Try another.
        </label>
      );
    } else if (is_email_availabe_data?.verifyEmail === true) {
      return <></>;
    } else return <></>;
  }, [is_email_availabe_data?.verifyEmail]);

  function valuetext(value: number) {
    return `${value}°C`;
  }
  const handleDOBChange = (event: Event, newValue: number | number[]) => {
    setPreferred_age(newValue as number[]);
  };
  const maxDate = moment().subtract(23, "years");
  const minDate = moment().subtract(73, "years");

  const handleSubmit = (e: any) => {
    try {
      e?.preventDefault();
    } catch (error) {
      console.log({ error });
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setAuthPage((val: any) => (val += 1));
  };

  const {
    mainFile,
    loading_image: loading_image_1,
    getRootProps,
    getInputProps,
    deleteFile,
  } = useUpload({
    fileType: "image",
  });

  const {
    mainFile: secondFile,
    loading_image: loading_image_2,
    getRootProps: getSecondRootProps,
    getInputProps: getSecondInputProps,
    deleteFile: deleteSecondFile,
  } = useUpload({
    fileType: "image",
  });
  const paymentSuccessHandler = (payload) => {
    const { plan, amount, plan_duration, email, tx_ref, transaction_id } =
      payload;
    paymentSuccess({
      variables: {
        payload: {
          amount,
          email,
          plan,
          plan_duration,
          transaction_id,
          tx_ref,
        },
      },
      onCompleted: (data) => {
        setSaveModal(true);
        setSubmitting(false);
        navigate("/dashboard/friend-request");
      },
      onError: (error) => {
        console.log(error);
        setSubmitting(false);
      },
    });
  };
  const handleFinalSubmit = useCallback(
    async (payload: any) => {
      setSubmitting(true);
      try {
        if (typeof payload?.preventDefault === "function") {
          payload?.preventDefault();
        }
        if (typeof payload?.preventDefault === "function") {
          payload?.preventDefault();
        }
      } catch (error) {
        console.log({ error });
        //@ts-ignore
        toast.error(error?.message ?? "Something went wrong.Try again later");
        setSubmitting(false);
      }
      const upload_response: any[] = await multipleFilesUrlObject(
        [mainFile.file, secondFile.file],
        `gallery/`
      );
      const completed =
        upload_response.every((e) => e.attachmentUrl.length > 0) || false;
      const plan_id = plan === "basic" ? 1 : 2;
      if (completed) {
        await signUp({
          variables: {
            payload: {
              first_name: userPreData?.first_name,
              last_name: userPreData?.last_name,
              password: confirmPassword,
              email: userPreData?.email,
              genotype,
              occupation: userPreData?.occupation,
              age: `${new Date(userPreData?.dob)?.toISOString?.()}`,
              religion,
              about_me,
              alais,
              ethnicity,
              gender: userPreData?.gender,
              hobbies,
              location_city: userPreData?.location_city,
              location_country: userPreData?.location_country,
              location_state: userPreData?.location_state,
              origin_country,
              origin_state,
              phone: userPreData?.phone_number,
              preferred_age: userPreData?.preferred_age?.trim?.(),
              preferred_country: preferred_country.join(),
              preferred_gender: userPreData?.preferred_gender,
              preferred_genotype: preferred_genotype.join(),
              preferred_religion: preferred_religion.join(),
              preferred_status: preferred_status.join(),
              qualification,
              status,
              subscription_id: plan_id,
              media_ids: upload_response.map((e) => e.id),
            },
          },
          onCompleted: (data) => {
            setUser(data?.userSignUp);
            if (payload?.status && payload?.tx_ref) {
              if (payload?.status === "successful") {
                paymentSuccessHandler({
                  plan,
                  amount: payload?.price,
                  plan_duration:
                    country?.trim?.() === "nigeria"
                      ? plan_duration
                      : plan_duration + "-usd",
                  email: userPreData?.email,
                  tx_ref: payload?.tx_ref,
                  transaction_id: payload?.transaction_id,
                });
              } else {
                toast.error(error?.message ?? "Payment Error");
              }
            }
            setSubmitting(false);
            navigate("/dashboard/friend-request");
          },
          onError: (error) => {
            setSubmitting(false);
            toast.error(error?.message ?? "Network error");
          },
        });
      } else {
        setSubmitting(false);
        toast.error("Error uploading images");
      }
    },
    [
      multipleFilesUrlObject,
      mainFile.file,
      secondFile.file,
      plan,
      signUp,
      first_name,
      last_name,
      confirmPassword,
      email,
      genotype,
      occupation,
      age,
      religion,
      about_me,
      alais,
      ethnicity,
      gender,
      hobbies,
      location_city,
      location_country,
      location_state,
      origin_country,
      origin_state,
      phone,
      preferred_age,
      preferred_country,
      preferred_gender,
      preferred_genotype,
      preferred_religion,
      preferred_status,
      qualification,
      status,
      setUser,
      paymentSuccessHandler,
      plan_duration,
      error?.message,
    ]
  );

  useEffect(() => {
    if (paid === true) {
      paidSubmit({
        status: "successful",
        amount: plan_cost,
        price: plan_cost.slice(1),
        tx_ref: user_reference,
        transaction_id: user_reference,
        customer: {
          plan,
          price: plan_cost,
          plan_duration,
          email: userPreData?.email,
          amount: plan_cost,
        },
      });
    }
  }, [paid]);

  const paidSubmit = useCallback(
    async (response: any) => {
      setHasPaid(false);
      if (typeof response?.preventDefault === "function") {
        response?.preventDefault();
      }
      if (typeof response?.stopPropagation === "function") {
        response.stopPropagation();
      }
      //FlutterWaveResponse
      // setPaymentInfo((prev) => response);
      handleFinalSubmit(response);
    },
    [handleFinalSubmit]
  );

  const toggleModal = () => {
    setSaveModal(false);
    window.location.href = "/";
  };

  const first_step_complete = useMemo(
    () =>
      // !!first_name.length &&
      // !!last_name.length &&
      !!alais.length &&
      // !!age &&
      // !!gender.length &&
      !!religion.length &&
      // !!occupation.length &&
      !!genotype.length &&
      !!mainFile?.file?.name?.length &&
      !!secondFile?.file?.name?.length,
    [
      // age,
      alais.length,
      // first_name.length,
      // gender.length,
      genotype.length,
      // last_name.length,
      mainFile?.file?.name?.length,
      // occupation.length,
      religion.length,
      secondFile?.file?.name?.length,
    ]
  );

  const second_step_complete = useMemo(
    () =>
      // !!location_country.length &&
      // !!location_state.length &&
      // !!location_city.length &&
      !!origin_country.length &&
      !!origin_state.length &&
      !!password.length &&
      !!confirmPassword.length &&
      !!ethnicity.length &&
      // !!email.length &&
      // !!phone &&
      !!qualification.length &&
      !!status.length &&
      !!hobbies.length &&
      !!about_me.length,
    [
      about_me.length,
      email.length,
      password.length,
      confirmPassword.length,
      ethnicity.length,
      hobbies.length,
      location_city.length,
      location_country.length,
      location_state.length,
      origin_country.length,
      origin_state.length,
      phone,
      qualification.length,
      status.length,
    ]
  );

  const last_step_complete = useMemo(
    () =>
      // !!preferred_gender.length &&
      // !!preferred_age.length &&
      !!preferred_country.length &&
      !!preferred_religion.length &&
      !!preferred_genotype.length &&
      !!preferred_status.length &&
      Number(user_agreed),
    [
      preferred_age.length,
      preferred_country.length,
      preferred_gender.length,
      preferred_genotype.length,
      preferred_religion.length,
      preferred_status.length,
      user_agreed,
    ]
  );

  const onClose = () => {
    //console.log("close");
  };

  const handleEmailBlur = () => {
    verifyEmailAddress(email)
      .then((value) => {
        if (!is_email_availabe_data.verifyEmail) {
          setEmail("");
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const handlePhoneBlur = () => {
    verifyPhoneNumber(phone)
      .then((value) => {
        if (!is_number_availabe_data.verifyPhone) {
          setPhone("");
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  return (
    <>
      {authPage === 1 ? (
        <form onSubmit={handleSubmit} className="space-y-10">
          <div className="grid gap-7 grid-cols-1 lg:grid-cols-2">
            {/* <div className="w-full">
              <label
                className="text-xs mb-3 pl-5 block font-medium"
                htmlFor="firstname"
              >
                First Name
              </label>
              <input
                required={true}
                placeholder="Enter first name"
                className="px-5 py-2.5 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl"
                onChange={(e) => setFirstName(e.target.value)}
                value={first_name}
              />
            </div>
            <div className="w-full">
              <label
                className="text-xs mb-3 pl-5 block font-medium"
                htmlFor="lastname"
              >
                Last Name
              </label>
              <input
                required={true}
                placeholder="Enter last name"
                className="px-5 py-2.5 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl"
                onChange={(e) => setLastName(e.target.value)}
                value={last_name}
              />
            </div> */}
            <div className="w-full">
              <label
                className="text-xs mb-3 pl-5 block font-medium"
                htmlFor="alias"
              >
                Username
              </label>
              <input
                required={true}
                placeholder="Add a preferred username "
                className="px-5 py-2.5 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl"
                onChange={(e) => setAlais(e.target.value)}
                value={alais}
              />
            </div>

            {/* <Menu as="div" className="w-full relative">
              {({ open }) => (
                <>
                  <Menu.Button as="div" className="">
                    <div className="w-full">
                      <label
                        className="text-xs mb-1 pl-5 block font-medium"
                        htmlFor="age"
                      >
                        Date of Birth
                      </label>
                      <div className="px-1 py-3 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 focus:ring-4 bg-transparent focus:ring-brand-2 w-full rounded-3xl cursor-pointer">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            value={age}
                            onChange={(newValue) => {
                              setAge(newValue);
                            }}
                            sx={{
                              "& .MuiPaper-root": {
                                padding: 2,
                                marginTop: 1,
                                paddingTop: 0,
                                width: "100%",
                              },
                              "& .MuiOutlinedInput-input": {
                                padding: 1,
                                width: "100%",
                              },
                              "& .MuiStack-root": {
                                paddingTop: 0,
                                width: "100%",
                              },
                              overflow: "hidden",
                              width: "100%",
                            }}
                            minDate={minDate}
                            maxDate={maxDate}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Menu.Button>
                </>
              )}
            </Menu>
            <Menu as="div" className="w-full relative">
              {({ open }) => (
                <>
                  <Menu.Button as="div" className="">
                    <div className="w-full">
                      <label
                        className="text-xs mb-3 pl-5 block font-medium"
                        htmlFor="gender"
                      >
                        Gender
                      </label>
                      <div className="px-5 py-3 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl flex justify-between items-center cursor-pointer">
                        <p className="text-sm">
                          {gender === "" ? "Select gender" : gender}
                        </p>
                        <i className="fa-solid fa-chevron-down"></i>
                      </div>
                    </div>
                  </Menu.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                    >
                      <div className="flex items-center text-text-1 justify-between">
                        <p className="text-10 ">Select gender</p>
                        <i className="fa-solid text-10 fa-chevron-up"></i>
                      </div>
                      {GENDERS.map((gender) => (
                        <Menu.Item key={gender}>
                          <div
                            onClick={() => {
                              setGender(gender);
                              setPreferred_gender(
                                gender === "Male" ? "Female" : "Male"
                              );
                            }}
                            className={`w-full rounded-lg px-3 py-2 hover:bg-drop-1 hover:text-white text-xs`}
                          >
                            {gender}
                          </div>
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu> */}
            <Menu as="div" className="w-full relative">
              {({ open }) => (
                <>
                  <Menu.Button as="div" className="">
                    <div className="w-full">
                      <label
                        className="text-xs mb-3 pl-5 block font-medium"
                        htmlFor="religion"
                      >
                        Religion
                      </label>
                      <div className="px-5 py-3 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl flex justify-between items-center cursor-pointer">
                        <p className="text-sm">
                          {religion === "" ? "Select religion" : religion}
                        </p>
                        <i className="fa-solid fa-chevron-down"></i>
                      </div>
                    </div>
                  </Menu.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                    >
                      <div className="flex items-center text-text-1 justify-between">
                        <p className="text-10 ">Select religion</p>
                        <i className="fa-solid text-10 fa-chevron-up"></i>
                      </div>
                      {RELIGIONS.map((religion) => (
                        <Menu.Item key={religion}>
                          <div
                            onClick={() => setReligion(religion)}
                            className={`w-full rounded-lg px-3 py-2 hover:bg-drop-1 hover:text-white text-xs`}
                          >
                            {religion}
                          </div>
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
            {/* <div className="w-full">
              <label
                className="text-xs mb-3 pl-5 block font-medium"
                htmlFor="occupation"
              >
                Occupation
              </label>
              <input
                required={true}
                placeholder="What do you do?"
                className="px-5 py-2.5 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl"
                onChange={(e) => setOccupation(e.target.value)}
                value={occupation}
              />
            </div> */}
            <Menu as="div" className="w-full relative">
              {({ open }) => (
                <>
                  <Menu.Button as="div" className="">
                    <div className="w-full">
                      <label
                        className="text-xs mb-3 pl-5 block font-medium"
                        htmlFor="genotype"
                      >
                        Genotype
                      </label>
                      <div className="px-5 py-3 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl flex justify-between items-center cursor-pointer">
                        <p className="text-sm">
                          {genotype === "" ? "Select genotype" : genotype}
                        </p>
                        <i className="fa-solid fa-chevron-down"></i>
                      </div>
                    </div>
                  </Menu.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                    >
                      <div className="flex items-center text-text-1 justify-between">
                        <p className="text-10 ">Select genotype</p>
                        <i className="fa-solid text-10 fa-chevron-up"></i>
                      </div>
                      {GENOTYPE.map((genotype) => (
                        <Menu.Item key={genotype}>
                          <div
                            onClick={() => setGenotype(genotype)}
                            className={`w-full rounded-lg px-3 py-2 hover:bg-drop-1 hover:text-white text-xs`}
                          >
                            {genotype}
                          </div>
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
            <div className="w-full">
              {loading_image_1 ? (
                <SkeletonWrapper>
                  <LinearProgress />
                </SkeletonWrapper>
              ) : (
                <>
                  <label
                    className="text-xs flex items-center gap-1 mb-3 pl-5  font-medium"
                    htmlFor="file"
                  >
                    Your photos{" "}
                    <ToolTip
                      content={
                        <div className="w-full p-3">
                          <p className="text-10 text-center">
                            Ensure at least one of your photos clearly shows
                            your face
                          </p>
                        </div>
                      }
                    >
                      <i className="fa-solid cursor-pointer fa-circle-info"></i>
                    </ToolTip>
                  </label>
                  {mainFile?.errors?.length <= 0 &&
                  mainFile?.file?.length <= 0 ? (
                    <div className={`h-full`} {...getRootProps()}>
                      <div className="">
                        <input required={true} {...getInputProps()} />
                        <h5 className="px-5 py-2.5 appearance-none cursor-pointer border-2 border-brand-1 text-center border-dashed ouline-0 focus:border-0 focus:outline-0  focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl">
                          Upload photo 1
                        </h5>
                      </div>
                    </div>
                  ) : mainFile?.errors?.length > 0 ? (
                    <div className={`h-full`} {...getRootProps()}>
                      <div className="">
                        <input required={true} {...getInputProps()} />
                        <h5 className="px-5 py-2.5 appearance-none border-2 cursor-pointer text-red-400 border-red-400 text-center border-dashed ouline-0 focus:border-0 focus:outline-0  focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl">
                          {mainFile?.errors[0]?.message}
                        </h5>
                      </div>
                    </div>
                  ) : (
                    <h5 className="px-5 py-2.5 appearance-none relative border-2 border-brand-1 text-center border-dashed ouline-0 focus:border-0 focus:outline-0  focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl">
                      {mainFile?.file?.name}
                      <i
                        style={{
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                        onClick={() => deleteFile()}
                        className="fa-solid cursor-pointer hover:bg-brand-1 rounded-full hover:text-white absolute fa-circle-xmark"
                      ></i>
                    </h5>
                  )}
                </>
              )}
            </div>

            <div className="w-full">
              {loading_image_2 ? (
                <SkeletonWrapper>
                  <LinearProgress />
                </SkeletonWrapper>
              ) : (
                <>
                  <label
                    className="text-xs mb-3 pl-5 block invisible font-medium"
                    htmlFor="genotype"
                  >
                    Your photos
                  </label>
                  {secondFile?.errors?.length <= 0 &&
                  secondFile?.file?.length <= 0 ? (
                    <div className={`h-full`} {...getSecondRootProps()}>
                      <div className="">
                        <input required={true} {...getSecondInputProps()} />
                        <h5 className="px-5 py-2.5 appearance-none cursor-pointer border-2 border-brand-1 text-center border-dashed ouline-0 focus:border-0 focus:outline-0  focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl">
                          Upload photo 2
                        </h5>
                      </div>
                    </div>
                  ) : secondFile?.errors?.length > 0 ? (
                    <div className={`h-full`} {...getSecondRootProps()}>
                      <div className="">
                        <input required={true} {...getSecondInputProps()} />
                        <h5 className="px-5 py-2.5 appearance-none border-2 cursor-pointer text-red-400 border-red-400 text-center border-dashed ouline-0 focus:border-0 focus:outline-0  focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl">
                          {secondFile?.errors[0]?.message}
                        </h5>
                      </div>
                    </div>
                  ) : (
                    <h5 className="px-5 py-2.5 appearance-none relative border-2 border-brand-1 text-center border-dashed ouline-0 focus:border-0 focus:outline-0  focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl">
                      {secondFile?.file?.name}
                      {/* add image preview later */}
                      {/* <img src={mainFile?.file?.path} alt={mainFile?.file?.name} />
                  <img
                    src={secondFile?.file?.path}
                    alt={secondFile?.file?.name}
                  /> */}

                      <i
                        style={{
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                        onClick={() => deleteSecondFile()}
                        className="fa-solid cursor-pointer hover:bg-brand-1 rounded-full hover:text-white absolute fa-circle-xmark"
                      ></i>
                    </h5>
                  )}
                </>
              )}
            </div>
          </div>
          {Boolean(!first_step_complete) && (
            <label
              className="text-xs mb-3 pl-5 block font-medium text-brand-red-1"
              style={{
                marginTop: "10px",
              }}
            >
              *All Fields Are required
            </label>
          )}
          <button
            type="submit"
            className={`py-2.5 block w-full font-semibold text-white ${
              Boolean(!first_step_complete) ? "bg-disabled-1" : "bg-brand-1"
            } rounded-3xl`}
            disabled={Boolean(!first_step_complete)}
          >
            Next
          </button>
        </form>
      ) : authPage === 2 ? (
        <form onSubmit={handleSubmit} className="space-y-10">
          <div className="grid gap-7 grid-cols-1 lg:grid-cols-2">
            {/* <Menu as="div" className="w-full relative">
              {({ open }) => (
                <>
                  <Menu.Button as="div" className="">
                    <div className="w-full">
                      <label
                        className="text-xs mb-3 pl-5 block font-medium"
                        htmlFor="location_country"
                      >
                        Location Country
                      </label>
                      <div className="px-5 py-3 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl flex justify-between items-center cursor-pointer">
                        <p className="text-sm">
                          {location_country === ""
                            ? "Select Location Country"
                            : location_country}
                        </p>
                        <i className="fa-solid fa-chevron-down"></i>
                      </div>
                    </div>
                  </Menu.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                    >
                      <div className="flex items-center text-text-1 justify-between">
                        <p className="text-10 ">Select location country</p>
                        <i className="fa-solid text-10 fa-chevron-up"></i>
                      </div>
                      {COUNTRY_LIST.map((location_country) => (
                        <Menu.Item key={location_country}>
                          <div
                            onClick={() => setLocationCountry(location_country)}
                            className={`w-full rounded-lg px-3 py-2 hover:bg-drop-1 hover:text-white text-xs`}
                          >
                            {location_country}
                          </div>
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
            <div className="w-full">
              <label
                className="text-xs mb-3 pl-5 block font-medium"
                htmlFor="state"
              >
                State
              </label>
              <div className="relative w-full">
                <input
                  required={true}
                  placeholder="Select State"
                  className="px-5 py-2.5 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl"
                  onChange={(e) => setLocationState(e.target.value)}
                  value={location_state}
                />
               
              </div>
            </div>
            <div className="w-full">
              <label
                className="text-xs mb-3 pl-5 block font-medium"
                htmlFor="city"
              >
                Location City
              </label>
              <div className="relative w-full">
                <input
                  required={true}
                  placeholder="Select city"
                  className="px-5 py-2.5 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl"
                  onChange={(e) => setLocationCity(e.target.value)}
                  value={location_city}
                />

              </div>
            </div> */}
            <Menu as="div" className="w-full relative">
              {({ open }) => (
                <>
                  <Menu.Button as="div" className="">
                    <div className="w-full">
                      <label
                        className="text-xs mb-3 pl-5 block font-medium"
                        htmlFor="origin_country"
                      >
                        Origin Country
                      </label>
                      <div className="px-5 py-3 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl flex justify-between items-center cursor-pointer">
                        <p className="text-sm">
                          {origin_country === ""
                            ? "Select Origin Country"
                            : origin_country}
                        </p>
                        <i className="fa-solid fa-chevron-down"></i>
                      </div>
                    </div>
                  </Menu.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                    >
                      <div className="flex items-center text-text-1 justify-between">
                        <p className="text-10 ">Select Origin country</p>
                        <i className="fa-solid text-10 fa-chevron-up"></i>
                      </div>
                      {COUNTRY_LIST.map((origin_country) => (
                        <Menu.Item key={origin_country}>
                          <div
                            onClick={() => setOriginCountry(origin_country)}
                            className={`w-full rounded-lg px-3 py-2 hover:bg-drop-1 hover:text-white text-xs`}
                          >
                            {origin_country}
                          </div>
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
            <div className="w-full">
              <label
                className="text-xs mb-3 pl-5 block font-medium"
                htmlFor="origin_state"
              >
                Origin State
              </label>
              <div className="relative w-full">
                <input
                  required={true}
                  placeholder="Select State"
                  className="px-5 py-2.5 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl"
                  onChange={(e) => setOriginState(e.target.value)}
                  value={origin_state}
                />
              </div>
            </div>
            {/* <div className="w-full">
              <label
                className="text-xs mb-3 pl-5 block font-medium"
                htmlFor="email"
              >
                Email Address
              </label>
              <input
                required={true}
                placeholder="Enter valid email address"
                type="email"
                className="px-5 py-2.5 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl"
                onChange={(e) => setEmail(e.target.value)}
                onBlur={handleEmailBlur}
                value={email}
              />
              {isEmailAvailable()}
            </div> */}

            <div className="w-full">
              <label
                className="text-xs mb-3 pl-5 block font-medium"
                htmlFor="password"
              >
                Password
              </label>
              <input
                required={true}
                placeholder="Enter your password"
                type="password"
                className="px-5 py-2.5 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl"
                onChange={(e) => setPassword(e.target.value)}
                // onBlur={handleEmailBlur}
                value={password}
              />
            </div>
            <div className="w-full">
              <label
                className="text-xs mb-3 pl-5 block font-medium"
                htmlFor="password"
              >
                Confirm Password
              </label>
              <input
                required={true}
                placeholder="confirm your password"
                type="password"
                className="px-5 py-2.5 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl"
                onChange={(e) => setConfirmPassword(e.target.value)}
                // onBlur={handleEmailBlur}
                value={confirmPassword}
              />
              {checkPassword && (
                <p className="ml-4 text-xs font-semibold text-rose-800 mt-1">
                  password does not match
                </p>
              )}
            </div>
            <div className="w-full">
              <label
                className="text-xs mb-3 pl-5 block font-medium"
                htmlFor="ethnicity"
              >
                Ethnicity
              </label>
              <div className="relative w-full">
                <input
                  required={true}
                  placeholder="Enter Ethnicity"
                  className="px-5 py-2.5 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl"
                  onChange={(e) => setEthnicity(e.target.value)}
                  value={ethnicity}
                />
                {/* <i
                  style={{
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                  }}
                  className="fa-solid text-brand-1 absolute fa-magnifying-glass"
                ></i> */}
              </div>
            </div>
            {/* <div className="w-full">
              <label
                className="text-xs mb-3 pl-5 block font-medium"
                htmlFor="password"
              >
                Password
              </label>
              <input
                required={true}
                placeholder="Enter your password"
                type="password"
                className="px-5 py-2.5 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl"
                onChange={(e) => setPassword(e.target.value)}
                // onBlur={handleEmailBlur}
                value={password}
              />
            </div> */}
            {/* <div className="w-full">
              <label
                className="text-xs mb-3 pl-5 block font-medium"
                htmlFor="password"
              >
                Verify password
              </label>
              <input
                required={true}
                placeholder="Verify password"
                type="password"
                className="px-5 py-2.5 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div> */}

            {/* <div className="w-full">
              <label
                className="text-xs mb-3 pl-5 block font-medium"
                htmlFor="phone"
              >
                Phone Number
              </label>
              <div className="px-3 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl">
                <PhoneInput
                  country={"ng"}
                  inputStyle={{
                    width: "100%",
                    height: "2.9em",
                    fontSize: "15px",
                    paddingLeft: "50px",
                    border: "none",
                    background: "none",
                  }}
                  value={phone}
                  onChange={(value) => setPhone("+" + value)}
                  onBlur={handlePhoneBlur}
                />
              </div>
              {isNumberAvailable()}
            </div> */}
            <Menu as="div" className="w-full relative">
              {({ open }) => (
                <>
                  <Menu.Button as="div" className="">
                    <div className="w-full">
                      <label
                        className="text-xs mb-3 pl-5 block font-medium"
                        htmlFor="qualification"
                      >
                        Highest Academic Qualification
                      </label>
                      <div className="px-5 py-3 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl flex justify-between items-center cursor-pointer">
                        <p className="text-sm">
                          {qualification === ""
                            ? "Select qualification"
                            : qualification}
                        </p>
                        <i className="fa-solid fa-chevron-down"></i>
                      </div>
                    </div>
                  </Menu.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                    >
                      <div className="flex items-center text-text-1 justify-between">
                        <p className="text-10 ">Select qualification</p>
                        <i className="fa-solid text-10 fa-chevron-up"></i>
                      </div>
                      {HIGHEST_QUALIFICATION.map((qualification) => (
                        <Menu.Item key={qualification}>
                          <div
                            onClick={() => setQualification(qualification)}
                            className={`w-full rounded-lg px-3 py-2 hover:bg-drop-1 hover:text-white text-xs`}
                          >
                            {qualification}
                          </div>
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
            <Menu as="div" className="w-full relative">
              {({ open }) => (
                <>
                  <Menu.Button as="div" className="">
                    <div className="w-full">
                      <label
                        className="text-xs mb-3 pl-5 block font-medium"
                        htmlFor="status"
                      >
                        Relationship status
                      </label>
                      <div className="px-5 py-3 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl flex justify-between items-center cursor-pointer">
                        <p className="text-sm">
                          {status === "" ? "Select status" : status}
                        </p>
                        <i className="fa-solid fa-chevron-down"></i>
                      </div>
                    </div>
                  </Menu.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                    >
                      <div className="flex items-center text-text-1 justify-between">
                        <p className="text-10 ">Select status</p>
                        <i className="fa-solid text-10 fa-chevron-up"></i>
                      </div>
                      {RELATIONSHIP_STATUS.map((status) => (
                        <Menu.Item key={status}>
                          <div
                            onClick={() => setStatus(status)}
                            className={`w-full rounded-lg px-3 py-2 hover:bg-drop-1 hover:text-white text-xs`}
                          >
                            {status}
                          </div>
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
          <div className="w-full grid lg:grid-cols-2 grid-cols-1 gap-4">
            <div className="">
              <label
                className="text-xs mb-3 pl-5 block font-medium"
                htmlFor="hobbies"
              >
                Hobbies
              </label>
              <textarea
                placeholder="List your hobbies"
                className="px-5 py-2.5 h-32 resize-none appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl"
                onChange={(e) => setHobbies(e.target.value)}
                value={hobbies}
              ></textarea>
            </div>
            <div className="">
              <label
                className="text-xs mb-3 pl-5 block font-medium"
                htmlFor="about"
              >
                About Me
              </label>
              <textarea
                placeholder="A little about yourself (300 characters max)"
                className="px-5 py-2.5 h-32 resize-none appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl"
                onChange={(e) => setAboutMe(e.target.value)}
                value={about_me}
              ></textarea>
            </div>
          </div>
          {Boolean(!second_step_complete) && (
            <label
              className="text-xs mb-3 pl-5 block font-medium text-brand-red-1"
              style={{
                marginTop: "10px",
              }}
            >
              *All Fields Are required
            </label>
          )}
          <button
            type="submit"
            className={`py-2.5 block w-full font-semibold text-white ${
              Boolean(!second_step_complete) ? "bg-disabled-1" : "bg-brand-1"
            } rounded-3xl`}
            disabled={Boolean(!second_step_complete)}
          >
            Next
          </button>
          <p
            onClick={() => setAuthPage((val: any) => (val -= 1))}
            className="flex gap-5 cursor-pointer justify-center items-center hover:text-brand-1"
          >
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </p>
        </form>
      ) : (
        <form
          onSubmit={plan === "basic" ? handleFinalSubmit : undefined}
          className="space-y-10"
        >
          <div className="grid gap-7 grid-cols-1 lg:grid-cols-2">
            {/* <Menu as="div" className="w-full relative" aria-disabled>
              {({ open }) => (
                <>
                  <Menu.Button as="div" className="">
                    <div className="w-full">
                      <label
                        className="text-xs mb-3 pl-5 block font-medium"
                        htmlFor="preferred_gender"
                      >
                        Preferred gender
                      </label>
                      <div className="px-5 py-3 appearance-none border-0 ouline-0 ring-2 focus:ring-4 bg-transparent focus:ring-brand-2 w-full rounded-3xl flex justify-between items-center">
                        <p className="text-sm">
                          {gender === "Male" ? "Female" : "Male"}
                        </p>
                      </div>
                    </div>
                  </Menu.Button>
                </>
              )}
            </Menu>
            <Menu as="div" className="w-full relative">
              {({ open }) => (
                <>
                  <Menu.Button as="div" className="">
                    <div className="w-full">
                      <label
                        className="text-xs mb-3 pl-5 block font-medium"
                        htmlFor="preferred_age_range"
                      >
                        Preferred age range
                      </label>
                      <div className="w-full">
                        <div className="px-4 py-1 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl">
                          <Slider
                            getAriaLabel={() => "Age range"}
                            value={preferred_age}
                            onChange={handleDOBChange}
                            valueLabelDisplay="auto"
                            getAriaValueText={valuetext}
                            min={23}
                            max={60}
                          />
                        </div>
                      </div>
                    </div>
                  </Menu.Button>
                </>
              )}
            </Menu> */}
            <Menu as="div" className="w-full relative">
              {({ open }) => (
                <>
                  <Menu.Button as="div" className="">
                    <div className="w-full">
                      <label
                        className="text-xs mb-3 pl-5 block font-medium"
                        htmlFor="location_country"
                      >
                        Preferred Location(s)
                      </label>
                      <div className="px-5 py-3 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl flex justify-between items-center cursor-pointer">
                        <p className="text-sm">
                          {preferred_country.length === 0
                            ? "Select preferred location(s)"
                            : preferred_country.join()}
                        </p>
                        <i className="fa-solid fa-chevron-down"></i>
                      </div>
                    </div>
                  </Menu.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                    >
                      <div className="flex items-center text-text-1 justify-between">
                        <p className="text-10 ">
                          Select one, multiple or any location(s)
                        </p>
                        <i className="fa-solid text-10 fa-chevron-up"></i>
                      </div>
                      {["Any", ...COUNTRY_LIST].map((l_c) => (
                        <div
                          key={l_c}
                          onClick={() => {
                            if (preferred_country.includes(l_c)) {
                              setPreferredLocation((prev) =>
                                prev.filter((e) => e !== l_c)
                              );
                            } else {
                              setPreferredLocation((prev) => [...prev, l_c]);
                            }
                          }}
                          className={`w-full rounded-lg px-3 py-2 hover:bg-drop-1 hover:text-white text-xs ${
                            preferred_country.includes(l_c)
                              ? "bg-drop-1"
                              : "bg-white"
                          }`}
                        >
                          {l_c}
                        </div>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
            <Menu as="div" className="w-full relative">
              {({ open }) => (
                <>
                  <Menu.Button as="div" className="">
                    <div className="w-full">
                      <label
                        className="text-xs mb-3 pl-5 block font-medium"
                        htmlFor="preferred_religion"
                      >
                        Preferred Religion(s)
                      </label>
                      <div className="px-5 py-3 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl flex justify-between items-center cursor-pointer">
                        <p className="text-sm">
                          {preferred_religion.length === 0
                            ? "Select Preferred religion(s)"
                            : preferred_religion.join()}
                        </p>
                        <i className="fa-solid fa-chevron-down"></i>
                      </div>
                    </div>
                  </Menu.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                    >
                      <div className="flex items-center text-text-1 justify-between">
                        <p className="text-10 ">
                          Select one, multiple or all religion(s)
                        </p>
                        <i className="fa-solid text-10 fa-chevron-up"></i>
                      </div>
                      {["Any", ...RELIGIONS].map((p_r) => (
                        <div
                          key={p_r}
                          onClick={() => {
                            if (preferred_religion.includes(p_r)) {
                              setPreferedReligion((prev) =>
                                prev.filter((e) => e !== p_r)
                              );
                            } else {
                              setPreferedReligion((prev) => [...prev, p_r]);
                            }
                          }}
                          className={`w-full rounded-lg px-3 py-2 hover:bg-drop-1 hover:text-white text-xs ${
                            preferred_religion.includes(p_r)
                              ? "bg-drop-1"
                              : "bg-white"
                          }`}
                        >
                          {p_r}
                        </div>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
            <Menu as="div" className="w-full relative">
              {({ open }) => (
                <>
                  <Menu.Button as="div" className="">
                    <div className="w-full">
                      <label
                        className="text-xs mb-3 pl-5 block font-medium"
                        htmlFor="preferred_genotype"
                      >
                        Prefered Genotype(s)
                      </label>
                      <div className="px-5 py-3 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl flex justify-between items-center cursor-pointer">
                        <p className="text-sm">
                          {preferred_genotype.length === 0
                            ? "Select prefered genotype"
                            : preferred_genotype.join()}
                        </p>
                        <i className="fa-solid fa-chevron-down"></i>
                      </div>
                    </div>
                  </Menu.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                    >
                      <div className="flex items-center text-text-1 justify-between">
                        <p className="text-10 ">
                          Select one or multiple genotype(s)
                        </p>
                        <i className="fa-solid text-10 fa-chevron-up"></i>
                      </div>
                      {GENOTYPE.map((p_g) => (
                        <div
                          key={p_g}
                          onClick={() => {
                            if (preferred_genotype.includes(p_g)) {
                              setpreferred_genotype((prev) =>
                                prev.filter((e) => e !== p_g)
                              );
                            } else {
                              setpreferred_genotype((prev) => [...prev, p_g]);
                            }
                          }}
                          className={`w-full rounded-lg px-3 py-2 hover:bg-drop-1 hover:text-white text-xs ${
                            preferred_genotype.includes(p_g)
                              ? "bg-drop-1"
                              : "bg-white"
                          }`}
                        >
                          {p_g}
                        </div>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
            <Menu as="div" className="w-full relative">
              {({ open }) => (
                <>
                  <Menu.Button as="div" className="">
                    <div className="w-full">
                      <label
                        className="text-xs mb-3 pl-5 block font-medium"
                        htmlFor="preferred_status"
                      >
                        Preferred Relationship status(es)
                      </label>
                      <div className="px-5 py-3 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl flex justify-between items-center cursor-pointer">
                        <p className="text-sm">
                          {preferred_status.length === 0
                            ? "Select status(es)"
                            : preferred_status.join()}
                        </p>
                        <i className="fa-solid fa-chevron-down"></i>
                      </div>
                    </div>
                  </Menu.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                    >
                      <div className="flex items-center text-text-1 justify-between">
                        <p className="text-10 ">
                          Select one, multiple or any status(es)
                        </p>
                        <i className="fa-solid text-10 fa-chevron-up"></i>
                      </div>
                      {["Any", ...RELATIONSHIP_STATUS].map((status) => (
                        <div
                          key={status}
                          // onClick={() => setPreferred_status(status)}
                          onClick={() => {
                            if (preferred_status.includes(status)) {
                              setPreferred_status((prev) =>
                                prev.filter((e) => e !== status)
                              );
                            } else {
                              setPreferred_status((prev) => [...prev, status]);
                            }
                          }}
                          className={`w-full rounded-lg px-3 py-2 hover:bg-drop-1 hover:text-white text-xs ${
                            preferred_status.includes(status)
                              ? "bg-drop-1"
                              : "bg-white"
                          }`}
                        >
                          {status}
                        </div>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
          {Boolean(!last_step_complete) && (
            <label
              className="text-xs mb-3 pl-5 block font-medium text-brand-red-1"
              style={{
                marginTop: "10px",
              }}
            >
              *All Fields Are required
            </label>
          )}
          <div className="w-full">
            <label className="text-s block font-medium" htmlFor="email">
              <input
                required={true}
                placeholder="Agree"
                type="checkbox"
                className="appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 rounded-3xl mr-3"
                onChange={(e) => {
                  setUser_agreed(e.target.checked);
                  setUserReference(`REFSUB${Date.now().toString()}`);
                }}
                value={Number(user_agreed)}
              />
              By continuing you agree to our&nbsp;{" "}
              <a
                href="/terms-and-condition"
                className="text-brand-red-1"
                target="_blank"
              >
                Terms & Conditions.
              </a>
            </label>
          </div>
          {save_modal ? (
            <div
              className={`py-2.5 font-semibold text-white`}
              style={{ display: "grid", placeItems: "center" }}
            >
              <CircularProgress size={14} color={"primary"} />
            </div>
          ) : plan === "basic" ? (
            <button
              type="button"
              className={`py-2.5 block w-full font-semibold text-white ${
                Boolean(!last_step_complete) ? "bg-disabled-1" : "bg-brand-1"
              } rounded-3xl`}
              disabled={Boolean(!last_step_complete)}
              onClick={handleFinalSubmit}
            >
              Save Profile
            </button>
          ) : (
            <PaystackPortal
              currency={plan_cost[0]}
              plan_cost={plan_cost.slice(1)}
              amount={plan_cost.slice(1)}
              email={userPreData?.email}
              phone_number={userPreData?.phone_number}
              name={`${userPreData?.last_name} ${userPreData?.first_name}`}
              plan={plan}
              onClose={onClose}
              onSuccess={setPaid} //{paidSubmit}
              title={`Pay ${plan_cost} for ${plan} plan`}
              last_step_complete={last_step_complete}
              currency_code={currency_code}
              plan_duration={plan_duration}
              reference={user_reference}
              mainCurrency={country?.trim?.() === "nigeria" ? "NGN" : "USD"}
            />
          )}

          <p
            onClick={() => setAuthPage((val: any) => (val -= 1))}
            className="flex gap-5 cursor-pointer justify-center items-center hover:text-brand-1"
          >
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </p>
        </form>
      )}
      <Modal type={"profile_saved"} open={save_modal} close={toggleModal} />
      {submitting && <PageLoadingOverlay open={submitting} />}
    </>
  );
}

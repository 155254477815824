import { Box } from "@mui/material";
import { styled } from "@mui/system";
import theme from "src/assets/colors/theme";

export const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "showHeader",
})<{ showHeader?: boolean }>(({ showHeader }) => ({
  overflowX: !showHeader ? undefined : "hidden",
  overflowY: !showHeader ? undefined : "scroll",
  height: !showHeader ? undefined : "calc(100vh - 225px)",
  borderRadius: "4px",
  padding: !showHeader ? "8px 0px" : "8px 0px",
  backgroundColor: theme.WHITE,

  "& .search-container": {
    height: "100%",

    "& .input-cont": {
      width: "100%",
      height: "36px",
      display: "flex",
      justifyContent: "space-between",
      gap: "8px",
      marginBottom: "12px",

      "& .MuiFormControl-root": {
        width: "100%",

        "& input": {
          padding: "6.5px 14px 6.5px 0",
        },
      },
    },

    "& .property-list": {
      "& .MuiBox-root": {
        padding: "12px 16px",
        cursor: "pointer",

        "& .MuiTypography-root": {
          fontSize: "1rem",
          color: theme.TEXT_PRIMARY,
          lineHeight: "24px",
          letterSpacing: "0.15px",
          fontWeight: "400",
        },
      },
    },
  },

  "& .actions": {
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(0,1fr))",
    gap: "8px",
    marginBottom: "5px",

    a: {
      gridColumn: "span 1",
      border: `1px solid ${theme.PRIMARY}`,
      height: "40px",
      borderRadius: "4px",

      fontWeight: 500,
      fontSize: "14px",

      letterSpacing: "0.4px",
      lineHeight: "24px",

      color: theme.PRIMARY,
      textDecoration: "none",

      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      // ':last-child': {
      //   gridColumn: 'span 2',
      // },
    },

    button: {
      gridColumn: "span 1",
      border: `1px solid ${theme.PRIMARY}`,
      height: "40px",
      borderRadius: "4px",

      fontWeight: 500,
      fontSize: "14px",

      letterSpacing: "0.4px",
      lineHeight: "24px",

      color: theme.PRIMARY,
      textDecoration: "none",

      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      ":last-child": {
        gridColumn: "span 2",
      },
    },
  },

  "& .person-info": {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.PRIMARY_BACKGROUND,
    padding: "8px",
    borderRadius: "4px",

    "& .top-container": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",

      "& .top-content": {
        display: "flex",
        alignItems: "center",
        width: "100%",
      },

      a: {
        color: theme.PRIMARY,
        textDecoration: "none",

        fontWeight: 500,
        fontSize: "1rem",

        letterSpacing: "0.15px",
        lineHeight: "18px",
      },

      "& .MuiChip-root": {
        marginRight: "0px",
        marginLeft: "8px",
      },

      "& .title": {
        fontSize: "16px",
        color: theme.PRIMARY,
        lineHeight: "18px",
        letterSpacing: "0.15px",
        fontWeight: "500",
        display: "flex",
        width: "100%",

        // quick hack - Emmanuel
        maxHeight: "38px",

        a: {
          // overflow: 'hidden',
          // display: '-webkit-box',
          // WebkitBoxOrient: 'vertical',
          // WebkitLineClamp: '2',
          overflowX: "scroll",
          whiteSpace: "nowrap",
          // width: '17vw',
          // width: '280px',
        },

        "& .MuiSvgIcon-root": {
          marginRight: "8px",
          alignSelf: "center",
        },
      },

      "& .address": {
        marginTop: "4px",
        fontSize: "1rem",
        color: theme.TEXT_PRIMARY,
        lineHeight: "24px",
        letterSpacing: "0.15px",
        fontWeight: "normal",
        width: "60%",
      },

      "& .chip-container": {
        marginTop: "4px",
        marginLeft: "-8px",
      },
    },

    "& .info-card": {
      marginTop: "12px",
      display: "flex",
      flexDirection: "column",
      border: `1px solid ${theme.GREY_VARIANT}`,
      backgroundColor: theme.WHITE,
      borderRadius: "4px",
      padding: "0 8px 8px",

      "& .bg-pry": {
        color: `${theme.PRIMARY}  !important`,
      },

      "& .content": {
        padding: "14px 0",
        borderBottom: `1px solid ${theme.GREY_VARIANT}`,
        display: "flex",
        alignItems: "center",

        a: {
          textDecoration: "none",
        },

        "& .container": {
          display: "flex",
          alignItems: "center",

          width: "100%",
        },
      },

      span: {
        fontSize: "14px",
      },

      "& .key": {
        fontWeight: "500",
        lineHeight: "18px",
        color: theme.TEXT_PRIMARY,
        marginRight: "4px",
        display: "block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",

        a: {
          textDecoration: "none",
          marginLeft: "4px",
        },
      },
    },
  },
}));

export const ToggleContainer = styled(Box)({
  borderRadius: "4px",
  padding: "8px",
  marginBottom: "8px",
  backgroundColor: theme.PRIMARY_BACKGROUND,

  select: {
    border: `1px solid ${theme.PRIMARY}`,
    width: "120px",
    height: "36px",
    borderRadius: "4px",
    padding: "0 5px",

    ":focus-visible": {
      outline: "none",
    },
  },

  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",

    select: {
      color: theme.PRIMARY,
    },

    "& .title": {
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: "18px",
      letterSpacing: "0.15px",
      color: theme.TEXT_PRIMARY,
    },

    button: {
      height: "30px",
      width: "49px",
    },
  },

  "& .container": {
    display: "flex",
    flexDirection: "column",

    "& .content": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: "2px",
      padding: "7px 8px",
      backgroundColor: theme.WHITE,
      border: `1px solid ${theme.GREY_VARIANT}`,

      ":nth-of-type(even)": {
        border: "none",
        backgroundColor: "transparent",
      },

      "& .MuiTypography-root": {
        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        color: theme.TEXT_PRIMARY,
      },

      "& .MuiChip-root": {
        marginRight: 0,
      },
    },
  },

  "& .prop-container": {
    display: "flex",
    flexDirection: "column",
    padding: "4px",

    "& .floor_plan": {
      height: "48px",
      backgroundColor: theme.WHITE,
      borderRadius: "2px",
      border: `1px solid ${theme.GREY_VARIANT}`,

      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      ":nth-of-type(even)": {
        border: "none",
        backgroundColor: "transparent",
      },

      "& .content": {
        flex: 1,
        display: "flex",
        alignItems: "center",

        "& .MuiTypography-root": {
          fontSize: "0.875rem",
          color: theme.TEXT_PRIMARY,
          lineHeight: "18px",
          letterSpacing: "0.15px",
          fontWeight: "400",
          margin: "0px 12px",
        },
      },

      "& .prop-actions": {
        display: "flex",
        gap: "6px",

        a: {
          background: "none",
          color: "inherit",
          border: "none",
          padding: 0,
          font: "inherit",
          cursor: "pointer",
          outline: "inherit",
          display: "flex",

          width: "24px",
          height: "40px",

          svg: {
            fontSize: "16px",
            color: theme.PRIMARY,
            margin: "auto",
          },
        },

        button: {
          background: "none",
          color: "inherit",
          border: "none",
          padding: 0,
          font: "inherit",
          cursor: "pointer",
          outline: "inherit",
          display: "flex",

          width: "24px",
          height: "40px",

          svg: {
            fontSize: "16px",
            color: theme.PRIMARY,
            margin: "auto",
          },
        },
      },
    },

    "& .prop-images": {
      display: "grid",
      gridTemplateColumns: "repeat(2,minmax(0,1fr))",
      gap: "8px",

      "& .MuiBox-root": {
        width: "100%",
        height: "149px",
        backgroundColor: theme.GREY_ICON,

        img: {
          width: "100%",
          height: "100%",
        },
      },
    },
  },
});

export const ToggleInfoContainer = styled(Box)({
  padding: "8px",
  borderRadius: "2px",
  backgroundColor: theme.WHITE,
  border: `1px solid ${theme.GREY_VARIANT}`,

  ":not(:first-of-type)": {
    marginTop: "4px",
  },

  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    marginBottom: "0px",

    "& .title": {
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: "18px",
      letterSpacing: "0.15px",
      color: theme.TEXT_PRIMARY,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      svg: {
        color: theme.TEXT_PRIMARY,
        fontSize: "1rem",
      },
    },

    "& .subtitle": {
      fontWeight: 400,
      fontSize: "0.7rem",
      lineHeight: "18px",
      letterSpacing: "0.15px",
      color: theme.TEXT_PRIMARY,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      marginTop: "4px",
    },
  },
});

export const ToggleUrlInfoContainer = styled(Box)({
  padding: "4px 8px",
  borderRadius: "2px",
  backgroundColor: theme.WHITE,
  border: `1px solid ${theme.GREY_VARIANT}`,

  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  ":not(:first-of-type)": {
    marginTop: "4px",
  },

  ":nth-of-type(even)": {
    border: "none",
    backgroundColor: "transparent",
  },

  "& .MuiBox-root": {
    "& .MuiTypography-root": {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      color: theme.TEXT_PRIMARY,

      span: {
        display: "block",
      },

      a: {
        color: theme.PRIMARY,
        textDecoration: "none",
      },
    },
  },
});

import { useMutation } from "@apollo/client";
import { ArrowRightAlt } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import ASSETS from "src/asset";
import {
  CHECK_VALID_USER,
  SEND_MAIL_CODE,
  VERIFY_MAIL_CODE,
} from "src/components/auth/data/mutation";
import useSignUp from "src/components/auth/hooks/useSignUp";

export default function NewVerification() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isMailVerification, setIsMailVerification] = useState(false);
  const [checkUser] = useMutation(CHECK_VALID_USER);
  const [verifyMailCode] = useMutation(VERIFY_MAIL_CODE);
  const [sendMailCode] = useMutation(SEND_MAIL_CODE);

  const { verifyEmailAddress } = useSignUp();

  const handleStartVerification = (theMail: any) => {
    setEmailError("");
    setIsLoading(true);
    setIsMailVerification(false);
    verifyEmailAddress(theMail)
      .then((value) => {
        //@ts-ignore
        if (value?.data?.verifyEmail === false) {
          setIsLoading(false);
          return setEmailError("This email is taken already!");
        }
        handleCheckUser(theMail);
      })
      .catch((error) => {
        console.log({ error });
      });
  };
  const handleSendMail = (theMail: any) => {
    setEmailError("");
    setIsLoading(true);
    sendMailCode({
      variables: {
        email: theMail,
      },
      onCompleted: (data) => {
        if (data?.sendEmailCode === false) {
          setIsLoading(false);
          toast.error("Something went wrong! Try again later ");
          return;
        }
        setIsMailVerification(true);
        setIsLoading(false);
        toast.success("Kindly check your mail for otp code!");
      },
      onError: (error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Error occured. Try again later");
      },
    });
  };
  const handleVerifyMail = (theMail: any, token: any) => {
    setEmailError("");
    setIsLoading(true);
    verifyMailCode({
      variables: {
        email: theMail,
        token,
      },
      onCompleted: (data) => {
        if (data?.verifyEmail === false) {
          setIsLoading(false);
          toast.error("Incorrect OTP, kindly check your mail");
          return;
        }
        localStorage?.clear?.();
        navigate(`/verification?email=${theMail}`);
      },
      onError: (error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Error occured. Try again later");
      },
    });
  };

  const handleCheckUser = (emailValue: any) => {
    setIsLoading(true);
    checkUser({
      variables: {
        email: emailValue,
      },
      onCompleted: (data) => {
        // setIsLoading(false);
        if (data?.verifyPreEmail?.message === "") {
          navigate(`/verificationsuccess`, {
            state: { payload: data?.verifyPreEmail?.payload },
          });
          return;
        }
        if (data?.verifyPreEmail?.message === "rejected") {
          navigate(`/verificationerror`);
          return;
        }
        handleSendMail(emailValue);
      },
      onError: (error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Error occured. Try again later");
      },
    });
  };
  return (
    <div className="w-full h-screen overflow-hidden">
      <div className="w-full grid-cols-1 h-full grid md:grid-cols-2">
        <div
          style={{
            backgroundImage: `url(${ASSETS.VerifyBackground})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundBlendMode: "overlay",
          }}
          className="w-full h-full hidden md:flex flex-col justify-end bg-[#00010781]"
        >
          <h2 className="text-2xl w-4/5 lg:w-3/5 mx-auto pb-10 playfair font-bold text-white">
            Welcome to the Nirvanae Private Match-making Community
          </h2>
        </div>
        <div className="w-full h-full overflow-y-auto md:overflow-hidden flex flex-col justify-between items-center p-5 sm:p-10">
          <div className="w-full space-y-5 lg:space-y-10">
            <img src={ASSETS?.Logo} className=" w-24 h-10 sm:h-20" />
            <div className="space-y-5">
              <h2 className="text-xl lg:text-3xl playfair text-[#081A51] font-bold ">
                Let’s Get Started
              </h2>
              <p className="text-sm lg:text-lg jarkata_sans">
                We are glad you have chosen us as your guide for your love
                journey. To ensure you are a fit for our exclusive community and
                are eligible for our service, we’d like you to answer a few
                questions. This will help us ensure you are eligible and match
                you with compatible like-minded individuals. Not to worry, this
                will only take a moment.
              </p>
              <div className="pt-5 grid-cols-1 gap-10 grid sm:grid-cols-2 sm:gap-5">
                <div className="w-full relative">
                  <input
                    type="email"
                    value={email}
                    disabled={isMailVerification}
                    onChange={(e) => {
                      if (isMailVerification) {
                        return;
                      }

                      setEmail(e.target.value);
                      setEmailError("");
                    }}
                    placeholder="Enter email to start or continue"
                    className={`text-sm w-full disabled:bg-gray-300 py-2.5 outline-none ring-0 border ${
                      emailError
                        ? "border-red-400"
                        : "focus:border-blue-600 border-gray-400 "
                    } rounded-lg`}
                  />
                  {emailError && (
                    <p className="text-red-600 absolute top-full mt-1 text-xs font-medium">
                      {emailError}
                    </p>
                  )}
                </div>
                {isMailVerification && (
                  <div className="w-full relative">
                    <input
                      type="text"
                      value={otp}
                      onChange={(e) => {
                        setOtp(e.target.value);
                        setOtpError("");
                      }}
                      placeholder="Enter otp from mail"
                      className={`text-sm w-full py-2.5 outline-none ring-0 border ${
                        otpError
                          ? "border-red-400"
                          : "focus:border-blue-600 border-gray-400 "
                      } rounded-lg`}
                    />
                    {otpError && (
                      <p className="text-red-600 absolute top-full mt-1 text-xs font-medium">
                        {otpError}
                      </p>
                    )}
                  </div>
                )}
                <button
                  disabled={isLoading}
                  onClick={() => {
                    if (!isMailVerification) {
                      const sanitizeMail = email?.trim?.()?.toLowerCase?.();
                      if (sanitizeMail === "") {
                        return setEmailError("Kindly add an email");
                      }
                      const match = sanitizeMail.match(
                        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
                      );
                      if (!match) {
                        setEmailError("Invalid email address");
                        return;
                      }
                      handleStartVerification(sanitizeMail);
                      return;
                    }
                    const sanitizeOtp = otp?.trim?.();
                    if (sanitizeOtp === "") {
                      return setOtpError("Kindly input the otp code");
                    }
                    return handleVerifyMail(
                      email?.trim?.()?.toLowerCase?.(),
                      sanitizeOtp
                    );
                  }}
                  className="px-4 w-full sm:px-6 py-2.5 bg-[#081a51] jarkata_sans text-xs sm:text-base rounded-lg text-white flex gap-2 items-center justify-center font-medium"
                >
                  {isLoading ? (
                    <BeatLoader size={10} color="#fff" />
                  ) : isMailVerification ? (
                    <>
                      Submit OTP
                      <ArrowRightAlt fontSize="inherit" />
                    </>
                  ) : (
                    <>
                      Get Started
                      <ArrowRightAlt fontSize="inherit" />
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="flex justify-center pt-10 space-x-5 divide-x divide-[#222E50] items-center">
            <p
              onClick={() => {
                navigate("/");
              }}
              className="text-xs w-max cursor-pointer hover:opacity-70 font-medium"
            >
              Home
            </p>
            <div className="pl-4">
              <p
                onClick={() => {
                  navigate("/login");
                }}
                className="text-xs w-max cursor-pointer hover:opacity-70 font-medium"
              >
                Login
              </p>
            </div>
            <div className="pl-4">
              <a
                target="_blank"
                href="/terms-and-condition"
                className="text-xs p-0 w-max cursor-pointer appearance-none hover:opacity-70 font-medium"
              >
                Terms and Condition
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

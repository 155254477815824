import { DateTime } from "luxon";
import { format } from "date-fns";

export const formatSecondsToMinuteSeconds = (seconds: number) => {
  if (isNaN(seconds)) {
    return ``;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};

export const formatSecondsToMinuteSecondsSpaced = (seconds: number) => {
  if (isNaN(seconds)) {
    return ``;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh} hr ${mm.toString().padStart(2, "0")} min ${ss} sec`;
  }
  if (mm) {
    return `${mm} min ${ss} sec`;
  }
  return `${ss} sec`;
};

export const timeSinceDateFormatted = (date: string) => {
  const created_at = new Date(date);
  const now_millisecounds = +new Date();
  const created_at_milliseconds = +new Date(date);
  const difference = now_millisecounds - created_at_milliseconds;
  const one_minute = 1000 * 60;
  const one_hour = 1000 * 60 * 60;
  const one_day = 1000 * 60 * 60 * 24;

  return difference > one_day
    ? `${created_at?.toLocaleString("default", {
        month: "short",
      })} ${created_at?.toLocaleString("default", {
        day: "2-digit",
      })}`
    : difference > one_hour
    ? `${Math.floor(difference / one_hour)}h`
    : `${Math.floor(difference / one_minute) || 1}m`;
};

export const formatDate = (date: string) => {
  if (!date) {
    return "";
  }
  const today = new Date(date);
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();

  return `${mm}/${dd}/${yyyy}`;
};

export const formatDateTime = (createdAt: string) =>
  format(new Date(createdAt), "Pp");

export const formatTime = (createdAt: string, is24Hours = false) => {
  const format = is24Hours ? DateTime.TIME_24_SIMPLE : DateTime.TIME_SIMPLE;
  const date = DateTime.fromISO(createdAt);
  return date.toLocaleString(format);
};

export const formatMonthDay = (createdAt: string) => {
  const today = new Date(createdAt);
  const month = today.toLocaleString("default", { month: "short" });
  const dd = String(today.getDate());
  return `${month} ${dd}`;
};

/**
 * @param tz
 * @returns
 * Supported Formats Docs: https://moment.github.io/luxon/#/formatting
 * TODO: replace with date-fns
 */
export const getTimestampByTz = (tz?: string | null, format?: string) => {
  return DateTime.local().setZone(tz).toFormat(format);
};

/**
 *
 * @param dateA
 * @param dateB
 * @param units pass in an array of units you want to get the diff back in the default h:m:s
 * @returns an object with the specified units
 */
export const getTimeDifferenceBetweenTwoDates = (
  dateA: string,
  dateB: string,
  units: string[] = ["hours", "minutes", "seconds"]
) => {
  try {
    const end = DateTime.fromISO(dateB);
    const start = DateTime.fromISO(dateA);
    return end.diff(start, units).toObject();
  } catch (error) {
    return {};
  }
};

export const generateTimestampFromTime = (time: string) => {
  const [hours, minutes, seconds] = time.split(":");
  const date = new Date();
  date.setHours(Number(hours));
  date.setMinutes(Number(minutes));
  if (seconds) {
    date.setSeconds(Number(seconds));
  }

  // const tzoffset = new Date().getTimezoneOffset() * 60000 //offset in milliseconds
  // const localISOTime = new Date(date.getTime() - tzoffset).toISOString()

  return date;
};

export const daysToSeconds = (days) => {
  if (!days) return 0;
  return days * 24 * 60 * 60;
};

export const secondsToDays = (sec) => {
  if (!sec) return 0;
  return sec / 86400;
};

export const extractTimeFromDate = (timestamp, includeSeconds = false) => {
  if (!timestamp) return null;
  const dateTime = DateTime.fromJSDate(timestamp);
  const format = includeSeconds ? "HH:mm:ss" : "HH:mm";
  return dateTime.toFormat(format);
};

export const convertToSecondsbyformat = (time: number, format: string) => {
  switch (format.toLowerCase()) {
    case "days":
      return time * 24 * 60 * 60;
    case "hours":
      return time * 60 * 60;
    case "minutes":
      return time * 60;
  }
};
export const convertfromSecondsbyformat = (
  seconds: number,
  format = "days"
) => {
  switch (format.toLowerCase()) {
    case "days":
      return seconds / 24 / 60 / 60;
    case "hours":
      return seconds / 60 / 60;
    case "minutes":
      return seconds / 60;
  }
};
export const extractDayFromDate = (timestamp) => {
  const dateTime = DateTime.fromJSDate(timestamp, { zone: "utc" });
  return dateTime.toFormat("M/dd");
};

export const calculateTimeLefttillNextPayment = (user) => {
  if (user.Invoice.length > 0) {
    const lastInvoice = user.Invoice?.sort(
      // @ts-ignore
      (a: any, b: any) => new Date(b?.created_at) - new Date(a?.created_at)
    )[0];
    const lastPaymentDate: any = new Date(lastInvoice?.created_at);
    const months_of_payment: number = calcualteMonthDuration(
      lastInvoice.payment?.amount
    );
    const nextPaymentDate: any = new Date(
      lastPaymentDate.setMonth(lastPaymentDate?.getMonth() + months_of_payment)
    );
    const currentDate: any = new Date();
    const timeLeft = nextPaymentDate - currentDate;
    const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    return daysLeft + " days left";
  }
  return "Not Available";
};

// export const AuthcalculateTimeLefttillNextPayment = (user) => {
//   console.log(user, "<<<<<<<<")
//   const lastInvoice = user?.sort(
//     // @ts-ignore
//     (a: any, b: any) => new Date(b.created_at) - new Date(a.created_at)
//     )[0];
//   const lastPaymentDate: any = new Date(lastInvoice.created_at);
//   const months_of_payment: number = calcualteMonthDuration(
//     lastInvoice.payment.amount
//   );
//   const nextPaymentDate: any = new Date(
//     lastPaymentDate.setMonth(lastPaymentDate.getMonth() + months_of_payment)
//   );
//   const currentDate: any = new Date();
//   const timeLeft = nextPaymentDate - currentDate;
//   const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
//   return daysLeft + " days left";
// };

const calcualteMonthDuration = (amount_paid) => {
  if (amount_paid === "40000") return 1;
  if (amount_paid === "90000") return 3;
  if (amount_paid === "120000") return 6;
  return 12;
};

export const sortMessages = (messages) => {
  if (!messages || messages.length === 0) {
    console.error("Messages array is empty or undefined");
    return [];
  }
  const messagesCopy = [...messages];

  return messagesCopy.sort((a, b) => {
    const dateA = new Date(a?.created_at);
    const dateB = new Date(b?.created_at);
    return dateB.getTime() - dateA.getTime();
  });
};

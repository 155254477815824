import { Disclosure } from "@headlessui/react";
import {
  DateRange,
  ErrorOutline,
  ExpandLess,
  ExpandMore,
  Replay,
} from "@mui/icons-material";
const Person =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/person.svg";

export default function AdminStrikesPage() {
  return (
    <div className="space-y-5 h-full w-full pb-40 p-5 overflow-auto ">
      <Disclosure
        as="div"
        className={"bg-[#f1f2faa2] border-gray-300 rounded-lg border"}
        defaultOpen={true}
      >
        {({ open }) => (
          <>
            <Disclosure.Button className="w-full">
              <div
                className={`flex w-full ${
                  open ? "rounded-t-lg" : "rounded-lg"
                } cursor-pointer items-center justify-between bg-white shadow-md p-4`}
              >
                <div className="flex gap-5 items-center">
                  <div className="w-10 h-10 flex justify-center items-center flex-shrink-0 rounded-full bg-[#21407d53]">
                    <img
                      src={Person}
                      alt="person"
                      className="w-full h-full rounded-full object-cover"
                    />
                  </div>
                  <p className="text-sm font-bold">Martin Jake</p>
                </div>
                <div className="flex gap-5 items-center">
                  <div className="gap-2 text-yellow-400 items-center flex">
                    <ErrorOutline fontSize="small" />
                    <h2 className="text-sm font-semibold">
                      Violation of Privacy
                    </h2>
                  </div>
                  <div className="gap-2 items-center flex">
                    <DateRange fontSize="small" />
                    <h2 className="text-sm font-semibold">
                      Apr 30, 2020 10:00 AM
                    </h2>
                  </div>
                  <span className="w-6 h-6 bg-gray-200 flex justify-center items-center rounded-full">
                    {open ? <ExpandLess fontSize="small" /> : <ExpandMore />}
                  </span>
                </div>
              </div>
            </Disclosure.Button>

            <Disclosure.Panel className="p-5 bg-white">
              <div className="space-y-5">
                <div className=" w-full bg-white overflow-hidden h-40 p-5 rounded-lg border border-[#017EFA]">
                  <p className="text-10">
                    Lorem ipsum dolor sit amet consectetur. Aliquam risus
                    aliquet et enim congue dui. Fusce eget egestas sed
                    consectetur. Natoque pulvinar adipiscing velit diam facilisi
                    in. Auctor interdum ipsum tempor venenatis id faucibus a.
                    Nibh viverra tellus orci pharetra donec. Non vulputate netus
                    hendrerit eget justo. At a pharetra pellentesque congue
                    venenatis molestie neque ultrices. Tortor nibh dictum
                    euismod sit volutpat imperdiet. Quisque eleifend ut feugiat
                    nec aliquet. Turpis in id id faucibus tempus. Eget
                    adipiscing mi vel vitae nibh cursus. Lobortis urna
                    pellentesque enim ultrices enim fermentum etiam. Arcu nunc
                    placerat morbi mauris eleifend velit dolor. Mollis diam quis
                    nec molestie sit vitae. Nulla eu magna leo nam massa ipsum
                    risus malesuada. Rhoncus eu elit at risus platea fames
                    varius mauris. Pharetra dolor dignissim egestas amet commodo
                    interdum augue. Sagittis fringilla non vitae orci lobortis
                    amet in sem volutpat. In tortor dui quisque eget. Praesent
                    hendrerit eu natoque viverra nisi sit venenatis semper
                    aliquam. Lorem ipsum dolor sit amet consectetur. Aliquam
                    risus aliquet et enim congue dui. Fusce eget egestas sed
                    consectetur. Natoque pulvinar adipiscing velit diam facilisi
                    in. Auctor interdum ipsum tempor venenatis id faucibus a.
                    Nibh viverra tellus orci pharetra donec. Non vulputate netus
                    hendrerit eget justo. At a pharetra pellentesque congue
                    venenatis molestie neque ultrices. Tortor nibh dictum
                    euismod sit volutpat{" "}
                  </p>
                </div>

                <button className="px-6 rounded-lg py-2.5 text-sm bg-[#017EFA] text-white flex gap-2 items-center hover:opacity-70 hover:shadow-md ">
                  Rescind
                  <Replay fontSize="small" />
                </button>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Disclosure
        as="div"
        className={"bg-[#f1f2faa2] border-gray-300 rounded-lg border"}
        defaultOpen={false}
      >
        {({ open }) => (
          <>
            <Disclosure.Button className="w-full">
              <div
                className={`flex w-full ${
                  open ? "rounded-t-lg" : "rounded-lg"
                } cursor-pointer items-center justify-between bg-white shadow-md p-4`}
              >
                <div className="flex gap-5 items-center">
                  <div className="w-10 h-10 flex justify-center items-center flex-shrink-0 rounded-full bg-[#21407d53]">
                    <img
                      src={Person}
                      alt="person"
                      className="w-full h-full rounded-full object-cover"
                    />
                  </div>
                  <p className="text-sm font-bold">Martin Jake</p>
                </div>
                <div className="flex gap-5 items-center">
                  <div className="gap-2 text-yellow-400 items-center flex">
                    <ErrorOutline fontSize="small" />
                    <h2 className="text-sm font-semibold">
                      Violation of Privacy
                    </h2>
                  </div>
                  <div className="gap-2 items-center flex">
                    <DateRange fontSize="small" />
                    <h2 className="text-sm font-semibold">
                      Apr 30, 2020 10:00 AM
                    </h2>
                  </div>
                  <span className="w-6 h-6 bg-gray-200 flex justify-center items-center rounded-full">
                    {open ? <ExpandLess fontSize="small" /> : <ExpandMore />}
                  </span>
                </div>
              </div>
            </Disclosure.Button>

            <Disclosure.Panel className="p-5 bg-white">
              <div className="space-y-5">
                <div className=" w-full bg-white overflow-hidden h-40 p-5 rounded-lg border border-[#017EFA]">
                  <p className="text-10">
                    Lorem ipsum dolor sit amet consectetur. Aliquam risus
                    aliquet et enim congue dui. Fusce eget egestas sed
                    consectetur. Natoque pulvinar adipiscing velit diam facilisi
                    in. Auctor interdum ipsum tempor venenatis id faucibus a.
                    Nibh viverra tellus orci pharetra donec. Non vulputate netus
                    hendrerit eget justo. At a pharetra pellentesque congue
                    venenatis molestie neque ultrices. Tortor nibh dictum
                    euismod sit volutpat imperdiet. Quisque eleifend ut feugiat
                    nec aliquet. Turpis in id id faucibus tempus. Eget
                    adipiscing mi vel vitae nibh cursus. Lobortis urna
                    pellentesque enim ultrices enim fermentum etiam. Arcu nunc
                    placerat morbi mauris eleifend velit dolor. Mollis diam quis
                    nec molestie sit vitae. Nulla eu magna leo nam massa ipsum
                    risus malesuada. Rhoncus eu elit at risus platea fames
                    varius mauris. Pharetra dolor dignissim egestas amet commodo
                    interdum augue. Sagittis fringilla non vitae orci lobortis
                    amet in sem volutpat. In tortor dui quisque eget. Praesent
                    hendrerit eu natoque viverra nisi sit venenatis semper
                    aliquam. Lorem ipsum dolor sit amet consectetur. Aliquam
                    risus aliquet et enim congue dui. Fusce eget egestas sed
                    consectetur. Natoque pulvinar adipiscing velit diam facilisi
                    in. Auctor interdum ipsum tempor venenatis id faucibus a.
                    Nibh viverra tellus orci pharetra donec. Non vulputate netus
                    hendrerit eget justo. At a pharetra pellentesque congue
                    venenatis molestie neque ultrices. Tortor nibh dictum
                    euismod sit volutpat{" "}
                  </p>
                </div>

                <button className="px-6 rounded-lg py-2.5 text-sm bg-[#017EFA] text-white flex gap-2 items-center hover:opacity-70 hover:shadow-md ">
                  Rescind
                  <Replay fontSize="small" />
                </button>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Disclosure
        as="div"
        className={"bg-[#f1f2faa2] border-gray-300 rounded-lg border"}
        defaultOpen={false}
      >
        {({ open }) => (
          <>
            <Disclosure.Button className="w-full">
              <div
                className={`flex w-full ${
                  open ? "rounded-t-lg" : "rounded-lg"
                } cursor-pointer items-center justify-between bg-white shadow-md p-4`}
              >
                <div className="flex gap-5 items-center">
                  <div className="w-10 h-10 flex justify-center items-center flex-shrink-0 rounded-full bg-[#21407d53]">
                    <img
                      src={Person}
                      alt="person"
                      className="w-full h-full rounded-full object-cover"
                    />
                  </div>
                  <p className="text-sm font-bold">Martin Jake</p>
                </div>
                <div className="flex gap-5 items-center">
                  <div className="gap-2 text-yellow-400 items-center flex">
                    <ErrorOutline fontSize="small" />
                    <h2 className="text-sm font-semibold">
                      Violation of Privacy
                    </h2>
                  </div>
                  <div className="gap-2 items-center flex">
                    <DateRange fontSize="small" />
                    <h2 className="text-sm font-semibold">
                      Apr 30, 2020 10:00 AM
                    </h2>
                  </div>
                  <span className="w-6 h-6 bg-gray-200 flex justify-center items-center rounded-full">
                    {open ? <ExpandLess fontSize="small" /> : <ExpandMore />}
                  </span>
                </div>
              </div>
            </Disclosure.Button>

            <Disclosure.Panel className="p-5 bg-white">
              <div className="space-y-5">
                <div className=" w-full bg-white overflow-hidden h-40 p-5 rounded-lg border border-[#017EFA]">
                  <p className="text-10">
                    Lorem ipsum dolor sit amet consectetur. Aliquam risus
                    aliquet et enim congue dui. Fusce eget egestas sed
                    consectetur. Natoque pulvinar adipiscing velit diam facilisi
                    in. Auctor interdum ipsum tempor venenatis id faucibus a.
                    Nibh viverra tellus orci pharetra donec. Non vulputate netus
                    hendrerit eget justo. At a pharetra pellentesque congue
                    venenatis molestie neque ultrices. Tortor nibh dictum
                    euismod sit volutpat imperdiet. Quisque eleifend ut feugiat
                    nec aliquet. Turpis in id id faucibus tempus. Eget
                    adipiscing mi vel vitae nibh cursus. Lobortis urna
                    pellentesque enim ultrices enim fermentum etiam. Arcu nunc
                    placerat morbi mauris eleifend velit dolor. Mollis diam quis
                    nec molestie sit vitae. Nulla eu magna leo nam massa ipsum
                    risus malesuada. Rhoncus eu elit at risus platea fames
                    varius mauris. Pharetra dolor dignissim egestas amet commodo
                    interdum augue. Sagittis fringilla non vitae orci lobortis
                    amet in sem volutpat. In tortor dui quisque eget. Praesent
                    hendrerit eu natoque viverra nisi sit venenatis semper
                    aliquam. Lorem ipsum dolor sit amet consectetur. Aliquam
                    risus aliquet et enim congue dui. Fusce eget egestas sed
                    consectetur. Natoque pulvinar adipiscing velit diam facilisi
                    in. Auctor interdum ipsum tempor venenatis id faucibus a.
                    Nibh viverra tellus orci pharetra donec. Non vulputate netus
                    hendrerit eget justo. At a pharetra pellentesque congue
                    venenatis molestie neque ultrices. Tortor nibh dictum
                    euismod sit volutpat{" "}
                  </p>
                </div>

                <button className="px-6 rounded-lg py-2.5 text-sm bg-[#017EFA] text-white flex gap-2 items-center hover:opacity-70 hover:shadow-md ">
                  Rescind
                  <Replay fontSize="small" />
                </button>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}

interface InputFieldProps {
  icon?: React.ReactElement;
  secret?: React.ReactElement;
  secretState?: boolean;
  labelView?: boolean;
  changeVisibleState?: () => void;
  type?: string;
  placeholder?: string;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  name?: string;
  className?: string;
  outBox?: string;
  inBox?: string;
  inputClassName?: string;
  labelStyle?: string;
}

const InputField: React.FC<InputFieldProps> = ({
  icon,
  secret,
  secretState,
  changeVisibleState,
  type,
  placeholder,
  label,
  onChange,
  value,
  name,
  outBox,
  inBox,
  inputClassName,
  labelView,
  labelStyle,
}) => {
  return (
    <>
      {labelView ? (
        <div className={outBox || `mt-8 w-full`}>
          <label className={labelStyle}>{label}</label>
          <div
            className={
              inBox ||
              `h-10 flex mt-2 lg:mt-3 pl-3 justify-between border border-border-gray items-center `
            }
          >
            {icon}
            <input
              type={type}
              autoComplete="off"
              placeholder={placeholder}
              value={value}
              name={name}
              onChange={onChange}
              className={
                inputClassName ||
                `bg-white border-0 lg:text-xs text-xs appearance-none w-full h-8 focus:outline-none focus:border-transparent focus:ring-0  `
              }
            />
            {secretState && (
              <div
                className="pl-2 pr-2 ncursor-pointer"
                onClick={changeVisibleState}
              >
                {secret}
              </div>
            )}
            {!secretState && (
              <div
                className="pl-2 pr-2 cursor-pointer"
                onClick={changeVisibleState}
              >
                {secret}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className={
            inBox ||
            `h-10 flex mt-2 pl-3 border border-border-gray items-center`
          }
        >
          {icon}
          <input
            type={type}
            placeholder={placeholder}
            value={value}
            name={name}
            onChange={onChange}
            className={
              inputClassName ||
              `bg-white lg:text-xs text-xs appearance-none w-5/6 h-8 border-0 focus:outline-none focus:border-transparent focus:ring-0`
            }
          />
          {secretState && (
            <div
              className="pl-2 pr-1 cursor-pointer"
              onClick={changeVisibleState}
            >
              {secret}
            </div>
          )}
          {!secretState && (
            <div
              className="pl-2 pr-1 cursor-pointer"
              onClick={changeVisibleState}
            >
              {secret}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default InputField;

import { FC } from "react";
import { Wrapper, Title } from "./PageTitle.styles";
//import GlobalSearch from 'src/modules/GlobalSearch/GlobalSearch'

interface Props {
  title?: string;
  render?: React.ReactNode;
  search?: boolean;
}

const Component: FC<Props> = ({ title, render, search }) => {
  return (
    <Wrapper>
      {!!title && (
        <Title variant="h4" m={1}>
          {title}
        </Title>
      )}
      {render}
    </Wrapper>
  );
};

export default Component;

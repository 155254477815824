import { KeyboardBackspace } from "@mui/icons-material";
//import { useMediaQuery } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "src/components/auth/hooks/UserProvider";
import PersonalInfo from "src/components/EditUser/PersonalInfo";
import Photos from "src/components/EditUser/Photos";
import Preference from "src/components/EditUser/Preference";
import Notifications from "src/components/Notification/notifications";
import { SideBarContext } from "src/context/SidebarProvider";

const UserEditScreen = () => {
  const { user } = useUser();
  //  const [items, setItems] = useState(SideBarItemObj);
  //const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [showNotifications, setShowNotifications] = useState(false);
  //const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsNav } = useContext(SideBarContext);
  // const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const file = URL.createObjectURL(e.target.files?.[0] as any);
  //   setSelectedImage(file);
  // };

  // const handleShowNotification = () => {
  //   setShowNotifications(!showNotifications);
  // };

  // const onAddImage = (e: ChangeEvent<HTMLInputElement>) => {
  //   const file = URL.createObjectURL(e.target.files?.[0] as any);
  //   handleAddImage(file);
  // };

  // const handleAddImage = (image) => {
  //   const photos = [...user.photos, image];
  //   // const updatedImage = { ...user, photos };
  // };

  const selections = [
    { label: "Information", icon: "fa-solid fa-home" },
    { label: "Preferences", icon: "fa-solid fa-container-storage" },
    { label: "Photos", icon: "fa-solid fa-container-storage" },
  ];

  // @ts-ignore
  const page = location?.state?.page || "";

  const [selectedPage, setSelectedPage] = useState(page === "edit" ? 1 : 0);

  useEffect(() => {
    setIsNav(false);
    return () => {
      setIsNav(true);
    };
  }, []);
  return (
    <div className="space-y-5 md:space-y-10">
      <h2
        onClick={() => {
          navigate(-1);
        }}
        className="text-sm w-max flex gap-2 items-center cursor-pointer hover:text-blue-600 font-bold text-[#1C1F37]"
      >
        <KeyboardBackspace fontSize="small" />
        Go Back
      </h2>
      <div className="flex items-center gap-3 md:gap-6">
        {selections?.map((selection, index) => (
          <h2
            onClick={() => {
              setSelectedPage(index);
            }}
            key={index}
            className={` flex cursor-pointer items-center gap-2 border-b-2 pb-2 md:text-base text-xs font-semibold ${
              selectedPage === index
                ? "border-blue-600 text-blue-600"
                : "border-transparent hover:border-gray-400 hover:opacity-70"
            }`}
          >
            {selection?.label}
          </h2>
        ))}
      </div>
      {selectedPage === 0 ? (
        <PersonalInfo />
      ) : selectedPage === 1 ? (
        <Preference />
      ) : (
        <Photos />
      )}
      <Notifications
        visible={showNotifications}
        close={() => setShowNotifications(false)}
        user_id={Number(user?.id)}
      />
    </div>
  );
};

export default UserEditScreen;

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import ASSETS from "src/asset";
import Cancel from "src/assets/cancel.svg";
import Mark from "src/assets/mark.svg";
import SingleButton from "src/components/SingleButton";
import { useCurrency } from "src/context/AmountProvider";
import { GENERICPLANSNG, GENERICPLANSUSD } from "src/helpers/constants";

const MainVerificationSuccess = () => {
  const location = useLocation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    waitForAnimate: false,
    cssEase: "linear",
    swipeToSlide: true,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { premiumMonth, premiumBi, premiumYear, premiumQuater, country } =
    useCurrency();
  const [currency, setCurrency] = useState(
    country?.trim?.() === "nigeria" ? "₦" : "$"
  );
  const [amount, setAmount] = useState(
    country?.trim?.() === "nigeria" ? "200000" : "200"
  );

  const navigate = useNavigate();

  //@ts-ignore
  const payload = location?.state?.payload || null;

  const [singlePlan, setSinglePlan] = useState("annually");

  const handleChoosePlan = (passedplan) => {
    setSinglePlan(passedplan?.plan);
    setAmount(passedplan?.amount);
  };

  const majorPlans =
    country?.trim?.() === "nigeria" ? GENERICPLANSNG : GENERICPLANSUSD;

  useEffect(() => {
    if (!payload) {
      navigate("/pre-verify");
    }
  }, [payload]);

  useEffect(() => {
    if (country?.trim?.() === "nigeria") {
      setCurrency("₦");
      setAmount("200000");
    } else {
      setCurrency("$");
      setAmount("200");
    }
  }, [country]);

  return (
    <div className="bg-white overflow-x-hidden w-full h-screen">
      <div className=" h-full flex-col md:flex-row  overflow-x-hidden flex p-4 gap-5 sm:p-6 lg:p-10 w-full">
        {/* text */}
        <div className="space-y-4 flex flex-col justify-center py-4 w-full md:w-1/2  xl:w-2/5 flex-shrink-0">
          <img
            onClick={() => {
              navigate("/");
            }}
            src={ASSETS?.Logo}
            className=" w-24 cursor-pointer h-10 sm:h-20"
          />
          <p className="text-[#081A51] playfair text-2xl font-bold sm:text-3xl   md:text-4xl lg:text-5xl xl:text-6xl">
            {`Congratulations ${payload?.first_name} ${payload?.last_name} 🎉!`}
          </p>
          <p className="text-[#223333] jarkata_sans pb-3 text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl font-medium ">
            You are a fit for our matchmaking service .Your thoroughness in
            providing all the necessary information has been very useful and we
            are thrilled to get you onboard. Here’s to our favorite love
            story,🥂 yours.
            <br />
            <br /> Select a membership plan to complete your registration on our
            platform
          </p>

          <div className="w-full py-2 rounded-lg flex  flex-wrap items-center gap-2 md:gap-3 xl:gap-4 justify-between ">
            {majorPlans
              ?.slice?.()
              ?.reverse?.()
              ?.map?.((plan) => (
                <div
                  onClick={() => handleChoosePlan(plan)}
                  className={`${
                    singlePlan === plan?.plan
                      ? "bg-primary-bg px-4 py-2 sm:py-3  h-max  rounded-md text-white text-center cursor-pointer lg:px-6 flex-grow"
                      : "text-primary-bg  rounded-md  text-center bg-[#E8EDFB] px-4 py-2 sm:py-3 cursor-pointer lg:px-6 h-max flex-grow"
                  }  `}
                >
                  <p className="font-bold capitalize text-xs  md:text-sm  xl:text-base">
                    {plan?.plan}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* card */}
        <div className="flex-grow md:w-1/2 xl:w-3/5">
          <div className="slider-container  xl:hidden pb-10">
            <Slider {...settings}>
              {/* premium mobible */}
              <div className="py-3">
                <div className=" max-md:p-2 p-4 rounded-xl bg-[#081A51]">
                  <p className="text-white text-2xl font-semibold">
                    Premium Tier
                  </p>
                  <p className="mt-4 text-xs text-white">
                    {singlePlan === "annually"
                      ? `The Planner: You know that finding the right person isn’t a quick fix. You’re in this for the long game, and you’re willing to let it play out over time.
The Busy Bee: Maybe life’s a bit hectic, and you need the space to balance it all while still making room for love. With a year on your side, there’s no rush—you can pace yourself.
The Thoughtful Newbie: Maybe you’re new to the dating scene, and you’re taking this seriously. You want to avoid missteps and give yourself the time to get it right.`
                      : singlePlan === "bi-annually"
                      ? `The Relationship Goal-Setter: You’ve got relationship goals, and now it’s time to put in the time to achieve them. You’re done casually dating, ready for something real, and you're committed to the process.
The Future Planner: Whether it’s starting a family or finding your long-term travel buddy, your sights set on the future—and you’re ready to start building it.`
                      : singlePlan === "quarterly"
                      ? `The Focused Dater: You know what you want, and you’re here to find it, but you’re still open to experimenting with your preferences before diving into something long-term.
Professionals: You're balancing work and life but have set aside a little more time to focus on relationships. This plan offers you the flexibility to find someone at your own pace, without feeling rushed.`
                      : `The Curious Explorer: You’re not looking for perfection right
                away. You want to see what’s out there, and maybe or maybe not
                get a little adventurous with a blind date. The Undecided: Still
                figuring out your “type”? Perfect. This is the no-pressure zone
                where you can test the waters and see what works for you.This
                plan is all about low-stakes exploration.`}
                  </p>
                  <div className="flex items-center mt-8">
                    <p className="text-2xl font-semibold text-white mr-1 max-md:text-[11px]">
                      {currency} {Number(amount)?.toLocaleString?.()}
                    </p>
                    <p className="text-white text-xs"> / {singlePlan}</p>
                  </div>
                  <SingleButton
                    className="border mt-10 py-2 rounded-sm bg-[#FFFFFF] cursor-pointer"
                    text="Get Started Now"
                    inlineStyle="text-center text-[#081A51]"
                    onBtnClick={() =>
                      navigate("/get-started/premium", {
                        state: {
                          currency_code: PlanAmountType(
                            singlePlan,
                            premiumMonth,
                            premiumBi,
                            premiumYear,
                            premiumQuater,
                            1
                          ),
                          plan_cost: PlanAmountType(
                            singlePlan,
                            premiumMonth,
                            premiumBi,
                            premiumYear,
                            premiumQuater,
                            0
                          ),
                          plan_duration: singlePlan,
                          payload,
                        },
                      })
                    }
                  />
                  {singlePlan === "annually" && (
                    <div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="mark" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Full Handler Support: You’re not doing this alone. Our
                          handlers will be your match-making coaches, guiding
                          you toward the most compatible people and helping you
                          refine your approach. They can go extra lengths to
                          find you a compatible match that may not be part of
                          our typical process.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Therapists on Deck: Sometimes love requires a little
                          soul-searching. Whether you need to work through past
                          baggage or just want someone to talk to along the way,
                          our therapists are here for you. A plan can be drafted
                          on how to navigate these challenges and when you find
                          THE one, we are happy to give some guidance to you
                          both
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Handler Suggestions: Get sent personalized matches
                          based on your set preferences. We’re talking
                          tailor-made connections that are aligned with your
                          preferences, personality, and long-term goals. Whether
                          it’s shared interests, life plans, or even values,
                          we’ve got your back in finding the person who checks
                          all your boxes.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Discover Matches:There is a whole song about finding
                          love in a hopeless place for a reason. While you're
                          laser-focused on finding "the one," sometimes love
                          surprises you. With Discover Suggestions, you'll be
                          introduced to profiles that might not meet every
                          preference on your list but still have the potential
                          to spark something special. Think of it as a little
                          nudge from the universe—or us—to keep your heart open
                          to unexpected connections.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Blind Dates: Skip the small talk. Busy schedule? No
                          problem. With Blind Dates, we cut through the endless
                          back-and-forth texting so you can jump straight into a
                          real connection. On these 6-minute video calls, you'll
                          get to pick up on those subtle verbal cues that just
                          don't come through in chat. It’s fast, efficient, and
                          way more insightful than days of messaging. On this
                          pla, we are in it together to win it.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          No Stone Unturned: With a full year, you have time to
                          explore every avenue. Blind dates, handler
                          suggestions, personalized matches—you’ll get it all,
                          with plenty of time to see what works best for you.
                        </p>
                      </div>
                    </div>
                  )}
                  {singlePlan === "bi-annually" && (
                    <div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="mark" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Handler Suggestions: Personalized matches based on
                          your set preferences, because you know what you want.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Discover Matches:These suggestions aren't handpicked
                          with laser precision to your preferences but, what is
                          the harm in seeing what the general dating pool looks
                          like to help manage your expectations?
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Blind Dates: You will get set up for blind dates which
                          you must endeavor to attend if you accept the
                          invitation. This is a quick process to showcase the
                          personalities behind the keyboards.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Handler Support: Access to your handler to help you on
                          your journey. Whether it is technical support, a quick
                          venting session or just updates on your progress with
                          the processes, your handler is available to answer all
                          enquiries promptly.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          No support from our Therapists: Therapists? Not for
                          you—at least, not right now. Either you’ve already
                          done the work, or you’re in a good place and focused
                          on finding the right person. Six months is all about
                          building that connection, not diving deep into
                          healing. And hey, if you ever need it down the line,
                          we’ll be here, but for now—you’ve got this!
                        </p>
                      </div>
                    </div>
                  )}
                  {singlePlan === "quarterly" && (
                    <div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="mark" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Handler Suggestions: Personalized matches based on
                          your set preferences, because you’re here with intent.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Discover Matches:These suggestions aren't handpicked
                          with laser precision to your preferences but, you are
                          not fussed because a pleasant surprise is welcome. In
                          fact, that is why you are here.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Blind Dates (With a Twist!): Depending on
                          availability, you might get set up for a blind date.
                          But here’s the deal— only if members of our community
                          who are more committed to the process request one with
                          you.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Enhanced Support: More access to your handler to help
                          you on your journey.
                        </p>
                      </div>

                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          No support from our Therapists: Therapists? Nah, it is
                          not that deep. You're more about meeting new people
                          than unpacking deep emotional baggage. No need to
                          overthink it—you’re just here to enjoy the ride and
                          see where things go. When you're ready for that deep
                          dive, we’ll be here for you
                        </p>
                      </div>
                    </div>
                  )}
                  {singlePlan === "monthly" && (
                    <div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="mark" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          We’ve got some delightful matches lined up for you in
                          our Discover section. These aren't handpicked with
                          laser precision for your preferences but, hey,
                          sometimes love surprises you, right? Explore people
                          outside your usual type—you never know!
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Depending on availability, you might get set up for a
                          blind date. But here’s the deal— only if members of
                          our community who are more committed to the process
                          request one with you
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Handler Suggestions? Not this time. But that’s okay,
                          you’re just getting started. Think of it as if you
                          need an outfit but you are not sure if you want a blue
                          one or a black one, no need to customize what you are
                          not sure of!
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Limited Handler Support: You won't be flying
                          completely solo—our handlers are here to assist, but
                          don't expect the full VIP treatment. Just enough to
                          keep you steady on your feet while you explore.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          No support from our Therapists: Talking to therapists
                          means going deep, unveiling and facing fears head on
                          to heal. You are not ready for that and it is okay. We
                          are ready when you are.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* free  mobile*/}
              <div className="py-3">
                <div className=" max-md:p-2 p-4 rounded-xl border border-[#98A2B3]">
                  <p className="text-[#081A51] text-2xl font-semibold">
                    Free Tier
                  </p>
                  <p className="mt-4 text-[#223333]">
                    Ideal for individuals who need a quick access to basic
                    features with an idea of exploring our platofrm
                  </p>
                  <div className="flex items-center mt-8">
                    <p className="text-2xl font-semibold text-[#081A51] mr-1 max-md:text-[11px]">
                      {currency} 0
                    </p>
                    <p className="text-[#223333] text-xs"> / {singlePlan}</p>
                  </div>
                  <SingleButton
                    className="border border-[#081A51] mt-10 py-2 rounded-sm cursor-pointer"
                    text="Get Started Now"
                    inlineStyle="text-center text-[#081A51]"
                    //@ts-ignore
                    onBtnClick={() => {
                      navigate("/get-started/basic", {
                        state: {
                          payload,
                        },
                      });
                    }}
                  />
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="mark" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      View profiles in our private matchmaking dating pool, get
                      new random suggestions in Discover.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="mark" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      Receive the Matchmaker’s Monthly Digest, a niche
                      publication sent to your mail.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      No access to matches with more precision in Handler’s
                      suggestions.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      No personalized matching by handler
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      No matching with private subscribers.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      Limited handler support.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      No access to system scheduled Blind Dates.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      No access to personalized Blind dates by Handler.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      No after match support.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      No scheduled therapy sessions.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      No access to therapy reports.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      No feedback on potential matches.
                    </p>
                  </div>
                  {/* <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-[#223333] text-sm max-md:text-[11px]">
                    Handler unlocked for all your questions, complaints and for
                    direct referrals
                  </p>
                </div> */}
                </div>
              </div>
            </Slider>
          </div>
          <div className="p-4 lg:grid hidden grid-cols-2 gap-5">
            <div className=" max-md:p-2 p-4 rounded-xl bg-[#081A51]">
              <p className="text-white text-2xl font-semibold">Premium Tier</p>
              <p className="mt-4 text-white text-xs">
                {singlePlan === "annually"
                  ? `The Planner: You know that finding the right person isn’t a quick fix. You’re in this for the long game, and you’re willing to let it play out over time.
The Busy Bee: Maybe life’s a bit hectic, and you need the space to balance it all while still making room for love. With a year on your side, there’s no rush—you can pace yourself.
The Thoughtful Newbie: Maybe you’re new to the dating scene, and you’re taking this seriously. You want to avoid missteps and give yourself the time to get it right.`
                  : singlePlan === "bi-annually"
                  ? `The Relationship Goal-Setter: You’ve got relationship goals, and now it’s time to put in the time to achieve them. You’re done casually dating, ready for something real, and you're committed to the process.
The Future Planner: Whether it’s starting a family or finding your long-term travel buddy, your sights set on the future—and you’re ready to start building it.`
                  : singlePlan === "quarterly"
                  ? `The Focused Dater: You know what you want, and you’re here to find it, but you’re still open to experimenting with your preferences before diving into something long-term.
Professionals: You're balancing work and life but have set aside a little more time to focus on relationships. This plan offers you the flexibility to find someone at your own pace, without feeling rushed.`
                  : `The Curious Explorer: You’re not looking for perfection right
                away. You want to see what’s out there, and maybe or maybe not
                get a little adventurous with a blind date. The Undecided: Still
                figuring out your “type”? Perfect. This is the no-pressure zone
                where you can test the waters and see what works for you.This
                plan is all about low-stakes exploration.`}
              </p>
              <div className="flex items-center mt-8">
                <p className="text-2xl font-semibold text-white mr-1 max-md:text-[11px]">
                  {currency} {Number(amount)?.toLocaleString?.()}
                </p>
                <p className="text-white text-xs"> / {singlePlan}</p>
              </div>
              <SingleButton
                className="border mt-10 py-2 rounded-sm bg-[#FFFFFF] cursor-pointer"
                text="Get Started Now"
                inlineStyle="text-center text-[#081A51]"
                onBtnClick={() => {
                  navigate("/get-started/premium", {
                    state: {
                      currency_code: PlanAmountType(
                        singlePlan,
                        premiumMonth,
                        premiumBi,
                        premiumYear,
                        premiumQuater,
                        1
                      ),
                      plan_cost: PlanAmountType(
                        singlePlan,
                        premiumMonth,
                        premiumBi,
                        premiumYear,
                        premiumQuater,
                        0
                      ),
                      plan_duration: singlePlan,
                      payload,
                    },
                  });
                }}
              />
              {singlePlan === "annually" && (
                <div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="mark" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Full Handler Support: You’re not doing this alone. Our
                      handlers will be your match-making coaches, guiding you
                      toward the most compatible people and helping you refine
                      your approach. They can go extra lengths to find you a
                      compatible match that may not be part of our typical
                      process.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Therapists on Deck: Sometimes love requires a little
                      soul-searching. Whether you need to work through past
                      baggage or just want someone to talk to along the way, our
                      therapists are here for you. A plan can be drafted on how
                      to navigate these challenges and when you find THE one, we
                      are happy to give some guidance to you both
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Handler Suggestions: Get sent personalized matches based
                      on your set preferences. We’re talking tailor-made
                      connections that are aligned with your preferences,
                      personality, and long-term goals. Whether it’s shared
                      interests, life plans, or even values, we’ve got your back
                      in finding the person who checks all your boxes.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Discover Matches:There is a whole song about finding love
                      in a hopeless place for a reason. While you're
                      laser-focused on finding "the one," sometimes love
                      surprises you. With Discover Suggestions, you'll be
                      introduced to profiles that might not meet every
                      preference on your list but still have the potential to
                      spark something special. Think of it as a little nudge
                      from the universe—or us—to keep your heart open to
                      unexpected connections.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Blind Dates: Skip the small talk. Busy schedule? No
                      problem. With Blind Dates, we cut through the endless
                      back-and-forth texting so you can jump straight into a
                      real connection. On these 6-minute video calls, you'll get
                      to pick up on those subtle verbal cues that just don't
                      come through in chat. It’s fast, efficient, and way more
                      insightful than days of messaging. On this pla, we are in
                      it together to win it.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      No Stone Unturned: With a full year, you have time to
                      explore every avenue. Blind dates, handler suggestions,
                      personalized matches—you’ll get it all, with plenty of
                      time to see what works best for you.
                    </p>
                  </div>
                </div>
              )}
              {singlePlan === "bi-annually" && (
                <div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="mark" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Handler Suggestions: Personalized matches based on your
                      set preferences, because you know what you want.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Discover Matches:These suggestions aren't handpicked with
                      laser precision to your preferences but, what is the harm
                      in seeing what the general dating pool looks like to help
                      manage your expectations?
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Blind Dates: You will get set up for blind dates which you
                      must endeavor to attend if you accept the invitation. This
                      is a quick process to showcase the personalities behind
                      the keyboards.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Handler Support: Access to your handler to help you on
                      your journey. Whether it is technical support, a quick
                      venting session or just updates on your progress with the
                      processes, your handler is available to answer all
                      enquiries promptly.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      No support from our Therapists: Therapists? Not for you—at
                      least, not right now. Either you’ve already done the work,
                      or you’re in a good place and focused on finding the right
                      person. Six months is all about building that connection,
                      not diving deep into healing. And hey, if you ever need it
                      down the line, we’ll be here, but for now—you’ve got this!
                    </p>
                  </div>
                </div>
              )}
              {singlePlan === "quarterly" && (
                <div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="mark" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Handler Suggestions: Personalized matches based on your
                      set preferences, because you’re here with intent.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Discover Matches:These suggestions aren't handpicked with
                      laser precision to your preferences but, you are not
                      fussed because a pleasant surprise is welcome. In fact,
                      that is why you are here.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Blind Dates (With a Twist!): Depending on availability,
                      you might get set up for a blind date. But here’s the
                      deal— only if members of our community who are more
                      committed to the process request one with you.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Enhanced Support: More access to your handler to help you
                      on your journey.
                    </p>
                  </div>

                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      No support from our Therapists: Therapists? Nah, it is not
                      that deep. You're more about meeting new people than
                      unpacking deep emotional baggage. No need to overthink
                      it—you’re just here to enjoy the ride and see where things
                      go. When you're ready for that deep dive, we’ll be here
                      for you
                    </p>
                  </div>
                </div>
              )}
              {singlePlan === "monthly" && (
                <div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="mark" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      We’ve got some delightful matches lined up for you in our
                      Discover section. These aren't handpicked with laser
                      precision for your preferences but, hey, sometimes love
                      surprises you, right? Explore people outside your usual
                      type—you never know!
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Depending on availability, you might get set up for a
                      blind date. But here’s the deal— only if members of our
                      community who are more committed to the process request
                      one with you
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Handler Suggestions? Not this time. But that’s okay,
                      you’re just getting started. Think of it as if you need an
                      outfit but you are not sure if you want a blue one or a
                      black one, no need to customize what you are not sure of!
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Limited Handler Support: You won't be flying completely
                      solo—our handlers are here to assist, but don't expect the
                      full VIP treatment. Just enough to keep you steady on your
                      feet while you explore.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      No support from our Therapists: Talking to therapists
                      means going deep, unveiling and facing fears head on to
                      heal. You are not ready for that and it is okay. We are
                      ready when you are.
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className=" max-md:p-2 p-4 rounded-xl border border-[#98A2B3]">
              <p className="text-[#081A51] text-2xl font-semibold">Free Tier</p>
              <p className="mt-4 text-[#223333]">
                Ideal for individuals who need a quick access to basic features
                with an idea of exploring our platofrm
              </p>
              <div className="flex items-center mt-8">
                <p className="text-2xl font-semibold text-[#081A51] mr-1 max-md:text-[11px]">
                  {currency} 0
                </p>
                <p className="text-[#223333] text-xs"> / {singlePlan}</p>
              </div>
              <SingleButton
                className="border border-[#081A51] mt-10 py-2 rounded-sm cursor-pointer"
                text="Get Started Now"
                inlineStyle="text-center text-[#081A51]"
                onBtnClick={() =>
                  navigate("/get-started/basic", {
                    state: {
                      payload,
                    },
                  })
                }
              />
              <div className="flex mt-10">
                <img src={Mark} className="h-6 mr-4" alt="mark" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  View profiles in our private matchmaking dating pool, get new
                  random suggestions in Discover.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Mark} className="h-6 mr-4" alt="mark" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  Receive the Matchmaker’s Monthly Digest, a niche publication
                  sent to your mail.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No access to matches with more precision in Handler’s
                  suggestions.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No personalized matching by handler.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No matching with private subscribers.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  Limited handler support.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No access to system scheduled Blind Dates.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No access to personalized Blind dates by Handler.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No after match support.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No scheduled therapy sessions.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No access to therapy reports.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No feedback on potential matches.
                </p>
              </div>
              {/* <div className="flex mt-10">
              <img src={Cancel} className="h-6 mr-4" alt="cancel" />
              <p className="text-[#223333] text-sm max-md:text-[11px]">
                Handler unlocked for all your questions, complaints and for
                direct referrals
              </p>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainVerificationSuccess;

const PlanAmountType = (
  plan: string,
  month: string,
  bi_annual: string,
  annual: string,
  quarter: string,
  index: number
) => {
  switch (plan) {
    case "bi-annually":
      return `${bi_annual.split("-")[index]}`;

    case "annually":
      return `${annual.split("-")[index]}`;

    case "monthly":
      return `${month.split("-")[index]}`;

    case "quarterly":
      return `${quarter.split("-")[index]}`;

    default:
      return ``;
  }
};

import parsePhoneNumber, {
  CountryCode,
  isValidPhoneNumber as _isValidPhoneNumber,
} from "libphonenumber-js";
import { v4 as uuidv4 } from "uuid";

export enum Identifier_Type {
  Phone = "phone",
  Email = "email",
}

export const formatPhoneNumber = (
  phoneNumber: string,
  countryCode?: CountryCode | undefined,
  countryCodeInclusive?: Boolean
) => {
  if (!phoneNumber) return "";
  const targetNumber = parsePhoneNumber(phoneNumber, countryCode || "US");
  if (targetNumber?.isValid()) {
    if (countryCodeInclusive) {
      return `${targetNumber.countryCallingCode} ${targetNumber.format(
        "NATIONAL"
      )}`; // +1 (213) 373-4253
    } else {
      return ` ${targetNumber.format("NATIONAL")}`; // (213) 373-4253
    }
  } else {
    return phoneNumber;
  }
};

export const isValidPhoneNumber = (
  phoneNumber: string,
  countryCode: CountryCode | undefined = "US"
) => {
  if (!phoneNumber) return false;
  return _isValidPhoneNumber(phoneNumber, countryCode);
};

export const getCountryCode = (phoneNumber: string) => {
  const targetNumber = parsePhoneNumber(phoneNumber);
  return targetNumber?.country || "";
};

export const safeNumber = (value?: number | String) => {
  const _number = Number(value);
  if (!isNaN(_number)) {
    return _number;
  } else {
    return 0;
  }
};

export const formatAmount = (number?: number | string | null) => {
  if (number) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    return formatter.format(safeNumber(number)); /* $2,500.00 */
  }
  return "$0";
};

// return string if the value is string otherwise empty string (useful when string/React.element are expected vals)
export const safeString = (value) => {
  if (typeof value === "string") return value;
  return "";
};

export const formatFileNameForUrl = (fileName: string) => {
  if (fileName) {
    return fileName.replace(/\s/g, "_");
  }
  return "";
};

export const randomId = () => Math.floor(Math.random() * 101);

export const generateUuid = () => uuidv4();

export const convertTimeToDateTime = (strTime) => {
  if (!strTime) return null;
  const formattedTime = convertTo24HrsTime(strTime);
  const [hours, minutes] = formattedTime?.split(":") || [];
  const now = new Date();
  const newDateTime = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    Number(hours),
    Number(minutes)
  );
  return newDateTime;
};

export const convertTo24HrsTime = (strTime) => {
  if (!strTime) return null;
  const [time, modifier] = strTime.split(" ");
  let [hours, minutes, seconds] = time.split(":");

  if (hours === "12") {
    hours = "0";
  }

  if (minutes === "00") {
    minutes = "0";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}:${seconds}`;
};

interface LinkGeneratorProp {
  url?: string;
  sub_domain?: string;
  ids?: string[];
}
export const integrationLinkGenerator = (payload: LinkGeneratorProp) => {
  const { url = "", sub_domain = "", ids = [""] } = payload;
  let result = url;
  if (sub_domain) {
    result = result.replace("sub_domain", sub_domain);
  }
  for (let index = 0; index < ids.length; index++) {
    const element = ids[index];
    result = result.replace("pms_id", element);
  }
  return result;
};

import { gql } from "@apollo/client";

export const GET_MEDIA_FILES_BY_IDS = gql`
  query MediaEntriesByIds($ids: [Int]!) {
    mediaEntriesByIds(ids: $ids) {
      id
      title
      url
      type
    }
  }
`;

export const GET_USER_CONVERSATIONS = gql`
  query ConversationsByUserId($userId: Int!) {
    conversationsByUserId(user_id: $userId) {
      messages {
        body
        created_at
        from {
          id
          first_name
          last_online
          is_online
          is_available
          last_name
          # user_status
          profile_image_url
          qualification
        }
        to {
          id
          first_name
          last_online
          is_online
          is_available
          last_name
          # user_status
          profile_image_url
          qualification
        }
        # media {
        #   id
        #   url
        #   created_at
        #   description
        # }
        attachments
        is_inbound
      }
      users {
        id
        first_name
        is_online
        is_available
        last_name
        #user_status
        profile_image_url
        last_online
        qualification
      }
      id
      lastMessageSentAt
      created_at
    }
  }
`;

export const HANDLER_LOGIN = gql`
  mutation Mutation($payload: CreateHandlerPayload) {
    handlerLogin(payload: $payload) {
      user_name
      profile_image_url
      id
      handler_type
      email
      deleted_at
      deleted
      created_at
    }
  }
`;

export const SEND_EMAIL = gql`
  mutation SendEmail($body: String!, $email: String!, $name: String!) {
    sendEmail(body: $body, email: $email, name: $name)
  }
`;
export const UserNotifications = gql`
  query NotificationsByUser($userId: Int!) {
    notificationsByUser(user_id: $userId) {
      id
      description
      created_at
      action_url
      deleted_at
      deleted
      destination_id
      is_archived
      is_read
      title
      type
      updated_at
      metadata
    }
  }
`;

export const SEND_USER_MATCH = gql`
  mutation CreateUserMatch($payload: CreateUserMatchPayload) {
    createUserMatch(payload: $payload) {
      id
      created_at
    }
  }
`;
export const GET_HANDLER_CONVERSATIONS = gql`
  query HandlerConversations($handlerId: Int) {
    handlerConversations(handler_id: $handlerId) {
      id
      lastMessageSentAt
      un_actioned_at
      unreadMessageCount
      updated_at
      user {
        id
        last_name
        first_name
        profile_image_url
      }
      handler_messages {
        id
        message_read
        message_read_at
        message_sender
        metadata
        status
        subject
        updated_at
        deleted_at
        deleted
        created_at
        body
        attachments
      }
      deleted_at
      deleted
      created_at
    }
  }
`;
export const GET_USER_HANDLER_CONVERSATION = gql`
  query Query($userId: Int!) {
    handlerConversationsByUserId(user_id: $userId) {
      actioned
      archived
      created_at
      deleted
      deleted_at
      id
      lastMessageSentAt
      un_actioned_at
      updated_at
      unreadMessageCount
      handler_messages {
        id
        media_url
        message_read
        message_read_at
        message_sender
        metadata
        status
        subject
        body
        created_at
        deleted
      }
    }
  }
`;

export const GET_USERS = gql`
  query GetUsers {
    users {
      id
      first_name
      last_online
      last_name
      alais
      email
      phone
      about_me
      age
      body_type
      country_code
      created_at
      deleted
      deleted_at
      ethnicity
      gender
      genotype
      hobbies
      id
      location_city
      location_country
      location_state
      media_ids
      occupation
      origin_country
      origin_state
      preferred_age
      preferred_country
      preferred_gender
      preferred_genotype
      preferred_religion
      preferred_status
      qualification
      religion
      status
      updated_at
      created_at
      Invoice {
        total
        quantity
        payment {
          amount
          title
        }
      }
      media {
        id
        title
        type
        url
      }
      subscription {
        cost
        id
        name
        priority
      }
      user_matched {
        id
        deleted
        updated_at
        user_match_status
        user_matched_status
        user_match {
          id
          first_name
          last_name
          last_online
        }
        created_at
      }
      user_match {
        id
        deleted
        updated_at
        user_match_status
        user_matched_status
        user_matched {
          id
          first_name
          last_name
          last_online
        }
        created_at
      }
      user_friend_matched {
        id
        deleted
        updated_at
        user_friend_match_status
        user_friend_matched_status
        user_friend_match {
          id
          first_name
          last_name
        }
        created_at
      }
      user_friend_match {
        id
        deleted
        updated_at
        user_friend_match_status
        user_friend_matched_status
        user_friend_matched {
          id
          first_name
          last_name
          last_online
        }
        created_at
      }
    }
  }
`;

export const GET_USER = gql`
  query User($userId: Int!) {
    user(id: $userId) {
      subscription {
        name
        id
        cost
      }
      created_notifications {
        action_url
        created_at
        deleted
        deleted_at
        description
        destination_id
        id
        is_archived
        is_read
        title
        type
        updated_at
        metadata
      }
      Invoice {
        id
        payment {
          amount
          created_at
          title
          id
        }
        quantity
        status
        total
        updated_at
        created_at
      }
      id
      about_me
      email
      email_is_verified
      first_name
      gender
      genotype
      last_online
      is_available
      last_name
      # interests
      location_city
      location_country
      location_state
      first_timer
      media_ids
      handler {
        user_name
        id
        profile_image_url
      }
      media {
        id
        url
        title
        description
        is_avatar
        deleted
      }
      occupation
      preferred_age
      preferred_country
      preferred_gender
      preferred_genotype
      preferred_religion
      preferred_status
      profile_image_url
      plan
      qualification
      religion
      updated_at
      age
      alais
      body_type
      country_code
      created_at
      deleted
      hobbies
      is_online
      mode
      plan
      paymentDate
      amount
      blind_date_first_timer
      paystackSubscription {
        active
        created_at
        deleted
        id
        plan
        exp_year
        exp_month
        last4
        card_type
        account_name
        channel
      }
      userReport {
        id
        message
        subject
        created_at
      }
      userHandlerRating {
        created_at
        id
        rating
        updated_at
      }
      handler {
        user_name
        id
      }
      handler_conversation {
        id
      }
      status
      #  user_status
      phone
      password
      origin_state
      origin_country
      ethnicity
      userStrike {
        id
        reason
        updated_at
        deleted_at
        deleted
        created_at
      }
      handler_rating
    }
  }
`;
export const HANDLER_GET_USER = gql`
  query User($userId: Int!) {
    user(id: $userId) {
      id
      first_name
      last_name
      alais
      email
      phone
      about_me
      last_online
      created_notifications {
        action_url
        created_at
        deleted
        deleted_at
        description
        destination_id
        id
        is_archived
        is_read
        title
        type
        updated_at
        metadata
      }
      age
      body_type
      country_code
      created_at
      deleted
      deleted_at
      ethnicity
      gender
      genotype
      hobbies
      subscription {
        name
        id
        cost
      }
      Invoice {
        id
        payment {
          amount
          created_at
          title
          id
        }
        quantity
        status
        total
        updated_at
        created_at
      }
      location_city
      location_country
      location_state
      media_ids
      occupation
      origin_country
      origin_state
      preferred_age
      preferred_country
      preferred_gender
      preferred_genotype
      preferred_religion
      preferred_status
      qualification
      religion
      status
      plan
      updated_at
      created_at
      media {
        id
        title
        type
        url
        is_avatar
      }
      subscription {
        cost
        id
        name
        priority
      }
      user_matched {
        id
        deleted
        updated_at
        user_match_status
        user_matched_status
        user_match {
          id
          first_name
          last_name
          last_online
          profile_image_url
          handler {
            user_name
            email
          }
        }
        created_at
      }
      user_match {
        id
        deleted
        updated_at
        user_match_status
        user_matched_status
        user_matched {
          id
          first_name
          last_name
          last_online
          profile_image_url
          handler {
            user_name
            email
          }
        }
        created_at
        suggested_by
      }
      is_available
      is_online
      userBlackList {
        approved
        created_at
        deleted_at
        deleted
        id
        reason
        updated_at
      }
      # user_status
      userStrike {
        created_at
        deleted
        deleted_at
        id
        reason
        updated_at
      }
      userReport {
        created_at
        deleted
        deleted_at
        id
        subject
        message
        updated_at
      }
    }
  }
`;

export const GET_USERS_TO_SUGGEST = gql`
  query Users($page: Int!, $pageSize: Int!) {
    users(page: $page, pageSize: $pageSize) {
      meta {
        currentPage
        pageSize
        totalCount
        totalPages
      }
      data {
        about_me
        age
        alais
        body_type
        country_code
        created_at
        email
        first_name
        gender
        genotype
        hobbies
        id
        location_city
        location_country
        location_state
        last_name
        occupation
        media {
          url
        }
        plan
        phone
        religion
        status
        handler {
          user_name
        }
        subscription {
          id
          name
          cost
        }
      }
    }
  }
`;
export const GET_BLIND_DATE_OF_HANDLER = gql`
  query GetBlindDateWhere($payload: FilterBlindDatePayload) {
    getBlindDateWhere(payload: $payload) {
      id
      user_match {
        id
        user_matched {
          id
          first_name
          last_name
          gender
          religion
          location_country
          location_state
          location_city
          about_me
          status
          genotype
          age
          occupation
          email
          profile_image_url
        }
        user_match {
          id
          first_name
          last_name
          gender
          religion
          location_country
          location_state
          location_city
          about_me
          status
          genotype
          age
          occupation
          email
          profile_image_url
        }
      }
      meeting_held
      match_cancel
      match_upcoming
      match_accept
      match_reject
      matched_cancel
      matched_upcoming
      matched_accept
      matched_reject
      meeting_link
      user_match_theme
      user_matched_theme
      user_match_meeting_time
      user_matched_meeting_time
      suggested_by
      deleted
      deleted_at
      updated_at
      created_at
    }
  }
`;

export const PAYSTACK_SUB_FILTER = gql`
  query PaystackSub($active: Boolean, $userId: Int) {
    paystackSubsWhere(payload: { active: $active, user_id: $userId }) {
      id
      plan
      paystack_subscription_id
      token
      updated_at
      created_at
      active
      deleted
      deleted_at
      subscription_code
    }
  }
`;
export const GET_ALL_THERAPIST = gql`
  query AllTherapist($page: Int!, $pageSize: Int!) {
    allTherapist(page: $page, pageSize: $pageSize) {
      data {
        imageUrl
        id
        name
        personaSummary
        specialties
      }
    }
  }
`;
export const GET_SINGLE_THERAPIST = gql`
  query Therapist($therapistId: Int!) {
    therapist(id: $therapistId) {
      id
      name
      personaSummary
      philosophy
      tagline
      educationAndExpertise
      specialties
      whatToExpect
      imageUrl
      created_at
      updated_at
      availability {
        id
        startTime
        endTime
        isBooked
        created_at
        updated_at
      }
      bookings {
        id
        meetingLink
        meetingId
        meetingHeld
        meetingDeleted
        status
        sessionDate
        created_at
        updated_at
        deleted
        deleted_at
        user {
          first_name
          last_name
          id
          profile_image_url
        }
        user_review_message
        user_review_subject
        therapist_review
        availability {
          startTime
          endTime
        }
        therapistStatus
      }
    }
  }
`;
export const GET_USER_THERAPIST_BOOKING = gql`
  query GetUserBooking($page: Int!, $pageSize: Int!, $userId: Int!) {
    getUserBooking(page: $page, pageSize: $pageSize, userId: $userId) {
      data {
        id
        meetingLink
        meetingId
        meetingHeld
        meetingDeleted
        status
        sessionDate
        created_at
        updated_at
        availability {
          id
          startTime
          endTime
          isBooked
          created_at
          updated_at
        }
        therapist {
          id
          name
           imageUrl
        }
        user_review_message
        user_review_subject
        therapist_review
        therapistStatus
      }
    }
  }
`;

import React from "react";
import {
  BackIconStyle,
  BackIconWrapper,
  EmptySearchListItemStyle,
  SearchBoxStylesDiv,
  SearchIconStyle,
  SearchInputBaseStyles,
  SearchInputStylesDiv,
  SearchListItemStyle,
  SearchListStylesDiv,
} from "./SearchDropDown.style";

interface SearchDropDownProps {
  list: any;
  cuurent_user_id: number;
  handleSelected: any;
  placeholder?: string;
  emptyText?: string;
  onBack?: any;
}
const SearchDropDown = ({
  list,
  cuurent_user_id,
  handleSelected,
  placeholder = "Search match user",
  emptyText = "No Admin found",
  onBack = null,
}: SearchDropDownProps) => {
  const [searchField, setSearchField] = React.useState<string>("");
  const handleInputChange = (e) => {
    setSearchField(e.target.value);
  };

  const filteredValues = list
    ?.filter((li) => li.id !== cuurent_user_id)
    ?.map((ro) => ({ id: ro.id, name: `${ro.first_name} ${ro.last_name}` }))
    ?.filter((row) => {
      return row.name.toLowerCase().includes(searchField.toLowerCase());
    });

  const onSelecting = (value) => {
    handleSelected(value);
  };

  return (
    <>
      <SearchBoxStylesDiv>
        {onBack && (
          <BackIconWrapper onClick={onBack}>
            <BackIconStyle /> Back
          </BackIconWrapper>
        )}
        <SearchInputStylesDiv>
          <SearchIconStyle fontSize="small" />

          <SearchInputBaseStyles
            multiline
            placeholder={placeholder}
            onChange={handleInputChange}
          />
        </SearchInputStylesDiv>
        <SearchListStylesDiv>
          {filteredValues.length ? (
            filteredValues?.map((row) => (
              <SearchListItemStyle
                onClick={() => {
                  onSelecting(Number(row.id));
                }}
                key={row.id}
                noWrap
              >
                {/* {row && <SourceStylesWrapper>{row}: </SourceStylesWrapper>} */}
                {row.name}
              </SearchListItemStyle>
            ))
          ) : (
            <EmptySearchListItemStyle>{emptyText}</EmptySearchListItemStyle>
          )}
        </SearchListStylesDiv>
      </SearchBoxStylesDiv>
    </>
  );
};

export default SearchDropDown;

import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  Operation,
  split,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";

import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { env } from "src/helpers";

const endpoint = env("graphql_api_endpoint") as string;
const url = env("graphql_api_endpoint_ws") as string;

const wsLink = new GraphQLWsLink(
  createClient({
    url, //: `${env("graphql_api_endpoint_ws")}`,
  })
);
const authLink = setContext(async (_, { headers }) => {
  // Docs: https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#retrieve-current-authenticated-user
  try {
    // let parsed_token: any = {};
    const parsed_token = localStorage.getItem("userToken");

    // console.log({ parsed_token });
    // const token = await AsyncStorage.getItem("USER");
    // const parsed_token = token ? JSON.parse(token) : null;

    return {
      headers: {
        ...headers,
        email: "parsed_token?.email",
        // Authorization: parsed_token ? `Bearer ${parsed_token}` : "",
      },
    };
  } catch (error) {
    // if exception then redirect to login
    // window.location.href = authHostedUi
    console.log("not authed");
  }
});

// query and mutation setup
const httpLink = createUploadLink({
  uri: (operation: Operation) => {
    // console.log({ operation });
    return `${endpoint}?operationName=${operation.operationName}`;
  },
  // credentials: "include",
  headers: {
    "keep-alive": "true",
    "Apollo-Require-Preflight": "true",
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  ApolloLink.from([httpLink])
  // ApolloLink.from([requestIdLink, errorLoggingLink, httpLink])
);

const client = new ApolloClient({
  link: ApolloLink.from([authLink, splitLink]),
  cache: new InMemoryCache(),
});

export default client;

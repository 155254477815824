import moment from "moment";
import { useEffect, useState } from "react";
import ASSETS from "src/asset";
import { formatDateTime, sortMessages } from "src/helpers/dateTime";

// interface Message {
//   body: string;
//   created_at: string;
//   deleted: boolean;
//   id: string;
//   media_url: string | null;
//   message_read: boolean;
//   message_read_at: string | null;
//   message_sender: string;
//   metadata: any | null;
//   status: any | null;
//   subject: string;
//   __typename: string;
// }

// interface Handler {
//   profile_image_url: string;
//   user_name:string;
// }

// interface UserMessage {
//   messageView: Message[];
//   handler: Handler;
//   user_profile_url: string;
// }

const UserMessage = ({ messageView, handler, user_profile_url }) => {
  const sortedMessages = sortMessages(messageView);
  return (
    <>
      {sortedMessages?.map((msg) => (
        <div
          className={`w-full my-1.5 bg-white ${
            msg?.message_sender !== "HANDLER"
              ? null
              : "border-l-4 border-[#017EFA]"
          } shadow-md rounded-lg`}
          key={msg.id}
        >
          {msg?.message_sender !== "HANDLER" ? (
            <>
              <div className="w-full flex justify-between  border-b-2 items-center">
                <div className="flex p-3 gap-2 items-center">
                  {
                    <img
                      src={user_profile_url ?? ASSETS?.Avi}
                      className="h-8 w-8 flex-shrink-0 rounded-full"
                      alt="img"
                    />
                  }
                  <p className="text-sm font-semibold">Me</p>
                </div>
                <p className=" px-3 pb-2 sm:p-3 lg:text-xs text-[9px]">
                  {moment(msg?.created_at).format("MMM DD, YYYY, hh:mm a")}
                </p>
              </div>
              <div className=" w-full p-3">
                <p className=" text-xs lg:text-sm text-gray-500 capitalize font-bold">
                  {msg?.subject ?? ""}
                </p>
                <p className="text-xs leading-8">{msg?.body ?? ""}</p>
              </div>
            </>
          ) : (
            <>
              <div className="w-full flex justify-between flex-col sm:flex-row items-start sm:items-center border-b-2">
                <div className="flex p-3 gap-2 justify-center items-center">
                  {
                    <img
                      src={handler?.profile_image_url ?? ASSETS?.Avi}
                      className="h-[24px] w-[24px text-xs"
                      alt="img"
                    />
                  }
                  <p className="text-sm font-semibold">{handler?.user_name}</p>
                  <div className=" ml-3 border border-[#017EFA] bg-blue-100 rounded-full px-4 py-1 flex justify-center items-center text-[#017EFA] text-xs">
                    <p className="text-[9px]">Handler</p>
                  </div>
                </div>
                <p className=" px-3 pb-2 sm:p-3 lg:text-xs text-[9px]">
                  {moment(msg?.created_at).format("MMM DD, YYYY, hh:mm a")}
                </p>
              </div>
              <div className=" w-full p-3">
                <p className="text-xs leading-8">
                  {msg?.subject ?? ""}
                  {msg?.body ?? ""}{" "}
                </p>
              </div>
            </>
          )}
        </div>
      ))}
    </>
  );
};

export default UserMessage;

import React, { ReactNode } from "react";

interface EmptyCardProps {
  header?: string;
  children?: ReactNode;
}

const EmptyCard: React.FC<EmptyCardProps> = ({ header, children }) => {
  return (
    <div className=" shadow-md py-5 divide-y divide-gray-300 bg-white rounded-lg flex-grow ">
      <p className="font-bold px-4 pb-4 w-full">{header}</p>
      {children}
    </div>
  );
};

export default EmptyCard;

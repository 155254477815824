import { useMutation, useLazyQuery } from "@apollo/client";
import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import { UPDATE_USER } from "../data/mutation";
import { GET_USER } from "src/hooks/data/queries";
//import {GET_USER}

const UserContext = createContext<{
  user: any; //change later,
  setUser: (payload: any) => void;
  logoutUser: () => void;
  saveUser: (payload: any, callBack?: any) => void;
  userRefetch?: any;
}>({
  user: null,
  saveUser: ({}, callback) => {},
  logoutUser: () => {},
  setUser: ({}) => {},
  userRefetch: () => {},
});
export const useUser = () => useContext(UserContext);

export const UserProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const [getUser, { data, refetch }] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
  });
  const [user_state, setUserState] = useState<any>(data?.user || null);
  const [updateUser] = useMutation(UPDATE_USER);
  const [isLoading, setIsLoading] = useState(true);

  //get user every 5 minutes and update the user state
  useEffect(() => {
    const handler = localStorage.getItem("handlerToken") || "";
    if (user_state?.id === undefined) return;
    if (handler !== "") {
      localStorage.removeItem("userToken");
      return;
    }
    const interval = setInterval(() => {
      if (user_state?.id === undefined) return;
      getUser({
        variables: {
          userId: Number(user_state?.id),
        },
        onCompleted(data) {
          if (data?.user !== null) {
            setUserState(data?.user);
            localStorage.setItem("userToken", JSON.stringify(data?.user));
          }
        },
        onError(error) {
          console.log({ error });
        },
      });
    }, 10000);
    return () => clearInterval(interval);
  }, [getUser, user_state]);
  useEffect(() => {
    // setIsLoading(true);
    const localState = localStorage.getItem("userToken");
    const handlerState = localStorage.getItem("handlerToken");

    if (!localState) {
      if (handlerState) return;

      if (
        window.location.pathname.includes("login") ||
        window.location.pathname.includes?.("choose-a-plan") ||
        window.location.pathname.includes?.("get-started") ||
        window.location.pathname.includes?.("admin") ||
        window.location.pathname.includes?.("forget-password") ||
        window.location.pathname.includes?.("reset-password") ||
        window.location.pathname.includes?.("pre-verify") ||
        window.location.pathname.includes?.("verification") ||
        window.location.pathname.includes?.("terms-and-condition") ||
        window.location.pathname.includes?.("super") ||
        window.location.pathname === "/"
      ) {
        setIsLoading(false);
        return;
      }
      setIsLoading(false);

      window.location.replace("/login");
    }
    setIsLoading(false);

    localStorage.removeItem("handlerToken");
    //@ts-ignore
    return setUserState(JSON.parse(localState));
  }, []);

  const saveUser = (payload, callback?: any, errorCB?: any) => {
    const json_user_data = JSON.stringify({ ...user_state, ...payload });
    updateUser({
      variables: {
        updateUserId: Number(user_state.id),
        payload,
      },
      onCompleted: (data) => {
        if (data?.updateUser) {
          setUserState({ ...user_state, ...payload });
          localStorage.setItem("userToken", json_user_data);
          localStorage.removeItem("handlerToken");
          if (typeof callback === "function") {
            return callback();
          }
          return;
        }
      },
      onError: (error) => {
        console.log(error);
        return errorCB?.();
      },
    });
  };

  const setUser = (payload) => {
    const json_user_data = JSON.stringify({ ...user_state, ...payload });
    localStorage.clear();
    localStorage.setItem("userToken", json_user_data);
    setUserState({ ...user_state, ...payload });
  };
  const logoutUser = () => {
    try {
      localStorage.removeItem("userToken");
      localStorage.removeItem("handlerToken");
      setUserState(null);
      localStorage.clear();
    } catch (error) {
      console.log(error);
    }
  };

  const userRefetch = () => {
    refetch();
    localStorage.removeItem("userToken");
    localStorage.setItem("userToken", JSON.stringify(data?.user));
    setUserState(data?.user);
  };

  const values = useMemo(
    () => ({
      user: user_state,
      saveUser,
      logoutUser,
      setUser,
      userRefetch,
    }),
    [user_state]
  );

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

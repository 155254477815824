import { Fragment, useState } from "react";
import imageCompression from "browser-image-compression";
import { useUser } from "../auth/hooks/UserProvider";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Check, Close, DeleteOutline, Person } from "@mui/icons-material";
import { Menu, Transition } from "@headlessui/react";
import useUploadFile from "src/hooks/useUploadMedia";
import Tippy from "@tippyjs/react";
import { toast } from "react-toastify";
import { BeatLoader, ClockLoader } from "react-spinners";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_MEDIA } from "src/hooks/data/mutations";

export default function Photos() {
  const { user, saveUser, setUser } = useUser();
  const [updateUserMedia] = useMutation(UPDATE_USER_MEDIA);
  const [addDataLoad, setAddDataLoad] = useState(false);
  const updateUserMediaHandler = ({ action, new_ids, old_ids }) => {
    updateUserMedia({
      variables: {
        payload: {
          action,
          id: Number(user?.id),
          new_media_ids: new_ids,
          old_media_ids: old_ids,
        },
      },
      onCompleted: (data) => {
        toast.success(
          action === "remove"
            ? "Image deleted successfully"
            : "Image uploaded successfully"
        );
        setUser({
          media: data?.updateUserMedia?.media,
        });
        setUserMedias(data?.updateUserMedia?.media);
      },
      onError: (error) => {
        toast.error(
          error?.message ?? "Error performing operation, try again later"
        );
      },
    });
  };
  const { uploadFile, multipleFilesUrlObject } = useUploadFile();
  const [userImage, setUserImage] = useState<any>(
    user?.profile_image_url ?? ""
  );
  const [loadProfile, setLoadProfile] = useState(false);
  const [loadDP, setLoadDP] = useState(false);

  const [userMedias, setUserMedias] = useState(
    user && user?.media ? [...user?.media?.map?.((i: any) => i)] : []
  );

  return (
    <div className="space-y-5">
      <div className="space-y-3">
        <h2 className="md:text-xl text-sm font-bold">Photos</h2>
        <div className="bg-white space-y-10 rounded-lg p-4 md:p-5 pb-10">
          <div className="flex flex-col gap-2 md:flex-row items-start md:items-center md:px-10 p-0 md:py-5">
            <div className="md:w-1/2 w-full ">
              <h2 className="text-sm font-semibold">Profile Photo</h2>
              <p className="text-xs">This is the profile picture/avatar</p>
            </div>
            <div className="md:w-4/5 w-full flex flex-col gap-5 sm:flex-row lg:gap-10 justify-center items-center">
              <div className="w-32 h-32  md:mx-0 shadow-md flex justify-center items-center relative rounded-full">
                {userImage ? (
                  <img
                    src={userImage}
                    alt="profile_picture"
                    className="w-full h-full shadow-md border-2 border-gray-200 rounded-full object-cover"
                  />
                ) : (
                  <Person fontSize="large" />
                )}
                {loadDP ? (
                  <label className="w-10 h-10 hover:bg-gray-100 rounded-full cursor-pointer z-10 absolute bottom-0 bg-white shadow-md right-0 hover:text-blue-6000 flex justify-center items-center">
                    <ClockLoader size={30} color="#081A51" />
                  </label>
                ) : (
                  <label className="w-10 h-10 hover:bg-gray-100 rounded-full cursor-pointer z-10 absolute bottom-0 bg-white shadow-md right-0 hover:text-blue-6000 flex justify-center items-center">
                    <CameraAltIcon fontSize="small" color="inherit" />
                    <input
                      onChange={async (e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                          setLoadDP(true);
                          let currentFile;
                          if (file.size > 400) {
                            const options = {
                              maxSizeMB: 0.1,
                              maxWidthOrHeight: 1920,
                              useWebWorker: true,
                            };

                            const compressedFile = await imageCompression(
                              file,
                              options
                            );
                            currentFile = new File(
                              [compressedFile],
                              compressedFile.name,
                              {
                                type: compressedFile.type,
                              }
                            );
                          }
                          const data = await uploadFile(
                            currentFile,
                            "gallery/"
                          );
                          setLoadDP(false);
                          return setUserImage(data?.url);
                        }
                      }}
                      type="file"
                      accept="image/*"
                      className="hidden"
                    />
                  </label>
                )}
              </div>
              {!userImage && !user?.profile_image_url ? null : userImage ===
                user?.profile_image_url ? null : (
                <div className="flex gap-5 items-center">
                  {loadProfile ? (
                    <button className="px-4 py-2 bg-gray-100 border border-gray-300 w-max rounded-lg ">
                      <BeatLoader size={10} color="#081A51" />
                    </button>
                  ) : (
                    <>
                      <Tippy content="Cancel">
                        <div
                          onClick={() =>
                            setUserImage(user?.profile_image_url ?? "")
                          }
                          className="w-10 h-10 border border-gray-300 text-red-600 rounded-full flex justify-center items-center text-sm cursor-pointer hover:bg-gray-100 bg-white"
                        >
                          <Close fontSize="inherit" color="inherit" />
                        </div>
                      </Tippy>
                      <Tippy content="Save">
                        <div
                          onClick={() => {
                            const payload = {
                              profile_image_url: userImage,
                            };
                            setLoadProfile(true);
                            saveUser(payload, () => {
                              setLoadProfile(false);
                              toast.success("Profile image saved successfully");
                            });
                          }}
                          className="w-10 h-10 border border-gray-300 text-green-600 rounded-full flex justify-center items-center text-sm cursor-pointer hover:bg-gray-100 bg-white"
                        >
                          <Check fontSize="inherit" color="inherit" />
                        </div>
                      </Tippy>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* <div className="flex flex-col gap-2 md:flex-row items-start md:items-center md:px-10 p-0 md:py-5">
            <div className="md:w-1/2 w-full ">
              <h2 className="text-sm font-semibold">Background Photo</h2>
              <p className="text-xs">This is the board display picture</p>
            </div>
            <div className="md:w-4/5  flex gap-10 items-center w-full ">
              <div className="w-full lg:w-60 flex justify-center items-center h-40 shadow-md relative rounded-lg">
                {backgroundImage?.url ? (
                  <img
                    src={backgroundImage?.url}
                    alt="profile_picture"
                    className="w-full h-full shadow-md border-2 border-gray-200 rounded-full object-cover"
                  />
                ) : (
                  <Person fontSize="large" />
                )}
                <label className="w-10 h-10 hover:bg-gray-100 rounded-full cursor-pointer z-10 absolute top-0 bg-white shadow-md right-0 hover:text-blue-6000 flex justify-center items-center">
                  <CameraAltIcon fontSize="small" color="inherit" />
                  <input
                    onChange={async (e) => {
                      const file = e.target.files?.[0];
                      if (file) {
                        const data = await uploadFile(file, "gallery/");
                        console.log(data);

                        return setBackgroundImage(data);
                      }
                    }}
                    type="file"
                    accept="image/*"
                    className="hidden"
                  />
                </label>
                <div className="absolute bg-[#0000005e] z-3 top-0 left-0 w-full h-full "></div>
              </div>
              {!backgroundImage?.url &&
              !user?.media?.[0]?.url ? null : backgroundImage?.url ===
                user?.media?.[0]?.url ? null : (
                <div className="flex gap-5 items-center">
                  {loadBg ? (
                    <button className="px-4 py-2 bg-gray-100 border border-gray-300 w-max rounded-lg ">
                      <BeatLoader size={10} color="#081A51" />
                    </button>
                  ) : (
                    <>
                      <Tippy content="Cancel">
                        <div
                          onClick={() =>
                            setBackgroundImage(user?.media?.[0] ?? "")
                          }
                          className="w-10 h-10 border border-gray-300 text-red-600 rounded-full flex justify-center items-center text-sm cursor-pointer hover:bg-gray-100 bg-white"
                        >
                          <Close fontSize="inherit" color="inherit" />
                        </div>
                      </Tippy>
                      <Tippy content="Save">
                        <div
                          onClick={() => {
                            let payload = {
                              media: [backgroundImage],
                            };
                            if (user?.media?.slice?.(1)?.length > 0) {
                              //@ts-ignore
                              payload.media = [
                                backgroundImage,
                                ...user?.media?.slice?.(1),
                              ];
                            }

                            console.log(payload);

                            setLoadBg(true);
                            saveUser(payload, () => {
                              setLoadBg(false);
                              toast.success(
                                "Background image saved successfully"
                              );
                            });
                          }}
                          className="w-10 h-10 border border-gray-300 text-green-600 rounded-full flex justify-center items-center text-sm cursor-pointer hover:bg-gray-100 bg-white"
                        >
                          <Check fontSize="inherit" color="inherit" />
                        </div>
                      </Tippy>
                    </>
                  )}
                </div>
              )}
            </div>
          </div> */}
          <div className="space-y-3 p-0 md:px-10 md:border border-gray-300 md:py-5">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-sm font-semibold">Medias</h2>
                <p className="text-xs">These are your photos to explore</p>
              </div>
              {addDataLoad ? (
                <div className="hover:bg-gray-100 text-xs md:text-base rounded-full cursor-pointer py-1.5 px-4 md:py-2 border border-gray-300 shadow-md hover:text-blue-6000 flex gap-2 justify-center items-center">
                  <ClockLoader size={10} color="#081A51" />
                  Uploading
                </div>
              ) : (
                <label className=" hover:bg-gray-100 text-xs md:text-base rounded-full cursor-pointer py-1.5 px-4 md:py-2 border border-gray-300 shadow-md hover:text-blue-6000 flex gap-2 justify-center items-center">
                  <CameraAltIcon fontSize="inherit" color="inherit" />
                  <input
                    onChange={async (e) => {
                      const file = e.target.files?.[0];
                      if (file) {
                        setAddDataLoad(true);
                        let currentFile;
                        if (file.size > 400) {
                          const options = {
                            maxSizeMB: 0.1,
                            maxWidthOrHeight: 1920,
                            useWebWorker: true,
                          };

                          const compressedFile = await imageCompression(
                            file,
                            options
                          );
                          currentFile = new File(
                            [compressedFile],
                            compressedFile.name,
                            {
                              type: compressedFile.type,
                            }
                          );
                        }
                        const data = await uploadFile(currentFile, "gallery/");
                        updateUserMediaHandler({
                          action: "add",
                          new_ids: [
                            ...userMedias?.map?.((i) => Number(i?.id)),
                            Number(data?.id),
                          ],
                          old_ids: userMedias?.map?.((i) => Number(i?.id)),
                        });
                        setAddDataLoad(false);
                      }
                    }}
                    type="file"
                    accept="image/*"
                    className="hidden"
                  />
                  Add
                </label>
              )}
            </div>
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 ">
              {userMedias?.map?.((md: any, index: number) => (
                <div key={index} className="">
                  <div className="relative w-full h-32 rounded-lg">
                    <img
                      src={md?.url}
                      className="w-full rounded-lg h-full object-cover"
                    />
                    <label className="w-10 h-10 hover:bg-gray-100 rounded-full cursor-pointer z-10 absolute top-0 bg-white shadow-md right-0 hover:text-blue-6000 flex justify-center items-center">
                      <Menu as="div" className="relative w-max">
                        {({ open }) => (
                          <>
                            <Menu.Button as="div" className="w-full">
                              <DeleteOutline fontSize="small" color="inherit" />
                            </Menu.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                static
                                className="focus:outline-none absolute  right-0 z-50 mt-1 w-60 origin-top-right rounded-lg border bg-white p-2 shadow-md ring-1 ring-gray-1"
                              >
                                <div className="space-y-1">
                                  <p className="pb-2 text-center text-base">
                                    Remove this image?
                                  </p>
                                  <div className="flex w-full items-center justify-between gap-5">
                                    <Menu.Item>
                                      <button
                                        // disabled={loadingRemoveUser}
                                        className="px-4 py-2 border border-gray-300 rounded-lg text-xs"
                                      >
                                        Cancel
                                      </button>
                                    </Menu.Item>
                                    <Menu.Item>
                                      <button
                                        // disabled={loadingRemoveUser}
                                        onClick={() => {
                                          updateUserMediaHandler({
                                            action: "remove",
                                            new_ids: userMedias
                                              ?.filter?.(
                                                (i) => i?.id !== md?.id
                                              )
                                              ?.map?.((i) => Number(i?.id)),
                                            old_ids: userMedias?.map?.((i) =>
                                              Number(i?.id)
                                            ),
                                          });
                                        }}
                                        className="px-4 py-2 bg-red-600 text-white border border-red-600 rounded-lg text-xs"
                                      >
                                        {"Continue"}
                                      </button>
                                    </Menu.Item>
                                  </div>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                    </label>
                    <div className="absolute rounded-lg bg-[#0000005e] z-3 top-0 left-0 w-full h-full "></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="flex justify-end">
            <button
              disabled={isNotChanged}
              className="px-6 py-2.5 rounded-lg bg-[#081A51] text-white font-semibold hover:opacity-75 disabled:bg-gray-300 disabled:text-gray-700"
            >
              Save Changes
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { MdEditSquare } from 'react-icons/md';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';

interface User {
  user_match_meeting_time: string;
}

interface Calendercomp {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  userData: User[];
  setSelectedDate: React.Dispatch<React.SetStateAction<any>>;
}

const Calendercomp: React.FC<Calendercomp> = ({ setModal, userData, setSelectedDate }) => {
  const [date, setDate] = useState(new Date());
  const [allDates, setAllDates] = useState<string[]>([]);

  useEffect(() => {
    // Convert meeting times to 'YYYY-MM-DD' format in UTC
    const highlightedDates = userData?.map((i) => {
      const meetingDate = new Date(i?.user_match_meeting_time);
      // Normalize to start of the day in UTC
      return moment(meetingDate)?.startOf('day')?.toISOString()?.slice(0, 10);
    });
    setAllDates(highlightedDates);
  }, [userData]);

  const tileClassName = ({ date, view }: { date: Date, view: string }) => {
    if (view === 'month') {
      const formattedDate = moment(date)?.startOf('day')?.toISOString()?.slice(0, 10);
      return allDates?.includes(formattedDate) ? 'highlighted' : null;
    }
    return null;
  };

  const handleClickDay = (date: Date) => {
    const formattedDate = moment(date).startOf('day').toISOString().slice(0, 10);
    if (allDates.includes(formattedDate)) {
      // Find all user data for the clicked date
      const userDataForDate = userData?.filter((user) => {
        const userDate = moment(user.user_match_meeting_time).startOf('day').toISOString().slice(0, 10);
        return userDate === formattedDate;
      });
      // console.log('User data for the selected date:', userDataForDate);
        setDate(date);
        setSelectedDate(userDataForDate?.[0])
    setModal(true); 
    }
  
  };

  return (
    <div className='py-2 sm:py-4 md:py-6 px-2 sm:px-4 md:px-6'>
      {/* header */}
      <div className='flex justify-between items-center border-b pb-2 sm:pb-3'>
        <h1 className='text-sm md:text-base font-semibold xl:text-lg'>Calendar</h1>
        {/* <MdEditSquare className='text-primary-bg md:text-xl xl:text-2xl cursor-pointer' /> */}
      </div>
      {/* body */}
      <div className='h-full'>

        {/* calender */}
        <div>
          <Calendar
            className="calendercomp"
            onClickDay={handleClickDay}
            value={date}
            tileClassName={tileClassName}
          />
        </div>
      </div>
    </div>
  );
};

export default Calendercomp;


import ASSETS from "src/asset";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderText, ParagraphText } from "./styles";
import { useMutation } from "@apollo/client";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import theme from "src/assets/colors/theme";
import { USER_SIGN_IN } from "src/components/auth/data/mutation";
import ButtonComp from "src/components/Button/Button";
import InputField from "src/components/Input";

export default function SuperAdminLogin() {
  const [userLogin, setUserLogin] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(true);
  const [signIn, { loading }] = useMutation(USER_SIGN_IN);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserLogin((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    const match = userLogin.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (!match) {
      toast.error("Invalid email address, please check and try again.");
      return;
    }
    navigate("/super/dashboard");
  };

  const disabled = Object.values(userLogin).some((text) => text === "");

  return (
    <div className="w-full h-screen  flex flex-col justify-center items-center  px-6 md:px-0  md:flex-row md:gap-4 lg:gap-0 mx-auto ">
      <div className="hidden h-full p-5 md:block md:w-1/2   ">
        <div className="bg-[#d9d9d988] rounded-3xl w-full h-full">
          <img
            src={ASSETS.SuperAdminLogin}
            alt="people"
            className=" w-full h-full object-contain mx-auto "
          />
        </div>
      </div>
      <div className=" w-full flex flex-col  md:mx-auto justify-center items-center  md:w-1/2 max-w-xs sm:max-w-sm md:max-w-xs  lg:max-w-sm  md:justify-start md:items-start xl:max-w-md ">
        {/* header */}

        <div className="w-full flex flex-col justify-center items-center mx-auto  md:justify-start md:mx-0 md:items-start  ">
          <img src={ASSETS.Logo} alt="logo" className="object-contain w-32 " />
          <h1 className="font-['sans-serif'] mt-6  text-brand-1 font-bold text-2xl">
            Administrative Dashboard
          </h1>
          <p className="text-sm mt-1">
            {" "}
            Login to your account to manage your project
          </p>
        </div>
        <div className="w-full ">
          <InputField
            label={"Email"}
            secretState={showPassword}
            icon={<EmailOutlinedIcon />}
            placeholder="Johndoe@example.com"
            type="email"
            name="email"
            onChange={handleChange}
            value={userLogin.email}
            labelView
            inBox={`h-10 flex mt-2 lg:mt-3 pl-3 justify-between border border-[#BAC0CE] items-center `}
          />
          <InputField
            label={"Password"}
            secretState={showPassword}
            icon={<LockOutlinedIcon />}
            secret={!showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            changeVisibleState={togglePasswordVisibility}
            type={!showPassword ? "text" : "password"}
            placeholder="************************"
            name="password"
            onChange={handleChange}
            value={userLogin.password}
            labelView
            inBox={`h-10 flex mt-2 lg:mt-3 pl-3 justify-between border border-[#BAC0CE] items-center `}
          />

          <ButtonComp
            children="Login"
            size="large"
            isLoading={loading}
            backgroundColor={theme.PRIMARY_DARK}
            onClick={handleSubmit}
            disabled={disabled || loading}
          />
        </div>
      </div>
    </div>
  );
}

import { Menu, Transition } from "@headlessui/react";
import {
  FilterList,
  MoreVert,
  PeopleOutline,
  PersonAddAlt,
  PersonOff,
  PersonRemove,
  RemoveRedEye,
} from "@mui/icons-material";
import moment from "moment";
import { Fragment, useState } from "react";
import LinePagination from "src/Paging/LinePagination";
import { useQuery } from "@apollo/client";
import { ADMIN_DASHBOARD } from "../auth/data/queries";

interface User {
  last_name: String;
  first_name: String;
  email: String;
  phone: String;
  date_joined: String;
  status: String;
  gender: String;
  genotype: String;
  relationship_status: String;
  ethinicity: String;
  date_of_birth: Date;
  username: String;
  religion: String;
  occupation: String;
  location: String;
  country: String;
  place_of_birth: String;
  nationality: String;
  qualification: String;
  hobbies: String;
  preferred_gender: String;
  preferred_location: String;
  preferred_religion: String;
  preferred_genotype: String;
  preferred_status: String;
  preferred_age: String;
}
type Alluserdata = User[];
export default function AllAdminHandlers({ setSelectedUser, setIndexPage }) {
  const { data, loading } = useQuery(ADMIN_DASHBOARD, {
    variables: {
      adminId: 1,
    },
    fetchPolicy: "network-only",
  });

  const [allDatas, setAllDatas] = useState<Alluserdata | []>([]);

  const {
    all_users_count,
    all_inactive_users,
    all_blacklisted_users,
    all_handlers_count,
    handlers,
  } = data?.adminDashboard || {};
  return (
    <div className="space-y-10">
      <div className="space-y-4">
        <h2 className="text-base font-bold">Search Handlers</h2>
        {/* <div className="grid grid-cols-5 gap-5">
          <div className="bg-white space-y-3 py-4 px-8 rounded-md flex flex-col justify-between">
            <div className="w-10 h-10 rounded-full bg-[#5718FF] flex justify-center items-center text-white">
              <PeopleOutline fontSize="small" />
            </div>
            <p className="text-sm font-normal">All Users</p>
            <h2 className="text-base font-bold">{all_users_count}</h2>
          </div>
          <div className="bg-white space-y-3 py-4 px-8 rounded-md flex flex-col justify-between">
            <div className="w-10 h-10 rounded-full bg-[#93FFCB] flex justify-center items-center text-black">
              <PersonAddAlt fontSize="small" />
            </div>
            <p className="text-sm font-normal">Active Users</p>
            <h2 className="text-base font-bold">250,000</h2>
          </div>
          <div className="bg-white space-y-3 py-4 px-8 rounded-md flex flex-col justify-between">
            <div className="w-10 h-10 rounded-full bg-[#CAD5FE] flex justify-center items-center text-black">
              <PersonOff fontSize="small" />
            </div>
            <p className="text-sm font-normal">Inactive Users</p>
            <h2 className="text-base font-bold">45,750</h2>
          </div>
          <div className="bg-white space-y-3 py-4 px-8 rounded-md flex flex-col justify-between">
            <div className="w-10 h-10 rounded-full bg-[#FF3366] flex justify-center items-center text-white">
              <PersonRemove fontSize="small" />
            </div>
            <p className="text-sm font-normal">Blacklisted Users</p>
            <h2 className="text-base font-bold">{all_blacklisted_users}</h2>
          </div>
          <div className="bg-white space-y-3 py-4 px-8 rounded-md flex flex-col justify-between">
            <div className="w-10 h-10 rounded-full bg-[#017EFA] flex justify-center items-center text-white">
              <PersonAddAlt fontSize="small" />
            </div>
            <p className="text-sm font-normal">Handlers</p>
            <h2 className="text-base font-bold">{all_handlers_count}</h2>
          </div>
        </div> */}
        <div className="bg-white rounded p-5">
          <div className="flex gap-6 justify-between items-end">
            <div className="space-y-3 flex-grow">
              <p className="text-sm font-medium">Handler Name</p>
              <input
                type="text"
                className="border w-full border-gray-400 text-xs py-2.5 "
                placeholder="Please input handlers name here"
              />
            </div>
            <div className="space-y-3 flex-grow">
              <p className="text-sm font-medium">Status</p>
              <select className="border w-full border-gray-400 text-xs py-2.5 ">
                <option value={""}>Sort by status</option>
              </select>
            </div>
            <button className="text-sm font-normal w-40 text-center flex-shrink-0 bg-[#081A51] outline-none border-none text-white px-4 py-2.5">
              Search Handlers
            </button>
          </div>
        </div>
      </div>
      <div className="space-y-5 pt-10 pb-10">
        <div className="flex justify-between items-center">
          <h2 className="text-base font-bold">Handlers</h2>
          <button className="text-sm font-normal w-40 text-center flex-shrink-0 border border-[#081A51] hover:bg-[#081A51] hover:text-white bg-transparent rounded-lg outline-none text-[#081A51] px-4 py-2.5">
            Create Handler
          </button>
        </div>
        <LinePagination data={handlers || []} setCurrentItems={setAllDatas}>
          <table className="relative rounded w-full divide-y divide-gray-1 overflow-y-auto  bg-white">
            <thead className=" rounded-lg rounded-t px-5">
              <tr className="rounded-t-lg">
                <th
                  scope="col"
                  className={`px-2 py-4 pl-5 text-left text-xs font-medium uppercase`}
                >
                  Handler Name
                </th>
                <th
                  scope="col"
                  className={`px-2 py-4  text-left text-xs font-medium uppercase`}
                >
                  Assigned Clients
                </th>
                <th
                  scope="col"
                  className={`px-2 py-4   text-left text-xs font-medium uppercase`}
                >
                  Average Rating
                </th>
                <th
                  scope="col"
                  className={`px-2 py-4 text-left text-xs font-medium uppercase`}
                >
                  Title
                </th>

                <th
                  scope="col"
                  className={`px-2 py-4  text-left text-xs font-medium uppercase`}
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-300 overflow-auto bg-white px-5 pt-5">
              {allDatas?.map?.((item: any, index: number) => (
                <tr
                  onClick={() => {
                    setSelectedUser(item);
                    setIndexPage(1);
                  }}
                  className="hover:bg-gray-100 cursor-pointer"
                  key={index}
                >
                  <td className="px-2 pl-5 py-4 text-left text-sm font-medium capitalize text-[#545F7D]">
                    {item?.user_name}
                  </td>
                  <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                    {/* {item?.first_name} */}
                    400
                  </td>
                  <td className="px-2 py-4 text-left text-xs font-normal lowercase text-[#545F7D]">
                    {/* {item?.email} */}
                    4.5
                  </td>
                  <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                    {/* {item?.phone} */}
                    Handler
                  </td>
                  {/* <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                    {moment(item?.date_joined).format("MMM DD, YYYY")}
                  </td> */}
                  <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                    <p
                      className={`px-4 py-1 w-max rounded-full ${
                        item?.status?.toLowerCase?.() === "inactive"
                          ? "bg-[#545f7d3b] text-black"
                          : item?.status?.toLowerCase?.() === "pending"
                          ? "bg-[#e9b30042] text-[#E9B200]"
                          : item?.status?.toLowerCase?.() === "active"
                          ? "bg-green-100 text-green-600 "
                          : "bg-gray-100 text-gray-600"
                      } text-center`}
                    >
                      {/* {item?.status} */} Inactive
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </LinePagination>
      </div>
    </div>
  );
}

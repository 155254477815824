import { useState } from "react";
import { Add, Delete, Send } from "@mui/icons-material";

export default function AdminContact() {
  const [allFiles, setAllFiles] = useState([
    {
      id: 1,
      file: null,
    },
  ]);
  return (
    <div className="px-5 w-full h-full overflow-y-auto pb-40">
      <div className="bg-white p-5 w-full">
        <div className="space-y-5 w-4/5 mx-auto">
          <div className="space-y-2">
            <label className="block text-sm font-semibold">Heading</label>
            <input
              className="w-full bg-white px-4 py-2 rounded-lg border border-[#102A42] text-sm"
              placeholder={"Input heading here"}
            />
          </div>
          <div className="space-y-2">
            <label className="block text-sm font-semibold">Message</label>
            <textarea
              className="resize-none w-full bg-white h-40 p-5 rounded-lg border border-[#102A42] text-sm"
              placeholder={"Input subject here"}
            ></textarea>
          </div>
          <div className="space-y-1">
            <div className="flex justify-between items-start">
              <div className="w-1/2 text-[#102A42] space-y-2">
                {allFiles?.map((item, index) => (
                  <div key={index} className="flex gap-5 items-center">
                    <label className="text-sm divide-x divide-[#102A42] cursor-pointer gap-3 flex font-semibold border border-[#102A42] ">
                      <span className="px-4 py-2.5 flex-shrink-0 ">
                        Select File
                      </span>
                      <input
                        type="file"
                        multiple={false}
                        onChange={(e) => {
                          setAllFiles((prev) =>
                            prev?.map((i) => {
                              if (i?.id === item?.id) {
                                // @ts-ignore
                                i.file = e.target.files?.[0];
                                return i;
                              }
                              return i;
                            })
                          );
                          e.stopPropagation();
                        }}
                        className="hidden"
                      />
                      <p className="px-4 block truncate py-2.5">
                        {item?.file
                          ? // @ts-ignore
                            item?.file?.name
                          : "No file choosen"}
                      </p>
                    </label>
                    {allFiles?.length !== 1 && (
                      <div
                        onClick={() => {
                          setAllFiles((prev) =>
                            prev?.filter((i) => i?.id !== item?.id)
                          );
                        }}
                        className="w-8 h-8 flex-shrink-0 flex justify-center text-sm items-center hover:shadow-sm rounded-full bg-gray-200 hover:text-[#102A42] cursor-pointer"
                      >
                        <Delete fontSize="small" />
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <button
                onClick={() => {
                  setAllFiles((prev) => [
                    ...prev,
                    { id: prev?.length + 1, file: null },
                  ]);
                }}
                className="flex text-[#102A42] gap-2 items-center border-none outline-none text-sm font-semibold"
              >
                <Add />
                Add More
              </button>
            </div>
            <p className="text-10">
              Allowed File Extensions: .jpg, .gif, .jpeg, .png, .pdf, zip, .doc,
              .docx (Max file size: 128MB)
            </p>
          </div>
          <button className="px-6 rounded-lg py-2.5 bg-[#017EFA] text-white flex gap-2 items-center hover:opacity-70 hover:shadow-md ">
            Send
            <Send fontSize="small" />
          </button>
        </div>
      </div>
    </div>
  );
}

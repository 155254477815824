import { createContext, useState, useEffect } from "react";
// import Notification from 'src/components/Notification';
import { NotificationsObj } from "src/data/notification";
import moment from 'moment';


export const SideBarContext = createContext<any>({
  show: false,
  showMessage: true,
  report: false,
  isNav: true,
  setIsNav: () => {},
  toggleShowSidebar: () => {},
  toggleShowDropdown: () => {},
  close: () => {},
  open: () => {},
  notifications: Array<any>,
  setUser: () => {},
  viewMatchProfile: () => {},
  viewNotifMessage: () => {},
  handleAddImage: () => {},
  setMatch: () => {},
  toggleMessageView: () => {},
  toggleReport: () => {},
  drop: false,
  userType: "user",
  setUserType: () => {},
  onlineStatusContext: {},
  lastSeen: "lastseen"
});

const SidebarProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [isNav, setIsNav] = useState(true);
  const [showMessage, setShowMessage] = useState(true);
  const [showReport, setShowReport] = useState(false);
  const [onlineStatusContext, setOnlineStatusContext] = useState<any>({});
  const [lastSeen, setLastSeen] = useState<any>('');
  const [notifications, setNotifications] = useState(NotificationsObj);
  const [userType, setUserType] = useState("user");
  const [match, setMatch] = useState(null);
  const toggleShowSidebar = () => setShow(!show);
  const toggleShowDropdown = () => setShow(!show);
  const close = () => setShow(false);
  const open = () => setShow(true);
  const viewMatchProfile = (match) => setMatch(match);
  const viewNotifMessage = (msg) => setMatch(msg);
  const toggleMessageView = () => setShowMessage(!showMessage);
  const toggleReport = () => setShowMessage(!showReport); 
  



  return (
    <SideBarContext.Provider
      value={{
        show,
        toggleShowSidebar,
        close,
        open,
        match,
        isNav,
        setIsNav,
        viewMatchProfile,
        toggleMessageView,
        showMessage,
        showReport,
        toggleReport,
        userType,
        setUserType,
        notifications,
        viewNotifMessage,
        toggleShowDropdown,
        onlineStatusContext,
        setOnlineStatusContext,
        lastSeen,
        setLastSeen
      }}
    >
      {children}
    </SideBarContext.Provider>
  );
};

export default SidebarProvider;

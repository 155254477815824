import { ArrowForward } from "@mui/icons-material";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ASSETS from "src/asset";
import { Link as Scroll } from "react-scroll";

export default function Header() {
  const [isBar, setIsBar] = useState(false);
  const [isHeader, setIsHeader] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const url = location.pathname;
  const handleScroll = () => {
    if (window.scrollY > 25) {
      return setIsHeader(true);
    }
    return setIsHeader(false);
  };
  window.addEventListener("scroll", handleScroll);

  const isUserLoggedIn = localStorage.getItem("userToken");

  return (
    <>
      {/* <div className="flex h-10 font-inter text-white bg-[#FF7BAC]  justify-center items-center">
        <p className="text-[8px] sm:text-xs w-max cursor-pointer flex gap-4 items-center">
          50% discount promotion Ends Soon 🌟, Get It Now
          <ArrowForward fontSize="inherit" color="inherit" />
        </p>
      </div> */}
      <header
        className={`z-50 p-5 md:px-10 ${
          isHeader ? "bg-white shadow" : "bg-transparent"
        } flex sticky top-0 justify-between items-center`}
      >
        <div className="flex gap-10 xl:gap-32 items-center">
          <Link to={"/"}>
            <img src={ASSETS.Logo} alt="logo" className="w-20 h-10 md:w-32 " />
          </Link>
          {url === "/" && (
            <div className="hidden md:flex gap-3 md:gap-5 xl:gap-10 items-center">
              <Scroll
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={-90}
                duration={500}
              >
                <p className="jarkata_sans main_thick_blue text-xs md:text-sm font-semibold cursor-pointer">
                  About Us
                </p>
              </Scroll>
              <Scroll
                activeClass="active"
                to="onboard"
                spy={true}
                smooth={true}
                offset={-90}
                duration={500}
              >
                <p className="jarkata_sans main_thick_blue text-xs md:text-sm font-semibold cursor-pointer">
                  How it Works
                </p>
              </Scroll>
              <Scroll
                activeClass="active"
                to="testimonies"
                spy={true}
                smooth={true}
                offset={-90}
                duration={500}
              >
                <p className="jarkata_sans main_thick_blue text-xs md:text-sm font-semibold cursor-pointer">
                  Testimonies
                </p>
              </Scroll>
              <Scroll
                activeClass="active"
                to="touch"
                spy={true}
                smooth={true}
                offset={-90}
                duration={500}
              >
                <p className="jarkata_sans main_thick_blue text-xs md:text-sm font-semibold cursor-pointer">
                  Contact us
                </p>
              </Scroll>
            </div>
          )}
        </div>
        <ul className="hidden md:flex justify-between items-center gap-5 xl:gap-10">
          {url !== "/" && (
            <li className="text-lg cursor-pointer font-bold text-brand-1">
              <Link to={"/"} className="flex gap-3 items-center">
                Home
              </Link>
            </li>
          )}
          {/* <li
            onClick={() => {
              document.querySelector("#plans")?.scrollIntoView({
                behavior: "smooth",
              });
            }}
            className="text-lg cursor-pointer font-normal"
          >
            Plans
          </li> */}

          {url === "/" && (
            <li>
              <Link to="/pre-verify">
                <p className="jarkata_sans main_thick_blue text-xs md:text-sm font-medium">
                  Sign up
                </p>
              </Link>
              {/* {!isUserLoggedIn ? (
              ) : null} */}
            </li>
          )}
          <li>
            {
              <p
                onClick={() => navigate("/login")}
                className="px-4 jarkata_sans rounded-full text-white bg-[#081a51] text-xs md:text-sm font-semibold cursor-pointer py-1.5 hover:opacity-75 flex items-center justify-center"
              >
                Login
              </p>
            }
          </li>
        </ul>
        <span className="md:hidden flex justify-center items-center rounded-lg hover:bg-gray-100 w-10 h-10">
          <i onClick={() => setIsBar(true)} className="fa-solid fa-bars"></i>
        </span>
      </header>
      <div
        style={{
          background: "#1d3149",
          transform: `${isBar ? "translateX(0)" : "translateX(100%)"}`,
          transition: "all ease .5s",
        }}
        className="fixed md:hidden space-y-5 z-50 top-0 left-0 w-full h-screen"
      >
        <div className="p-3  flex justify-end">
          <span
            onClick={() => setIsBar(false)}
            className="w-10 h-10 flex justify-center items-center rounded-full hover:bg-gray-100"
          >
            <i className="fa-solid text-xl font-bold text-white fa-x"></i>
          </span>
        </div>
        <div className="space-y-5 px-10 ">
          <Link to="/">
            <p
              onClick={() => {
                setIsBar(false);
              }}
              className="jarkata_sans text-white py-5 text-xs md:text-sm font-semibold"
            >
              Home
            </p>
          </Link>
          <Scroll
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={-90}
            duration={500}
          >
            <p
              onClick={() => {
                navigate("/");
                setIsBar(false);
              }}
              className="jarkata_sans text-white py-5 text-xs md:text-sm font-semibold cursor-pointer"
            >
              About Us
            </p>
          </Scroll>
          <Scroll
            activeClass="active"
            to="onboard"
            spy={true}
            smooth={true}
            offset={-90}
            duration={500}
          >
            <p
              onClick={() => {
                navigate("/");
                setIsBar(false);
              }}
              className="jarkata_sans text-white py-5 text-xs md:text-sm font-semibold cursor-pointer"
            >
              How it Works
            </p>
          </Scroll>
          <Scroll
            activeClass="active"
            to="testimonies"
            spy={true}
            smooth={true}
            offset={-90}
            duration={500}
          >
            <p
              onClick={() => {
                navigate("/");
                setIsBar(false);
              }}
              className="jarkata_sans text-white py-5 text-xs md:text-sm font-semibold cursor-pointer"
            >
              Testimonies
            </p>
          </Scroll>
          <Scroll
            activeClass="active"
            to="touch"
            spy={true}
            smooth={true}
            offset={-90}
            duration={500}
          >
            <p
              onClick={() => {
                navigate("/");
                setIsBar(false);
              }}
              className="jarkata_sans text-white py-5 text-xs md:text-sm font-semibold cursor-pointer"
            >
              Contact us
            </p>
          </Scroll>
        </div>
        <ul className="flex flex-col sm:flex-row px-10 w-full justify-between items-center gap-10">
          {url === "/" && (
            <>
              <li className="text-xl w-full">
                <span
                  onClick={() => {
                    setIsBar(false);
                    navigate("/pre-verify");
                  }}
                  className="px-5 text-sm border border-white text-white rounded-3xl font-semibold cursor-pointer py-2 flex items-center justify-center"
                >
                  Sign up
                </span>
              </li>
              <li className="text-xl w-full">
                <span
                  onClick={() => {
                    setIsBar(false);
                    navigate("/login");
                  }}
                  className="px-5 text-sm bg-white text-brand-1 rounded-3xl font-semibold cursor-pointer py-2 flex items-center justify-center"
                >
                  Login
                </span>
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  );
}

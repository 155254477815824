import React, { useState } from "react";
import InputField from "src/components/Input";
import ButtonComp from "src/components/Button/Button";
import theme from "src/assets/colors/theme";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "src/hooks/data/mutations";
import { useUser } from "src/components/auth/hooks/UserProvider";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ArrowBack, KeyboardBackspace } from "@mui/icons-material";

const AuthResetPassword = () => {
  const { user } = useUser();
  const [resetPassword] = useMutation(RESET_PASSWORD);

  // const [password, setPassword] = useState({ current: "", new:"", confirm: "" });
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [showCurrent, setShowCurrent] = useState(true);
  const [showNew, setShowNew] = useState(true);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleCurrentVisibility = () => {
    setShowCurrent(!showCurrent);
  };
  const toggleNewVisibility = () => {
    setShowNew(!showNew);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "newPassword") {
      setNewPassword(value);
    }
    if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
    if (name === "currentPassword") {
      setCurrentPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    resetPassword({
      variables: {
        userId: Number(user?.id),
        oldPassword: currentPassword,
        password: confirmPassword,
      },
      onCompleted: (res) => {
        if (res.resetPassword) {
          toast.success(res.message ?? "Password reset successfully");
          navigate("/dashboard/my-profile");
        } else {
          toast.error("Current password is wrong");
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  // const disabled = Object.values(password).some((text) => text === "") || Object.values(password).some((text) => text.new === "");

  const disabled =
    newPassword === "" ||
    confirmPassword === "" ||
    currentPassword === "" ||
    confirmPassword !== newPassword;

  const checkPassword = confirmPassword !== newPassword;

  return (
    <>
      <h2
        onClick={() => {
          navigate(-1);
        }}
        className="text-sm w-max flex gap-2 items-center cursor-pointer hover:text-blue-600 font-bold text-[#1C1F37] mb-4"
      >
        <KeyboardBackspace fontSize="small" />
        Back to profile
      </h2>
      <div className="px-4 py-8 bg-white flex justify-center max-md:px-1 mt-4">
        {
          <div className="w-[100%] flex flex-col items-center lg:w-[40%] md:w-[60%]">
            {/* {resetPasswordInCompleted  && <p className='text-sm font-semibold text-rose-700'>Old password does not match...</p>} */}
            <InputField
              label={"Current Password"}
              labelStyle="text-[#081A51] text-xs lg:text-sm font-semibold"
              secretState={showPassword}
              icon={<LockOutlinedIcon />}
              secret={
                !showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />
              }
              changeVisibleState={toggleCurrentVisibility}
              type={!showCurrent ? "text" : "password"}
              placeholder="************************"
              name="currentPassword"
              onChange={handleChange}
              value={currentPassword}
              labelView
            />
            <InputField
              label={"New Password"}
              labelStyle="text-[#081A51] text-xs lg:text-sm font-semibold"
              secretState={showPassword}
              icon={<LockOutlinedIcon />}
              secret={
                !showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />
              }
              changeVisibleState={toggleNewVisibility}
              type={!showNew ? "text" : "password"}
              placeholder="************************"
              name="newPassword"
              onChange={handleChange}
              value={newPassword}
              labelView
            />
            <InputField
              label={"Confirm Password"}
              labelStyle="text-[#081A51] text-xs lg:text-sm font-semibold"
              secretState={showPassword}
              icon={<LockOutlinedIcon />}
              secret={
                !showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />
              }
              changeVisibleState={togglePasswordVisibility}
              type={!showPassword ? "text" : "password"}
              placeholder="************************"
              name="confirmPassword"
              onChange={handleChange}
              value={confirmPassword}
              labelView
            />
            {checkPassword && (
              <p className="text-sm font-semibold text-rose-700 mt-1">
                New Password and Confirm password does not match
              </p>
            )}
            <ButtonComp
              children="Reset Password"
              size="large"
              backgroundColor={theme.PRIMARY_DARK}
              onClick={handleSubmit}
              disabled={disabled}
            />
          </div>
        }
      </div>
    </>
  );
};

export default AuthResetPassword;

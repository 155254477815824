import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SideBarItem } from "src/components/Sidebar/UserDashSideBar";
import { SideBarItemObjAdmin } from "src/data/sidebar_item";
import ASSETS from "src/asset";

interface Page {
  icon: String;
  text: String;
  iconSm: String;
  textSm: String;
  page: String;
  title: String;
}

const Logo =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/logo.svg";
const Vector =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/vector.svg";
const Question =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/question.svg";
const LogoIconSm =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/logout_sm.svg";

const SuperAdminDashboardLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [showSm, setShowSm] = useState(false);
  const [pageInfo, setPageInfo] = useState<Page | null>(null);
  const [navlinks, setNavlinks] = useState(SideBarItemObjAdmin);

  useEffect(() => {
    setPageInfo(navlinks?.filter((i) => i?.page === location?.pathname)?.[0]);
    setShowSm(false);
  }, [location, navlinks]);

  const mainpath = window.location.pathname;

  return (
    <div className="w-full h-full">
      <div className="flex w-full h-screen overflow-hidden bg-white">
        <div
          className={` hidden md:block ${
            show ? "w-72" : "w-28"
          } bg-[#081A51] relative transition-all flex-shrink-0 h-full overflow-hidden `}
        >
          <button
            onClick={() => setShow((val) => !val)}
            className="rounded-lg z-50 shadow-lg bg-white opacity-100 hover:bg-gray-50 cursor-pointer absolute -right-2.5"
          >
            {show ? (
              <ChevronLeftOutlined className="shadow-lg" />
            ) : (
              <ChevronRightOutlined className="shadow-lg" />
            )}
          </button>
          <div className="flex flex-col h-full justify-between overflow-hidden">
            <div className="w-full p-4 gap-3 flex items-center justify-center relative">
              <img src={Vector} className="w-8 h-8" alt="vector" />
              {show && (
                <h2
                  className={`text-white canela text-3xl whitespace-nowrap overflow-hidden transition-all `}
                >
                  Nirvanae
                </h2>
              )}
            </div>
            <div className="h-full py-2 pt-5 px-4 gap-3 overflow-y-auto flex flex-col overflow-hidden">
              {navlinks.map((item, i) => {
                const active = pageInfo?.page === item?.page;
                return (
                  <div key={i}>
                    <div
                      onClick={() => {
                        setPageInfo(item);
                        navigate(item.page);
                      }}
                      className={`relative flex items-center ${
                        !show && "justify-center"
                      } gap-3 py-2 px-3 font-medium rounded-md cursor-pointer group
            transition-colors ${
              active
                ? "bg-[#FF7BAC] text-white"
                : "hover:bg-[#1B2B65] text-white max-lg:hover:bg-white"
            }
        `}
                    >
                      {item?.extra}
                      {show && (
                        <div className="flex items-center gap-2 ">
                          <p
                            className={`text-white whitespace-nowrap overflow-hidden transition-all text-base `}
                          >
                            {item?.text}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="p-4 flex justify-center items-center">
              {show ? (
                <button
                  className={`text-xs px-6 py-1  text-white h-10 bg-[#017EFA] rounded-lg gap-2 flex justify-center items-center cursor-pointer `}
                  onClick={() => {}}
                >
                  <p className="font-extrabold text-white">Logout</p>
                </button>
              ) : (
                <img
                  onClick={() => {}}
                  src={LogoIconSm}
                  className="h-6 w-6 cursor-pointer"
                  alt="img"
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex-grow relative h-full overflow-hidden bg-[#F2F5FE]">
          <div className="w-full h-20 z-50 sticky top-0 left-0 shadow-md bg-white ">
            <div className="flex h-full px-4 sm:px-5  justify-between items-center">
              <h1 className=" text-base flex gap-2 sm:gap-5 items-center lg:text-xl font-bold">
                <span onClick={() => {}} className="md:hidden">
                  <img src={Logo} className="w-40 h-20" />
                </span>
                <span className="hidden md:inline-block">
                  {pageInfo?.title
                    ? pageInfo?.title
                    : mainpath?.includes?.("dashboard/edit")
                    ? "Edit Profile"
                    : mainpath?.includes?.("dashboard/auth-reset-password")
                    ? "Reset Password"
                    : mainpath?.includes?.("dashboard/choose-a-plan")
                    ? "Manage Subscription"
                    : mainpath?.includes?.("match-profile")
                    ? "User Details"
                    : mainpath?.includes?.("request-profile")
                    ? "Request Details"
                    : "Nirvanae"}
                </span>
              </h1>
              <div className="flex sm:gap-8 gap-4 items-center">
                <div
                  className="flex flex-col gap-1 sm:flex-row sm:gap-2 w-max cursor-pointer items-center "
                  onClick={() => {}}
                >
                  <img
                    src={ASSETS?.Avi}
                    alt="profile"
                    className="h-6 shadow-md rounded-full w-6 object-cover "
                  />
                  <p className="text-xs capitalize hidden md:inline-block font-medium">
                    Super Admin
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="h-full w-full overflow-y-auto p-4 pb-52 sm:py-7 sm:px-10 sm:pb-40 relative">
            <Outlet />
          </div>
        </div>
        {showSm && (
          <div
            onClick={() => {
              setShowSm(false);
            }}
            className={`lg:hidden bg-[#000107] transition-all z-50 absolute bg-opacity-50
            h-full overflow-hidden w-full flex flex-row-reverse`}
          >
            <p
              className="text-2xl text-white font-semibold cursor-pointer top-[0px] absolute right-[16px] opacity-[99] "
              onClick={() => setShowSm(false)}
            >
              x
            </p>
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="absolute left-0 flex flex-col h-full justify-between overflow-hidden w-[60%] bg-[#081A51]"
            >
              <div className="w-full p-4 gap-3 flex items-center justify-center relative">
                <img src={Vector} className="w-8 h-8" alt="vector" />
                {show && (
                  <h2
                    className={`text-white canela text-3xl whitespace-nowrap overflow-hidden transition-all `}
                  >
                    Nirvanae
                  </h2>
                )}
              </div>
              <div className="h-full py-2 pt-5 px-4 gap-3 overflow-y-auto flex flex-col overflow-hidden">
                {navlinks.map((item, i) => (
                  <div key={i}>
                    <SideBarItem
                      icon={item.icon}
                      text={item.text}
                      iconSm={item.iconSm}
                      key={i}
                      textSm={item.textSm}
                      active={pageInfo?.page === item?.page}
                      onClick={() => {
                        setPageInfo(item);
                        navigate(item.page);
                      }}
                      show={show}
                    />
                  </div>
                ))}
              </div>
              <div className="p-4 flex justify-center items-center">
                {show ? (
                  <div
                    className={`bg-[#1B2B65] w-full rounded-lg flex flex-col items-center relative px-4 py-6 transition-all group`}
                  >
                    <img
                      src={Question}
                      className="h-16 absolute -top-6"
                      alt="question"
                    />
                    <div
                      className={`flex justify-between gap-2 items-center flex-col overflow-hidden transition-all `}
                    >
                      <h4 className={`text-sm text-white`}>
                        Done for the day?
                      </h4>
                      <button
                        onClick={() => {}}
                        className={`text-xs px-6 py-1  text-white h-10 bg-[#017EFA] rounded-lg gap-2 flex justify-center items-center cursor-pointer `}
                      >
                        <img
                          src={LogoIconSm}
                          className="h-6 cursor-pointer"
                          alt="logo"
                        />
                        <p className="font-extrabold text-white">Logout</p>
                      </button>
                    </div>
                  </div>
                ) : (
                  <img
                    src={LogoIconSm}
                    onClick={() => {}}
                    className="h-6 w-6 cursor-pointer"
                    alt="img"
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuperAdminDashboardLayout;

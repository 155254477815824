//@ts-nocheck
import { useMutation, useQuery } from "@apollo/client";
import { Disclosure } from "@headlessui/react";
import {
  ArrowBack,
  ArrowBackIosNew,
  Edit,
  ExpandLess,
  ExpandMore,
  KeyboardBackspace,
  Person,
  SearchOutlined,
  Send,
} from "@mui/icons-material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import NoChat from "src/assets/no_chat.png";
import NoMessage from "src/assets/no_message.png";
import { SEND_HANDLER_MESSAGE } from "src/hooks/data/mutations";
import { GET_HANDLER_CONVERSATIONS } from "src/hooks/data/queries";
import { useHandler } from "../auth/hooks/HandlerProvider";
import PageLoader from "../PageLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import ViewProfile from "./ViewProfile";
import { SideBarContext } from "src/context/SidebarProvider";
import ASSETS from "src/asset";
import { handlerChats } from "./mockData";

export default function Chats() {
  const { handler } = useHandler();
  const navigate = useNavigate();
  const {
    data: conversation_data,
    refetch,
    loading: loadChats,
  } = useQuery(GET_HANDLER_CONVERSATIONS, {
    variables: {
      handlerId: Number(handler?.id),
    },
    fetchPolicy: "no-cache",
  });
  const [sendHandlerMessage] = useMutation(SEND_HANDLER_MESSAGE);
  const chats = conversation_data?.handlerConversations || [];
  // const chats = handlerChats || [];
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedMessages, setSelectedMessages] = useState<any>(null);
  const [messages, setMessages] = useState<string>("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dataString = searchParams.get("selectedUserID");
  const [previewUser, setPreviewUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setIsNav } = useContext(SideBarContext);

  useEffect(() => {
    if (dataString && conversation_data?.handlerConversations?.length > 0) {
      if (selectedUser && selectedMessages) return;

      const mainUser = conversation_data?.handlerConversations?.filter?.(
        (convo: any) => Number(convo?.user?.id) === Number(dataString)
      )?.[0];

      setSelectedUser(mainUser?.user);
      setSelectedMessages(mainUser?.handler_messages);
      setSelectedConversation(mainUser);
    }
  }, [dataString, conversation_data, selectedUser, selectedMessages]);
  const [selectedConversation, setSelectedConversation] = useState<any>(null);

  function getCurrentTime(datestring) {
    const date = new Date(datestring);

    let hours = date.getHours();
    let minutes = date.getMinutes();

    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    //@ts-ignore
    minutes = minutes < 10 ? "0" + minutes : minutes;

    const time = hours + ":" + minutes + " " + ampm;

    return time;
  }

  // const getMostRecentDate = (item) => {
  //   return item.message.reduce((latest, msg) => {
  //     const msgDate = new Date(msg.created_at);
  //     return msgDate > latest ? msgDate : latest;
  //   }, new Date(0)); // Start with the earliest possible date
  // };

  const sortMessagesByRecent = (messages) => {
    return messages?.slice?.()?.sort?.((a, b) => {
      const aRecent =
        a?.handler_messages?.length > 0
          ? new Date(
              Math.max(
                ...a?.handler_messages?.map?.((message) =>
                  new Date(message?.created_at)?.getTime?.()
                )
              )
            )
          : new Date(0);
      const bRecent =
        b?.handler_messages?.length > 0
          ? new Date(
              Math.max(
                ...b?.handler_messages?.map?.((message) =>
                  new Date(message?.created_at)?.getTime?.()
                )
              )
            )
          : new Date(0);
      return new Date(bRecent) - new Date(aRecent);
    });
  };
  // const sortMessagesByRecentoo = (messages) => {
  //   console.log(
  //     messages?.slice?.(0, 10)?.sort?.((a, b) => {
  //       const aRecent =
  //         a?.handler_messages?.length > 0
  //           ? new Date(
  //               Math.max(
  //                 ...a?.handler_messages?.map?.((message) =>
  //                   new Date(message?.created_at)?.getTime?.()
  //                 )
  //               )
  //             )
  //           : new Date(0);
  //       const bRecent =
  //         b?.handler_messages?.length > 0
  //           ? new Date(
  //               Math.max(
  //                 ...b?.handler_messages?.map?.((message) =>
  //                   new Date(message?.created_at)?.getTime?.()
  //                 )
  //               )
  //             )
  //           : new Date(0);
  //       return new Date(bRecent) - new Date(aRecent);
  //     })
  //   );
  // };
  // sortMessagesByRecentoo(chats);
  const [displayChatData, setDisplayChatData] = useState([]);

  useEffect(() => {
    setDisplayChatData(sortMessagesByRecent(chats));
  }, [conversation_data]);

  const [searchedData, setSearchedData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const ltv = searchValue?.trim?.()?.toLowerCase?.();
    if (ltv?.length > 0) {
      const filtered = displayChatData?.filter?.(
        (i) =>
          i?.user?.first_name?.toLowerCase?.()?.includes?.(ltv) ||
          i?.user?.last_name?.toLowerCase?.()?.includes?.(ltv)
      );
      setSearchedData(filtered);
    } else {
      setSearchedData(displayChatData);
    }
  }, [displayChatData, searchValue]);

  const handleSentMessage = () => {
    // let media_ids = [];
    // if (selectedFileState.length > 0) {
    //   //save to s3 and get media_ids
    //   // save in media_ids
    // }
    setLoading(true);
    sendHandlerMessage({
      variables: {
        message: {
          message_sender: "HANDLER",
          subject: "Nirvanae",
          body: messages,
          handler_conversation_id: Number(selectedConversation?.id),
          //   media_ids,
        },
      },
      onCompleted: (data) => {
        refetch();
        //  console.log({ data, selectedMessages });
        setMessages("");
        setSelectedMessages((prev) => [
          ...prev,
          data.createOutgoingHandlerMessage,
        ]);
        setLoading(false);
        // setMessageView([...messageView, message]);
        // setHandlerResponse(true);
        // toggleMessageView(!showMessage);
        // setMessage({ id: 0, header: "", message: "", isHandler: false });
        // setSelectedFileState([{ file: "" }]);
      },
      onError: (error) => {
        console.log({ error });
        setLoading(false);
      },
    });
  };
  useEffect(() => {
    if (selectedUser) {
      setIsNav(false);
    } else {
      setIsNav(true);
    }
    return () => {
      setIsNav(true);
    };
  }, [selectedUser]);
  return (
    <>
      {previewUser ? (
        <div className="space-y-5">
          <h2
            onClick={() => {
              setPreviewUser(false);
            }}
            className="text-sm w-max flex gap-2 items-center cursor-pointer hover:text-blue-600 font-bold text-[#1C1F37]"
          >
            <KeyboardBackspace fontSize="small" />
            Go Back to Chats
          </h2>
          <ViewProfile user={selectedUser} isHandler />
        </div>
      ) : (
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden ">
          {loadChats ? (
            <PageLoader />
          ) : chats?.length > 0 ? (
            <div className="flex w-full h-full overflow-hidden">
              <div className="w-80 hidden lg:inline-block pb-32 bg-white flex-shrink-0 h-full overflow-hidden">
                <div className="p-2 relative">
                  <input
                    type="text"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Search"
                    className="text-sm w-full pl-7 rounded-lg"
                  />
                  <p
                    style={{ top: "50%", transform: "translateY(-50%)" }}
                    className="w-max text-base text-gray-400 absolute left-4 top-1/2"
                  >
                    <SearchOutlined fontSize="inherit" />
                  </p>
                </div>
                <div className="w-full divide-y divide-gray-200 h-full overflow-y-auto">
                  {searchedData?.length > 0 ? (
                    searchedData?.slice?.()?.map?.((conversation, index) => {
                      const dv = conversation?.handler_messages?.sort?.(
                        (a, b) =>
                          //@ts-ignore
                          new Date(b?.created_at) - new Date(a?.created_at)
                      );

                      const lastM = dv?.length > 0 ? dv?.[0] : null;
                      return (
                        <div
                          onClick={() => {
                            setSelectedUser(conversation?.user);
                            setSelectedMessages(conversation?.handler_messages);
                            setSelectedConversation(conversation);
                          }}
                          key={index}
                          className={`py-6 px-3 cursor-pointer ${
                            selectedUser?.id === conversation?.user?.id
                              ? "bg-[#CAD5FE]"
                              : "hover:bg-gray-100"
                          } flex justify-between items-center`}
                        >
                          <div className="flex gap-2 items-center">
                            <div
                              className={`w-8 h-8 ${
                                selectedUser?.id === conversation?.user?.id &&
                                "bg-white"
                              } border relative flex-shrink-0 flex justify-center items-center border-gray-300 shadow-md rounded-full`}
                            >
                              {
                                <img
                                  src={
                                    conversation?.user?.profile_image_url ??
                                    ASSETS?.Avi
                                  }
                                  className="w-full h-full rounded-full object-cover"
                                  alt="user"
                                />
                              }
                              <div
                                className={`absolute w-2 h-2 rounded-full ${
                                  conversation?.user?.is_online?.toLowerCase?.() ===
                                  "online"
                                    ? "bg-green-500"
                                    : "bg-gray-400"
                                } bottom-0 right-0`}
                              ></div>
                            </div>

                            <div>
                              <h2 className="text-sm capitalize font-bold">
                                {conversation?.user?.first_name +
                                  " " +
                                  conversation?.user?.last_name}
                              </h2>
                              <p className="text-xs font-medium">
                                {lastM
                                  ? lastM?.body?.length > 23
                                    ? `${
                                        lastM?.message_sender === "USER"
                                          ? ""
                                          : "You: "
                                      }${lastM?.body?.slice?.(0, 23)}...`
                                    : `${
                                        lastM?.message_sender === "USER"
                                          ? ""
                                          : "You: "
                                      }${lastM?.body}`
                                  : null}
                              </p>
                            </div>
                          </div>
                          {lastM && (
                            <p className="text-xs flex-shrink-0">
                              {getCurrentTime(lastM?.created_at)}
                            </p>
                          )}
                          {/* {conversation?.unread_messages > 0 ? (
                          <div
                            style={{
                              background: "linear-gradient(#FD749B, #281AC8)",
                            }}
                            className="w-6 h-6 text-white rounded-full flex justify-center items-center text-xs font-semibold"
                          >
                            {conversation?.unread_messages}
                          </div>
                        ) : null} */}
                        </div>
                      );
                    })
                  ) : (
                    <div className=" items-center justify-center w-full h-60 bg-white flex">
                      <div className=" flex gap-5 flex-col justify-center items-center">
                        <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                          <img
                            src={NoMessage}
                            alt="empty"
                            className="w-5 md:w-10 h-5 md:h-10 object-contain"
                          />
                        </div>
                        <h2 className="text-sm text-center font-bold text-[#081A51] ">
                          No data available
                        </h2>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex-grow overflow-y-auto h-full">
                {selectedUser ? (
                  <>
                    <div className="divide-y w-full divide-gray-300 lg:hidden pb:32 bg-white ">
                      <div className="flex gap-3 p-5  items-center">
                        <span
                          onClick={() => {
                            setSelectedUser(null);
                            navigate("/dashboard/handler/chat");
                          }}
                          className="lg:hidden "
                        >
                          <ArrowBack
                            fontSize="inherit"
                            className="lg:hidden"
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                        <div
                          onClick={() => setPreviewUser(true)}
                          className="flex gap-2 w-max sm:gap-5 items-center"
                        >
                          <div className="text-sm sm:text-lg w-8 sm:w-10 h-8 flex justify-center items-center shadow-md border-[#081A51] sm:h-10 rounded-full border">
                            {
                              <img
                                src={
                                  selectedUser?.profile_image_url ?? ASSETS?.Avi
                                }
                                className="w-full h-full rounded-full object-cover"
                                alt="user"
                              />
                            }
                          </div>
                          <div className="md:space-y-1">
                            <h2 className="text-sm capitalize sm:text-sm font-bold">
                              {selectedUser?.first_name
                                ? `${
                                    selectedUser?.first_name
                                  }, ${selectedUser?.last_name?.charAt?.(0)}`
                                : ""}
                            </h2>
                            <p className="text-10 sm:text-xs">
                              {selectedUser?.status ?? ""}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="space-y-5 px-5 sm:px-10 py-5">
                        <Disclosure
                          as="div"
                          className={
                            "bg-[#f1f2faa2] border-gray-300 rounded-lg border"
                          }
                          defaultOpen={false}
                        >
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="w-full">
                                <div
                                  className={`flex w-full ${
                                    open ? "rounded-t-lg" : "rounded-lg"
                                  } cursor-pointer items-center text-sm sm:text-lg justify-between bg-white shadow-md p-4`}
                                >
                                  <div className="flex gap-5 text-sm sm:text-lg items-center">
                                    <Edit fontSize="inherit" />
                                    <p className="text-xs sm:text-sm font-bold">
                                      Reply{" "}
                                      {selectedUser?.first_name +
                                        " " +
                                        selectedUser?.last_name}
                                    </p>
                                  </div>

                                  {open ? (
                                    <ExpandLess fontSize="inherit" />
                                  ) : (
                                    <ExpandMore fontSize="inherit" />
                                  )}
                                </div>
                              </Disclosure.Button>

                              <Disclosure.Panel className="p-5">
                                <div className="space-y-5">
                                  <div className="space-y-2">
                                    <label className="block text-xs font-medium">
                                      Message
                                    </label>
                                    <textarea
                                      className="resize-none w-full text-xs bg-white h-40 p-5 rounded-lg border border-[#102A42] sm:text-sm"
                                      placeholder={"Input subject here"}
                                      value={messages}
                                      onChange={(e) => {
                                        setMessages(e.target.value);
                                      }}
                                    ></textarea>
                                  </div>
                                  {/* <div className="space-y-1">
                            <div className="flex justify-between items-start">
                              <div className="w-1/2 text-[#102A42] space-y-2">
                                {allFiles?.map((item, index) => (
                                  <div
                                    key={index}
                                    className="flex gap-5 items-center"
                                  >
                                    <label className="text-sm divide-x divide-[#102A42] cursor-pointer gap-3 flex font-semibold border border-[#102A42] ">
                                      <span className="px-4 py-2.5 flex-shrink-0 ">
                                        Select File
                                      </span>
                                      <input
                                        type="file"
                                        multiple={false}
                                        onChange={(e) => {
                                          setAllFiles((prev) =>
                                            prev?.map((i) => {
                                              if (i?.id === item?.id) {
                                                // @ts-ignore
                                                i.file = e.target.files?.[0];
                                                return i;
                                              }
                                              return i;
                                            })
                                          );
                                          e.stopPropagation();
                                        }}
                                        className="hidden"
                                      />
                                      <p className="px-4 block truncate py-2.5">
                                        {item?.file
                                          ? // @ts-ignore
                                            item?.file?.name
                                          : "No file choosen"}
                                      </p>
                                    </label>
                                    {allFiles?.length !== 1 && (
                                      <div
                                        onClick={() => {
                                          setAllFiles((prev) =>
                                            prev?.filter(
                                              (i) => i?.id !== item?.id
                                            )
                                          );
                                        }}
                                        className="w-8 h-8 flex-shrink-0 flex justify-center text-sm items-center hover:shadow-sm rounded-full bg-gray-200 hover:text-[#102A42] cursor-pointer"
                                      >
                                        <Delete fontSize="small" />
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                              <button
                                onClick={() => {
                                  setAllFiles((prev) => [
                                    ...prev,
                                    { id: prev?.length + 1, file: null },
                                  ]);
                                }}
                                className="flex text-[#102A42] gap-2 items-center border-none outline-none text-sm font-semibold"
                              >
                                <Add />
                                Add More
                              </button>
                            </div>
                            <p className="text-10">
                              Allowed File Extensions: .jpg, .gif, .jpeg, .png,
                              .pdf, zip, .doc, .docx (Max file size: 128MB)
                            </p>
                          </div> */}
                                  <button
                                    onClick={handleSentMessage}
                                    disabled={loading}
                                    className="px-6 w-full sm:w-max justify-center rounded-lg py-2.5 bg-[#017EFA] text-white flex gap-2 items-center hover:opacity-70 text-sm sm:text-base disabled:bg-gray-300 disabled:text-[#017EFA] hover:shadow-md "
                                  >
                                    {loading ? (
                                      <BeatLoader size={10} color="#081A51" />
                                    ) : (
                                      <>
                                        Send
                                        <Send fontSize="inherit" />
                                      </>
                                    )}
                                  </button>
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                        {selectedMessages
                          ?.slice?.()
                          ?.sort?.(
                            (a: any, b: any) =>
                              //@ts-ignore
                              new Date(b?.created_at) - new Date(a?.created_at)
                          )
                          ?.map((message: any, index: number) => (
                            <Message
                              selectedUser={selectedUser}
                              selectedMessage={message}
                              setPreview={setPreviewUser}
                              key={index}
                            />
                          ))}
                      </div>
                      <div className="py-10"></div>
                    </div>
                    <div className="space-y-5 w-full hidden lg:inline-block p-5 pb-32">
                      <Disclosure
                        as="div"
                        className={
                          "bg-[#f1f2faa2] border-gray-300 rounded-lg border"
                        }
                        defaultOpen={false}
                      >
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="w-full">
                              <div
                                className={`flex w-full ${
                                  open ? "rounded-t-lg" : "rounded-lg"
                                } cursor-pointer items-center justify-between bg-white shadow-md p-4`}
                              >
                                <div className="flex gap-5 items-center">
                                  <Edit />
                                  <p className="text-sm font-bold">
                                    Reply{" "}
                                    {selectedUser?.first_name +
                                      " " +
                                      selectedUser?.last_name}
                                  </p>
                                </div>

                                {open ? <ExpandLess /> : <ExpandMore />}
                              </div>
                            </Disclosure.Button>

                            <Disclosure.Panel className="p-5">
                              <div className="space-y-5">
                                <div className="space-y-2">
                                  <label className="block text-xs font-medium">
                                    Message
                                  </label>
                                  <textarea
                                    className="resize-none w-full bg-white h-40 p-5 rounded-lg border border-[#102A42] text-sm"
                                    placeholder={"Input subject here"}
                                    value={messages}
                                    onChange={(e) => {
                                      setMessages(e.target.value);
                                    }}
                                  ></textarea>
                                </div>
                                {/* <div className="space-y-1">
                            <div className="flex justify-between items-start">
                              <div className="w-1/2 text-[#102A42] space-y-2">
                                {allFiles?.map((item, index) => (
                                  <div
                                    key={index}
                                    className="flex gap-5 items-center"
                                  >
                                    <label className="text-sm divide-x divide-[#102A42] cursor-pointer gap-3 flex font-semibold border border-[#102A42] ">
                                      <span className="px-4 py-2.5 flex-shrink-0 ">
                                        Select File
                                      </span>
                                      <input
                                        type="file"
                                        multiple={false}
                                        onChange={(e) => {
                                          setAllFiles((prev) =>
                                            prev?.map((i) => {
                                              if (i?.id === item?.id) {
                                                // @ts-ignore
                                                i.file = e.target.files?.[0];
                                                return i;
                                              }
                                              return i;
                                            })
                                          );
                                          e.stopPropagation();
                                        }}
                                        className="hidden"
                                      />
                                      <p className="px-4 block truncate py-2.5">
                                        {item?.file
                                          ? // @ts-ignore
                                            item?.file?.name
                                          : "No file choosen"}
                                      </p>
                                    </label>
                                    {allFiles?.length !== 1 && (
                                      <div
                                        onClick={() => {
                                          setAllFiles((prev) =>
                                            prev?.filter(
                                              (i) => i?.id !== item?.id
                                            )
                                          );
                                        }}
                                        className="w-8 h-8 flex-shrink-0 flex justify-center text-sm items-center hover:shadow-sm rounded-full bg-gray-200 hover:text-[#102A42] cursor-pointer"
                                      >
                                        <Delete fontSize="small" />
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                              <button
                                onClick={() => {
                                  setAllFiles((prev) => [
                                    ...prev,
                                    { id: prev?.length + 1, file: null },
                                  ]);
                                }}
                                className="flex text-[#102A42] gap-2 items-center border-none outline-none text-sm font-semibold"
                              >
                                <Add />
                                Add More
                              </button>
                            </div>
                            <p className="text-10">
                              Allowed File Extensions: .jpg, .gif, .jpeg, .png,
                              .pdf, zip, .doc, .docx (Max file size: 128MB)
                            </p>
                          </div> */}
                                <button
                                  onClick={handleSentMessage}
                                  disabled={loading}
                                  className="px-6 rounded-lg py-2.5 bg-[#017EFA] text-white flex gap-2 items-center hover:opacity-70 disabled:bg-gray-300 disabled:text-[#017EFA] hover:shadow-md "
                                >
                                  {loading ? (
                                    <BeatLoader size={10} color="#081A51" />
                                  ) : (
                                    <>
                                      Send
                                      <Send fontSize="small" />
                                    </>
                                  )}
                                </button>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                      {selectedMessages
                        ?.slice?.()
                        ?.sort?.(
                          (a: any, b: any) =>
                            //@ts-ignore
                            new Date(b?.created_at) - new Date(a?.created_at)
                        )
                        ?.map((message: any, index: number) => (
                          <Message
                            selectedUser={selectedUser}
                            selectedMessage={message}
                            setPreview={setPreviewUser}
                            key={index}
                          />
                        ))}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="lg:hidden bg-white ">
                      <div className="bg-gray-200 p-4">
                        <h2 className="sm:text-base text-sm font-medium">
                          All Chat Conversations
                        </h2>
                      </div>
                      <div className="p-2 relative">
                        <input
                          type="text"
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                          placeholder="Search"
                          className="text-sm w-full pl-7 rounded-lg"
                        />
                        <p
                          style={{ top: "50%", transform: "translateY(-50%)" }}
                          className="w-max text-base text-gray-400 absolute left-4 top-1/2"
                        >
                          <SearchOutlined fontSize="inherit" />
                        </p>
                      </div>
                      <div className="divide-y p-3 pt-0 pb-40 divide-gray-300">
                        {searchedData?.length > 0 ? (
                          searchedData?.map?.((conversation, index) => {
                            const dv = conversation?.handler_messages?.sort?.(
                              (a, b) =>
                                //@ts-ignore
                                new Date(b?.created_at) -
                                //@ts-ignore
                                new Date(a?.created_at)
                            );

                            const lastM = dv?.length > 0 ? dv?.[0] : null;
                            return (
                              <div
                                onClick={() => {
                                  setSelectedUser(conversation?.user);
                                  setSelectedMessages(
                                    conversation?.handler_messages
                                  );
                                  setSelectedConversation(conversation);
                                }}
                                key={index}
                                className={`py-6 px-3 cursor-pointer ${
                                  selectedUser?.first_name ===
                                  conversation?.user?.first_name
                                    ? "bg-[#CAD5FE]"
                                    : "hover:bg-gray-100"
                                } flex justify-between items-center`}
                              >
                                <div className="flex gap-2 items-center">
                                  <div
                                    className={`w-8 h-8 ${
                                      selectedUser?.first_name ===
                                        conversation?.user?.first_name &&
                                      "bg-white"
                                    } border relative flex-shrink-0 flex justify-center items-center border-gray-300 shadow-md rounded-full`}
                                  >
                                    {
                                      <img
                                        src={
                                          conversation?.user
                                            ?.profile_image_url ?? ASSETS?.Avi
                                        }
                                        className="w-full h-full rounded-full object-cover"
                                        alt="user"
                                      />
                                    }
                                    <div
                                      className={`absolute w-2 h-2 rounded-full ${
                                        conversation?.user?.is_online?.toLowerCase?.() ===
                                        "online"
                                          ? "bg-green-500"
                                          : "bg-gray-400"
                                      } bottom-0 right-0`}
                                    ></div>
                                  </div>

                                  <div>
                                    <h2 className="text-sm capitalize font-bold">
                                      {conversation?.user?.first_name}
                                    </h2>
                                    <p className="text-xs font-medium">
                                      {lastM
                                        ? lastM?.body?.length > 23
                                          ? `${
                                              lastM?.message_sender === "USER"
                                                ? ""
                                                : "You: "
                                            }${lastM?.body?.slice?.(0, 23)}...`
                                          : `${
                                              lastM?.message_sender === "USER"
                                                ? ""
                                                : "You: "
                                            }${lastM?.body}`
                                        : null}
                                    </p>
                                  </div>
                                </div>
                                {lastM && (
                                  <p className="text-xs flex-shrink-0">
                                    {getCurrentTime(lastM?.created_at)}
                                  </p>
                                )}
                                {/* {conversation?.unread_messages > 0 ? (
                                  <div
                                    style={{
                                      background: "linear-gradient(#FD749B, #281AC8)",
                                    }}
                                    className="w-6 h-6 text-white rounded-full flex justify-center items-center text-xs font-semibold"
                                  >
                                    {conversation?.unread_messages}
                                  </div>
                                ) : null} */}
                              </div>
                            );
                          })
                        ) : (
                          <div className=" items-center justify-center w-full h-60 bg-white flex">
                            <div className=" flex gap-5 flex-col justify-center items-center">
                              <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                                <img
                                  src={NoMessage}
                                  alt="empty"
                                  className="w-5 md:w-10 h-5 md:h-10 object-contain"
                                />
                              </div>
                              <h2 className="text-xs text-center font-bold text-[#081A51] ">
                                No data available
                              </h2>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="p-5 hidden lg:block h-full">
                      <div className=" items-center justify-center w-full h-5/6 bg-white flex">
                        <div className=" flex gap-5 flex-col justify-center items-center">
                          <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                            <img
                              src={NoMessage}
                              alt="empty"
                              className="w-5 md:w-10 h-5 md:h-10 object-contain"
                            />
                          </div>
                          <h2 className="text-lg text-center font-bold text-[#081A51] ">
                            Select a chat to get started
                          </h2>
                          <p className="text-sm w-4/5 md:w-3/5 mx-auto text-center">
                            To start a conversation please select a contact from
                            the list of previously contacted matches on the left
                            side of the page to begin a conversation. You can
                            also start a new conversation with any of your
                            recently matched contacts
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="p-5 h-full">
              <div className=" items-center justify-center w-full h-5/6 bg-white flex">
                <div className=" flex gap-5 flex-col justify-center items-center">
                  <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                    <img
                      src={NoChat}
                      alt="empty"
                      className="w-5 md:w-10 h-5 md:h-10 object-contain"
                    />
                  </div>
                  <h2 className="text-lg text-center font-bold text-[#081A51] ">
                    No chats found
                  </h2>
                  <p className="text-sm w-4/5 md:w-3/5 mx-auto text-center">
                    We couldn’t find any chat history records linked to your
                    account. This could be because you have not engage in any
                    conversation with any of assigned user. Start a conversation
                    today to begin bonding with your users
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
const Message = ({ selectedUser, selectedMessage, setPreview }) => {
  return (
    <div className="bg-white divide-y divide-[#017EFA] border-l-4 shadow-md rounded-lg border-[#017EFA]">
      <div className="flex flex-col items-start px-6 py-4 gap-1 justify-between sm:flex-row sm:items-center">
        <div
          onClick={() => setPreview(true)}
          className="flex w-max cursor-pointer hover:text-blue-600 gap-2 sm:gap-5 items-center"
        >
          {selectedMessage?.message_sender === "HANDLER" ? null : (
            <div className="w-8 h-8 border flex justify-center items-center border-gray-300 shadow-md rounded-full">
              {
                <img
                  src={selectedUser?.profile_image_url ?? ASSETS?.Avi}
                  className="w-full h-full rounded-full object-cover"
                  alt="user"
                />
              }
            </div>
          )}
          <h2 className="text-sm sm:text-base capitalize font-semibold">
            {selectedMessage?.message_sender === "HANDLER"
              ? "You"
              : `${
                  selectedUser?.first_name
                }, ${selectedUser?.last_name?.charAt?.(0)}`}
          </h2>
        </div>
        <p className="text-[10px] sm:text-sm font-semibold">
          {moment(selectedMessage?.created_at).format("MMM DD, YYYY, hh:mm a")}
        </p>
      </div>
      <p className="text-xs px-6 py-4 font-medium">{selectedMessage?.body}</p>
    </div>
  );
};

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationsObj } from "src/data/notification";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { useMediaQuery } from "@mui/material";
import { NOTIFICATION_CHANGED } from "src/hooks/data/subscriptions";
import { useSubscription } from "@apollo/client";
import { useUser } from "../auth/hooks/UserProvider";
import moment from "moment";
import { MdOutlineClose } from "react-icons/md";
import { FaBell } from "react-icons/fa6";
interface NotifProps {
  visible: boolean;
  close: () => void;
  user_id: number;
}

const Notifications: React.FC<NotifProps> = ({ visible, close, user_id }) => {
  const { user } = useUser();
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const { data } = useSubscription(NOTIFICATION_CHANGED, {
    variables: { user_id },
    onData: (data) => {
      console.log(data);
    },
  });

  const navigate = useNavigate();

const firstFourNotif = user?.created_notifications?.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
  ?.filter((i: any) => i?.deleted !== true && i?.is_read === false)
  ?.slice(0, 4)
  ;

  return (
    <>
      {visible && (
        <div
          style={{ zIndex: 90 }}
          className={`w-72 xl:w-80 bg-white absolute right-4 shadow-lg   `}
        >
          <div className="w-full py-3 lg:py-4 bg-[#081A51] px-4 flex items-center justify-between">
            <p className="text-xs lg:text-sm font-bold text-white max-lg:text-[10px]">
              Notifications
            </p>
            <MdOutlineClose  onClick={close}  className="text-xl text-white cursor-pointer lg:text-3xl" />
           
          </div>
          {/* mother */}
          <div className="  flex justify-center items-center py-6 lg:py-8   ">
            {/* empty */}
            {
              user?.created_notifications?.length < 0 ?  <div className="flex flex-col justify-center items-center px-2  ">
              <div className="bg-[#F2F4F7] h-8 w-8 rounded-full flex justify-center items-center lg:w-10 lg:h-10 ">
               
                <FaBell  className="lg:text-xl"/>
              </div>
              <p className="text-sm lg:text-base  text-[#222E50] mt-2 font-bold  md:mt-3 xl:mt-4">
                No Notifications to show yet
              </p>
              <p className="text-xs   text-[#222E50] px-2 mt-1 text-center w-72">
                You will see useful notifications here soon. Please check back
                regularly
              </p>
            </div> :  <div className="flex flex-col justify-start items-center px-2 w-full">
             {firstFourNotif.map((item:any, index:number) => {
              return (
                <div
                  key={index}
                  className="flex   gap-3 w-full px-2"
                >
                
                  {/* circle */}
                  <div className='w-max  relative    '>
                    <div className='bg-primary-bg  w-3 h-3 lg:w-4 lg:h-4 rounded-full relative  '>
                     {/*inner  circle */}
                      <div className='bg-white w-[5px] h-[5px] lg:w-2 lg:h-2 rounded-full absolute top-1/2 bottom-1/2  -translate-x-1/2 -translate-y-1/2 left-1/2 right-1/2 flex justify-center items-center '>
                        
                      </div>
                    </div>
                    {/* line */}
                    {index === firstFourNotif.length - 1 ? null : (
                      <div className='absolute  w-1 h-full top-0 bottom-0 -translate-x-1/2   left-1/2 bg-[#F2F4F7] -z-10 '></div>
                    )}
                  </div>
                  {/* text */}
                  <div
                    className=' -mt-1  pb-4   w-full  lg:pb-5   '
                  >
                    {/* text */}
                    <div
                      className='space-y-1'
                    >
                      {/* Header */}
                     
                       <div className="flex justify-between items-center w-full">
                        <p
                          className="text-sm font-bold truncate"
                        >
                          {item?.title}
                        </p>
                         <p className=" w-max  rounded-full text-[8px] sm bg-[#F2F4F7] text-black flex justify-center items-center  px-2 flex-shrink-0 py-1">
                        {moment(item?.created_at).fromNow()}
                      </p>
                      </div>
                      {/* desc */}
                      <p className='text-xs  truncate w-44'>
                        {item.description}
                       
                      </p>
                    </div>
                  </div>
                </div>
              )
            })}
             <div
                className="  mt-2 lg:mt-1  text-xs bg-[#081A51] font-bold  text-white flex items-center justify-center m-auto rounded-md px-6 lg:px-10 hover:bg-opacity-75 py-1 lg:py-2 cursor-pointer"
                onClick={() => navigate("/dashboard/notifications")}
              >
                View All
              </div>
          
            </div>
            }
 
           
          </div>
        
       
        </div>
      )}
    </>
  );
};

export default Notifications;










import React, { useState } from "react";
import InputField from "./Input";
import SingleButton from "./SingleButton";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";

export default function ReportIsuueComponent({
  reportIssueHandler = () => {},
}) {
  const [report, setReport] = useState({
    subject: "",
    message: "",
  });
  const handleReportChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setReport((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const reportdisabled = Object.values(report).some((text) => text === "");
  return (
    <div className="w-full bg-white flex py-4 flex-col rounded-sm px-4 md:px-6 xl:px-8 ">
      <div className="relative space-y-2 self-center ">
        <InputField
          label={"Heading"}
          placeholder="Input Heading here"
          type="text"
          name="subject"
          onChange={handleReportChange}
          value={report.subject}
          labelView
          outBox="w-full  "
          labelStyle="lg:text-sm text-xs rounded-md font-semibold text-[#11142D]"
        />
        <div className="w-full flex flex-col">
          <label className="text-[#11142D] text-xs font-semibold mt-2">
            Message
          </label>
          <textarea
            placeholder="Input Message here"
            name="message"
            className="w-full h-40 sm:h-[220px] border-primary-bg rounded-md mt-2 focus:outline-none focus:border-slate-300 focus:ring-0 text-sm"
            rows={10}
            cols={100}
            onChange={handleReportChange}
            value={report.message}
          />
        </div>
        {/* <div className="w-full mt-16 max-lg:mt-8 flex justify-between">
        <div className="flex flex-col">
          {selectedFileState?.map((sel, i) => (
            <>
              <div className="flex">
                <label
                  htmlFor="fileInput"
                  className="cursor-pointer h-10 w-40 max-lg:w-12 max-lg:text-[9px] max-lg:h-7 max-lg:border-slate-300  border border-[#102A42] flex items-center justify-center mt-2"
                >
                  Select file
                </label>
                <input
                  type="file"
                  id="fileInput"
                  className="absolute opacity-0 cursor-pointer"
                  accept=".jpg, .gif, .jpeg, .png, .pdf, zip, .doc, .docx"
                  onChange={(e) => {
                    handleFileChange(e, i);
                  }}
                />
                <p
                  className="text-slate-700 h-10 w-[400px] truncate
                      max-lg:ml-1 max-lg:w-32 max-lg:text-[9px] max-lg:h-7 max-lg:border-slate-300 border border-[#102A42] flex items-center justify-center mt-2 text-sm whitespace-nowrap"
                >
                  {" "}
                  {sel.file}{" "}
                </p>
              </div>
              <p className="text-xs ml-2 mt-2 max-lg:text-[9px] max-lg:ml-0">
                Allowedz File Extensions: .jpg, .gif, .jpeg, .png,
                .pdf, zip, .doc, .docx (Max file size: 128MB)
              </p>
            </>
          ))}
        </div>
      </div>
      <SingleButton
        icon={
          <AddOutlinedIcon
            style={{
              color: "black",
              marginRight: "3px",
              fontSize: isSmallScreen ? "9px" : "25px",
            }}
          />
        }
        text="Add more"
        className="h-10 w-[170px] absolute flex mt-2 justify-center items-center border-2 border-[#F1F2FA] cursor-pointer shadow-2xl max-lg:h-7 right-1 bottom-[70px] max-lg:w-16 max-lg:bottom-[87px]"
        inlineStyle="text-slate-700 max-lg:text-[9px]"
        onBtnClick={handleAddMore}
      /> */}
        <div className="w-full pt-4">
          <SingleButton
            icon={
              <SendOutlinedIcon
                style={{
                  marginLeft: "10px",
                  color: "white",
                  transform: "rotate(315deg)",
                  fontSize: "14px",
                }}
              />
            }
            text="Send"
            className={`h-8 w-full sm:w-28 flex flex-row-reverse justify-center items-center  shadow ${
              reportdisabled ? "bg-[#c5ddf4]" : "bg-[#017EFA]"
            } cursor-pointer mt-2 shadow-2xl`}
            inlineStyle="text-slate-100 text-xs"
            onBtnClick={() => {
              reportIssueHandler();
            }}
            disabled={reportdisabled}
          />
        </div>
      </div>
      <p className="text-xs  italic text-rose-600 py-2 text-center mt-6">
        Please note that all complaints are confidential and are directly submitted to an overhead supervisor
      </p>
    </div>
  );
}

//@ts-nocheck
import { useMutation, useQuery } from "@apollo/client";
import {
  Add,
  DeleteOutline,
  FilterList,
  KeyboardBackspace,
  Person,
  Search,
} from "@mui/icons-material";
import { Fragment, useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { CREATE_SUGGESTED_MATCHES } from "src/hooks/data/mutations";
import { GET_USERS_TO_SUGGEST } from "src/hooks/data/queries";
import PageLoader from "../PageLoader";
import ViewProfile from "./ViewProfile";
import LinePagination from "src/Paging/LinePagination";
import { Menu, Transition } from "@headlessui/react";
import { AGERANGE, GENDERS, SUBSCRIPTIONS } from "src/helpers/constants";
import { mockData } from "./mockData";
import moment from "moment";
import ASSETS from "src/asset";

const BriefCase =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/brief_case.svg";
const Crown =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/crown.svg";
const NoUser =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/no_user.png";

interface User {
  id: Number;
  img: String;
  first_name: String;
  alais: String;
  occupation: String;
  dob: String;
  gender: String;
  genotype: String;
  phoneNo: String;
  email: String;
  ethnicity: String;
  religion: String;
  location: String;
  pob: String;
  qualifications: String;
  relation_status: String;
  hobbies: String;
  preferred_gender: String;
  preferred_age_range: String;
  preferred_location: String;
  preferred_religion: String;
  preferred_genotype: String;
  preferred_relation_status: String;
  photos: Object;
  about: String;
}
type Alldata = User[];
export default function SuggestUser({ setSuggestUser, user_id }) {
  const [createSuggestedMatches] = useMutation(CREATE_SUGGESTED_MATCHES);

  const [page, setPage] = useState(1);
  const pageSize = 50;

  const { data, loading } = useQuery(GET_USERS_TO_SUGGEST, {
    variables: {
      page: page,
      pageSize: pageSize,
    },
    fetchPolicy: "network-only",
  });

  const [selectedUser, setSelectedUser] = useState<Alldata | []>([]);
  const [allDatas, setAllDatas] = useState<Alldata | []>([]);
  const mainUsers = data?.users?.data || [];
  const metadata = data?.users?.meta;
  // const mainUsers = mockData;
  const [search, setSearch] = useState("");
  const [indexPage, setIndexPage] = useState(0);
  const [viewUser, setViewUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchedData, setSearchedData] = useState<Alldata | []>([]);
  const [filteredData, setFilteredData] = useState<Alldata | []>([]);
  const [isFilter, setIsFilter] = useState(false);
  const calculateAge = (birthdate) => {
    const birthDate = new Date(birthdate);
    const ageDiffMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDiffMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const sendHandler = () => {
    setIsLoading(true);
    createSuggestedMatches({
      variables: {
        payload: {
          suggestions: selectedUser?.map((su) => Number(su.id)),
          target_user_id: Number(user_id),
        },
      },
      onCompleted: (data) => {
        if (data.createHandlerUserMatch?.length > 0) {
          toast.success("Suggestions created");
          setSuggestUser(false);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error("Something went wrong, Try again later");
        }
      },
      onError: (error) => {
        toast.error(error.message ?? "Network Error");
        setIsLoading(false);
        console.log(error);
      },
    });
  };
  const [allFilters, setAllFilters] = useState({
    gender: "",
    age: "",
    location: "",
  });

  const getValues = () => {
    let newObj = {};
    for (let key in allFilters) {
      if (allFilters?.[key]?.trim?.() !== "") {
        newObj[key] = allFilters[key];
      }
    }
    return newObj;
  };

  useEffect(() => {
    const sans = search?.trim?.()?.toLowerCase?.();
    const isOne = Object.values(allFilters)?.some?.((i) => i !== "");
    if (sans && allDatas?.length > 0) {
      const filtered = allDatas?.filter?.(
        (i) =>
          i?.first_name?.toLowerCase?.()?.includes?.(sans) ||
          i?.last_name?.toLowerCase?.()?.includes?.(sans) ||
          i?.email?.toLowerCase?.()?.includes?.(sans)
      );
      return setSearchedData(filtered);
    }
    if (sans && allDatas?.length <= 0 && isOne === false) {
      const filtered = mainUsers?.filter?.(
        (i) =>
          i?.first_name?.toLowerCase?.()?.includes?.(sans) ||
          i?.last_name?.toLowerCase?.()?.includes?.(sans) ||
          i?.email?.toLowerCase?.()?.includes?.(sans)
      );
      return setSearchedData(filtered);
    }
    if (sans && allDatas?.length <= 0 && isOne === true) {
      return setSearchedData([]);
    }
    if (!sans && allDatas?.length > 0) {
      return setSearchedData(allDatas);
    }
    if (!sans && allDatas?.length <= 0 && isOne === true) {
      return setSearchedData([]);
    }
    return setSearchedData(mainUsers);
  }, [search, allDatas, data]);

  const mainLength = selectedUser?.length;
  return (
    <div className="space-y-6 pb-20">
      <h2
        onClick={() => {
          if (indexPage === 0) {
            return setSuggestUser(false);
          }
          setIndexPage((val) => val - 1);
        }}
        className="text-sm w-max flex gap-2 items-center cursor-pointer hover:text-blue-600 font-bold text-[#1C1F37]"
      >
        <KeyboardBackspace fontSize="small" />
        Back {indexPage !== 0 && " to suggest users"}
      </h2>
      <div
        className={`${
          indexPage === 0 && "bg-white rounded-lg divide-gray-200"
        }`}
      >
        {loading ? (
          <div className="py-10">
            <PageLoader />
          </div>
        ) : indexPage === 0 ? (
          <>
            <div className="flex justify-between flex-col gap-5 lg:flex-row items-start p-4 lg:items-center">
              <h2 className="text-base font-bold flex-shrink-0 text-[#213F7D]">
                Select user suggestions
              </h2>
              <div className="flex flex-col-reverse sm:flex-row w-full justify-end gap-2 items-center">
                {selectedUser?.length > 0 && (
                  <button
                    onClick={sendHandler}
                    disabled={isLoading}
                    className={`px-4 py-2 gap-5 w-full sm:w-max  rounded-lg bg-blue-600 text-white font-medium hover:opacity-75 flex-shrink-0 disabled:bg-gray-300 disabled:text-gray-700`}
                  >
                    {isLoading ? (
                      <BeatLoader size={10} color="#081A51" />
                    ) : (
                      `Send Selection (${mainLength})`
                    )}
                  </button>
                )}
                <div className="flex w-full sm:w-max gap-5 items-center">
                  <div className="relative w-full lg:w-52 ">
                    <Search
                      fontSize="small"
                      className="absolute text-gray-400 left-4"
                      style={{ top: "50%", transform: "translateY(-50%)" }}
                    />
                    <input
                      placeholder="Search by name or email"
                      type="text"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      className="bg-gray-100 w-full border border-[#E3E8F1] px-4 pl-10 rounded-lg py-2 text-sm"
                    />
                  </div>
                  <Menu as="div" className="w-max flex-shrink-0 relative">
                    {({ open }) => (
                      <>
                        <Menu.Button
                          as="div"
                          className="w-max text-xs sm:text-base flex gap-2 border border-gray-400 px-3 py-2 sm:py-1.5 cursor-pointer bg-white rounded-md   items-center"
                        >
                          <FilterList fontSize="inherit" />
                          Filter
                        </Menu.Button>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="absolute normal-case p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg z-40 rounded-lg w-60 lg:w-96 top-full right-0"
                          >
                            <div className=" gap-2 grid grid-cols-1 md:grid-cols-2 w-full  py-3 px-2">
                              {/* <div className="gap-2 flex flex-col justify-between">
                                  <p className="text-[10px] sm:text-xs font-medium ">
                                    Date
                                  </p>
                                  <input
                                    type="date"
                                    value={allFilters?.created_at}
                                    onChange={(e) =>
                                      setAllFilters((prev) => ({
                                        ...prev,
                                        created_at: e.target.value,
                                      }))
                                    }
                                    placeholder="Enter date"
                                    className="w-full text-xs sm:text-sm px-4 py-2 rounded-lg"
                                  />
                                </div> */}
                              <div className="gap-2 flex flex-col justify-between">
                                <p className="text-[10px] sm:text-xs font-medium ">
                                  Location
                                </p>
                                <input
                                  type="text"
                                  value={allFilters?.location}
                                  onChange={(e) =>
                                    setAllFilters((prev) => ({
                                      ...prev,
                                      location: e.target.value,
                                    }))
                                  }
                                  placeholder="Enter location"
                                  className="w-full text-xs sm:text-sm px-4 py-2 rounded-lg"
                                />
                              </div>
                              <div className="gap-2 flex flex-col justify-between">
                                <p className="text-[10px] sm:text-xs font-medium ">
                                  Age Range
                                </p>
                                <select
                                  value={allFilters?.age}
                                  onChange={(e) =>
                                    setAllFilters((prev) => ({
                                      ...prev,
                                      age: e.target.value,
                                    }))
                                  }
                                  className="w-full text-xs sm:text-sm px-4 py-2 rounded-lg"
                                >
                                  <option value={""}>Select age range</option>
                                  {AGERANGE?.map?.((sub, index) => (
                                    <option key={index} value={sub}>
                                      {sub}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="gap-2 flex flex-col justify-between">
                                <p className="text-[10px] sm:text-xs font-medium ">
                                  Gender
                                </p>
                                <select
                                  value={allFilters?.gender}
                                  onChange={(e) =>
                                    setAllFilters((prev) => ({
                                      ...prev,
                                      gender: e.target.value,
                                    }))
                                  }
                                  className="w-full text-xs sm:text-sm px-4 py-2 rounded-lg"
                                >
                                  <option value={""}>Select gender</option>
                                  {GENDERS?.map?.((gene, index) => (
                                    <option key={index} value={gene}>
                                      {gene}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="flex w-full flex-col lg:flex-row col-span-1 lg:col-span-2 justify-end pt-4 border-t gap-2 items-center">
                                <Menu.Item>
                                  <button
                                    onClick={() => {
                                      setAllFilters({
                                        location: "",
                                        genotype: "",
                                        age: "",
                                      });
                                      setAllDatas([]);
                                      setIsFilter(false);
                                    }}
                                    className="px-4 py-2 rounded-lg text-center w-full uppercase bg-white text-[#081A51] border border-[#081A51] text-xs font-normal "
                                  >
                                    Reset
                                  </button>
                                </Menu.Item>
                                <Menu.Item>
                                  <button
                                    onClick={() => {
                                      setIsFilter(true);
                                      const majorData = mainUsers;
                                      const newFilteration = majorData.filter(
                                        (person) => {
                                          const age = person?.age?.includes?.(
                                            "-"
                                          )
                                            ? calculateAge(person.age)
                                            : Number(
                                                moment().diff(
                                                  //@ts-ignore
                                                  moment(
                                                    person?.age,
                                                    "DD/MM/YYYY"
                                                  ),
                                                  "years"
                                                )
                                              );
                                          const ageMatch =
                                            (allFilters?.age === "18 - 20" &&
                                              age >= 18 &&
                                              age <= 20) ||
                                            (allFilters?.age === "21 - 30" &&
                                              age >= 21 &&
                                              age <= 30) ||
                                            (allFilters?.age === "31 - 40" &&
                                              age >= 31 &&
                                              age <= 40) ||
                                            (allFilters?.age === "41 - 50" &&
                                              age >= 41 &&
                                              age <= 50) ||
                                            (allFilters?.age === "51 - 60" &&
                                              age >= 51 &&
                                              age <= 60);

                                          const locationMatch =
                                            person?.location_country
                                              .toLowerCase()
                                              .includes(
                                                allFilters?.location?.toLowerCase?.()
                                              ) ||
                                            person?.location_state
                                              .toLowerCase()
                                              .includes(
                                                allFilters?.location?.toLowerCase?.()
                                              );

                                          return (
                                            (!allFilters?.gender ||
                                              person.gender?.toLowerCase?.() ===
                                                allFilters?.gender?.toLowerCase?.()) &&
                                            (!allFilters?.age || ageMatch) &&
                                            (!allFilters?.location ||
                                              locationMatch)
                                          );
                                        }
                                      );
                                      if (
                                        newFilteration?.length <= 0 &&
                                        Object.keys(getValues()).length <= 0
                                      ) {
                                        return setAllDatas([]);
                                      }
                                      setAllDatas(newFilteration);
                                    }}
                                    className="px-4 py-2 rounded-lg text-center w-full uppercase text-white bg-[#081A51] border border-[#081A51] text-xs font-normal "
                                  >
                                    Filter
                                  </button>
                                </Menu.Item>
                              </div>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
            </div>
            <div className="flex px-2 py-4 md:p-4 justify-between items-center">
              <p className="text-[9px] w-1/2 block truncate sm:text-sm">
                {search?.trim?.() &&
                  `Search result for: ${search?.trim?.()?.toUpperCase?.()}`}
              </p>

              <div className="flex flex-col sm:flex-row gap-1 sm:gap-3 items-start sm:items-center">
                {isFilter ? (
                  <>
                    {Object.values(allFilters)?.some?.((i) => i !== "") && (
                      <p className="text-[9px] sm:text-xs">Filter by:</p>
                    )}
                    <div className="flex gap-2 items-center">
                      {Object?.entries(allFilters)?.map?.((item, index) => {
                        if (item?.[1] === "") {
                          return null;
                        }
                        return (
                          <div
                            key={index}
                            className={`flex w-max ${
                              item?.[0]?.toLowerCase?.() === "genotype"
                                ? "bg-purple-100 text-purple-600"
                                : "bg-blue-100 text-blue-600"
                            } capitalize px-2 sm:px-4 rounded-full py-1 items-center`}
                          >
                            <p className="text-[9px] sm:text-xs font-semibold">
                              {item?.[1]}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <p className="text-[9px] italic sm:text-sm">No Filters</p>
                )}
              </div>
            </div>
            {mainUsers?.length > 0 ? (
              <div>
                <div
                  style={{ minHeight: "24rem" }}
                  className="w-full flex flex-col justify-between min-h-96 relative z-10 bg-white overflow-x-auto"
                >
                  <table className="relative w-full divide-y divide-gray-1 overflow-auto  bg-white">
                    <thead className=" rounded-lg bg-gray-50 rounded-t border px-5">
                      <tr className="rounded-t-lg">
                        <th
                          scope="col"
                          className={`px-2 py-4 pl-3 text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                        ></th>
                        <th
                          scope="col"
                          className={`px-2 py-4 text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className={`px-2 py-4  text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                        >
                          Gender
                        </th>
                        <th
                          scope="col"
                          className={`px-2 py-4  text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className={`px-2 py-4 text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                        >
                          Age
                        </th>
                        <th
                          scope="col"
                          className={`px-2 py-4  text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                        >
                          Location
                        </th>
                        <th
                          scope="col"
                          className={`px-2 py-4  text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                        >
                          Subscription
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-300 w-full overflow-auto bg-white px-5 pt-5">
                      {searchedData?.length > 0 ? (
                        searchedData?.map?.((item: any, index: number) => {
                          const location = `${
                            item?.location_state
                              ? `${item?.location_state?.trim?.()}, `
                              : ""
                          }${
                            item?.location_country
                              ? item?.location_country?.trim?.()
                              : ""
                          }`;
                          return (
                            <tr
                              onClick={() => {
                                setViewUser(item);
                                setIndexPage(1);
                              }}
                              className="hover:bg-gray-100"
                              key={index}
                            >
                              <td
                                onClick={(e) => {
                                  return e.stopPropagation();
                                }}
                                className="px-2 pl-5 py-2.5 text-left text-xs font-medium capitalize text-[#2563EB] cursor-pointer"
                              >
                                {selectedUser?.some(
                                  (i) => i?.id === item?.id
                                ) ? (
                                  <span
                                    onClick={() => {
                                      setSelectedUser((prev) =>
                                        prev?.filter((i) => i?.id !== item?.id)
                                      );
                                    }}
                                    className="w-5 h-5 sm:w-7 sm:h-7 cursor-pointer hover:opacity-75 z-30 rounded-full bg-[#EC4141] text-xs sm:text-sm flex text-white justify-center items-center"
                                  >
                                    <DeleteOutline fontSize="inherit" />
                                  </span>
                                ) : (
                                  <span
                                    onClick={() => {
                                      // @ts-ignore
                                      setSelectedUser((prev) => [
                                        ...prev,
                                        item,
                                      ]);
                                    }}
                                    className="w-5 h-5 sm:w-7 sm:h-7 cursor-pointer hover:opacity-75 z-30 rounded-full bg-[#1B7297] text-xs sm:text-sm flex text-white justify-center items-center"
                                  >
                                    <Add fontSize="inherit" />
                                  </span>
                                )}
                              </td>
                              <td className="px-2 py-2.5 whitespace-nowrap text-left text-xs font-semibold capitalize text-[#545F7D]">
                                <div className="flex gap-2 items-center">
                                  <img
                                    src={item?.profile_image_url ?? ASSETS?.Avi}
                                    className="rounded-full flex-shrink-0 object-cover w-7 h-7 sm:w-9 sm:h-9"
                                    alt="person"
                                  />
                                  {item?.last_name?.toLowerCase?.() +
                                    " " +
                                    item?.first_name?.toLowerCase?.()}
                                </div>
                              </td>
                              <td className="px-2 py-2.5 whitespace-nowrap text-left text-xs font-normal lowercase text-[#545F7D]">
                                {item?.gender?.toLowerCase?.()}
                              </td>
                              <td className="px-2 py-2.5 whitespace-nowrap text-left text-xs font-normal lowercase text-[#545F7D]">
                                {item?.status?.toLowerCase?.()}
                              </td>
                              <td className="px-2 py-2.5 text-left whitespace-nowrap text-xs font-normal  text-[#545F7D]">
                                {item?.age?.includes?.("-")
                                  ? calculateAge(item?.age)
                                    ? calculateAge(item?.age)
                                    : "N/A"
                                  : moment().diff(
                                      //@ts-ignore
                                      moment(item?.age, "DD/MM/YYYY"),
                                      "years"
                                    ) ?? "N/A"}
                              </td>
                              <td className="px-2 py-2.5 text-left whitespace-nowrap text-xs font-normal capitalize text-[#545F7D]">
                                {location?.length > 20
                                  ? `${location?.slice(0, 20)}...`
                                  : location}
                              </td>
                              <td className="px-2 py-2.5 text-left whitespace-nowrap text-xs font-normal capitalize text-[#545F7D]">
                                {item?.subscription?.name === "basic" ? (
                                  <p className="text-[10px] px-4 py-0.5 w-max text-center rounded-full bg-gray-300 text-gray-800">
                                    {item?.subscription?.name}
                                  </p>
                                ) : (
                                  <p className="text-[10px] px-4 py-0.5 w-max text-center rounded-full bg-purple-300 text-purple-800">
                                    {item?.subscription?.name}
                                  </p>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={8}>
                            <div className=" items-center justify-center w-full h-80 bg-white flex">
                              <div className="h-full flex gap-5 flex-col justify-center items-center">
                                <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                                  <img
                                    src={NoUser}
                                    alt="empty"
                                    className="w-5 md:w-10 h-5 md:h-10 object-contain"
                                  />
                                </div>
                                <h2 className="text-lg text-center font-bold text-[#081A51] ">
                                  No users found
                                </h2>
                                <p className="text-sm w-4/5 mx-auto text-center">
                                  No data for the selections, kindly change your
                                  filters
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="p-4 flex gap-5 items-center justify-end">
                    <button
                      onClick={() => {
                        setAllFilters({
                          location: "",
                          genotype: "",
                          age: "",
                        });
                        setAllDatas([]);
                        setIsFilter(false);
                        setPage(page - 1);
                      }}
                      disabled={page <= 1}
                      className="px-4 py-2 text-xs border border-gray-400 flex gap-2 items-center rounded-lg disabled:bg-gray-400 disabled:text-gray-700"
                    >
                      Previous
                    </button>
                    <span className="text-sm">
                      Page{" "}
                      <span className=" text-blue-800 font-semibold">
                        {metadata?.currentPage}
                      </span>{" "}
                      of {metadata?.totalPages}
                    </span>
                    <button
                      onClick={() => {
                        setAllFilters({
                          location: "",
                          genotype: "",
                          age: "",
                        });
                        setAllDatas([]);
                        setIsFilter(false);
                        setPage(page + 1);
                      }}
                      disabled={page >= metadata?.totalPages}
                      className="px-4 py-2 text-xs border border-gray-400 flex gap-2 items-center rounded-lg disabled:bg-gray-400 disabled:text-gray-700"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className=" items-center rounded-lg justify-center w-full py-20 bg-white flex">
                <div className="h-full flex gap-5 flex-col justify-center items-center">
                  <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                    <img
                      src={NoUser}
                      alt="empty"
                      className="w-5 md:w-10 h-5 md:h-10 object-contain"
                    />
                  </div>
                  {
                    <>
                      <h2 className="text-sm lg:text-lg text-center font-bold text-[#081A51] ">
                        You have no user to suggest
                      </h2>
                      <p className="text-xs lg:text-sm w-4/5 pb-5 md:w-3/5 mx-auto text-center">
                        You have exhausted your user suggestions. Kindly check
                        this space frequently to see all user available for
                        suggestions. We welcome you to chat with your handler if
                        you have any complaints or inquiries
                      </p>
                    </>
                  }
                </div>
              </div>
            )}
          </>
        ) : (
          <ViewProfile
            user={viewUser}
            isSetSelected
            handleClick={() => {
              if (selectedUser?.some((i) => i?.id === viewUser?.id)) {
                return setSelectedUser((prev) =>
                  prev?.filter((i) => i?.id !== viewUser?.id)
                );
              }
              // @ts-ignore
              setSelectedUser((prev) => [...prev, viewUser]);
              return;
            }}
            userSelected={selectedUser?.some((i) => i?.id === viewUser?.id)}
            isHandler
          />
        )}
      </div>
    </div>
  );
}

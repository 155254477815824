import { useQuery } from "@apollo/client";
import { Menu, Transition } from "@headlessui/react";
import { FilterList } from "@mui/icons-material";
import moment from "moment";
import { Fragment, useState } from "react";
import LinePagination from "src/Paging/LinePagination";
import { HANDLER_USERS } from "../auth/data/queries";
import PageLoader from "../PageLoader";
import { calculateAge } from "src/helpers/utils";

interface User {
  last_name: String;
  first_name: String;
  email: String;
  phone: String;
  date_joined: String;
  status: String;
  gender: String;
  genotype: String;
  relationship_status: String;
  ethinicity: String;
  date_of_birth: Date;
  username: String;
  religion: String;
  occupation: String;
  location: String;
  country: String;
  place_of_birth: String;
  nationality: String;
  qualification: String;
  hobbies: String;
  preferred_gender: String;
  preferred_location: String;
  preferred_religion: String;
  preferred_genotype: String;
  preferred_status: String;
  preferred_age: String;
}
type Alluserdata = User[];
export default function UsersReadOnly({ handler_id }) {
  const { data, loading } = useQuery(HANDLER_USERS, {
    variables: {
      handlerId: Number(handler_id?.id),
    },
    fetchPolicy: "cache-and-network",
  });
  const [allDatas, setAllDatas] = useState<Alluserdata | []>([]);
  return (
    <div className="w-full h-full overflow-hidden pl-5">
      {loading ? (
        <PageLoader />
      ) : (
        <div className="space-y-7 h-full w-full pb-40 pt-5 overflow-auto bg-white">
          <h2 className="text-xl font-semibold px-5">Users assigned</h2>
          <LinePagination
            data={data?.handlerUsers}
            setCurrentItems={setAllDatas}
          >
            <div className="relative w-full border overflow-auto">
              <table className="relative w-full divide-y divide-gray-1 overflow-auto  bg-white">
                <thead className=" rounded-lg rounded-t px-5">
                  <tr className="rounded-t-lg">
                    <th
                      scope="col"
                      className={`px-2 py-4 pl-5 text-left text-xs font-medium uppercase`}
                    >
                      <div className="flex gap-2 items-center">
                        Last name
                        {/* <Menu as="div" className="w-max relative">
                          {({ open }) => (
                            <>
                              <Menu.Button as="div" className="w-max">
                                <FilterList
                                  fontSize="small"
                                  className="text-blue-600 cursor-pointer shadow-md"
                                />
                              </Menu.Button>
                              <Transition
                                show={open}
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items
                                  static
                                  className="absolute normal-case z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-60 top-full left-0"
                                >
                                  <div className="space-y-3 py-3 px-2">
                                    <div className="space-y-2">
                                      <p className="text-xs font-medium ">
                                        Firstname
                                      </p>
                                      <input
                                        type="text"
                                        placeholder="Enter firstname"
                                        className="w-full text-sm px-4 py-2 rounded-lg"
                                      />
                                    </div>
                                    <div className="space-y-2">
                                      <p className="text-xs font-medium ">
                                        Lastname
                                      </p>
                                      <input
                                        type="text"
                                        placeholder="Enter lastname"
                                        className="w-full text-sm px-4 py-2 rounded-lg"
                                      />
                                    </div>
                                    <div className="space-y-2">
                                      <p className="text-xs font-medium ">
                                        Email
                                      </p>
                                      <input
                                        type="email"
                                        placeholder="example@email.com"
                                        className="w-full text-sm px-4 py-2 rounded-lg"
                                      />
                                    </div>
                                    <div className="space-y-2">
                                      <p className="text-xs font-medium ">
                                        Date
                                      </p>
                                      <input
                                        type="date"
                                        placeholder="Enter date"
                                        className="w-full text-sm px-4 py-2 rounded-lg"
                                      />
                                    </div>
                                    <div className="space-y-2">
                                      <p className="text-xs font-medium ">
                                        Phone Number
                                      </p>
                                      <input
                                        type="text"
                                        placeholder="Enter phone"
                                        className="w-full text-sm px-4 py-2 rounded-lg"
                                      />
                                    </div>
                                    <div className="space-y-2">
                                      <p className="text-xs font-medium ">
                                        Status
                                      </p>
                                      <select className="w-full text-sm px-4 py-2 rounded-lg">
                                        <option value={""}>
                                          Select status
                                        </option>
                                        <option value={"Pending"}>
                                          Pending
                                        </option>
                                        <option value={"Active"}>Active</option>
                                        <option value={"Inactive"}>
                                          Inactive
                                        </option>
                                        <option value={"Blacklisted"}>
                                          Blacklisted
                                        </option>
                                      </select>
                                    </div>
                                    <div className="flex pt-4 border-t gap-2 items-center">
                                      <button className="px-4 py-2 rounded-lg text-center w-full uppercase bg-white text-[#081A51] border border-[#081A51] text-xs font-normal ">
                                        Reset
                                      </button>
                                      <button className="px-4 py-2 rounded-lg text-center w-full uppercase text-white bg-[#081A51] border border-[#081A51] text-xs font-normal ">
                                        Filter
                                      </button>
                                    </div>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </>
                          )}
                        </Menu> */}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className={`px-2 py-4 text-left text-xs font-medium uppercase`}
                    >
                      First name
                    </th>
                    <th
                      scope="col"
                      className={`px-2 py-4 text-left text-xs font-medium uppercase`}
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className={`px-2 py-4 text-left text-xs font-medium uppercase`}
                    >
                      Subscription
                    </th>
                    <th
                      scope="col"
                      className={`px-2 py-4 text-left text-xs font-medium uppercase`}
                    >
                      Age
                    </th>
                    {/* <th
                      scope="col"
                      className={`px-2 py-4 text-left text-xs font-medium uppercase`}
                    >
                      <div className="flex gap-2 items-center">
                        Status
                        <FilterList fontSize="small" />
                      </div>
                    </th> */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-300 overflow-auto bg-white px-5 pt-5">
                  {data?.handlerUsers?.map?.((item: any, index: number) => (
                    <tr className="hover:bg-gray-50" key={index}>
                      <td className="px-2 pl-5 py-2.5 text-left text-xs font-normal capitalize text-[#545F7D]">
                        {item?.last_name}
                      </td>
                      <td className="px-2 py-2.5 text-left text-xs font-normal capitalize text-[#545F7D]">
                        {item?.first_name}
                      </td>
                      <td className="px-2 py-2.5 text-left text-xs font-normal lowercase text-[#545F7D]">
                        {item?.email}
                      </td>

                      <td className="px-2 py-2.5 text-left text-xs font-normal capitalize text-[#545F7D]">
                        {item?.subscription?.name === "basic" ? (
                          <p className="text-[10px] px-4 py-0.5 w-max text-center rounded-full bg-gray-100 border border-gray-800 text-gray-800">
                            {item?.subscription?.name}
                          </p>
                        ) : item?.plan?.toLowerCase?.() === "monthly" ? (
                          <p className="text-[10px] px-4 py-0.5 w-max text-center rounded-full bg-purple-100 border border-purple-800 text-purple-800">
                            {item?.plan}
                          </p>
                        ) : (
                          <p className="text-[10px] px-4 py-0.5 w-max text-center rounded-full bg-blue-100 border border-blue-800 text-blue-800">
                            {item?.plan}
                          </p>
                        )}
                      </td>
                      <td className="px-2 py-2.5 text-left text-xs font-normal capitalize text-[#545F7D]">
                        {item?.age?.includes?.("-")
                          ? calculateAge(item?.age)
                            ? calculateAge(item?.age)
                            : "N/A"
                          : moment().diff(
                              //@ts-ignore
                              moment(item?.age, "DD/MM/YYYY"),
                              "years"
                            ) ?? "N/A"}
                      </td>
                      {/* <td className="px-2 py-2.5 text-left text-xs font-normal capitalize text-[#545F7D]">
                        <p
                          className={`px-4 py-1 w-max rounded-full ${
                            item?.status?.toLowerCase?.() === "inactive"
                              ? "bg-[#545f7d3b] text-black"
                              : item?.status?.toLowerCase?.() === "pending"
                              ? "bg-[#e9b30042] text-[#E9B200]"
                              : item?.status?.toLowerCase?.() === "active"
                              ? "bg-[#39cd633f] text-[#39CD62] "
                              : "bg-[#e4033b41] text-[#E4033B]"
                          } text-center`}
                        >
                          {item?.status}
                        </p>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </LinePagination>
        </div>
      )}
    </div>
  );
}

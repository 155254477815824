import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";

export default function MainConfetti() {
  const [dimension, setDimension] = useState({
    width: window?.innerWidth,
    height: window?.innerHeight,
  });

  const showAnimation = () => {
    setDimension({ width: window?.innerWidth, height: window?.innerHeight });
  };
  useEffect(() => {
    window.addEventListener("resize", showAnimation);
    return () => {
      window.removeEventListener("resize", showAnimation);
    };
  }, [dimension]);
  return <Confetti width={dimension?.width} height={dimension?.height} />;
}

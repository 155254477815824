import { useMutation } from "@apollo/client";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { useMediaQuery } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Notif from "src/assets/notif.svg";
import { useUser } from "src/components/auth/hooks/UserProvider";
import Notifications from "src/components/Notification/notifications";
import SingleButton from "src/components/SingleButton";
import { SideBarItemObj } from "src/data/sidebar_item";
import {
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from "src/hooks/data/mutations";
import { toast } from "react-toastify";
import Loading from "src/components/Loading";
import { FaBell } from "react-icons/fa6";

const Message = () => {
  const location: any = useLocation();
  const { user, userRefetch } = useUser();
  const msg = location?.state?.msg || null;

  const [showNotifications, setShowNotifications] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const [deleteNotification] = useMutation(DELETE_NOTIFICATION);
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const back = () => navigate("/dashboard/notifications");

  const deleteNotificationHandler = () => {
    setLoading(true);
    deleteNotification({
      variables: {
        deleteNotificationId: Number(msg?.id),
      },
      onCompleted: () => {
        setLoading(false);
        toast.success("Notification deleted successfully");
        userRefetch();
        back();
      },
      onError: (error) => {
        setLoading(false);
        toast.error("An error occurred while deleting notification");
      },
    });
  };

  const updateNotif = () => {
    updateNotification({
      variables: {
        payload: {
          id: Number(msg?.id),
          is_read: true,
        },
      },
      onCompleted: () => {
        userRefetch();
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  useEffect(() => {
    updateNotif();
  }, []);
  return (
    <div className="">
      {loading && <Loading />}
      <div className="">
        <div className="w-full flex justify-between">
          <p
            onClick={back}
            className=" w-max flex gap-2 cursor-pointer items-center font-semibold"
          >
            <KeyboardBackspaceOutlinedIcon
              style={{
                fontSize: isSmallScreen ? "16px" : "30px",
                cursor: "pointer",
              }}
            />
            <span className=" sm:text-sm text-[10px]">
              Back to notifications
            </span>
          </p>
          <SingleButton
            className="bg-[#081A51] w-32 h-10 max-lg:w-24 max-lg:h-8 rounded-md flex justify-center items-center cursor-pointer"
            text="Delete Message"
            inlineStyle="text-white text-xs max-lg:text-[10px]"
            onBtnClick={deleteNotificationHandler}
          />
        </div>
        <div className="bg-white w-full mt-8 space-y-5 rounded-md p-4">
          <div className="w-full flex justify-between">
            <div className="flex gap-2 items-center">
              <div className="bg-[#F2F4F7] h-8 w-8 rounded-full flex justify-center items-center lg:w-10 lg:h-10 ">
               
                <FaBell  className="lg:text-xl"/>
              </div>
              <h2 className="font-bold capitalize md:text-base text-xs ">
                {msg?.title}
              </h2>
            </div>
            <p>
              <DateRangeOutlinedIcon
                style={{ fontSize: isSmallScreen ? "12px" : "14px" }}
              />
              <span className="font-semibold text-xs ml-2 max-lg:ml-1 max-lg:text-[9px]">
                {moment(msg?.created_at).format("MMM DD, YYYY")}
              </span>
            </p>
          </div>
          <div className="w-full ">
            <p className="text-md max-lg:text-[9px]">
              Dear{" "}
              <span className="font-semibold text-md">{user?.first_name},</span>
            </p>
            <p className="leading-8 max-lg:leading-6 text-xs text-[#11142D] max-lg:text-[9px]">
              {msg?.description}
            </p>
            {/* <p className="mt-4 text-[#11142D] max-lg:text-[10px]">
              [Your Name]{" "}
            </p>
            <p className="mt-3 text-[#11142D] max-lg:text-[10px]">
              Customer Support Team{" "}
            </p> */}
          </div>
        </div>
      </div>

      <Notifications
        visible={showNotifications}
        close={() => setShowNotifications(false)}
        user_id={Number(user?.id)}
      />
    </div>
  );
};

export default Message;

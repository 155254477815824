import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

function valuetext(value: number) {
  return `${value}°C`;
}

export default function RangeSlider({
  styles = {},
  initaialNumber,
  setNumber,
}) {
  const [value, setValue] = React.useState<number[]>(
    initaialNumber ? initaialNumber : [20, 37]
  );

  const handleChange = (event: Event, newValue: number | number[]) => {
    setNumber?.(newValue);
    setValue(newValue as number[]);
  };

  return (
    <Box sx={styles || { width: 300 }}>
      <Slider
        getAriaLabel={() => "Temperature range"}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
      />
    </Box>
  );
}

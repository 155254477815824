import { useQuery } from "@apollo/client";
import { Menu, Transition } from "@headlessui/react";
import {
  FilterList,
  PeopleOutline,
  PersonAddAlt,
  PersonOff,
  PersonRemove,
  Search,
} from "@mui/icons-material";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import NoUser from "src/assets/no_user.png";
import LinePagination from "src/Paging/LinePagination";
import { GET_HANDLER_USERS } from "../auth/data/queries";
import { useHandler } from "../auth/hooks/HandlerProvider";
import PageLoader from "../PageLoader";
import { GENOTYPE, SUBSCRIPTIONS } from "src/helpers/constants";

interface User {
  last_name: String;
  first_name: String;
  email: String;
  phone: String;
  created_at: String;
  status: String;
  gender: String;
  genotype: String;
  relationship_status: String;
  ethinicity: String;
  dob: Date;
  username: String;
  religion: String;
  occupation: String;
  location: String;
  country: String;
  place_of_birth: String;
  nationality: String;
  qualification: String;
  hobbies: String;
  preferred_gender: String;
  preferred_location: String;
  preferred_religion: String;
  preferred_genotype: String;
  preferred_status: String;
  preferred_age: String;
}
type Alluserdata = User[];
export default function AllUsers({ setSelectedUser, setIndexPage }) {
  const { handler } = useHandler();
  const { data, loading } = useQuery(GET_HANDLER_USERS, {
    variables: {
      handlerId: Number(handler?.id),
    },
    fetchPolicy: "no-cache",
  });
  const users = data?.handlerUsers ?? [];

  const [allDatas, setAllDatas] = useState<Alluserdata | []>([]);

  const [searchValue, setSearchValue] = useState("");
  const [searchedData, setSearchedData] = useState<Alluserdata | []>([]);
  const [filteredData, setFilteredData] = useState<Alluserdata | []>([]);
  const [isFilter, setIsFilter] = useState(false);
  const [allFilters, setAllFilters] = useState({
    genotype: "",
    user_plan: "",
    location: "",
  });

  const calculateAge = (birthdate) => {
    const birthDate = new Date(birthdate);
    const ageDiffMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDiffMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const getValues = () => {
    let newObj = {};
    for (let key in allFilters) {
      if (allFilters?.[key]?.trim?.() !== "") {
        newObj[key] = allFilters[key];
      }
    }
    return newObj;
  };

  useEffect(() => {
    const sans = searchValue?.trim?.()?.toLowerCase?.();
    const isOne = Object.values(allFilters)?.some?.((i) => i !== "");
    if (sans && allDatas?.length > 0) {
      const filtered = allDatas?.filter?.(
        (i) =>
          i?.first_name?.toLowerCase?.()?.includes?.(sans) ||
          i?.last_name?.toLowerCase?.()?.includes?.(sans) ||
          i?.email?.toLowerCase?.()?.includes?.(sans)
      );
      return setSearchedData(filtered);
    }
    if (sans && allDatas?.length <= 0 && isOne === false) {
      const filtered = users?.filter?.(
        (i) =>
          i?.first_name?.toLowerCase?.()?.includes?.(sans) ||
          i?.last_name?.toLowerCase?.()?.includes?.(sans) ||
          i?.email?.toLowerCase?.()?.includes?.(sans)
      );
      return setSearchedData(filtered);
    }
    if (sans && allDatas?.length <= 0 && isOne === true) {
      return setSearchedData([]);
    }
    if (!sans && allDatas?.length > 0) {
      return setSearchedData(allDatas);
    }
    if (!sans && allDatas?.length <= 0 && isOne === true) {
      return setSearchedData([]);
    }
    return setSearchedData(users);
  }, [searchValue, allDatas, data]);

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <div className="space-y-10">
          <div className="space-y-4">
            <h2 className="text-base font-bold">User's Summary</h2>
            <div className="grid sm:grid-cols-2 grid-cols-1 lg:grid-cols-4 gap-5">
              <div className="bg-white space-y-3 py-4 px-8 rounded-md">
                <div className="w-10 h-10 rounded-full bg-[#5718FF] flex justify-center items-center text-white">
                  <PeopleOutline fontSize="small" />
                </div>
                <p className="text-sm font-normal">Assigned Users</p>
                <h2 className="text-base font-bold">{users.length}</h2>
              </div>
              <div className="bg-white space-y-3 py-4 px-8 rounded-md">
                <div className="w-10 h-10 rounded-full bg-[#93FFCB] flex justify-center items-center text-black">
                  <PersonAddAlt fontSize="small" />
                </div>
                <p className="text-sm font-normal">Active Users</p>
                <h2 className="text-base font-bold">
                  {
                    users.filter(
                      (e) => e?.user_status?.toUpperCase?.() === "ACTIVE"
                    )?.length
                  }
                </h2>
              </div>
              <div className="bg-white space-y-3 py-4 px-8 rounded-md">
                <div className="w-10 h-10 rounded-full bg-[#CAD5FE] flex justify-center items-center text-black">
                  <PersonOff fontSize="small" />
                </div>
                <p className="text-sm font-normal">Inactive Users</p>
                <h2 className="text-base font-bold">
                  {
                    users.filter(
                      (e) => e?.user_status?.toUpperCase?.() === "INACTIVE"
                    )?.length
                  }
                </h2>
              </div>
              <div className="bg-white space-y-3 py-4 px-8 rounded-md">
                <div className="w-10 h-10 rounded-full bg-[#FF3366] flex justify-center items-center text-white">
                  <PersonRemove fontSize="small" />
                </div>
                <p className="text-sm font-normal">Blacklisted Users</p>
                <h2 className="text-base font-bold">
                  {
                    users.filter(
                      (e) => e?.user_status?.toUpperCase?.() === "BLACKLISTED"
                    )?.length
                  }
                </h2>
              </div>
            </div>
          </div>
          <div className="space-y-4 pb-40">
            <h2 className="text-base font-bold">All users</h2>
            {users?.length > 0 ? (
              <div className="border border-gray-300 rounded-lg">
                <div className=" divide-y divide-gray-300 ">
                  <div className="flex px-2 py-4 md:p-4 justify-between items-center">
                    <div className="relative text-xs sm:text-sm w-1/2 px-0.5">
                      <input
                        id="search-settings"
                        name="search-settings"
                        type="search"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target?.value)}
                        placeholder="Search by name or email"
                        autoComplete="off"
                        className="focus:outline-none block w-full appearance-none rounded-md border border-gray-400 p-2 pl-7 sm:pl-10 text-xs placeholder-gray-500 shadow-sm focus:border-blue-1 focus:ring-blue-1 disabled:bg-gray-300 sm:text-base"
                      />
                      <Search
                        fontSize="inherit"
                        className="absolute left-3 top-1/2 w-5 -translate-y-1/2 transform  text-gray-500"
                      />
                    </div>
                    <Menu as="div" className="w-max relative">
                      {({ open }) => (
                        <>
                          <Menu.Button
                            as="div"
                            className="w-max text-xs sm:text-base flex gap-2 border border-gray-400 px-3 py-2 cursor-pointer bg-white rounded-md  items-center"
                          >
                            <FilterList fontSize="inherit" />
                            Filter
                          </Menu.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="absolute normal-case p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg z-30 rounded-lg w-60 lg:w-96 top-full right-0"
                            >
                              <div className=" gap-2 grid grid-cols-1 md:grid-cols-2 w-full  py-3 px-2">
                                {/* <div className="gap-2 flex flex-col justify-between">
                                  <p className="text-[10px] sm:text-xs font-medium ">
                                    Date
                                  </p>
                                  <input
                                    type="date"
                                    value={allFilters?.created_at}
                                    onChange={(e) =>
                                      setAllFilters((prev) => ({
                                        ...prev,
                                        created_at: e.target.value,
                                      }))
                                    }
                                    placeholder="Enter date"
                                    className="w-full text-xs sm:text-sm px-4 py-2 rounded-lg"
                                  />
                                </div> */}
                                <div className="gap-2 flex flex-col justify-between">
                                  <p className="text-[10px] sm:text-xs font-medium ">
                                    Location
                                  </p>
                                  <input
                                    type="text"
                                    value={allFilters?.location}
                                    onChange={(e) =>
                                      setAllFilters((prev) => ({
                                        ...prev,
                                        location: e.target.value,
                                      }))
                                    }
                                    placeholder="Enter location"
                                    className="w-full text-xs sm:text-sm px-4 py-2 rounded-lg"
                                  />
                                </div>
                                <div className="gap-2 flex flex-col justify-between">
                                  <p className="text-[10px] sm:text-xs font-medium ">
                                    User Plan
                                  </p>
                                  <select
                                    value={allFilters?.user_plan}
                                    onChange={(e) =>
                                      setAllFilters((prev) => ({
                                        ...prev,
                                        user_plan: e.target.value,
                                      }))
                                    }
                                    className="w-full text-xs sm:text-sm px-4 py-2 rounded-lg"
                                  >
                                    <option value={""}>Select plan</option>
                                    {SUBSCRIPTIONS?.map?.((sub, index) => (
                                      <option key={index} value={sub?.name}>
                                        {sub?.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="gap-2 flex flex-col justify-between">
                                  <p className="text-[10px] sm:text-xs font-medium ">
                                    Genotype
                                  </p>
                                  <select
                                    value={allFilters?.genotype}
                                    onChange={(e) =>
                                      setAllFilters((prev) => ({
                                        ...prev,
                                        genotype: e.target.value,
                                      }))
                                    }
                                    className="w-full text-xs sm:text-sm px-4 py-2 rounded-lg"
                                  >
                                    <option value={""}>Select genotype</option>
                                    {GENOTYPE?.map?.((gene, index) => (
                                      <option key={index} value={gene}>
                                        {gene}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="flex w-full flex-col lg:flex-row col-span-1 lg:col-span-2 justify-end pt-4 border-t gap-2 items-center">
                                  <Menu.Item>
                                    <button
                                      onClick={() => {
                                        setAllFilters({
                                          location: "",
                                          genotype: "",
                                          user_plan: "",
                                        });
                                        setAllDatas(users);
                                        setIsFilter(false);
                                      }}
                                      className="px-4 py-2 rounded-lg text-center w-full uppercase bg-white text-[#081A51] border border-[#081A51] text-xs font-normal "
                                    >
                                      Reset
                                    </button>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <button
                                      onClick={() => {
                                        setIsFilter(true);
                                        const majorData = users;
                                        const newFilteration = majorData.filter(
                                          (person) => {
                                            const locationMatch =
                                              person?.location_country
                                                .toLowerCase()
                                                .includes(
                                                  allFilters?.location?.toLowerCase?.()
                                                ) ||
                                              person?.location_state
                                                .toLowerCase()
                                                .includes(
                                                  allFilters?.location?.toLowerCase?.()
                                                );

                                            return (
                                              (!allFilters?.genotype ||
                                                person.genotype?.toLowerCase?.() ===
                                                  allFilters?.genotype?.toLowerCase?.()) &&
                                              // (!allFilters?.age || ageMatch) &&
                                              (!allFilters?.location ||
                                                locationMatch)
                                            );
                                          }
                                        );
                                        if (
                                          newFilteration?.length <= 0 &&
                                          Object.keys(getValues()).length <= 0
                                        ) {
                                          return setAllDatas(majorData);
                                        }
                                        setAllDatas(newFilteration);
                                      }}
                                      className="px-4 py-2 rounded-lg text-center w-full uppercase text-white bg-[#081A51] border border-[#081A51] text-xs font-normal "
                                    >
                                      Filter
                                    </button>
                                  </Menu.Item>
                                </div>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                  <div className="flex px-2 py-4 md:p-4 justify-between items-center">
                    <p className="text-[9px] w-1/2 block truncate sm:text-sm">
                      {searchValue?.trim?.() &&
                        `Search result for: ${searchValue
                          ?.trim?.()
                          ?.toUpperCase?.()}`}
                    </p>

                    <div className="flex flex-col sm:flex-row gap-1 sm:gap-3 items-start sm:items-center">
                      {isFilter ? (
                        <>
                          {Object.values(allFilters)?.some?.(
                            (i) => i !== ""
                          ) && (
                            <p className="text-[9px] sm:text-xs">Filter by:</p>
                          )}
                          <div className="flex gap-2 items-center">
                            {Object?.entries(allFilters)?.map?.(
                              (item, index) => {
                                if (item?.[1] === "") {
                                  return null;
                                }
                                return (
                                  <div
                                    key={index}
                                    className={`flex w-max ${
                                      item?.[0]?.toLowerCase?.() === "genotype"
                                        ? "bg-purple-100 text-purple-600"
                                        : "bg-blue-100 text-blue-600"
                                    } capitalize px-2 sm:px-4 rounded-full py-1 items-center`}
                                  >
                                    <p className="text-[9px] sm:text-xs font-semibold">
                                      {item?.[1]}
                                    </p>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </>
                      ) : (
                        <p className="text-[9px] italic sm:text-sm">
                          No Filters
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <LinePagination
                  data={searchedData}
                  setCurrentItems={setFilteredData}
                >
                  <div
                    style={{ minHeight: "24rem" }}
                    className="w-full min-h-96 relative z-10 bg-white overflow-x-auto"
                  >
                    <table className="relative w-full divide-y divide-gray-1 overflow-auto  bg-white">
                      <thead className=" rounded-lg bg-gray-50 rounded-t border px-5">
                        <tr className="rounded-t-lg">
                          <th
                            scope="col"
                            className={`px-2 py-4 pl-5 text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                          >
                            <div className="flex gap-2  items-center">
                              Name
                              <FilterList fontSize="inherit" />
                            </div>
                          </th>
                          <th
                            scope="col"
                            className={`px-2 py-4 text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                          >
                            <div className="flex gap-2 items-center">
                              Genotype
                              <FilterList fontSize="inherit" />
                            </div>
                          </th>
                          <th
                            scope="col"
                            className={`px-2 py-4 text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                          >
                            <div className="flex gap-2 items-center">
                              Age
                              <FilterList fontSize="inherit" />
                            </div>
                          </th>
                          <th
                            scope="col"
                            className={`px-2 py-4 text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                          >
                            <div className="flex gap-2 items-center">
                              Gender
                              <FilterList fontSize="inherit" />
                            </div>
                          </th>
                          <th
                            scope="col"
                            className={`px-2 py-4  text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                          >
                            <div className="flex gap-2 items-center">
                              Email <FilterList fontSize="inherit" />
                            </div>
                          </th>
                          <th
                            scope="col"
                            className={`px-2 py-4  text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                          >
                            <div className="flex gap-2 items-center">
                              Location
                              <FilterList fontSize="inherit" />
                            </div>
                          </th>
                          <th
                            scope="col"
                            className={`px-2 py-4 text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                          >
                            <div className="flex gap-2 items-center">
                              Subscription
                              <FilterList fontSize="inherit" />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-300 w-full overflow-auto bg-white px-5 pt-5">
                        {filteredData?.length > 0 ? (
                          filteredData?.map?.((item: any, index: number) => {
                            const location = `${
                              item?.location_state
                                ? `${item?.location_state?.trim?.()}, `
                                : ""
                            }${
                              item?.location_country
                                ? item?.location_country?.trim?.()
                                : ""
                            }`;

                            return (
                              <tr
                                onClick={() => {
                                  setSelectedUser(item);
                                  setIndexPage(1);
                                }}
                                className="hover:bg-gray-100"
                                key={index}
                              >
                                <td className="px-2 pl-5 py-4 text-left text-xs font-medium capitalize text-[#2563EB] cursor-pointer">
                                  {`${item?.last_name?.toLowerCase?.()} ${item?.first_name?.toLowerCase?.()}`}
                                </td>
                                <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                                  {item?.genotype}
                                </td>
                                <td className="px-2 py-4 text-left whitespace-nowrap text-xs font-normal  text-[#545F7D]">
                                  {item?.age?.includes?.("-")
                                    ? calculateAge(item?.age)
                                      ? calculateAge(item?.age)
                                      : "N/A"
                                    : moment().diff(
                                        //@ts-ignore
                                        moment(item?.age, "DD/MM/YYYY"),
                                        "years"
                                      ) ?? "N/A"}
                                </td>
                                <td className="px-2 py-4 text-left whitespace-nowrap text-xs font-normal  text-[#545F7D]">
                                  {item?.gender?.toLowerCase?.()}
                                </td>
                                <td className="px-2 py-4 text-left text-xs font-normal lowercase text-[#545F7D]">
                                  {item?.email}
                                </td>
                                <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                                  {location?.length > 20
                                    ? `${location?.slice(0, 20)}...`
                                    : location}
                                </td>
                                <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                                  {item?.subscription?.name === "basic" ? (
                                    <p className="text-[10px] px-4 py-0.5 w-max text-center rounded-full bg-gray-100 border border-gray-800 text-gray-800">
                                      {item?.subscription?.name}
                                    </p>
                                  ) : item?.plan?.toLowerCase?.() ===
                                    "monthly" ? (
                                    <p className="text-[10px] px-4 py-0.5 w-max text-center rounded-full bg-purple-100 border border-purple-800 text-purple-800">
                                      {item?.plan}
                                    </p>
                                  ) : (
                                    <p className="text-[10px] px-4 py-0.5 w-max text-center rounded-full bg-blue-100 border border-blue-800 text-blue-800">
                                      {item?.plan}
                                    </p>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={6}>
                              <div className=" items-center justify-center w-full h-80 bg-white flex">
                                <div className="h-full flex gap-5 flex-col justify-center items-center">
                                  <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                                    <img
                                      src={NoUser}
                                      alt="empty"
                                      className="w-5 md:w-10 h-5 md:h-10 object-contain"
                                    />
                                  </div>
                                  <h2 className="text-lg text-center font-bold text-[#081A51] ">
                                    No users found
                                  </h2>
                                  <p className="text-sm w-4/5 mx-auto text-center">
                                    No data for the selections, kindly change
                                    your filters
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </LinePagination>
              </div>
            ) : (
              <div className=" items-center justify-center w-full h-80 bg-white flex">
                <div className="h-full flex gap-5 flex-col justify-center items-center">
                  <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                    <img
                      src={NoUser}
                      alt="empty"
                      className="w-5 md:w-10 h-5 md:h-10 object-contain"
                    />
                  </div>
                  <h2 className="text-lg text-center font-bold text-[#081A51] ">
                    No users Found
                  </h2>
                  <p className="text-sm w-4/5 md:w-3/5 mx-auto text-center">
                    You currently manage no user. Kindly wait for the super
                    admin to administer users to you. We welcome you to contact
                    support if you have any complaints or inquiries
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

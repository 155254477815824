import AnimatedTexts from "./AnimatedTexts";
import ASSETS from "src/asset";

export default function Loader() {
  return (
    <div className="fixed left-0 bg-[#000107d2] flex justify-center items-center w-full z-50 h-full top-0">
      <div className="flex flex-col gap-1 justify-center items-center">
        <img src={ASSETS?.Circle} alt="spin" className=" animate-spin" />
        <AnimatedTexts />
      </div>
    </div>
  );
}

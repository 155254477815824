import React, { useState, useEffect } from "react";

const texts = [
  "Please give us some minutes while we check your eligibility...",
  "Analyzing your responses ...",
  "Determining your eligibility based on analysis ...",
  "Computing eligibility analysis result ...",
];

const AnimatedTexts = () => {
  const [index, setIndex] = useState(0);
  const [animation, setAnimation] = useState("fade-in");

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimation("fade-out");
    }, 3000); // Change text every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const handleAnimationEnd = () => {
    if (animation === "fade-out") {
      setIndex((prevIndex) => (prevIndex + 1) % texts.length);
      setAnimation("fade-in");
    } else if (animation === "fade-in") {
      setAnimation("");
    }
  };

  return (
    <p
      id="animatedText"
      className={`text-sm text-center font-medium text-white ${animation}`}
      onAnimationEnd={handleAnimationEnd}
    >
      {texts[index]}
    </p>
  );
};

export default AnimatedTexts;

import { styled } from "@mui/system";
// import theme from 'src/assets/colors/theme'
import { Input } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

export const MainWrapperDiv = styled("div")({
  height: 200,
  width: 800,
  backgroundColor: "white",
  "&.small": {
    width: "100%",
  },
});

export const ButtonWrapperDiv = styled("div")({
  display: "flex",
  marginLeft: 600,
  gap: "5px",
  marginTop: "5%",
  width: "35%",
  "&.small": {
    marginLeft: "auto",
    justifyContent: "flex-end",
  },
});

export const MessageVariable = styled("div")({
  display: "flex",
  width: "150px",
  marginLeft: "auto",
  marginTop: "-1px",
  position: "relative",
  transition: "all 0.2 ease",
  ".MuiInputBase-root": {
    width: "100%",
    height: "100%",
    fontSize: "13px",
    fontWeight: 500,
    ".MuiOutlinedInput-input": {
      padding: "10px",
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: "1px solid transparent",
    },
  },
});

export const VariableValue = styled("span")({
  position: "absolute",
  top: "11px",
  left: "5px",
  fontSize: "13px",
  fontWeight: 500,
});

export const StyledInput = styled(Input)({
  display: "none",
});

export const AttachFile = styled("div")({
  color: "blue",
  position: "absolute",
  cursor: "pointer",
  right: "20px",
  zIndex: 3,
  marginTop: "-50px",
});

export const RemoveImg = styled(CancelIcon)({
  position: "absolute",
  top: "-5px",
  right: "-5px",
  color: "blue",
  backgroundColor: "blue",
  borderRadius: "50%",
  fontSize: "large",
  cursor: "pointer",
});

export const AttachedImg = styled("div")({
  display: "flex",
  width: "100%",
  flexWrap: "wrap",
  gap: "10px",
  margin: "10px 0px 0px",
  div: {
    position: "relative",
    width: "80px",
    height: "60px",
    margin: "0px 4px",
    zIndex: 2,
    img: {
      width: "100%",
      height: "100%",
      borderRadius: "4px",
    },
  },
});

export const Error = styled("div")({
  color: "red",
  fontSize: "13px",
  fontWeight: "400",
});

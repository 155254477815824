import {
  ChatBubbleOutline,
  Group,
  KeyboardBackspace,
  MoreHoriz,
  PersonOutline,
} from "@mui/icons-material";
import { useState } from "react";
import UsersReadOnly from "./UsersReadOnly";
import AdminMessages from "./AdminMessages";
import AdminUserSuggestion from "./AdminUserSuggestion";
import AdminStrikesPage from "./AdminStrikesPage";
import AdminBlacklistPage from "./AdminBlacklist";
import AdminAssignUser from "./AdminAssignUser";
import AdminContact from "./AdminContact";
import HandlerRatings from "./HandlerRatings";
import Tippy from "@tippyjs/react";
import ASSETS from "src/asset";

const Handler =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/handler.svg";
export default function AdminSelectedHandler({
  setSelectedUser,
  setIndexPage,
  selectedUser,
}) {
  const [selectedPage, setSelectedPage] = useState(0);
  return (
    <div className="absolute overflow-hidden top-0 left-0 w-full h-full">
      <div className="flex h-full overflow-hidden">
        <div className="w-60 h-full overflow-y-auto space-y-7 pb-40 pt-5 flex-shrink-0 px-3 bg-white">
          <div className="flex justify-between items-center">
            <Tippy content="Go Back">
              <div
                onClick={() => {
                  setSelectedUser(null);
                  setIndexPage(0);
                }}
                className="w-8 h-8 rounded-full cursor-pointer hover:opacity-70 bg-[#F2F5FE] flex justify-center items-center "
              >
                <KeyboardBackspace fontSize="small" />
              </div>
            </Tippy>

            <div className="w-8 h-8 rounded-full cursor-pointer bg-[#F2F5FE] flex justify-center items-center ">
              <MoreHoriz fontSize="small" />
            </div>
          </div>
          <div className="flex flex-col gap-2 justify-center items-center">
            <div className="w-20 h-20 rounded-full border border-[#3361FF] p-1 ">
              <img
                alt="handler"
                src={ASSETS?.Avi}
                className="w-full h-full rounded-full"
              />
            </div>
            <div className="text-center">
              <h2 className="text-base font-semibold">
                {selectedUser?.user_name}
              </h2>
              <p className="text-xs font-medium">Handler</p>
            </div>
          </div>
          <div className="">
            <div className="grid grid-cols-2 transition-all rounded-lg  ">
              <div
                onClick={() => setSelectedPage(0)}
                className={`p-4 flex  ${
                  selectedPage === 0
                    ? "bg-[#3361FF] border-[#3361FF] scale-110 text-white rounded-lg"
                    : "text-[#081A51] border-[#081A51] rounded-tl-lg hover:bg-gray-100"
                }  cursor-pointer border border-t-2 border-l-2 justify-center items-center gap-1 flex-col`}
              >
                <Group fontSize="small" />
                <h2 className="text-10 font-semibold  text-center ">
                  Assigned users
                </h2>
              </div>
              <div
                onClick={() => setSelectedPage(1)}
                className={`p-4 flex ${
                  selectedPage === 1
                    ? "bg-[#3361FF] border-[#3361FF] scale-110 text-white rounded-lg"
                    : "text-[#081A51] border-[#081A51] rounded-tr-lg hover:bg-gray-100"
                }  border cursor-pointer border-t-2 border-r-2  justify-center items-center gap-1 flex-col`}
              >
                <ChatBubbleOutline fontSize="small" />
                <h2 className="text-10 font-semibold text-center ">Messages</h2>
              </div>
              <div
                onClick={() => setSelectedPage(2)}
                className={`p-4 flex ${
                  selectedPage === 2
                    ? "bg-[#3361FF] border-[#3361FF] scale-110 text-white rounded-lg"
                    : "text-[#081A51] border-[#081A51] hover:bg-gray-100"
                }  border cursor-pointer border-l-2 justify-center items-center gap-1 flex-col`}
              >
                <ChatBubbleOutline fontSize="small" />
                <h2 className="text-10 font-semibold text-center ">
                  Suggestions
                </h2>
              </div>
              <div
                onClick={() => setSelectedPage(3)}
                className={`p-4 flex ${
                  selectedPage === 3
                    ? "bg-[#3361FF] border-[#3361FF] scale-110 text-white rounded-lg"
                    : "text-[#081A51] border-[#081A51] hover:bg-gray-100"
                }   border cursor-pointer border-r-2  justify-center items-center gap-1 flex-col`}
              >
                <ChatBubbleOutline fontSize="small" />
                <h2 className="text-10 font-semibold text-center ">
                  Stikes issued
                </h2>
              </div>
              <div
                onClick={() => setSelectedPage(4)}
                className={`p-4 flex  ${
                  selectedPage === 4
                    ? "bg-[#3361FF] border-[#3361FF] scale-110 text-white rounded-lg"
                    : "text-[#081A51] border-[#081A51] hover:bg-gray-100"
                }  border cursor-pointer border-l-2 justify-center items-center gap-1 flex-col`}
              >
                <ChatBubbleOutline fontSize="small" />
                <h2 className="text-10 font-semibold text-center ">
                  User Blacklisted
                </h2>
              </div>
              {/* <div
                onClick={() => setSelectedPage(5)}
                className={`p-4 flex  ${
                  selectedPage === 5
                    ? "bg-[#3361FF] border-[#3361FF] scale-110 text-white rounded-lg"
                    : "text-[#081A51] border-[#081A51] hover:bg-gray-100"
                }  border cursor-pointer border-r-2  justify-center items-center gap-1 flex-col`}
              >
                <ChatBubbleOutline fontSize="small" />
                <h2 className="text-10 font-semibold  text-center ">
                  Assigned users
                </h2>
              </div> */}
              <div
                onClick={() => setSelectedPage(6)}
                className={`p-4 flex  ${
                  selectedPage === 6
                    ? "bg-[#3361FF] border-[#3361FF] scale-110 text-white rounded-lg"
                    : "text-[#081A51] border-[#081A51] rounded-bl-lg hover:bg-gray-100"
                }  border cursor-pointer border-b-2 border-l-2 justify-center items-center gap-1 flex-col`}
              >
                <ChatBubbleOutline fontSize="small" />
                <h2 className="text-10 font-semibold text-center ">Ratings</h2>
              </div>
              {/* <div
                onClick={() => setSelectedPage(7)}
                className={`p-4 flex ${
                  selectedPage === 7
                    ? "bg-[#3361FF] border-[#3361FF] scale-110 text-white rounded-lg"
                    : "text-[#081A51] border-[#081A51] rounded-br-lg hover:bg-gray-100"
                }  border cursor-pointer border-b-2 border-r-2  justify-center items-center gap-1 flex-col`}
              >
                <ChatBubbleOutline fontSize="small" />
                <h2 className="text-10 font-semibold  text-center ">Contact</h2>
              </div> */}
            </div>
          </div>
        </div>
        <div className="w-full flex-grow h-full relative overflow-hidden ">
          {selectedPage === 0 ? (
            <UsersReadOnly handler_id={selectedUser} />
          ) : selectedPage === 1 ? (
            <AdminMessages handler_id={selectedUser} />
          ) : selectedPage === 2 ? (
            <AdminUserSuggestion />
          ) : selectedPage === 3 ? (
            <AdminStrikesPage />
          ) : selectedPage === 4 ? (
            <AdminBlacklistPage />
          ) : selectedPage === 5 ? (
            <AdminAssignUser />
          ) : selectedPage === 6 ? (
            <HandlerRatings />
          ) : (
            <AdminContact />
          )}
        </div>
      </div>
    </div>
  );
}

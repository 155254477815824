import { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";

interface DateCalendar {
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<any>>;
  setSelectedTherapistTime: React.Dispatch<React.SetStateAction<any>>;
}

const DateCalendar: React.FC<DateCalendar> = ({
  selectedDate,
  setSelectedDate,
  setSelectedTherapistTime,
}) => {
  const handleClickDay = (date: Date) => {
    setSelectedDate(date);
    setSelectedTherapistTime(null);
  };

  return (
    <Calendar
      className="calendercomp"
      onClickDay={handleClickDay}
      value={selectedDate}
      minDate={new Date()}
    />
  );
};

export default DateCalendar;

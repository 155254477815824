import React, { Fragment, useState } from "react";
import RangeSlider from "../RangeSlider/RangeSlider";
import { useMediaQuery } from "@mui/material";
import { useUser } from "../auth/hooks/UserProvider";
import { Menu, Transition } from "@headlessui/react";
import {
  GENOTYPE,
  RELATIONSHIP_STATUS,
  RELIGIONS,
  COUNTRY_LIST
} from "src/helpers/constants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

export default function Preference() {
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const { user, saveUser } = useUser();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [preferredGenotype, setPreferredGenotype] = useState(
    user?.preferred_genotype?.split?.(",")?.map?.((a) => a?.trim?.()) ?? []
  );
   const [preferredLocation, setPreferredLocation] = useState(
    user?.preferred_country?.split?.(",")?.map?.((a) => a?.trim?.()) ?? []
  );
  const [preferredAge, setPreferredAge] = useState(
    user?.preferred_age?.split?.("-")?.map?.((a) => Number(a?.trim?.())) ?? []
  );
  const [preferredReligion, setPreferredReligion] = useState(
    user?.preferred_religion?.split?.(",")?.map?.((a) => a?.trim?.()) ?? []
  );
  const [preferredStatus, setPreferredStatus] = useState(
    user?.preferred_status?.split?.(",")?.map?.((a) => a?.trim?.()) ?? []
  );

  console.log("user", preferredLocation);

  return (
    <div className="space-y-5">
      <div className="space-y-3">
        <h2 className="md:text-xl text-sm font-bold">Preferences</h2>
        <div className="bg-white space-y-10 rounded-lg p-4 md:p-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 items-end gap-4 lg:grid-cols-3 lg:gap-7">
            <Menu as="div" className="w-full relative">
              {({ open }) => (
                <>
                  <Menu.Button as="div" className="">
                    <div className="w-full space-y-2">
                      <label
                        htmlFor="religion"
                        className="text-xs sm:text-sm block font-bold"
                      >
                        Preferred Religion(s)
                      </label>
                      <div className="focus:outline-none w-full appearance-none rounded-md cursor-pointer border border-gray-300 px-3 py-2.5 placeholder-gray-400 flex justify-between items-center shadow-sm focus:border-blue-1 focus:ring-blue-1 sm:text-sm capitalize lg:text-lg text-xs">
                        <p className="text-xs sm:text-sm block truncate">
                          {preferredReligion.length === 0
                            ? "Select Preferred religion(s)"
                            : preferredReligion.join()}
                        </p>
                        <i className="fa-solid text-xs fa-chevron-down"></i>
                      </div>
                    </div>
                  </Menu.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                    >
                      <div className="flex items-center text-text-1 justify-between">
                        <p className="text-10 ">
                          Select one, multiple or all religion(s)
                        </p>
                        <i className="fa-solid text-10 fa-chevron-up"></i>
                      </div>
                      {["Any", ...RELIGIONS].map((p_r) => (
                        <div
                          key={p_r}
                          onClick={() => {
                            if (
                              preferredReligion
                                ?.map((i) => i?.toLowerCase?.())
                                ?.includes?.(p_r?.toLowerCase?.())
                            ) {
                              setPreferredReligion((prev) =>
                                prev.filter((e) => e !== p_r)
                              );
                            } else {
                              setPreferredReligion((prev) => [...prev, p_r]);
                            }
                          }}
                          className={`w-full rounded-lg cursor-pointer px-3 py-2 text-xs ${
                            preferredReligion
                              ?.map((i) => i?.toLowerCase?.())
                              ?.includes?.(p_r?.toLowerCase?.())
                              ? "bg-drop-1 text-white"
                              : "bg-white hover:bg-gray-200"
                          }`}
                        >
                          {p_r}
                        </div>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
            <Menu as="div" className="w-full relative">
              {({ open }) => (
                <>
                  <Menu.Button as="div" className="">
                    <div className="w-full space-y-2">
                      <label
                        htmlFor="genotype"
                        className="text-xs sm:text-sm block font-bold"
                      >
                        Prefered Genotype(s)
                      </label>
                      <div className="focus:outline-none w-full appearance-none rounded-md cursor-pointer border border-gray-300 px-3 py-2.5 placeholder-gray-400 flex justify-between items-center shadow-sm focus:border-blue-1 focus:ring-blue-1 sm:text-sm capitalize lg:text-lg text-xs">
                        <p className="text-xs sm:text-sm block truncate">
                          {preferredGenotype.length === 0
                            ? "Select prefered genotype"
                            : preferredGenotype.join()}
                        </p>
                        <i className="fa-solid text-xs fa-chevron-down"></i>
                      </div>
                    </div>
                  </Menu.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                    >
                      <div className="flex items-center text-text-1 justify-between">
                        <p className="text-10 ">
                          Select one or multiple genotype(s)
                        </p>
                        <i className="fa-solid text-10 fa-chevron-up"></i>
                      </div>
                      {GENOTYPE.map((p_g) => (
                        <div
                          key={p_g}
                          onClick={() => {
                            if (preferredGenotype.includes(p_g)) {
                              setPreferredGenotype((prev) =>
                                prev.filter((e) => e !== p_g)
                              );
                            } else {
                              setPreferredGenotype((prev) => [...prev, p_g]);
                            }
                          }}
                          className={`w-full rounded-lg px-3 cursor-pointer py-2 text-xs ${
                            preferredGenotype.includes(p_g)
                              ? "bg-drop-1 text-white"
                              : "bg-white hover:bg-gray-200"
                          }`}
                        >
                          {p_g}
                        </div>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
            <Menu as="div" className="w-full relative">
              {({ open }) => (
                <>
                  <Menu.Button as="div" className="">
                    <div className="w-full space-y-2">
                      <label
                        htmlFor="status"
                        className="text-xs sm:text-sm block font-bold"
                      >
                        Preferred Relationship
                      </label>
                      <div className="focus:outline-none w-full appearance-none rounded-md cursor-pointer border border-gray-300 px-3 py-2.5 placeholder-gray-400 flex justify-between items-center shadow-sm focus:border-blue-1 focus:ring-blue-1 sm:text-sm capitalize lg:text-lg text-xs">
                        <p className="text-xs sm:text-sm block truncate">
                          {preferredStatus.length === 0
                            ? "Select status(es)"
                            : preferredStatus.join()}
                        </p>
                        <i className="fa-solid text-xs fa-chevron-down"></i>
                      </div>
                    </div>
                  </Menu.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                    >
                      <div className="flex items-center text-text-1 justify-between">
                        <p className="text-10 ">
                          Select one, multiple or any status(es)
                        </p>
                        <i className="fa-solid text-10 fa-chevron-up"></i>
                      </div>
                      {["Any", ...RELATIONSHIP_STATUS].map((status) => (
                        <div
                          key={status}
                          // onClick={() => setpreferredStatus(status)}
                          onClick={() => {
                            if (preferredStatus.includes(status)) {
                              setPreferredStatus((prev) =>
                                prev.filter((e) => e !== status)
                              );
                            } else {
                              setPreferredStatus((prev) => [...prev, status]);
                            }
                          }}
                          className={`w-full rounded-lg px-3 py-2 cursor-pointer text-xs ${
                            preferredStatus.includes(status)
                              ? "bg-drop-1 text-white"
                              : "bg-white hover:bg-gray-200"
                          }`}
                        >
                          {status}
                        </div>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
             <Menu as="div" className="w-full relative">
              {({ open }) => (
                <>
                  <Menu.Button as="div" className="">
                    <div className="w-full space-y-2">
                      <label
                        htmlFor="status"
                        className="text-xs sm:text-sm block font-bold"
                      >
                        Preferred Location
                      </label>
                      <div className="focus:outline-none w-full appearance-none rounded-md cursor-pointer border border-gray-300 px-3 py-2.5 placeholder-gray-400 flex justify-between items-center shadow-sm focus:border-blue-1 focus:ring-blue-1 sm:text-sm capitalize lg:text-lg text-xs">
                        <p className="text-xs sm:text-sm block truncate">
                          {preferredLocation.length === 0
                            ? "Select location(s)"
                            : preferredLocation.join()}
                        </p>
                        <i className="fa-solid text-xs fa-chevron-down"></i>
                      </div>
                    </div>
                  </Menu.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0 h-[40vh]"
                    >
                      <div className="flex items-center text-text-1 justify-between">
                        <p className="text-10 ">
                          Select one, multiple or any location(s)
                        </p>
                        <i className="fa-solid text-10 fa-chevron-up"></i>
                      </div>
                      {["Any", ...COUNTRY_LIST].map((status) => (
                        <div
                          key={status}
                          // onClick={() => setpreferredStatus(status)}
                          onClick={() => {
                            if (preferredLocation.includes(status)) {
                              setPreferredLocation((prev) =>
                                prev.filter((e) => e !== status)
                              );
                            } else {
                              setPreferredLocation((prev) => [...prev, status]);
                            }
                          }}
                          className={`w-full rounded-lg px-3 py-2 cursor-pointer text-xs ${
                            preferredLocation.includes(status)
                              ? "bg-drop-1 text-white"
                              : "bg-white hover:bg-gray-200"
                          }`}
                        >
                          {status}
                        </div>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
          <div className="w-full py-5 flex flex-col justify-start m-auto ">
            <p className="text-sm font-bold">Preferred age range</p>
            <RangeSlider
              initaialNumber={preferredAge}
              setNumber={setPreferredAge}
              styles={{
                width: "100%",
                marginLeft: isSmallScreen ? "0px" : "4px",
                marginTop: isSmallScreen ? "3px" : "7px",
              }}
            />
          </div>
          <div className="flex justify-end">
            <button
              disabled={isLoading}
              onClick={() => {
                const payload = {
                  preferred_genotype: preferredGenotype?.join(", "),
                  preferred_religion: preferredReligion?.join(", "),
                   preferred_country: preferredLocation?.join(", "),
                  preferred_status: preferredStatus?.join?.(", "),
                  preferred_age: preferredAge?.join?.("-"),
                };

                setIsLoading(true);
                saveUser(payload, () => {
                  setIsLoading(false);
                  toast.success("Changes saved successfully");
                  navigate(-1);
                });
              }}
              className="px-6 py-2.5 w-full sm:w-max rounded-lg bg-[#081A51] text-white font-semibold hover:opacity-75 disabled:bg-gray-300 disabled:text-gray-700"
            >
              {isLoading ? (
                <BeatLoader size={10} color="#fff" />
              ) : (
                "Save Changes"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

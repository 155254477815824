import { GridView } from "@mui/icons-material";
import ASSETS from "src/asset";
const Message =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/message_icon.svg";
const MessageSm =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/message_icon_sm.svg";
const MyProfile =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/my_profile.svg";
const Profile =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/profile.svg";
const ProfileSm =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/profile_sm.svg";
const Request =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/request_icon.svg";
const RequestSm =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/request_icon_sm.svg";
const DashLine =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/line_dash.png";
const Msg =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/msg.png";
const Pulse =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/pulse_alert.png";

export const SideBarItemObj = [
  {
    icon: MyProfile,
    text: "My Profile",
    iconSm: ProfileSm,
    textSm: "My Profile",
    page: "/dashboard/my-profile",
    title: "Welcome to your profile page",
  },
  // {
  //   icon: Matches,
  //   text: "Matches",
  //   iconSm: MatchesSm,
  //   textSm: "Matches",
  //   page: "/dashboard/matches",
  //   title: "Matches",
  // },
  {
    icon: Request,
    text: "Discover",
    iconSm: RequestSm,
    textSm: "Discover",
    page: "/dashboard/friend-request",
    title: "Discover",
  },
  {
    icon: Message,
    text: "Chat with a handler",
    iconSm: MessageSm,
    textSm: "Chat with a handler",
    page: "/dashboard/handler-chat",
    title: "Chat",
  },
  {
    icon: Profile,
    text: "Handler Suggestions",
    iconSm: ProfileSm,
    textSm: "Handler Suggestions",
    page: "/dashboard/profile-suggestion",
    title: "Suggestions by your handler",
  },
  {
    icon: Msg,
    text: "Messenger",
    iconSm: ProfileSm,
    textSm: "Messenger",
    page: "/dashboard/messages",
    title: "Chat Messenger",
  },
  {
    icon: ASSETS.blindicon,
    text: "Blind Dates",
    iconSm: ASSETS.blindicon,
    textSm: "Blind Dates",
    page: "/dashboard/blind-date",
    title: "Blind Dates",
  },
  {
    icon: ASSETS.TherapistIcon,
    text: "Therapy",
    iconSm: ASSETS.TherapistIcon,
    textSm: "Therapy",
    page: "/dashboard/therapy",
    title: "Nirvanae Wellness",
  },
  {
    icon: ASSETS.sub,
    text: "Subscription",
    iconSm: ASSETS.sub,
    textSm: "Subscription",
    page: "/dashboard/choose-a-plan",
    title: "Subscriptions",
  },
];
export const SideBarItemObjHandler = [
  {
    icon: DashLine,
    text: "Handler's Dashboard",
    iconSm: DashLine,
    textSm: "Handler's Dashboard",
    page: "/dashboard/handler",
    title: "Handler's Dashboard",
  },
  {
    icon: Profile,
    text: "Add User",
    iconSm: Profile,
    textSm: "Add User",
    page: "/dashboard/handler/add-user",
    title: "Add User",
  },
  {
    icon: Message,
    text: "Chats",
    iconSm: MessageSm,
    textSm: "Chats",
    page: "/dashboard/handler/chat",
    title: "Chats",
  },
  {
    icon: ASSETS.BdVid,
    text: "Blind Dates",
    iconSm: ASSETS.BdVid,
    textSm: "Blind Dates",
    page: "/dashboard/handler/blind-dates",
    title: "Blind Dates",
  },
  {
    icon: ASSETS.TherapistIcon,
    text: "Therapy",
    iconSm: ASSETS.TherapistIcon,
    textSm: "Therapy",
    page: "/dashboard/handler/therapy",
    title: "Therapy Sessions",
  },
];
export const SideBarItemObjAdmin = [
  {
    icon: DashLine,
    text: "Dashboard",
    iconSm: DashLine,
    textSm: "Dashboard",
    page: "/super/dashboard",
    title: "Dashboard",
    extra: <GridView fontSize="inherit" />,
  },
  {
    icon: DashLine,
    text: "Handler Mgt",
    iconSm: DashLine,
    textSm: "Handler Mgt",
    page: "/super/handler",
    title: "Handler Management",
    extra: <GridView fontSize="inherit" />,
  },
  {
    icon: DashLine,
    text: "User Mgt",
    iconSm: DashLine,
    textSm: "User Mgt",
    page: "/super/user",
    title: "User Management",
    extra: <GridView fontSize="inherit" />,
  },
  {
    icon: DashLine,
    text: "Lobby",
    iconSm: DashLine,
    textSm: "Lobby",
    page: "/super/lobby",
    title: "Lobby",
    extra: <GridView fontSize="inherit" />,
  },
  {
    icon: DashLine,
    text: "Rejected Users",
    iconSm: DashLine,
    textSm: "Rejected Users",
    page: "/super/rejected_user",
    title: "Rejected Users",
    extra: <GridView fontSize="inherit" />,
  },
  {
    icon: DashLine,
    text: "Announcements",
    iconSm: DashLine,
    textSm: "Announcements",
    page: "/super/announcements",
    title: "Announcements",
    extra: <GridView fontSize="inherit" />,
  },
  {
    icon: DashLine,
    text: "Feedbacks",
    iconSm: DashLine,
    textSm: "Feedbacks",
    page: "/super/feedbacks",
    title: "Feedbacks",
    extra: <GridView fontSize="inherit" />,
  },

  {
    icon: Pulse,
    text: "Violations",
    iconSm: Pulse,
    textSm: "Violations",
    page: "/super/violations",
    title: "Violations",
    extra: <GridView fontSize="inherit" />,
  },
  {
    icon: DashLine,
    text: "Handler Chat",
    iconSm: DashLine,
    textSm: "Handler Chat",
    page: "/super/handler_chat",
    title: "Handler Chat",
    extra: <GridView fontSize="inherit" />,
  },
];

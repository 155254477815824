import { FC } from "react";
import {
  MainWrapper,
  DataGridWrapper,
  ToolBarWrapper,
  LeftToolBarWrapper,
  RightToolBarWrapper,
} from "./EnhancedTable.styles";
import { DataGrid, DataGridProps, GridToolbarExport } from "@mui/x-data-grid";

// extend wrapper proper with original prop so they are supported
/**
 * Accepts all the native props of MUI Datagrid.
 * You need to make sure that parent container has intrinsic
 * hieght/width set. Also background for table has been set as
 * transparent, pass bgColor prop to make the background solid.
 */
interface EnhancedTableProps extends DataGridProps {
  leftToolBar?: React.ReactNode;
  rightToolBar?: React.ReactNode;
  disableSelectionOnClick?: boolean;
  pageSize?: number;
  rowsPerPageOptions?: number[];
  bgColor?: string;
  enableExport?: boolean;
}
const EnhancedTable: FC<EnhancedTableProps> = ({
  leftToolBar,
  rightToolBar,
  pageSize,
  disableSelectionOnClick,
  rowsPerPageOptions,
  bgColor,
  enableExport,
  ...rest
}) => {
  const CustomToolbar = () => {
    return <GridToolbarExport />;
  };
  return (
    <MainWrapper>
      {(leftToolBar || rightToolBar) && (
        <ToolBarWrapper>
          <LeftToolBarWrapper>{leftToolBar}</LeftToolBarWrapper>
          <RightToolBarWrapper>{rightToolBar}</RightToolBarWrapper>
        </ToolBarWrapper>
      )}
      <DataGridWrapper bgColor={bgColor}>
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            {...rest}
            style={{ border: "none" }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSizeOptions={[20]}
            disableRowSelectionOnClick={disableSelectionOnClick ?? true}
            components={{
              ...rest.components,
              NoRowsOverlay: () => <p></p>,
              NoResultsOverlay: () => <p></p>,
              ...(true && { Toolbar: CustomToolbar }),
            }}
          />
        </div>
      </DataGridWrapper>
    </MainWrapper>
  );
};

export default EnhancedTable;

import { PersonOutline } from "@mui/icons-material";
import { useState } from "react";
import { ADMIN_HANDLER_CONVOS } from "../auth/data/queries";
import { useQuery } from "@apollo/client";
import PageLoader from "../PageLoader";
import moment from "moment";

const Empty =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/empty_message.png";

export default function AdminMessages({ handler_id }) {
  console.log({ handler_id });
  const { data, loading } = useQuery(ADMIN_HANDLER_CONVOS, {
    variables: {
      handlerId: Number(handler_id?.id),
    },
    fetchPolicy: "network-only",
  });

  // const [allFiles, setAllFiles] = useState([
  //   {
  //     id: 1,
  //     file: null,
  //   },
  // ]);
  const conversations = data?.adminhandlerConversations;

  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedMessageFilter, setSelectedMessageFilter] = useState("all");
  console.log({ conversations, selectedUser });
  return (
    <div className="space-y-4 absolute top-0 left-0 w-full h-full overflow-hidden">
      {loading ? (
        <PageLoader />
      ) : (
        <div className="flex w-full pb-0 h-full overflow-hidden">
          <div className="w-60 flex-shrink-0 divide-y pb-60 divide-gray-200 bg-white h-full overflow-y-auto">
            {conversations?.map?.((conversation, index) => (
              <div
                onClick={() => {
                  setSelectedUser(conversation);
                }}
                key={index}
                className={`p-6 cursor-pointer ${
                  selectedUser?.user?.first_name ===
                  conversation?.user?.first_name
                    ? "bg-[#CAD5FE]"
                    : "hover:bg-gray-100"
                } flex justify-between items-center`}
              >
                <div className="flex gap-2 items-center">
                  <div className="w-8 h-8 flex-shrink-0 relative rounded-full bg-[#213F7D]">
                    <img
                      src={conversation?.user?.profile_image_url}
                      className="w-full h-full rounded-full object-cover"
                      alt="user"
                    />
                    <div
                      className={`absolute w-2 h-2 rounded-full ${
                        conversation?.status?.toLowerCase?.() === "online"
                          ? "bg-green-500"
                          : "bg-gray-400"
                      } bottom-0 right-0`}
                    ></div>
                  </div>
                  <div>
                    <h2 className="text-sm font-bold">
                      {conversation?.user?.first_name}
                    </h2>
                    <p className="text-10 capitalize">
                      {conversation?.user?.is_online}
                    </p>
                  </div>
                </div>
                {/* {conversation?.unread_messages > 0 ? (
                  <div
                    style={{
                      background: "linear-gradient(#FD749B, #281AC8)",
                    }}
                    className="w-6 h-6 text-white rounded-full flex justify-center items-center text-xs font-semibold"
                  >
                    {conversation?.unread_messages}
                  </div>
                ) : null} */}
              </div>
            ))}
          </div>
          <div className="flex-grow pb-40 overflow-y-auto h-full">
            {selectedUser ? (
              // showMessage(handler_id, selectedUser)
              selectedUser?.handler_messages?.map((message) => (
                <div className="space-y-5 p-5">
                  <div className="bg-white divide-y divide-[#017EFA] border-l-4 shadow-md rounded-lg border-[#017EFA]">
                    <div className="flex px-6 py-4 justify-between items-center">
                      <div className="flex gap-5 items-center">
                        {message?.message_sender !== "USER" ? (
                          <PersonOutline />
                        ) : (
                          <img
                            src={selectedUser?.user?.profile_image_url}
                            alt="profile"
                            className="h-full w-full object-cover rounded-full"
                          />
                        )}
                        <h2 className="text-base relative font-semibold">
                          {message?.message_sender !== "USER" ? (
                            <p className="text-xs px-4 border border-blue-600 py-0.5 ml-1 rounded-full bg-blue-200 text-blue-600 w-max absolute left-full -top-2">
                              {handler_id?.user_name} Handler
                            </p>
                          ) : (
                            <>{selectedUser?.user?.first_name}</>
                          )}
                        </h2>
                      </div>
                      <p className="text-sm font-semibold">
                        {moment(message?.created_at).format("MMM DD, YYYY")}
                      </p>
                    </div>
                    <p className="text-xs px-6 py-4 font-medium">
                      {message?.body}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div className="pb-20 h-full flex gap-5 flex-col justify-center items-center">
                <img
                  src={Empty}
                  alt="empty"
                  className="w-60 h-60 object-cover"
                />
                <h2 className="text-lg font-normal">
                  Select a user to view messages!
                </h2>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export const showMessage = (
  handler_id: any,
  selectedUser: any,
  message: any
) => {
  console.log({ handler_id, selectedUser });
  return (
    <div className="space-y-5 p-5 pb-32">
      {/* <Disclosure
      as="div"
      className={"bg-[#f1f2faa2] border-gray-300 rounded-lg border"}
      defaultOpen={false}
    >
      {({ open }) => (
        <>
          <Disclosure.Button className="w-full">
            <div
              className={`flex w-full ${
                open ? "rounded-t-lg" : "rounded-lg"
              } cursor-pointer items-center justify-between bg-white shadow-md p-4`}
            >
              <div className="flex gap-5 items-center">
                <Edit />
                <p className="text-sm font-bold">Reply</p>
              </div>

              {open ? <ExpandLess /> : <ExpandMore />}
            </div>
          </Disclosure.Button>

          <Disclosure.Panel className="p-5">
            <div className="space-y-5">
              <div className="space-y-2">
                <label className="block text-xs font-medium">
                  Message
                </label>
                <textarea
                  className="resize-none w-full bg-white h-40 p-5 rounded-lg border border-[#102A42] text-sm"
                  placeholder={"Input subject here"}
                ></textarea>
              </div>
              <div className="space-y-1">
                <div className="flex justify-between items-start">
                  <div className="w-1/2 text-[#102A42] space-y-2">
                    {allFiles?.map((item, index) => (
                      <div
                        key={index}
                        className="flex gap-5 items-center"
                      >
                        <label className="text-sm divide-x divide-[#102A42] cursor-pointer gap-3 flex font-semibold border border-[#102A42] ">
                          <span className="px-4 py-2.5 flex-shrink-0 ">
                            Select File
                          </span>
                          <input
                            type="file"
                            multiple={false}
                            onChange={(e) => {
                              setAllFiles((prev) =>
                                prev?.map((i) => {
                                  if (i?.id === item?.id) {
                                    // @ts-ignore
                                    i.file = e.target.files?.[0];
                                    return i;
                                  }
                                  return i;
                                })
                              );
                              e.stopPropagation();
                            }}
                            className="hidden"
                          />
                          <p className="px-4 block truncate py-2.5">
                            {item?.file
                              ? // @ts-ignore
                                item?.file?.name
                              : "No file choosen"}
                          </p>
                        </label>
                        {allFiles?.length !== 1 && (
                          <div
                            onClick={() => {
                              setAllFiles((prev) =>
                                prev?.filter(
                                  (i) => i?.id !== item?.id
                                )
                              );
                            }}
                            className="w-8 h-8 flex-shrink-0 flex justify-center text-sm items-center hover:shadow-sm rounded-full bg-gray-200 hover:text-[#102A42] cursor-pointer"
                          >
                            <Delete fontSize="small" />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={() => {
                      setAllFiles((prev) => [
                        ...prev,
                        { id: prev?.length + 1, file: null },
                      ]);
                    }}
                    className="flex text-[#102A42] gap-2 items-center border-none outline-none text-sm font-semibold"
                  >
                    <Add />
                    Add More
                  </button>
                </div>
                <p className="text-10">
                  Allowed File Extensions: .jpg, .gif, .jpeg, .png,
                  .pdf, zip, .doc, .docx (Max file size: 128MB)
                </p>
              </div>
              <button className="px-6 rounded-lg py-2.5 bg-[#017EFA] text-white flex gap-2 items-center hover:opacity-70 hover:shadow-md ">
                Send
                <Send fontSize="small" />
              </button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure> */}
      <div className="bg-white divide-y divide-[#017EFA] border-l-4 shadow-md rounded-lg border-[#017EFA]">
        <div className="flex px-6 py-4 justify-between items-center">
          <div className="flex gap-5 items-center">
            <PersonOutline />
            <h2 className="text-base relative font-semibold">
              {handler_id?.user_name}
              <p className="text-xs px-4 border border-blue-600 py-0.5 ml-1 rounded-full bg-blue-200 text-blue-600 w-max absolute left-full -top-2">
                Handler
              </p>
            </h2>
          </div>
          <p className="text-sm font-semibold">7/11/23 (1:50pm)</p>
        </div>
        <p className="text-xs px-6 py-4 font-medium">
          Lorem ipsum dolor sit amet consectetur. Lacinia adipiscing cursus
          massa cursus. Velit ullamcorper et vel donec adipiscing elementum a
          amet elit. Consectetur est sed erat habitant. Lobortis etiam vel
          fringilla faucibus. Sagittis nunc scelerisque quisque orci. Auctor
          integer viverra dis adipiscing ac lacus facilisi. Diam vitae rutrum
          congue sit aliquam mauris. Aenean rutrum tempor congue facilisis ut
          nibh nulla. Sit amet non a scelerisque ut.
        </p>
      </div>
    </div>
  );
};

import { Person } from "@mui/icons-material";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import moment from "moment";
import { useState } from "react";
import { FaAngleDown, FaBell } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useUser } from "src/components/auth/hooks/UserProvider";

const AllNotifications = () => {
  const { user } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("all");
  const notifPerPage = 10;
  const lastIndex = currentPage * notifPerPage;
  const firstIndex = lastIndex - notifPerPage;

  // Filtering notifications based on the selected filter
  const filteredNotifications = user?.created_notifications
    ?.filter?.((i: any) => i?.deleted !== true)
    ?.filter((i: any) => {
      if (filter === "unread") {
        return i?.is_read === false;
      } else if (filter === "read") {
        return i?.is_read === true;
      }
      return true;
    });

  const notificationList = filteredNotifications
    ?.slice?.()
    ?.sort?.((a: any, b: any) => {
      return new Date(b?.created_at).getTime() - new Date(a?.created_at).getTime();
    })
    ?.slice(firstIndex, lastIndex);

  const npage = Math.ceil(filteredNotifications?.length / notifPerPage);
  const numbers = [...Array(npage + 1)?.keys()]?.slice(1);
  const navigate = useNavigate();

  const handleViewMessage = (msg) => {
    navigate(`/dashboard/message/${msg.id}`, { state: { msg } });
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const changePage = (id) => {
    setCurrentPage(id);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setCurrentPage(1); // Reset to the first page on filter change
  };

  return (
    <div>
      <div className="">
        <div className="w-full flex justify-between items-center">
          <p className="text-base font-semibold lg:text-xl">My Notifications</p>
          <div className="relative bg-white text-primary-bg px-2 py-1  rounded-md font-bold w-max border outline-white">
            <select
              value={filter}
              onChange={handleFilterChange}
              className="bg-transparent outline-white border-none text-xs lg:text-sm bg-"
            >
              <option value="all">All</option>
              <option value="unread">Unread</option>
              <option value="read">Read</option>
            </select>
       
          </div>
        </div>
        <div className="bg-white divide-y divide-gray-300 w-full mt-8 rounded-md">
          {notificationList?.map((notification) => (
            <div className="flex p-4 gap-3 justify-between items-center" key={notification.id}>
              <div
                className="flex gap-3 md:gap-4 xl:gap-5 items-center cursor-pointer"
                onClick={() => handleViewMessage(notification)}
              >
                <div className="bg-[#F2F4F7] h-8 w-8 rounded-full flex justify-center items-center lg:w-10 lg:h-10">
                  <FaBell className="lg:text-xl" />
                </div>
                <div
                  className={`w-2 h-2 rounded-full ${
                    notification?.is_read === false ? "bg-blue-600" : "bg-transparent"
                  }`}
                ></div>
                <div className="flex flex-col">
                  <h2
                    className={`${
                      notification?.is_read ? "font-medium" : "font-bold"
                    } md:text-lg text-[10px] capitalize`}
                  >
                    {notification?.title}
                  </h2>
                  <p
                    className={`text-[8px] lg:text-xs ${
                      notification?.is_read ? "" : "font-bold "
                    }`}
                  >
                    {notification?.description}
                  </p>
                </div>
              </div>
              <p className="text-[8px] sm:text-sm font-semibold">
                {moment(notification?.created_at).fromNow()}
              </p>
            </div>
          ))}
        </div>
        <div className="my-8 flex items-center justify-end">
          <span
            className="h-6 w-5 p-2 flex justify-center items-center cursor-pointer bg-white shadow-md"
            onClick={prevPage}
          >
            <KeyboardArrowLeftOutlinedIcon style={{ color: "#D4678F" }} />
          </span>
          {numbers.map((n, i) => (
            <div
              className={`h-7 w-6 p-2 flex border-2 justify-center items-center bg-white cursor-pointer`}
              key={i}
            >
              <p
                className={`${currentPage === n ? "text-rose-700" : ""}`}
                onClick={() => changePage(n)}
              >
                {n}
              </p>
            </div>
          ))}
          <span
            className="h-6 w-5 p-2 flex justify-center items-center cursor-pointer bg-white shadow-md"
            onClick={nextPage}
          >
            <KeyboardArrowRightOutlinedIcon style={{ color: "#A303A0" }} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default AllNotifications;

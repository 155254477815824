import {
  ArrowBack,
  Delete,
  Group,
  Person,
  PersonAdd,
  PersonAddAlt,
  Place,
  Search,
  Work,
  WorkOutline,
} from "@mui/icons-material";
import { useState } from "react";
import { AGERANGE, GENDERS } from "src/helpers/constants";
import ASSETS from "src/asset";
import { useMutation, useQuery } from "@apollo/client";
import { GET_HANDLER_USERS, USER_PENDING_REQUESTS } from "../auth/data/queries";
import { useHandler } from "../auth/hooks/HandlerProvider";
import ResponsiveOverlay from "src/overlays/ResponsiveOverlay";
import moment from "moment";
import { GET_USERS_TO_SUGGEST } from "src/hooks/data/queries";
import HandleSelectUser from "./HandleSelectUser";
import { CREATE_HANDLER_BLIND_DATE } from "../auth/data/mutation";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

interface MUSER {
  __typename: String;
  id: String;
  occupation: String;
  last_name: String;
  profile_image_url: String;
  first_name: String;
  last_online: String;
  location_state: String;
  location_country: String;
  location_city: String;
  age: String;
  gender: String;
  status: String;
}

export default function CreateBlindDate({ setIndexPage, refetch }) {
  const { handler } = useHandler();

  const [createHandlerDate] = useMutation(CREATE_HANDLER_BLIND_DATE);
  const { data } = useQuery(GET_HANDLER_USERS, {
    variables: {
      handlerId: Number(handler?.id),
    },
    fetchPolicy: "network-only",
  });

  const [dateTheme, setDateTheme] = useState("");
  const [meetingNote, setMeetingNote] = useState("");
  const [meetingDate, setMeetingDate] = useState("");
  const [openSelectUser, setOpenSelectUser] = useState(false);
  const [openSecondSelectUser, setOpenSecondSelectUser] = useState(false);
  const [filterGender, setFilterGender] = useState("");
  const [firstParticipant, setFirstParticipant] = useState<MUSER | null>(null);
  const [secondParticipant, setSecondParticipant] = useState<MUSER | null>(
    null
  );
  const [pickedUser, setPickedUser] = useState<MUSER | null>(null);
  const [dateError, setDateError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { data: suggestedData } = useQuery(USER_PENDING_REQUESTS, {
    variables: {
      userPendingRequestsId: Number(firstParticipant?.id),
      suggestedBy: "HANDLER",
    },
    skip: !firstParticipant?.id,
    fetchPolicy: "network-only",
  });

  const users = data?.handlerUsers ?? [];
  const mainUsers =
    suggestedData?.userPendingRequests
      ?.filter?.(
        (i) => i?.user_match_status !== true && i?.user_matched_status !== true
      )
      ?.map?.((item: any) =>
        item?.user_match?.id !== firstParticipant?.id
          ? { ...item?.user_match, main_match_id: item?.id }
          : { ...item?.user_matched, main_match_id: item?.id }
      ) || [];

  const filteredHUsers = users
    ? filterGender === ""
      ? users
      : users?.filter?.(
          (i) => i?.gender?.toLowerCase?.() === filterGender?.toLowerCase?.()
        )
    : [];

  const calculateAge = (birthdate) => {
    const birthDate = new Date(birthdate);
    const ageDiffMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDiffMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };
  const getToday = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const createTheBlindDate = (payLoad) => {
    setIsLoading(true);
    createHandlerDate({
      variables: {
        payload: payLoad,
      },
      onCompleted: (data) => {
        if (data.createBlindDate) {
          toast.success("Blind date created");
          // setSuggestUser(false);
          setIsLoading(false);
          refetch?.();
          setIndexPage(0);
        } else {
          setIsLoading(false);
          toast.error("Unable to create, Try again later");
        }
      },
      onError: (error) => {
        toast.error(error.message ?? "Network Error");
        setIsLoading(false);
      },
    });
  };

  const firstParticipantLocation = `${
    firstParticipant?.location_state
      ? `${firstParticipant?.location_state?.trim?.()}, `
      : ""
  }${
    firstParticipant?.location_country
      ? firstParticipant?.location_country?.trim?.()
      : ""
  }`;
  const secondParticipantLocation = `${
    secondParticipant?.location_state
      ? `${secondParticipant?.location_state?.trim?.()}, `
      : ""
  }${
    secondParticipant?.location_country
      ? secondParticipant?.location_country?.trim?.()
      : ""
  }`;

  const firstParticipantAge = firstParticipant?.age?.includes?.("-")
    ? calculateAge(firstParticipant?.age)
      ? calculateAge(firstParticipant?.age)
      : ""
    : //@ts-ignore
      moment().diff(moment(firstParticipant?.age, "DD/MM/YYYY"), "years");

  const secondParticipantAge = secondParticipant?.age?.includes?.("-")
    ? calculateAge(secondParticipant?.age)
      ? calculateAge(secondParticipant?.age)
      : ""
    : //@ts-ignore
      moment().diff(moment(secondParticipant?.age, "DD/MM/YYYY"), "years");

  return (
    <>
      <ResponsiveOverlay
        isOpen={openSelectUser}
        setIsOpen={() => {
          setPickedUser(null);
          setOpenSelectUser(false);
        }}
        title={"Select user"}
        size="small"
      >
        <div className="bg-white w-full">
          <div className="space-y-3 ">
            <div className="flex px-3 sm:px-5 justify-between items-center">
              <h2 className="text-sm font-semibold">My Users</h2>
              <select
                value={filterGender}
                disabled={secondParticipant ? true : false}
                onChange={(e) => setFilterGender(e.target.value)}
                className="w-32 text-xs sm:text-sm disabled:bg-gray-300 disabled:text-gray-800 px-4 py-2 rounded-lg"
              >
                <option value={""}>All Gender</option>
                {GENDERS?.map?.((gene, index) => (
                  <option key={index} value={gene}>
                    {gene}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-full px-3 sm:px-5 divide-y divide-gray-300 h-72 overflow-y-auto ">
              {filteredHUsers?.map?.((user, index) => {
                const location = `${
                  user?.location_state
                    ? `${user?.location_state?.trim?.()}, `
                    : ""
                }${
                  user?.location_country ? user?.location_country?.trim?.() : ""
                }`;
                const userAge = user?.age?.includes?.("-")
                  ? calculateAge(user?.age)
                    ? calculateAge(user?.age)
                    : ""
                  : moment().diff(moment(user?.age, "DD/MM/YYYY"), "years");
                return (
                  <div key={index} className=" py-1.5">
                    <div className="flex w-full justify-between items-center">
                      <div className="flex gap-2 items-center">
                        <div className="w-6 sm:w-8 h-6 sm:h-8 p-0.5 border border-gray-400 flex-shrink-0 rounded-full">
                          <img
                            src={user?.profile_image_url ?? ASSETS?.Avi}
                            className="rounded-full  object-cover w-full h-full"
                            alt="person"
                          />
                        </div>
                        <div className="space-y-1">
                          <div>
                            <h2 className="text-xs sm:text-sm capitalize font-semibold">
                              {user?.first_name?.toLowerCase?.() +
                                " " +
                                user?.last_name?.toLowerCase?.() +
                                `${userAge ? ", " + userAge : ""}`}
                            </h2>
                            <p className="text-[9px] sm:text-xs w-full flex gap-2 items-center">
                              <WorkOutline fontSize="inherit" />
                              {user?.occupation?.length > 30
                                ? `${user?.occupation?.slice?.(0, 30)}...`
                                : user?.occupation}
                            </p>
                          </div>
                          <div className="flex gap-5 md:gap-8 items-center">
                            <span className="flex gap-1 items-center">
                              <p className="text-[9px] sm:text-xs">
                                <Place fontSize="inherit" />
                              </p>
                              <p className="text-[9px] sm:text-xs font-semibold">
                                {location?.length > 20
                                  ? `${location?.slice(0, 20)}...`
                                  : location}
                              </p>
                            </span>
                            <span className="flex gap-1 items-center">
                              <p className="text-[9px] sm:text-xs">
                                <Person fontSize="inherit" />
                              </p>
                              <p className="text-[9px] sm:text-xs font-semibold">
                                {user?.gender?.toLowerCase?.()}
                              </p>
                            </span>
                            <span className="flex gap-1 items-center">
                              <p className="text-[9px] sm:text-xs">
                                <Group fontSize="inherit" />
                              </p>
                              <p className="text-[9px] sm:text-xs font-semibold">
                                {user?.status?.toLowerCase?.()}
                              </p>
                            </span>
                          </div>
                        </div>
                      </div>
                      <input
                        type="checkbox"
                        onChange={() => {
                          if (pickedUser?.id === user?.id) {
                            return setPickedUser(null);
                          }

                          return setPickedUser(user);
                        }}
                        checked={pickedUser?.id === user?.id}
                        className="w-4 flex-shrink-0 h-4"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex p-3 sm:p-5 w-full justify-end gap-5 bg-gray-50 items-center">
            <button
              onClick={() => {
                setPickedUser(null);
                setOpenSelectUser(false);
              }}
              className="px-4 sm:px-6 py-2 rounded-lg text-center text-xs sm:text-base font-semibold bg-white border border-gray-400 text-gray-700"
            >
              Cancel
            </button>
            <button
              disabled={!pickedUser}
              onClick={() => {
                setFirstParticipant(pickedUser);
                setPickedUser(null);
                setOpenSelectUser(false);
              }}
              className="px-4 sm:px-6 py-2 rounded-lg text-center text-xs sm:text-base font-semibold bg-[#081A51] disabled:bg-gray-300 disabled:text-gray-700 text-white"
            >
              {"Select"}
            </button>
          </div>
        </div>
      </ResponsiveOverlay>
      <ResponsiveOverlay
        isOpen={openSecondSelectUser}
        setIsOpen={() => {
          setPickedUser(null);
          setOpenSecondSelectUser(false);
        }}
        title={"Select user"}
        size="small"
      >
        <HandleSelectUser
          allData={mainUsers?.filter?.(
            (i) =>
              i?.gender?.toLowerCase?.() !==
              firstParticipant?.gender?.toLowerCase?.()
          )}
          pickedUser={pickedUser}
          setPickedUser={setPickedUser}
          setOpenSecondSelectUser={setOpenSecondSelectUser}
          setSecondParticipant={setSecondParticipant}
        />
      </ResponsiveOverlay>
      <div className="space-y-5">
        <div
          onClick={() => {
            setIndexPage(0);
            refetch?.();
          }}
          className="w-max flex text-sm cursor-pointer hover:text-blue-600 font-semibold gap-2 items-center"
        >
          <ArrowBack fontSize="inherit" />
          Back to all schedules
        </div>

        <div className="flex flex-col-reverse lg:items-start lg:flex-row gap-5">
          <div className="border space-y-6 border-[#545f7d82] bg-white rounded-lg px-5 py-6 flex-grow">
            <div className="space-y-3">
              <p className="text-sm font-semibold text-[#222E50]">Date theme</p>
              <input
                className="text-sm py-2.5 outline-none border border-[#545f7d82] rounded bg-white w-full"
                type="text"
                placeholder="e.g. Love at first sight"
                value={dateTheme}
                onChange={(e) => setDateTheme(e.target.value)}
              />
            </div>
            <div className="space-y-3 relative">
              <p className="text-sm font-semibold text-[#222E50]">
                Meeting Date{" "}
                {meetingDate ? `(${moment(meetingDate)?.fromNow?.()})` : ""}
              </p>
              <input
                className={`text-sm py-2.5 outline-none border ${
                  dateError ? "border-red-600" : " border-[#545f7d82] "
                } rounded bg-white w-full`}
                type="datetime-local"
                // placeholder="e.g. Love at first sight"
                value={meetingDate}
                min={getToday()}
                onChange={(e) => {
                  setDateError("");
                  setMeetingDate(e.target.value);
                }}
              />
              {dateError && (
                <p className="text-red-600 text-xs font-medium">{dateError}</p>
              )}
            </div>
            {/* <div className="space-y-3">
              <p className="text-sm font-semibold text-[#222E50]">
                Meeting Note
              </p>
              <textarea
                className="text-sm h-20 resize-none outline-none border border-[#545f7d82] rounded bg-white w-full"
                value={meetingNote}
                onChange={(e) => setMeetingNote(e.target.value)}
                placeholder="Enter short meeting note here"
              ></textarea>
            </div> */}
            <div className="">
              <button
                disabled={
                  isLoading ||
                  !dateTheme?.trim?.() ||
                  !meetingDate?.trim?.() ||
                  // !meetingNote?.trim?.() ||
                  !firstParticipant ||
                  !secondParticipant
                }
                onClick={() => {
                  if (new Date(meetingDate) < new Date()) {
                    return setDateError("Kindly choose a future date");
                  }

                  const mainPayload = {
                    match_user_id: Number(firstParticipant?.id),
                    matched_user_id: Number(secondParticipant?.id),
                    //@ts-ignore
                    user_match_id: Number(secondParticipant?.main_match_id),
                    handler_id: Number(handler?.id),
                    user_match_theme: dateTheme?.trim?.(),
                    user_matched_theme: dateTheme?.trim?.(),
                    user_match_meeting_time: moment
                      .utc(meetingDate)
                      .toISOString(),
                    user_matched_meeting_time: moment
                      .utc(meetingDate)
                      .toISOString(),
                  };

                  createTheBlindDate(mainPayload);
                  // setIndexPage(0);
                }}
                className="px-4 gap-2 flex items-center sm:px-6 py-2 bg-[#081a51] jarkata_sans text-xs md:text-sm rounded-lg text-white disabled:bg-gray-300 disabled:text-gray-700 font-medium"
              >
                {isLoading ? (
                  <BeatLoader size={10} color="#fff" />
                ) : (
                  "Create Date"
                )}
              </button>
            </div>
          </div>
          <div className="w-full lg:w-80 xl:w-96 flex-shrink-0 space-y-5">
            {/* 
                <div className="grid grid-cols-3 gap-2">
                    <div className="gap-2 flex flex-col justify-between">
                    <select className="w-full text-xs sm:text-sm px-4 py-2 rounded-lg">
                        <option value={""}>Gender</option>
                        {GENDERS?.map?.((gene, index) => (
                        <option key={index} value={gene}>
                            {gene}
                        </option>
                        ))}
                    </select>
                    </div>
                    <input
                    type="text"
                    placeholder="Location"
                    className="w-full text-xs sm:text-sm px-4 py-2 rounded-lg"
                    />

                    <div className="gap-2 flex flex-col justify-between">
                    <select className="w-full text-xs sm:text-sm px-4 py-2 rounded-lg">
                        <option value={""}>Age</option>
                        {AGERANGE?.map?.((sub, index) => (
                        <option key={index} value={sub}>
                            {sub}
                        </option>
                        ))}
                    </select>
                    </div>
                </div>
                </div>
              
            </div> */}

            <div className="border w-full divide-y divide-gray-300 border-[#545f7d82] bg-white rounded-lg ">
              <p className="p-4">
                <h2 className="text-sm text-center">
                  {firstParticipant && secondParticipant
                    ? 2
                    : firstParticipant && !secondParticipant
                    ? 1
                    : secondParticipant && !firstParticipant
                    ? 1
                    : 0}{" "}
                  Participants Selected
                </h2>
              </p>
            </div>
            <div className="border w-full divide-y divide-gray-300 border-[#545f7d82] bg-white rounded-lg ">
              {firstParticipant ? (
                <div className="p-4">
                  <div className="space-y-3">
                    <h2 className="text-sm text-center text-blue-600 font-semibold">
                      First Participant
                    </h2>
                    <div className="flex justify-between items-center">
                      <div className="flex gap-5 items-start">
                        <div className="w-6 flex-shrink-0 sm:w-8 h-6 sm:h-8 text-base flex justify-center items-center rounded-full  text-white">
                          <img
                            //@ts-ignore
                            src={
                              firstParticipant?.profile_image_url ?? ASSETS?.Avi
                            }
                            className="rounded-full  object-cover w-full h-full"
                            alt="person"
                          />
                        </div>
                        <div>
                          <h2 className="text-xs sm:text-sm capitalize font-semibold">
                            {firstParticipant?.first_name?.toLowerCase?.() +
                              " " +
                              firstParticipant?.last_name?.toLowerCase?.()}
                          </h2>
                          <p className="text-[9px] sm:text-xs w-full flex gap-2 items-center">
                            <WorkOutline fontSize="inherit" />
                            {firstParticipant?.occupation}
                          </p>
                        </div>
                      </div>
                      {firstParticipantAge && (
                        <p className="text-xs font-medium">
                          {firstParticipantAge}
                        </p>
                      )}
                    </div>
                    <div className="flex gap-5 md:gap-8 items-center">
                      <span className="flex gap-1 items-center">
                        <p className="text-[9px] sm:text-xs">
                          <Place fontSize="inherit" />
                        </p>
                        <p className="text-[9px] sm:text-xs font-semibold">
                          {firstParticipantLocation?.length > 20
                            ? `${firstParticipantLocation?.slice(0, 20)}...`
                            : firstParticipantLocation}
                        </p>
                      </span>
                      <span className="flex gap-1 items-center">
                        <p className="text-[9px] sm:text-xs">
                          <Person fontSize="inherit" />
                        </p>
                        <p className="text-[9px] sm:text-xs font-semibold">
                          {firstParticipant?.gender?.toLowerCase?.()}
                        </p>
                      </span>
                      <span className="flex gap-1 items-center">
                        <p className="text-[9px] sm:text-xs">
                          <Group fontSize="inherit" />
                        </p>
                        <p className="text-[9px] sm:text-xs font-semibold">
                          {firstParticipant?.status?.toLowerCase?.()}
                        </p>
                      </span>
                    </div>
                    <div className="flex gap-5 items-center">
                      <button
                        disabled={secondParticipant ? true : false}
                        onClick={() => {
                          setFirstParticipant(null);
                        }}
                        className="px-4 gap-2 flex items-center sm:px-6 py-2.5 bg-red-600 jarkata_sans text-xs md:text-sm rounded-lg disabled:bg-gray-300 disabled:text-gray-700 text-white font-medium"
                      >
                        <Delete fontSize="inherit" />
                      </button>
                      <button
                        disabled={secondParticipant ? true : false}
                        onClick={() => {
                          if (secondParticipant) {
                            setFilterGender(
                              secondParticipant?.gender?.toLowerCase?.() ===
                                "female"
                                ? "Male"
                                : "Female"
                            );
                          }
                          setPickedUser(firstParticipant);
                          setOpenSelectUser(true);
                        }}
                        className="px-4 gap-2 flex items-center sm:px-6 py-2 border bg-white border-[#081a51] jarkata_sans text-xs md:text-sm disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-700 rounded-lg text-[#081a51] font-medium"
                      >
                        Change user
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="p-4 flex flex-col gap-5 w-full justify-center items-center ">
                  <div className="w-12 h-12 text-base flex justify-center items-center rounded-full bg-gray-800 text-white">
                    <PersonAdd fontSize="inherit" />
                  </div>
                  <button
                    onClick={() => {
                      setOpenSelectUser(true);
                    }}
                    className="px-4 gap-2 flex items-center sm:px-6 py-2 bg-[#081a51] jarkata_sans text-xs md:text-sm rounded-lg text-white font-medium"
                  >
                    Select First User
                  </button>
                </div>
              )}
            </div>
            <div className="border w-full divide-y divide-gray-300 border-[#545f7d82] bg-white rounded-lg ">
              {secondParticipant ? (
                <div className="p-4">
                  <div className="space-y-3">
                    <h2 className="text-sm text-center text-blue-600 font-semibold">
                      Second Participant
                    </h2>
                    <div className="flex justify-between items-center">
                      <div className="flex gap-5 items-start">
                        <div className="w-6 flex-shrink-0 sm:w-8 h-6 sm:h-8 text-base flex justify-center items-center rounded-full  text-white">
                          <img
                            //@ts-ignore
                            src={
                              secondParticipant?.profile_image_url ??
                              ASSETS?.Avi
                            }
                            className="rounded-full  object-cover w-full h-full"
                            alt="person"
                          />
                        </div>
                        <div>
                          <h2 className="text-xs sm:text-sm capitalize font-semibold">
                            {secondParticipant?.first_name?.toLowerCase?.() +
                              " " +
                              secondParticipant?.last_name?.toLowerCase?.()}
                          </h2>
                          <p className="text-[9px] sm:text-xs w-full flex gap-2 items-center">
                            <WorkOutline fontSize="inherit" />
                            {secondParticipant?.occupation}
                          </p>
                        </div>
                      </div>
                      {secondParticipantAge && (
                        <p className="text-xs font-medium">
                          {secondParticipantAge}
                        </p>
                      )}
                    </div>
                    <div className="flex gap-5 md:gap-8 items-center">
                      <span className="flex gap-1 items-center">
                        <p className="text-[9px] sm:text-xs">
                          <Place fontSize="inherit" />
                        </p>
                        <p className="text-[9px] sm:text-xs font-semibold">
                          {secondParticipantLocation?.length > 20
                            ? `${secondParticipantLocation?.slice(0, 20)}...`
                            : secondParticipantLocation}
                        </p>
                      </span>
                      <span className="flex gap-1 items-center">
                        <p className="text-[9px] sm:text-xs">
                          <Person fontSize="inherit" />
                        </p>
                        <p className="text-[9px] sm:text-xs font-semibold">
                          {secondParticipant?.gender?.toLowerCase?.()}
                        </p>
                      </span>
                      <span className="flex gap-1 items-center">
                        <p className="text-[9px] sm:text-xs">
                          <Group fontSize="inherit" />
                        </p>
                        <p className="text-[9px] sm:text-xs font-semibold">
                          {secondParticipant?.status?.toLowerCase?.()}
                        </p>
                      </span>
                    </div>
                    <div className="flex gap-5 items-center">
                      <button
                        // disabled={secondParticipant}
                        onClick={() => {
                          setSecondParticipant(null);
                        }}
                        className="px-4 gap-2 flex items-center sm:px-6 py-2.5 bg-red-600 jarkata_sans text-xs md:text-sm rounded-lg disabled:bg-gray-300 disabled:text-gray-700 text-white font-medium"
                      >
                        <Delete fontSize="inherit" />
                      </button>
                      <button
                        onClick={() => {
                          setPickedUser(secondParticipant);
                          setOpenSecondSelectUser(true);
                        }}
                        className="px-4 gap-2 flex items-center sm:px-6 py-2 border bg-white border-[#081a51] jarkata_sans text-xs md:text-sm rounded-lg text-[#081a51] font-medium"
                      >
                        Change user
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="p-4 flex flex-col gap-5 w-full justify-center items-center ">
                  <div className="w-12 h-12 text-base flex justify-center items-center rounded-full bg-gray-800 text-white">
                    <PersonAdd fontSize="inherit" />
                  </div>
                  <button
                    disabled={!firstParticipant}
                    onClick={() => {
                      setOpenSecondSelectUser(true);
                    }}
                    className="px-4 gap-2 flex items-center sm:px-6 py-2 bg-[#081a51] jarkata_sans text-xs md:text-sm rounded-lg disabled:bg-gray-300 disabled:text-gray-800 text-white font-medium"
                  >
                    Select Second User
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import InputField from "src/components/Input";
import { useMutation } from "@apollo/client";
import { DELETE_REQUEST } from "src/hooks/data/mutations";
const Logo =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/logo.svg";
const Vector =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/vector1.svg";
const People =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/group_people.svg";
const Phone1 =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/phone1.svg";
const Phone2 =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/phone2.svg";
const Phone3 =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/phone3.svg";
const Phone4 =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/phone4.svg";
const LineGroup =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/line_group.svg";

const DeleteAccout = () => {
  const [deleteForm, setDeleteForm] = useState(false);
  const navigate = useNavigate();
  const [deleteAccountRequest, { loading }] = useMutation(DELETE_REQUEST);
  const handleDeleteForm = () => setDeleteForm(!deleteForm);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [reason, setReason] = useState("");

  const handleDeleteAccountRequest = () => {
    if (!email || !password || !phone || !first_name || !reason)
      return alert("Please fill all fields");
    deleteAccountRequest({
      variables: {
        payload: { email, first_name, password, phone, reason },
      },
      onCompleted: (data) => {
        if (data.deleteRequest) {
          navigate("/");
        }
      },
      onError: (error) => {
        console.log(error);
        alert("An error occured");
      },
    });
  };

  return (
    <div className="bg-white min-h-screen">
      <nav className="flex w-full justify-between h-16 border-2 bg-white px-8">
        <img src={Logo} className="w-20 h-10 items-center mt-3" alt="logo" />
        <div className="w-1/5 flex justify-around items-center max-lg:hidden">
          {/* <span 
                onClick={() => navigate('/subscribe')}
                className='w-24 h-10 border-2 text-[#081A51] text-xs flex items-center justify-center font-semibold rounded-lg cursor-pointer'>
                Sign Up
                </span>
               <span className='w-24 h-10 border-2 bg-[#081A51] text-white text-xs flex items-center justify-center font-semibold rounded-lg cursor-pointer'>Login</span> */}
          <span></span>
        </div>
        <img
          src={Vector}
          className="w-8 h-8 lg:hidden items-center mt-3"
          alt="vector"
        />
      </nav>
      <div className="mt-8 w-full flex bg-white px-24 justify-around max-lg:flex-col max-lg:px-4">
        <div className="w-2/4 max-lg:w-full">
          <p className="text-[#222E50] text-4xl w-2/4 font-semibold leading-14 max-lg:w-full">
            {" "}
            Are you looking to discontinue our service with you?{" "}
          </p>
          <p className="text-sm leading-6 text-[#222E50] mt-8">
            We're sorry to hear that you're considering deleting your account
            from the Nirvanae app. We understand that this decision may be a
            personal one, and we respect your choice. Outlined below is a step
            by step process on how to get started or you can fill the account
            delete form to initiate this process
          </p>
          <div
            className="mt-10 h-14 w-2/6 bg-[#081A51] text-white text-xs rounded-lg flex justify-center items-center font-semibold cursor-pointer
                      max-lg:w-[150px] max-lg:m-auto max-lg:h-12 max-lg:mt-10
                "
            onClick={handleDeleteForm}
          >
            Account Delete Form
          </div>
        </div>
        <img src={People} alt="people" className="h-96" />
      </div>
      <div className="flex w-[95%] m-auto bg-white mt-12 ">
        <img src={LineGroup} alt="line" className="h-[800px]" />
        <div className="w-9/12 ml-4">
          <div className="flex justify-between w-full ">
            <div className="flex flex-col">
              <span className="text-[#222E50] font-semibold text-sm">
                Login into the Nirvanae Mobile App{" "}
              </span>
              <span className="text-[#343D48] text-xs">
                Locate the Nirvanae app on your mobile device and login using
                your registered username/email and password
              </span>
            </div>
            <KeyboardArrowUpOutlinedIcon className="mt-3" />
          </div>
          <img src={Phone1} alt="1" className="h-[250px] m-auto my-8" />
          <div className="flex justify-between w-full  mt-16">
            <div className="flex flex-col">
              <span className="text-[#222E50] font-semibold text-sm">
                Click on the profile tab to get started{" "}
              </span>
              <span className="text-[#343D48] text-xs">
                Click on the Profile tab to reveal the settings page and
                navigate your way to the delete account page
              </span>
            </div>
            <KeyboardArrowUpOutlinedIcon className="mt-3" />
          </div>
          <div className="w-full flex justify-around max-lg:flex-col">
            <img src={Phone2} alt="1" className="h-[250px] my-8" />
            <img src={Phone3} alt="1" className="h-[250px] my-8" />
            <img src={Phone4} alt="1" className="h-[250px] my-8" />
          </div>
          <div className="flex justify-between w-full mt-10">
            <div className="flex flex-col">
              <span className="text-[#222E50] font-semibold text-sm">
                Click on the Account delete option{" "}
              </span>
              <span className="text-[#343D48] text-xs">
                Select the account delete option , fill out our little
                questionnaire and authenticate the process . Your account will
                be deleted after 24hours of request
              </span>
            </div>
            <KeyboardArrowUpOutlinedIcon className="mt-3" />
          </div>
          <div className="w-full flex justify-around max-lg:flex-col bg-white">
            <img src={Phone2} alt="1" className="h-[250px] my-8" />
            <img src={Phone3} alt="1" className="h-[250px] my-8" />
            <img src={Phone4} alt="1" className="h-[250px] my-8" />
          </div>
        </div>
      </div>
      {deleteForm && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center">
          <div className="w-[40%] h-[700px] max-lg:h-[850px] mt-20 bg-white relative max-lg:w-[95%] rounded-md flex flex-col">
            <div className="bg-[#081A51] w-full h-[45px] flex px-6 justify-between items-center">
              <span className="text-sm text-white font-semibold ml-2">
                Account Delete From
              </span>
              <span
                className="text-white font-semibold text0-sm cursor-pointer"
                onClick={() => setDeleteForm(false)}
              >
                X
              </span>
            </div>
            <div className=" w-[85%] self-center">
              <div className="w-full flex justify-between max-lg:flex-col max-lg:justify-normal">
                <InputField
                  labelView
                  labelStyle="text-[#11142D] text-xs font-semibold"
                  label="Firstname"
                  inBox="w-[80%] border-2 border-slate-300 rounded-md h-8"
                  inputClassName="ml-2 bg-white max-lg:text-xs w-5/6 h-6 appearance-none border-0 focus:outline-none focus:border-transparent focus:ring-0"
                  onChange={(e) => setFirst_name(e.target.value)}
                  value={first_name}
                />
                <InputField
                  labelView
                  labelStyle="text-[#11142D] text-xs font-semibold"
                  label="Phone Number"
                  inBox="w-[80%] border-2 border-slate-300 rounded-md h-8"
                  inputClassName="ml-2 bg-white max-lg:text-xs appearance-none w-5/6 h-6 border-0 focus:outline-none focus:border-transparent focus:ring-0"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />
              </div>
              <div className="w-full flex justify-between max-lg:flex-col max-lg:justify-normal">
                <InputField
                  labelView
                  labelStyle="text-[#11142D] text-xs font-semibold"
                  label="Email"
                  type="email"
                  inBox="w-[80%] border-2 border-slate-300 rounded-md h-8"
                  inputClassName="ml-2 bg-white max-lg:text-xs appearance-none w-5/6 h-6 border-0 focus:outline-none focus:border-transparent focus:ring-0"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <InputField
                  labelView
                  labelStyle="text-[#11142D] text-xs font-semibold"
                  label="Password"
                  type="password"
                  inBox="w-[80%] border-2 border-slate-300 rounded-md h-8"
                  inputClassName="ml-2 bg-white max-lg:text-xs appearance-none w-5/6 h-6 border-0 focus:outline-none focus:border-transparent focus:ring-0"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </div>
              <div className="w-full flex justify-start flex-col mt-8">
                <label className="text-[#11142D] text-xs font-semibold">
                  Reason for Closing Account
                </label>
                <textarea
                  placeholder="Write your reason  here"
                  className="w-[90%] h-[150px] border-slate-300 rounded-md mt-2  focus:outline-none focus:border-slate-300 focus:ring-0 text-sm"
                  onChange={(e) => setReason(e.target.value)}
                  value={reason}
                ></textarea>
              </div>
            </div>
            <button
              type="button"
              className={`w-[100px] h-[40px] bg-[${
                loading ? "bg-disabled-1" : "#081A51"
              }] text-white font-semibold text-xs m-auto mt-8 rounded-lg flex items-center justify-center cursor-pointer`}
              disabled={loading}
              onClick={() => {
                handleDeleteAccountRequest();
              }}
            >
              Close Account
            </button>
            <p className="text-xs mt-10 text-rose-600 leading-4 absolute bottom-3 px-2 text-center">
              Please insure that you input the correct details in order to
              validate the authenticity of your account with us. Account
              deletion usually takes less than 24hours after request has been
              made to be processed
            </p>
          </div>
        </div>
      )}
      <div className="flex w-full justify-start items-center h-16 border-2 bg-[#081A51] px-8 mt-28">
        <p className="text-white text-xs">
          copyright @ 2022-2023 Nirvanae. All Rights Reserved
        </p>
      </div>
    </div>
  );
};
// border-indigo-600
export default DeleteAccout;

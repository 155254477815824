import { Instagram } from "@mui/icons-material";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link as NavLink, useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import ASSETS from "src/asset";
import Cards from "src/components/Cards";
import Header from "src/components/header/Homeheader";
import XIcon from "@mui/icons-material/X";
import Testimonials from "src/components/new_home/Testimonials";
import Pricing from "src/components/new_home/Pricing";
import ReactPlayer from 'react-player'
export default function NewHome() {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="bg-[#F7F7F8]">
      <Header />
      <div className="container px-6 md:px-10 overflow-hidden mx-auto">
        <main className="space-y-24 md:space-y-32 lg:space-y-40 pt-12 ">
          {/* hero */}
          <div className=" flex flex-col items-center gap-10 justify-between">
            <div className="w-full md:w-5/6 md:mt-10 relative flex flex-col items-center space-y-5 mx-auto">
              <div className="bg-white relative p-3 md:p-5 flex gap-2 sm:gap-6 items-center">
                <img
                  src={ASSETS.TopLine}
                  alt="layer"
                  className="w-3 h-3 sm:w-6 sm:h-6 absolute z-10 -top-2 -left-2 sm:-top-4 sm:-left-3"
                />
                {/* <img
                  src={ASSETS.HeartCircle}
                  alt="layer"
                  className="w-3 h-3 sm:w-6 sm:h-6 absolute z-10 right-5 top-full mt-2 sm:mt-4"
                /> */}
                <div className="w-6 h-6 sm:w-10 sm:h-10 flex-shrink-0 rounded-sm text-[#FF7BAC] bg-[#FAD0E0] flex justify-center items-center">
                  <img
                    src={ASSETS.Lightning}
                    alt="layer"
                    className="w-3 h-3 sm:w-6 sm:h-6"
                  />
                </div>
                <h1 className=" text-xs sm:text-base md:text-xl lg:text-2xl xl:text-4xl main_thick_blue playfair font-semibold ">
                  Let
                  <span className="text-[#FF7BAC]"> Mental health </span>
                  experts guide you to love. 
                </h1>
              </div>
              <div className=" w-5/6 sm:w-8/12 xl:w-9/12 relative ">
                <p className="text-[11px] sm:text-sm lg:text-lg text-center jarkata_sans">
                  You get a handler, a professional clinical psychologist who serves as not only your handler, but your gossip partner! Now you can meet people while talking about them with someone who cares. Join our community of lovers. Sign up now!
                </p>
                  <img
                  src={ASSETS.HeartCircle}
                  alt="layer"
                  className="w-3 h-3 sm:w-6 sm:h-6 absolute z-10 -right-6 top-0 "
                />
                <img
                  src={ASSETS.HeartCircle}
                  alt="layer"
                  className="w-3 h-3 sm:w-6 sm:h-6 absolute z-10 left-5 top-full "
                />
              </div>

              <div className="flex gap-4 pt-5 items-center">
                <button
                  onClick={() => {
                    navigate("/pre-verify");
                  }}
                  className="px-4 sm:px-6 py-2 bg-[#081a51] jarkata_sans text-xs md:text-base rounded-full text-white font-medium"
                >
                  Get Started
                </button>

                {/* <Link
                  activeClass="active"
                  to="pricing"
                  spy={true}
                  smooth={true}
                  offset={-90}
                  duration={500}
                  className="px-4 sm:px-6 py-2 cursor-pointer bg-white jarkata_sans text-xs sm:text-base rounded-full font-medium"
                >
                  View Pricing
                </Link> */}
              </div>
            </div>
            <div className="w-full">
              <Cards />
            </div>
          </div>
          {/* about */}
          <div
            id="about"
            className=" flex flex-col gap-16  md:flex-row justify-between items-center  xl:max-w-7xl xl:mx-auto "
          >
            {/* text */}
            <div className="w-full text-brand-1 max-w-sm md:max-w-full md:w-1/2  space-y-7">
              <div
                // data-aos="fade-up"
                className=" w-1/2 sm:w-3/4 flex gap-5 justify-center mx-auto md:mx-0 sm:justify-end items-center"
              >
                <p className="flex-grow h-0.5 bg-[#FF7BAC]"></p>
                <h2 className="text-[#253D5B] w-max flex-shrink-0 font-bold playfair">
                  About Us
                </h2>
              </div>
              <h2
                // data-aos="fade-up-right"
                className="text-base text-center md:text-left md:text-xl lg:text-3xl xl:text-4xl playfair font-semibold main_thick_blue"
              >
                A Different Kind of Matchmaking
              </h2>

              <h2
                // data-aos="fade-up-right"
                className="text-sm text-justify md:text-left text-[#223333] lg:text-base font-medium"
              >
                The Nirvanae web app is a tech enabled product of Nirvanae Tech
                Group. We are a social tech company with interests in products,
                services, and platforms that bring people together. Join our
                community of lovers on the Nirvanae web app and form new
                friendships, find new networks, and even, find your perfect
                match. Your handler guides you, and provides after match support
                so that if you have any questions or difficulties with your
                matches, we are providing the best support of any platform in
                the world. So what are you waiting for? Become a Nirvanian. JOIN
                US!
              </h2>
              <div className="flex justify-center md:justify-start gap-1 w-full items-center">
                <button
                  //   data-aos="fade-up"
                  onClick={() => {
                    navigate("/pre-verify");
                  }}
                  data-aos-duration="2000"
                  className="px-4 sm:px-6 py-2 bg-[#081a51] jarkata_sans text-xs md:text-base rounded-full text-white font-medium"
                >
                  Get Started
                </button>
              </div>
            </div>
            {/* image */}
            <div
              //   data-aos="fade-up-left"
              className=" relative w-full md:w-1/2   "
            >
              <img
                src={ASSETS.UserViews}
                alt="couple in the garden"
                className="object-cover w-full max-w-xs mx-auto  md:max-w-md "
              />
            </div>
          </div>
          {/* <Testimonials /> */}
          {/* testominy */}
           <div id="testimonies" className="space-y-5">
      <div
        // data-aos="fade-up"
        className="w-1/2 md:w-1/4 flex gap-5 justify-center mx-auto sm:justify-end items-center"
      >
        <p className="flex-grow h-0.5 bg-[#FF7BAC]"></p>
        <h2 className="text-[#253D5B] w-max flex-shrink-0 font-bold playfair">
          Testimonials
        </h2>
      </div>
      <div>
        <h2
          // data-aos="fade-up-right"
          className="text-base text-center md:text-xl lg:text-3xl playfair font-semibold main_thick_blue"
        >
          Our Success Stories Speak for themselves
        </h2>
        <p className="text-sm  text-[#223333] xl:text-lg text-center pt-1">
          Don’t just take our words for it ,listen to what our clients have to
          say about us
        </p>
      </div>

{/* video */}
 <div className='flex justify-center items-center h-full rounded-3xl  w-full py-6 md:py-8 xl:py-12 '>
  
              <div className='player-wrapper rounded-xl h-[50vh] lg:h-[70vh] w-full px-4 sm:max-w-lg md:max-w-2xl  py-4   lg:max-w-5xl  xl:max-w-7xl relative ring ring-[#ff7babbf]   ring-offset-1 lg:ring-offset-2'>
                <ReactPlayer
                  className='react-player player-wrapper '
                  url="https://youtu.be/dz_-yOV92A4?si=baezjFhzsHm-alsJ"
                  width='100%'
                  height='100%'
                  controls={true}
                  playing={true}
                  light={false}
                />
              
              </div>
            </div>
    
    </div>
          {/* getting started */}
          <div
            id="onboard"
            className=" flex flex-col gap-10 md:gap-5 md:flex-row justify-between items-center mx-auto max-w-7xl  px-6 "
          >
            {/* text */}
            <div className="w-full text-brand-1 md:w-1/2 max-w-sm md:max-w-full space-y-7">
              <div className=" w-1/2 sm:w-3/4 flex gap-5 justify-center mx-auto md:mx-0 sm:justify-end items-center">
                <p className="flex-grow h-0.5 bg-[#FF7BAC]"></p>
                <h2 className="text-[#253D5B] w-max flex-shrink-0 font-bold playfair">
                  Getting Started
                </h2>
              </div>
              <h2 className="text-lg text-center md:text-left md:text-xl lg:text-3xl xl:text-4xl playfair font-semibold main_thick_blue">
                Onboarding just got easier
              </h2>

              <h2 className="text-sm  text-justify md:text-left font-medium  text-[#223333] lg:text-base">
                Excited to dive into the world of matchmaking? Our platform
                offers personalized assistance every step of the way. From
                signing up to finding your perfect match, our dedicated handlers
                are here to guide you through the process. Let us help you
                navigate the journey to finding love and meaningful connections.
                Start your matchmaking adventure today!
              </h2>
              <div className="flex justify-center md:justify-start gap-1 w-full items-center">
                <Link
                  activeClass="active"
                  to="touch"
                  spy={true}
                  smooth={true}
                  offset={-90}
                  duration={500}
                  className="px-4 sm:px-6 cursor-pointer py-2 bg-[#081a51] jarkata_sans text-xs md:text-base rounded-full text-white font-medium"
                >
                  Get in Touch
                </Link>
              </div>
            </div>
            {/* image */}
            <div className="rounded-3xl relative w-full md:w-1/2 lg:w-2/5  ">
              <div className="relative">
                <img
                  src={ASSETS.RedBoard}
                  alt="layer"
                  className="w-5 h-5 sm:w-10 sm:h-10 absolute z-10 -right-2 -top-2 md:-right-5 md:-top-6"
                />
                <img
                  src={ASSETS.RedBoard}
                  alt="layer"
                  className="w-5 h-5 sm:w-10 sm:h-10 absolute z-10 -left-2 md:-left-5 -bottom-2 md:-bottom-6"
                />
                <div className="relative rounded-tl-3xl z-20  rounded-br-3xl bg-[#F1F2FA]">
                  <div className="relative w-11/12 md:w-4/5 mx-auto  space-y-5 py-5 md:py-10">
                    <div className="bg-white p-4 flex gap-2 items-center rounded-lg">
                      <div className="w-8 h-8 md:w-10 md:h-10 bg-[#F0F1F9] flex-shrink-0 rounded-md flex justify-center items-center">
                        <img
                          src={ASSETS.UserPlus}
                          alt="layer"
                          className="w-3 h-3 sm:w-5 sm:h-5"
                        />
                      </div>
                      <div>
                        <h2 className="text-sm xl:text-base font-bold playfair">Sign Up</h2>
                        <p className="text-[10px] xl:text-sm">
                          Begin by creating your account on our platform. It's
                          quick and easy!
                        </p>
                      </div>
                    </div>
                    <div className="bg-white p-4 flex gap-2 items-center rounded-lg">
                      <div className="w-8 h-8 md:w-10 md:h-10 bg-[#F0F1F9] flex-shrink-0 rounded-md flex justify-center items-center">
                        <img
                          src={ASSETS.UserCheck}
                          alt="layer"
                          className="w-3 h-3 sm:w-5 sm:h-5"
                        />
                      </div>
                      <div>
                        <h2 className="text-sm  xl:text-base  font-bold playfair">
                          Complete Your Profile
                        </h2>
                        <p className="text-[10px]  xl:text-sm">
                          Tell us about yourself and what you're looking for in
                          a partner. The more details you provide, the better we
                          can match you.
                        </p>
                      </div>
                    </div>
                    <div className="bg-white p-4 flex gap-2 items-center rounded-lg">
                      <div className="w-8 h-8 md:w-10 md:h-10 bg-[#F0F1F9] flex-shrink-0 rounded-md flex justify-center items-center">
                        <img
                          src={ASSETS.Users}
                          alt="layer"
                          className="w-3 h-3 sm:w-5 sm:h-5"
                        />
                      </div>
                      <div>
                        <h2 className="text-sm  xl:text-base  font-bold playfair">
                          Handler Assignment
                        </h2>
                        <p className="text-[10px]  xl:text-xs">
                          A handler will be assigned to you, who will be tasked
                          with guiding & finding people who are perfect matches
                          for you
                        </p>
                      </div>
                    </div>
                    <div className="bg-white p-4 flex gap-2 items-center rounded-lg">
                      <div className="w-8 h-8 md:w-10 md:h-10 bg-[#F0F1F9] flex-shrink-0 rounded-md flex justify-center items-center">
                        <img
                          src={ASSETS.Heart}
                          alt="layer"
                          className="w-3 h-3 sm:w-5 sm:h-5"
                        />
                      </div>
                      <div>
                        <h2 className="text-sm  xl:text-base  font-bold playfair">
                          Start Connecting
                        </h2>
                        <p className="text-[10px]  xl:text-xs">
                          Initiate contact and start getting to know each other
                          through our secure messaging system.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <img
                src={ASSETS.UserViews}
                alt="couple in the garden"
                className="object-cover w-full max-w-xs mx-auto md:mx-0 md:max-w-xs lg:max-w-md"
              /> */}
            </div>
          </div>
          {/* why */}
          <div className="space-y-20 px-6 md:space-y-0 md:flex md:items-center md:justify-center md:gap-14 lg:gap-8  pt-6 lg:pt-10  ">
            {/* image */}
            <div className="flex  justify-center items-center md:w-1/2 mx-auto w-full">
              <img
                src={ASSETS.JoinUser}
                alt="layer"
                className="object-cover w-full max-w-xs md:max-w-xs xl:max-w-md "
              />
            </div>
            {/* text */}
            <div className="flex flex-col  space-y-6 sm:space-y-8 xl:space-y-10 md:w-1/2 ">
              <div
                // data-aos="fade-up"
                className="w-3/5 sm:w-1/2 flex gap-5 justify-center mx-auto md:mx-0 sm:justify-start items-center"
              >
                <h2 className="text-[#253D5B] w-max flex-shrink-0 font-bold playfair">
                  Why Nirvanae ?
                </h2>
                <p className="flex-grow h-0.5 bg-[#FF7BAC]"></p>
              </div>
              <div>
                <h2
                  // data-aos="fade-up-right"
                  className="text-base text-center md:text-left md:text-xl lg:text-3xl playfair font-semibold main_thick_blue"
                >
                  Wondering what all the fuss is about? 
                </h2>
                <p className="text-xs text-center  text-[#223333] lg:text-base  pt-1 max-w-xs md:max-w-full mx-auto md:mx-0 md:text-left">
                   There are many reasons why we stand out, but let’s save time by giving you 3
                </p>
              </div>
              <div>
                <div className="relative flex gap-10 pb-10">
                  <div className="absolute w-2 z-10 left-3 bg-[#FF7BAC] h-full "></div>
                  <div className="w-8 relative z-20 h-8 flex-shrink-0 flex justify-center items-center rounded-full bg-gradient-to-b from-[#914F68] to-[#F786B2]  ">
                    <div className="w-1/2 bg-white h-1/2 font-semibold text-[#914F68] rounded-full flex justify-center items-center text-xs ">
                      1
                    </div>
                  </div>
                  <div className="space-y-1">
                    <h2 className="text-base lg:text-lg playfair font-semibold">
                    Sanitized Userbase
                    </h2>
                    <p className="text-xs lg:text-sm">
                   Our thorough screening process ensures that only members who we believe share our values and can find fulfillment with us, are accepted.
                    </p>
                  </div>
                </div>
                <div className="relative flex gap-10 pb-10">
                  <div className="absolute w-2 z-10 left-3 bg-[#FF7BAC] h-full "></div>
                  <div className="w-8 h-8 relative z-20 flex-shrink-0 flex justify-center items-center rounded-full bg-gradient-to-b from-[#914F68] to-[#F786B2] ">
                    <div className="w-1/2 bg-white h-1/2 font-semibold text-[#914F68] rounded-full flex justify-center items-center text-xs ">
                      2
                    </div>
                  </div>
                  <div className="space-y-1">
                    <h2 className="text-base lg:text-lg playfair font-semibold">
                   A dedicated handler 
                    </h2>
                    <p className="text-xs lg:text-sm">
                     Think of this person like your gossip partner, a certified clinical psychologist to guide you in your journey to love.
                    </p>
                  </div>
                </div>
                <div className="relative flex gap-10 pb-10 ">
                  <div className="absolute w-2 z-10 left-3 bg-[#FF7BAC] h-full "></div>
                  <div className="w-8 h-8 flex-shrink-0 flex justify-center items-center rounded-full bg-gradient-to-b from-[#914F68] to-[#F786B2] z-20">
                    <div className="w-1/2 bg-white h-1/2 font-semibold text-[#914F68] rounded-full flex justify-center items-center text-xs ">
                      3
                    </div>
                  </div>
                  <div className="space-y-1">
                    <h2 className="text-base lg:text-lg playfair font-semibold">
                     After match support
                    </h2>
                    <p className="text-xs lg:text-sm">
                      We just don’t pair you and leave you hanging. You can lay complaints about your match, or make suggestions and we would work to see any issue resolved.
                    </p>
                  </div>
                </div>
                <div className="relative flex gap-10 ">
                  <div className="w-8 h-8 flex-shrink-0 flex justify-center items-center rounded-full bg-gradient-to-b from-[#914F68] to-[#F786B2] ">
                    <div className="w-1/2 bg-white h-1/2 font-semibold text-[#914F68] rounded-full flex justify-center items-center text-xs ">
                      4
                    </div>
                  </div>
                  <div className="space-y-1">
                    <h2 className="text-base lg:text-lg playfair font-semibold">
                     No “Hook Ups”
                    </h2>
                    <p className="text-xs lg:text-sm">
                      Unlike most other dating apps for Africans, Nirvanae has a strict No hook up/ prostitution policy. Users who engage or induce such acts will be swiftly blacklisted.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <Pricing /> */}
{/* get in touch */}
          <div
            id="touch"
            className="space-y-20 md:space-y-0 md:flex md:items-center md:justify-center md:gap-14 lg:gap-8 px-6  py-6 lg:py-10  "
          >
            <div className="flex justify-center items-center md:w-1/2">
              <img
                src={ASSETS.Intouch}
                alt="layer"
                className="object-cover w-full max-w-xs md:max-w-xs  "
              />
            </div>
            <div className="flex flex-col  space-y-6 sm:space-y-8 xl:space-y-10 md:w-1/2 ">
              <div
                // data-aos="fade-up"
                className=" w-1/2 sm:w-3/5 flex gap-5 justify-center mx-auto md:mx-0 sm:justify-start items-center"
              >
                <p className="flex-grow h-0.5 bg-[#FF7BAC]"></p>
                <h2 className="text-[#253D5B] w-max flex-shrink-0 font-bold playfair">
                  Get in Touch
                </h2>
              </div>
              <div className="relative w-11/12 md:w-4/5">
                <div className="bg-white  z-20 border-l-8 relative p-5 border-[#FC87B3]">
                  <p className="text-sm mb-4  text-[#223333] ">
                   Are you having issues with subscription? Or do you want more information? Send us a mail for whatever you need.
                  </p>
                  <a
                    href={"mailto:support@nirvanaeng.com"}
                    className="px-4 sm:px-6 cursor-pointer py-2 bg-[#081a51] jarkata_sans text-xs md:text-base rounded-full text-white font-medium"
                  >
                    Get in Touch
                  </a>
                </div>
                <div className="absolute z-10 w-48 h-32 bg-gradient-to-r from-[#ff7bab7e] to-[#081a517c] -right-10 md:-right-20 top-full -mt-24 md:-mt-20"></div>
              </div>
            </div>
          </div>
          {/* ready to join? */}
           <div
            id=""
            className=" flex flex-col gap-10  xl:max-w-7xl mx-auto pt-6 lg:pt-10 "
          >
            {/* text */}
            <div className="w-full text-brand-1   space-y-3 lg:space-y-5">
              <div
                // data-aos="fade-up"
                className=" w-1/2  flex flex-row-reverse gap-5 justify-center  items-center mx-auto"
              >
                <p className="flex-grow h-0.5 bg-[#FF7BAC]"></p>
                <h2 className="text-[#253D5B] w-max flex-shrink-0 font-bold playfair">
                Ready to join our community ?
                </h2>
              </div>
              <h2
                // data-aos="fade-up-right"
                className="text-base text-center  md:text-xl lg:text-2xl xl:text-3xl playfair font-semibold main_thick_blue"
              >
              Then let’s see if you are a perfect fit
              </h2>

              <h2
                // data-aos="fade-up-right"
                className="text-xs text-center text-[#223333] lg:text-base  font-medium max-w-xs sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-4xl mx-auto"
              >
                We’d like you to answer a few questions. This will help us ensure you are eligible and match you with compatible like-minded individuals. Not to worry, this will only take a moment.
              </h2>
              <div className="flex justify-center gap-1 w-full items-center">
                <button
                  //   data-aos="fade-up"
                 onClick={() => {
                    navigate("/pre-verify");
                  }}
                  data-aos-duration="2000"
                  className="px-4 sm:px-6 py-2 bg-[#081a51] jarkata_sans text-xs md:text-base rounded-full text-white font-medium"
                >
                  Get Started
                </button>
              </div>
            </div>
            {/* image */}
            <div
              //   data-aos="fade-up-left"
              className=" relative w-full  "
            >
              <img
                src={ASSETS.join}
                alt="couple in the garden"
                className="object-cover w-full max-w-5xl mx-auto "
              />
            </div>
          </div>
        </main>
      </div>
      {/* footer */}
      <footer className="bg-[#1E293B]  ">
        <div className="flex flex-col justify-between p-10 items-center  md:flex-row  space-y-6 md:space-y-0">
          <NavLink to={"/"}>
            <img src={ASSETS.nLogo} alt="logo" className="w-24" />
          </NavLink>
          <div className="hidden md:flex gap-3 md:gap-5 xl:gap-10 items-center">
            <NavLink to="#">
              <p className="jarkata_sans text-white text-xs md:text-sm font-semibold">
                About Us
              </p>
            </NavLink>
            <NavLink to="#">
              <p className="jarkata_sans text-white text-xs md:text-sm font-semibold">
                How it Works
              </p>
            </NavLink>
            <NavLink to="#">
              <p className="jarkata_sans text-white text-xs md:text-sm font-semibold">
                Testimonies
              </p>
            </NavLink>
            <NavLink to="#">
              <p className="jarkata_sans text-white text-xs md:text-sm font-semibold">
                Contact us
              </p>
            </NavLink>
          </div>
          <div className="flex items-center gap-5 justify-center ">
            <a href="https://instagram.com/nirvanaehub" className=" text-white">
              <Instagram fontSize="small" color="inherit" />
            </a>
            <a
              href="https://x.com/nirvanaehub?t=DTFZyXHl7N4ooju9kDygJw&s=08"
              className="text-white"
            >
              <XIcon fontSize="small" color="inherit" />
            </a>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-1 items-center justify-center">
          <p className="text-[10px] sm:text-xs p-10 text-[#CBD5E1] border-t border-[#1E293B] font-semibold">
            © Copyright 2020 - {new Date().getFullYear()} Nirvanae. All Rights Reserved
          </p>
        </div>
      </footer>
    </div>
  );
}

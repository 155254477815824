// import moment from 'moment';
import moment from 'moment-timezone';
import React, { useState } from 'react'
import { FaVideoSlash } from 'react-icons/fa6'
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { useMutation, useQuery } from '@apollo/client';
import {  GET_DATE_REQUESTS } from '../auth/data/queries';
import { useUser } from "src/components/auth/hooks/UserProvider";
import { ACCEPT_BLIND_DATE, BLIND_DATE_ACTIONS } from '../auth/data/mutation';
import { toast } from 'react-toastify';
function Requests({refetch:callupcoming }) {
    const [requests,setRequests] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
     const [requestsPerPage, setRequestsPerPage] = useState(2);
     const [isLoading,setIsLoading]= useState(false)
const calculateAge = (birthdate) => {
    const birthDate = new Date(birthdate);
    const ageDiffMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDiffMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  
    interface User {
  id: number;
  username: string;
  occupation: string;
  age: number;
   stateOfOrigin:string;
    countryOfOrigin:string;
     locationBased:string;
     date:Date,
      gender: 'Male' | 'Female'
}
 
const {user} = useUser()
const {data,loading,refetch} = useQuery(GET_DATE_REQUESTS,{
  variables:{
  getBlindDateRequestMatchesId: Number(user?.id)
  },
  fetchPolicy: "no-cache"
})

// console.log("reqdata",data)

const [sendblinddateactions] = useMutation(
  BLIND_DATE_ACTIONS
)

const [acceptblinddate] = useMutation(
ACCEPT_BLIND_DATE
)


  
const handleAccept = (id:any)=>{
  // console.log("matchid",id)
  // console.log("userid",user.id)
  setIsLoading(true)
  acceptblinddate(
  { variables:{
 "payload": {
    "blind_date_id": Number(id),
    "user_id": Number(user.id)
  }
  },
  onCompleted:(data)=>{
    callupcoming()
  refetch()
  setIsLoading(false)
  toast.success("Match Accepted Successfull")
},
onError:(error)=>{
  setIsLoading(false)
  toast.error(error?.message)
}
   
}
  )
}

const handleReject = (id:any)=>{
  setIsLoading(true)
  sendblinddateactions(
    {
      variables:{
  "updateBlindDateId": Number(id),
  "payload": {
    "match_reject": true,
    "match_user_id": Number(user.id)
  },
 
},
onCompleted:(data)=>{
  refetch()
  setIsLoading(false)
  toast.success("Match Reject Successfull")
},
onError:(error)=>{
  setIsLoading(false)
  toast.error(error?.message)
}
    }
  )
}



function occupationArticle(occupation: string): string {
  const vowels = ['a', 'e', 'i', 'o', 'u'];
  const firstChar = occupation?.toLowerCase()[0];

  if (vowels.includes(firstChar)) {
    return 'an';
  } else {
    return 'a';
  }}
function genderArticle(gender: string): string {
 
  const usergender = gender?.toLowerCase()[0];

  if (usergender === "male") {
    return 'He';
  } else {
    return 'She';
  }}

  // Calculate pagination

  const updatedRequest = data?.
getBlindDateRequestMatches?.filter((i)=> i?.match_reject !== true && i?.matched_reject !== true) 
  // console.log("updated", updatedRequest)
  const indexOfLastRequest = currentPage * requestsPerPage;
  const indexOfFirstRequest = indexOfLastRequest - 2;
  const currentRequests = updatedRequest?.slice(indexOfFirstRequest, indexOfLastRequest);
  const totalPages = Math.ceil(updatedRequest?.length /2 );
// console.log("current" ,currentRequests)
// console.log(totalPages)
console.log("sent requests",  updatedRequest)
  // Handle items per page change
  const handleRequestsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newRequestsPerPage = parseInt(event.target.value, 10);
    setRequestsPerPage(newRequestsPerPage);
    setCurrentPage(1); 
    
  };


  // Generate dropdown options
  const getDropdownOptions = (): JSX.Element[] => {
    const options: JSX.Element[] = [];
    for (let i = 1; i <= totalPages; i++) {
      options.push(<option key={i} value={i *2}>{i}</option>);
    }
    return options;
  };


  return (
 <div className='py-2 sm:py-4 md:py-6 px-2 sm:px-4 md:px-6 h-full'>
    <div className='flex justify-between items-center border-b pb-2 sm:pb-3'>
        <h1 className='text-sm md:text-base font-semibold xl:text-lg'>Date requests</h1>
       {currentRequests?.length > 0 && currentRequests?.[0] !== null  && <div className='flex items-center space-x-2 flex-shrink-0'>
            <span className='text-xs lg:text-sm'>Showing</span>
           <select
                        value={requestsPerPage}
                        onChange={handleRequestsPerPageChange}
                        className='border border-gray-300 rounded-md px-6 lg:px-8 py-1 lg:py-2 text-xs'
                    >
                        {getDropdownOptions()}
                    </select>
            <span className='text-xs lg:text-sm'>out of {totalPages}</span>
        </div>}
    </div>
    {loading &&
      <div className="md:bg-white ">
          <div className="gap-3 p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            <div className="max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden">
              <div className="animate-pulse bg-gray-200 h-24 w-full"></div>
              <div className="p-6">
                <div className="animate-pulse bg-gray-200 w-1/4 h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-full h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-3/4 h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-1/2 h-4 mb-2"></div>
              </div>
            </div>
            <div className="max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden">
              <div className="animate-pulse bg-gray-200 h-24 w-full"></div>
              <div className="p-6">
                <div className="animate-pulse bg-gray-200 w-1/4 h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-full h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-3/4 h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-1/2 h-4 mb-2"></div>
              </div>
            </div>
            <div className="max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden">
              <div className="animate-pulse bg-gray-200 h-24 w-full"></div>
              <div className="p-6">
                <div className="animate-pulse bg-gray-200 w-1/4 h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-full h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-3/4 h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-1/2 h-4 mb-2"></div>
              </div>
            </div>
          </div>
        </div>
    }
   {!loading && <div className={`${currentRequests?.length > 0 && currentRequests?.[0] !== null  ? 'h-max grid grid-cols-1 sm:grid-cols-2 gap-2 pt-4 w-full':'h-full  pt-2  w-full'}`}>
        {currentRequests?.length > 0 && currentRequests?.[0] !== null ? (
            currentRequests?.map((i, index) => (

                <div key={index}>
                    <div className='border rounded-md hover:shadow-md  px-2 lg:px-3 xl:px-4  flex flex-col justify-between  py-2 lg:py-3 xl:py-4  space-y-3 h-full'>
                      {/* first */}
                      <div className='space-y-3'> 
                         <div className='flex items-center gap-2'>
                            <div className='w-14 lg:w-12 xl:w-16 flex-shrink-0'>
                                <img src="/images/hidden.png" alt="" />
                            </div>
                            <h1 className='font-medium text-sm  md:text-base xl:text-lg truncate'>{i?.user_match?.user_matched?.alais}</h1>
                        </div>
                         <p className='text-xs xl:text-sm '>{i?.i_match?.i_matched?.alais} is {occupationArticle(i?.user_match?.user_matched?.occupation)} {i?.user_match?.user_matched?.occupation} aged {i?.user_match?.user_matched?.age?.includes?.("-")
    ? //@ts-ignore
      calculateAge(i?.user_match?.user_matched?.age)
      ? //@ts-ignore
        calculateAge(i?.user_match?.user_matched?.age)
      : ""
    : moment().diff(
        //@ts-ignore
        moment(i?.user_match?.user_matched?.age, "DD/MM/YYYY"),
        "years"
      ) ?? ""}. {i?.user_match?.user_matched?.gender === 'Male' ? 'He' : 'She'} is from {i?.user_match?.user_matched?.origin_state}, {i?.user_match?.user_matched?.origin_country} and currently lives in {i?.user_match?.user_matched?.location_state} , {i?.user_match?.user_matched?.location_country}</p>
                      </div>
                       
                        {/* second */}
                       <div className='space-y-3'>

                         <div className='flex items-center flex-wrap gap-2'>
          {Number(i?.matched_user_id) === Number(user?.id) && !i?.matched_accept ? (
            <>
              <button disabled={isLoading} onClick={() => handleAccept(i?.id)} className='text-green-700 border border-green-500 px-6 rounded-md py-1 text-xs lg:text-sm font-medium hover:bg-green-500 hover:text-white hover:shadow-md flex-grow'>Accept</button>
              <button disabled={isLoading} onClick={() => handleReject(i?.id)} className='text-red-700 border border-red-500 px-6 rounded-md py-1 text-xs lg:text-sm font-medium hover:bg-red-500 hover:text-white hover:shadow-md flex-grow'>Reject</button>
            </>
          ): Number(i?.match_user_id) === Number(user?.id) && !i?.match_accept  ?(
            <>
              <button disabled={isLoading} onClick={() => handleAccept(i?.id)} className='text-green-700 border border-green-500 px-6 rounded-md py-1 text-xs lg:text-sm font-medium hover:bg-green-500 hover:text-white hover:shadow-md flex-grow'>Accept</button>
              <button disabled={isLoading} onClick={() => handleReject(i?.id)} className='text-red-700 border border-red-500 px-6 rounded-md py-1 text-xs lg:text-sm font-medium hover:bg-red-500 hover:text-white hover:shadow-md flex-grow'>Reject</button>
            </>
          ) : ""}
          {Number(i?.matched_user_id) === Number(user?.id) && i?.
matched_accept
? (
            <button className='text-black bg-gray-300 px-6 rounded-md py-1 text-xs lg:text-sm font-medium hover:text-white hover:shadow-md flex-grow'>Awaiting response</button>
          ) : Number(i?.match_user_id) === Number(user?.id) && i?.
match_accept
? (
            <button className='text-black bg-gray-300 px-6 rounded-md py-1 text-xs lg:text-sm font-medium hover:text-white hover:shadow-md flex-grow'>Awaiting response</button>
          ):""}
        </div>
                        <div className='border-t flex justify-center items-center'>
                            <p className='pt-3 pb-1 text-xs italic font-semibold'>{moment.utc(i?.user_match_meeting_time).format('Do MMMM YYYY, hh:mma')} </p>
                        </div>
                       </div>
                       
                    </div>
                </div>
            ))
        ) : (
            <div className='flex flex-col justify-center items-center mx-auto h-full w-full space-y-2'>
                <div className='rounded-full bg-[#F5F5F5] w-10 h-10 md:w-12 md:h-12 xl:w-14 xl:h-14 flex justify-center items-center mx-auto'>
                    <FaVideoSlash className='text-lg md:text-xl xl:text-2xl text-primary-bg' />
                </div>
                <div className='space-y-6'>
                    <div className='space-y-2'>
                        <h1 className='font-bold text-xs sm:text-sm md:text-base  xl:text-lg text-center'>You have no pending blind date requests</h1>
                        <p className='text-xs md:text-sm xl:text-sm text-center  max-w-xs md:max-w-md xl:max-w-lg mx-auto'>All your blind date requests will be displayed here when you are matched. Feeling itchy? then speak with your handler to quickly get you matched</p>
                    </div>
                    <div className='flex justify-center items-center'>
                        <button className='bg-primary-bg px-4 py-2 text-white hover:bg-opacity-80 hover:shadow-md rounded-md text-xs md:text-sm lg:px-6 font-bold xl:text-base'>
                            <Link to="/dashboard/handler-chat">Chat with handler</Link>
                        </button>
                    </div>
                </div>
            </div>
        )}
    </div>}
</div>


  )
}


export default Requests




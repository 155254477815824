import { styled } from '@mui/system'
import theme from 'src/assets/colors/theme'
import { Box } from '@mui/material'

export const TabContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',

  '& .MuiButton-root': {
    marginRight: '10px',
    borderRadius: '4px',
  },

  '& .MuiButton-textPrimary.active': {
    backgroundColor: theme.PRIMARY_80,
  },

  '& .btn-container': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '& .side-component': {
    width: '100px',
    justifyContent: 'space-around',
    padding: 0,
    flexDirection: 'unset',
  },
})

import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
// import { UPDATE_HANDLER } from "../data/mutation";

const HandlerContext = createContext<{
  handler: any; //change later,
  setHandler: (payload: any) => void;
  logoutHandler: () => void;
  // saveHandler: (payload: any, callBack?: any) => void;
}>({
  handler: null,
  // saveHandler: ({}, callback) => {},
  logoutHandler: () => {},
  setHandler: ({}) => {},
});
//    localStorage.setItem("handlerToken", handlerLogin.email);
export const useHandler = () => useContext(HandlerContext);

export const HandlerProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [handler_state, setHandlerState] = useState<any>(
    //JSON.parse(localStorage.getItem("handlerToken") || "")
    null
  );
  // const [updateHandler] = useMutation(UPDATE_HANDLER);

  useEffect(() => {
    // const localState = localStorage.getItem("handlerToken");
    try {
      setHandlerState(JSON.parse(localStorage.getItem("handlerToken") || ""));
    } catch (error) {
      console.log(error);
    }
  }, []);

  // const saveHandler = (payload, callback?: any) => {
  //   const json_handler_data = JSON.stringify({ ...handler_state, ...payload });
  //   updateHandler({
  //     variables: {
  //       updateHandlerId: Number(handler_state.id),
  //       payload,
  //     },
  //     onCompleted: (data) => {
  //       localStorage.setItem("handlerToken", json_handler_data);
  //       if (typeof callback === "function") {
  //         callback();
  //       }
  //     },
  //     onError: (error) => {
  //       console.log(error);
  //     },
  //   });
  // };
  const setHandler = (payload) => {
    console.log({ handler_state, payload });
    const json_handler_data = JSON.stringify({ ...handler_state, ...payload });
    localStorage.removeItem("userToken");
    localStorage.setItem("handlerToken", json_handler_data);
    setHandlerState({ ...handler_state, ...payload });
  };
  const logoutHandler = () => {
    try {
      setHandlerState?.(null);
      localStorage.removeItem("userToken");
      localStorage.removeItem("handlerToken");
      localStorage.clear();
    } catch (error) {
      console.log(error);
    }
  };

  const values = useMemo(
    () => ({
      handler: handler_state,
      // saveHandler,
      logoutHandler,
      setHandler,
    }),
    [handler_state, setHandler]
  );

  return (
    <HandlerContext.Provider value={values}>{children}</HandlerContext.Provider>
  );
};

import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "../data/mutation";

const useUpdateUser = () => {
  const [updateUser, { data, loading, error }] = useMutation(UPDATE_USER);
  return {
    updateUser,
  };
};

export default useUpdateUser;

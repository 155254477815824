import { Outlet, Route, Routes } from "react-router-dom";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FriendPage from "./pages/Friends";
import Homepage from "./pages/Home";
import ProfilePage from "./pages/Profile";
import SignUp from "./pages/Signup";
import { ApolloProvider } from "@apollo/client";
import client from "src/data/apollo";
import { CurrencyProvider } from "./context/AmountProvider";
import Terms from "./pages/Terms";
import Accepted from "./pages/Accepted";
import Rejected from "./pages/Rejected";
import ResponseGiven from "./pages/ResponseGiven";
import HandlerDashboard from "./pages/HandlerDashboard";
import UserDetails from "./pages/UserDetails";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import UserDashboard from "./pages/UserDashboard";
import SidebarProvider from "./context/SidebarProvider";
import TermsAndCondition from "./pages/termAndCondition";
import DeleteAccout from "./pages/DeleteAccout";
import Subscribe from "./pages/subscribe";
import NewHandler from "./pages/NewHandler";
import MonthlySub from "./pages/MonthlySub";
import BiAnnualSub from "./pages/BiAnnualSub";
import MatchProfile from "./pages/matchProfile";
import Matches from "./pages/Matches";
import FriendRequest from "./pages/FriendRequest";
import ChatHandler from "./pages/ChatHandler";
import ProfileSuggestion from "./pages/ProfileSuggestion";
import UserProfile from "./pages/UserProfile";
import DashboardLayout from "./pages/UserDashboard";
import Index from "./components/NewHandlerDashboard/Index";
import Chats from "./components/NewHandlerDashboard/Chats";
import AdminAddUser from "./components/NewHandlerDashboard/AdminAddUser";
import AdminIndex from "./components/NewHandlerDashboard/AdminIndex";
import UserEditScreen from "./pages/UserEditScreen";
import AllNotifications from "./pages/AllNotifications";
import Message from "./pages/message";
import { UserProvider, useUser } from "./components/auth/hooks/UserProvider";
import {
  HandlerProvider,
  useHandler,
} from "./components/auth/hooks/HandlerProvider";
import ChoosePlan from "./pages/ChoosePlan";
// import AuthChoosePlan from "./pages/AuthChoosePlan";
import AuthChoosePlan from "./pages/AuthChoosePlan";
import UserMessages from "./pages/UserMessages";
import Violations from "./pages/Violations";
import CreatePasswordUser from "./pages/CreatePasswordUser";
import AuthResetPassword from "./pages/AuthResetPassword";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "tippy.js/dist/tippy.css";
import ViewUserProfile from "./pages/ViewUserProfile";
import NewHome from "./pages/NewHome";
import NewVerification from "./pages/NewVerification";
import VerificationIndex from "./components/auth/new_verification/Index";
import VerificationError from "./components/auth/new_verification/VerificationError";
import MainVerificationSuccess from "./components/auth/new_verification/MainVerificationSuccess";
import IdleProvider from "./components/auth/IdleProvider";
import HandlerBlindDates from "./components/NewHandlerDashboard/HandlerBlindDates";
import UserBlindDate from "./pages/UserBlindDate";
import RevisedAuthPlan from "./pages/RevisedAuthPlan";
import SuperAdminLogin from "./pages/SuperAdminLogin";
import SuperAdminDashboardLayout from "./pages/SuperAdminDashboardLayout";
import SuperAdminDashboard from "./pages/SuperAdminDashboard";
import SuperAdminUser from "./pages/SuperAdminUser";
import SuperAdminLobby from "./pages/SuperAdminLobby";
import SuperAdminRejectedUsers from "./pages/SuperAdminRejectedUsers";
import SuperAdminAnnouncements from "./pages/SuperAdminAnnouncements";
import SuperAdminFeedbacks from "./pages/SuperAdminFeedbacks";
import SuperAdminHandlerChat from "./pages/SuperAdminHandlerChat";
import UserTherapy from "./pages/UserTherapy";
import HandlerTherapy from "./pages/HandlerTherapy";
function App() {
  return (
    <>
      <SidebarProvider>
        <ApolloProvider client={client}>
          <UserProvider>
            <HandlerProvider>
              <CurrencyProvider>
                <IdleProvider>
                  <Routes>
                    <Route path="/" element={<NewHome />} />
                    <Route path="/dashboard" element={<DashboardLayout />}>
                      <Route path="handler" element={<Index />} />
                      <Route
                        path="handler/blind-dates"
                        element={<HandlerBlindDates />}
                      />
                      <Route path="handler/chat" element={<Chats />} />
                      <Route
                        path="handler/add-user"
                        element={<AdminAddUser />}
                      />
                      <Route path="admin" element={<AdminIndex />} />
                      <Route path="matches" element={<Matches />} />

                      <Route path="blind-date" element={<UserBlindDate />} />
                      <Route
                        path="profile-suggestion"
                        element={<ProfileSuggestion />}
                      />
                      <Route path="my-profile" element={<UserProfile />} />
                      <Route path="therapy" element={<UserTherapy />} />
                      <Route
                        path="handler/therapy"
                        element={<HandlerTherapy />}
                      />
                      <Route path="messages" element={<UserMessages />} />
                      <Route path="handler-chat" element={<ChatHandler />} />
                      <Route
                        path="friend-request"
                        element={<FriendRequest />}
                      />
                      <Route
                        path="choose-a-plan"
                        element={<RevisedAuthPlan />}
                      />
                      <Route
                        path="match-profile/:matchId"
                        element={<MatchProfile />}
                      />
                      <Route
                        path="request-profile/:matchId"
                        element={<ViewUserProfile />}
                      />
                      <Route path="edit" element={<UserEditScreen />} />
                      <Route
                        path="auth-reset-password"
                        element={<AuthResetPassword />}
                      />
                      <Route
                        path="notifications"
                        element={<AllNotifications />}
                      />
                      <Route path="message/:msgId" element={<Message />} />
                    </Route>
                    <Route path="/make-a-friend" element={<FriendPage />} />
                    <Route path="/profile" element={<ProfilePage />} />
                    <Route path="/get-started/:id" element={<SignUp />} />
                    <Route path="/monthly-sub" element={<MonthlySub />} />
                    <Route path="/bi-annual-sub" element={<BiAnnualSub />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/pre-verify" element={<NewVerification />} />
                    <Route
                      path="/verification"
                      element={<VerificationIndex />}
                    />
                    <Route
                      path="/verificationerror"
                      element={<VerificationError />}
                    />
                    <Route
                      path="/verificationsuccess"
                      element={<MainVerificationSuccess />}
                    />
                    <Route path="/choose-a-plan" element={<ChoosePlan />} />
                    <Route
                      path="/forget-password"
                      element={<ForgetPassword />}
                    />
                    <Route path="/reset-password" element={<ResetPassword />} />

                    <Route
                      path="/terms-and-condition"
                      element={<TermsAndCondition />}
                    />
                    <Route path="/subscribe" element={<Subscribe />} />
                    <Route path="/delete-account" element={<DeleteAccout />} />
                    <Route
                      path="/create-password"
                      element={<CreatePasswordUser />}
                    />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/accepted" element={<Accepted />} />
                    <Route path="/rejected" element={<Rejected />} />
                    <Route path="/response_given" element={<ResponseGiven />} />
                    <Route path="/admin" element={<HandlerDashboard />} />
                    <Route path="/super_admin" element={<SuperAdminLogin />} />
                    <Route
                      path="/super"
                      element={<SuperAdminDashboardLayout />}
                    >
                      <Route
                        path="dashboard"
                        element={<SuperAdminDashboard />}
                      />
                      <Route path="handler" element={<AdminIndex />} />
                      <Route path="user" element={<SuperAdminUser />} />
                      <Route path="lobby" element={<SuperAdminLobby />} />
                      <Route
                        path="rejected_user"
                        element={<SuperAdminRejectedUsers />}
                      />
                      <Route
                        path="announcements"
                        element={<SuperAdminAnnouncements />}
                      />
                      <Route
                        path="feedbacks"
                        element={<SuperAdminFeedbacks />}
                      />
                      <Route path="violations" element={<Violations />} />
                      <Route
                        path="handler_chat"
                        element={<SuperAdminHandlerChat />}
                      />
                    </Route>
                    <Route
                      path="/admin/user/:user_id"
                      element={<UserDetails />}
                    />
                  </Routes>
                </IdleProvider>
              </CurrencyProvider>
            </HandlerProvider>
          </UserProvider>
        </ApolloProvider>
      </SidebarProvider>
      <ToastContainer
        transition={Slide}
        autoClose={4000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="colored"
        pauseOnHover
        toastClassName="main_toast_container"
      />
    </>
  );
}
//res.status(200).redirect(env("web_url") + "/response_given");
export default App;

import Slider from "react-slick";
import ASSETS from "src/asset";
export default function Cards() {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 3,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      <div className="w-60 h-60 py-1 px-2 mb-10">
        <div className="bg-gray-100 p-3 mb-10 flex-shrink-0 h-full rounded-2xl w-full">
          <img
            src={ASSETS.Frame1}
            alt="beautiful lovers"
            loading="lazy"
            className="w-full rounded-2xl h-full object-cover"
          />
        </div>
      </div>
      <div className="w-60 h-60 py-1 px-2 mb-10">
        <div className="bg-gray-100 p-3 flex-shrink-0 h-full md:translate-y-10 rounded-2xl w-full">
          <img
            src={ASSETS.Frame2}
            alt="beautiful lovers"
            loading="lazy"
            className="w-full rounded-2xl h-full object-cover"
          />
        </div>
      </div>
      <div className="w-60 h-60 py-1 px-2 mb-10">
        <div className="bg-gray-100 p-3 flex-shrink-0 md:translate-y-5 h-full rounded-2xl w-full">
          <img
            src={ASSETS.Frame3}
            alt="beautiful lovers"
            loading="lazy"
            className="w-full rounded-2xl h-full object-cover"
          />
        </div>
      </div>
      <div className="w-60 h-60 py-1 px-2 mb-10">
        <div className="bg-gray-100 p-3 flex-shrink-0 h-full md:translate-y-10 rounded-2xl w-full">
          <img
            src={ASSETS.Frame4}
            alt="beautiful lovers"
            loading="lazy"
            className="w-full rounded-2xl h-full object-cover"
          />
        </div>
      </div>
      <div className="w-60 h-60 py-1 px-2 mb-10">
        <div className="bg-gray-100 p-3 flex-shrink-0 h-full rounded-2xl w-full">
          <img
            src={ASSETS.MainBg}
            alt="beautiful lovers"
            loading="lazy"
            className="w-full rounded-2xl h-full object-cover"
          />
        </div>
      </div>
    </Slider>
  );
}

import { useQuery } from "@apollo/client";
import { KeyboardBackspace, PersonOutline } from "@mui/icons-material";
import { useState } from "react";
import { HANDLER_GET_USER } from "src/hooks/data/queries";
import NoChat from "src/assets/no_chat.png";
import moment from "moment";
import { BeatLoader } from "react-spinners";
import ClientMeetingNotes from "./ClientMeetingNotes";

interface User {
  id: Number;
  last_name: String;
  about_me: String;
  plan: String;
  first_name: String;
  email: String;
  phone: String;
  date_joined: String;
  status: String;
  gender: String;
  genotype: String;
  age: String;
  media: [Object];
  user_match: [Object];
  userStrike: [Object];
  userReport: [Object];
  userBlackList: [Object];
  subscription: any;
  alais: String;
  religion: String;
  occupation: String;
  location_city: String;
  location_country: String;
  origin_country: String;
  ethnicity: String;
  qualification: String;
  hobbies: String;
  preferred_gender: String;
  preferred_country: String;
  preferred_religion: String;
  preferred_genotype: String;
  preferred_status: String;
  preferred_age: String;
}

export default function ClientDetails({ passedUser, setIndexPage }) {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedPage, setSelectedPage] = useState(0);
  const { refetch, loading } = useQuery(HANDLER_GET_USER, {
    variables: {
      userId: Number(passedUser?.id),
    },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      setSelectedUser(data.user);
    },
  });
  const selections = ["General Details", "Photos", "Meeting Notes"];
  return (
    <div className="space-y-10">
      <h2
        onClick={() => {
          setSelectedUser(null);
          setIndexPage(0);
        }}
        className="text-sm w-max flex gap-2 items-center cursor-pointer hover:text-blue-600 font-bold text-[#1C1F37]"
      >
        <KeyboardBackspace fontSize="small" />
        Back to all clients
      </h2>
      <div className="flex gap-5 flex-col sm:flex-row items-start justify-between sm:items-center ">
        <h2 className="text-base font-bold">Client’s Details</h2>
      </div>
      {loading ? (
        <div className="flex h-60 justify-center items-center">
          <BeatLoader color="#FE6D68" size={20} />
        </div>
      ) : (
        <>
          <div className="bg-white p-5 space-y-7 pb-0">
            <div className="flex sm:divide-x gap-5 flex-col sm:flex-row items-start divide-gray-200 sm:items-center">
              <div className="flex gap-5 flex-shrink-0 items-center">
                <div className="w-10 sm:w-16 h-10 text-base sm:text-lg sm:h-16 flex justify-center items-center flex-shrink-0 rounded-full bg-[#21407d53]">
                  <PersonOutline
                    fontSize="inherit"
                    className="text-[#081A51]"
                  />
                </div>
                <div className="">
                  <h2 className="text-base capitalize font-semibold">
                    {selectedUser?.first_name + " " + selectedUser?.last_name}
                  </h2>
                  <p className="text-xs font-medium">{selectedUser?.alais}</p>
                </div>
              </div>
              <div className="pl-5 hidden sm:inline-block flex-shrink-0">
                <h2 className="text-base font-semibold">User's Plan</h2>
                <p className="text-xs font-medium">
                  {selectedUser?.subscription?.name ?? "N/A"}
                </p>
              </div>
              <div className="pl-5  hidden sm:inline-block">
                <h2 className="text-base font-semibold">About</h2>
                <p className="text-xs font-medium">{selectedUser?.about_me}</p>
              </div>
            </div>
            <div className="relative">
              <div className={`flex gap-10 overflow-x-auto pt-5  items-center`}>
                {selections?.map((selection, index) => (
                  <h2
                    onClick={() => {
                      setSelectedPage(index);
                    }}
                    key={index}
                    className={` border-b flex-shrink-0 w-max text-sm cursor-pointer pb-4 font-medium ${
                      selectedPage === index
                        ? "border-[#D4678F] text-[#D4678F] "
                        : "border-transparent hover:opacity-70"
                    }`}
                  >
                    {selection}
                  </h2>
                ))}
              </div>
            </div>
          </div>
          {selectedPage === 0 ? (
            <div className="bg-white p-5 space-y-7">
              <h2 className="text-base font-bold text-[#213F7D]">
                Personal Information
              </h2>
              <div className="space-y-7 divide-y divide-gray-200">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 lg:grid-cols-5 lg:gap-8">
                  <div className="">
                    <p className="text-10 uppercase font-normal text-[#545F7D] ">
                      Full name
                    </p>
                    <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                      {selectedUser?.first_name + " " + selectedUser?.last_name}
                    </h2>
                  </div>
                  <div className="">
                    <p className="text-10 uppercase font-normal text-[#545F7D] ">
                      Phone number
                    </p>
                    <h2 className="text-sm font-semibold text-[#545F7D]">
                      {selectedUser?.phone}
                    </h2>
                  </div>
                  <div className="">
                    <p className="text-10 uppercase font-normal text-[#545F7D] ">
                      Email
                    </p>
                    <h2 className="text-sm break-words lowercase font-semibold text-[#545F7D]">
                      {selectedUser?.email}
                    </h2>
                  </div>
                  <div className="">
                    <p className="text-10 uppercase font-normal text-[#545F7D] ">
                      Gender
                    </p>
                    <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                      {selectedUser?.gender}
                    </h2>
                  </div>
                  <div className="">
                    <p className="text-10 uppercase font-normal text-[#545F7D] ">
                      Genotype
                    </p>
                    <h2 className="text-sm uppercase font-semibold text-[#545F7D]">
                      {selectedUser?.genotype}
                    </h2>
                  </div>
                  <div className="">
                    <p className="text-10 uppercase font-normal text-[#545F7D] ">
                      Relationship status
                    </p>
                    <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                      {selectedUser?.status}
                    </h2>
                  </div>
                  <div className="">
                    <p className="text-10 uppercase font-normal text-[#545F7D] ">
                      Ethnicity
                    </p>
                    <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                      {selectedUser?.ethnicity}
                    </h2>
                  </div>
                  <div className="">
                    <p className="text-10 uppercase font-normal text-[#545F7D] ">
                      Date of Birth
                    </p>
                    <h2 className="text-sm font-semibold text-[#545F7D]">
                      {selectedUser?.age?.includes?.("/")
                        ? //@ts-ignore
                          moment(selectedUser?.age, "DD/MM/YYYY").format(
                            "DD MMM, YYYY"
                          )
                        : //@ts-ignore
                          moment(new Date(selectedUser?.age)).format(
                            "DD MMM, YYYY"
                          ) ?? "N/A"}
                    </h2>
                  </div>
                  <div className="">
                    <p className="text-10 uppercase font-normal text-[#545F7D] ">
                      alais
                    </p>
                    <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                      {selectedUser?.alais}
                    </h2>
                  </div>
                  <div className="">
                    <p className="text-10 uppercase font-normal text-[#545F7D] ">
                      Religion
                    </p>
                    <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                      {selectedUser?.religion}
                    </h2>
                  </div>
                  <div className="">
                    <p className="text-10 uppercase font-normal text-[#545F7D] ">
                      Occupation
                    </p>
                    <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                      {selectedUser?.occupation}
                    </h2>
                  </div>
                  <div className="">
                    <p className="text-10 uppercase font-normal text-[#545F7D] ">
                      Location
                    </p>
                    <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                      {selectedUser?.location_city}
                    </h2>
                  </div>
                  <div className="">
                    <p className="text-10 uppercase font-normal text-[#545F7D] ">
                      Country
                    </p>
                    <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                      {selectedUser?.location_country}
                    </h2>
                  </div>
                  <div className="">
                    <p className="text-10 uppercase font-normal text-[#545F7D] ">
                      Place of Birth
                    </p>
                    <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                      {selectedUser?.origin_country}
                    </h2>
                  </div>
                  <div className="">
                    <p className="text-10 uppercase font-normal text-[#545F7D] ">
                      Ethnicity
                    </p>
                    <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                      {selectedUser?.ethnicity}
                    </h2>
                  </div>
                  <div className="">
                    <p className="text-10 uppercase font-normal text-[#545F7D] ">
                      Qualification
                    </p>
                    <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                      {selectedUser?.qualification}
                    </h2>
                  </div>
                  <div className="">
                    <p className="text-10 uppercase font-normal text-[#545F7D] ">
                      Hobbies
                    </p>
                    <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                      {selectedUser?.hobbies}
                    </h2>
                  </div>
                </div>
                <div className="space-y-7 pt-7">
                  <h2 className="text-base font-bold text-[#213F7D]">
                    Preferences
                  </h2>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 lg:grid-cols-5 lg:gap-8">
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Gender
                      </p>
                      <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                        {selectedUser?.preferred_gender}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Location
                      </p>
                      <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                        {selectedUser?.preferred_country}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Religion
                      </p>
                      <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                        {selectedUser?.preferred_religion}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Genotype
                      </p>
                      <h2 className="text-sm uppercase font-semibold text-[#545F7D]">
                        {selectedUser?.preferred_genotype}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Relationship status
                      </p>
                      <h2 className="text-sm uppercase font-semibold text-[#545F7D]">
                        {selectedUser?.preferred_status}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Age
                      </p>
                      <h2 className="text-sm font-semibold text-[#545F7D]">
                        {selectedUser?.preferred_age}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : selectedPage === 1 ? (
            <div className="bg-white p-5 space-y-7">
              <h2 className="text-base font-bold text-[#213F7D]">
                User's Photos
              </h2>
              {
                //@ts-ignore
                selectedUser?.media?.length > 0 ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                    {selectedUser?.media?.map((asset: any) => (
                      <div
                        className="w-full h-40 border border-gray-600 rounded-lg"
                        key={asset.id}
                      >
                        <img
                          alt="images"
                          src={asset?.url}
                          className="w-full object-cover h-full rounded-lg"
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className=" items-center justify-center w-full h-60 bg-white flex">
                    <div className=" flex gap-5 flex-col justify-center items-center">
                      <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                        <img
                          src={NoChat}
                          alt="empty"
                          className="w-5 md:w-10 h-5 md:h-10 object-contain"
                        />
                      </div>
                      <h2 className="text-lg text-center font-bold text-[#081A51] ">
                        No Photos available
                      </h2>
                      <p className="text-sm w-4/5 mx-auto text-center">
                        This user doesn't have any photo in their media
                      </p>
                    </div>
                  </div>
                )
              }
            </div>
          ) : (
            <div className="bg-white p-5 space-y-7 ">
              <ClientMeetingNotes client={passedUser} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

import { useQuery } from "@apollo/client";
import { Image, Person } from "@mui/icons-material";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { useState } from "react";
// import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NoChat from "src/assets/no_chat.png";
import EmptyCard from "src/components/Card/emptyCard";
import AdminModal from "src/components/Message/AdminModal";
import PageLoader from "src/components/PageLoader";
import SingleButton from "src/components/SingleButton";
import { timeSinceDateFormatted } from "src/helpers";
import { GET_USER } from "src/hooks/data/queries";

const MatchProfile = () => {
  // const [match, setMatch] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const parameter = useParams();

  const closeModal = () => setShowModal(false);

  const {
    data,
    loading: loading_user,
    // refetch,
    // error,
  } = useQuery(GET_USER, {
    variables: {
      userId: Number(parameter?.matchId),
    },
  });

  // console.log(object)
  return (
    <>
      {loading_user ? (
        <PageLoader />
      ) : (
        <>
          <div className="space-y-10 ">
            <div
              onClick={() => navigate("/dashboard/matches")}
              className="flex items-center w-max gap-2 cursor-pointer"
            >
              <KeyboardBackspaceOutlinedIcon
                style={{
                  cursor: "pointer",
                  fontSize: "25px",
                }}
              />
              <span className="text-sm font-bold">Back to all Matches</span>
            </div>

            <div className="md:pb-16">
              <div className="w-full h-40 sm:h-60 lg:h-[297px] bg-[#0000009e] bg-blend-soft-light rounded-lg px-4 py-2 flex justify-between bg-cover bg-center bg-no-repeat relative">
                {
                  <div
                    style={{
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    className="absolute text-6xl text-gray-500 lg:text-9xl z-50 w-max "
                  >
                    <Image fontSize="inherit" color="inherit" />
                  </div>
                }
                <div className=" absolute bottom-0 translate-y-5 gap-2 md:gap-5 lg:translate-y-20  flex ">
                  <div className="lg:w-44 border p-1 text-lg lg:text-7xl flex justify-center items-center border-gray-300 bg-white h-12 w-12 sm:w-20 sm:h-20 lg:h-44 max-lg:w-16 max-lg:h-16 rounded-full bg-cover bg-center bg-no-repeat">
                    {!data?.user?.profile_image_url ? (
                      <Person fontSize="inherit" />
                    ) : (
                      <img
                        src={data?.user?.profile_image_url}
                        alt="profile"
                        className="h-full w-full object-cover rounded-full"
                      />
                    )}
                  </div>
                  <span className="text-white sm:mt-5 lg:mt-10  max-lg:text-slate-700 md:text-lg font-bold  capitalize whitespace-nowrap text-xs  ">
                    {`${data?.user?.first_name} ${data?.user?.last_name}`}
                  </span>
                </div>
                <div className="self-end gap-5 p-2  absolute right-4 ">
                  <SingleButton
                    icon={
                      <WarningAmberOutlinedIcon
                        style={{
                          color: "white",
                          marginRight: "6px",
                          fontSize: "12px",
                        }}
                      />
                    }
                    text={
                      data?.user?.userStrike?.length > 1
                        ? `${data?.user?.userStrike?.length} Strikes`
                        : `${data?.user?.userStrike?.length} Strike`
                    }
                    className="p-2 flex justify-center items-center bg-green-600 rounded-lg cursor-pointer max-lg:text-xs"
                    inlineStyle="text-slate-100 text-sm max-lg:text-[9px] max-lg:text-xs"
                    onBtnClick={() => setShowModal(true)}
                  />
                </div>
              </div>
            </div>

            <div className=" bg-white divide-y divide-gray-300 shadow-md rounded-lg">
              <p className="font-bold capitalize p-4 text-base">
                About {`${data?.user?.first_name} ${data?.user?.last_name}`}
              </p>
              <div className=" p-5 w-full">
                {data?.user?.about_me ? (
                  <p className="text-base font-medium ">
                    {data?.user?.about_me}
                  </p>
                ) : (
                  <p className="text-base italic font-medium ">
                    No bio/about available
                  </p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
              <EmptyCard header="Personal Information">
                <div className="p-4 space-y-2 pb-0">
                  <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Full name:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.first_name ?? "N/A"}
                    </span>
                  </p>
                  <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Username:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.alais ?? "N/A"}
                    </span>
                  </p>
                  <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Date of Birth:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.age ?? "N/A"}
                    </span>
                  </p>
                  <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Gender:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.gender ?? "N/A"}
                    </span>
                  </p>
                  <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Genotype:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.genotype ?? "N/A"}
                    </span>
                  </p>
                  {/* <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Phone Number:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.phone ?? "N/A"}
                    </span>
                  </p>
                  <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Email:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.email ?? "N/A"}
                    </span>
                  </p> */}
                  <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Etnicity:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.ethnicity ?? "N/A"}
                    </span>
                  </p>
                </div>
              </EmptyCard>
              <EmptyCard header="Other Details">
                <div className="p-4 space-y-2 pb-0">
                  <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Religion:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.religion ?? "N/A"}
                    </span>
                  </p>
                  <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Occupation:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.occupation ?? "N/A"}
                    </span>
                  </p>
                  <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Location:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.location_country ?? "N/A"}
                    </span>
                  </p>
                  <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Place of birth:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.origin_country ?? "N/A"}
                    </span>
                  </p>
                  <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Highest Academic Qualification:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.qualification ?? "N/A"}
                    </span>
                  </p>
                  <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Relationship Status:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.status ?? "N/A"}
                    </span>
                  </p>
                  <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Hobbies:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.hobbies ?? "N/A"}
                    </span>
                  </p>
                </div>
              </EmptyCard>
              <EmptyCard header="Preferences">
                <div className="p-4 space-y-2 pb-0">
                  <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Preferred Gender:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.preferred_gender ?? "N/A"}
                    </span>
                  </p>
                  <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Preferred age range:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.preferred_age ?? "N/A"}
                    </span>
                  </p>
                  <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Gender:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.preferred_gender ?? "N/A"}
                    </span>
                  </p>
                  <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Preferred Genotype:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.preferred_genotype ?? "N/A"}
                    </span>
                  </p>
                  <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Preferred Religion:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.preferred_religion ?? "N/A"}
                    </span>
                  </p>
                  <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                    Preferred Relationship:{" "}
                    <span className="text-sm font-normal capitalize">
                      {data?.user?.preferred_status ?? "N/A"}
                    </span>
                  </p>
                </div>
              </EmptyCard>
            </div>
            <div className="w-full p-4 rounded-lg shadow-md bg-white">
              <p className="font-bold capitalize text-base">
                {`${data?.user?.first_name} ${data?.user?.last_name}` + "'s"}{" "}
                Photos
              </p>
              {data?.user?.media?.length > 0 ? (
                <div className="w-full py-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
                  {data?.user?.media?.map?.((photo: any, i: number) => (
                    <img
                      key={i}
                      className={`h-40 border border-gray-600 object-cover rounded-lg w-full`}
                      src={photo?.url}
                      alt="match"
                    />
                  ))}
                </div>
              ) : (
                <div className=" items-center justify-center w-full h-60 bg-white flex">
                  <div className=" flex gap-5 flex-col justify-center items-center">
                    <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                      <img
                        src={NoChat}
                        alt="empty"
                        className="w-5 md:w-10 h-5 md:h-10 object-contain"
                      />
                    </div>
                    <h2 className="text-lg text-center font-bold text-[#081A51] ">
                      No Photos available
                    </h2>
                    <p className="text-sm w-4/5 mx-auto text-center">
                      This user doesn't have any photo in their media
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <AdminModal
            visible={showModal}
            close={closeModal}
            header="bg-[#081A51] w-full py-3 flex px-6 justify-between items-center mt-20"
            headerText="Strikes issued by handler"
            containerStyle="h-0 lg:w-[50%] w-[95%] bg-white "
          >
            <div className="w-full lg:p-4 p-2 m-auto flex flex-col flex-grow justify-between lg:h-[700px] h-[850px] bg-white">
              <div className="w-full mt-6 ">
                {data?.user?.userStrike?.map((uS, i) => (
                  <StrikeMessage
                    strike={uS}
                    handler_name={data?.user?.handler?.user_name}
                    key={i}
                    className={`flex justify-between w-full p-4 ${
                      i < 2 ? "border-l-2 border-slate-400" : ""
                    } h-32 relative `}
                  />
                ))}
              </div>
              <p className="text-[#A303A0] font-semibold text-center text-xs lg:text-sm mt-20">
                Please be adviced that after 3 strikes your account will be
                suspended on our platform
              </p>
            </div>
          </AdminModal>
        </>
      )}
    </>
  );
};

export default MatchProfile;

const StrikeMessage = ({ strike, handler_name = "", className = "" }) => {
  return (
    <div
      className={className || `flex justify-between w-full h-32 max-lg:h-52 `}
    >
      <div className="border-2 w-4 h-4 rounded-full border-[#D4678F] absolute top-[-6px] left-[-9px] bg-white"></div>
      <div className=" flex-col absolute top-[-6px] mb-8">
        <p className="text-[#222E50] font-semibold text-sm">
          Handler: {handler_name}
        </p>
        <p className="text-[#343D48] text-[11px] lg:text-sm lg:leading-6 w-[80%] leading-5">
          {strike?.reason}
        </p>
      </div>
      <p className="w-12 h-5 rounded-md lg:text-xs bg-[#f8d9df] text-[#FE5578] flex justify-center items-center text-[10px] p-1 right-[10px] top-[-6px] absolute">
        {timeSinceDateFormatted(strike?.created_at)}
      </p>
    </div>
  );
};

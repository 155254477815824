import { Menu, Transition } from "@headlessui/react";
import { FilterList, MoreVert, RemoveRedEye } from "@mui/icons-material";
import moment from "moment";
import { Fragment, useState } from "react";
import LinePagination from "src/Paging/LinePagination";
import ResponsiveOverlay from "src/overlays/ResponsiveOverlay";

interface User {
  reporter: String;
  violator: String;
  violation_type: String;
  reason: String;
  date_of_request: String;
}
type Alluserdata = User[];

export default function Violations() {
  const [users, setUsers] = useState([
    {
      reporter: "Akpan Okon",
      violator: "Adedeji Daniel",
      violation_type: "Strike",
      reason: "Violation of Privacy",
      date_of_request: "2023-12-01T12:43:10.997Z",
    },
  ]);
  const [allDatas, setAllDatas] = useState<Alluserdata | []>([]);
  const [openOverlay, setOpenOverlay] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  return (
    <>
      <ResponsiveOverlay
        isOpen={openOverlay}
        setIsOpen={setOpenOverlay}
        title={"Report"}
      >
        <div className="p-10 pt-5">
          <div className="grid grid-cols-3 gap-5"></div>
          <div className="flex gap-5 justify-center items-center">
            <button className="px-4 py-2 rounded-lg text-white  bg-[#081A51] text-sm font-semibold">
              Strike User
            </button>
            <button className="px-4 py-2 rounded-lg text-white  bg-[#FF0000] text-sm font-semibold">
              Blaclist User
            </button>
          </div>
        </div>
      </ResponsiveOverlay>
      <div className="space-y-4 pb-40">
        <h2 className="text-base font-bold">All Violations</h2>
        <LinePagination data={users} setCurrentItems={setAllDatas}>
          <table className="relative w-full divide-y divide-gray-1 overflow-y-auto  bg-white">
            <thead className=" rounded-lg rounded-t border px-5">
              <tr className="rounded-t-lg">
                <th
                  scope="col"
                  className={`px-2 py-4 pl-5 text-left text-xs font-medium uppercase`}
                >
                  <div className="flex gap-2 items-center">Reporter</div>
                </th>
                <th
                  scope="col"
                  className={`px-2 py-4 pl-5 text-left text-xs font-medium uppercase`}
                >
                  <div className="flex gap-2 items-center">Violator</div>
                </th>
                <th
                  scope="col"
                  className={`px-2 py-4 pl-5  text-left text-xs font-medium uppercase`}
                >
                  <div className="flex gap-2 items-center">Violation type</div>
                </th>
                <th
                  scope="col"
                  className={`px-2 py-4 pl-5  text-left text-xs font-medium uppercase`}
                >
                  <div className="flex gap-2 items-center">Reason</div>
                </th>
                <th
                  scope="col"
                  className={`px-2 py-4 pl-5  text-left text-xs font-medium uppercase`}
                >
                  <div className="flex gap-2 items-center">Date of Request</div>
                </th>

                <th
                  scope="col"
                  className={`px-2 py-4 pl-5  text-left text-xs font-medium uppercase`}
                ></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-300 overflow-auto bg-white px-5 pt-5">
              {allDatas?.map?.((item: any, index: number) => (
                <tr
                  onClick={() => {
                    setSelectedUser(item);
                    setOpenOverlay(true);
                  }}
                  className="hover:bg-gray-100"
                  key={index}
                >
                  <td className="px-2 pl-5 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                    {item?.reporter}
                  </td>
                  <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                    {item?.violator}
                  </td>
                  <td className="px-2 py-4 text-left text-xs font-normal lowercase text-[#545F7D]">
                    {item?.violation_type}
                  </td>
                  <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                    {item?.reason}
                  </td>
                  <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                    {moment(item?.date_of_request).format(
                      "MMM DD, YYYY hh:ss a"
                    )}
                  </td>

                  <td
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="px-2 py-4 text-left text-xs font-normal capitalize"
                  >
                    <MoreVert />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </LinePagination>
      </div>
    </>
  );
}

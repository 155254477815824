import moment from "moment";

export const calculateAge = (birthdate) => {
  const birthDate = new Date(birthdate);
  const ageDiffMs = Date.now() - birthDate.getTime();
  const ageDate = new Date(ageDiffMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};
export const getLocalTimeOfUser = (date, format) => {
  if (date) {
    const localTime = moment(date).local().format(format);
    return localTime;
  }
  return moment(new Date()).local().format(format);
};

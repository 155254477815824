import { useQuery } from "@apollo/client";
import { Box, Chip, LinearProgress, Typography } from "@mui/material";
import moment from "moment";
import { useCallback } from "react";
import { formatPhoneNumber } from "src/helpers";
import { GET_USER } from "src/hooks/data/queries";
import useUploadFile from "src/hooks/useUploadMedia";
import { SkeletonWrapper } from "src/pages/styles";
import ToolTip from "../ToolTip";
import { useUpload } from "../common/useUpload";
import useUpdateUser from "./hooks/useSignUp";
import { Container } from "./info_user.style";

type InfoLocationTypes = {
  user_payload: any;
  personId?: number;
  showHeader?: boolean;
  propertyId?: number | null;
  showChangeProperty?: boolean;
};

const InfoLocation = ({
  user_payload,
  personId,
  showHeader = true,
  showChangeProperty = true,
  propertyId: _propertyId,
}: InfoLocationTypes) => {
  const { updateUser } = useUpdateUser();

  const { loading, data, refetch } = useQuery(GET_USER, {
    variables: {
      userId: Number(user_payload.id),
    },
    fetchPolicy: "cache-and-network",
  });

  const { multipleFilesUrlObject } = useUploadFile();

  const {
    mainFile,
    loading_image: loading_image_1,
    getRootProps,
    getInputProps,
    deleteFile,
  } = useUpload({
    fileType: "image",
  });

  const {
    mainFile: secondFile,
    loading_image: loading_image_2,
    getRootProps: getSecondRootProps,
    getInputProps: getSecondInputProps,
    deleteFile: deleteSecondFile,
  } = useUpload({
    fileType: "image",
  });

  const handleUserUpdate = useCallback(async () => {
    const upload_response: any[] = await multipleFilesUrlObject(
      [mainFile.file, secondFile.file],
      `gallery/`
    );
    const completed =
      upload_response.every((e) => e.attachmentUrl.length > 0) || false;
    if (completed) {
      await updateUser({
        variables: {
          updateUserId: `${data?.user?.id}`,
          payload: {
            media_ids: upload_response.map((e) => e.id),
          },
        },
        onCompleted: async (data) => {
          refetch();
        },
      });
    }
  }, [
    mainFile,
    secondFile,
    multipleFilesUrlObject,
    updateUser,
    data?.user?.id,
    refetch,
  ]);
  return (
    <Container showHeader={showHeader}>
      {!loading && (
        <>
          <Box className="person-info">
            <Box className="info-card">
              <Box className="content">
                <span className="container">
                  <span className="key">Alais:</span>
                  {data?.user?.alais}
                </span>
              </Box>
              <Box className="content">
                <span className="container">
                  <span className="key">Email:</span>
                  {data?.user?.email}
                </span>
              </Box>
              <Box className="content">
                <span className="container">
                  <span className="key">Phone Number:</span>
                  {data?.user?.phone}
                </span>
              </Box>
              <Box className="content">
                <span className="container">
                  <span className="key">Location:</span>
                  {data?.user?.location_country}
                </span>
              </Box>
              <Box className="content">
                <span className="container">
                  <span className="key">DOB:</span>
                  {data?.user?.age} - {moment().diff(data?.user?.age, "years")}{" "}
                  years old
                </span>
              </Box>
              <Box className="content">
                <span className="container">
                  <span className="key">Subscription:</span>
                  {data?.user?.plan?.toUpperCase()}
                </span>
              </Box>
              <Box className="content">
                <span className="container">
                  <span className="key">Relationship Status:</span>
                  {data?.user?.status}
                </span>
              </Box>
              <Box className="content">
                <span className="container">
                  <span className="key">Genotype:</span>
                  {data?.user?.genotype}
                </span>
              </Box>
              <Box className="content">
                <span className="container">
                  <span className="key">Joined:</span>
                  {moment(data?.user?.created_at).format("DD/MM/YYYY")}
                </span>
              </Box>
              <Box className="content">
                <span className="container">
                  <span className="key">Status:</span>
                  <Chip
                    label={data?.user?.deleted ? "inactive" : "active"}
                    color="primary"
                  />
                </span>
              </Box>
              {/* <Box className="content">
                <Box>
                  <Typography>Preferred Age Range</Typography>
                </Box>
                <Box>
                  <Chip
                    label={`${data?.user?.preferred_age}`}
                    color="primary"
                  />
                </Box>
              </Box>
              <Box className="content">
                <Box>
                  <Typography>Preferred Age Range</Typography>
                </Box>
                <Box>
                  <Chip
                    label={`${data?.user?.preferred_country}`}
                    color="primary"
                  />
                </Box>
              </Box>
              <Box className="content">
                <Box>
                  <Typography>Preferred Genotype</Typography>
                </Box>
                <Box>
                  <Chip
                    label={`${data?.user?.preferred_genotype}`}
                    color="primary"
                  />
                </Box>
              </Box>
              <Box className="content">
                <Box>
                  <Typography>Preferred Religion</Typography>
                </Box>
                <Box>
                  <Chip
                    label={`${data?.user?.preferred_religion}`}
                    color="primary"
                  />
                </Box>
              </Box>
              <Box className="content">
                <Box>
                  <Typography>Preferred Relationship status</Typography>
                </Box>
                <Box>
                  <Chip
                    label={`${data?.user?.preferred_status}`}
                    color="primary"
                  />
                </Box>
              </Box> */}
              {data?.user?.media_ids.length < 2 && (
                <>
                  <Box className="content">
                    <span className="container">
                      <div className="w-full">
                        {loading_image_1 ? (
                          <SkeletonWrapper>
                            <LinearProgress />
                          </SkeletonWrapper>
                        ) : (
                          <>
                            <label
                              className="text-xs flex items-center gap-1 mb-3 pl-5 block font-medium"
                              htmlFor="file"
                            >
                              Upload User photos{" "}
                              <ToolTip
                                content={
                                  <div className="w-full p-3">
                                    <p className="text-10 text-center">
                                      Ensure at least one of your photos clearly
                                      shows your face
                                    </p>
                                  </div>
                                }
                              >
                                <i className="fa-solid cursor-pointer fa-circle-info"></i>
                              </ToolTip>
                            </label>
                            {mainFile?.errors?.length <= 0 &&
                            mainFile?.file?.length <= 0 ? (
                              <div className={`h-full`} {...getRootProps()}>
                                <div className="">
                                  <input required={true} {...getInputProps()} />
                                  <h5 className="px-5 py-2.5 appearance-none cursor-pointer border-2 border-brand-1 text-center border-dashed ouline-0 focus:border-0 focus:outline-0  focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl">
                                    Upload photo 1
                                  </h5>
                                </div>
                              </div>
                            ) : mainFile?.errors?.length > 0 ? (
                              <div className={`h-full`} {...getRootProps()}>
                                <div className="">
                                  <input required={true} {...getInputProps()} />
                                  <h5 className="px-5 py-2.5 appearance-none border-2 cursor-pointer text-red-400 border-red-400 text-center border-dashed ouline-0 focus:border-0 focus:outline-0  focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl">
                                    {mainFile?.errors[0]?.message}
                                  </h5>
                                </div>
                              </div>
                            ) : (
                              <h5 className="px-5 py-2.5 appearance-none relative border-2 border-brand-1 text-center border-dashed ouline-0 focus:border-0 focus:outline-0  focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl">
                                {mainFile?.file?.name}
                                <i
                                  style={{
                                    top: "50%",
                                    right: "10px",
                                    transform: "translateY(-50%)",
                                  }}
                                  onClick={() => deleteFile()}
                                  className="fa-solid cursor-pointer hover:bg-brand-1 rounded-full hover:text-white absolute fa-circle-xmark"
                                ></i>
                              </h5>
                            )}
                          </>
                        )}
                      </div>
                    </span>
                  </Box>
                  <Box className="content">
                    <span className="container">
                      <div className="w-full">
                        {loading_image_2 ? (
                          <SkeletonWrapper>
                            <LinearProgress />
                          </SkeletonWrapper>
                        ) : (
                          <>
                            <label
                              className="text-xs mb-3 pl-5 block invisible font-medium"
                              htmlFor="genotype"
                            >
                              User photos
                            </label>
                            {secondFile?.errors?.length <= 0 &&
                            secondFile?.file?.length <= 0 ? (
                              <div
                                className={`h-full`}
                                {...getSecondRootProps()}
                              >
                                <div className="">
                                  <input
                                    required={true}
                                    {...getSecondInputProps()}
                                  />
                                  <h5 className="px-5 py-2.5 appearance-none cursor-pointer border-2 border-brand-1 text-center border-dashed ouline-0 focus:border-0 focus:outline-0  focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl">
                                    Upload photo 2
                                  </h5>
                                </div>
                              </div>
                            ) : secondFile?.errors?.length > 0 ? (
                              <div
                                className={`h-full`}
                                {...getSecondRootProps()}
                              >
                                <div className="">
                                  <input
                                    required={true}
                                    {...getSecondInputProps()}
                                  />
                                  <h5 className="px-5 py-2.5 appearance-none border-2 cursor-pointer text-red-400 border-red-400 text-center border-dashed ouline-0 focus:border-0 focus:outline-0  focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl">
                                    {secondFile?.errors[0]?.message}
                                  </h5>
                                </div>
                              </div>
                            ) : (
                              <h5 className="px-5 py-2.5 appearance-none relative border-2 border-brand-1 text-center border-dashed ouline-0 focus:border-0 focus:outline-0  focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl">
                                {secondFile?.file?.name}
                                {/* add image preview later */}
                                {/* <img src={mainFile?.file?.path} alt={mainFile?.file?.name} />
                  <img
                    src={secondFile?.file?.path}
                    alt={secondFile?.file?.name}
                  /> */}

                                <i
                                  style={{
                                    top: "50%",
                                    right: "10px",
                                    transform: "translateY(-50%)",
                                  }}
                                  onClick={() => deleteSecondFile()}
                                  className="fa-solid cursor-pointer hover:bg-brand-1 rounded-full hover:text-white absolute fa-circle-xmark"
                                ></i>
                              </h5>
                            )}
                          </>
                        )}
                      </div>
                    </span>
                  </Box>
                  {mainFile?.file?.name && secondFile?.file?.name && (
                    <Box className="content">
                      <div
                        onClick={handleUserUpdate}
                        style={{ cursor: "pointer" }}
                      >
                        <Chip label={"SAVE IMAGES"} color="primary" />
                      </div>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>

          {true && (
            <>
              {[]?.map((group: any) => (
                <Box className="content" key={group?.id}>
                  <Typography className="title-value" key={group?.id}>
                    {group?.name}
                  </Typography>
                </Box>
              ))}
            </>
          )}

          <Box className="">
            {true && (
              <>
                {[].map((staff: any) => (
                  <Box className="content" key={staff?.id}>
                    <Box>
                      <Typography>{`${staff?.first_name} ${staff?.last_name}`}</Typography>
                    </Box>
                    <Box>
                      <Typography>
                        {formatPhoneNumber(staff?.phone_number || "")}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </>
            )}
          </Box>
        </>
      )}
    </Container>
  );
};

export default InfoLocation;

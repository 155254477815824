import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const chartAreaBackgroundColor = {
  id: "chartAreaBackgroundColor",
  beforeDatasetsDraw(chart, args, plugins) {
    const {
      ctx,
      chartArea: { top, bottom, left, right, width, height },
    } = chart;

    ctx.save();
    ctx.fillStyle = "white";
    ctx.fillRect(left, top, width, height);
  },
};
const options = {
  scales: {
    y: {
      suggestedMax: 150,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Details",
    },
    chartAreaBackgroundColor,
  },
};

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const data = {
  labels,
  datasets: [
    {
      label: "",
      data: [65, 59, 80, 81, 56, 35, 27, 56, 90, 45],
      borderColor: "#3361FF",
      backgroundColor: "rgb(59 130 246 / 0.5)",
      fill: "start",
    },
  ],
};

export default function LineGraph() {
  return (
    <Line
      style={{ width: "100%", height: "100%" }}
      data={data}
      // @ts-ignore
      options={options}
    />
  );
}

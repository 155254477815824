import {
  ArrowDownward,
  ArrowUpward,
  ExpandMore,
  Star,
  StarOutline,
  ThumbUpOffAlt,
} from "@mui/icons-material";
import moment from "moment";
import { useState } from "react";
import Person from "src/assets/person.svg";
import LineGraph from "../Charts/Linegraph";

export default function HandlerRatings() {
  const [users, setUsers] = useState([
    {
      rated_user: "Martin Tyler",
      rating: 5,
      suggested_user: "Bryan Tilson",
      suggested_occupation: "manager",
      status: "pending",
      date_suggested: "2023-10-02T12:43:11.823Z",
    },
    {
      rated_user: "Ebony Chinenye",
      rating: 3,
      suggested_user: "Michelle Dozie",
      suggested_occupation: "product designer",
      status: "accepted",
      date_suggested: "2023-10-02T12:43:11.823Z",
    },
    {
      rated_user: "Martin Tyler",
      rating: 4,
      suggested_user: "Bryan Tilson",
      suggested_occupation: "manager",
      status: "declined",
      date_suggested: "2023-10-02T12:43:11.823Z",
    },
    {
      rated_user: "Ebony Chinenye",
      rating: 2,
      suggested_user: "Michelle Dozie",
      suggested_occupation: "product designer",
      status: "accepted",
      date_suggested: "2023-10-02T12:43:11.823Z",
    },
  ]);
  return (
    <div className="w-full h-full px-5 space-y-5 pb-40 overflow-y-auto">
      <div className="flex bg-white p-5 rounded-lg justify-between items-center">
        <div className="gap-7 flex items-center">
          <div className="space-y-2">
            <div className="flex gap-2 items-center">
              <span className="w-6 h-6 bg-blue-100 text-blue-600 flex justify-center items-center rounded-full">
                <ThumbUpOffAlt fontSize="small" />
              </span>
              <p className="text-xs">Total Ratings</p>
            </div>
            <h2 className="text-2xl font-semibold">150k</h2>
          </div>
          <div className="space-y-2">
            <div className="flex gap-2 items-center">
              <span className="w-6 h-6 bg-blue-100 text-blue-600 flex justify-center items-center rounded-full">
                <ThumbUpOffAlt fontSize="small" />
              </span>
              <p className="text-xs">Positive Ratings</p>
            </div>
            <h2 className="text-2xl flex items-end gap-1 font-semibold">
              240k
              <p className="flex text-10 items-center -mt-3">
                <ArrowUpward
                  fontSize="small"
                  className="text-green-600"
                  style={{ fontSize: "15px" }}
                />
                <span className="text-green-600 mr-1">12%</span>
                vs last 1 month
              </p>
            </h2>
          </div>
          <div className="space-y-2">
            <div className="flex gap-2 items-center">
              <span className="w-6 h-6 bg-red-100 text-red-600 flex justify-center items-center rounded-full">
                <ThumbUpOffAlt fontSize="small" />
              </span>
              <p className="text-xs">Negative Ratings</p>
            </div>
            <h2 className="text-2xl flex items-end gap-1 font-semibold">
              43k
              <p className="flex text-10 items-center -mt-3">
                <ArrowDownward
                  fontSize="small"
                  className="text-red-600"
                  style={{ fontSize: "15px" }}
                />
                <span className="text-red-600 mr-1">8%</span>
                vs last 1 month
              </p>
            </h2>
          </div>
        </div>
        <p className="text-sm w-max cursor-pointer font-bold flex gap-2 items-center">
          Monthly
          <ExpandMore className="text-blue-600" fontSize="small" />
        </p>
      </div>
      <div className="w-full h-80 p-5 pr-0 bg-blue-100 rounded-lg">
        <LineGraph />
      </div>
      <table className="relative w-full divide-y divide-gray-1 overflow-auto  bg-white">
        <thead className=" rounded-lg rounded-t border px-5">
          <tr className="rounded-t-lg">
            <th
              scope="col"
              className={`px-2 py-4 pl-5 text-left text-xs font-medium uppercase`}
            >
              <div className="flex gap-2 items-center">Users who rated</div>
            </th>
            <th
              scope="col"
              className={`px-2 py-4 text-left text-xs font-medium uppercase`}
            >
              <div className="flex gap-2 items-center">Rating</div>
            </th>
            <th
              scope="col"
              className={`px-2 py-4  text-left text-xs font-medium uppercase`}
            >
              <div className="flex gap-2 items-center">Status</div>
            </th>
            <th
              scope="col"
              className={`px-2 py-4   text-left text-xs font-medium uppercase`}
            >
              <div className="flex gap-2 items-center">Date Rated</div>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-300 overflow-auto bg-white px-5 pt-5">
          {users?.map?.((item: any, index: number) => {
            let myarr = Array.from({ length: 5 }, (_, index) => index);
            return (
              <tr className="hover:bg-gray-100" key={index}>
                <td className="px-2 pl-5 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                  <div className="flex gap-5 items-center">
                    <div className="w-8 h-8 rounded-full">
                      <img
                        src={Person}
                        alt="user"
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>
                    <div>
                      <h2 className="text-sm font-semibold">
                        {item?.rated_user}
                      </h2>
                      <p className="text-xs">{item?.suggested_occupation}</p>
                    </div>
                  </div>
                </td>
                <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                  <div className="flex gap-2 items-center">
                    {myarr?.map?.((i) => {
                      if (item?.rating < i + 1) {
                        return <StarOutline key={i} fontSize="small" />;
                      }
                      return (
                        <Star
                          key={i}
                          className={"text-yellow-400"}
                          fontSize="small"
                        />
                      );
                    })}
                  </div>
                </td>

                <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                  <p
                    className={`px-4 py-1 w-max rounded-full ${
                      item?.status?.toLowerCase?.() === "inactive"
                        ? "bg-[#545f7d3b] text-black"
                        : item?.status?.toLowerCase?.() === "pending"
                        ? "bg-[#e9b30042] text-[#E9B200]"
                        : item?.status?.toLowerCase?.() === "accepted"
                        ? "bg-[#39cd633f] text-[#39CD62] "
                        : "bg-[#e4033b41] text-[#E4033B]"
                    } text-center`}
                  >
                    {item?.status}
                  </p>
                </td>
                <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                  {moment(item?.date_suggested).format("MMM DD, YYYY")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

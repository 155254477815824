import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
  Modifier,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useState, FC } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import IconButton from "@mui/material/IconButton";
import {
  ButtonWrapperDiv,
  MainWrapperDiv,
  MessageVariable,
  VariableValue,
  StyledInput,
  AttachedImg,
  RemoveImg,
  AttachFile,
  Error,
} from "./RichEditor.style";
import { Emoji } from "./emoji-icons";
import ArticleIcon from "@mui/icons-material/Article";
import { Button } from "src/components";

interface Props {
  setFinishedText;
  body?: string;
  handleRichTextModalClose;
  toolbarCustomButtons?;
  width?: string;
  variant?;
  top?;
  sms?;
  attachedImg?;
  fileUpload?;
  removeFile?;
  placeholder?: string;
  addNode?;
  errorText?: string;
  customStyle?;
  allow_Attachment?: boolean;
  variablesOption?;
  accept_types?: string;
  view_only?: boolean;
}

export const RichEditor: FC<Props> = ({
  setFinishedText,
  body = "<p>My initial content.</p>",
  handleRichTextModalClose,
  width,
  variant,
  placeholder,
  top,
  sms,
  fileUpload,
  removeFile,
  attachedImg,
  errorText,
  customStyle = {},
  allow_Attachment = false,
  variablesOption,
  accept_types = "image/*",
  view_only = false,
}) => {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(body))
    )
  );

  const [variable] = useState("");
  let dummyTextHolder = "Message Variables";

  const insertText = (text, editorState) => {
    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text
    );

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      "insert-characters"
    );
    return EditorState.forceSelection(
      newEditorState,
      newContent.getSelectionAfter()
    );
  };

  const VariableSelect = () => {
    const handleChange = (event: SelectChangeEvent) => {
      setEditorState(insertText(`{${event.target.value}}`, editorState));
    };

    return (
      <>
        <Select
          onChange={handleChange}
          value={variable}
          IconComponent={KeyboardArrowDownIcon}
          MenuProps={{ sx: { height: "30%" } }}
          disabled={view_only}
        >
          {variablesOption &&
            variablesOption?.map((variable) => (
              <MenuItem value={variable?.value} key={variable.value}>
                {variable?.label}
              </MenuItem>
            ))}
        </Select>
      </>
    );
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  const onsubmit = (e) => {
    e.preventDefault();
    const tohtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setFinishedText(tohtml);
    // setEditorState(EditorState.createEmpty())
  };

  const customStyleMap = {
    BOLD: {
      textShadow: "1px 0 0 black",
      fontSize: "1.1em",
    },
  };
  const colorChange = {
    color: "1px 0 0 black",
  };

  const isImage = (file) => {
    return (
      file &&
      ["image", "gif", "jpeg", "png"].includes(file?.type?.split("/")[0])
    );
  };
  return (
    <>
      <MainWrapperDiv className={`${width && width}`}>
        <Editor
          customStyleMap={customStyleMap}
          editorState={editorState}
          readOnly={view_only}
          onEditorStateChange={onEditorStateChange}
          placeholder={placeholder || "Compose Your Answer"}
          toolbarCustomButtons={
            variablesOption?.length
              ? [
                  <MessageVariable>
                    {variable === "" && (
                      <VariableValue>{dummyTextHolder}</VariableValue>
                    )}
                    {VariableSelect()}
                  </MessageVariable>,
                ]
              : []
          }
          toolbar={{
            options: ["inline", "list", "link", "emoji", "history"],
            inline: {
              inDropdown: false,
              options: ["bold", "italic", "underline", "strikethrough"],
              bold: { className: colorChange },
            },
            list: {
              inDropdown: false,
              options: ["unordered", "ordered", "indent", "outdent"],
            },
            link: {
              inDropdown: false,
              showOpenOptionOnHover: true,
              defaultTargetOption: "_self",
              options: ["link", "unlink"],
            },
            emoji: {
              emojis: Emoji,
            },
            history: {
              inDropdown: false,
              options: ["undo", "redo"],
            },
          }}
          editorStyle={{
            paddingLeft: "10px",
            paddingRight: "10px",
            marginTop: top ? "40px" : "",
            height: top ? "130px" : "147px",
            ...customStyle,
          }}
        />
      </MainWrapperDiv>
      {allow_Attachment && !view_only ? (
        <>
          <AttachFile>
            <label htmlFor="contained-button-file">
              <StyledInput
                id="contained-button-file"
                type="file"
                //accept images and documents
                inputProps={{
                  multiple: true,
                  accept: accept_types,
                }}
                onChange={(e) => fileUpload(e)}
              />
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <AttachFileOutlinedIcon />
              </IconButton>
            </label>
          </AttachFile>
          <AttachedImg>
            {attachedImg &&
              attachedImg?.map((item, _i) => (
                <div key={_i}>
                  {isImage(item) ? (
                    <img src={item?.attachmentUrl} alt={"set"} />
                  ) : (
                    <ArticleIcon fontSize="large" />
                  )}
                  <RemoveImg onClick={() => removeFile(_i)} />
                </div>
              ))}
          </AttachedImg>
        </>
      ) : null}
      {errorText && <Error>{errorText}</Error>}
      <ButtonWrapperDiv className={`${width && width}`}>
        {/* <button onClick={handleRichTextModalClose}>Cancel</button> */}
        <Button onClick={onsubmit} type={"button"}>
          Send
        </Button>
      </ButtonWrapperDiv>
    </>
  );
};

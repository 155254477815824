import { useQuery } from "@apollo/client";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import NoUser from "src/assets/no_user.png";
import Card from "src/components/Card";
import InputField from "src/components/Input";
import Notifications from "src/components/Notification/notifications";
import PageLoader from "src/components/PageLoader";
import { USER_COMPLETED_MATCHES } from "src/components/auth/data/queries";
import { useUser } from "src/components/auth/hooks/UserProvider";

const Matches = () => {
  const { user } = useUser();

  const {
    data,
    loading: loading_user,
    // refetch,
    // error,
  } = useQuery(USER_COMPLETED_MATCHES, {
    variables: {
      userMatchesId: Number(user?.id),
    },
    fetchPolicy: "network-only",
  });

  const [search, setSearch] = useState("");
  const [active, setActive] = useState(true);
  // const [items, setItems] = useState<any>(SideBarItemObj);
  const [showNotifications, setShowNotifications] = useState(false);
  const [displayData, setDisplayData] = useState<any>([]);
  const userRef = useRef<HTMLInputElement | null>(null);
  // const { users, show, viewMatchProfile, singleUser } =
  //   useContext<any>(SideBarContext);
  const navigate = useNavigate();
  // const handleShowNotification = () => {
  //   setShowNotifications(!showNotifications);
  // };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let text = e.target.name;
    text = e.target.value;
    setSearch(text);
    // filter logic here....
  };

  const handleMatchProfileNavigation = (match) => {
    navigate(`/dashboard/match-profile/${match}`);
  };

  function handleClickOutside(event) {
    if (!userRef?.current?.contains(event.target)) setActive(true);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userRef]);

  useEffect(() => {
    if (search?.trim?.()) {
      const filteredData = data?.userMatches?.filter?.((i: any) =>
        i?.user_matched?.first_name
          ?.toLowerCase?.()
          ?.includes?.(search?.toLowerCase?.())
      );
      setDisplayData(filteredData);
    } else {
      setDisplayData(data?.userMatches);
    }
  }, [search, data]);

  return (
    <div className="w-full bg-white">
      {loading_user ? (
        <PageLoader />
      ) : (
        <>
          {displayData?.length > 0 && (
            <div className="flex w-full flex-col gap-3 sm:flex-row justify-between border-b-2 p-4">
              <div>
                <span className="font-semibold text-lg">Recent Matches</span>
                {search?.trim?.() && (
                  <p>
                    Showing result for{" "}
                    <span className="font-bold text-blue-600 uppercase">
                      {search}
                    </span>
                  </p>
                )}
              </div>
              <div ref={userRef} className="">
                {active ? (
                  <div
                    onClick={() => setActive(false)}
                    className="bg-[#E3E8F1] w-full sm:w-52 h-8 flex justify-center rounded-lg items-center cursor-pointer max-lg:w-36"
                  >
                    <span>
                      <SearchOutlinedIcon className="text-[#979797]" />
                    </span>
                    <span className="text-[#979797] ml-2 max-lg:text-xs">
                      Search...
                    </span>
                  </div>
                ) : (
                  <InputField
                    name="search"
                    value={search}
                    onChange={handleChange}
                    inBox="mt-0 bg-inherit"
                    inputClassName="bg-[#E3E8F1] pl-4 rounded-lg text-sm
                max-lg:text-xs appearance-none w-full sm:w-52 h-8 border-0 focus:outline-none focus:border-transparent focus:ring-0 max-lg:w-36
                "
                  />
                )}
              </div>
            </div>
          )}
          {displayData?.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 px-5 py-7">
              {displayData?.map((userMatches: any, i: number) => (
                <div key={i} className="">
                  {/* <Card
                    img={userMatches?.user_matched?.profile_image_url}
                    name={userMatches?.user_matched?.first_name}
                    occupation={userMatches?.user_matched?.occupation}
                    userMatch={userMatches}
                    handleCardClick={() =>
                      handleMatchProfileNavigation(
                        userMatches?.user_matched?.id
                      )
                    }
                    btnClickAccept={() =>
                      handleMatchProfileNavigation(userMatches)
                    }
                    btnClickDecline={() =>
                      handleMatchProfileNavigation(userMatches)
                    }
                  /> */}
                </div>
              ))}
            </div>
          ) : (
            <div className=" items-center rounded-lg justify-center w-full py-20 bg-white flex">
              <div className="h-full flex gap-5 flex-col justify-center items-center">
                <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                  <img
                    src={NoUser}
                    alt="empty"
                    className="w-5 md:w-10 h-5 md:h-10 object-contain"
                  />
                </div>
                <h2 className="text-sm lg:text-lg text-center font-bold text-[#081A51] ">
                  You have no available matches
                </h2>
                <p className="text-xs lg:text-sm w-4/5 pb-5 md:w-3/5 mx-auto text-center">
                  You have no matches. Kindly check this space frequently to see
                  all matches accepted by you . We welcome you to chat with your
                  handler if you have any complaints or inquiries
                </p>
                <button
                  onClick={() => {
                    navigate("/dashboard/handler-chat");
                  }}
                  className="px-6 text-sm md:text-base py-2.5 rounded-lg bg-[#081A51] text-white font-semibold hover:opacity-75 disabled:bg-gray-300 disabled:text-gray-700"
                >
                  Chat with Handler
                </button>
              </div>
            </div>
          )}
        </>
      )}
      <Notifications
        visible={showNotifications}
        close={() => setShowNotifications(false)}
        user_id={Number(user?.id)}
      />
    </div>
  );
};

export default Matches;

import { useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { MoonLoader } from "react-spinners";
import ASSETS from "src/asset";
import AllTherapists from "src/components/usertherapy/AllTherapists";
import SelectedTherapist from "src/components/usertherapy/SelectedTherapist";
import UserTherapistBooking from "src/components/usertherapy/UserTherapistBooking";
import { GET_ALL_THERAPIST } from "src/hooks/data/queries";
import SlideCardsOverlay from "src/overlays/SlideCardsOverlay";

export default function UserTherapy() {
  const [indexPage, setIndexPage] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [allAvailableTherapist, setAllAvailableTherapist] = useState([]);
  const [fetchData, { loading }] = useLazyQuery(GET_ALL_THERAPIST, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const tdata = data?.allTherapist?.data;
      setAllAvailableTherapist(tdata);
      setSelectedTherapist(tdata?.[0]);
      setOpenConfirm(true);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [selectedTherapist, setSelectedTherapist] = useState(null);

  const handleClick = () => {
    fetchData({
      variables: {
        page: 1,
        pageSize: 10,
      },
    });
  };
  const handleCheckClick = () => {
    setIndexPage(1);
    setOpenConfirm(false);
  };

  const handleBackClick = () => {
    setIndexPage(0);
    setSelectedTherapist(null);
  };

  return (
    <>
      <SlideCardsOverlay
        isOpen={openConfirm}
        setIsOpen={setOpenConfirm}
        title={""}
      >
        <div
          onClick={() => setOpenConfirm(false)}
          className="h-full bg-transparent w-full"
        >
          <AllTherapists
            selectedTherapist={selectedTherapist}
            setSelectedTherapist={setSelectedTherapist}
            availableTherapists={allAvailableTherapist}
            handleCheckClick={handleCheckClick}
            setIndexPage={setIndexPage}
            setOpenConfirm={setOpenConfirm}
          />
        </div>
      </SlideCardsOverlay>
      {loading && (
        <div className="fixed z-50 left-0 bg-[#000] bg-opacity-75 top-0 w-full h-screen flex justify-center items-center">
          <MoonLoader size={40} color="#fff" />
        </div>
      )}
      {indexPage === 0 ? (
        <div className="space-y-5">
          <div className="flex gap-5">
            <div className="flex-grow bg-gradient-to-r from-[#FE6D68] to-[#FEC76A] rounded-lg">
              <div
                style={{
                  backgroundImage: `url(${ASSETS?.FadeBackground})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
                className="flex gap-5 w-full items-center p-5 h-full"
              >
                <div className="space-y-4 text-white">
                  <div>
                    <h2 className="text-4xl canela font-semibold">
                      Welcome to Nirvanae Wellness
                    </h2>
                    <p className="text-sm">
                      Start your journey to self-discovery and healthier
                      connections. At Nirvanae Wellness, we provide expert
                      guidance and resources to help you grow emotionally, heal,
                      and build fulfilling relationships.
                    </p>
                  </div>
                  <button
                    onClick={() => {
                      // //@ts-ignore
                      // setSelectedTherapist(temp_ponds?.[0]);
                      // setOpenConfirm(true);
                      handleClick();
                    }}
                    className="px-6 hover:opacity-70 rounded-lg py-2.5 bg-white border-none outline-none text-[#FE6D68] text-sm font-semibold"
                  >
                    Book a Therapist
                  </button>
                </div>
                <img
                  src={ASSETS?.TherapyHero}
                  alt="hero"
                  className="max-w-sm hidden lg:inline-block"
                />
              </div>
            </div>
            {/* <div className="w-4/12 flex-shrink-0 divide-y  bg-white rounded-lg divide-gray-300">
              <div className="flex px-4 py-3 justify-between items-center">
                <h2 className="text-sm font-semibold">Appointments</h2>
                <div className="w-8 h-8 flex justify-center items-center text-xs rounded-full border-2 border-green-600">
                  0/3
                </div>
              </div>
              <div className="">
                <div className="h-full w-full bg-white shadow-md rounded-md lg:col-span-1 ">
                  <Calendercomp
                    setModal={setModal}
                    userData={userData}
                    setSelectedDate={setSelectedDate}
                    hideTitle={true}
                  />
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="flex gap-5">
            <div className="flex-grow divide-y divide-gray-300 bg-white rounded-lg ">
              <h2 className="text-sm font-semibold p-4">Recent Appointments</h2>
              <div className="">
                <table className="relative rounded w-full divide-y divide-gray-1 overflow-y-auto  bg-white">
                  <thead className=" rounded-lg rounded-t px-5">
                    <tr className="rounded-t-lg">
                      <th
                        scope="col"
                        className={`px-2 py-4 pl-5 text-left text-xs font-medium capitalize whitespace-nowrap`}
                      >
                        Therapist
                      </th>
                      <th
                        scope="col"
                        className={`px-2 py-4  text-left text-xs font-medium capitalize whitespace-nowrap`}
                      >
                        Start Time
                      </th>
                      <th
                        scope="col"
                        className={`px-2 py-4   text-left text-xs font-medium capitalize whitespace-nowrap`}
                      >
                        End Time
                      </th>
                      <th
                        scope="col"
                        className={`px-2 py-4 text-left text-xs font-medium capitalize whitespace-nowrap`}
                      >
                        Meeting Status
                      </th>

                      <th
                        scope="col"
                        className={`px-2 py-4  text-left text-xs font-medium capitalize whitespace-nowrap`}
                      >
                        Feedback
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-300 overflow-auto bg-white px-5 pt-5">
                    {allDatas?.map?.((item: any, index: number) => (
                      <tr
                        className="hover:bg-gray-100 cursor-pointer"
                        key={index}
                      >
                        <td className="px-2 pl-5 py-4 text-left text-sm font-medium capitalize text-[#545F7D]">
                          {item?.therapist}
                        </td>
                        <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                          {item?.start_time}
                        </td>
                        <td className="px-2 py-4 text-left text-xs font-normal lowercase text-[#545F7D]">
                          {item?.end_time}
                        </td>
                        <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                          <p
                            className={`px-4 py-1 w-max rounded-full ${
                              item?.status?.toLowerCase?.() === "inactive"
                                ? "bg-[#545f7d3b] text-black"
                                : item?.status?.toLowerCase?.() === "pending"
                                ? "bg-[#e9b30042] text-[#E9B200]"
                                : item?.status?.toLowerCase?.() === "active"
                                ? "bg-green-100 text-green-600 "
                                : "bg-gray-100 text-gray-600"
                            } text-center`}
                          >
                            {item?.status}
                          </p>
                        </td>

                        <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                          <p
                            className={`px-4 py-1 w-max rounded-full ${
                              item?.feedback?.toLowerCase?.() === "inactive"
                                ? "bg-[#545f7d3b] text-black"
                                : item?.feedback?.toLowerCase?.() === "pending"
                                ? "bg-[#e9b30042] text-[#E9B200]"
                                : item?.feedback?.toLowerCase?.() === "active"
                                ? "bg-green-100 text-green-600 "
                                : "bg-gray-100 text-gray-600"
                            } text-center`}
                          >
                            Sent
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="w-4/12 flex-shrink-0 divide-y  bg-white rounded-lg divide-gray-300">
              <h2 className="text-sm font-semibold p-4">
                Upcoming Appointment
              </h2>
              <div className="flex flex-col justify-center gap-2 p-4 items-center">
                <img src={ASSETS.Therapist1} alt="doc" className="w-40 h-40" />
                <h2 className="text-sm font-semibold">Dr Bunny</h2>
                <p className="text-xs">07:30am 28th Oct 2024</p>
              </div>
              <div className="flex items-center justify-between p-4">
                <button className="px-4 hover:opacity-70 rounded-lg py-2.5 bg-white border border-gray-400 outline-none text-xs font-semibold">
                  Cancel Appointment
                </button>
                <button className="px-6 hover:opacity-70 border border-blue-600 rounded-lg py-2.5 bg-blue-600 border-none outline-none text-white text-xs font-semibold">
                  Join meeting
                </button>
              </div>
            </div>
          </div> */}
          <UserTherapistBooking />
        </div>
      ) : (
        <SelectedTherapist
          selectedTherapist={selectedTherapist}
          handleBackClick={handleBackClick}
        />
      )}
    </>
  );
}

import FooterComponent from "../components/footer/Index";
import InputFields from "../components/FormComponent";
import HomeHeader from "../components/header/Homeheader";

export default function ProfilePage() {
  return (
    <div className="container px-5 pt-10 pb-5 mx-auto">
      <HomeHeader />
      <div className="absolute flex items-center sm:items-end sm:pb-10 top-0 left-0 bgfriend">
        <div className="container px-5 mx-auto">
          <h2 className="text-3xl sm:text-6xl mt-10 sm:mt-0 text-center sm:text-left font-bold text-white">
            Profile
            <br /> Info
          </h2>
        </div>
      </div>
      <div className="flex flex-col pt-[250px] justify-between gap-10">
        <div className="w-full md:w-5/6 lg:w-2/3  py-10">
          <h2 className="text-base">
            This feature helps you find people with similar interests and helps
            you build your social network
          </h2>
          <form className="py-10">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5">
              <InputFields label={"Sex"} />
              <InputFields label={"Genotype"} />
              <InputFields label={"State of Origin"} />
              <InputFields label={"Location"} />
              <InputFields label={"Height"} />
              <InputFields label={"Religion"} />
              <InputFields label={"Body Type"} />
              <InputFields label={"Personality Type"} />
              <div className="sm:col-span-2">
                <InputFields label={"Level Of Education"} />
              </div>
            </div>
            <div className="w-full flex-wrap gap-10 justify-between py-10 flex">
              <div className="w-full sm:w-2/5">
                <input
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Full Name"
                  autoComplete="off"
                  className="appearance-none block w-full px-3 py-2.5 border-b border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-1 focus:border-blue-1 sm:text-lg"
                />
              </div>
              <div className="w-full sm:w-2/5">
                <input
                  id="occupation"
                  name="occupation"
                  type="text"
                  placeholder="Occupation"
                  autoComplete="off"
                  className="appearance-none block w-full px-3 py-2.5 border-b border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-1 focus:border-blue-1 sm:text-lg"
                />
              </div>
              <div className="w-full sm:w-2/5">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  autoComplete="off"
                  className="appearance-none block w-full px-3 py-2.5 border-b border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-1 focus:border-blue-1 sm:text-lg"
                />
              </div>
              <div className="w-full sm:w-2/5">
                <input
                  id="code"
                  name="code"
                  type="text"
                  placeholder="Personality Type"
                  autoComplete="off"
                  className="appearance-none block w-full px-3 py-2.5 border-b border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-1 focus:border-blue-1 sm:text-lg"
                />
              </div>
            </div>
            <button
              type="submit"
              className="bg-transparent mt-10 py-3 px-10 text-sm text-gray-500 font-bold hover:bg-blue-300 transition ease-in-out duration-300 hover:text-white font-medium outline-0 border border-blue-200 uppercase"
            >
              Submit
            </button>
          </form>
        </div>
        <FooterComponent />
      </div>
    </div>
  );
}

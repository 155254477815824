export default function InputFields({ label }) {
  return (
    <div className="w-full flex flex-col">
      <label className="text-base font-medium">{label ?? ""}</label>
      <select className="mt-1 block w-full px-3 py-2.5 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-1 focus:border-blue-1 sm:text-lg">
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
      </select>
    </div>
  );
}

import { useMutation, useQuery } from "@apollo/client";
import { Check, ContentPaste, Edit } from "@mui/icons-material";
import { useState } from "react";
import { BeatLoader } from "react-spinners";
import ASSETS from "src/asset";
import ClientDetails from "src/components/handlerTherapy/ClientDetails";
import { getLocalTimeOfUser } from "src/helpers/utils";
import { GET_SINGLE_THERAPIST } from "src/hooks/data/queries";
import ResponsiveOverlay from "src/overlays/ResponsiveOverlay";
import GaugeChart from "react-gauge-chart";
import { FaVideoSlash } from "react-icons/fa6";
import Iframe from "react-iframe";
import { toast } from "react-toastify";
import { UPDATE_USER_BOOK_THERAPIST } from "src/hooks/data/mutations";
import moment from "moment";

export default function HandlerTherapy() {
  const [openReview, setOpenReview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [devoT, setDevoT] = useState(4);
  const { data, loading, refetch } = useQuery(GET_SINGLE_THERAPIST, {
    variables: {
      therapistId: Number(devoT),
    },
    fetchPolicy: "network-only",
  });
  const [updatebookingTherapist] = useMutation(UPDATE_USER_BOOK_THERAPIST);

  const allDatas = data?.therapist?.bookings;
  const [indexPage, setIndexPage] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);

  const [meetingLink, setMeetLink] = useState("");
  const [endTime, setEndTime] = useState<any>("");
  const [openFrame, setOpenFrame] = useState<boolean>(false);
  const [targetBooking, setTargetBooking] = useState(null);
  const [handlerNote, setHandlerNote] = useState("");

  const handleTerminate = () => {
    setOpenFrame(false);
    setEndTime("");
    setMeetLink("");
    //@ts-ignore
    // handleSubtleUpdate({ meetingHeld: true }, Number(targetBooking?.id));
    // handletermination();
    setOpenReview(true);
  };
  const handleUpdateClick = (values, bookingId) => {
    setIsLoading(true);
    updatebookingTherapist({
      variables: {
        updateBookingId: bookingId,
        payload: values,
      },
      onCompleted: (data) => {
        toast.success("Report submitted successfully");
        setIsLoading(false);
        setOpenReview(false);
        setHandlerNote("");
        refetch();
      },
      onError: (error) => {
        toast.error("Error submitting note. Try again later");
        setIsLoading(false);
        console.log({ error });
      },
    });
  };

  const handleSubtleUpdate = (values, bookingId) => {
    updatebookingTherapist({
      variables: {
        updateBookingId: bookingId,
        payload: values,
      },
      onCompleted: (data) => {
        refetch();
      },
      onError: (error) => {
        console.log({ error });
      },
    });
  };
  const upComingBookings =
    allDatas?.length > 0
      ? allDatas?.filter?.(
          (i) =>
            i?.status?.toLowerCase?.() !== "cancelled" &&
            i?.therapistStatus?.toLowerCase?.() === "pending" &&
            new Date(i?.availability?.startTime) > new Date()
        )
      : [];

  const devTherapists = [
    {
      id: "7",
      name: "Gigi – Your Sound Mind Guide!",
    },
    {
      id: "6",
      name: "Zane – The Empathetic Listener!",
    },
    {
      id: "5",
      name: "Rio – Your Positive Change Partner!",
    },
    {
      id: "4",
      name: "Sage – The Compassionate Catalyst!",
    },
  ];

  const handleStartMeeting = (upcoming) => {
  console.log("Upcoming session details:", upcoming);

  // Convert the meeting time to local time
  const meetingTime = moment.utc(upcoming?.sessionDate); 
  const localMeetingTime = moment(getLocalTimeOfUser(meetingTime, "YYYY-MM-DDTHH:mm:ss")); 

  // Get the current time in the user's local timezone
  const currentTime = moment(); 
  const adjustedMeetingTime = localMeetingTime.clone().subtract(1, "minutes"); 

  console.log("Local Meeting Time:", localMeetingTime.format("YYYY-MM-DD HH:mm:ss"));
  console.log("Adjusted Meeting Time (1 minute earlier):", adjustedMeetingTime.format("YYYY-MM-DD HH:mm:ss"));
  console.log("Current Local Time:", currentTime.format("YYYY-MM-DD HH:mm:ss"));

  // Compare local times
  if (currentTime.isSameOrAfter(adjustedMeetingTime)) {
    console.log("Meeting can start now.");
    setMeetLink(upcoming?.meetingLink);
    handleSubtleUpdate(
      { therapistStatus: "CONFIRMED" },
      Number(upcoming?.id)
    );
    setOpenFrame(true);
    setTargetBooking(upcoming);
    setHandlerNote("");
  } else {
    console.log("Meeting cannot start yet.");
    toast?.error("You can't join the meeting now as the time isn't due yet.");
  }
};


  return (
    <>
      {openFrame && (
        <div
          style={{ zIndex: 70 }}
          className="fixed top-0 bottom-0  left-0 right-0 bg-black bg-opacity-70 flex justify-center items-center  "
        >
          <div className="relative w-full h-full ">
            <Iframe
              url={meetingLink}
              width="100%"
              height="100%"
              className="w-full md:h-full  "
              // allowFullScreen={true}
              allow="camera; microphone; fullscreen; autoplay; encrypted-media"
            />
            <h1 className="animate-pulse text-sm lg:text-base font-bold text-white absolute bottom-24 left-2 md:bottom-2 w-max ">
              Meeting will end by {endTime}{" "}
            </h1>
            <div
              onClick={() => handleTerminate()}
              className="absolute bottom-24 md:bottom-2 right-2 w-max   shadow shadow-white  flex items-center gap-2 bg-red-500 text-white px-3 lg:px-5 rounded-md  py-2  cursor-pointer "
            >
              <h1 className=" text-sm lg:text-base">End Meeting</h1>
              <FaVideoSlash className="lg:text-xl" />
            </div>
          </div>
        </div>
      )}
      <ResponsiveOverlay
        isOpen={openReview}
        setIsOpen={setOpenReview}
        title={"Please drop a meeting note"}
        size="small"
      >
        <div className="w-full">
          <div className="space-y-5 p-5 sm:pt-0 sm:p-10 pt-0">
            {/* <div className="space-y-3">
              <label
                htmlFor="meeting_date"
                className="text-sm block font-medium"
              >
                Meeting Date
              </label>
              <input
                name="meeting_date"
                id="meeting_date"
                className="text-base w-full px-4 focus:border-blue-600 focus:border-2 py-2.5 outline-none border border-gray-300 rounded"
                placeholder="e.g Excellent session"
                autoComplete="off"
                autoCorrect="off"
                type="date"
              />
            </div> */}
            <div className="space-y-3">
              <label htmlFor="note" className="text-sm block font-medium">
                Note
              </label>
              <textarea
                name="note"
                id="note"
                className="text-base w-full h-40 px-4 py-2.5  outline-none border border-gray-300 rounded"
                placeholder="Enter your note here"
                value={handlerNote}
                onChange={(e) => setHandlerNote(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="flex p-5 w-full justify-end gap-5 flex-col-reverse sm:flex-row bg-gray-50 items-center">
            {!isLoading && (
              <button
                onClick={() => {
                  setOpenReview(false);

                  toast.info(
                    "Kindly note as a therapist, you're required to submit a brief note on the session "
                  );
                }}
                className="px-6 py-2 rounded-lg w-full sm:w-max text-center text-base font-semibold bg-white border border-gray-400 text-gray-700"
              >
                Cancel
              </button>
            )}
            <button
              disabled={isLoading}
              onClick={() => {
                if (handlerNote?.trim?.() === "") {
                  return toast.error("Kindly fill all fields");
                }
                const payload = {
                  therapist_review: handlerNote?.trim?.(),
                  therapistStatus: "COMPLETED",
                };
                //@ts-ignore
                handleUpdateClick(payload, Number(targetBooking?.id));
              }}
              className="px-6 py-2 rounded-lg w-full sm:w-max text-center text-base font-semibold bg-blue-600 text-white"
            >
              {isLoading ? (
                <BeatLoader size={10} color="#fff" />
              ) : (
                "Submit Note"
              )}
            </button>
          </div>
        </div>
      </ResponsiveOverlay>
      {indexPage === 0 ? (
        <div className="space-y-5">
          <div className="flex justify-end gap-3 items-center">
            {devTherapists?.map?.((dev) => (
              <button
                onClick={() => setDevoT(Number(dev?.id))}
                key={dev?.id}
                className={`px-4 border rounded-lg py-1.5 ${
                  devoT === Number(dev?.id)
                    ? "bg-blue-600 border-blue-600 text-white"
                    : "hover:bg-gray-100 border-gray-600"
                } text-sm `}
              >
                {dev?.name?.split?.("–")?.[0]}
              </button>
            ))}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
            <div className="w-full divide-y  bg-white rounded-lg divide-gray-300">
              <h2 className="text-sm font-semibold p-4">Overall Rating</h2>
              <div className="p-4">
                <GaugeChart
                  colors={["#dc2626", "#facc15", "#16a34a"]}
                  percent={0}
                  textColor="#000"
                  id="gauge-chart1"
                />
                <h2 className="text-sm text-center font-semibold">
                  {" "}
                  Rating is unavailable
                </h2>
                <p className="text-xs text-center">
                  This feature is coming soon!
                </p>
              </div>
            </div>
            <div className="w-full divide-y sm:col-span-2 bg-white rounded-lg divide-gray-300">
              <h2 className="text-sm font-semibold p-4">
                Upcoming Appointments
              </h2>
              <div className="p-4 divide-y divide-gray-200 ">
                {upComingBookings?.length > 0 ? (
                  upComingBookings
                    ?.sort?.(
                      (a, b) =>
                        //@ts-ignore
                        new Date(a?.availability?.startTime) -
                        //@ts-ignore
                        new Date(b?.availability?.startTime)
                    )
                    ?.map?.((upcoming) => (
                      <div key={upcoming?.id} className="py-2">
                        <div className="flex flex-col sm:flex-row gap-5 justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="w-8 h-8 flex-shrink-0 rounded-full">
                              <img
                                src={
                                  upcoming?.user?.profile_image_url ??
                                  ASSETS?.Avi
                                }
                                alt="profile"
                                className="h-full w-full object-cover rounded-full"
                              />
                            </div>
                            <div className="">
                              <h2 className="text-sm font-semibold">
                                {upcoming?.user?.first_name +
                                  " " +
                                  upcoming?.user?.last_name}
                              </h2>
                              <p className="text-xs">
                                {`${getLocalTimeOfUser(
                                  upcoming?.availability?.startTime,
                                  "dddd Do MMM YYYY"
                                )} (${getLocalTimeOfUser(
                                  upcoming?.availability?.startTime,
                                  "h:mm a"
                                )} - ${getLocalTimeOfUser(
                                  upcoming?.availability?.endTime,
                                  "h:mm a"
                                )})`}
                              </p>
                            </div>
                          </div>
                          <button
                            //   disabled={true}
                            onClick={() => {
                              handleStartMeeting(upcoming)
                            }}
                            className="px-4 disabled:bg-gray-300 w-full sm:w-max disabled:text-gray-600 disabled:border-gray-600  hover:opacity-70 border border-blue-600 rounded-lg py-2.5 bg-blue-600 border-none outline-none text-white text-xs font-semibold"
                          >
                            Join meeting
                          </button>
                        </div>
                      </div>
                    ))
                ) : (
                  <div className="flex h-40 gap-2 justify-center flex-col items-center">
                    <div className="w-10 h-10 text-2xl bg-gray-50 border border-[#FE6D68] text-[#FE6D68] rounded-lg flex justify-center items-center">
                      <ContentPaste fontSize="inherit" color="inherit" />
                    </div>
                    No appointment available
                  </div>
                )}
              </div>
            </div>
            {/* <div className="w-full hidden lg:inline-block divide-y  bg-white rounded-lg divide-gray-300">
              <h2 className="text-sm font-semibold p-4">Calendar</h2>
            </div> */}
          </div>

          <div
            style={{ minHeight: "350px" }}
            className="flex-grow divide-y divide-gray-300  bg-white rounded-lg "
          >
            <h2 className="text-sm font-semibold p-4">Recent Appointments</h2>
            <div className="w-full overflow-x-auto">
              {loading ? (
                <div className="flex h-60 justify-center items-center">
                  <BeatLoader color="#FE6D68" size={20} />
                </div>
              ) : allDatas?.length > 0 ? (
                <table className="relative rounded w-full divide-y divide-gray-1 overflow-y-auto  bg-white">
                  <thead className=" rounded-lg rounded-t px-5">
                    <tr className="rounded-t-lg">
                      <th
                        scope="col"
                        className={`px-2 py-4 pl-5 text-left text-xs font-medium capitalize whitespace-nowrap`}
                      >
                        Client
                      </th>
                      <th
                        scope="col"
                        className={`px-2 py-4  text-left text-xs font-medium capitalize whitespace-nowrap`}
                      >
                        Start Time
                      </th>
                      <th
                        scope="col"
                        className={`px-2 py-4   text-left text-xs font-medium capitalize whitespace-nowrap`}
                      >
                        End Time
                      </th>
                      <th
                        scope="col"
                        className={`px-2 py-4 text-left text-xs font-medium capitalize whitespace-nowrap`}
                      >
                        Meeting Status
                      </th>

                      <th
                        scope="col"
                        className={`px-2 py-4  text-left text-xs font-medium capitalize whitespace-nowrap`}
                      >
                        Notes
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-300 overflow-auto bg-white px-5 pt-5">
                    {allDatas
                      ?.sort?.(
                        (a, b) =>
                          //@ts-ignore
                          new Date(b?.availability?.startTime) -
                          //@ts-ignore
                          new Date(a?.availability?.startTime)
                      )
                      ?.map?.((item: any, index: number) => (
                        <tr
                          onClick={() => {
                            setSelectedUser(item?.user);
                            setIndexPage(1);
                          }}
                          className="hover:bg-gray-50 cursor-pointer"
                          key={index}
                        >
                          <td className="px-2 pl-5 py-4 text-left whitespace-nowrap lg:whitespace-normal text-xs font-medium capitalize text-[#545F7D]">
                            {item?.user?.first_name +
                              " " +
                              item?.user?.last_name}
                          </td>
                          <td className="px-2 py-4 text-left whitespace-nowrap lg:whitespace-normal text-xs font-normal text-[#545F7D]">
                            {getLocalTimeOfUser(
                              item?.availability?.startTime,
                              "h:mm a, Do MMM YYYY"
                            )}
                          </td>
                          <td className="px-2 py-4 text-left whitespace-nowrap lg:whitespace-normal text-xs font-normal text-[#545F7D]">
                            {getLocalTimeOfUser(
                              item?.availability?.endTime,
                              "h:mm a, Do MMM YYYY"
                            )}
                          </td>
                          <td className="px-2 py-4 text-left whitespace-nowrap lg:whitespace-normal text-xs font-normal capitalize text-[#545F7D]">
                            <p
                              className={`px-2 py-0.5 w-max border rounded-md ${
                                item?.status?.toLowerCase?.() === "cancelled"
                                  ? "bg-red-100 text-red-600 border-red-600"
                                  : item?.therapistStatus?.toLowerCase?.() ===
                                    "inactive"
                                  ? "bg-[#545f7d3b] text-black border-[#545f7d3b]"
                                  : item?.therapistStatus?.toLowerCase?.() ===
                                    "pending"
                                  ? "bg-yellow-100 text-yellow-600 border-yellow-600"
                                  : item?.therapistStatus?.toLowerCase?.() ===
                                    "cancelled"
                                  ? "bg-red-100 text-red-600 border-red-600"
                                  : item?.therapistStatus?.toLowerCase?.() ===
                                      "active" ||
                                    item?.therapistStatus?.toLowerCase?.() ===
                                      "completed"
                                  ? "bg-green-100 text-green-600 border-green-600 "
                                  : "bg-gray-100 text-gray-600 border-gray-600"
                              } text-center`}
                            >
                              {item?.status?.toLowerCase?.() === "cancelled"
                                ? item?.status?.toLowerCase?.()
                                : item?.therapistStatus?.toLowerCase?.() ===
                                  "confirmed"
                                ? "Attended"
                                : item?.therapistStatus?.toLowerCase?.()}
                            </p>
                          </td>

                          <td
                            onClick={(e) => e.stopPropagation()}
                            className="px-2 py-4 text-left whitespace-nowrap lg:whitespace-normal text-xs font-normal capitalize text-[#545F7D]"
                          >
                            {item?.status?.toLowerCase?.() === "cancelled" ? (
                              <p
                                className={`px-2 py-0.5 w-max border border-red-600 rounded-md bg-red-600 text-white text-center`}
                              >
                                Cancelled
                              </p>
                            ) : item?.therapistStatus?.toLowerCase?.() ===
                                "pending" && item?.meetingHeld === false ? (
                              <p
                                className={`px-2 py-0.5 w-max border border-yellow-600 rounded-md bg-yellow-600 text-white text-center`}
                              >
                                Pending
                              </p>
                            ) : item?.meetingHeld === true &&
                              item?.therapist_review ? (
                              <p
                                className={`px-2 flex items-center gap-1 hover:opacity-70 py-0.5 w-max border border-green-600 rounded-md bg-green-600 text-white text-center`}
                              >
                                <Check fontSize="inherit" color="inherit" />
                                Sent
                              </p>
                            ) : (
                              <p
                                onClick={(e) => {
                                  setOpenReview(true);
                                  e.stopPropagation();
                                  setTargetBooking(item);
                                  setHandlerNote("");
                                }}
                                className={`px-2 flex items-center gap-1 hover:opacity-70 py-0.5 w-max border border-blue-600 rounded-md bg-blue-600 text-white text-center`}
                              >
                                <Edit fontSize="inherit" color="inherit" />
                                Write
                              </p>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                <div className="flex h-60 gap-2 justify-center flex-col items-center">
                  <div className="w-20 h-20 text-4xl bg-gray-50 border border-[#FE6D68] text-[#FE6D68] rounded-lg flex justify-center items-center">
                    <ContentPaste fontSize="inherit" color="inherit" />
                  </div>
                  No booking available
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <ClientDetails passedUser={selectedUser} setIndexPage={setIndexPage} />
      )}
    </>
  );
}

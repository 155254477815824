import moment from "moment";
import { useState } from "react";
import LinePagination from "src/Paging/LinePagination";
const Person =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/person.svg";

interface User {
  main_user: String;
  main_user_occupation: String;
  suggested_user: String;
  suggested_occupation: String;
  status: String;
  date_suggested: String;
}
type Alluserdata = User[];
export default function AdminUserSuggestion() {
  const [selectedMessageFilter, setSelectedMessageFilter] = useState("all");
  const [users, setUsers] = useState([
    {
      main_user: "Martin Tyler",
      main_user_occupation: "chef",
      suggested_user: "Bryan Tilson",
      suggested_occupation: "manager",
      status: "pending",
      date_suggested: "2023-10-02T12:43:11.823Z",
    },
    {
      main_user: "Ebony Chinenye",
      main_user_occupation: "realtor",
      suggested_user: "Michelle Dozie",
      suggested_occupation: "product designer",
      status: "accepted",
      date_suggested: "2023-10-02T12:43:11.823Z",
    },
    {
      main_user: "Martin Tyler",
      main_user_occupation: "chef",
      suggested_user: "Bryan Tilson",
      suggested_occupation: "manager",
      status: "declined",
      date_suggested: "2023-10-02T12:43:11.823Z",
    },
    {
      main_user: "Ebony Chinenye",
      main_user_occupation: "realtor",
      suggested_user: "Michelle Dozie",
      suggested_occupation: "product designer",
      status: "accepted",
      date_suggested: "2023-10-02T12:43:11.823Z",
    },
  ]);
  const [allDatas, setAllDatas] = useState<Alluserdata | []>([]);
  return (
    <div className="space-y-4 absolute top-0 left-0 w-full h-full overflow-y-auto">
      <div className="grid px-5 grid-cols-4 gap-3">
        <div
          onClick={() => setSelectedMessageFilter("all")}
          className={`w-full ${
            selectedMessageFilter === "all"
              ? "bg-[#3361FF] text-white"
              : "bg-white hover:bg-gray-100"
          } px-3 py-2 flex gap-2 items-center justify-center rounded-lg relative cursor-pointer font-semibold `}
        >
          <p
            style={{ top: "50%", transform: "translateY(-50%)", height: "70%" }}
            className={`absolute w-1 ${
              selectedMessageFilter === "all" ? "bg-white" : "bg-green-600"
            } left-1 top-1 `}
          ></p>
          <p className="text-normal text-10">All suggestions</p>
          <span className="w-6 h-6 rounded-full border-2 border-gray-200  flex-shrink-0 flex justify-center items-center">
            <p className=" text-10 ">10</p>
          </span>
        </div>
        <div
          onClick={() => setSelectedMessageFilter("open")}
          className={`w-full ${
            selectedMessageFilter === "open"
              ? "bg-[#3361FF] text-white"
              : "bg-white hover:bg-gray-100"
          } px-3 py-2 flex gap-2 items-center justify-center rounded-lg relative cursor-pointer font-semibold `}
        >
          <p
            style={{ top: "50%", transform: "translateY(-50%)", height: "70%" }}
            className={`absolute w-1 ${
              selectedMessageFilter === "open" ? "bg-white" : "bg-blue-600"
            } left-1 top-1 `}
          ></p>
          <p className="text-normal text-10">Accepted suggestions</p>
          <span className="w-6 h-6 rounded-full border-2 border-gray-200  flex-shrink-0 flex justify-center items-center">
            <p className=" text-10 ">10</p>
          </span>
        </div>
        <div
          onClick={() => setSelectedMessageFilter("closed")}
          className={`w-full ${
            selectedMessageFilter === "closed"
              ? "bg-[#3361FF] text-white"
              : "bg-white hover:bg-gray-100"
          } px-3 py-2 flex gap-2 items-center justify-center rounded-lg relative cursor-pointer font-semibold `}
        >
          <p
            style={{ top: "50%", transform: "translateY(-50%)", height: "70%" }}
            className={`absolute w-1 ${
              selectedMessageFilter === "closed" ? "bg-white" : "bg-red-600"
            } left-1 top-1 `}
          ></p>
          <p className="text-normal text-10">Declined suggestions</p>
          <span className="w-6 h-6 rounded-full border-2 border-gray-200  flex-shrink-0 flex justify-center items-center">
            <p className=" text-10 ">10</p>
          </span>
        </div>
        <div
          onClick={() => setSelectedMessageFilter("pending")}
          className={`w-full ${
            selectedMessageFilter === "pending"
              ? "bg-[#3361FF] text-white"
              : "bg-white hover:bg-gray-100"
          } px-3 py-2 flex gap-2 items-center justify-center rounded-lg relative cursor-pointer font-semibold `}
        >
          <p
            style={{ top: "50%", transform: "translateY(-50%)", height: "70%" }}
            className={`absolute w-1 ${
              selectedMessageFilter === "pending" ? "bg-white" : "bg-yellow-400"
            } left-1 top-1 `}
          ></p>
          <p className="text-normal text-10">Pending suggestions</p>
          <span className="w-6 h-6 rounded-full border-2 border-gray-200  flex-shrink-0 flex justify-center items-center">
            <p className=" text-10 ">10</p>
          </span>
        </div>
      </div>
      <div className="px-5 pb-20 h-full">
        <LinePagination data={users} setCurrentItems={setAllDatas}>
          <table className="relative w-full divide-y divide-gray-1 overflow-auto  bg-white">
            <thead className=" rounded-lg rounded-t border px-5">
              <tr className="rounded-t-lg">
                <th
                  scope="col"
                  className={`px-2 py-4 pl-5 text-left text-xs font-medium uppercase`}
                >
                  <div className="flex gap-2 items-center">USER</div>
                </th>
                <th
                  scope="col"
                  className={`px-2 py-4 pl-5 text-left text-xs font-medium uppercase`}
                >
                  <div className="flex gap-2 items-center">SUGGESTION</div>
                </th>
                <th
                  scope="col"
                  className={`px-2 py-4 pl-5  text-left text-xs font-medium uppercase`}
                >
                  <div className="flex gap-2 items-center">STATUS</div>
                </th>
                <th
                  scope="col"
                  className={`px-2 py-4 pl-5  text-left text-xs font-medium uppercase`}
                >
                  <div className="flex gap-2 items-center">DATE SUGGESTED</div>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-300 overflow-auto bg-white px-5 pt-5">
              {allDatas?.map?.((item: any, index: number) => (
                <tr className="hover:bg-gray-100" key={index}>
                  <td className="px-2 pl-5 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                    <div className="flex gap-5 items-center">
                      <div className="w-8 h-8 rounded-full">
                        <img
                          src={Person}
                          alt="user"
                          className="w-full h-full object-cover rounded-full"
                        />
                      </div>
                      <div>
                        <h2 className="text-sm font-semibold">
                          {item?.main_user}
                        </h2>
                        <p className="text-xs">{item?.main_user_occupation}</p>
                      </div>
                    </div>
                  </td>
                  <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                    <div className="flex gap-5 items-center">
                      <div className="w-8 h-8 rounded-full">
                        <img
                          src={Person}
                          alt="user"
                          className="w-full h-full object-cover rounded-full"
                        />
                      </div>
                      <div>
                        <h2 className="text-sm font-semibold">
                          {item?.suggested_user}
                        </h2>
                        <p className="text-xs">{item?.suggested_occupation}</p>
                      </div>
                    </div>
                  </td>

                  <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                    <p
                      className={`px-4 py-1 w-max rounded-full ${
                        item?.status?.toLowerCase?.() === "inactive"
                          ? "bg-[#545f7d3b] text-black"
                          : item?.status?.toLowerCase?.() === "pending"
                          ? "bg-[#e9b30042] text-[#E9B200]"
                          : item?.status?.toLowerCase?.() === "accepted"
                          ? "bg-[#39cd633f] text-[#39CD62] "
                          : "bg-[#e4033b41] text-[#E4033B]"
                      } text-center`}
                    >
                      {item?.status}
                    </p>
                  </td>
                  <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                    {moment(item?.date_suggested).format("MMM DD, YYYY")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </LinePagination>
      </div>
    </div>
  );
}

import { useLocation } from "react-router-dom";

export default function FooterComponent() {
  const location = useLocation();
  return (
    <div className="border-t-2 border-gray-300 w-full pt-3 flex justify-end">
      <p
        className={`${location?.pathname === "/" ? "text-white" : ""} text-xs`}
      >
        © 2023 Nirvanae. All Rights Reserved
      </p>
    </div>
  );
}

import { styled } from "@mui/system";
import theme from "src/assets/colors/theme";
import { Box } from "@mui/material";

export const Aside = styled("aside")({
  display: "grid",
  gridRow: "span 3/span 3",
  overflowY: "scroll",
  // height: 'calc(100% - 8.5rem)',
  width: "21em",
  background: theme.WHITE,
  borderRadius: "4px",
  padding: "16px",
});

export const AsideWrapper = styled("section")({
  display: "flex",
  flexDirection: "column",
  flex: 1,

  "& .location-container": {
    flex: 1,
    width: "300px",
  },
});

export const AvatarContainer = styled(Box)({
  display: "grid",
  placeItems: "center",
});

import { Avatar, Box } from "@mui/material";
import { FC, Fragment, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { EnhancedTable, Tabs, Toast } from "src/components";
import { safeString } from "src/helpers";
import {
  MainContainer,
  RightToolBarWrapper,
  TopContainer,
} from "src/pages/styles";
import InfoLocation from "../InfoUser/InfoUser";
import { Aside, AsideWrapper, AvatarContainer } from "./Sidebar.styles";

import { useMutation, useQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useCallback, useMemo } from "react";
import { Outlet } from "react-router";
import ButtonComp from "src/components/Button/Button";
import {
  GET_USERS,
  GET_USER,
  SEND_EMAIL,
  SEND_USER_MATCH,
} from "src/hooks/data/queries";
import { RichEditor } from "../RichEditor/RichEditor";
import SearchDropDown from "../SearchDropDown/SearchDropDown";
import { useCookies } from "react-cookie";
import { format } from "date-fns";

interface Props {}

const Sidebar: FC<Props> = () => {
  const location = useLocation();
  const [cookies] = useCookies(["user_authenticated"]);
  if (cookies.user_authenticated !== "true") {
    window.location.href = "/admin";
  }
  // @ts-ignore
  const user: any = location?.state || "";
  const { data: user_data, refetch: refetch_user } = useQuery(GET_USER, {
    variables: {
      userId: Number(user?.id),
    },
    fetchPolicy: "cache-and-network",
  });
  const name: any = user_data?.user?.last_name;
  const full_name: any = `${user_data?.user?.last_name} ${user_data?.user?.first_name}`;
  const logo: any = user_data?.user?.media?.[0]?.url;
  const titleRef = useRef<HTMLInputElement | null>(null);
  const [titleDivHeight, setTitleDivHeight] = useState<any>(0);
  const [show_notification, setShowNotification] = useState<boolean>(false);
  const [notification_msg, setNotificationMsg] = useState<string>("");

  //
  const { data } = useQuery(GET_USERS, {
    fetchPolicy: "network-only",
  });
  const UsersList: any[] =
    data?.users?.map((e: any) => ({ ...e, plan: e.subscription.name })) || [];

  const [tab, setTab] = useState("matches");
  const [message_body, setMessageBody] = useState("");
  const [match_modal, setMatchModal] = useState(false);
  const RenderCell = useCallback((params: GridRenderCellParams) => {
    return <p>{params.row[params.field]}</p>;
  }, []);

  const RenderStatusCell = useCallback((params: GridRenderCellParams) => {
    if (params.row[params.field] === true) {
      return <p>Accepted</p>;
    }
    if (params.row[params.field] === false) {
      return <p>Rejected</p>;
    }

    return <p>No Response</p>;
  }, []);

  const RenderDateCell = useCallback((params: GridRenderCellParams) => {
    return <p>{params.row[params.field]}</p>;
  }, []);

  const UserMatches =
    user_data?.user?.user_match?.map((e) => ({
      ...e,
      match_name: `${e?.user_matched?.first_name} ${e?.user_matched.last_name}`,
      created_at: format(new Date(e?.created_at), "PPP"),
    })) || [];

  const UserFriendMatches =
    user_data?.user?.user_friend_match?.map((e) => ({
      ...e,
      match_name: `${e?.user_friend_matched?.first_name} ${e?.user_friend_matched?.last_name}`,
      created_at: format(new Date(e?.created_at), "PPP"),
    })) || [];

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "match_name",
        headerName: "Match Name ",
        flex: 1,
        renderCell: RenderCell,
      },
      {
        field: "created_at",
        headerName: "Match Date",
        flex: 1,
        renderCell: RenderDateCell,
      },
      {
        field: "user_match_status",
        headerName: "User Response",
        flex: 1,
        renderCell: RenderStatusCell,
      },
      {
        field: "user_matched_status",
        headerName: "Match Response",
        flex: 1,
        renderCell: RenderStatusCell,
      },
    ],
    [RenderCell, RenderDateCell, RenderStatusCell]
  );

  const firend_columns: GridColDef[] = useMemo(
    () => [
      {
        field: "match_name",
        headerName: "Match Name",
        flex: 1,
        renderCell: RenderCell,
      },
      {
        field: "created_at",
        headerName: "Match Date",
        flex: 1,
        renderCell: RenderDateCell,
      },
      {
        field: "user_friend_match_status",
        headerName: "User Response",
        flex: 1,
        renderCell: RenderStatusCell,
      },
      {
        field: "user_friend_matched_status",
        headerName: "Match Response",
        flex: 1,
        renderCell: RenderStatusCell,
      },
    ],
    [RenderCell, RenderDateCell, RenderStatusCell]
  );

  const [sendMessageHandler] = useMutation(SEND_EMAIL);

  const [sendUserMatch] = useMutation(SEND_USER_MATCH);

  const sendMessage = useCallback(
    (body: any) => {
      sendMessageHandler({
        variables: {
          body,
          email: user_data?.user?.email,
          name: full_name,
        },
        onCompleted: (data) => {
          if (data.sendEmail) {
            setMessageBody(body);
            setMessageBody("");
            setTab("matches");
            setShowNotification(false);
            setShowNotification(true);
            setNotificationMsg("Message sent successfully");
          }
        },
      });
      // onClose()
    },
    [full_name, sendMessageHandler, user_data?.user?.email]
  );

  const createUserMatch = ({ user_id, target_user_id }) => {
    sendUserMatch({
      variables: {
        payload: {
          target_user_id,
          user_id,
        },
      },
      onCompleted: (data) => {
        if (data?.createUserMatch) {
          setMatchModal(false);
          refetch_user();
          setTab("matches");
          setShowNotification(false);
          setShowNotification(true);
          setNotificationMsg("Match sent successfully");
        }
        // window.location.href = "/admin";
      },
    });
  };
  return (
    <>
      <Aside>
        <AsideWrapper>
          <AvatarContainer>
            <Avatar
              alt={safeString(name)}
              src={logo}
              sx={{ width: 100, height: 100 }}
            />
            <Box>{full_name.toUpperCase()}</Box>
          </AvatarContainer>

          <Box className="location-container">
            <InfoLocation
              user_payload={user}
              showHeader={false}
              showChangeProperty={false}
            />
          </Box>
        </AsideWrapper>
      </Aside>
      <div>
        {" "}
        <TopContainer>
          <Tabs
            labels={["Matches", "Friend Matches", "Send Message"]}
            handleTabClick={(e) => {
              setTab(e);
            }}
            activeTab={"Matches"}
          />
        </TopContainer>
        <MainContainer>
          {false ? (
            "Loading tab content..."
          ) : (
            <>
              {tab === "matches" ? (
                <EnhancedTable
                  columns={columns}
                  rows={UserMatches}
                  rightToolBar={
                    <RightToolBarWrapper>
                      <ButtonComp
                        onClick={() => {
                          setMatchModal(true);
                        }}
                      >
                        New Match
                      </ButtonComp>
                    </RightToolBarWrapper>
                  }
                  pageSize={15}
                  loading={false}
                />
              ) : (
                <></>
              )}
              {tab === "friend_matches" ? (
                <EnhancedTable
                  columns={firend_columns}
                  rows={UserFriendMatches}
                  pageSize={15}
                  loading={false}
                />
              ) : (
                <></>
              )}

              {tab === "send_message" && (
                <div>
                  <RichEditor
                    width="small"
                    variant
                    top
                    body={message_body}
                    placeholder="Write a message..."
                    setFinishedText={sendMessage}
                    handleRichTextModalClose={() => {}}
                    customStyle={{
                      marginTop: "0px",
                      height: "8em",
                      width: "95%",
                    }}
                    allow_Attachment={false}
                    variablesOption={[]}
                  />
                </div>
              )}
            </>
          )}
          <Outlet />
        </MainContainer>
      </div>

      {match_modal ? (
        <Transition.Root show={match_modal} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed inset-0 z-50"
            open={match_modal}
            onClose={() => {
              setMatchModal(false);
            }}
          >
            <div className="flex items-end justify-center min-h-screen text-center sm:block">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                afterEnter={() => {
                  const refing = titleRef?.current?.clientHeight;
                  setTitleDivHeight(refing);
                }}
                // afterLeave={()=>{}}
              >
                <div
                  style={{ background: "#00000094" }}
                  className="h-screen w-full inline-block text-left transform transition-all align-middle"
                >
                  <div
                    className="mx-auto relative"
                    style={{ maxWidth: "1920px" }}
                  >
                    <div className="absolute top-0 right-0 pr-4 sm:pr-6 pt-4 sm:pt-5">
                      <button
                        type="button"
                        className="text-gray-500 hover:text-gray-800 focus:outline-none focus:ring-0"
                        onClick={() => setMatchModal(false)}
                      >
                        <span className="sr-only">Close</span>
                        <i className="fa-regular hidden fa-circle-xmark"></i>
                      </button>
                    </div>

                    <div
                      ref={titleRef}
                      className="px-4 sm:px-6 py-4 sm:py-5"
                    ></div>
                    {/* <Dialog.Title
                      as="h3"
                      className="text-4xl canela font-bold text-center font-medium leading-6 text-brand-1"
                    >
                      {"Profile Saved"}!
                    </Dialog.Title> */}
                    <div
                      className="px-4 sm:px-6 pt-1 sm:pt-2 pb-5"
                      style={{
                        height: `calc(100vh - ${titleDivHeight}px)`,
                        overflowY: "auto",
                      }}
                    >
                      <div className="w-full h-full flex justify-center items-center">
                        <Dialog.Panel className="w-full space-y-5 max-w-md -mt-32 transform relative overflow-hidden rounded-3xl bg-white px-6 py-16 flex flex-col items-center align-middle shadow-xl transition-all">
                          <Dialog.Title
                            as="h3"
                            className="text-2xl canela font-bold text-center font-medium leading-6 text-brand-1"
                          >
                            {"Match Target"}?
                          </Dialog.Title>
                          <SearchDropDown
                            list={UsersList}
                            cuurent_user_id={user_data?.user?.id}
                            handleSelected={(e) => {
                              createUserMatch({
                                user_id: Number(user.id),
                                target_user_id: e,
                              });
                            }}
                          />
                        </Dialog.Panel>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      ) : null}
      <Toast
        show={show_notification}
        msg={notification_msg}
        severity="success"
      />
    </>
  );
};
export default Sidebar;

import Notif from "src/assets/notif.svg";






export const NotificationsObj = <any>[
    {
        id:1,
        name: 'Gem',
        text: 'This is to inform you about an important change regarding our Reseller Portal product. After careful consideration, we have made the difficult decision to discontinue the ....',
        time: '18min',
        img: Notif,
        title: 'Sub Ressler Portal Discontinuation',
        isHandler: true
    },
    {
        id:2,
        name: 'Emeka',
        text: 'This is to inform you about an important change regarding our Reseller Portal product. After careful consideration, we have made the difficult decision to discontinue the ....',
        time: '18min',
        img: Notif,
        title: 'Sub Ressler Portal Discontinuation',
        isHandler: true
    },
    {
        id:2,
        name: 'Mary Osaka',
        text: 'This is to inform you about an important change regarding our Reseller Portal product. After careful consideration, we have made the difficult decision to discontinue the ....',
        time: '18min',
        img: Notif,
        title: 'Sub Ressler Portal Discontinuation',
        isHandler: false
    },
    {
        id:3,
        name: 'Mary Osaka',
        text: 'This is to inform you about an important change regarding our Reseller Portal product. After careful consideration, we have made the difficult decision to discontinue the ....',
        time: '18min',
        img: Notif,
        title: 'Sub Ressler Portal Discontinuation',
        isHandler: false
    },
    {
        id:4,
        name: 'Mary Osaka',
        text: 'This is to inform you about an important change regarding our Reseller Portal product. After careful consideration, we have made the difficult decision to discontinue the ....',
        time: '18min',
        img: Notif,
        title: 'Sub Ressler Portal Discontinuation',
        isHandler: false
    },
    {
        id:5,
        name: 'Mary Osaka',
        text: 'This is to inform you about an important change regarding our Reseller Portal product. After careful consideration, we have made the difficult decision to discontinue the ....',
        time: '18min',
        img: Notif,
        title: 'Sub Ressler Portal Discontinuation',
        isHandler: false
    },
    {
        id:6,
        name: 'Mary Osaka',
        text: 'This is to inform you about an important change regarding our Reseller Portal product. After careful consideration, we have made the difficult decision to discontinue the ....',
        time: '18min',
        img: Notif,
        title: 'Sub Ressler Portal Discontinuation',
        isHandler: false
    },
    {
        id:7,
        name: 'Mary Osaka',
        text: 'This is to inform you about an important change regarding our Reseller Portal product. After careful consideration, we have made the difficult decision to discontinue the ....',
        time: '18min',
        img: Notif,
        title: 'Sub Ressler Portal Discontinuation',
        isHandler: false
    },
    {
        id:8,
        name: 'Mary Osaka',
        text: 'This is to inform you about an important change regarding our Reseller Portal product. After careful consideration, we have made the difficult decision to discontinue the ....',
        time: '18min',
        img: Notif,
        title: 'Sub Ressler Portal Discontinuation',
        isHandler: true
    },
    {
        id:9,
        name: 'Mary Osaka',
        text: 'This is to inform you about an important change regarding our Reseller Portal product. After careful consideration, we have made the difficult decision to discontinue the ....',
        time: '18min',
        img: Notif,
        title: 'Sub Ressler Portal Discontinuation',
        isHandler: false
    },

    {
        id:10,
        name: 'Mary Osaka',
        text: 'This is to inform you about an important change regarding our Reseller Portal product. After careful consideration, we have made the difficult decision to discontinue the ....',
        time: '18min',
        img: Notif,
        title: 'Sub Ressler Portal Discontinuation',
        isHandler: false
    },
    {
        id:11,
        name: 'Mary Osaka',
        text: 'This is to inform you about an important change regarding our Reseller Portal product. After careful consideration, we have made the difficult decision to discontinue the ....',
        time: '18min',
        img: Notif,
        title: 'Sub Ressler Portal Discontinuation',
        isHandler: false
    },
    {
        id:12,
        name: 'Mary Osaka',
        text: 'This is to inform you about an important change regarding our Reseller Portal product. After careful consideration, we have made the difficult decision to discontinue the ....',
        time: '18min',
        img: Notif,
        title: 'Sub Ressler Portal Discontinuation',
        isHandler: false
    },
    {
        id:13,
        name: 'Mary Osaka',
        text: 'This is to inform you about an important change regarding our Reseller Portal product. After careful consideration, we have made the difficult decision to discontinue the ....',
        time: '18min',
        img: Notif,
        title: 'Sub Ressler Portal Discontinuation',
        isHandler: false
    },
    {
        id:14,
        name: 'Mary Osaka',
        text: 'This is to inform you about an important change regarding our Reseller Portal product. After careful consideration, we have made the difficult decision to discontinue the ....',
        time: '18min',
        img: Notif,
        title: 'Sub Ressler Portal Discontinuation',
        isHandler: false
    },
    {
        id:15,
        name: 'Mary Osaka',
        text: 'This is to inform you about an important change regarding our Reseller Portal product. After careful consideration, we have made the difficult decision to discontinue the ....',
        time: '18min',
        img: Notif,
        title: 'Sub Ressler Portal Discontinuation',
        isHandler: false
    },
    {
        id:16,
        name: 'Mary Osaka',
        text: 'This is to inform you about an important change regarding our Reseller Portal product. After careful consideration, we have made the difficult decision to discontinue the ....',
        time: '18min',
        img: Notif,
        title: 'Sub Ressler Portal Discontinuation',
        isHandler: false
    },
]
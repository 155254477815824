import { useMutation } from "@apollo/client";
import React, { useEffect } from "react";
import { IdleTimerProvider, useIdleTimer } from "react-idle-timer";
import { TOGGLE_IS_ONLINE } from "./data/mutation";
import { useUser } from "./hooks/UserProvider";

const IdleProvider = ({ children }) => {
  const [toggleStatus] = useMutation(TOGGLE_IS_ONLINE);
  const { user } = useUser();

  const updateUserStatus = (value: Boolean) => {
    toggleStatus({
      variables: {
        userId: Number(user?.id),
        isOnline: value,
        isAvailable: value,
      },
      onCompleted: (data) => {
        console.log(data);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };
  const handleOnIdle = () => {
    updateUserStatus(false);
  };

  const handleOnActive = () => {
    updateUserStatus(true);
  };

  const handleBeforeUnload = () => {
    updateUserStatus(false);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <IdleTimerProvider
      timeout={1000 * 60 * 5}
      onIdle={handleOnIdle}
      onActive={handleOnActive}
    >
      {children}
    </IdleTimerProvider>
  );
};

export default IdleProvider;

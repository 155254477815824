import Snackbar from "@mui/material/Snackbar";
import { Alert, AlertProps } from "@mui/material";
import { FC, useState } from "react";

interface Props extends AlertProps {
  show: boolean;
  msg: string;
  transitionComp?;
}

const Toast: FC<Props> = ({ show, msg, transitionComp, ...rest }) => {
  const [open, setOpen] = useState(show);
  return (
    <Snackbar
      open={open}
      autoHideDuration={transitionComp ? null : 3500}
      TransitionComponent={transitionComp}
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{ zIndex: 1 }}
    >
      <Alert {...rest}>{msg}</Alert>
    </Snackbar>
  );
};

export default Toast;

import { useState } from "react";

export default function ToolTip(props){
    let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 100);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="relative w-max"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {props.children}
      {active && (
        <div style={{background: '#253D5B', left: '50%', transform: 'translateX(-50%)'}} className={`absolute text-white shadow-md bottom-full font-medium text-10 p-2 w-60 rounded-lg`}>
          {props.content}
        </div>
      )}
    </div>
  );
}
import React from "react";

interface AdminModalProps {
  visible?: boolean;
  close?: () => void;
  children?: React.ReactNode;
  header?: string;
  headerText?: string;
  containerStyle?: string;
  childrenStyle?: string;
}

const AdminModal: React.FC<AdminModalProps> = ({
  visible = false,
  close,
  children,
  header,
  headerText,
  containerStyle,
  childrenStyle,
}) => {
  return (
    <>
      {visible && (
        <div className="fixed inset-0  z-50 overflow-auto bg-black bg-opacity-50 flex justify-center ">
          <div
            className={
              containerStyle ||
              `w-[40%] h-[700px] max-lg:h-[850px] mt-20 bg-white relative max-lg:w-[95%] rounded-md flex flex-col`
            }
          >
            <div
              className={
                header ||
                `bg-[#081A51] w-full py-3 flex px-6 justify-between items-center`
              }
            >
              <span className={`text-sm text-white font-semibold ml-2`}>
                {headerText}
              </span>
              <span
                className="text-white font-semibold text0-sm cursor-pointer"
                onClick={close}
              >
                X
              </span>
            </div>
            <div className={childrenStyle}>{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminModal;

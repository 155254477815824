import React, { useState } from 'react';
import WelcomePage from 'src/components/UserBlindDateComp/WelcomePage';
import Datefeature from 'src/components/UserBlindDateComp/Datefeature';
import { useUser } from 'src/components/auth/hooks/UserProvider';
import { useMutation, useQuery } from '@apollo/client';
import { CHECK_FIRST_TIMER } from 'src/components/auth/data/queries';
import { UPDATE_USER } from 'src/components/auth/data/mutation';
import Loading from "src/components/Loading";

function UserBlindDate() {
    const [displayPage, setDisplayPage] = useState(false);
const[isLoading,setIsLoading] = useState(false)
    // Hook calls should be inside the component function
    const { user } = useUser();
    const { data, loading, refetch } = useQuery(CHECK_FIRST_TIMER, {
        variables: {
            "checkFirstTimerId": Number(user?.id)
        }
    });
    const [sendBlindDateActions] = useMutation(UPDATE_USER);

    const handleAccept = () => {
        setIsLoading(true)
        sendBlindDateActions({
            variables: {
                "updateUserId": Number(user?.id),
                "payload": {
                    "blind_date_first_timer": false
                }
            },
            onCompleted: (data) => {
                refetch();
                console.log("done")
                 setIsLoading(false)
            },
            onError: (error) => {
                // Handle the error here
                console.error(error);
            }
        });
    };
// console.log("see am here ",user?.blind_date_first_timer)
    return (
        <div>
            {loading ? <Loading /> : user?.blind_date_first_timer && !loading ? <WelcomePage handleAccept={handleAccept} isLoading={isLoading} /> : <Datefeature />}
        </div>
    );
}

export default UserBlindDate;

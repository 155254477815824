import FooterComponent from "../components/footer/Index";
import HomeHeader from "../components/header/Homeheader";

export default function FriendPage() {
  return (
    <div className="container px-5 pt-10 pb-5 mx-auto">
      <HomeHeader />
      <div className="absolute flex items-center sm:items-end sm:pb-10 top-0 left-0 bgfriend">
        <div className="container px-5 mx-auto">
          <h2 className="text-3xl sm:text-6xl mt-10 sm:mt-0 text-center sm:text-left font-bold text-white">
            Make a <br /> Friend
          </h2>
        </div>
      </div>
      <div className="flex flex-col pt-[250px] justify-between gap-10">
        <div className="w-full">
          <h2 className="text-base">
            This feature helps you find people with similar interests and helps
            you build your social network
          </h2>
          <form className="pb-10">
            <div className="flex flex-col sm:flex-row gap-10 items-center">
              <div className="w-full sm:w-1/2 flex-wrap gap-10 justify-between py-10 flex">
                <div className="w-full">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Full Name"
                    autoComplete="off"
                    className="appearance-none block w-full px-3 py-2.5 border-b border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-1 focus:border-blue-1 sm:text-lg"
                  />
                </div>
                <div className="w-full">
                  <input
                    id="occupation"
                    name="occupation"
                    type="text"
                    placeholder="Occupation"
                    autoComplete="off"
                    className="appearance-none block w-full px-3 py-2.5 border-b border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-1 focus:border-blue-1 sm:text-lg"
                  />
                </div>
                <div className="w-full">
                  <input
                    id="location"
                    name="location"
                    type="text"
                    placeholder="Location"
                    autoComplete="off"
                    className="appearance-none block w-full px-3 py-2.5 border-b border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-1 focus:border-blue-1 sm:text-lg"
                  />
                </div>
                <div className="w-full">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    autoComplete="off"
                    className="appearance-none block w-full px-3 py-2.5 border-b border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-1 focus:border-blue-1 sm:text-lg"
                  />
                </div>
                <div className="w-full">
                  <input
                    id="code"
                    name="code"
                    type="text"
                    placeholder="Personality Type"
                    autoComplete="off"
                    className="appearance-none block w-full px-3 py-2.5 border-b border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-1 focus:border-blue-1 sm:text-lg"
                  />
                </div>
              </div>
              <div className="w-full sm:w-1/2 flex flex-col">
                <textarea
                  placeholder="About yourself and friends you w"
                  className="appearance-none h-40 block w-full px-3 py-2.5 border border-gray-300 shadow-sm placeholder-gray-400 resize-none focus:outline-none focus:ring-blue-1 focus:border-blue-1 sm:text-lg"
                ></textarea>
                <button
                  type="submit"
                  className="bg-transparent mt-10 w-full sm:w-[40%] lg:w-[20%] text-center self-end py-3 text-sm text-gray-500 font-bold hover:bg-blue-300 transition ease-in-out duration-300 hover:text-white  outline-0 border border-blue-200 uppercase"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
        <FooterComponent />
      </div>
    </div>
  );
}

import { useMutation, useQuery } from "@apollo/client";
import {
  AssignmentInd,
  CreditCard,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardBackspace,
} from "@mui/icons-material";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BuyPlan from "src/assets/buy-plan.svg";
import Mark from "src/assets/mark.svg";
import PayMark from "src/assets/payment-mark.svg";
import Cancel from "src/assets/cancel.svg";
import {
  PAYMENT_SUCCESS,
  UPDATE_USER,
} from "src/components/auth/data/mutation";
import { useUser } from "src/components/auth/hooks/UserProvider";
import PaystackPortal from "src/components/paystack/PaystackPortal";
import { calculateTimeLefttillNextPayment } from "src/helpers/dateTime";
import { CANCEL_PAYSTACK_SUB } from "src/hooks/data/mutations";
import { PAYSTACK_SUB_FILTER } from "src/hooks/data/queries";
import { Menu, Transition } from "@headlessui/react";
import ResponsiveOverlay from "src/overlays/ResponsiveOverlay";
import { BeatLoader } from "react-spinners";
import PageLoader from "src/components/PageLoader";
import moment from "moment";
import Loading from "src/components/Loading";
import { useCurrency } from "src/context/AmountProvider";
import { GENERICPLANSNG, GENERICPLANSUSD } from "src/helpers/constants";
import SingleButton from "src/components/SingleButton";
import Slider from "react-slick";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  waitForAnimate: false,
  cssEase: "linear",
  swipeToSlide: true,
  pauseOnHover: true,

  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const RevisedAuthPlan = () => {
  const { user, userRefetch } = useUser();
  const [paymentSuccess] = useMutation(PAYMENT_SUCCESS);
  const { country } = useCurrency();
  const Delete =
    "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/delete.svg";

  const {
    data: paystackFilter,
    error,
    loading,
    refetch,
  } = useQuery(PAYSTACK_SUB_FILTER, {
    variables: {
      // active: true,
      userId: Number(user?.id),
    },
    fetchPolicy: "no-cache",
  });

  const [cancelPaystack] = useMutation(CANCEL_PAYSTACK_SUB);
  const [updateUser] = useMutation(UPDATE_USER);
  const [currency, setCurrency] = useState(
    country?.trim?.() === "nigeria" ? "₦" : "$"
  );
  const [amount, setAmount] = useState(
    country?.trim?.() === "nigeria" ? "200000" : "200"
  );
  const [discount, setDiscount] = useState("100000");
  const [singlePlan, setSinglePlan] = useState("annually");
  const [successfullPayment, setSuccessfullPayment] = useState(false);
  const [step, setStep] = useState(1);
  const [user_reference, setUserReference] = useState<string>(
    `REFSUB${Date.now().toString()}`
  );
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  const handleChoosePlan = (passedplan) => {
    setSinglePlan(passedplan?.plan);
    setAmount(passedplan?.amount);
    setUserReference(`REFSUB${Date.now().toString()}`);
  };
  const handleDiscountChange = (p) => {
    setDiscount(p.discPrice);
    setSinglePlan(p.duration);
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const mainPaystackObject = useMemo(() => {
    const filteredPaystack = paystackFilter?.["paystackSubsWhere"]?.filter?.(
      (i) => i?.active
    );
    return filteredPaystack?.[filteredPaystack?.length - 1];
  }, [paystackFilter]);

  const transformPrice = (price) => {
    if (price === "100000") {
      return "100,000";
    }
    if (price === "45000") {
      return "45,000";
    }
    if (price === "60000") {
      return "60,000";
    }
    if (price === "19500") {
      return "19,500";
    }
  };

  function calculateExpirationDate(
    paymentDate: any,
    period: any,
    ad: any = ""
  ) {
    if (!paymentDate) {
      return "";
    }

    const date = new Date(paymentDate);
    let expirationDate;

    switch (period.toLowerCase()) {
      case "annually":
        expirationDate = new Date(date.setFullYear(date.getFullYear() + 1));
        break;
      case "annually-usd":
        expirationDate = new Date(date.setFullYear(date.getFullYear() + 1));
        break;
      case "yearly":
        expirationDate = new Date(date.setFullYear(date.getFullYear() + 1));
        break;

      case "monthly":
        expirationDate = new Date(date.setMonth(date.getMonth() + 1));
        break;
      case "monthly-usd":
        expirationDate = new Date(date.setMonth(date.getMonth() + 1));
        break;

      case "bi-annually":
        expirationDate = new Date(date.setMonth(date.getMonth() + 6));
        break;
      case "bi-annually-usd":
        expirationDate = new Date(date.setMonth(date.getMonth() + 6));
        break;

      case "quarterly":
        expirationDate = new Date(date.setMonth(date.getMonth() + 3));
        break;
      case "quarterly-usd":
        expirationDate = new Date(date.setMonth(date.getMonth() + 3));
        break;

      default:
        return (expirationDate = new Date());
    }
    const now = new Date();
    if (ad) {
      return expirationDate?.toISOString?.();
    }
    if (expirationDate < now) {
      return "expired";
    }

    if (expirationDate !== "Invalid Date") {
      return expirationDate?.toISOString?.();
    }
    return `Network Error`;
  }

  const cancelSubscription = () => {
    const py_token = mainPaystackObject?.token;
    const py_sub_id = mainPaystackObject?.id;
    const py_subscription_code = mainPaystackObject?.subscription_code;
    const py_subscription_id = mainPaystackObject?.paystack_subscription_id;
    setIsLoading(true);
    cancelPaystack({
      variables: {
        payload: {
          token: py_token,
          sub_id: Number(py_sub_id),
          subscription_id: py_subscription_id,
          subscription_code: py_subscription_code,
        },
      },
      onCompleted: (data) => {
        setIsLoading(false);
        if (data?.cancelPaystack === false) {
          toast.error("Unale to cancel, kindly contact support!");
          return;
        }
        setOpenConfirm(false);
        toast.success("Successfully Cancelled Subscription");
        navigate("/dashboard/my-profile");
      },
      onError: (errorCancelPaystack) => {
        setIsLoading(false);
        toast.error(errorCancelPaystack?.message ?? "Network error");
      },
    });
  };

  const paymentSuccessHandler = (payload) => {
    const { plan, amount, email, tx_ref, transaction_id } = payload;
    setIsLoadingPayment(true);
    paymentSuccess({
      variables: {
        payload: {
          amount,
          email,
          plan: plan,
          plan_duration: country?.trim?.() === "nigeria" ? plan : plan + "-usd",
          transaction_id,
          tx_ref,
        },
      },
      onCompleted: (data) => {
        setIsLoadingPayment(false);
        setOpenUpgrade(false);
        userRefetch();
        setSuccessfullPayment(true);
      },
      onError: (error) => {
        setIsLoadingPayment(false);
        console.log(error);
      },
    });
  };

  const planFromPayFilter = mainPaystackObject?.plan;
  const mainPaystack = user?.paystackSubscription?.filter?.(
    (i) => i?.plan === planFromPayFilter
  )?.[0];

  function calculateDaysLeft(paymentDateString) {
    const paymentDate = new Date(paymentDateString);
    const currentDate = new Date();

    //@ts-ignore
    const differenceInTime = paymentDate - currentDate;

    const daysLeft = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));

    return daysLeft;
  }

  const mainPlan =
    user?.mode === "paystack"
      ? mainPaystackObject?.plan ?? user?.plan
      : user?.plan;
  const paystackDate = mainPaystackObject?.created_at ?? new Date();
  const majorPlans =
    country?.trim?.() === "nigeria" ? GENERICPLANSNG : GENERICPLANSUSD;

  const preparePlans = (arr: any, plan: any) => {
    let newplan;
    switch (plan?.toLowerCase?.()) {
      case "monthly":
        newplan = [...arr?.filter((i) => i?.plan !== "monthly")];
        break;
      case "monthly-usd":
        newplan = [...arr?.filter((i) => i?.plan !== "monthly")];
        break;
      case "quarterly":
        //@ts-ignore
        newplan = [
          ...arr?.filter(
            (i) => i?.plan !== "monthly" && i?.plan !== "quarterly"
          ),
        ];
        break;
      case "quarterly-usd":
        //@ts-ignore
        newplan = [
          ...arr?.filter(
            (i) => i?.plan !== "monthly" && i?.plan !== "quarterly"
          ),
        ];
        break;
      case "bi-annually":
        //@ts-ignore
        newplan = [
          ...arr?.filter(
            (i) =>
              i?.plan !== "monthly" &&
              i?.plan !== "quarterly" &&
              i?.plan !== "bi-annually"
          ),
        ];
        break;
      case "bi-annually-usd":
        //@ts-ignore
        newplan = [
          ...arr?.filter(
            (i) =>
              i?.plan !== "monthly" &&
              i?.plan !== "quarterly" &&
              i?.plan !== "bi-annually"
          ),
        ];
        break;

      default:
        newplan = [];
    }
    return newplan;
  };

  useEffect(() => {
    setCurrency(country?.trim?.() === "nigeria" ? "₦" : "$");
  }, [country]);

  return (
    <>
      <ResponsiveOverlay
        isOpen={openConfirm}
        setIsOpen={setOpenConfirm}
        title={""}
        size="small"
      >
        <div className="h-full w-full">
          <div className="w-full gap-5 mx-auto p-5 pt-10 bg-white flex flex-col justify-center items-center">
            <>
              <div className="w-20 h-20 flex-shrink-0 rounded-full flex justify-center items-center bg-gray-200">
                <img src={Delete} className="w-10 h-10" alt="unmatch" />
              </div>
              <div className="space-y-2">
                <p className="text-[#081A51] text-lg font-bold text-center">
                  You are about to cancel re-current payment subscription!
                </p>
                <p className="text-[#223333] text-sm text-center">
                  Important: Cancelling will not refund your current
                  subscription period. You'll continue to have access until{" "}
                  <span className="font-semibold">
                    {moment(
                      calculateExpirationDate(
                        user?.mode === "paystack"
                          ? mainPaystack?.created_at ?? paystackDate
                          : user?.paymentDate,
                        user?.mode === "paystack"
                          ? mainPlan
                          : user?.plan?.toLowerCase?.(),
                        "lo"
                      )
                    )?.format?.("Do MMMM YYYY")}
                  </span>
                  , and no further charges will be made <br />
                  Do you wish to continue?
                </p>
              </div>
            </>
          </div>
          <div className="flex p-5 w-full justify-end gap-5 bg-gray-50 items-center">
            {!isLoading && (
              <button
                onClick={() => {
                  setOpenConfirm(false);
                }}
                className="px-4 sm:px-6 py-2 rounded-lg text-center text-xs sm:text-base font-semibold bg-white border border-gray-400 text-gray-700"
              >
                {"Cancel"}
              </button>
            )}
            <button
              disabled={isLoading}
              onClick={() => {
                cancelSubscription();
              }}
              className="px-4 sm:px-6 py-2 rounded-lg text-center text-xs sm:text-base font-semibold bg-[#081A51] text-white"
            >
              {isLoading ? <BeatLoader size={10} color="#fff" /> : "Continue"}
            </button>
          </div>
        </div>
      </ResponsiveOverlay>
      <ResponsiveOverlay
        isOpen={openUpgrade}
        setIsOpen={setOpenUpgrade}
        title={"Upgrade plan"}
        // size="small"
      >
        <div className="">
          <div className="w-full gap-5 space-y-5 mx-auto p-5 pt-0 bg-white ">
            <h2 className="text-sm font-semibold">
              Upgrade to a bigger plan and enjoy more benefits
            </h2>
            <div className="slider-container  lg:hidden pb-10">
              <Slider {...settings}>
                {/* premium mobible */}
                {preparePlans(majorPlans, mainPlan)?.map?.((item: any) => {
                  const majdesc =
                    item?.plan === "annually"
                      ? `The Planner: You know that finding the right person isn’t a quick fix. You’re in this for the long game, and you’re willing to let it play out over time.`
                      : item?.plan === "bi-annually"
                      ? `The Relationship Goal-Setter: You’ve got relationship goals, and now it’s time to put in the time to achieve them. You’re done casually dating, ready for something real, and you're committed to the process`
                      : item?.plan === "quarterly"
                      ? `The Focused Dater: You know what you want, and you’re here to find it, but you’re still open to experimenting with your preferences.`
                      : ``;
                  return (
                    <div key={item?.plan} className="py-3 px-1">
                      <div className="border p-2.5 h-full py-7 space-y-5 border-[#081A51] relative rounded-lg">
                        <p className="text-xs ">{majdesc?.slice?.(0, 250)}</p>
                        <div>
                          <p className="text-xl font-semibold flex items-center justify-center  max-md:text-[11px]">
                            {currency}{" "}
                            {Number(item?.amount)?.toLocaleString?.()}
                          </p>
                          <p className=" text-xs text-center capitalize font-medium">
                            {item?.plan}
                          </p>
                        </div>
                        <PaystackPortal
                          currency={"NGN"}
                          plan_cost={item?.amount}
                          amount={item?.amount}
                          reference={user_reference}
                          email={user?.email}
                          plan={item?.plan}
                          onClose={() => {}}
                          mainCurrency={
                            country?.trim?.() === "nigeria" ? "NGN" : "USD"
                          }
                          onSuccess={() => {
                            setSinglePlan(item?.plan);
                            setAmount(item?.amount);
                            paymentSuccessHandler({
                              plan: item?.plan,
                              amount,
                              email: user?.email,
                              tx_ref: user_reference,
                              transaction_id: user_reference,
                            });
                          }}
                          title={`Upgrade`}
                          last_step_complete={true}
                          currency_code={"NGN"}
                          phone_number={undefined}
                          name={undefined}
                          plan_duration={undefined}
                          className="border border-[#081A51] w-full text-center  py-2 rounded-sm cursor-pointer"
                        />
                        {/* <SingleButton
                      className="border border-[#081A51] mt-10 py-2 rounded-sm cursor-pointer"
                      text="Upgrade"
                      inlineStyle="text-center text-[#081A51]"
                      //@ts-ignore
                      onBtnClick={() => {

                      }}
                    /> */}
                        {item?.plan === "annually" && (
                          <div>
                            <div className="flex mt-10">
                              <img src={Mark} className="h-6 mr-4" alt="mark" />
                              <p className="text-sm max-md:text-[11px]">
                                Full Handler Support: You’re not doing this
                                alone. Our handlers will be your match-making
                                coaches, guiding you toward the most compatible
                                people and helping you refine your approach.
                                They can go extra lengths to find you a
                                compatible match that may not be part of our
                                typical process.
                              </p>
                            </div>
                            <div className="flex mt-10">
                              <img
                                src={Mark}
                                className="h-6 mr-4"
                                alt="cancel"
                              />
                              <p className="text-sm max-md:text-[11px]">
                                Therapists on Deck: Sometimes love requires a
                                little soul-searching. Whether you need to work
                                through past baggage or just want someone to
                                talk to along the way, our therapists are here
                                for you. A plan can be drafted on how to
                                navigate these challenges and when you find THE
                                one, we are happy to give some guidance to you
                                both
                              </p>
                            </div>
                            <div className="flex mt-10">
                              <img
                                src={Mark}
                                className="h-6 mr-4"
                                alt="cancel"
                              />
                              <p className="text-sm max-md:text-[11px]">
                                Handler Suggestions: Get sent personalized
                                matches based on your set preferences. We’re
                                talking tailor-made connections that are aligned
                                with your preferences, personality, and
                                long-term goals. Whether it’s shared interests,
                                life plans, or even values, we’ve got your back
                                in finding the person who checks all your boxes.
                              </p>
                            </div>
                            <div className="flex mt-10">
                              <img
                                src={Mark}
                                className="h-6 mr-4"
                                alt="cancel"
                              />
                              <p className="text-sm max-md:text-[11px]">
                                Discover Matches:There is a whole song about
                                finding love in a hopeless place for a reason.
                                While you're laser-focused on finding "the one,"
                                sometimes love surprises you. With Discover
                                Suggestions, you'll be introduced to profiles
                                that might not meet every preference on your
                                list but still have the potential to spark
                                something special. Think of it as a little nudge
                                from the universe—or us—to keep your heart open
                                to unexpected connections.
                              </p>
                            </div>
                            <div className="flex mt-10">
                              <img
                                src={Mark}
                                className="h-6 mr-4"
                                alt="cancel"
                              />
                              <p className="text-sm max-md:text-[11px]">
                                Blind Dates: Skip the small talk. Busy schedule?
                                No problem. With Blind Dates, we cut through the
                                endless back-and-forth texting so you can jump
                                straight into a real connection. On these
                                6-minute video calls, you'll get to pick up on
                                those subtle verbal cues that just don't come
                                through in chat. It’s fast, efficient, and way
                                more insightful than days of messaging. On this
                                pla, we are in it together to win it.
                              </p>
                            </div>
                            <div className="flex mt-10">
                              <img
                                src={Mark}
                                className="h-6 mr-4"
                                alt="cancel"
                              />
                              <p className="text-sm max-md:text-[11px]">
                                No Stone Unturned: With a full year, you have
                                time to explore every avenue. Blind dates,
                                handler suggestions, personalized matches—you’ll
                                get it all, with plenty of time to see what
                                works best for you.
                              </p>
                            </div>
                          </div>
                        )}
                        {item?.plan === "bi-annually" && (
                          <div>
                            <div className="flex mt-10">
                              <img src={Mark} className="h-6 mr-4" alt="mark" />
                              <p className="text-sm max-md:text-[11px]">
                                Handler Suggestions: Personalized matches based
                                on your set preferences, because you know what
                                you want.
                              </p>
                            </div>
                            <div className="flex mt-10">
                              <img
                                src={Mark}
                                className="h-6 mr-4"
                                alt="cancel"
                              />
                              <p className="text-sm max-md:text-[11px]">
                                Discover Matches:These suggestions aren't
                                handpicked with laser precision to your
                                preferences but, what is the harm in seeing what
                                the general dating pool looks like to help
                                manage your expectations?
                              </p>
                            </div>
                            <div className="flex mt-10">
                              <img
                                src={Mark}
                                className="h-6 mr-4"
                                alt="cancel"
                              />
                              <p className="text-sm max-md:text-[11px]">
                                Blind Dates: You will get set up for blind dates
                                which you must endeavor to attend if you accept
                                the invitation. This is a quick process to
                                showcase the personalities behind the keyboards.
                              </p>
                            </div>
                            <div className="flex mt-10">
                              <img
                                src={Mark}
                                className="h-6 mr-4"
                                alt="cancel"
                              />
                              <p className="text-sm max-md:text-[11px]">
                                Handler Support: Access to your handler to help
                                you on your journey. Whether it is technical
                                support, a quick venting session or just updates
                                on your progress with the processes, your
                                handler is available to answer all enquiries
                                promptly.
                              </p>
                            </div>
                            <div className="flex mt-10">
                              <img
                                src={Cancel}
                                className="h-6 mr-4"
                                alt="cancel"
                              />
                              <p className="text-sm max-md:text-[11px]">
                                No support from our Therapists: Therapists? Not
                                for you—at least, not right now. Either you’ve
                                already done the work, or you’re in a good place
                                and focused on finding the right person. Six
                                months is all about building that connection,
                                not diving deep into healing. And hey, if you
                                ever need it down the line, we’ll be here, but
                                for now—you’ve got this!
                              </p>
                            </div>
                          </div>
                        )}
                        {item?.plan === "quarterly" && (
                          <div>
                            <div className="flex mt-10">
                              <img src={Mark} className="h-6 mr-4" alt="mark" />
                              <p className="text-sm max-md:text-[11px]">
                                Handler Suggestions: Personalized matches based
                                on your set preferences, because you’re here
                                with intent.
                              </p>
                            </div>
                            <div className="flex mt-10">
                              <img
                                src={Mark}
                                className="h-6 mr-4"
                                alt="cancel"
                              />
                              <p className="text-sm max-md:text-[11px]">
                                Discover Matches:These suggestions aren't
                                handpicked with laser precision to your
                                preferences but, you are not fussed because a
                                pleasant surprise is welcome. In fact, that is
                                why you are here.
                              </p>
                            </div>
                            <div className="flex mt-10">
                              <img
                                src={Mark}
                                className="h-6 mr-4"
                                alt="cancel"
                              />
                              <p className="text-sm max-md:text-[11px]">
                                Blind Dates (With a Twist!): Depending on
                                availability, you might get set up for a blind
                                date. But here’s the deal— only if members of
                                our community who are more committed to the
                                process request one with you.
                              </p>
                            </div>
                            <div className="flex mt-10">
                              <img
                                src={Mark}
                                className="h-6 mr-4"
                                alt="cancel"
                              />
                              <p className="text-sm max-md:text-[11px]">
                                Enhanced Support: More access to your handler to
                                help you on your journey.
                              </p>
                            </div>

                            <div className="flex mt-10">
                              <img
                                src={Cancel}
                                className="h-6 mr-4"
                                alt="cancel"
                              />
                              <p className="text-sm max-md:text-[11px]">
                                No support from our Therapists: Therapists? Nah,
                                it is not that deep. You're more about meeting
                                new people than unpacking deep emotional
                                baggage. No need to overthink it—you’re just
                                here to enjoy the ride and see where things go.
                                When you're ready for that deep dive, we’ll be
                                here for you
                              </p>
                            </div>
                          </div>
                        )}

                        {item?.plan === "annually" && (
                          <p className="text-10 px-4 py-1 bg-purple-100 text-purple-600 rounded absolute -top-4 right-1">
                            Recommended
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div
              className={`lg:grid hidden ${
                preparePlans(majorPlans, mainPlan)?.length > 1
                  ? "w-full"
                  : "w-4/5 mx-auto"
              } grid-cols-${preparePlans(majorPlans, mainPlan)?.length} gap-5`}
            >
              {preparePlans(majorPlans, mainPlan)?.map?.((item: any) => {
                const majdesc =
                  item?.plan === "annually"
                    ? `The Planner: You know that finding the right person isn’t a quick fix. You’re in this for the long game, and you’re willing to let it play out over time.`
                    : item?.plan === "bi-annually"
                    ? `The Relationship Goal-Setter: You’ve got relationship goals, and now it’s time to put in the time to achieve them. You’re done casually dating, ready for something real, and you're committed to the process`
                    : item?.plan === "quarterly"
                    ? `The Focused Dater: You know what you want, and you’re here to find it, but you’re still open to experimenting with your preferences.`
                    : ``;
                return (
                  <div
                    key={item?.plan}
                    className="border p-2.5 py-7 space-y-5 border-[#081A51] relative rounded-lg"
                  >
                    <p className="text-xs ">{majdesc?.slice?.(0, 250)}</p>
                    <div>
                      <p className="text-xl font-semibold flex items-center justify-center  max-md:text-[11px]">
                        {currency} {Number(item?.amount)?.toLocaleString?.()}
                      </p>
                      <p className=" text-xs text-center capitalize font-medium">
                        {item?.plan}
                      </p>
                    </div>
                    <PaystackPortal
                      currency={"NGN"}
                      plan_cost={item?.amount}
                      amount={item?.amount}
                      reference={user_reference}
                      email={user?.email}
                      plan={item?.plan}
                      onClose={() => {}}
                      mainCurrency={
                        country?.trim?.() === "nigeria" ? "NGN" : "USD"
                      }
                      onSuccess={() => {
                        setSinglePlan(item?.plan);
                        setAmount(item?.amount);
                        paymentSuccessHandler({
                          plan: item?.plan,
                          amount,
                          email: user?.email,
                          tx_ref: user_reference,
                          transaction_id: user_reference,
                        });
                      }}
                      title={`Upgrade`}
                      last_step_complete={true}
                      currency_code={"NGN"}
                      phone_number={undefined}
                      name={undefined}
                      plan_duration={undefined}
                      className="border border-[#081A51] w-full text-center  py-2 rounded-sm cursor-pointer"
                    />
                    {/* <SingleButton
                      className="border border-[#081A51] mt-10 py-2 rounded-sm cursor-pointer"
                      text="Upgrade"
                      inlineStyle="text-center text-[#081A51]"
                      //@ts-ignore
                      onBtnClick={() => {

                      }}
                    /> */}
                    {item?.plan === "annually" && (
                      <div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="mark" />
                          <p className="text-sm max-md:text-[11px]">
                            Full Handler Support: You’re not doing this alone.
                            Our handlers will be your match-making coaches,
                            guiding you toward the most compatible people and
                            helping you refine your approach. They can go extra
                            lengths to find you a compatible match that may not
                            be part of our typical process.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Therapists on Deck: Sometimes love requires a little
                            soul-searching. Whether you need to work through
                            past baggage or just want someone to talk to along
                            the way, our therapists are here for you. A plan can
                            be drafted on how to navigate these challenges and
                            when you find THE one, we are happy to give some
                            guidance to you both
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Handler Suggestions: Get sent personalized matches
                            based on your set preferences. We’re talking
                            tailor-made connections that are aligned with your
                            preferences, personality, and long-term goals.
                            Whether it’s shared interests, life plans, or even
                            values, we’ve got your back in finding the person
                            who checks all your boxes.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Discover Matches:There is a whole song about finding
                            love in a hopeless place for a reason. While you're
                            laser-focused on finding "the one," sometimes love
                            surprises you. With Discover Suggestions, you'll be
                            introduced to profiles that might not meet every
                            preference on your list but still have the potential
                            to spark something special. Think of it as a little
                            nudge from the universe—or us—to keep your heart
                            open to unexpected connections.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Blind Dates: Skip the small talk. Busy schedule? No
                            problem. With Blind Dates, we cut through the
                            endless back-and-forth texting so you can jump
                            straight into a real connection. On these 6-minute
                            video calls, you'll get to pick up on those subtle
                            verbal cues that just don't come through in chat.
                            It’s fast, efficient, and way more insightful than
                            days of messaging. On this pla, we are in it
                            together to win it.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            No Stone Unturned: With a full year, you have time
                            to explore every avenue. Blind dates, handler
                            suggestions, personalized matches—you’ll get it all,
                            with plenty of time to see what works best for you.
                          </p>
                        </div>
                      </div>
                    )}
                    {item?.plan === "bi-annually" && (
                      <div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="mark" />
                          <p className="text-sm max-md:text-[11px]">
                            Handler Suggestions: Personalized matches based on
                            your set preferences, because you know what you
                            want.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Discover Matches:These suggestions aren't handpicked
                            with laser precision to your preferences but, what
                            is the harm in seeing what the general dating pool
                            looks like to help manage your expectations?
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Blind Dates: You will get set up for blind dates
                            which you must endeavor to attend if you accept the
                            invitation. This is a quick process to showcase the
                            personalities behind the keyboards.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Handler Support: Access to your handler to help you
                            on your journey. Whether it is technical support, a
                            quick venting session or just updates on your
                            progress with the processes, your handler is
                            available to answer all enquiries promptly.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            No support from our Therapists: Therapists? Not for
                            you—at least, not right now. Either you’ve already
                            done the work, or you’re in a good place and focused
                            on finding the right person. Six months is all about
                            building that connection, not diving deep into
                            healing. And hey, if you ever need it down the line,
                            we’ll be here, but for now—you’ve got this!
                          </p>
                        </div>
                      </div>
                    )}
                    {item?.plan === "quarterly" && (
                      <div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="mark" />
                          <p className="text-sm max-md:text-[11px]">
                            Handler Suggestions: Personalized matches based on
                            your set preferences, because you’re here with
                            intent.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Discover Matches:These suggestions aren't handpicked
                            with laser precision to your preferences but, you
                            are not fussed because a pleasant surprise is
                            welcome. In fact, that is why you are here.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Blind Dates (With a Twist!): Depending on
                            availability, you might get set up for a blind date.
                            But here’s the deal— only if members of our
                            community who are more committed to the process
                            request one with you.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Enhanced Support: More access to your handler to
                            help you on your journey.
                          </p>
                        </div>

                        <div className="flex mt-10">
                          <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            No support from our Therapists: Therapists? Nah, it
                            is not that deep. You're more about meeting new
                            people than unpacking deep emotional baggage. No
                            need to overthink it—you’re just here to enjoy the
                            ride and see where things go. When you're ready for
                            that deep dive, we’ll be here for you
                          </p>
                        </div>
                      </div>
                    )}

                    {item?.plan === "annually" && (
                      <p className="text-10 px-4 py-1 bg-purple-100 text-purple-600 rounded absolute -top-4 right-1">
                        Recommended
                      </p>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </ResponsiveOverlay>

      <div className="overflow pt-5 space-y-5">
        {isLoadingPayment && <Loading />}
        {loading ? (
          <PageLoader noMargin />
        ) : successfullPayment ? (
          <div className="w-full bg-white">
            <div className="w-full flex p-5 py-10 gap-5  flex-col justify-center items-center">
              <div className="w-12 lg:w-24 h-12 lg:h-24 rounded-full bg-[#F5F5F5] flex justify-center items-center shadow-md ">
                <img
                  src={PayMark}
                  alt="empty"
                  className="w-8 lg:w-12 h-8 lg:h-12 object-contain"
                />
              </div>
              <p className=" font-semibold text-[#081A51] text-sm sm:text-base lg:text-2xl text-center">
                Payment Successful
              </p>
              <p className="w-full text-xs sm:text-sm md:w-3/4 text-center">
                Your payment of{" "}
                {country?.trim?.() === "nigeria" ? "NGN" : "USD"} {amount} for
                the {singlePlan?.toLocaleLowerCase()} premium subscription has
                been processed successfully. Thanks for trusting us to manage
                your online dating experience. You can can start enjoying the
                premium features of our app
              </p>
              <button
                onClick={() => {
                  setSuccessfullPayment(false);
                  refetch();
                }}
                className="w-full sm:w-max justify-center text-white text-xs font-medium sm:text-sm flex items-center bg-[#081A51] px-4 sm:px-6 py-2.5 rounded-lg"
              >
                Continue
              </button>
            </div>
          </div>
        ) : (
          <>
            {user?.subscription?.name === "basic" ? (
              step === 1 ? (
                <div className="w-full bg-white ">
                  <div className="w-full flex p-5 gap-5 py-10 flex-col justify-center items-center">
                    <div className="w-12 lg:w-24 h-12 lg:h-24 rounded-full bg-[#F5F5F5] flex justify-center items-center shadow-md">
                      <img
                        src={BuyPlan}
                        alt="empty"
                        className="w-8 lg:w-12 h-8 lg:h-12 rounded-full object-cover"
                      />
                    </div>
                    <p className=" font-semibold text-[#081A51] text-sm sm:text-base lg:text-2xl text-center">
                      You are currently on the free plan
                    </p>
                    <p className="w-full text-xs sm:text-sm md:w-3/4 text-center">
                      You have no access to personalized recommendations from
                      Handlers, Limited or No handler support to coach for
                      matches. No match suggestions. You only get requests from
                      paying users if they come across your profile in their
                      pool and want to meet you. Beef up your dating game today,
                      become a premium subscriber
                    </p>
                    <button
                      onClick={() => {
                        handleNextStep();
                      }}
                      className="w-full sm:w-max justify-center text-white text-xs font-medium sm:text-sm flex items-center bg-[#081A51] px-4 sm:px-6 py-2.5 rounded-lg"
                    >
                      Buy Subscription
                    </button>
                  </div>
                </div>
              ) : (
                <div className=" bg-white border-2 rounded-lg divide-y divide-gray-300">
                  <div className="flex w-full flex-col gap-3 px-4 sm:flex-row justify-between ">
                    <p className="text-[#081A51] p-4 w-max border-b-4 border-[#081A51] text-xs sm:text-sm font-semibold">
                      Purchase Subscription plan
                    </p>
                  </div>
                  <div className="p-4 px-4 sm:px-6 space-y-6">
                    <p className="text-[#223333] text-sm sm:text-base font-medium">
                      {singlePlan === "annually"
                        ? `The Planner: You know that finding the right person isn’t a quick fix. You’re in this for the long game, and you’re willing to let it play out over time.
                        The Busy Bee: Maybe life’s a bit hectic, and you need the space to balance it all while still making room for love. With a year on your side, there’s no rush—you can pace yourself.
                        The Thoughtful Newbie: Maybe you’re new to the dating scene, and you’re taking this seriously. You want to avoid missteps and give yourself the time to get it right.
`
                        : singlePlan === "bi-annually"
                        ? `The Relationship Goal-Setter: You’ve got relationship goals, and now it’s time to put in the time to achieve them. You’re done casually dating, ready for something real, and you're committed to the process.
                        The Future Planner: Whether it’s starting a family or finding your long-term travel buddy, your sights set on the future—and you’re ready to start building it.
`
                        : singlePlan === "quarterly"
                        ? `The Focused Dater: You know what you want, and you’re here to find it, but you’re still open to experimenting with your preferences before diving into something long-term.
                        Professionals: You're balancing work and life but have set aside a little more time to focus on relationships. This plan offers you the flexibility to find someone at your own pace, without feeling rushed.
`
                        : `The Curious Explorer: You’re not looking for perfection right away. You want to see what’s out there, and maybe or maybe not get a little adventurous with a blind date.
                        The Undecided: Still figuring out your “type”? Perfect. This is the no-pressure zone where you can test the waters and see what works for you.This plan is all about low-stakes exploration.`}
                    </p>
                    {singlePlan === "annually" && (
                      <div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="mark" />
                          <p className="text-sm max-md:text-[11px]">
                            Full Handler Support: You’re not doing this alone.
                            Our handlers will be your match-making coaches,
                            guiding you toward the most compatible people and
                            helping you refine your approach. They can go extra
                            lengths to find you a compatible match that may not
                            be part of our typical process.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Therapists on Deck: Sometimes love requires a little
                            soul-searching. Whether you need to work through
                            past baggage or just want someone to talk to along
                            the way, our therapists are here for you. A plan can
                            be drafted on how to navigate these challenges and
                            when you find THE one, we are happy to give some
                            guidance to you both
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Handler Suggestions: Get sent personalized matches
                            based on your set preferences. We’re talking
                            tailor-made connections that are aligned with your
                            preferences, personality, and long-term goals.
                            Whether it’s shared interests, life plans, or even
                            values, we’ve got your back in finding the person
                            who checks all your boxes.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Discover Matches:There is a whole song about finding
                            love in a hopeless place for a reason. While you're
                            laser-focused on finding "the one," sometimes love
                            surprises you. With Discover Suggestions, you'll be
                            introduced to profiles that might not meet every
                            preference on your list but still have the potential
                            to spark something special. Think of it as a little
                            nudge from the universe—or us—to keep your heart
                            open to unexpected connections.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Blind Dates: Skip the small talk. Busy schedule? No
                            problem. With Blind Dates, we cut through the
                            endless back-and-forth texting so you can jump
                            straight into a real connection. On these 6-minute
                            video calls, you'll get to pick up on those subtle
                            verbal cues that just don't come through in chat.
                            It’s fast, efficient, and way more insightful than
                            days of messaging. On this pla, we are in it
                            together to win it.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            No Stone Unturned: With a full year, you have time
                            to explore every avenue. Blind dates, handler
                            suggestions, personalized matches—you’ll get it all,
                            with plenty of time to see what works best for you.
                          </p>
                        </div>
                      </div>
                    )}

                    {singlePlan === "bi-annually" && (
                      <div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="mark" />
                          <p className="text-sm max-md:text-[11px]">
                            Handler Suggestions: Personalized matches based on
                            your set preferences, because you know what you
                            want.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Discover Matches:These suggestions aren't handpicked
                            with laser precision to your preferences but, what
                            is the harm in seeing what the general dating pool
                            looks like to help manage your expectations?
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Blind Dates: You will get set up for blind dates
                            which you must endeavor to attend if you accept the
                            invitation. This is a quick process to showcase the
                            personalities behind the keyboards.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Handler Support: Access to your handler to help you
                            on your journey. Whether it is technical support, a
                            quick venting session or just updates on your
                            progress with the processes, your handler is
                            available to answer all enquiries promptly.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            No support from our Therapists: Therapists? Not for
                            you—at least, not right now. Either you’ve already
                            done the work, or you’re in a good place and focused
                            on finding the right person. Six months is all about
                            building that connection, not diving deep into
                            healing. And hey, if you ever need it down the line,
                            we’ll be here, but for now—you’ve got this!
                          </p>
                        </div>
                      </div>
                    )}

                    {singlePlan === "quarterly" && (
                      <div className="">
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="mark" />
                          <p className="text-sm max-md:text-[11px]">
                            Handler Suggestions: Personalized matches based on
                            your set preferences, because you’re here with
                            intent.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Discover Matches:These suggestions aren't handpicked
                            with laser precision to your preferences but, you
                            are not fussed because a pleasant surprise is
                            welcome. In fact, that is why you are here.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Blind Dates (With a Twist!): Depending on
                            availability, you might get set up for a blind date.
                            But here’s the deal— only if members of our
                            community who are more committed to the process
                            request one with you.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Enhanced Support: More access to your handler to
                            help you on your journey.
                          </p>
                        </div>

                        <div className="flex mt-10">
                          <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            No support from our Therapists: Therapists? Nah, it
                            is not that deep. You're more about meeting new
                            people than unpacking deep emotional baggage. No
                            need to overthink it—you’re just here to enjoy the
                            ride and see where things go. When you're ready for
                            that deep dive, we’ll be here for you
                          </p>
                        </div>
                      </div>
                    )}
                    {singlePlan === "monthly" && (
                      <div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="mark" />
                          <p className="text-sm max-md:text-[11px]">
                            We’ve got some delightful matches lined up for you
                            in our Discover section. These aren't handpicked
                            with laser precision for your preferences but, hey,
                            sometimes love surprises you, right? Explore people
                            outside your usual type—you never know!
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Depending on availability, you might get set up for
                            a blind date. But here’s the deal— only if members
                            of our community who are more committed to the
                            process request one with you
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Handler Suggestions? Not this time. But that’s okay,
                            you’re just getting started. Think of it as if you
                            need an outfit but you are not sure if you want a
                            blue one or a black one, no need to customize what
                            you are not sure of!
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Limited Handler Support: You won't be flying
                            completely solo—our handlers are here to assist, but
                            don't expect the full VIP treatment. Just enough to
                            keep you steady on your feet while you explore.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            No support from our Therapists: Talking to
                            therapists means going deep, unveiling and facing
                            fears head on to heal. You are not ready for that
                            and it is okay. We are ready when you are.
                          </p>
                        </div>
                      </div>
                    )}

                    <p className="text-[#081A51] font-semibold text-sm">
                      Select Plan duration
                    </p>

                    <div className=" space-y-7 w-full">
                      <div className="w-full p-2 rounded-lg flex flex-wrap items-center gap-3 md:gap-4 xl:gap-5">
                        {majorPlans.map((plan) => (
                          <div
                            onClick={() => handleChoosePlan(plan)}
                            className={`${
                              singlePlan === plan?.plan
                                ? "bg-primary-bg px-4 py-2  rounded-md text-white text-center cursor-pointer lg:px-6"
                                : "text-primary-bg h-full rounded-md  text-center bg-[#E8EDFB] px-4 py-2 cursor-pointer lg:px-6"
                            }  `}
                          >
                            <p className="text-xs capitalize lg:text-sm xl:text-base">
                              {plan?.plan}
                            </p>
                            <p className="font-bold text-sm lg:text-base xl:text-xl">
                              {currency}{" "}
                              {Number(plan?.amount)?.toLocaleString?.()}
                            </p>
                          </div>
                        ))}
                      </div>
                      <div className="">
                        <PaystackPortal
                          currency={"NGN"}
                          plan_cost={Math.round(+amount)}
                          amount={Math.round(+amount)}
                          reference={user_reference}
                          email={user?.email}
                          plan={singlePlan}
                          onClose={() => {}}
                          mainCurrency={
                            country?.trim?.() === "nigeria" ? "NGN" : "USD"
                          }
                          onSuccess={() => {
                            paymentSuccessHandler({
                              plan: singlePlan,
                              amount,
                              email: user?.email,
                              tx_ref: user_reference,
                              transaction_id: user_reference,
                            });
                          }} //{paidSubmit}
                          title={`Pay ${
                            country?.trim?.() === "nigeria" ? "NGN" : "USD"
                          } ${Number(amount)?.toLocaleString?.()}`}
                          last_step_complete={true}
                          currency_code={"NGN"}
                          phone_number={undefined}
                          name={undefined}
                          plan_duration={undefined}
                          className="w-full sm:w-max justify-center text-white text-xs font-medium sm:text-sm flex items-center bg-[#081A51] px-4 sm:px-6 py-2.5 rounded-lg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            ) : isViewDetails ? (
              <div className=" bg-white border-2 rounded-lg divide-y divide-gray-300">
                <div className="w-full p-3 sm:p-5 relative">
                  <span
                    onClick={() => setIsViewDetails(false)}
                    // style={{ transform: "translateY(-50%)" }}
                    className=" flex pb-2 w-max items-center hover:text-blue-600 cursor-pointer "
                  >
                    <KeyboardArrowLeft />
                    Go Back
                  </span>
                  <p className="text-[#081A51] capitalize text-sm sm:text-lg text-center font-semibold">
                    Premium plan benefits - (
                    {mainPlan?.toLowerCase?.()?.includes?.("usd")
                      ? `${mainPlan
                          ?.toLowerCase?.()
                          ?.replace?.(/-usd$/, "")} ${`(USD)`}`
                      : mainPlan}
                    )
                  </p>
                </div>

                {(mainPlan?.toLowerCase() === "annually" ||
                  mainPlan?.toLowerCase() === "annually-usd" ||
                  mainPlan?.toLowerCase() === "yearly") && (
                  <div className="p-4">
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="mark" />
                      <p className="text-sm max-md:text-[11px]">
                        Full Handler Support: You’re not doing this alone. Our
                        handlers will be your match-making coaches, guiding you
                        toward the most compatible people and helping you refine
                        your approach. They can go extra lengths to find you a
                        compatible match that may not be part of our typical
                        process.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className="text-sm max-md:text-[11px]">
                        Therapists on Deck: Sometimes love requires a little
                        soul-searching. Whether you need to work through past
                        baggage or just want someone to talk to along the way,
                        our therapists are here for you. A plan can be drafted
                        on how to navigate these challenges and when you find
                        THE one, we are happy to give some guidance to you both
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className="text-sm max-md:text-[11px]">
                        Handler Suggestions: Get sent personalized matches based
                        on your set preferences. We’re talking tailor-made
                        connections that are aligned with your preferences,
                        personality, and long-term goals. Whether it’s shared
                        interests, life plans, or even values, we’ve got your
                        back in finding the person who checks all your boxes.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className="text-sm max-md:text-[11px]">
                        Discover Matches:There is a whole song about finding
                        love in a hopeless place for a reason. While you're
                        laser-focused on finding "the one," sometimes love
                        surprises you. With Discover Suggestions, you'll be
                        introduced to profiles that might not meet every
                        preference on your list but still have the potential to
                        spark something special. Think of it as a little nudge
                        from the universe—or us—to keep your heart open to
                        unexpected connections.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className="text-sm max-md:text-[11px]">
                        Blind Dates: Skip the small talk. Busy schedule? No
                        problem. With Blind Dates, we cut through the endless
                        back-and-forth texting so you can jump straight into a
                        real connection. On these 6-minute video calls, you'll
                        get to pick up on those subtle verbal cues that just
                        don't come through in chat. It’s fast, efficient, and
                        way more insightful than days of messaging. On this pla,
                        we are in it together to win it.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className="text-sm max-md:text-[11px]">
                        No Stone Unturned: With a full year, you have time to
                        explore every avenue. Blind dates, handler suggestions,
                        personalized matches—you’ll get it all, with plenty of
                        time to see what works best for you.
                      </p>
                    </div>
                  </div>
                )}
                {(mainPlan?.toLowerCase() === "bi-annually" ||
                  mainPlan?.toLowerCase() === "bi-annually-usd" ||
                  mainPlan?.toLowerCase() === "bi-yearly") && (
                  <div className="p-4">
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="mark" />
                      <p className="text-sm max-md:text-[11px]">
                        Handler Suggestions: Personalized matches based on your
                        set preferences, because you know what you want.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className="text-sm max-md:text-[11px]">
                        Discover Matches:These suggestions aren't handpicked
                        with laser precision to your preferences but, what is
                        the harm in seeing what the general dating pool looks
                        like to help manage your expectations?
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className="text-sm max-md:text-[11px]">
                        Blind Dates: You will get set up for blind dates which
                        you must endeavor to attend if you accept the
                        invitation. This is a quick process to showcase the
                        personalities behind the keyboards.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className="text-sm max-md:text-[11px]">
                        Handler Support: Access to your handler to help you on
                        your journey. Whether it is technical support, a quick
                        venting session or just updates on your progress with
                        the processes, your handler is available to answer all
                        enquiries promptly.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                      <p className="text-sm max-md:text-[11px]">
                        No support from our Therapists: Therapists? Not for
                        you—at least, not right now. Either you’ve already done
                        the work, or you’re in a good place and focused on
                        finding the right person. Six months is all about
                        building that connection, not diving deep into healing.
                        And hey, if you ever need it down the line, we’ll be
                        here, but for now—you’ve got this!
                      </p>
                    </div>
                  </div>
                )}
                {(mainPlan?.toLowerCase() === "quarterly" ||
                  mainPlan?.toLowerCase() === "quarterly-usd") && (
                  <div className="p-4">
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="mark" />
                      <p className="text-sm max-md:text-[11px]">
                        Handler Suggestions: Personalized matches based on your
                        set preferences, because you’re here with intent.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className="text-sm max-md:text-[11px]">
                        Discover Matches:These suggestions aren't handpicked
                        with laser precision to your preferences but, you are
                        not fussed because a pleasant surprise is welcome. In
                        fact, that is why you are here.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className="text-sm max-md:text-[11px]">
                        Blind Dates (With a Twist!): Depending on availability,
                        you might get set up for a blind date. But here’s the
                        deal— only if members of our community who are more
                        committed to the process request one with you.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className="text-sm max-md:text-[11px]">
                        Enhanced Support: More access to your handler to help
                        you on your journey.
                      </p>
                    </div>

                    <div className="flex mt-10">
                      <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        No support from our Therapists: Therapists? Nah, it is
                        not that deep. You're more about meeting new people than
                        unpacking deep emotional baggage. No need to overthink
                        it—you’re just here to enjoy the ride and see where
                        things go. When you're ready for that deep dive, we’ll
                        be here for you
                      </p>
                    </div>
                  </div>
                )}

                {(mainPlan?.toLowerCase() === "monthly" ||
                  mainPlan?.toLowerCase() === "monthly-usd") && (
                  <div className="p-4">
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="mark" />
                      <p className="text-sm max-md:text-[11px]">
                        We’ve got some delightful matches lined up for you in
                        our Discover section. These aren't handpicked with laser
                        precision for your preferences but, hey, sometimes love
                        surprises you, right? Explore people outside your usual
                        type—you never know!
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className="text-sm max-md:text-[11px]">
                        Depending on availability, you might get set up for a
                        blind date. But here’s the deal— only if members of our
                        community who are more committed to the process request
                        one with you
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                      <p className="text-sm max-md:text-[11px]">
                        Handler Suggestions? Not this time. But that’s okay,
                        you’re just getting started. Think of it as if you need
                        an outfit but you are not sure if you want a blue one or
                        a black one, no need to customize what you are not sure
                        of!
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                      <p className="text-sm max-md:text-[11px]">
                        Limited Handler Support: You won't be flying completely
                        solo—our handlers are here to assist, but don't expect
                        the full VIP treatment. Just enough to keep you steady
                        on your feet while you explore.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                      <p className="text-sm max-md:text-[11px]">
                        No support from our Therapists: Talking to therapists
                        means going deep, unveiling and facing fears head on to
                        heal. You are not ready for that and it is okay. We are
                        ready when you are.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className=" bg-white border-2 rounded-lg divide-y divide-gray-300">
                {user?.mode === "paystack" ? (
                  <>
                    <div className="flex w-full p-5 justify-between items-center">
                      <p className="text-[#081A51] font-semibold">
                        Membership Subscription
                      </p>
                      {mainPlan?.toLowerCase?.() === "annually" ||
                      mainPlan?.toLowerCase?.() === "annually-usd" ||
                      mainPlan?.toLowerCase?.() === "yearly" ? null : (
                        <button
                          onClick={() => {
                            setUserReference(`REFSUB${Date.now().toString()}`);
                            setOpenUpgrade(true);
                          }}
                          className="w-max justify-center text-white text-xs font-medium sm:text-sm flex items-center bg-[#081A51] px-4 sm:px-6 py-2.5 rounded-lg"
                        >
                          Upgrade Plan
                        </button>
                      )}
                    </div>
                    <div className="p-5 space-y-5">
                      <div className="rounded-lg  border-t-blue-400 divide-y divide-gray-300 border border-t-4">
                        <div className="p-4">
                          <h2 className="text-lg font-semibold">
                            Premium plan
                          </h2>
                          <p className="text-xs">
                            Enjoy access to handlers, personal
                            therapist/handler, suggestions and more.
                          </p>
                        </div>
                        <div className="p-1">
                          <div
                            onClick={() => setIsViewDetails(true)}
                            className="flex p-3 cursor-pointer hover:bg-gray-100 justify-between items-center"
                          >
                            <h2 className="text-sm">View more details</h2>
                            <KeyboardArrowRight />
                          </div>
                        </div>
                      </div>
                      <p className="text-[#081A51] font-semibold">
                        Payment Info
                      </p>
                      <div className="rounded-lg  border-t-blue-400 divide-y divide-gray-300 border border-t-4">
                        <div className="p-4">
                          <h2 className="text-xs">Payment Plan</h2>
                          <p className="text-base capitalize font-semibold">
                            {mainPlan?.toLowerCase?.()?.includes?.("usd")
                              ? `${mainPlan
                                  ?.toLowerCase?.()
                                  ?.replace?.(/-usd$/, "")} ${`(USD)`}`
                              : mainPlan}
                          </p>
                        </div>
                        <div className="p-4">
                          <h2 className="text-xs">Expires In</h2>
                          <p className="text-base font-semibold">
                            {calculateExpirationDate(
                              mainPaystackObject?.created_at ?? paystackDate,
                              mainPlan
                            ) !== "expired"
                              ? `${calculateDaysLeft(
                                  calculateExpirationDate(
                                    mainPaystackObject?.created_at ??
                                      paystackDate,
                                    mainPlan
                                  )
                                )} days left`
                              : "expired"}
                          </p>
                        </div>
                        <div className="p-4">
                          <h2 className="text-xs">Payment Method</h2>
                          <p className="text-base items-start text-blue-400 flex gap-2 sm:items-center font-semibold">
                            <CreditCard color="inherit" />
                            <div className="flex gap-4 sm:gap-10 items-start flex-col sm:flex-row sm:items-center">
                              Paystack
                              {user?.paystackSubscription?.[0]?.last4 && (
                                <div className="px-4 py-2.5 text-black rounded-lg border border-gray-400">
                                  <div className="flex justify-between gap-10 items-center">
                                    <div>
                                      <h2 className="text-sm font-semibold">
                                        **** **** ****{" "}
                                        {mainPaystack?.last4 ??
                                          user?.paystackSubscription?.[0]
                                            ?.last4}
                                      </h2>
                                      <p className="text-xs">
                                        {mainPaystack?.card_type ??
                                          user?.paystackSubscription?.[0]
                                            ?.card_type}
                                      </p>
                                    </div>
                                    <p className="text-xs">
                                      {mainPaystack?.exp_month ??
                                        user?.paystackSubscription?.[0]
                                          ?.exp_month}{" "}
                                      /{" "}
                                      {mainPaystack?.exp_year?.slice(2) ??
                                        user?.paystackSubscription?.[0]?.exp_year?.slice(
                                          2
                                        )}{" "}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </p>
                        </div>
                        <div className="p-4">
                          <h2 className="text-xs pb-1">
                            Recurring Subscription
                          </h2>
                          {mainPaystack?.active ? (
                            <p className="text-xs px-4 w-max py-1 bg-blue-100 rounded-lg border-2 border-blue-600 text-blue-600 flex gap-2 items-center font-semibold">
                              Enabled
                            </p>
                          ) : (
                            <p className="text-xs px-4 w-max py-1 bg-gray-100 rounded-lg border-2 border-gray-600 text-gray-600 flex gap-2 items-center font-semibold">
                              Non-recurring (Disabled)
                            </p>
                          )}
                        </div>
                        {mainPaystack?.active && (
                          <div className="p-4">
                            <div className="flex justify-center items-center">
                              <button
                                onClick={() => {
                                  setOpenConfirm(true);
                                }}
                                className="w-full sm:w-max justify-center text-white text-xs font-medium sm:text-sm flex items-center bg-[#081A51] px-4 sm:px-6 py-2.5 rounded-lg"
                              >
                                Cancel Recurring Membership Subscription
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex w-full p-5 justify-between items-center">
                      <p className="text-[#081A51] font-semibold">
                        Membership Subscription
                      </p>
                      {mainPlan?.toLowerCase?.() === "annually" ||
                      mainPlan?.toLowerCase?.() === "annually-usd" ||
                      mainPlan?.toLowerCase?.() === "yearly" ? null : (
                        <button
                          onClick={() => {
                            setUserReference(`REFSUB${Date.now().toString()}`);
                            setOpenUpgrade(true);
                          }}
                          className="w-max justify-center text-white text-xs font-medium sm:text-sm flex items-center bg-[#081A51] px-4 sm:px-6 py-2.5 rounded-lg"
                        >
                          Upgrade Plan
                        </button>
                      )}
                    </div>
                    <div className="p-5 space-y-5">
                      <div className="rounded-lg  border-t-blue-400 divide-y divide-gray-300 border border-t-4">
                        <div className="p-4">
                          <h2 className="text-lg font-semibold">
                            Premium plan
                          </h2>
                          <p className="text-xs">
                            Enjoy access to handlers, personal
                            therapist/handler, suggestions and more.
                          </p>
                        </div>
                        <div className="p-1">
                          <div
                            onClick={() => setIsViewDetails(true)}
                            className="flex p-3 cursor-pointer hover:bg-gray-100 justify-between items-center"
                          >
                            <h2 className="text-sm">View more details</h2>
                            <KeyboardArrowRight />
                          </div>
                        </div>
                      </div>
                      <p className="text-[#081A51] font-semibold">
                        Payment Info
                      </p>
                      <div className="rounded-lg  border-t-blue-400 divide-y divide-gray-300 border border-t-4">
                        <div className="p-4">
                          <h2 className="text-xs">Payment Plan</h2>
                          <p className="text-base capitalize font-semibold">
                            {user?.plan}
                          </p>
                        </div>
                        <div className="p-4">
                          <h2 className="text-xs">Last Payment</h2>
                          <p className="text-base font-semibold">
                            {moment(user?.paymentDate ?? new Date())?.format?.(
                              "Do MMMM YYYY"
                            )}
                          </p>
                        </div>
                        <div className="p-4">
                          <h2 className="text-xs">Valid until</h2>
                          <p className="text-base font-semibold">
                            {calculateExpirationDate(
                              user?.paymentDate,
                              user?.plan?.toLowerCase?.()
                            ) === "expired"
                              ? "Expired"
                              : moment(
                                  calculateExpirationDate(
                                    user?.paymentDate,
                                    user?.plan?.toLowerCase?.()
                                  ) ?? new Date()
                                )?.format?.("Do MMMM YYYY")}
                          </p>
                        </div>
                        <div className="p-4">
                          <h2 className="text-xs">Payment Method</h2>
                          <p className="text-base text-blue-400 flex gap-2 items-center font-semibold">
                            <AssignmentInd color="inherit" />
                            Payment through handler
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default RevisedAuthPlan;

import { useState } from "react";
import ResponsiveOverlay from "src/overlays/ResponsiveOverlay";
import AdminSelectedHandler from "./AdminSelectedHandler";
import AllAdminHandlers from "./AllAdminHandlers";

interface User {
  last_name: String;
  first_name: String;
  email: String;
  phone: String;
  date_joined: String;
  status: String;
  gender: String;
  genotype: String;
  relationship_status: String;
  ethinicity: String;
  date_of_birth: Date;
  username: String;
  religion: String;
  occupation: String;
  location: String;
  country: String;
  place_of_birth: String;
  nationality: String;
  qualification: String;
  hobbies: String;
  preferred_gender: String;
  preferred_location: String;
  preferred_religion: String;
  preferred_genotype: String;
  preferred_status: String;
  preferred_age: String;
}
export default function AdminIndex() {
  const [indexPage, setIndexPage] = useState(0);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedPage, setSelectedPage] = useState(0);
  const selections = [
    "General Details",
    "Photos",
    "Pending Matches",
    "Matches",
    "Strike Report",
    "Blacklist Report",
  ];
  const [openOverlay, setOpenOverlay] = useState(false);
  const [action, setAction] = useState("strike");
  return (
    <>
      <ResponsiveOverlay
        isOpen={openOverlay}
        setIsOpen={setOpenOverlay}
        title={
          action === "strike"
            ? "Reason for strike"
            : "Reason for  Blacklisting user"
        }
      >
        <div className="p-10 pt-5">
          <div className="w-full  space-y-5">
            <textarea
              className="resize-none w-full h-80 p-5 rounded-lg border border-[#102A42] text-sm"
              placeholder={
                action === "strike"
                  ? "Input Reason for strike here"
                  : "Reason for  Blacklisting user"
              }
            ></textarea>
            <div className="flex justify-center items-center">
              <button className="px-4 py-2 rounded-lg text-white  bg-[#081A51] text-sm font-semibold">
                {action === "strike" ? "Strike user" : "Blacklist user"}
              </button>
            </div>
          </div>
        </div>
      </ResponsiveOverlay>
      {indexPage === 0 ? (
        <AllAdminHandlers
          setSelectedUser={setSelectedUser}
          setIndexPage={setIndexPage}
        />
      ) : (
        <AdminSelectedHandler
          setSelectedUser={setSelectedUser}
          setIndexPage={setIndexPage}
          selectedUser={selectedUser}
        />
      )}
    </>
  );
}

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Tooltip, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'
import { useNavigate } from 'react-router'
import { BackButtonWrapper, StyledSpan } from './BackButton.styles'

interface Props {
  link?: string
  onClick?: () => void
  parent: string
  child: string | ReactNode
  fontSize?: number | string
  childLink?: string
  useToolTip?: boolean
  tipTitle?: string
}
const BackButton: FC<Props> = ({
  link,
  parent,
  child,
  onClick,
  fontSize,
  childLink,
  useToolTip,
  tipTitle,
}) => {
  const navigate = useNavigate()
  return (
    <BackButtonWrapper fontSize={fontSize}>
      {useToolTip ? (
        <Tooltip
          // @ts-ignore
          title={tipTitle && tipTitle}
          // @ts-ignore
          placement={tipTitle && 'bottom'}
        >
          <Typography
            variant="h4"
            className="parent"
            onClick={() => {
              if (link) {
                navigate(link)
              } else {
                onClick && onClick()
              }
            }}
          >
            <ArrowBackIcon />
            {parent}
          </Typography>
        </Tooltip>
      ) : (
        <Typography
          variant="h4"
          className="parent"
          onClick={() => {
            if (link) {
              navigate(link)
            } else {
              onClick && onClick()
            }
          }}
        >
          <ArrowBackIcon />
          {parent}
        </Typography>
      )}
      <StyledSpan
        onClick={() => {
          if (childLink) {
            navigate(childLink)
          } else {
            onClick && onClick()
          }
        }}
        variant="h4"
        className={`${childLink && 'haslink'}`}
      >
        {child || ''}
      </StyledSpan>
    </BackButtonWrapper>
  )
}

export default BackButton

import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function Accepted() {
  const titleRef = useRef<HTMLInputElement | null>(null);
  const [titleDivHeight, setTitleDivHeight] = useState<any>(0);
  const [save_modal, setSaveModal] = useState<boolean>(true);
  const toggleModal = () => {
    setSaveModal(false);
    window.location.href = "/";
  };

  return (
    <Transition.Root show={save_modal} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50"
        open={save_modal}
        onClose={toggleModal}
      >
        <div className="flex items-end justify-center min-h-screen text-center sm:block">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            afterEnter={() => {
              const refing = titleRef?.current?.clientHeight;
              setTitleDivHeight(refing);
            }}
            // afterLeave={()=>{}}
          >
            <div
              style={{ background: "#00000094" }}
              className="h-screen w-full inline-block text-left transform transition-all align-middle"
            >
              <div className="mx-auto relative" style={{ maxWidth: "1920px" }}>
                <div className="absolute top-0 right-0 pr-4 sm:pr-6 pt-4 sm:pt-5">
                  <button
                    type="button"
                    className="text-gray-500 hover:text-gray-800 focus:outline-none focus:ring-0"
                    onClick={() => toggleModal()}
                  >
                    <span className="sr-only">Close</span>
                    <i className="fa-regular hidden fa-circle-xmark"></i>
                  </button>
                </div>

                <div ref={titleRef} className="px-4 sm:px-6 py-4 sm:py-5"></div>

                <div
                  className="px-4 sm:px-6 pt-1 sm:pt-2 pb-5"
                  style={{
                    height: `calc(100vh - ${titleDivHeight}px)`,
                    overflowY: "auto",
                  }}
                >
                  {/* content */}
                  <div className="w-full h-full flex justify-center items-center">
                    <Dialog.Panel className="w-full space-y-20 max-w-md -mt-32 transform relative overflow-hidden rounded-3xl bg-white px-6 py-16 flex flex-col items-center align-middle shadow-xl transition-all">
                      <Dialog.Title
                        as="h3"
                        className="text-4xl canela font-bold text-center font-medium leading-6 text-brand-1"
                      >
                        {"Match Accepted"}!
                      </Dialog.Title>
                      <button
                        className="w-5/6 block px-5 py-3 text-lg text-white transition duration-150 ease-out hover:ease-in rounded-3xl bg-brand-1 hover:-translate-y-1 hover:shadow-md active:shadow-none font-semibold flex gap-2 items-center justify-center"
                        onClick={() => toggleModal()}
                      >
                        {"Close"}
                      </button>
                    </Dialog.Panel>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import { Place, Verified, Work } from "@mui/icons-material";
import moment from "moment";
import React from "react";
import { useUser } from "../auth/hooks/UserProvider";
import ToolTip from "../ToolTip";

interface UserMatchProps {
  img: string;
  name: string;
  occupation: string;
  userMatch: Object;
  btnClickDecline: (param: Object) => void;
  btnClickAccept: (param: Object) => void;
  request?: boolean;
  btnOnClick?: () => void;
  handleCardClick?: (param: Object) => void;
  type: String;
}

const Card: React.FC<UserMatchProps> = ({
  img,
  name,
  userMatch,
  occupation,
  btnOnClick,
  request,
  btnClickDecline,
  btnClickAccept,
  handleCardClick,
  type = "system",
}) => {
  const { user } = useUser();
  const calculateAge = (birthdate) => {
    const birthDate = new Date(birthdate);
    const ageDiffMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDiffMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };
  //@ts-ignore
  const userAge = userMatch?.user_matched?.age?.includes?.("-")
    ? //@ts-ignore
      calculateAge(userMatch?.user_matched?.age)
      ? //@ts-ignore
        calculateAge(userMatch?.user_matched?.age)
      : ""
    : moment().diff(
        //@ts-ignore
        moment(userMatch?.user_matched?.age, "DD/MM/YYYY"),
        "years"
      ) ?? "";
  return (
    <div className=" h-full rounded-lg">
      <div className="w-full bg-gray-300 h-40 rounded-t-lg ">
        {img ? (
          <img
            src={img}
            alt="person"
            className="w-full object-cover  h-full rounded-t-lg"
          />
        ) : (
          <div className="w-full h-full flex md:text-2xl lg:text-4xl justify-center items-center">
            <img
              src={
                "https://res.cloudinary.com/horlarde/image/upload/v1721627582/no_image_zrdycr.jpg"
              }
              alt="person"
              className="w-full object-cover h-full rounded-t-lg"
            />
          </div>
        )}
      </div>
      <div className="bg-white rounded-b-lg text-brand-1 border  space-y-2 p-4 shadow-md ">
        <div className="flex text-xs gap-2 items-center">
          <h2 className="text-sm capitalize font-bold truncate">
            {name}
            {userAge ? `,` : ""}
          </h2>
          {userAge ? `${userAge}` : ""}
          <Verified fontSize="inherit" />
        </div>
        <div className="grid grid-cols-2 gap-2 items-center">
          <div className="w-full flex text-[9.5px] gap-1 items-center">
            <Work fontSize="inherit" />

            <p className="text-[9.5px] whitespace-nowrap max-lg:text-[9px] block truncate">
              {occupation}
            </p>
          </div>
          <div className="w-full flex text-[9.5px] gap-1 items-center">
            <Place fontSize="inherit" />

            <p className=" capitalize text-[9.5px] whitespace-nowrap max-lg:text-[9px] block truncate">
              {
                //@ts-ignore
                userMatch?.user_matched?.location_state ?? "N/A"
              }
              ,{" "}
              {
                //@ts-ignore
                userMatch?.user_matched?.location_country ?? "N/A"
              }
            </p>
          </div>
        </div>
        <p className="text-xs py-2 block truncate w-full">
          {
            //@ts-ignore
            userMatch?.user_matched?.about_me ?? "Hello there, I'm available"
          }
        </p>
        {type === "pending" ? (
          <div className="flex gap-2 items-center">
            <button className="text-xs cursor-auto w-full border bg-gray-300 text-[#081a51] font-medium rounded-lg  px-3 py-2 text-center">
              Awaiting {name?.split?.(" ")?.[0]}'s response
            </button>
          </div>
        ) : type === "match" ? (
          <div className="flex flex-wrap gap-2  items-center">
            <button
              onClick={handleCardClick}
              className="text-[9px]  whitespace-nowrap border border-primary-bg hover:bg-primary-bg hover:text-white  rounded-lg hover:opacity-75 px-3 py-2 text-center flex-grow"
            >
              View Profile
            </button>
            <button
                onClick={() => {
                  btnClickAccept(userMatch);
                }}
                className="text-[9px]  whitespace-nowrap border border-primary-bg hover:bg-primary-bg hover:text-white rounded-lg hover:opacity-75 px-3 py-2 text-center flex-grow"
              >
                Accept
              </button>
           <button
                onClick={() => {
                  btnClickDecline(userMatch);
                }}
                className="text-[9px]  whitespace-nowrap border border-primary-bg hover:bg-primary-bg hover:text-white rounded-lg hover:opacity-75 px-3 py-2 text-center flex-grow"
              >
                Decline
              </button>
          </div>
        ) : (
          <div className="flex gap-2 flex-wrap justify-between items-center">
            <button
              onClick={handleCardClick}
              className="text-[9px]  whitespace-nowrap border border-primary-bg hover:bg-primary-bg hover:text-white rounded-lg hover:opacity-75 px-3 py-2 text-center flex-grow"
            >
              View Profile
            </button>
           <button
                onClick={() => {
                  btnClickAccept(userMatch);
                }}
                className="text-[9px]  whitespace-nowrap border border-primary-bg hover:bg-primary-bg hover:text-white rounded-lg hover:opacity-75 px-3 py-2 text-center flex-grow"
              >
                Send Request
              </button>
          <button
                onClick={() => {
                  btnClickDecline(userMatch);
                }}
                className="text-[9px]  whitespace-nowrap border border-primary-bg hover:bg-primary-bg hover:text-white rounded-lg hover:opacity-75 px-3 py-2 text-center flex-grow"
              >
                Decline
              </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;

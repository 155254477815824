import { gql } from "@apollo/client";

export const CONVERSATION_CHANGED = gql`
  subscription Subscription($userId: Int) {
    conversationChanged(user_id: $userId) {
      id
      lastMessageSentAt
      created_at
      messages {
        body
        created_at
        from {
          id
          first_name
          last_online
          is_online
          is_available
          last_name
          # user_status
          profile_image_url
          qualification
        }
        to {
          id
          first_name
          last_online
          is_online
          is_available
          last_name
          # user_status
          profile_image_url
          qualification
        }
        # media {
        #   id
        #   url
        #   created_at
        #   description
        # }
        attachments
        is_inbound
      }
      users {
        id
        first_name
        is_online
        is_available
        last_name
        #  user_status
        profile_image_url
        last_online
        qualification
      }
    }
  }
`;
export const NOTIFICATION_CHANGED = gql`
  subscription Subscription($userId: Int) {
    notificationChanged(user_id: $userId) {
      action_url
      deleted
      deleted_at
      description
      destination_id
      id
      is_archived
      is_read
      title
      type
      updated_at
      user {
        id
        last_name
        first_name
      }
      created_at
    }
  }
`;
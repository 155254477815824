import React, { useState } from "react";
import { SUBSCRIBE_REQUEST } from "src/hooks/data/mutations";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
const Logo =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/logo.svg";
const happy =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/happy1.svg";
const Vector =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/vector1.svg";
const Subscribe = () => {
  const navigate = useNavigate();
  const [subscribeAccountRequest, { loading }] = useMutation(SUBSCRIBE_REQUEST);
  const [email, setEmail] = useState("");

  const handleSubscribeAccountRequest = () => {
    if (!email) return alert("Please enter your email address to subscribe");
    subscribeAccountRequest({
      variables: {
        email,
      },
      onCompleted: (data) => {
        if (data.subscribeUser) {
          navigate("/");
        }
      },
      onError: (error) => {
        console.log(error);
        alert("An error occured");
      },
    });
  };
  return (
    <div className="h-screen relative max-lg:fixed">
      <img
        src={happy}
        alt="Background"
        className="absolute object-cover w-full h-full "
      />
      <div className="absolute inset-0 bg-[#000107] bg-opacity-50"></div>
      <div className="relative z-10">
        <nav className="flex w-full justify-between h-16 px-8  max-lg:w-[100%]">
          <img
            src={Logo}
            className="w-20 h-10 items-center mt-3 z-10"
            alt="logo"
          />
          <div className="w-1/5 flex justify-around items-center max-lg:hidden">
            {/* <span className="w-24 h-10 border-2 border-white text-white text-xs flex items-center justify-center font-semibold rounded-lg cursor-pointer">
              Sign Up
            </span> */}
            {/* <span className='w-24 h-10  bg-white text-[#081A51] text-xs flex items-center justify-center font-semibold rounded-lg cursor-pointer'>Login</span> */}
            <span></span>
          </div>
          <img
            src={Vector}
            className="w-8 h-8 lg:hidden items-center mt-3 z-10"
            alt="logo"
          />
        </nav>
        <div className="w-[60%] h-[480px] m-auto mt-24 flex flex-col items-center max-lg:w-[95%] max-lg:ml-2 max-lg:mt-24">
          <p className="text-4xl text-white font-bold text-center leading-10 w-[70%] max-lg:w-[80%] max-lg:text-2xl">
            We are currently not accepting any new intakes !
          </p>
          <p className="text-sm text-white w-[600px] text-center mt-8 leading-6 max-lg:w-[100%]">
            Hello, love seekers! The Nirvanae website is currently undergoing a
            little 'tender' loving care. We're sprucing things up to make your
            romantic journey even better. Sign Up for our Newsletter so you
            would be amongst the first to get informed when we back up. Thanks
            for your patience, and we'll be back soon to help you find your
            perfect match!"
          </p>
          <div className="h-[50px] bg-white w-[60%] rounded-sm flex px-3 mt-8 max-lg:flex-col max-lg:w-[90%] max-lg:h-[100px]">
            <input
              type="text"
              className="h-[36px] mt-2 w-[90%] appearance-none border-slate-400 focus:outline-none focus:border-slate-400 focus:ring-0 rounded-md
                max-lg:ml-2 
            "
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              className=" ml-2 w-24 h-[36px] border-2 bg-[#081A51] text-white text-xs flex items-center justify-center font-semibold rounded-lg cursor-pointer mt-2 
                        max-lg:w-[90%] max-lg:h-[100px]
            "
              disabled={loading}
              onClick={handleSubscribeAccountRequest}
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;

import "aos/dist/aos.css";

export default function Terms() {
  return (
    <div className="container px-5 xl:px-0 overflow-hidden mx-auto">
      <main className="space-y-10 py-12">
        <div className="flex flex-col items-center gap-10 justify-between">
          <div className="w-full mt-20 sm:mt-0 lg:w-2/3 xl:w-1/2 relative flex flex-col items-center space-y-10 mx-auto">
            <h1
              data-aos="zoom-in"
              data-aos-duration="1000"
              className="text-3xl canela text-brand-1 sm:text-6xl text-center font-normal"
            >
              Terms & Conditions
            </h1>
          </div>
        </div>
        <div className="flex flex-col gap-10 md:flex-row justify-between items-center">
          <div className="w-full text-brand-1">
            <h2 className="text-base sm:text-xl mb-6">
              <div className="text-5xl text-brand-red-1 font-semibold mr-2 mb-3">
                1.
              </div>
              You have a valid means of identification
            </h2>
            <h2 className="text-base sm:text-xl mb-6">
              <div className="text-5xl text-brand-red-1 font-semibold mr-2 mb-3">
                2.
              </div>
              In our private matchmaking, despite our overwhelming success, we
              do not promise marriage. We promise to connect busy singles who
              are searching with other busy singles. For contact, complaints,
              and enquiries, reach us on&nbsp;
              <a
                href="mailto://support@nirvanaeng.com"
                className="text-brand-red-1"
              >
                @nirvanaehub
              </a>
            </h2>
            <h2 className="text-base sm:text-xl mb-6">
              <div className="text-5xl text-brand-red-1 font-semibold mr-2 mb-3">
                3.
              </div>
              No matter what country you reside in, it is legal for you to be on
              a dating site.
            </h2>
            <h2 className="text-base sm:text-xl mb-6">
              <div className="text-5xl text-brand-red-1 font-semibold mr-2 mb-3">
                4.
              </div>
              You are responsible for your own safety on dates, meet ups, as
              well as for the matches you accept or reject.
            </h2>
            <h2 className="text-base sm:text-xl mb-6">
              <div className="text-5xl text-brand-red-1 font-semibold mr-2 mb-3">
                5.
              </div>
              You agree to us taking your bio details and that we can use it to
              find matches for you.
            </h2>
            <h2 className="text-base sm:text-xl mb-6">
              <div className="text-5xl text-brand-red-1 font-semibold mr-2 mb-3">
                6.
              </div>
              In our private matchmaking, depending on factor surrounding your
              profile like genotype, age, or location, it may be tougher or
              easier for our system to generate matches for you. However, do not
              be fazed by this, as we will not require a new subscription if
              you've not been sufficiently matched according to the tenets of
              your membership agreement.
            </h2>
            <h2 className="text-base sm:text-xl mb-6">
              <div className="text-5xl text-brand-red-1 font-semibold mr-2 mb-3">
                7.
              </div>
              We are always looking for potential matches for subscribers who
              are looking to build our database further.
            </h2>
            <h2 className="text-base sm:text-xl mb-6">
              <div className="text-5xl text-brand-red-1 font-semibold mr-2 mb-3">
                8.
              </div>
              You are not a person with malicious intent to fleece people on
              Nirvanae and can be charged based on your behavior on our
              platform.
            </h2>
            <h2 className="text-base sm:text-xl mb-6">
              <div className="text-5xl text-brand-red-1 font-semibold mr-2 mb-3">
                9.
              </div>
              You adhere to all our rules concerning going on dates, including
              scheduling dates or blind dates via zoom calls, dinner or lunch
              dates, walk in the parks, as well as any other dates as agreed
              upon by you and whoever you’ve matched with on our site.
            </h2>
            <h2 className="text-base sm:text-xl mb-6">
              <div className="text-5xl text-brand-red-1 font-semibold mr-2 mb-3">
                10.
              </div>
              You adhere and agree to all our terms.
            </h2>
          </div>
        </div>
        <footer>
          <div className="flex items-center justify-center">
            <p className="text-xs sm:text-sm font-semibold">
              © 2023 Nirvanae. All Rights Reserved
            </p>
          </div>
          <div className="flex items-center justify-center mt-4">
            <a href="https://instagram.com/nirvanaehub" className="mr-3">
              <img
                alt="Instagram"
                title=""
                height="20"
                src="https://dearmacfiles.s3.amazonaws.com/ff3e0ac34ae01ec16ca695a224fe1658.png"
                style={{ display: "block" }}
                width="20"
              />
            </a>
            <a href="https://x.com/nirvanaehub?t=DTFZyXHl7N4ooju9kDygJw&s=08">
              <img
                alt="Twitter"
                title=""
                height="20"
                src="https://dearmacfiles.s3.amazonaws.com/a937f2afb5e8b32918e8dcfc806cd6b7.png"
                style={{ display: "block" }}
                width="20"
              />
            </a>
          </div>
        </footer>
      </main>
    </div>
  );
}

import { useContext } from "react";
import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";
import { SideBarContext } from "src/context/SidebarProvider";

const Question =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/question.svg";
const LogoIconSm =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/logout_sm.svg";
const Vector =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/vector.svg";
const LogoIconSmm =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/logout_smm.svg";

export const SideBar = ({ children }) => {
  const { show, toggleShowSidebar } = useContext(SideBarContext);
  return (
    <div className="relative">
      <aside className="h-screen max-lg:hidden">
        <nav
          className={`h-full pb-8 flex flex-col bg-[#081A51] boder-r shadow-sm transition-all w-20 ${
            show ? "w-64" : "w-20"
          }`}
        >
          <div className="pl-4 pb-2 pt-4 flex items-center relative">
            <img src={Vector} className="w-8 h-8" alt="vector" />
            <span
              className={`text-white text-2xl whitespace-nowrap overflow-hidden transition-all ml-3 ${
                show ? "w-28" : "w-0"
              }`}
            >
              Nirvanae
            </span>
            <button
              onClick={toggleShowSidebar}
              className="rounded-lg shadow-lg bg-white opacity-100 hover:bg-gray-50 cursor-pointer absolute -right-2.5"
            >
              {show ? (
                <ChevronLeftOutlined className="shadow-lg" />
              ) : (
                <ChevronRightOutlined className="shadow-lg" />
              )}
            </button>
          </div>
          <ul className="flex-1 px-3 mt-14">{children}</ul>

          {show ? (
            <div
              className={`bg-[#1B2B65] h-36 m-auto rounded-lg flex flex-col items-center relative px-2 transition-all ${
                show ? "w-52 ml-3" : "w-0"
              } relative group`}
            >
              <img
                src={Question}
                className="h-16 absolute -top-6"
                alt="question"
              />
              <div
                className={`mt-8 flex justify-between items-center flex-col overflow-hidden transition-all ${
                  show ? "w-48 " : "w-0"
                }`}
              >
                <h4 className={`text-sm text-white`}>Done for the day?</h4>
                <div
                  className={`text-xs text-white h-10 bg-[#017EFA] w-44 rounded-lg flex justify-center items-center mt-2 cursor-pointer `}
                >
                  <img
                    src={LogoIconSm}
                    className="h-6 cursor-pointer mr-2"
                    alt="logo"
                  />
                  <p className="font-extrabold text-white">Logout</p>
                </div>
              </div>
              {/* {!show && (
                            <div
                                className={`
                                absolute left-full rounded-md px-2 py-1 ml-6
                                text-indigo-800 text-sm whitespace-nowrap
                                invisible opacity-20 -translate-x-3 transition-all
                                group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 z-[1]
                            `}
                                >
                            Logout 
                            </div>
                        )} */}
            </div>
          ) : (
            <img
              src={LogoIconSm}
              className="h-8 cursor-pointer mr-4"
              alt="img"
            />
          )}
        </nav>
      </aside>
      {show && (
        <ul
          className={`flex-1 bg-white absolute right-5 top-16 lg:hidden shadow-lg mt-0.5 rounded-lg transition-all z-[1] ${
            show ? " h-80 w-44 " : "h-0"
          }`}
        >
          {children}
          <li className="flex py-2 px-3 my-1 cursor-pointer mt-4">
            <img
              src={LogoIconSmm}
              className="max-lg:h2 max-lg:w-4 lg:hidden mr-2"
              alt="logo"
            />
            <span
              className={` whitespace-nowrap overflow-hidden transition-all lg:hidden
                 max-lg:text-slate-900 max-lg:text-xs max-lg:ml-0 font-extrabold cursor-pointer`}
            >
              Logout
            </span>
          </li>
        </ul>
      )}
    </div>
  );
};

export const SideBarItem = ({
  icon,
  iconSm,
  text,
  textSm,
  active = false,
  onClick,
  show,
}) => {
  return (
    <div
      onClick={onClick}
      className={`relative flex items-center ${
        !show && "justify-center"
      } gap-3 py-2 px-3 font-medium rounded-md cursor-pointer group
            transition-colors ${
              active
                ? "bg-[#1B2B65] text-white"
                : "hover:bg-[#1B2B65] text-white max-lg:hover:bg-white"
            }
        `}
    >
      <img src={icon} className="w-6 h-6" alt="icon" />
      {show && (
        <div className="flex items-center gap-2 ">
           <p
          className={`text-white whitespace-nowrap overflow-hidden transition-all text-base `}
        >
          {text}
        </p>
        {
text === "Blind Dates" &&
<img
                    src={"/images/new.png"}
                    alt="profile_picture"
                     width={1000}
                height={1000}
                    className='w-10    '
                  />
        }

        </div>
       
      )}
    </div>
  );
};

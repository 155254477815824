import { Menu, Transition } from "@headlessui/react";
import { AddCircle, Message, MoreVert, People } from "@mui/icons-material";
import moment from "moment";
import { Fragment, useState } from "react";
import ASSETS from "src/asset";
import CreateBlindDate from "./CreateBlindDate";
import { GET_BLIND_DATE_OF_HANDLER } from "src/hooks/data/queries";
import { useQuery } from "@apollo/client";
import { useHandler } from "../auth/hooks/HandlerProvider";
import Loading from "../Loading";
import { BeatLoader } from "react-spinners";

export default function HandlerBlindDates() {
  const [indexPage, setIndexPage] = useState(0);
  const { handler } = useHandler();
  const { data, loading, refetch } = useQuery(GET_BLIND_DATE_OF_HANDLER, {
    variables: {
      payload: {
        handler_id: Number(handler?.id),
      },
    },
    fetchPolicy: "no-cache",
  });

  const allDates = data?.getBlindDateWhere || [];

  return (
    <>
      {indexPage === 0 ? (
        <div className="space-y-8">
          <div className="space-y-3">
            <h2 className="text-base font-medium text-[#222E50]">
              Quick Guide
            </h2>
            <div className="border border-[#545f7d82] bg-white rounded-lg">
              <div className="flex gap-5 px-5 flex-col-reverse sm:flex-row pb-3 justify-between items-center">
                <div className="space-y-5 py-4">
                  <p className="text-sm">
                    Quickly scan through your assigned users and try to match
                    make premium users who you feel might be up for some
                    adventure. Happy Match making !!
                  </p>
                  <button
                    onClick={() => {
                      setIndexPage(1);
                    }}
                    className="px-4 gap-2 flex items-center sm:px-6 py-2 bg-[#081a51] jarkata_sans text-xs md:text-sm rounded-lg text-white font-medium"
                  >
                    Create a Date
                    <AddCircle fontSize="inherit" />
                  </button>
                </div>
                <img
                  src={ASSETS?.Bdate}
                  alt="blind date"
                  className="w-40 h-40"
                />
              </div>
            </div>
          </div>
          <div className="space-y-3">
            <div className="flex justify-between items-center">
              <h2 className="text-base font-medium text-[#222E50]">
                Match History
              </h2>
            </div>

            <div className="border border-[#545f7d82] bg-white rounded-lg">
              {loading ? (
                <div className=" min-h-[400px] flex justify-center items-center">
                  <BeatLoader size={20} color="#222E50" />
                </div>
              ) : allDates?.filter?.((i) => i?.suggested_by === "HANDLER")
                  ?.length > 0 ? (
                <div
                  style={{ minHeight: "24rem" }}
                  className="w-full min-h-96 rounded-lg relative z-10 bg-white overflow-x-auto"
                >
                  <table className="relative w-full divide-y divide-gray-1 overflow-auto  bg-white">
                    <thead className=" rounded-lg bg-gray-50 rounded-t border px-5">
                      <tr className="rounded-t-lg">
                        <th
                          scope="col"
                          className={`px-2 py-4 pl-5 text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                        >
                          <div className="flex gap-2  items-center">Theme</div>
                        </th>
                        <th
                          scope="col"
                          className={`px-2 py-4 text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                        >
                          <div className="flex gap-2  items-center">
                            Participant 1
                          </div>
                        </th>
                        <th
                          scope="col"
                          className={`px-2 py-4 text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                        >
                          Participant 2
                        </th>
                        <th
                          scope="col"
                          className={`px-2 py-4 text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                        >
                          StAtus 1
                        </th>
                        <th
                          scope="col"
                          className={`px-2 py-4 text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                        >
                          Status 2
                        </th>
                        <th
                          scope="col"
                          className={`px-2 py-4  text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                        >
                          Meeting Date
                        </th>
                        {/* <th
                          scope="col"
                          className={`px-2 py-4  text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                        >
                          Overall Status
                        </th> */}
                        {/* <th
                          scope="col"
                          className={`px-2 py-4 text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                        ></th> */}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-300 w-full overflow-auto bg-white px-5 pt-5">
                      {allDates
                        ?.filter?.((i) => i?.suggested_by === "HANDLER")
                        ?.sort?.(
                          (a, b) =>
                            //@ts-ignore
                            new Date(b?.created_at) - new Date(a?.created_at)
                        )
                        ?.map?.((item: any, index: number) => {
                          return (
                            <tr className="hover:bg-gray-100" key={index}>
                              <td
                                className={`px-2 pl-5 py-4 ${
                                  !item?.user_match_theme
                                    ? "italic font-medium text-[10px]"
                                    : "font-semibold text-xs"
                                } text-left capitalize text-[#545F7D]`}
                              >
                                {item?.user_match_theme ?? "Not Specified"}
                              </td>
                              <td className="px-2 py-4 text-left text-xs font-medium capitalize text-[#545F7D]">
                                {item?.user_match?.user_match?.last_name +
                                  " " +
                                  item?.user_match?.user_match?.first_name}
                              </td>
                              <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                                {item?.user_match?.user_matched?.last_name +
                                  " " +
                                  item?.user_match?.user_matched?.first_name}
                              </td>
                              <td
                                className={`px-2 py-4 text-left whitespace-nowrap text-xs font-normal  ${
                                  item?.match_accept
                                    ? "text-green-600"
                                    : item?.match_reject
                                    ? "text-red-600"
                                    : "text-yellow-600"
                                }`}
                              >
                                {item?.match_accept
                                  ? "Accepted"
                                  : item?.match_reject
                                  ? "Rejected"
                                  : "Pending"}
                              </td>
                              <td
                                className={`px-2 py-4 text-left whitespace-nowrap text-xs font-normal  ${
                                  item?.matched_accept
                                    ? "text-green-600"
                                    : item?.matched_reject
                                    ? "text-red-600"
                                    : "text-yellow-600"
                                }`}
                              >
                                {item?.matched_accept
                                  ? "Accepted"
                                  : item?.matched_reject
                                  ? "Rejected"
                                  : "Pending"}
                              </td>
                              <td className="px-2 py-4 text-left text-xs font-normal text-[#545F7D]">
                                {item?.user_match_meeting_time ||
                                item?.user_matched_meeting_time
                                  ? moment
                                      .utc(
                                        item?.user_match_meeting_time ||
                                          item?.user_matched_meeting_time
                                      )
                                      .format("MMM D, YYYY hh:mm A")
                                  : "Not Specified"}
                              </td>
                              {/* <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                              {item?.status?.toLowerCase?.() === "pending" ? (
                                <p className="text-[10px] px-4 py-0.5 w-max text-center rounded-full bg-yellow-100 text-yellow-800">
                                  {item?.status}
                                </p>
                              ) : item?.status?.toLowerCase?.() ===
                                "approved" ? (
                                <p className="text-[10px] px-4 py-0.5 w-max text-center rounded-full bg-green-100 text-green-800">
                                  {item?.status}
                                </p>
                              ) : (
                                <p className="text-[10px] px-4 py-0.5 w-max text-center rounded-full bg-red-100 text-red-800">
                                  {"Pending"}
                                </p>
                              )}
                            </td> */}
                              {/* <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                              <Menu
                                as="div"
                                className="w-max flex-shrink-0 relative"
                              >
                                {({ open }) => (
                                  <>
                                    <Menu.Button
                                      as="div"
                                      className="w-max text-xs sm:text-base flex gap-2 px-3 py-2 sm:py-1.5 cursor-pointer bg-white rounded-md items-center"
                                    >
                                      <MoreVert fontSize="inherit" />
                                    </Menu.Button>
                                    <Transition
                                      show={open}
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items
                                        static
                                        className="absolute normal-case p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg z-40 rounded-lg w-60 top-full right-0"
                                      >
                                        <div className=" w-full">
                                          <div className="flex cursor-pointer hover:bg-gray-200 hover:text-blue-600 text-xs font-medium gap-2 items-center px-4 py-2 rounded-lg">
                                            <People fontSize="inherit" />
                                            Re-Match Participants
                                          </div>
                                          <div className="flex cursor-pointer hover:bg-gray-200 hover:text-blue-600 text-xs font-medium gap-2 items-center px-4 py-2 rounded-lg">
                                            <Message fontSize="inherit" />
                                            Message Participant 1
                                          </div>
                                          <div className="flex cursor-pointer hover:bg-gray-200 hover:text-blue-600 text-xs font-medium gap-2 items-center px-4 py-2 rounded-lg">
                                            <Message fontSize="inherit" />
                                            Message Participant 2
                                          </div>
                                        </div>
                                      </Menu.Items>
                                    </Transition>
                                  </>
                                )}
                              </Menu>
                            </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div
                  style={{ minHeight: "400px" }}
                  className="flex h-full w-full flex-col gap-5 justify-center items-center"
                >
                  <div className=" w-16 h-16 bg-[#F5F5F5] rounded-full flex justify-center items-center">
                    <img
                      src={ASSETS?.Vido}
                      className="object-cover"
                      alt="video"
                    />
                  </div>
                  <h2 className="text-base font-semibold text-[#081A51] text-center">
                    You haven’t set up a blind date match
                  </h2>
                  <p className="text-xs w-1/2 text-center">
                    We welcome you to setup a blind date match for premium users
                    assigned to you who feel might be open to something
                    adventurous . Happy match making
                  </p>
                  <button
                    onClick={() => {
                      setIndexPage(1);
                    }}
                    className="px-4 gap-2 flex items-center sm:px-6 py-2 bg-[#081a51] jarkata_sans text-xs md:text-sm rounded-lg text-white font-medium"
                  >
                    Create a Date
                    <AddCircle fontSize="inherit" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <CreateBlindDate setIndexPage={setIndexPage} refetch={refetch} />
      )}
    </>
  );
}

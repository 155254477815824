import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CloseImage from "src/assets/close.png";

export default function SlideCardsOverlay({
  isOpen,
  setIsOpen,
  title,
  children,
  size = "responsive",
}) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={isOpen}
        onClose={() => {}}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              onClick={() => setIsOpen(false)}
              className="fixed inset-0 bg-[#000] bg-opacity-75 transition-opacity"
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block transform overflow-hidden rounded-lg bg-transparent text-left align-middle  transition-all sm:my-8 ${
                size === "small"
                  ? "w-full sm:max-w-xl"
                  : size === "medium"
                  ? "w-full sm:max-w-xl md:max-w-2xl"
                  : size === "large"
                  ? "w-full sm:max-w-xl md:max-w-2xl lg:max-w-4xl"
                  : "w-full md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl"
              }`}
            >
              <div className="absolute top-0 right-0 pt-4 pr-4 sm:pr-6">
                <button
                  type="button"
                  className="outline-none focus:outline-none bg-black rounded text-gray-500 hover:text-gray-800 focus:border-none focus:outline-0 focus:border-0 focus:ring-none focus:ring-0 focus:ring-transparent focus:ring-offset-1"
                  onClick={() => setIsOpen(false)}
                >
                  <span className="sr-only">Close</span>
                </button>
              </div>

              {title ? (
                <div className="mb-5 bg-[#081A51] py-4 px-4 sm:px-6">
                  <Dialog.Title
                    as="h3"
                    className="pr-10 text-left text-lg font-semibold leading-6 text-white sm:text-xl "
                  >
                    {title}
                  </Dialog.Title>
                </div>
              ) : null}

              <div>{children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

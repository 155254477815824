import { useCookies } from "react-cookie";
import { BackButton, PageTitle } from "src/components";
import Sidebar from "src/components/Sidebar/Sidebar";
import { DetailsWrapper, PageWrapper } from "./styles";

const UserDetails = (props) => {
  const [cookies] = useCookies(["user_authenticated"]);

  if (cookies.user_authenticated !== "true") {
    window.location.href = "/admin";
  }

  return (
    <div>
      <PageTitle
        // title="User details"
        render={
          <BackButton
            link={"/admin"}
            parent="Admin Dashboard /"
            child={"User"}
            useToolTip={false}
          />
        }
      />
      <PageWrapper>
        <DetailsWrapper>
          <Sidebar />
        </DetailsWrapper>
      </PageWrapper>
    </div>
  );
};

export default UserDetails;

import { useMutation, useLazyQuery } from "@apollo/client";
import { useCallback } from "react";
import { UPLOAD_FILE } from "src/hooks/data/mutations";
import { GET_MEDIA_FILES_BY_IDS } from "src/hooks/data/queries";

const useUploadFile = () => {
  const [uploadCurrentFile] = useMutation(UPLOAD_FILE, {
    context: {
      headers: {
        "apollo-require-preflight": true,
      },
    },
  });

  const [getMediaFilesByIds, { data: media_files }] = useLazyQuery(
    GET_MEDIA_FILES_BY_IDS
  );

  const uploadFile = useCallback(
    async (file, file_path = "") => {
      try {
        const { data } = await uploadCurrentFile({
          variables: {
            payload: { file, file_path },
          },
        });
        return { id: data.singleUpload.id, url: data.singleUpload.url };
      } catch (error) {
        console.error(error);
      }
    },
    [uploadCurrentFile]
  );

  const multipleFilesUrlObject = async (files: File[], file_path) => {
    const attachmentPayload = await Promise.all(
      files.map(async (file: File, i) => {
        const reader = new FileReader();
        const currentFile = files[i];
        const response = await uploadFile(currentFile, file_path);
        if (response) {
          const fileName: string = file.name.split(".")[0];
          return {
            id: response.id,
            fileName,
            attachmentUrl: response.url || "",
            type: file.type,
          };
        }
        reader.onloadend = async () => {};
        reader.readAsDataURL(file);
        return { id: "", fileName: "", attachmentUrl: "", type: "" };
      })
    );
    return attachmentPayload;
  };

  const getMediaFiles = async (ids: number[]) =>
    await getMediaFilesByIds({ variables: { ids } });
  return {
    uploadFile,
    multipleFilesUrlObject,
    getMediaFiles,
    media_files: media_files?.mediaEntriesByIds || [],
  };
};

export default useUploadFile;

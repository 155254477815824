import { Lock } from "@mui/icons-material";
import { useState } from "react";
const Logo =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/logo.svg";
export default function CreatePasswordUser() {
  const [indexPage, setIndexPage] = useState(0);
  return (
    <div className="bg-white w-full h-screen flex flex-col justify-center items-center">
      <div className=" flex items-center gap-5 ">
        <img src={Logo} alt="logo" className="w-40 h-20 mb-2" />
      </div>
      <div className="w-full md:w-1/2 mx-auto shadow-md rounded-lg p-5 border border-gray-300">
        <div className="w-full space-y-5 sm:w-4/5 mx-auto">
          <div>
            <h2 className="text-2xl mb-2 canela font-semibold text-[#081A51] text-center ">
              {indexPage === 0 ? "Create a password" : "Link Expired"}{" "}
            </h2>
            <p className="text-sm text-center">
              {indexPage === 0
                ? "Provide a unique password to synonymous to you to get started"
                : "You have an expired link, kindly visit your as we've re generated a new link for you. "}
            </p>
          </div>
          <div className="py-5 w-full mx-auto md:w-4/5  space-y-5">
            {indexPage === 0 ? (
              <>
                <div>
                  <label
                    htmlFor="new_password"
                    className="font-medium text-[#081A51] text-sm mb-1"
                  >
                    New Password
                  </label>
                  <div className="w-full relative">
                    <Lock
                      fontSize="small"
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        fontSize: "16px",
                      }}
                    />
                    <input
                      type="password"
                      placeholder="***********"
                      className="w-full pl-10 rounded-lg "
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="confirm_new_password"
                    className="font-medium text-[#081A51] text-sm mb-1"
                  >
                    Confirm New Password
                  </label>
                  <div className="w-full relative">
                    <Lock
                      fontSize="small"
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        fontSize: "16px",
                      }}
                    />
                    <input
                      type="password"
                      placeholder="***********"
                      className="w-full pl-10 rounded-lg "
                    />
                  </div>
                </div>
                <div className="pt-5">
                  <button
                    onClick={() => setIndexPage(1)}
                    className="px-3 rounded-lg w-full bg-[#081A51] py-2.5 text-center font-semibold text-white"
                  >
                    Continue
                  </button>
                </div>
              </>
            ) : (
              <button
                onClick={() => setIndexPage(0)}
                className="px-3 rounded-lg w-full bg-[#081A51] py-2.5 text-center font-semibold text-white"
              >
                Open Mail
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import { useMutation, useLazyQuery } from "@apollo/client";
import { USER_SIGN_UP } from "../data/mutation";
import { VERIFY_EMAIL_ADDRESS, VERIFY_PHONE_NUMBER } from "../data/queries";

const useSignUp = () => {
  const [signUp, { data, loading, error }] = useMutation(USER_SIGN_UP);

  const [verifyEmail, { data: is_email_availabe_data }] =
    useLazyQuery(VERIFY_EMAIL_ADDRESS);

  const [verifyNumber, { data: is_number_availabe_data }] =
    useLazyQuery(VERIFY_PHONE_NUMBER);

  const verifyEmailAddress = (email: string) =>
    verifyEmail({ variables: { email: email } });

  const verifyPhoneNumber = (phone_number: string) =>
    verifyNumber({ variables: { number: phone_number } });

  return {
    signUp,
    sign_up_loading: loading,
    data,
    loading,
    error,
    verifyEmailAddress,
    verifyPhoneNumber,
    is_email_availabe_data,
    is_number_availabe_data,
  };
};

export default useSignUp;

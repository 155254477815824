import { useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useHandler } from "src/components/auth/hooks/HandlerProvider";
import { useUser } from "src/components/auth/hooks/UserProvider";
import { HANDLER_LOGIN } from "src/hooks/data/queries";

export default function HandlerDashboard() {
  const navigate = useNavigate();
  const [_, setCookie] = useCookies(["user_authenticated"]);
  // const [searchTerm, setSearchTerm] = useState("");
  const { logoutUser } = useUser();
  const { setHandler } = useHandler();
  const [user_name, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const step_complete = useMemo(
    () => !!user_name.length && !!password.length,
    [password.length, user_name.length]
  );

  const titleRef = useRef<HTMLInputElement | null>(null);
  const [titleDivHeight, setTitleDivHeight] = useState<any>(0);
  const [save_modal, setSaveModal] = useState<boolean>(true);
  const toggleModal = () => {
    setSaveModal(false);
    window.location.href = "/";
  };

  const [adminLogin] = useMutation(HANDLER_LOGIN);

  useEffect(() => {
    const localState = localStorage.getItem("handlerToken");
    if (localState) {
      navigate("/dashboard/handler");
    }
  }, [navigate]);

  const handleSubmit = (e: any) => {
    e?.preventDefault();
    handleClick();
  };

  const handleClick = useCallback(() => {
    setLoading(true);
    adminLogin({
      variables: {
        payload: {
          user_name,
          password,
        },
      },
      onCompleted: (data) => {
        if (data?.handlerLogin) {
          const json_handler_data = JSON.stringify({ ...data?.handlerLogin });

          setCookie("user_authenticated", json_handler_data, {
            maxAge: 43200,
          });
          setLoading(false);
          logoutUser?.();
          setHandler(data?.handlerLogin);
          navigate("/dashboard/handler");
        } else {
          setCookie("user_authenticated", "");
          setLoading(false);
          toast.error("Handler not found, kindly contact super admin");
        }
      },
      onError: (error) => {
        setLoading(false);
        toast.error(error?.message ?? "Network Error, Try again later");
      },
    });
    // onClose()
  }, [adminLogin, navigate, password, setCookie, user_name]);

  return (
    <>
      <Transition.Root show={save_modal} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-50"
          open={save_modal}
          onClose={() => {}}
        >
          <div className="flex items-end justify-center min-h-screen text-center sm:block">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              afterEnter={() => {
                const refing = titleRef?.current?.clientHeight;
                setTitleDivHeight(refing);
              }}
              // afterLeave={()=>{}}
            >
              <div
                style={{ background: "#00000094" }}
                className="h-screen w-full inline-block text-left transform transition-all align-middle"
              >
                <div
                  className="mx-auto relative"
                  style={{ maxWidth: "1920px" }}
                >
                  <div className="absolute top-0 right-0 pr-4 sm:pr-6 pt-4 sm:pt-5">
                    <button
                      type="button"
                      className="text-gray-500 hover:text-gray-800 focus:outline-none focus:ring-0"
                      onClick={() => toggleModal()}
                    >
                      <span className="sr-only">Close</span>
                      <i className="fa-regular hidden fa-circle-xmark"></i>
                    </button>
                  </div>

                  <div
                    ref={titleRef}
                    className="px-4 sm:px-6 py-4 sm:py-5"
                  ></div>

                  <div
                    className="px-4 sm:px-6 pt-1 sm:pt-2 pb-5"
                    style={{
                      height: `calc(100vh - ${titleDivHeight}px)`,
                      overflowY: "auto",
                    }}
                  >
                    {/* content */}
                    <div className="w-full h-full flex justify-center items-center">
                      <Dialog.Panel className="w-full space-y-5 max-w-md -mt-32 transform relative overflow-hidden rounded-3xl bg-white px-6 py-16 flex flex-col items-center align-middle shadow-xl transition-all">
                        <form
                          onSubmit={handleSubmit}
                          className="space-y-5 md:space-y-7"
                        >
                          <h2 className="text-center text-lg font-semibold">
                            Handler Login
                          </h2>
                          <div className="grid gap-7 grid-cols-1 lg:grid-cols-2">
                            <div className="w-full">
                              <label
                                className="text-xs mb-3 pl-5 block font-medium"
                                htmlFor="user_name"
                              >
                                User Name
                              </label>
                              <input
                                required={true}
                                placeholder="Enter User Name"
                                className="px-5 py-2.5 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl"
                                onChange={(e) => setUserName(e.target.value)}
                                value={user_name}
                              />
                            </div>
                            <div className="w-full">
                              <label
                                className="text-xs mb-3 pl-5 block font-medium"
                                htmlFor="password"
                              >
                                Password
                              </label>
                              <input
                                required={true}
                                placeholder="password"
                                className="px-5 py-2.5 appearance-none border-0 ouline-0 focus:border-0 focus:outline-0 ring-2 focus:ring-4 ring-brand-1 bg-transparent focus:ring-brand-2 w-full rounded-3xl"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                type={"password"}
                              />
                            </div>
                          </div>
                          <button
                            type="submit"
                            className={`py-2.5 block w-full font-semibold text-white ${
                              Boolean(!step_complete)
                                ? "bg-disabled-1"
                                : "bg-brand-1"
                            } rounded-3xl`}
                            disabled={Boolean(!step_complete) || loading}
                          >
                            {loading ? (
                              <BeatLoader size={10} color="#fff" />
                            ) : (
                              "Log In"
                            )}
                          </button>
                        </form>
                      </Dialog.Panel>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

import { CircularProgress } from '@mui/material'
import { FC, memo } from 'react'
import { ButtonCustomProps, StyledButton } from './Button.styles'

interface ButtonProps extends ButtonCustomProps {
  isLoading?: boolean
  textColor?: string
  backgroundColor?: string
  borderColor?: string
  className?: string
  // style?: object
}

const ButtonComp: FC<ButtonProps> = memo(
  ({
    variant = 'contained',
    disabled = false,
    size = 'medium',
    children,
    isLoading,
    textColor,
    backgroundColor,
    borderColor,
    type,
    onClick,
    fullWidth,
    startIcon,
    endIcon,
    className,
  }: // style,
  ButtonProps) => {
    return (
      <StyledButton
        size={size}
        variant={variant}
        disabled={disabled || isLoading}
        textColor={textColor}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        type={type}
        onClick={onClick}
        fullWidth={fullWidth}
        startIcon={startIcon}
        endIcon={endIcon}
        className={className}
        // style={style}
      >
        {isLoading ? <CircularProgress size={14} /> : children}
      </StyledButton>
    )
  },
)

export default ButtonComp

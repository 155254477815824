import { gql, useMutation } from "@apollo/client";

export const UPLOAD_FILE = gql`
  mutation SingleUpload($payload: SingleUploadPayload!) {
    singleUpload(payload: $payload) {
      id
      url
      status
    }
  }
`;
export const UPDATE_USER_MEDIA = gql`
  mutation UpdateUserMedia($payload: UpdateUserMediaPayload) {
    updateUserMedia(payload: $payload) {
      media {
        url
        id
      }
    }
  }
`;

export const DELETE_REQUEST = gql`
  mutation DeleteRequest($payload: DeleteRequestPayload) {
    deleteRequest(payload: $payload)
  }
`;

export const SUBSCRIBE_REQUEST = gql`
  mutation SubscribeUser($email: String!) {
    subscribeUser(email: $email)
  }
`;
export const CREATE_SUGGESTED_MATCHES = gql`
  mutation CreateHandlerUserMatch($payload: CreateHandlerUserMatchPayload) {
    createHandlerUserMatch(payload: $payload) {
      id
      updated_at
      user_match {
        id
      }
      user_match_status
      user_matched_status
      user_matched {
        id
        first_name
        profile_image_url
      }
    }
  }
`;

export const SEND_HANDLER_MESSAGE = gql`
  mutation CreateOutgoingHandlerMessage(
    $message: CreateOutgoingHandlerMessageInput
  ) {
    createOutgoingHandlerMessage(message: $message) {
      id
      media {
        id
        url
      }
      body
      created_at
      message_sender
      status
      subject
      message_read
      deleted
    }
  }
`;

export const RATE_HANDLER = gql`
  mutation RateHandler($userId: Int!, $rating: Int!, $handlerId: Int) {
    rateHandler(user_id: $userId, rating: $rating, handler_id: $handlerId) {
      created_at
      handler {
        id
        user_name
      }
      rating
      updated_at
      user {
        id
        last_name
      }
    }
  }
`;

export const REPORT_ISSUE = gql`
  mutation ReportIssue($message: String!, $userId: Int!, $subject: String) {
    reportIssue(message: $message, user_id: $userId, subject: $subject) {
      created_at
      deleted
      deleted_at
      id
      message
      subject
    }
  }
`;
export const RESEND_PASSWORD = gql`
  mutation Mutation($handlerId: Int!, $userId: Int!) {
    resendPassword(handler_id: $handlerId, user_id: $userId)
  }
`;

export const SEND_MESSAGE = gql`
  mutation Mutation($message: CreateOutgoingMessageInput) {
    createOutgoingMessage(message: $message) {
      body
      conversation {
        id
        lastMessageSentAt
        unreadMessageCount
      }
      from {
        id
        first_name
        last_name
      }
      to {
        id
        first_name
        last_name
      }
      is_inbound
      message_type
      status
      subject
      created_at
    }
  }
`;
export const RAISE_BLACKLIST = gql`
  mutation BlacklistUser($handlerId: Int!, $userId: Int!, $reason: String!) {
    blacklistUser(handler_id: $handlerId, user_id: $userId, reason: $reason) {
      approved
      created_at
      deleted
      deleted_at
      id
      reason
      updated_at
      user {
        id
        first_name
        last_name
      }
    }
  }
`;
export const STRIKE_USER = gql`
  mutation Mutation($handlerId: Int!, $userId: Int!, $reason: String!) {
    strikeUser(handler_id: $handlerId, user_id: $userId, reason: $reason) {
      reason
      created_at
      deleted
      deleted_at
      id
      updated_at
      user {
        id
        first_name
        last_name
      }
    }
  }
`;
export const RESET_PASSWORD = gql`
  mutation Mutation($userId: Int!, $oldPassword: String!, $password: String!) {
    resetPassword(
      user_id: $userId
      old_password: $oldPassword
      password: $password
    )
  }
`;
export const REQUEST_PASSWORD = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email)
  }
`;
export const RESET_NEW_PASSWORD = gql`
  mutation ForgetPassword($email: String!, $code: String!, $password: String!) {
    forgetPassword(email: $email, code: $code, password: $password)
  }
`;
export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification($payload: UpdateNotificationPayload) {
    updateNotification(payload: $payload) {
      id
      destination_id
      is_archived
      is_read
      title
      type
      updated_at
      description
      deleted_at
      deleted
      created_at
      action_url
    }
  }
`;
export const DELETE_ACCOUNT = gql`
  mutation DeleteUser($deleteUserId: Int!) {
    deleteUser(id: $deleteUserId)
  }
`;

export const CANCEL_PAYSTACK_SUB = gql`
  mutation CancelPaystack($payload: cancelPaystackSubPayload) {
    cancelPaystack(payload: $payload)
  }
`;
export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($deleteNotificationId: Int!) {
    deleteNotification(id: $deleteNotificationId) {
      action_url
      created_at
      deleted
      deleted_at
      description
      destination_id
      id
      is_archived
      is_read
      title
      type
      updated_at
    }
  }
`;

export const USER_STATUS_ACTIONED = gql`
  mutation UserStatusActioned(
    $isAvailable: Boolean
    $isOnline: Boolean
    $userId: Int
  ) {
    userStatusActioned(
      isAvailable: $isAvailable
      isOnline: $isOnline
      user_id: $userId
    ) {
      isAvailable
      isOnline
      lastOnline
      user_id
    }
  }
`;
export const USER_BOOK_THERAPIST = gql`
  mutation CreateBooking($payload: CreateBookingPayload) {
    createBooking(payload: $payload) {
      id
      user {
        id
        first_name
        last_name
      }
      therapist {
        id
        name
      }
      meetingLink
      meetingId
      meetingHeld
      status
      meetingDeleted
      sessionDate
      created_at
      updated_at
      deleted
      deleted_at
    }
  }
`;
export const CANCEL_USER_BOOK_THERAPIST = gql`
  mutation CancelBooking($cancelBookingId: Int!) {
    cancelBooking(id: $cancelBookingId) {
      id
      meetingLink
      status
    }
  }
`;
export const UPDATE_USER_BOOK_THERAPIST = gql`
  mutation UpdateBooking(
    $updateBookingId: Int!
    $payload: UpdateBookingPayload
  ) {
    updateBooking(id: $updateBookingId, payload: $payload) {
      id
      user {
        first_name
      }
      therapist_review
      user_review_subject
      user_review_message
    }
  }
`;

export function useUpdateUserLoginStatus() {
  return useMutation(USER_STATUS_ACTIONED);
}

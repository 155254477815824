import ASSETS from "src/asset";

export default function SingleTherapist({
  therapist,
  isSelectedTherapist,
  index,
  handleCheckClick,
}) {
  return (
    <div
      className={`relative flex w-72 cursor-default rounded-2xl bg-white shadow-md sm:w-80 xl:w-96 2xl:w-[400px] ${
        isSelectedTherapist ? "" : ""
      }`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="z-10 flex h-full w-full flex-col justify-between space-y-5 rounded-2xl p-3 ">
        <div className="w-full h-60 rounded-lg">
          <img
            src={therapist?.imageUrl ?? ASSETS?.Avi}
            alt=""
            className="h-full w-full rounded-lg object-cover object-top"
          />
        </div>
        <div className="">
          <p className=" font-bold text-center">{therapist?.name}</p>
          {/* <p className="text-xs font-medium text-gray-600">
            {therapist?.personaSummary}
          </p> */}
        </div>
        {/* <div className="grid grid-cols-2 gap-1">
          {therapist?.specialties?.length > 0 ? (
            therapist?.specialties?.slice?.(0, 2)?.map((tag, idx) => (
              <p
                key={idx}
                className={` rounded w-full truncate block border ${
                  idx === 0
                    ? "border-gray-600 bg-gray-200 text-gray-600"
                    : "border-purple-600 bg-purple-200 text-purple-600"
                } px-2 py-0.5 whitespace-nowrap text-xs font-medium  `}
              >
                {tag}
              </p>
            ))
          ) : (
            <p className="text-xs font-medium italic">No tags available</p>
          )}
        </div> */}
        {isSelectedTherapist && (
          <div className="text-center">
            <button
              onClick={() => {
                handleCheckClick();
              }}
              className="border border-gray-300 hover:border-[#FE6D68] hover:bg-[#FE6D68] hover:text-white px-4 py-2 rounded-lg text-sm"
            >
              Check Availability
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

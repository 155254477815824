import { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import SignUpForm from "src/components/auth/SignUpForm";
import Header from "src/components/header/Homeheader";

export default function Signup() {
  const [authPage, setAuthPage] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  // get id from useParams and get state
  const { id } = useParams();
  const plan = id?.replace("-", " ");
  // @ts-ignore
  const plan_cost = location?.state?.plan_cost || "";
  // @ts-ignore
  const currency_code = location?.state?.currency_code || "";
  // @ts-ignore
  const plan_duration = location?.state?.plan_duration || "";
  // @ts-ignore
  const userPreData = location?.state?.payload || {};

  useEffect(() => {
    //@ts-ignore
    if (!location?.state?.payload || !location?.state?.payload?.email) {
      navigate("/pre-verify");
    }
  }, [location]);

  return (
    <div className="h-screen">
      <Header />
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="lg:w-2/5 space-y-5 p-5">
            <h2
              data-aos="fade-up"
              className="uppercase text-xs md:text-base text-brand-red-1 font-semibold"
            >
              Complete Profile
            </h2>
            <h2
              data-aos="fade-up-right"
              className="text-2xl text-brand-1 sm:text-4xl font-medium"
            >
              <span className="font-semibold">Find</span> people with similar
              interests and <span className="font-semibold">build</span> your
              social network
            </h2>
            <p className="text-lg pt-5 font-medium">
              You chose the{" "}
              {
                <span className="text-brand-red-1 capitalize font-semibold">
                  {plan}
                </span>
              }{" "}
              plan
            </p>
          </div>
          <div className="lg:w-1/2 p-5 space-y-10">
            <div className="flex gap-5 items-center">
              <span className="w-5 h-5 rounded-full bg-brand-1"></span>
              <p className="text-xs">
                Step {authPage} of 3 -{" "}
                {plan === "basic" && authPage !== 3
                  ? "Your bio"
                  : "Your preferences"}
              </p>
            </div>
            <SignUpForm
              authPage={authPage}
              setAuthPage={setAuthPage}
              plan={plan}
              plan_cost={plan_cost}
              currency_code={currency_code}
              plan_duration={plan_duration}
              userPreData={userPreData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import { useNavigate } from "react-router-dom";
import { KeyboardBackspace } from "@mui/icons-material";

const Logo =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/logo.svg";

const Vector =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/vector1.svg";

const TermsAndCondition = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full relative overflow-hidden h-full">
      <nav className="flex w-full fixed top-0 left-0 justify-between shadow-md bg-white px-10 py-3">
        <img
          onClick={() => {
            navigate("/");
          }}
          src={Logo}
          className="w-32 h-14 cursor-pointer items-center"
        />
        {/* <div className="w-2/5 flex justify-around items-center max-lg:hidden">
          <span className="text-[#081A51] text-xs font-bold">
            Privacy Policy{" "}
          </span>
          <span className="text-[#081A51] text-xs font-bold">
            Cookies Policy{" "}
          </span>
          <span className="text-[#081A51] text-xs font-bold">
            Terms and Conditions{" "}
          </span>
        </div>
        <img src={Vector} className="w-8 h-8 lg:hidden items-center mt-3" /> */}
      </nav>
      <div className="h-full py-28 space-y-5 px-5 md:px-10 overflow-y-auto">
        <h2 className="text-base font-bold sm:text-lg lg:text-2xl text-center playfair">
          THE TERMS & CONDITION OF USE FOR NIRVANAE’s DATING APPLICATION/WEBSITE
        </h2>
        <div className="space-y-8 text-[#081A51]">
          <div className="space-y-5">
            <h2 className="text-sm md:text-lg font-semibold">Introduction</h2>
            <p className="jarkata_sans">
              This is a legal agreement ("Agreement") between you (“the user”)
              and Nirvanae Global Investment, a company, duly incorporated under
              all applicable Nigerian laws with RC NO. 1579243 and operative
              from Nigeria. ("Nirvanae”, “us”, “our”, or “we”). This Agreement
              applies to the access and use of the Nirvanae Application,
              including mobile and online versions and the download and use of
              any of our applications (including; "iOS and Android
              applications”), and registration to or use of any of the services
              provided by us through the aforementioned platforms (collectively,
              with the Websites and Applications each, being either a “Service”
              or the “Services”). By using any of the Services, you agree to,
              and are bound by, these following terms and conditions which shall
              serve as the operative contract, for as long as you continue to
              use the Services provided by us via this platform or any connected
              platforms at present or in the future.
            </p>
            <h2 className="text-xs md:text-base font-medium">
              KINDLY NOTE THAT WHERE YOU PROCEED TO USE THIS
              WEBSITE/APPLICATION, IT WILL SERVE AS AN IMPLICATION OF YOUR
              INDICATED INTEREST TO BE BOUND BY THE PROVISIONS OF THIS
              AGREEMENT.
            </h2>
            <p className="jarkata_sans">
              Your use of, continued use of, or decision to participate in the
              services provided on this website/application, may be subject to
              additional terms, and such terms will be either as listed in this
              Agreement or will be presented for your subsequent review and
              acceptance when you sign up for the use of this website or our
              affiliate platform’s services which are incorporated in this
              agreement or may be subsequently incorporated owing to change of
              policies, or a need to review the terms of use for this platform.
            </p>
          </div>
          <div className="space-y-5">
            <h2 className="text-sm md:text-lg font-semibold">
              ABOUT THE PLATFORM
            </h2>
            <p className="jarkata_sans">
              We are a matchmaking/dating platform in Africa, with focus on
              building meaningful relationships between corporates and business
              class individuals. From zero members and subscribers in 2017, we
              have grown to over 80 thousand members. We are a creatively
              distinctive brand in Africa, as we have over the course of our
              carefully curated and personally streamlined services, recorded up
              to 36 marriages. We eliminate metrics like location, age, height,
              genotype, working status so that all that is left for a
              prospective to bother about is emotional connectivity with whoever
              they are paired with.
            </p>
          </div>
          <div className="space-y-5">
            <h2 className="text-sm md:text-lg font-semibold">
              REVIEW OF TERMS
            </h2>
            <p className="jarkata_sans">
              This Terms and Condition of use which may be interchangeably
              referred to as the “Agreement” is subject to change by Nirvanae
              Global Investment Limited (“The Company”) in its sole discretion
              at any given time. And as such, we (The Company) will notify you
              of any such changes by posting an updated version of the Agreement
              on this page and we may opt to send in a notification to your
              designated email address, where possible/ practicable; This is why
              we encourage you to regularly familiarize yourself with the
              content of this agreement as Your continued use of the Services
              after the posting of a reviewed terms to this Agreement will
              constitute your acceptance of such terms.
            </p>
          </div>
          <div className="space-y-5">
            <h2 className="text-sm md:text-lg font-semibold">VERIFICATION</h2>
            <p className="jarkata_sans">
              We make it possible for Users to enjoy a free signup to our unique
              services. However, app usage is very minimal regarding things like
              sending roses, sending and receiving bracelets, sending likes,
              Rage moves. The app constantly prompts the user to get verified in
              order to see suggestions and be introduced to a wider range of
              people.
            </p>
            <p className="jarkata_sans">Verification Key submissions:</p>
            <p className="jarkata_sans font-medium"> - Work ID</p>
            <p className="jarkata_sans font-medium">
              - Link to your Instagram, Facebook, and LinkedIn
            </p>
            <p className="jarkata_sans font-medium">
              - National ID card or any valid means of identification
            </p>
            <p className="jarkata_sans">
              A wait time is imposed so that AI may review profiles within.
              Instagram and Facebook and LinkedIn links are required so that we
              don’t end up pairing you with coworkers. You give the app
              permission to review contacts in these apps so that it can
              automatically avoid them when suggesting pairings for you.
            </p>
            <div className="space-y-5 pt-5">
              <h2 className="text-sm md:text-lg font-semibold">
                1. ELIGIBILITY
              </h2>
              <div className="space-y-5 pl-5">
                <div className="flex items-start gap-2">
                  <span>a.</span>
                  <div>
                    <h2 className="text-sm md:text-lg font-semibold">
                      Minimum Age:
                    </h2>
                    <p className="jarkata_sans">
                      As a user, you must be at least 18 years old or at the
                      legally appointed age of adulthood within your Country, to
                      register for the services offered on this platform. Your
                      continued use of the website/application, is an indication
                      of your representation to be within this minimum age range
                      (18 years old) or your Country’s legally appointed age for
                      the attainment of adulthood.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <span>b.</span>
                  <div>
                    <h2 className="text-sm md:text-lg font-semibold">
                      Marital Status:
                    </h2>
                    <p className="jarkata_sans">
                      Your use of the website/application, affirms that you have
                      provided your current status, either to be single,
                      married, or legally separated. Please note that in this
                      regard, Nirvanae will not be responsible and cannot
                      therefore be used as a reason for marital separation or
                      dissolution, or any other form of issues affecting any of
                      your pre or currently existing relationships, either at
                      the time of sign up, continued usage or at any time
                      thereafter.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <span>c.</span>
                  <div>
                    <h2 className="text-sm md:text-lg font-semibold">
                      Criminal History:
                    </h2>
                    <p className="jarkata_sans">
                      Nirvanae does not serve as a platform to link criminals
                      such as sexual offenders or of any kind to other members
                      of the society. Therefore, you agree that your use of the
                      website/application, stands as a firm representation that
                      you are not in any way affiliated with any criminal
                      proceedings or a convict of any indictable offense in any
                      part of the World. Notwithstanding, Nirvanae, may opt in
                      warring circumstances to conduct background checks on
                      representations made and may use the information obtained
                      in determining your continued use of the
                      website/application and this shall not count towards any
                      acts of discrimination whatsoever.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-5 pt-5">
              <h2 className="text-sm md:text-lg font-semibold">
                2. USE OF SERVICES
              </h2>
              <div className="space-y-5 pl-5">
                <div className="flex items-start gap-2">
                  <span>a.</span>
                  <div>
                    <h2 className="text-sm md:text-lg font-semibold">
                      Geographic Limitations:
                    </h2>
                    <p className="jarkata_sans">
                      Although Nirvanae is registered under Nigerian Laws, the
                      services provided on the Nirvanae website/Application, is
                      accessible to users all over the world, and as such,
                      subject to transnational regulations and limitations
                      imposed in the jurisdiction of use.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <span>b.</span>
                  <div>
                    <h2 className="text-sm md:text-lg font-semibold">
                      Submitted Information:
                    </h2>
                    <p className="jarkata_sans">
                      To the extent permitted by law, you are solely responsible
                      for, and assume all liability regarding, <br />
                      i. The information and content you contribute to the
                      Services; <br />
                      ii. The information and content you post, transmit,
                      publish, or otherwise make available (hereinafter "post")
                      through the Services; and <br />
                      iii. Your interactions with other Registered Users through
                      the Services. You warrant and represent that all
                      information provided to Nirvanae through our Services or
                      otherwise will be truthful, accurate, and complete, and
                      will be submitted only for lawful purposes. Details of how
                      Nirvanae may use information you provide or post which is
                      personal to you are set out in our Privacy Policy.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <span>c.</span>
                  <div>
                    <h2 className="text-sm md:text-lg font-semibold">
                      Assumption of Risk and Precautions:
                    </h2>
                    <p className="jarkata_sans">
                      You assume all risk when using the services provided by
                      the use of our websites or other platforms, including but
                      not limited to all of the risks associated with any online
                      or offline interactions with others, including dating. You
                      agree to take all necessary precautions when meeting
                      individuals through our provided range service.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <span>d.</span>
                  <div>
                    <h2 className="text-sm md:text-lg font-semibold">
                      No Guarantees:
                    </h2>
                    <p className="jarkata_sans">
                      The Nirvanae website, its mobile Applications, or other of
                      its affiliate platforms may not be able to provide partner
                      suggestions for everyone seeking to use its Services.
                      Further, Nirvanae makes no guarantees as to the number or
                      frequency of partner suggestions or matches through its
                      services, or to users’ ability, desire or criteria to
                      communicate with other users. You understand that Nirvanae
                      makes no guarantees, either express or implied, regarding
                      your ultimate compatibility with individuals you meet
                      through its provided services or as to the conduct of such
                      individuals.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <span>e.</span>
                  <div>
                    <h2 className="text-sm md:text-lg font-semibold">
                      Violation Reports:
                    </h2>
                    <p className="jarkata_sans">
                      All reports of violation may be made, by contacting our
                      customer service centers available in our contact
                      information. This report may include the violation of both
                      registered and unregistered users, as it affects your use
                      of the platforms.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <span>f.</span>
                  <div>
                    <h2 className="text-sm md:text-lg font-semibold">
                      Removal of content:
                    </h2>
                    <p className="jarkata_sans">
                      Nirvanae reserves the right, but has no obligation, to
                      monitor the information or material you submit to the
                      Services or post in the public areas of the Services.
                      Nirvanae will have the right to remove any such
                      information or material that in its sole opinion violates,
                      or may violate, any applicable law or either the letter or
                      spirit of this Agreement or upon the reasonable request of
                      any third party. Nirvanae further reserves the right to
                      remove partner suggestions or matches previously delivered
                      to you, in its reasonable discretion, in order to assure
                      that you have a quality experience on the Services.
                      Nirvanae may also automatically delete unanswered messages
                      sent by or to profiles that have been identified as spam
                      or romance scamming or have otherwise deleted.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <span>g.</span>
                  <div>
                    <h2 className="text-sm md:text-lg font-semibold">
                      Restrictions:
                    </h2>
                    <p className="jarkata_sans">
                      Ever user of the Nirvanae website/Application, or
                      Affiliate Platforms, is obligated to observe, basic
                      courtesy, and may not post, transmit or communicate
                      contents considered to be confidential or any links
                      connected thereto with any third party, whether registered
                      or unregistered. The following information are hereby
                      considered to be restricted; Information that: <br />
                      i. promotes racism, bigotry, hatred or physical harm of
                      any kind against any group or individual;
                      <br />
                      ii. is intended to or tends to abuse, harass, threaten or
                      intimidate any other users of the Services;
                      <br />
                      iii. is defamatory, intentionally inaccurate, abusive,
                      obscene, profane, offensive, sexually charged, obscene or
                      otherwise objectionable or unlawful;
                      <br />
                      iv. Infringes the intellectual property right of Nirvanae
                      or any third-party including copyright in and to content
                      (e.g., music, movies, videos, photographs, images,
                      software, literary works, etc.);
                      <br />
                      v. contains video, audio photographs, or images of another
                      person;
                      <br />
                      vi. promotes or enables illegal or unlawful activities,
                      such as instructions on how to make or buy illegal weapons
                      or drugs, violate someone's privacy, harm or harass
                      another person, steal someone else’s identity, create or
                      disseminate computer viruses, or circumvent copy-protect
                      devices;
                      <br />
                      vii. is false or misrepresentative or otherwise intended
                      to defraud, swindle or deceive other users of the
                      Services;
                      <br />
                      viii. contains viruses, ransomware, spyware, adware, time
                      bombs, cancel bots, worms or other harmful, or disruptive
                      codes, components or devices;
                      <br />
                      ix. promotes or solicits involvement in or support of a
                      political platform, religion, cult, or sect;
                      <br />
                      x. disseminates another person's personal information
                      without his or her permission, or collects or solicits
                      another person's personal information for commercial or
                      unlawful purposes;
                      <br />
                      xi. is off-topic, meaningless, or otherwise intended to
                      annoy or interfere with others' enjoyment of the Services;
                      <br />
                      xii. impersonates, or otherwise misrepresents affiliation,
                      connection or association with, any person or entity;
                      <br />
                      xiii. solicits gambling or engages in any gambling or
                      similar activity;
                      <br />
                      xiv. uses scripts, bots or other automated technology to
                      access the Services;
                      <br />
                      xv. uses the Services for chain letter, junk mail or spam
                      e-mails;
                      <br />
                      xvi. collects or solicits personal information about
                      anyone under 18; or
                      <br />
                      xvii. Is in any way used for or in connection with
                      spamming, phishing, trolling, or similar activities.
                    </p>
                  </div>
                </div>
                <h2 className="text-xs md:text-base font-medium">
                  Kindly note the above shall not be construed as being a
                  restriction on the right of the user to the Freedom of
                  Expression as guaranteed under applicable laws.
                </h2>
                <div className="flex items-start gap-2">
                  <span>h.</span>
                  <div>
                    <h2 className="text-sm md:text-lg font-semibold">
                      False Information:
                    </h2>
                    <p className="jarkata_sans">
                      On no account, may any user both registered and
                      unregistered, provide misleading or false information to
                      Nirvanae or to any other user. If information provided is
                      at any point is considered to be false, Nirvanae reserves
                      the right to terminate your use of the platform.
                      Subsequently all changes and adjustments made to existing
                      information, must be promptly communicated to Nirvanae.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <span>i.</span>
                  <div>
                    <h2 className="text-sm md:text-lg font-semibold">
                      Payment Plans:
                    </h2>
                    <p className="jarkata_sans">
                      We accept online payments for purchases as specified at
                      the point of payment. Your payment method will be charged
                      as stated at the point of purchase. If you have selected a
                      payment plan, you agree to the payment plan as stated at
                      the point of purchase. All prices reflect cost in a
                      predetermined currency value which is discretionary. You
                      represent and warrant that you are authorized to use the
                      payment method you select, and you agree to indemnify and
                      hold harmless Nirvanae and any and all of its
                      representatives from any loss or damages incurred from any
                      misrepresentation of the above.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <span>j.</span>
                  <div>
                    <h2 className="text-sm md:text-lg font-semibold">
                      Indemnity and Limitation of liability:
                    </h2>
                    <p className="jarkata_sans">
                      You agree to hold Nirvanae and any and all of its
                      representatives harmless from any and all losses, damages,
                      rights, claims and actions of any kind arising from or
                      related to the Site, content or related services,
                      including but not limited to: (a) telephone, electronic,
                      hardware or software, network, Internet or computer
                      malfunctions, failures or difficulties of any kind; (b)
                      failed, incomplete, garbled or delayed computer
                      transmissions; (c) any condition caused by events beyond
                      the control of Dating with Confidence that may cause the
                      Site or services to be disrupted or corrupted; (d) any
                      injuries, losses or damages of any kind arising in
                      connection with or as a result of your use of the Site,
                      content or related services. This includes any attorney’s
                      fees and costs related to the defense of any claim related
                      to Nirvanae or any of its products, services, or
                      representatives. All Site content and all products are
                      considered “as is” and do not carry warranties of any
                      kind.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <span>k.</span>
                  <div>
                    <h2 className="text-sm md:text-lg font-semibold">
                      Cancellation and suspension of account:
                    </h2>
                    <p className="jarkata_sans">
                      Nirvanae as the operator of its website/Application,
                      reserves the right to cancel and suspend any accounts
                      where in its discretionary opinion, it stands against the
                      aims and objectives the services on any or all of its
                      platforms.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <span>l.</span>
                  <div>
                    <h2 className="text-sm md:text-lg font-semibold">
                      Our Refund Policy:
                    </h2>
                    <p className="jarkata_sans">
                      Here at Nirvanae, we're all about creating sparks and
                      finding your perfect match! While we pour our hearts and
                      expertise into every matchmaking endeavor, we also believe
                      in keeping things lighthearted and straightforward, just
                      like your journey to finding love should be. No
                      Takebacksies Policy: Once you've embarked on the thrilling
                      adventure of matchmaking with us, we're in it together!
                      While we can't promise a happily ever after for everyone,
                      we do promise to put our best cupid foot forward in
                      finding your ideal partner. Love Don't Cost a Thing: We're
                      all about spreading love, not refunds. Since our
                      matchmakers dedicate their time and energy to handcrafting
                      personalized connections just for you, we kindly ask that
                      you embrace the journey with us, no refunds necessary.
                      Sparks Are Non-Refundable: While we can't guarantee
                      fireworks with every match, we can promise you'll always
                      have a blast! So, let's embrace the excitement of the
                      unknown and see where the matchmaking magic takes us.
                      Cancellation Policy: If for any reason you decide to
                      cancel your matchmaking journey with us, we totally
                      understand! Just keep in mind that, like true love,
                      refunds are a rare gem in our world. However, we'll do our
                      best to accommodate your request with a warm smile and a
                      heartfelt farewell. In Case of Emergency Love: In the
                      unlikely event of a love emergency, please contact our
                      customer care cupids, and we'll be happy to assist you
                      with any concerns or further questions you may have.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-5 pt-5">
              <h2 className="text-sm md:text-lg font-semibold">
                3. TRADEMARKS
              </h2>
              <div className="space-y-5 pl-5">
                <p className="jarkata_sans">
                  Nirvanae is trademarked and may not be used in connection with
                  any product or service that is not part of Nirvanae in any
                  manner that is likely to cause confusion among clients, or in
                  any manner that disparages or discredits Dating with
                  Confidence. Any other trademarks that appear on our Site are
                  the property of their respective owners, who may or may not be
                  affiliated with, connected to, or sponsored by Nirvanae.
                </p>
              </div>
            </div>
            <div className="space-y-5 pt-5">
              <h2 className="text-sm md:text-lg font-semibold">
                4. RIGHT TO REPORT ABUSE
              </h2>
              <div className="space-y-5 pl-5">
                <p className="jarkata_sans">
                  All users may report abuse to the customer care contact lines
                  provided on the platforms, by sending an email to the provided
                  address, details on the abuse may be attached to aid
                  appreciation of facts constituting the abuse.
                </p>
              </div>
            </div>
            <div className="space-y-5 pt-5">
              <h2 className="text-sm md:text-lg font-semibold">
                5. LIMITED WARRANTIES
              </h2>
              <div className="space-y-5 pl-5">
                <p className="jarkata_sans">
                  Nirvanae provides no representation warranties, implied or
                  explicit on its use of the website, its application, or its
                  affiliate platforms for information. Nirvanae may discontinue
                  the website/ Application and other platforms affiliated with
                  it, at its discretion.
                </p>
              </div>
            </div>
            <div className="space-y-5 pt-5">
              <h2 className="text-sm md:text-lg font-semibold">
                6. REVIEW OF MEMBERSHIP PRICING
              </h2>
              <div className="space-y-5 pl-5">
                <p className="jarkata_sans">
                  Nirvanae is committed to providing the best services to its
                  users/customers and this may involve the review of membership
                  pricing from time to time. This change in pricing will be
                  communicated to your designated addresses. Your continued
                  usage of the website, connotes your representation to be bound
                  by the communicated review. <br />
                  To this end, Nirvanae shall not be considered liable for any
                  such deductions on the pretext of your not being aware of the
                  reviewed membership price.
                </p>
              </div>
            </div>
            <div className="space-y-5 pt-5">
              <h2 className="text-sm md:text-lg font-semibold">
                7. OUR RIGHTS TO USE YOUR CONTENT
              </h2>
              <div className="space-y-5 pl-5">
                <p className="jarkata_sans">
                  At Nirvanae, we reserve, the right to use your content, and
                  your continued use of the website, acts as an authorization to
                  use your content.
                </p>
              </div>
            </div>
            <div className="space-y-5 pt-5">
              <h2 className="text-sm md:text-lg font-semibold">
                8. USERS RIGHT OF CANCELLATION
              </h2>
              <div className="space-y-5 pl-5">
                <p className="jarkata_sans">
                  The user reserves the right to cancel subsisting membership
                  plan with Nirvanae, but may do so by clicking the subscribe
                  icon and filling out the survey form for proper documentation.
                </p>
              </div>
            </div>
            <div className="space-y-5 pt-5">
              <h2 className="text-sm md:text-lg font-semibold">
                9. BREACH OF TERMS AND CONDITIONS
              </h2>
              <div className="space-y-5 pl-5">
                <p className="jarkata_sans">
                  Where Nirvanae finds in its discretion any act of a user to be
                  in breach of the provisions of this agreement, it may in its
                  discretion, inform the user of this breach, and carry out
                  disciplinary action as it deems fit.
                </p>
              </div>
            </div>
            <div className="space-y-5 pt-5">
              <h2 className="text-sm md:text-lg font-semibold">
                10. THIRD PARTY WEBSITES
              </h2>
              <div className="space-y-5 pl-5">
                <p className="jarkata_sans">
                  Nirvanae will not be held liable for any activities, breach,
                  and action of third-party websites, inclusive of any of their
                  fraudulent acts whether or not the link to these malicious
                  acts was provided on the Nirvanae platforms.
                </p>
              </div>
            </div>
            <div className="space-y-5 pt-5">
              <h2 className="text-sm md:text-lg font-semibold">
                11. ASSIGNMENT
              </h2>
              <div className="space-y-5 pl-5">
                <p className="jarkata_sans">
                  This agreement, may not be assigned to any third party by any
                  user, without the permission of Nirvanae first had and
                  obtained.
                </p>
              </div>
            </div>
            <div className="space-y-5 pt-5">
              <h2 className="text-sm md:text-lg font-semibold">
                12. SEVERABILITY
              </h2>
              <div className="space-y-5 pl-5">
                <p className="jarkata_sans">
                  In the event that any part of this Agreement is found to be
                  inconsistent with any regulations by a Court of competent
                  jurisdiction, such a part so discovered, may be severed as a
                  part from this agreement and the remaining provisions not
                  affected shall continue to operate as if the severed or
                  revised provision was not a part of it at the initial point.
                </p>
              </div>
            </div>
            <div className="space-y-5 pt-5">
              <h2 className="text-sm md:text-lg font-semibold">
                13. ENTIRE AGREEMENT:
              </h2>
              <div className="space-y-5 pl-5">
                <p className="jarkata_sans">
                  This Agreement contains the entire agreement and understanding
                  among the parties hereto with respect to the subject matter
                  hereof, and supersedes all prior and contemporaneous
                  agreements, understandings, inducements and conditions,
                  express or implied, oral or written, of any nature whatsoever
                  with respect to the subject matter hereof. The express terms
                  hereof control and supersede any course of performance and/or
                  usage of the trade inconsistent with any of the terms hereof.
                </p>
              </div>
            </div>
            <div className="space-y-5 pt-5">
              <h2 className="text-sm md:text-lg font-semibold">
                14. VARIATION:
              </h2>
              <div className="space-y-5 pl-5">
                <p className="jarkata_sans">
                  Kindly note that Nirvanae may from time to time add, revise,
                  or substitute for cancel or vary all or any of the provisions
                  of this Agreement for the purpose of more efficiently or
                  satisfactorily implementing or facilitating any of the objects
                  of this Agreement and at its discretion.
                </p>
              </div>
            </div>

            <h2 className="text-sm pt-10 text-center md:text-lg font-semibold">
              If you have any questions about the Terms and Conditions, please
              feel free to contact us.
            </h2>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-center items-center py-10  bg-[#081A51] px-8">
        <p className="text-white text-center text-xs">
          Copyright @ 2022-2023 Nirvanae. All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default TermsAndCondition;

import { Button } from "src/components";
import { TabContainer } from "./Tabs.styles";
import { Box } from "@mui/material";

/**
 * This component renders tab headers
 * @param activeTab {string} - this is the current tab
 * @param labels {string[]} - this is the list of tab labels
 * @param handleTabClick {function} - this function handles when a tab is clicked
 * @param SideComponent {any} - this component that needs to be rendered as the tab button child
 * @params SideComponentIndex {number} - this is the position of the side component
 * Note: whenever a label contains space it replaces all space with _(underscore)
 * @returns a jsx element
 */

const convert = (label) => label?.split(" ")?.join("_")?.toLowerCase();

const Tabs = ({ activeTab, labels, handleTabClick }) => {
  return (
    <TabContainer>
      {labels.map((title, index) => {
        const label = convert(title);

        return (
          <Button
            key={label}
            variant="text"
            onClick={() => handleTabClick(label)}
            className={activeTab === label ? "active" : ""}
          >
            <Box>{title}</Box>
          </Button>
        );
      })}
    </TabContainer>
  );
};

export default Tabs;

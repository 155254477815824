import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaCalendarCheck, FaCalendarXmark, FaVideoSlash } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { SiGooglemeet } from "react-icons/si";
import { useUser } from '../auth/hooks/UserProvider';
import { useQuery } from '@apollo/client';
import { GET_UPCOMING_EVENTS } from '../auth/data/queries';
import { BsFillInfoCircleFill } from "react-icons/bs";
interface User {
  user_match_theme: string;
  user_match_meeting_time: string;
}

interface UpcomingEventsProps {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  userData: User[];
  setSelectedDate: React.Dispatch<React.SetStateAction<any>>;
  loading: boolean; 
}

const UpcomingEvents: React.FC<UpcomingEventsProps> = ({ setModal,userData, setSelectedDate, loading }) => {
  const [requests, setRequests] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [requestsPerPage, setRequestsPerPage] = useState(3);
// console.log("ueventsooo",userData)
const upcoming = userData

  const indexOfLastRequest = currentPage * requestsPerPage;
  const indexOfFirstRequest = indexOfLastRequest - 3;
  const currentRequests = upcoming?.slice(indexOfFirstRequest, indexOfLastRequest);
  const totalPages = Math.ceil(upcoming?.length / 3);

  const handleRequestsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newRequestsPerPage = parseInt(event.target.value, 10);
    setRequestsPerPage(newRequestsPerPage);
    setCurrentPage(1);
  };


  // Generate dropdown options
  const getDropdownOptions = (): JSX.Element[] => {
    const options: JSX.Element[] = [];
    for (let i = 1; i <= totalPages; i++) {
      options.push(<option key={i} value={i * 3}>{i}</option>);
    }
    return options;
  };

  const handleView = (user) => {
    setModal(true);
    setSelectedDate(user);
  };

  return (
    <div className='py-2 sm:py-4 md:py-6 px-2 sm:px-4 md:px-6 h-full'>
      {/* header */}
      <div className='flex justify-between items-center border-b pb-2 sm:pb-3'>
        <h1 className='text-sm md:text-base font-semibold xl:text-lg'>Upcoming Events</h1>
        {currentRequests?.length > 0 && currentRequests?.[0] !== null && (
          <div className='flex items-center space-x-2 flex-shrink-0'>
            <span className='text-xs lg:text-sm'>Showing</span>
            <select
              value={requestsPerPage}
              onChange={handleRequestsPerPageChange}
              className='border border-gray-300 rounded-md px-6 lg:px-8 py-1 lg:py-2 text-xs'
            >
              {getDropdownOptions()}
            </select>
            <span className='text-xs lg:text-sm'>out of {totalPages}</span>
          </div>
        )}
      </div>
         {loading &&
      <div className="md:bg-white ">
          <div className="gap-3 p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            <div className="max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden">
              <div className="animate-pulse bg-gray-200 h-24 w-full"></div>
              <div className="p-6">
                <div className="animate-pulse bg-gray-200 w-1/4 h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-full h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-3/4 h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-1/2 h-4 mb-2"></div>
              </div>
            </div>
            <div className="max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden">
              <div className="animate-pulse bg-gray-200 h-24 w-full"></div>
              <div className="p-6">
                <div className="animate-pulse bg-gray-200 w-1/4 h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-full h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-3/4 h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-1/2 h-4 mb-2"></div>
              </div>
            </div>
            <div className="max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden">
              <div className="animate-pulse bg-gray-200 h-24 w-full"></div>
              <div className="p-6">
                <div className="animate-pulse bg-gray-200 w-1/4 h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-full h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-3/4 h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-1/2 h-4 mb-2"></div>
              </div>
            </div>
          </div>
        </div>
    }
      {/* body */}
      {!loading &&<div className='h-full'>
        <div className={`${currentRequests?.length > 0 && currentRequests?.[0] !== null ? 'h-max grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 pt-4 w-full' : 'h-56 pt-4 w-full'}`}>
          {currentRequests?.length > 0 && currentRequests?.[0] !== null? (
            currentRequests?.map((user, index) => (
              <div key={index}>
                <div className='bg-white shadow-md lg:shadow-lg rounded-md hover:shadow-md px-6 flex flex-col justify-between py-4 border-l-4 lg:border-l-8 border-[#017EFA] space-y-5 lg:space-y-7 h-full'>
                  <h1 className='font-bold text-sm sm:text-base xl:text-lg '>{user?.user_match_theme}</h1>
                  <div className='flex items-center gap-2 lg:gap-3'>
                    <SiGooglemeet className='text-primary-bg lg:text-xl' />
                    <p className='text-xs sm:text-sm lg:text-base'>Blind Date Meet</p>
                  </div>
                  <div className='flex items-center gap-2 lg:gap-3'>
                    <FaCalendarCheck className='text-primary-bg lg:text-xl' />
                    <p className='text-xs sm:text-sm lg:text-base'>{moment.utc(user?.user_match_meeting_time).format('Do MMMM YYYY, hh:mma')}</p>
                  </div>
                   <div className='flex items-center gap-2 lg:gap-3'>
                    <BsFillInfoCircleFill className='text-primary-bg lg:text-xl' />
                    <p className='text-xs sm:text-sm lg:text-base'>please note this blind date will last 6 minutes</p>
                  </div>
                  <div className='flex items-center justify-between gap-2'>
                    <div className='w-12 lg:w-14 relative flex-shrink-0'>
                      <img src="/images/hidden.png" alt="" />
                      <div className='absolute top-0 left-1/2 w-12 lg:w-14'>
                        <img src="/images/user.png" alt="" />
                      </div>
                    </div>
                    
                    <button onClick={()=>handleView(user)} className='text-primary-bg border border-primary-bg px-6 rounded-md py-2 text-xs lg:text-sm xl:text-base font-medium hover:bg-primary-bg hover:text-white hover:shadow-md'>View Details</button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className='flex flex-col justify-center items-center h-full space-y-6'>
              <div className='rounded-full bg-[#F5F5F5] w-12 h-12 md:w-14 md:h-14 xl:w-16 xl:h-16 flex justify-center items-center mx-auto'>
                <FaCalendarXmark className='text-xl md:text-2xl xl:text-3xl text-primary-bg' />
              </div>
              <p className='text-xs md:text-sm xl:text-sm text-center max-w-xs md:max-w-md xl:max-w-lg mx-auto'>You currently have no activity lined up yet</p>
              
            </div>
          )}
        </div>
      </div>}
    </div>
  );
}

export default UpcomingEvents;

import { Menu, Transition } from "@headlessui/react";
import {
  Backspace,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircleOutline,
  FilterList,
} from "@mui/icons-material";
import moment from "moment";
import { Fragment, useState } from "react";
import LinePagination from "src/Paging/LinePagination";

interface User {
  last_name: String;
  first_name: String;
  email: String;
  phone: String;
  date_joined: String;
  status: String;
  gender: String;
  genotype: String;
  relationship_status: String;
  ethinicity: String;
  date_of_birth: Date;
  username: String;
  religion: String;
  occupation: String;
  location: String;
  country: String;
  place_of_birth: String;
  nationality: String;
  qualification: String;
  hobbies: String;
  preferred_gender: String;
  preferred_location: String;
  preferred_religion: String;
  preferred_genotype: String;
  preferred_status: String;
  preferred_age: String;
}
type Alluserdata = User[];
export default function AdminAssignUser() {
  const [users, setUsers] = useState([
    {
      last_name: "Akpan",
      first_name: "Adedeji",
      email: "adedeji@gmail.com",
      phone: "09127654323",
      date_joined: "2023-12-01T12:43:10.997Z",
      status: "inactive",
      gender: "male",
      genotype: "AS",
      relationship_status: "single",
      ethinicity: "african",
      date_of_birth: new Date("1991-03-14T12:43:10.997Z"),
      username: "Adjpan",
      religion: "christian",
      occupation: "product manager",
      location: "Abuja",
      country: "Nigeria",
      place_of_birth: "Abuja",
      nationality: "Nigerian",
      qualification: "Phd",
      hobbies: "Singing, Dancing",
      preferred_gender: "Female",
      preferred_location: "USA",
      preferred_religion: "Christian",
      preferred_genotype: "AA",
      preferred_status: "single",
      preferred_age: "18 and above",
    },
    {
      last_name: "Ebony",
      first_name: "Boluwatife",
      email: "Boluwatife@gmail.com",
      phone: "08123563981",
      date_joined: "2023-10-02T12:43:11.823Z",
      status: "pending",
      gender: "female",
      genotype: "AS",
      relationship_status: "single",
      ethinicity: "african",
      date_of_birth: new Date("1993-03-02T12:43:11.823Z"),
      username: "boluwababy",
      religion: "christian",
      occupation: "Realtor",
      location: "Lekki Phase 1, Lagos",
      country: "Nigeria",
      place_of_birth: "Lagos",
      nationality: "Nigerian",
      qualification: "B.Sc",
      hobbies: "Urshering, Dancing",
      preferred_gender: "Male",
      preferred_location: "Awoyaya",
      preferred_religion: "Christian",
      preferred_genotype: "AS",
      preferred_status: "single",
      preferred_age: "30 and above",
    },
    {
      last_name: "Mitchell",
      first_name: "Dozie",
      email: "okwu@gmail.com",
      phone: "09127654323",
      date_joined: "2023-12-01T12:43:10.997Z",
      status: "active",
      gender: "male",
      genotype: "AA",
      relationship_status: "single",
      ethinicity: "african",
      date_of_birth: new Date("1991-03-14T12:43:10.997Z"),
      username: "Daddie",
      religion: "christian",
      occupation: "product designer",
      location: "Awoyaya, Lagos",
      country: "Nigeria",
      place_of_birth: "Lagos",
      nationality: "Nigerian",
      qualification: "B.Sc",
      hobbies: "Doking, Hookup",
      preferred_gender: "Female",
      preferred_location: "Ajah",
      preferred_religion: "Christian",
      preferred_genotype: "AA",
      preferred_status: "single",
      preferred_age: "25 and above",
    },
  ]);
  const [allDatas, setAllDatas] = useState<Alluserdata | []>([]);
  const [selectedUser, setSelectedUser] = useState<Alluserdata | []>([]);
  return (
    <div className="w-full h-full overflow-y-auto pl-5 space-y-5 pb-60">
      {selectedUser?.length > 0 && (
        <div className="flex px-5 gap-5 justify-end items-center">
          <button className="flex gap-2 items-center rounded-lg px-4 py-2 text-sm bg-green-400 text-white">
            <CheckCircleOutline fontSize="small" />
            {`Assign ${selectedUser?.length} User${
              selectedUser?.length > 1 ? "s" : ""
            }`}
          </button>
          <button
            onClick={() => setSelectedUser([])}
            className="flex gap-2 items-center rounded-lg px-4 py-2 text-sm bg-gray-400 "
          >
            Remove all
            <Backspace fontSize="small" />
          </button>
        </div>
      )}
      <div className="space-y-4  w-full p-5 bg-white">
        <h2 className="text-base font-bold">Assign Users</h2>
        <LinePagination data={users} setCurrentItems={setAllDatas}>
          <div className="relative w-full overflow-auto">
            <table className="relative w-full divide-y divide-gray-1 overflow-auto  bg-white">
              <thead className=" rounded-lg rounded-t border px-5">
                <tr className="rounded-t-lg">
                  <th
                    scope="col"
                    className={`px-2 py-4 pl-5 text-left text-xs font-medium uppercase`}
                  >
                    <div className="flex gap-2 items-center">
                      Last name
                      <Menu as="div" className="w-max relative">
                        {({ open }) => (
                          <>
                            <Menu.Button as="div" className="w-max">
                              <FilterList
                                fontSize="small"
                                className="text-blue-600 cursor-pointer shadow-md"
                              />
                            </Menu.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                static
                                className="absolute normal-case z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-60 top-full left-0"
                              >
                                <div className="space-y-3 py-3 px-2">
                                  <div className="space-y-2">
                                    <p className="text-xs font-medium ">
                                      Firstname
                                    </p>
                                    <input
                                      type="text"
                                      placeholder="Enter firstname"
                                      className="w-full text-sm px-4 py-2 rounded-lg"
                                    />
                                  </div>
                                  <div className="space-y-2">
                                    <p className="text-xs font-medium ">
                                      Lastname
                                    </p>
                                    <input
                                      type="text"
                                      placeholder="Enter lastname"
                                      className="w-full text-sm px-4 py-2 rounded-lg"
                                    />
                                  </div>
                                  <div className="space-y-2">
                                    <p className="text-xs font-medium ">
                                      Email
                                    </p>
                                    <input
                                      type="email"
                                      placeholder="example@email.com"
                                      className="w-full text-sm px-4 py-2 rounded-lg"
                                    />
                                  </div>
                                  <div className="space-y-2">
                                    <p className="text-xs font-medium ">Date</p>
                                    <input
                                      type="date"
                                      placeholder="Enter date"
                                      className="w-full text-sm px-4 py-2 rounded-lg"
                                    />
                                  </div>
                                  <div className="space-y-2">
                                    <p className="text-xs font-medium ">
                                      Phone Number
                                    </p>
                                    <input
                                      type="text"
                                      placeholder="Enter phone"
                                      className="w-full text-sm px-4 py-2 rounded-lg"
                                    />
                                  </div>
                                  <div className="space-y-2">
                                    <p className="text-xs font-medium ">
                                      Status
                                    </p>
                                    <select className="w-full text-sm px-4 py-2 rounded-lg">
                                      <option value={""}>Select status</option>
                                      <option value={"Pending"}>Pending</option>
                                      <option value={"Active"}>Active</option>
                                      <option value={"Inactive"}>
                                        Inactive
                                      </option>
                                      <option value={"Blacklisted"}>
                                        Blacklisted
                                      </option>
                                    </select>
                                  </div>
                                  <div className="flex pt-4 border-t gap-2 items-center">
                                    <button className="px-4 py-2 rounded-lg text-center w-full uppercase bg-white text-[#081A51] border border-[#081A51] text-xs font-normal ">
                                      Reset
                                    </button>
                                    <button className="px-4 py-2 rounded-lg text-center w-full uppercase text-white bg-[#081A51] border border-[#081A51] text-xs font-normal ">
                                      Filter
                                    </button>
                                  </div>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className={`px-2 py-4 pl-5 text-left text-xs font-medium uppercase`}
                  >
                    <div className="flex gap-2 items-center">
                      First name
                      <FilterList fontSize="small" />
                    </div>
                  </th>
                  <th
                    scope="col"
                    className={`px-2 py-4 pl-5  text-left text-xs font-medium uppercase`}
                  >
                    <div className="flex gap-2 items-center">
                      Email
                      <FilterList fontSize="small" />
                    </div>
                  </th>
                  <th
                    scope="col"
                    className={`px-2 py-4 pl-5  text-left text-xs font-medium uppercase`}
                  >
                    <div className="flex gap-2 items-center">
                      Phone number
                      <FilterList fontSize="small" />
                    </div>
                  </th>
                  <th
                    scope="col"
                    className={`px-2 py-4 pl-5  text-left text-xs font-medium uppercase`}
                  >
                    <div className="flex gap-2 items-center">
                      Date joined
                      <FilterList fontSize="small" />
                    </div>
                  </th>
                  <th
                    scope="col"
                    className={`px-2 py-4 pl-5  text-left text-xs font-medium uppercase`}
                  >
                    <div className="flex gap-2 items-center">
                      Status
                      <FilterList fontSize="small" />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-300 overflow-auto bg-white px-5 pt-5">
                {allDatas?.map?.((item: any, index: number) => (
                  <tr
                    onClick={() => {
                      if (
                        selectedUser?.some(
                          (i) => i?.first_name === item?.first_name
                        )
                      ) {
                        return setSelectedUser((prev) =>
                          prev?.filter(
                            (i) => i?.first_name !== item?.first_name
                          )
                        );
                      }
                      // @ts-ignore
                      return setSelectedUser((prev) => [...prev, item]);
                    }}
                    className="hover:bg-gray-100"
                    key={index}
                  >
                    <td className="px-2 pl-5 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                      <div className="flex gap-2 items-center">
                        {selectedUser?.some(
                          (i) => i?.first_name === item?.first_name
                        ) ? (
                          <CheckBox fontSize="small" />
                        ) : (
                          <CheckBoxOutlineBlank fontSize="small" />
                        )}
                        {item?.last_name}
                      </div>
                    </td>
                    <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                      {item?.first_name}
                    </td>
                    <td className="px-2 py-4 text-left text-xs font-normal lowercase text-[#545F7D]">
                      {item?.email}
                    </td>
                    <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                      {item?.phone}
                    </td>
                    <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                      {moment(item?.date_joined).format("MMM DD, YYYY")}
                    </td>
                    <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                      <p
                        className={`px-4 py-1 w-max rounded-full ${
                          item?.status?.toLowerCase?.() === "inactive"
                            ? "bg-[#545f7d3b] text-black"
                            : item?.status?.toLowerCase?.() === "pending"
                            ? "bg-[#e9b30042] text-[#E9B200]"
                            : item?.status?.toLowerCase?.() === "active"
                            ? "bg-[#39cd633f] text-[#39CD62] "
                            : "bg-[#e4033b41] text-[#E4033B]"
                        } text-center`}
                      >
                        {item?.status}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </LinePagination>
      </div>
    </div>
  );
}

import React, { ReactNode } from 'react';

interface SingleButtonProps {
    text?: string | number;
    className?: string;
    inlineStyle?: string;
    icon?: ReactNode;
    onBtnClick?: () => void;
    disabled?: boolean;
    paragrah?: string;
}



const SingleButton: React.FC<SingleButtonProps> = ({icon, text, inlineStyle, className, onBtnClick, disabled, paragrah}) => { 
  return (
    <>
         {disabled ?
          <div onClick={onBtnClick} className={className}>
            {icon}
            <p className={`${inlineStyle}`}>{text} <span>{paragrah}</span></p>
        </div> :
        <div onClick={onBtnClick} className={className}>
            {icon}
            <p className={`${inlineStyle}`}>{text}</p>
        </div>}
    </>
  )
}

export default SingleButton;
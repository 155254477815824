import { useMutation } from '@apollo/client';
import React from 'react';
import { useUser } from "src/components/auth/hooks/UserProvider"
import { BLIND_DATE_ACTIONS } from '../auth/data/mutation';
import { Link } from 'react-scroll'
import { BeatLoader } from 'react-spinners';

interface WelcomePageProps {
    // setDisplay: React.Dispatch<React.SetStateAction<string>>;
    handleAccept: (id: any) => void; // Add this line
    isLoading: boolean
}
const info = [
  {
    id: 1,
    title: 'Getting Started',
    description: [
      { id: 1, item: 'Get paired with someone new every time' },
      { id: 2, item: '6-minute video call dates - short, sweet, and to the point!' },
      { id: 3, item: 'Meet people from diverse backgrounds and cultures' },
      { id: 4, item: 'Video calls only - no hiding, just authentic connections!' }
    ]
  },
   {
    id: 2,
    title: 'What you stand to benefit',
    description: [
      { id: 1, item: 'A unique opportunity to break out of your social shell' },
      { id: 2, item: 'A chance to practice your communication skills' },
      { id: 3, item: 'A fun and exciting way to make new friends and connections' },
    ]
  },
   {
    id: 3,
    title: 'Please Be guided',
    description: [
      { id: 1, item: 'As a subscriber, check here every weekend to see if you have a new blind date.' },
      { id: 2, item: 'Blind dates are automatically set up. If within a month you’ve not had at least one blind date, kindly contact your handler.' },
      { id: 3, item: 'Missing two Blind Dates in a row will result in a strike' },
      { id: 4, item: 'Accumulating three strikes will lead to blacklisting from our community' },
     
    ]
  },
];

const WelcomePage  = ({handleAccept,isLoading}) => {
    const {user} = useUser()
    return (
        <main className='space-y-10 bg-white py-10 px-2 sm:px-4 overflow-x-hidden mb-14 md:mb-0'>
            {/* one */}
            <div
     
      >
     
        <div className='     max-w-xs sm:max-w-md mx-auto md:max-w-3xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl px-4 md:px-6  lg:px-8'>
          {/* container */}
          <div className=' space-y-6 sm:space-y-10 md:flex justify-between items-center'>
            {/* text */}
            <div className=' space-y-4 md:w-1/2'>
              <h1 className='font-black  font-mono text-center md:text-left text-xl  max-w-sm mx-auto sm:text-2xl sm:max-w-full md:mx-0 text-babyblack leading-10 md:text-3xl md:w-full sm:leading-[2.8rem] lg:text-4xl lg:leading-[3rem] xl:text-5xl xl:max-w-full xl:leading-[3.5rem] text-primary-bg'>
                Welcome to Blind Dates!
              </h1>
              <p className='text-center text-sm md:text-left max-w-xs mx-auto md:mx-0 text-babyblack leading-5 font-medium sm:text-sm sm:max-w-full md:text-base lg:max-w-sm lg:text-lg xl:max-w-full xl:text-lg'>
              We're thrilled to introduce you to our exciting feature, Blind Dates, where you'll embark on a thrilling adventure of video call dates with mystery matches from around the world! Imagine meeting new friends and potential partners without any preconceptions, just pure, unadulterated connection.
              </p>
              {/* <button className='mx-auto md:mx-0 flex px-4 md:px-5 lg:px-6 py-2 lg:py-3 text-white bg-primary-bg   rounded-md shadow-md cursor-pointer text-xs sm:text-sm font-bold md:text-sm lg:text-base tracking-wide'>
                <Link 
                  activeClass='active'
                  to='started'
                  spy={true}
                  smooth={true}
                  offset={-90}
                  duration={500}
                >
                  Get Started
                </Link>
              </button> */}
            </div>
            {/* photo */}
            <div className='  relative md:w-1/2 py-6'>
             <img
                    src={"/images/welcome1.png"}
                    alt="profile_picture"
                     width={1000}
                height={1000}
                    className='max-w-xs sm:max-w-sm xl:max-w-md mx-auto w-full  '
                  />
    
            </div>
          </div>
        </div>
      </div>
       {/* our story */}
      <div className=' section-center mt-20 lg:mt-32 space-y-10 md:space-y-12 lg:space-y-16 xl:space-y-20  flex flex-col items-center justify-center mx-auto  overflow-x-hidden'>
        {/*  Headline*/}
        <div className='flex flex-col justify-center items-center space-y-4 relative'>
          <h1 className='text-primary-bg font-extrabold relative w-max sm:text-2xl md:text-xl lg:text-2xl  xl:text-3xl'>
            How it Works{' '}
           
          </h1>
             {/* <div className='bg-primary-bg  w-20 h-4  absolute -bottom-1  left-0 -z-20'></div> */}
          {/* top */}
          {/* <h2 className='text-center text-babyblack  font-bold sm:text-xl   xl:text-3xl md:max-w-xs xl:max-w-lg font-mono'>
            Here's what you stand to gain by joining forces with us
          </h2> */}
        </div>

        {/* component */}
        <div className=' flex flex-col justify-center   items-center max-w-sm lg:max-w-xl xl:max-w-3xl w-full overflow-x-hidden'>
          {info.map((item, index) => {
            return (
              <div
                key={index}
                className={`${
                  index % 2 === 1
                    ? 'flex gap-8 '
                    : 'flex  gap-8  '
                }`}
              >
                {/* dummy */}
                <div className='hidden md:flex md:w-full lg:w-full ' >
                  {/* Header */}
                  <h1 className='text-sm sm:text-base md:text-lg font-bold xl:text-xl'>
                    {item.title}
                  </h1>
                </div>
                {/* circle */}
                <div className='w-max  relative   md:flex md:justify-center md:items-start '>
                  <div className='bg-primary-bg w-8 h-8 sm:w-10 sm:h-10 rounded-full relative  '>
                    {/* circle */}
                    <div className='bg-white w-4 h-4 sm:w-5 sm:h-5 rounded-full absolute top-1/2 bottom-1/2  -translate-x-1/2 -translate-y-1/2 left-1/2 right-1/2 flex justify-center items-center z-20 '>
                      <h1 className='text-xs'>{item.id}</h1>
                    </div>
                  </div>
                  {/* line */}
                  {index === info.length - 1 ? null : (
                    <div className='absolute  w-1 h-full top-0 bottom-0 -translate-x-1/2   left-1/2 bg-primary-bg z-10 '></div>
                  )}
                </div>
                {/* text */}
                <div key={index}
                  className={`${
                    index % 2 === 1
                      ? 'flex items-start  md:flex-row-reverse  gap-3 lg:gap-6 pb-8 md:pb-12 xl:pb-20 md:w-full lg:w-full  '
                      : 'flex items-start  gap-3 pb-8 md:pb-12  md:w-full lg:gap-6  xl:pb-20 lg:w-full '
                  }`}
                >
                  {/* icon */}
                  {/* <div className='bg-babygreen text-babyblue w-max rounded-md p-2   md:p-3  xl:p-4 text-xs sm:text-base md:text-lg lg:text-xl xl:text-3xl'>
                    {item.icon}
                  </div> */}
                  {/* text */}
                  <div
                    className={`${
                      index % 2 === 1
                        ? 'space-y-3 md:space-y-0 '
                        : 'space-y-3 md:space-y-0'
                    }`}
                  >
                    {/* Header */}
                    <h1 className='text-sm sm:text-base md:text-lg font-bold xl:text-xl md:hidden'>
                      {item.title}
                    </h1>
                    {/* desc */}
                    {item?.description.map((i,index)=>{
                        return(
<ol className='text-xs font-medium sm:text-sm xl:text-lg pb-1 lg:pb-2'>
                                  {i.item}    
                                    </ol>
                        )
                    })}
                     
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {/* three */}
       <div id='started' className='md:flex my-14 space-y-6 md:space-y-0'>
        {/* image */}
        <div className='  relative md:w-1/2'>
          <img
                    src={"/images/welcome2.png"}
                    alt="profile_picture"
                     width={1000}
                height={1000}
                    className='max-w-xs sm:max-w-sm xl:max-w-md mx-auto  w-full '
                  />
        </div>
        {/* blue back */}
        <div
          className=' w-full flex flex-col justify-center items-center space-y-6 md:w-1/2 md:items-start
        '
        >
          {/* text */}
          {/* top */}
          <h2 className='text-center text-primary-bg  font-bold sm:text-xl  md:text-left xl:text-3xl md:max-w-xs xl:max-w-md font-sans'>
            Happy Matching
          </h2>
          {/* paragraph */}
          <p className='text-babyblack  text-sm text-center max-w-xs leading-6 sm:text-sm sm:leading-5  md:text-base md:text-left md:leading-6 xl:text-lg xl:max-w-md font-medium'>
           Don't miss out on this incredible chance to expand your social circle and create unforgettable memories! Join Blind Dates today and discover a world of new connections!
          </p>

          <button
          disabled={isLoading}
            onClick={() =>
             handleAccept()
            }
            className='mx-auto md:mx-0 flex px-4 md:px-5 lg:px-6 py-2 lg:py-3 text-white bg-primary-bg   rounded-md shadow-md cursor-pointer text-xs sm:text-sm font-bold md:text-sm lg:text-base tracking-wide hover:bg-opacity-80'
          >
          { isLoading ? <BeatLoader size={10} color="#fff" />  : "Get Started"}
          </button>
        </div>
      </div>
        </main>
    );
};

export default WelcomePage;

import { ArrowRightAlt } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ASSETS from "src/asset";

export default function VerificationError() {
  const navigate = useNavigate();
  return (
    <div className="w-full h-screen p-5 md:p-0 overflow-y-auto md:overflow-hidden">
      <div className="space-y-5 md:space-y-0 md:flex md:items-center md:justify-center md:gap-14 lg:gap-8  pt-6 lg:pt-10  ">
        <img src={ASSETS?.Logo} className="  md:hidden w-24 h-10 sm:h-20" />
        <div className="flex justify-center md:justify-start items-center md:w-1/2">
          <img
            src={ASSETS.Verify_error}
            alt="layer"
            className="object-cover w-full max-w-xs md:max-w-xs xl:max-w-xl "
          />
        </div>
        <div className="flex flex-col justify-between h-full space-y-20  md:w-1/2 md:pr-10 ">
          <div className="space-y-6 sm:space-y-8 xl:space-y-10">
            <img
              onClick={() => {
                navigate("/");
              }}
              src={ASSETS?.Logo}
              className=" hidden hover:opacity-70 cursor-pointer md:inline-block w-24 h-10 sm:h-20"
            />
            <h2 className="text-base flex items-center justify-center md:justify-start text-center md:text-left md:text-3xl playfair text-[#081A51] font-bold ">
              Opps !! You didn't make the cut{" "}
              <span className=" text-xl md:text-6xl">😢</span>
            </h2>
            <p className="text-sm lg:text-lg jarkata_sans">
              We are currently unable to onboard you as a member of our
              community. We like to accept only clients we are sure we can
              match. Feel like you’ve been hard done by? Contact Support for
              assistance!
            </p>
            <div className="pt-5 ">
              <a
                href={"mailto:support@nirvanaeng.com"}
                className="px-4 mx-auto w-max md:mx-0 sm:px-6 py-2 bg-[#081a51] jarkata_sans text-xs sm:text-base rounded-lg text-white flex gap-2 items-center justify-center font-medium"
              >
                Get in Touch
                <ArrowRightAlt fontSize="inherit" />
              </a>
            </div>
          </div>
          <div className="flex pb-10 md:pb-5 justify-center space-x-5 divide-x divide-[#222E50] items-center">
            <p
              onClick={() => {
                navigate("/");
              }}
              className="text-xs w-max cursor-pointer hover:opacity-70 font-medium"
            >
              Home
            </p>

            <div className="pl-4">
              <a
                target="_blank"
                href="/terms-and-condition"
                className="text-xs w-max cursor-pointer appearance-none hover:opacity-70 font-medium"
              >
                Terms and Conditions
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

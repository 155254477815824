import imageCompression from "browser-image-compression";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

interface FileType {
  file: any;
  errors: any;
}
export const useUpload = ({ fileType }) => {
  const maxSize = 25428800;
  const [files, setFiles] = useState<FileType>({ file: [], errors: [] });
  const [loading_image, setLoadingImage] = useState<boolean>(false);
  const onDrop = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      setLoadingImage(true);
      //   console.log(acceptedFiles, rejectedFiles);
      if (acceptedFiles?.length > 0) {
        const fileMatched = acceptedFiles.map((file) => ({ file, errors: [] }));
        // console.log(fileMatched);
        let currentFile: FileType = fileMatched[0];
        if (fileType === "image") {
          if (currentFile.file.size > 400) {
            const options = {
              maxSizeMB: 0.1,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };

            const compressedFile = await imageCompression(
              currentFile.file,
              options
            );
            currentFile = {
              file: new File([compressedFile], compressedFile.name, {
                type: compressedFile.type,
              }),
              errors: [],
            };
            setFiles((prev) => currentFile);
          }
          setFiles((prev) => currentFile);
          setLoadingImage(false);
          return;
        }
      }
      setLoadingImage(false);
      return setFiles(rejectedFiles[0]);
    },
    [fileType]
  );

  const deleteFile = () => {
    setFiles({ file: [], errors: [] });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
    maxFiles: 1,
    minSize: 0,
    maxSize,
  });

  return {
    mainFile: files,
    loading_image,
    getRootProps,
    getInputProps,
    deleteFile,
    setFiles,
  };
};

// import axios from "axios";
// import { CURRENCY_DATA } from "src/helpers/constants";

export const useCurrencyHook = () => async (amount, cb, country) => {
  try {
    return cb(
      `${country === "nigeria" ? "₦" : "$"}${Math.round(
        Number(amount.split("-")[0])
      )}-${amount?.split("-")[1]}`
    );
  } catch (error) {
    //We need a logger
    const initialAmount = `${country === "nigeria" ? "₦" : "$"}${Number(
      amount.split("-")[0]
    )}-${amount?.split("-")[1]}`;
    return initialAmount;
  }
};

// export const useCurrencyHook = () => async (amount, cb) => {
//   try {
//     const response = await axios.get("https://ipapi.co/json/");
//     const parsed_ip_info = response.data;
//     const currency = parsed_ip_info.currency;
//     const country = parsed_ip_info.country_code;

//     if (country === "US") {
//       return `$${amount}`;
//     } else {
//       const converted_amount = await axios.get(
//         `https://api.apilayer.com/exchangerates_data/convert?to=${currency}&from=${"USD"}&amount=${amount}`,
//         {
//           headers: {
//             apikey: "33fKlSWpR7Z3l09ZCvQJWAx2UEA1Legn",
//           },
//         }
//       );
//       const imt = `${CURRENCY_DATA[country].symbol}${Math.round(
//         Number(converted_amount.data.result)
//       )}-${CURRENCY_DATA[country].currency}`;

//       return cb(imt);
//     }
//   } catch (error) {
//     //We need a logger
//     const initialAmount = `$${amount}`;
//     return cb(initialAmount);
//   }
// };

import SearchIcon from '@mui/icons-material/Search'
import { Card, InputBase, Typography } from '@mui/material'
import { styled } from '@mui/system'
import Theme from 'src/assets/colors/theme'
import { ArrowBack } from '@mui/icons-material'

export const SearchBoxStylesDiv = styled(Card)({
  minWidth: '250px',
  maxWidth: '320px',
  minHeight: '180px',
  maxHeight: '204px',
  overflowY: 'scroll',
  paddingLeft: '12px',
})

export const SearchInputStylesDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: '10px',
})

export const SearchInputBaseStyles = styled(InputBase)({
  width: '100%',
  overflow: 'auto',
  marginLeft: '10px',
})

export const SearchListStylesDiv = styled('div')({
  padding: '10px',
  cursor: 'pointer',
  paddingTop: 0,
  paddingLeft: 0,
})

export const SearchListItemStyle = styled(Typography)({
  marginTop: '10px',
  marginBottom: 10,
  textOverflow: 'ellipsis',
})

export const EmptySearchListItemStyle = styled(Typography)({
  color: Theme.GREY_TABLE,
})

export const SearchIconStyle = styled(SearchIcon)({
  color: Theme.GREY_ICON,
})

export const SourceStylesWrapper = styled('span')({
  fontSize: '16px',
  fontWeight: '500',
})

export const BackIconStyle = styled(ArrowBack)({
  color: Theme.PRIMARY,
})

export const BackIconWrapper = styled('div')({
  display: 'flex',
  width: '20%',
  alignItems: 'center',
  color: Theme.PRIMARY,
  cursor: 'pointer',
})

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputField from "src/components/Input";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import theme from "src/assets/colors/theme";
import ButtonComp from "src/components/Button/Button";
import { useMutation } from "@apollo/client";
import { REQUEST_PASSWORD } from "src/hooks/data/mutations";
import { toast } from "react-toastify";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [requestNewPassword] = useMutation(REQUEST_PASSWORD);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let email = e.target.name;
    email = e.target.value;
    setEmail(email);
  };

  const disabled = email === "" && true;

  const handleSubmit = async () => {
    setLoading(true);
    requestNewPassword({
      variables: {
        email,
      },

      onCompleted: (res) => {
        setLoading(false);
        if (res?.requestPasswordReset === true) {
          toast.success(
            "Request successfully, kindly check yor mail for an otp code"
          );
          // navigate("/reset-password");
          navigate(`/reset-password?email=${email}`);
        } else {
          toast.error("Email not found, kindly register");
        }
      },
      onError: (err) => {
        setLoading(false);
        toast.error(err?.message ?? "Error requesting password");
      },
    });
  };
  const backToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="flex flex-col justify-center items-center mx-auto h-screen space-y-4 md:space-y-6 backedground">
      {/* logo */}
      <div className="flex items-center ">
        <img
          src="https://dearmacmedia.s3.amazonaws.com/assests/assets/nlogo+1.png"
          alt="logo"
          className="object-cover w-32 md:w-40 xl:w-48"
        />
      </div>
      {/* form */}
      <div className="">
        <div className=" border px-6 md:px-10 py-10 md:py-14 rounded-md shadow-md lg:px-12  xl:px-14 lg:py-16  bg-white  xl:py-20 ">
          <div className="text-left ">
            <h1 className=" font-['sans-serif'] font-bold  text-primary-bg text-base sm:text-xl  ">
              {" "}
              Forgot Password?
            </h1>
            <p className="mt-2 text-xs sm:text-sm text-primary-bg">
              Don’t worry help is on it’s way, Just send us your email
            </p>
          </div>
          <InputField
            label={"Email"}
            icon={<EmailOutlinedIcon />}
            placeholder="Johndoe@example.com"
            type="email"
            name="email"
            onChange={handleChange}
            value={email}
            labelView
          />
          <ButtonComp
            children="Retrieve"
            size="large"
            backgroundColor={theme.PRIMARY_DARK}
            disabled={disabled}
            isLoading={loading}
            onClick={() => {
              handleSubmit();
            }}
          />
        </div>
      </div>
      <p className="py-2 text-white text-center text-sm md:text-base xl:text-lg ">
        Remembered password? That’s great!{" "}
        <span
          className="text-white md:ml-2 underline font-bold"
          onClick={backToLogin}
        >
          Login
        </span>
      </p>
    </div>
  );
};

export default ForgetPassword;

import { useState } from "react";
import Colors from "src/assets/colors.svg";
import { CustomText, HeaderText, ParagraphText } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";

import InputField from "src/components/Input";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import theme from "src/assets/colors/theme";
import ButtonComp from "src/components/Button/Button";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { RESET_NEW_PASSWORD } from "src/hooks/data/mutations";

const instructions = [
  "Use Alphanumeric passwords",
  "Do not reuse passwords",
  "Do not reveal your password to anybody",
  "Logout from all foreign devices when used",
  "Report any malicious action on your account",
];
const logo =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/logo.svg";
const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otpValue, seTOtpValue] = useState("");
  const navigate = useNavigate();
  const [resetNewPassword] = useMutation(RESET_NEW_PASSWORD);
  const [loading, setLoading] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userMail = searchParams.get("email");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "newPassword") {
      setNewPassword(value);
    } else {
      setConfirmPassword(value);
    }
  };

  const disabled =
    newPassword === "" || confirmPassword === "" || otpValue?.trim?.() === "";

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword?.trim?.() === confirmPassword?.trim?.()) {
      setLoading(true);
      resetNewPassword({
        variables: {
          email: userMail,
          code: otpValue,
          password: newPassword?.trim?.(),
        },

        onCompleted: (res) => {
          setLoading(false);
          if (res?.forgetPassword === true) {
            toast.success("Password reset successfully, kindly login");
            navigate("/login");
          } else {
            toast.error("Invalid code entered, kindly check your mail");
          }
        },
        onError: (err) => {
          setLoading(false);
          toast.error(err?.message ?? "Error reseting password");
        },
      });
    } else {
      // Passwords don't match, show an error
      return toast.error("Password does not match");
    }
  };

  return (
    <div className="w-full h-screen grid md:grid-cols-2 grid-cols-1 bg-white overflow-y-auto md:overflow-hidden overflow-hidden">
      <div className=" h-full relative hidden w-full overflow-hidden md:flex flex-col bg-slate-50 justify-center">
        <div className="flex flex-col justify-center items-center ">
          <div className="flex flex-col justify-start px-6 max-w-md">
            <h1 className="font-extrabold text-primary-bg md:text-2xl xl:text-4xl font-['sans-serif'] ">
              Important Information
            </h1>
            <p className="text-sm lg:text-base xl:text-lg pt-2">
              Please <span className="font-extrabold">read</span> the
              information below and before resetting your password
            </p>
            <ul className="mt-4">
              {instructions.map((i, k) => (
                <li key={k} className="my-2 list-none">
                  <div className="flex items-start">
                    <p className="text-3xl pr-2">*</p>
                    <p className="text-sm lg:text-base xl:text-lg"> {i}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="absolute bottom-0  left-0  ">
          <img src={Colors} className="object-cover w-36 lg:w-40 xl:w-44" />
        </div>
      </div>
      {/* text and form */}
      <div className="w-full h-full md:overflow-y-auto">
        <div className="items-center  flex-col justify-center flex p-5 sm:p-10">
          <div className="flex-col flex ">
            <img
              src={logo}
              alt="logo"
              className="object-cover w-40 md:w-44 xl:w-48"
            />
            <HeaderText className="mt-6 font-['sans-serif'] max-lg:text-center h-1 ">
              Reset Password
            </HeaderText>
            <CustomText className="max-lg:text-center mt-10 max-lg:mt-8">
              Your are one step away from rejoining our love community
            </CustomText>
            <div className="sm:max-w-md  lg:max-w-xl">
              <InputField
                label={"OTP"}
                placeholder="Enter Otp"
                labelStyle="text-sm sm:text-base font-semibold"
                type="text"
                name="header"
                onChange={(e) => seTOtpValue(e.target.value)}
                value={otpValue}
                labelView
              />
              <InputField
                label={"New Password"}
                secretState={showPassword}
                icon={<LockOutlinedIcon />}
                secret={
                  !showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />
                }
                changeVisibleState={togglePasswordVisibility}
                type={!showPassword ? "text" : "password"}
                placeholder="************************"
                name="newPassword"
                onChange={handleChange}
                value={newPassword}
                labelView
              />
              <InputField
                label={"Confirm New Password"}
                secretState={showPassword}
                icon={<LockOutlinedIcon />}
                secret={
                  !showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />
                }
                changeVisibleState={togglePasswordVisibility}
                type={!showPassword ? "text" : "password"}
                placeholder="************************"
                name="confirmPassword"
                onChange={handleChange}
                value={confirmPassword}
                labelView
              />
              <ButtonComp
                children="Reset"
                size="large"
                backgroundColor={theme.PRIMARY_DARK}
                onClick={handleSubmit}
                isLoading={loading}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

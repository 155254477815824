import { styled } from "@mui/system";
import defaultTheme from "src/assets/colors/theme";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { Chip } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { RemoveRedEye } from "@mui/icons-material";
import { overrideTheme } from "src/assets/colors/theme";

export const PageWrapper = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  backgroundColor: defaultTheme.PRIMARY_BACKGROUND,
  height: "100vh",
  overflow: "hidden",
});

export const Wrapper = styled(Box)({
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingTop: "10px",
  height: "86.5%",
});

export const LoadingWrapper = styled("div")({
  width: "auto",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const TopCard = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

export const RightToolBarWrapper = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "10px",
  paddingRight: 15,
});

export const SearchDivWrapper = styled("div")({
  width: "15em",
  height: "3.6em",
});

export const TopCardBody = styled(Box)({
  display: "flex",
  marginTop: "32px",
  overflowY: "auto",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "10px",
});

export const TopCardItem = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  color: defaultTheme.PRIMARY,
  border: "1px dashed",
  padding: "18px",
  width: "30%",
  cursor: "pointer",
});

export const Text = styled(Typography)({});

export const CustomText = styled("span")({
  color: defaultTheme.PRIMARY,
  fontWeight: 400,
  fontSize: "0.875rem",
  cursor: "pointer",
  a: {
    textDecoration: "none",
  },
});


export const BottomCard = styled("div")({
  marginTop: "20px",
  flexWrap: "wrap",
  borderTop: `1px solid ${defaultTheme.GREY_VARIANT}`,
  width: "100%",
});

export const BottomCardHeader = styled("div")({
  margin: "12px 0px",
  lineHeight: "1.8",
});

export const BottomCardTitle = styled("h5")({
  fontSize: "16px",
  fontWeight: "500",
});

export const BottomCardDesc = styled("p")({
  fontSize: "16px",
  fontWeight: "400",
});

export const BottomCardBody = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "10px",
});

export const BorderedCard = styled("div")({
  padding: "18px",
  borderRadius: "4px",
  border: `1px dashed ${defaultTheme.PRIMARY}`,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flex: "1 1 auto",
  height: "110px",
  width: "30%",
  backgroundColor: "transparent",
  color: defaultTheme.PRIMARY,
});

export const PlainCard = styled("div")({
  padding: "18px",
  borderRadius: "4px",
  border: `1px solid ${defaultTheme.GREY_VARIANT}`,
  display: "block",
  backgroundColor: defaultTheme.WHITE,
  width: "30%",
  cursor: "pointer",
});

export const IconWrapper = styled("div")({
  color: defaultTheme.PRIMARY,
});

export const CardText = styled("span")({
  fontSize: "20px",
  fontWeight: "500",
  marginTop: "15px",
});

export const CardTitle = styled("h4")({
  fontSize: "20px",
  fontWeight: "500",
  display: "block",
  margin: "5px 0px",
});

export const CardDescription = styled("p")({
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
});

export const TableWrapper = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  minHeight: "100%",
  backgroundColor: "inherit",
  marginTop: "20px",
});

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  backgroundColor: defaultTheme.WHITE,
});

export const ButtonWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  color: defaultTheme.PRIMARY,
});

export const StyledChip = styled(Chip)({
  fontSize: "13px",
  fontWeight: "400",
  "&.draft": {
    color: defaultTheme.WARNING_MAIN,
    backgroundColor: defaultTheme.WARNING_OUTLINED,
  },
  "&.active": {
    color: defaultTheme.SUCCESS_DARK,
    backgroundColor: defaultTheme.SUCCESS_OUTLINED,
  },
  "&.disabled": {
    color: defaultTheme.TAG_FORGROUND_RED,
    backgroundColor: defaultTheme.TAG_BACKGROUND_RED,
  },
});

export const BtnWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  width: "50%",
});

export const BtnText = styled("span")({
  cursor: "pointer",
});

export const EditIcon = styled(EditOutlinedIcon)({
  fontSize: "large",
  cursor: "pointer",
  color: defaultTheme.PRIMARY,
  "&.disabled": {
    color: defaultTheme.GREY_ICON,
  },
});

export const RemoveRedEyeIcon = styled(RemoveRedEye)({
  fontSize: "large",
  cursor: "pointer",
  color: defaultTheme.PRIMARY,
  "&.disabled": {
    color: defaultTheme.GREY_ICON,
  },
});

export const Delete = styled(DeleteIcon)({
  fontSize: "large",
  cursor: "pointer",
});

export const PropWrapper = styled("div")({
  width: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const DetailsWrapper = styled("section")({
  padding: 18,
  display: "grid",
  gap: 18,
  height: "100%",
  gridTemplateColumns: "1fr 4fr",
  gridTemplateRows: "0.5fr 0.3fr 9fr",
  gridTemplateAreas: `"sidebar header header"
  "sidebar main main"`,
  overflow: "hidden",
});

// export const DetailsWrapper = styled("section")({
//   padding: 18,
//   display: "flex",
//   //gap: 18,
//   justifyContent: "space-between",
// });

export const TopContainer = styled("section")({
  display: "grid",
  gridColumn: "span 2/span 2",
  background: defaultTheme.WHITE,
  borderRadius: "4px",
  paddingLeft: "12px",
  height: "64px",
});

export const MainContainer = styled("section")({
  display: "grid",
  gridArea: "span 2 / span 2",
  borderRadius: "4px",
  overflowY: "auto",
  width: "100%",
  height: "100%",
});

export const UnitWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
});

export const AccessMsg = styled("div")({
  width: "100%",
  height: "2rem",
  padding: 10,
  background: defaultTheme.PRIMARY_80,
  color: defaultTheme.PRIMARY_DARK,
  display: "flex",
  alignItems: "center",
  fontSize: 14,
  fontWeight: 400,
  em: {
    margin: "0px 5px 0px 2px",
  },
  svg: {
    color: defaultTheme.PRIMARY_DARK,
    marginRight: "0.5rem",
  },
});

export const SkeletonWrapper = styled("div")({
  background: "transparent",
  width: "100%",
  marginTop: "3em",
  padding: 0,
});

export const HeaderText = styled('p')(({ theme }) => ({
  fontSize: 30,
  fontWeight: 600,
  color: defaultTheme.PRIMARY_DARK,
  // fontFamily: overrideTheme.typography
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    /* Styles for screens with a maximum width of 600px */
    fontSize: 20,
  },

}));
export const ParagraphText = styled('p')(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: 400,
  color: defaultTheme.PRIMARY_DARK,
  whiteSpace: 'nowrap',
  cursor: "pointer",
  a: {
    textDecoration: "none",
  },
  // fontFamily: overrideTheme.typography
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    /* Styles for screens with a maximum width of 600px */
    fontSize: 12,
    marginBottom: 8 
  },

}));

// @ts-nocheck
import { useState } from "react";
import { useUser } from "../auth/hooks/UserProvider";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  HIGHEST_QUALIFICATION,
  RELATIONSHIP_STATUS,
  RELIGIONS,
} from "src/helpers/constants";

export default function PersonalInfo() {
  const { user, saveUser } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [userAbout, setUserAbout] = useState(user?.about_me ?? "");
  const [firstName, setFirstName] = useState(user?.first_name ?? "");
  const [lastName, setLastName] = useState(user?.last_name ?? "");
  const [userName, setUserName] = useState(user?.alais ?? "");
  const [occupation, setOccupation] = useState(user?.occupation ?? "");
  const [religion, setReligion] = useState(user?.religion ?? "");
  const [qualification, setQualification] = useState(user?.qualification ?? "");
  const [status, setStatus] = useState(user?.status ?? "");
  const [city, setCity] = useState(user?.location_city ?? "");
  const [state, setState] = useState(user?.location_state ?? "");
  const [country, setCountry] = useState(user?.location_country ?? "");
  const [hobbies, setHobbies] = useState(user?.hobbies ?? "");

  const isNotChanged =
    userAbout === user?.about_me &&
    firstName === user?.first_name &&
    lastName === user?.last_name &&
    occupation === user?.occupation &&
    religion === user?.religion &&
    userName === user?.alais &&
    qualification === user?.qualification &&
    status === user?.status &&
    city === user?.location_city &&
    state === user?.location_state &&
    country === user?.location_country &&
    hobbies === user?.hobbies;

  return (
    <div className="space-y-5">
      <div className="space-y-3">
        <h2 className="md:text-xl text-sm font-bold">About Me</h2>
        <div className="bg-white rounded-lg p-4 md:p-6">
          <textarea
            value={userAbout}
            onChange={(e) => setUserAbout(e.target.value)}
            placeholder="Kindly enter your bio..."
            className="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 placeholder-gray-400 shadow-sm text-xs sm:text-sm focus:border-blue-1 focus:ring-blue-1 lg:text-lg h-28 resize-none"
          ></textarea>
        </div>
      </div>
      <div className="space-y-3">
        <h2 className="md:text-xl text-sm font-bold">Personal Details</h2>
        <div className="bg-white space-y-10 rounded-lg p-4 md:p-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-7">
            <div className="space-y-2">
              <label
                htmlFor="firstname"
                className="text-xs sm:text-sm block font-bold"
              >
                First Name
              </label>
              <input
                type="text"
                name="firstname"
                id="firstname"
                value={firstName}
                placeholder="Enter first name"
                onChange={(e) => setFirstName(e.target.value)}
                className="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 placeholder-gray-400 shadow-sm text-xs sm:text-sm focus:border-blue-1 focus:ring-blue-1 lg:text-lg"
              />
            </div>
            <div className="space-y-2">
              <label
                htmlFor="lastname"
                className="text-xs sm:text-sm block font-bold"
              >
                Last Name
              </label>
              <input
                type="text"
                name="lastname"
                id="lastname"
                value={lastName}
                placeholder="Enter last name"
                onChange={(e) => setLastName(e.target.value)}
                className="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 placeholder-gray-400 shadow-sm text-xs sm:text-sm focus:border-blue-1 focus:ring-blue-1 lg:text-lg"
              />
            </div>
            <div className="space-y-2">
              <label
                htmlFor="username"
                className="text-xs sm:text-sm block font-bold"
              >
                User Name
              </label>
              <input
                type="text"
                name="username"
                id="username"
                value={userName}
                placeholder="Enter user name"
                onChange={(e) => setUserName(e.target.value)}
                className="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 placeholder-gray-400 shadow-sm text-xs sm:text-sm focus:border-blue-1 focus:ring-blue-1 lg:text-lg"
              />
            </div>

            <div className="space-y-2">
              <label
                htmlFor="religion"
                className="text-xs sm:text-sm block font-bold"
              >
                Religion
              </label>
              <select
                id="religion"
                name="religion"
                value={religion}
                onChange={(e) => setReligion(e.target.value)}
                className="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 placeholder-gray-400 shadow-sm text-xs sm:text-sm focus:border-blue-1 focus:ring-blue-1 lg:text-lg"
              >
                <option value={""}>Select religion</option>
                {RELIGIONS.map((status, i) => (
                  <option value={status} key={i}>
                    {status}
                  </option>
                ))}
              </select>
            </div>

            <div className="space-y-2">
              <label
                htmlFor="occupation"
                className="text-xs sm:text-sm block font-bold"
              >
                Occupation
              </label>
              <input
                type="text"
                name="occupation"
                id="occupation"
                value={occupation}
                placeholder="Enter Occupation"
                onChange={(e) => setOccupation(e.target.value)}
                className="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 placeholder-gray-400 shadow-sm text-xs sm:text-sm focus:border-blue-1 focus:ring-blue-1 lg:text-lg"
              />
            </div>

            <div className="space-y-2">
              <label
                htmlFor="city"
                className="text-xs sm:text-sm block font-bold"
              >
                City
              </label>
              <input
                type="text"
                name="city"
                id="city"
                value={city}
                placeholder="Enter City"
                onChange={(e) => setCity(e.target.value)}
                className="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 placeholder-gray-400 shadow-sm text-xs sm:text-sm focus:border-blue-1 focus:ring-blue-1 lg:text-lg"
              />
            </div>
            <div className="space-y-2">
              <label
                htmlFor="state"
                className="text-xs sm:text-sm block font-bold"
              >
                State
              </label>
              <input
                type="text"
                name="state"
                id="state"
                value={state}
                placeholder="Enter State"
                onChange={(e) => setState(e.target.value)}
                className="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 placeholder-gray-400 shadow-sm text-xs sm:text-sm focus:border-blue-1 focus:ring-blue-1 lg:text-lg"
              />
            </div>
            <div className="space-y-2">
              <label
                htmlFor="country"
                className="text-xs sm:text-sm block font-bold"
              >
                Country
              </label>
              <input
                type="text"
                name="country"
                id="country"
                value={country}
                placeholder="Enter Country"
                onChange={(e) => setCountry(e.target.value)}
                className="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 placeholder-gray-400 shadow-sm text-xs sm:text-sm focus:border-blue-1 focus:ring-blue-1 lg:text-lg"
              />
            </div>

            <div className="space-y-2">
              <label
                htmlFor="qualifications"
                className="text-xs sm:text-sm block font-bold"
              >
                Qualification
              </label>
              <select
                id="qualifications"
                name="qualifications"
                value={qualification}
                onChange={(e) => setQualification(e.target.value)}
                className="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 placeholder-gray-400 shadow-sm text-xs sm:text-sm focus:border-blue-1 focus:ring-blue-1 lg:text-lg"
              >
                <option value={""}>Select qualifications</option>
                {HIGHEST_QUALIFICATION.map((status, i) => (
                  <option value={status} key={i}>
                    {status}
                  </option>
                ))}
              </select>
            </div>
            <div className="space-y-2">
              <label
                htmlFor="status"
                className="text-xs sm:text-sm block font-bold"
              >
                Relationship Status
              </label>
              <select
                id="status"
                name="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 placeholder-gray-400 shadow-sm text-xs sm:text-sm focus:border-blue-1 focus:ring-blue-1 lg:text-lg"
              >
                <option value={""}>Select status</option>
                {RELATIONSHIP_STATUS.map((status, i) => (
                  <option value={status} key={i}>
                    {status}
                  </option>
                ))}
              </select>
            </div>
            <div className="space-y-2">
              <label
                htmlFor="Hobbies"
                className="text-xs sm:text-sm block font-bold"
              >
                Hobbies
              </label>
              <input
                type="text"
                name="Hobbies"
                id="Hobbies"
                value={hobbies}
                placeholder="Enter Hobbies"
                onChange={(e) => setHobbies(e.target.value)}
                className="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 placeholder-gray-400 shadow-sm text-xs sm:text-sm focus:border-blue-1 focus:ring-blue-1 lg:text-lg"
              />
            </div>
          </div>
          <div className="flex justify-end">
            <button
              onClick={() => {
                const payload = {};
                if (userAbout?.trim?.() !== user?.about_me) {
                  payload.about_me = userAbout?.trim?.();
                }
                if (firstName?.trim?.() !== user?.first_name) {
                  payload.first_name = firstName?.trim?.();
                }
                if (lastName?.trim?.() !== user?.last_name) {
                  payload.last_name = lastName?.trim?.();
                }
                if (occupation?.trim?.() !== user?.occupation) {
                  payload.occupation = occupation?.trim?.();
                }
                if (religion?.trim?.() !== user?.religion) {
                  payload.religion = religion?.trim?.();
                }
                if (userName?.trim?.() !== user?.alais) {
                  payload.alais = userName?.trim?.();
                }
                if (qualification?.trim?.() !== user?.qualification) {
                  payload.qualification = qualification?.trim?.();
                }
                if (status?.trim?.() !== user?.status) {
                  payload.status = status?.trim?.();
                }
                if (city?.trim?.() !== user?.location_city) {
                  payload.location_city = city?.trim?.();
                }
                if (state?.trim?.() !== user?.location_state) {
                  payload.location_state = state?.trim?.();
                }
                if (country?.trim?.() !== user?.location_country) {
                  payload.location_country = country?.trim?.();
                }
                if (hobbies?.trim?.() !== user?.hobbies) {
                  payload.hobbies = hobbies?.trim?.();
                }

                setIsLoading(true);
                saveUser(payload, () => {
                  setIsLoading(false);
                  toast.success("Changes saved successfully");
                  navigate(-1);
                });
              }}
              disabled={isNotChanged || isLoading}
              className="px-6 py-2.5 w-full sm:w-max rounded-lg bg-[#081A51] text-white font-semibold hover:opacity-75 disabled:bg-gray-300 disabled:text-gray-700"
            >
              {isLoading ? (
                <BeatLoader size={10} color="#fff" />
              ) : (
                "Save Changes"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import { Menu, Transition } from "@headlessui/react";
import {
  AccountBalanceWallet,
  GroupRemove,
  Groups,
  People,
} from "@mui/icons-material";
import { Fragment, useState } from "react";
import LinePagination from "src/Paging/LinePagination";

export default function SuperAdminDashboard() {
  const [allDatas, setAllDatas] = useState([]);
  const [transactionLists] = useState([
    {
      fullname: "Mohammed",
      email: "adedeji@gmail.com",
      phone: "08078903721",
      type: "Premium Monthly",
      date_created: "May 15, 2020 10:00 AM",
      days_remaining: "22",
      status: "active",
    },
  ]);
  return (
    <div className="space-y-8">
      <div className="grid grid-cols-4 gap-5">
        <div className="bg-white space-y-3 p-4 rounded">
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium">All Subscriptions</p>
            <div className="w-6 h-6 text-sm bg-[#F1F2FA] text-brand-1 rounded-full flex justify-center items-center">
              <AccountBalanceWallet fontSize="inherit" />
            </div>
          </div>
          <h2 className="text-2xl font-bold">N3,000,000</h2>
        </div>
        <div className="bg-white space-y-3 p-4 rounded">
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium">All Users</p>
            <div className="w-6 h-6 text-sm bg-[#F1F2FA] text-brand-1 rounded-full flex justify-center items-center">
              <People fontSize="inherit" />
            </div>
          </div>
          <h2 className="text-2xl font-bold">3,900</h2>
        </div>
        <div className="bg-white space-y-3 p-4 rounded">
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium">Blacklisted Users</p>
            <div className="w-6 h-6 text-sm bg-[#F1F2FA] text-brand-1 rounded-full flex justify-center items-center">
              <GroupRemove fontSize="inherit" />
            </div>
          </div>
          <h2 className="text-2xl font-bold">100</h2>
        </div>
        <div className="bg-white space-y-3 p-4 rounded">
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium">All Handlers</p>
            <div className="w-6 h-6 text-sm bg-[#F1F2FA] text-brand-1 rounded-full flex justify-center items-center">
              <Groups fontSize="inherit" />
            </div>
          </div>
          <h2 className="text-2xl font-bold">34</h2>
        </div>
      </div>
      <div className="flex gap-5 ">
        <div className="flex-grow divide-y divide-gray-300 bg-white rounded">
          <div className="p-4">
            <p className="text-sm font-medium">Monthly Financial Chart</p>
          </div>
          <div className="p-4 h-40"></div>
        </div>
        <div className="w-3/12 flex-shrink-0 divide-y divide-gray-300 bg-white rounded">
          <div className="p-4">
            <p className="text-sm font-medium">User Analysis</p>
          </div>
          <div className="p-4 h-40"></div>
        </div>
      </div>
      <div className=" bg-white rounded">
        <div className="p-4 flex justify-between items-center">
          <p className="text-sm font-medium">Transaction history</p>
          <Menu as="div" className="w-max relative">
            {({ open }) => (
              <>
                <Menu.Button as="div" className="">
                  <div className="cursor-pointer bg-gray-300 hover:opacity-70 rounded px-4 py-2 flex gap-3 items-center">
                    <p className="text-sm">Sort by status</p>
                    <i className="fa-solid text-xs fa-chevron-down"></i>
                  </div>
                </Menu.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="absolute z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-full top-full left-0"
                  >
                    <div className="flex items-center text-text-1 justify-between">
                      <p className="text-10 ">Select religion</p>
                      <i className="fa-solid text-10 fa-chevron-up"></i>
                    </div>
                    {/* {RELIGIONS.map((religion) => (
                        <Menu.Item key={religion}>
                          <div
                            onClick={() => setReligion(religion)}
                            className={`w-full rounded-lg px-3 py-2 hover:bg-drop-1 hover:text-white text-xs`}
                          >
                            {religion}
                          </div>
                        </Menu.Item>
                      ))} */}
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
        <div className="">
          <LinePagination
            data={transactionLists || []}
            setCurrentItems={setAllDatas}
          >
            <table className="relative w-full overflow-y-auto border  bg-white">
              <thead className=" rounded-lg rounded-t border px-5">
                <tr className="rounded-t-lg">
                  <th
                    scope="col"
                    className={`px-2 py-4 pl-5 text-left text-xs font-medium uppercase`}
                  >
                    <div className="flex gap-2 items-center">Full Name</div>
                  </th>
                  <th
                    scope="col"
                    className={`px-2 py-4 text-left text-xs font-medium uppercase`}
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className={`px-2 py-4  text-left text-xs font-medium uppercase`}
                  >
                    Phone Number
                  </th>
                  <th
                    scope="col"
                    className={`px-2 py-4  text-left text-xs font-medium uppercase`}
                  >
                    Subscription Type
                  </th>
                  <th
                    scope="col"
                    className={`px-2 py-4  text-left text-xs font-medium uppercase`}
                  >
                    Subscription Date
                  </th>
                  <th
                    scope="col"
                    className={`px-2 py-4  text-left text-xs font-medium uppercase`}
                  >
                    Days Remaining
                  </th>
                  <th
                    scope="col"
                    className={`px-2 py-4  text-left text-xs font-medium uppercase`}
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-300 overflow-auto bg-white px-5 pt-5">
                {allDatas?.map?.((item: any, index: number) => (
                  <tr className="hover:bg-gray-100" key={index}>
                    <td className="px-2 pl-5 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                      {item?.fullname}
                    </td>
                    <td className="px-2 py-4 text-left text-xs font-normal lowercase text-[#545F7D]">
                      {item?.email}
                    </td>
                    <td className="px-2 py-4 text-left text-xs font-normal text-[#545F7D]">
                      {item?.phone}
                    </td>
                    <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                      {item?.type}
                    </td>
                    <td className="px-2 py-4 text-left text-xs font-normal text-[#545F7D]">
                      {item?.date_created}
                    </td>
                    <td className="px-2 py-4 text-left text-xs font-normal text-[#545F7D]">
                      {item?.days_remaining}
                    </td>
                    <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                      <p
                        className={`px-4 py-1 w-max rounded-full ${
                          item?.status?.toLowerCase?.() === "inactive"
                            ? "bg-[#545f7d3b] text-black"
                            : item?.status?.toLowerCase?.() === "pending"
                            ? "bg-[#e9b30042] text-[#E9B200]"
                            : item?.status?.toLowerCase?.() === "active"
                            ? "bg-[#39cd633f] text-[#39CD62] "
                            : "bg-[#e4033b41] text-[#E4033B]"
                        } text-center`}
                      >
                        {item?.status}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </LinePagination>
        </div>
      </div>
    </div>
  );
}

import { gql } from "@apollo/client";

export const UPDATE_USER = gql`
  mutation UpdateUser($updateUserId: ID!, $payload: UpdateUserPayload) {
    updateUser(id: $updateUserId, payload: $payload) {
      id
      media_ids
      media {
        id
        url
      }
    }
  }
`;
//

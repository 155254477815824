import { BeatLoader } from "react-spinners";

export default function Loading() {
  return (
    <div
      style={{ backgroundColor: "#212529c9", zIndex: 999999999 }}
      className="fixed top-0 left-0 z-60 flex h-full w-full items-center justify-center"
    >
      <div className="flex flex-col items-center justify-center">
        <h2 className="animate-pulse text-xl font-medium text-white">
          Loading...
        </h2>
        <BeatLoader color="white" size={30} />
      </div>
    </div>
  );
}
